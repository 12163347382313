import { FetchNextPageOptions } from '@tanstack/react-query';
import {
    ColumnDef,
    SortingState,
    flexRender,
    getCoreRowModel,
    getExpandedRowModel,
    getSortedRowModel,
    useReactTable,
} from '@tanstack/react-table';
import { ChevronDown, ChevronRight } from 'lucide-react';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableHeader,
    TableRow,
} from 'src/components/ui/table';
import { cn } from 'src/lib/utils';

interface Props<TData, TSubRowField extends keyof TData, TValue>
    extends React.HTMLAttributes<HTMLDivElement> {
    columns: ColumnDef<TData, TValue>[];
    data: TData[];
    height?: string;
    fetchNextPage?: (options?: FetchNextPageOptions) => void;
    isLoading?: boolean;
    isFetching?: boolean;
    hasNextPage?: boolean;
    headerClassName?: string;
    parentClassName?: string;
    subRowsField?: TSubRowField;
    toggleAllRowsExpanded?: boolean;
    groupBy?: string;
    groupLabels?: Record<string, string>;
}

export function VirtualTable<TData, TSubRowField extends keyof TData, TValue>({
    columns,
    data,
    height,
    fetchNextPage,
    isLoading,
    isFetching,
    hasNextPage,
    headerClassName,
    parentClassName,
    className,
    subRowsField,
    toggleAllRowsExpanded,
    groupBy,
    groupLabels,
}: Props<TData, TSubRowField, TValue>) {
    const tableRef = useRef<HTMLTableElement>(null);
    const { ref, inView } = useInView();
    const [sorting, setSorting] = useState<SortingState>([]);
    const [expanded, setExpanded] = useState<any>({});

    const getSubRows = (row: TData) => {
        if (!subRowsField) return [];

        return row[subRowsField] as TData[];
    };

    const table = useReactTable({
        columns,
        data,
        state: {
            sorting,
            expanded,
        },
        onExpandedChange: setExpanded,
        onSortingChange: setSorting,
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getExpandedRowModel: getExpandedRowModel(),
        getSubRows: (row) => getSubRows(row),
    });

    const isEmpty = data.length === 0;

    useEffect(() => {
        if (inView && hasNextPage) {
            fetchNextPage?.();
        }
    }, [inView, fetchNextPage, hasNextPage]);

    useEffect(() => {
        if (toggleAllRowsExpanded) {
            table.toggleAllRowsExpanded();
        }
    }, [toggleAllRowsExpanded, table]);

    const groupedRows = useMemo(() => {
        if (!groupBy || !data.length) return null;

        return data.reduce((groups: Record<string, TData[]>, item: any) => {
            const group = item[groupBy] || 'Others';

            if (!groups[group]) groups[group] = [];
            groups[group].push(item);

            return groups;
        }, {});
    }, [data, groupBy]);

    return (
        <Table
            // className='h-full border text-xs'
            className={cn(className, 'h-full border text-xs')}
            parentClassName={parentClassName}
            parentStyle={{
                height: '100%',
                // maxHeight: maxHeight > 280 ? `${maxHeight}px` : 'auto',
                flex: 1,
                overflow: 'auto', // Add this to enable scrolling
            }}
            ref={tableRef}
        >
            <TableHeader className='sticky top-0 z-10'>
                {table.getHeaderGroups().map((headerGroup) => (
                    <TableRow key={headerGroup.id}>
                        {headerGroup.headers.map((header) => {
                            return (
                                <TableHead
                                    key={header.id}
                                    className={cn(
                                        'whitespace-nowrap px-4',
                                        headerClassName
                                    )}
                                    style={{
                                        width: header.column.getSize(),
                                    }}
                                >
                                    {header.isPlaceholder
                                        ? null
                                        : flexRender(
                                              header.column.columnDef.header,
                                              header.getContext()
                                          )}
                                </TableHead>
                            );
                        })}
                    </TableRow>
                ))}
            </TableHeader>

            <TableBody className='bg-white'>
                {isEmpty && !isLoading && (
                    <TableRow>
                        <TableCell
                            colSpan={table.getAllColumns().length}
                            className='h-24 text-center'
                        >
                            No results.
                        </TableCell>
                    </TableRow>
                )}

                {isLoading && !isEmpty && (
                    <TableRow>
                        <TableCell
                            className='text-center'
                            colSpan={table.getAllColumns().length}
                        >
                            Loading...
                        </TableCell>
                    </TableRow>
                )}

                {!isLoading && !isEmpty && groupedRows && (
                    <>
                        {Object.entries(groupedRows).map(([group, items]) => (
                            <React.Fragment key={group}>
                                <TableRow
                                    className='cursor-pointer bg-gray-100 hover:bg-gray-200'
                                    onClick={() => {
                                        const newExpanded = { ...expanded };
                                        newExpanded[group] = !expanded[group];
                                        setExpanded(newExpanded);
                                    }}
                                >
                                    <TableCell
                                        colSpan={columns.length}
                                        className='font-medium'
                                    >
                                        <div className='flex items-center gap-2'>
                                            {expanded[group] ? (
                                                <ChevronDown size={16} />
                                            ) : (
                                                <ChevronRight size={16} />
                                            )}
                                            {groupLabels?.[group] || group}
                                        </div>
                                    </TableCell>
                                </TableRow>

                                {expanded[group] &&
                                    items.map((item: any, index) => (
                                        <TableRow
                                            key={index}
                                            className='h-[50px]'
                                        >
                                            {table
                                                .getRowModel()
                                                .rows.find(
                                                    (row) =>
                                                        row.original === item
                                                )
                                                ?.getVisibleCells()
                                                .map((cell) => (
                                                    <TableCell
                                                        key={cell.id}
                                                        className={cn(
                                                            'truncate px-4 text-xs',
                                                            cell.column
                                                                .columnDef.meta
                                                                ?.columnsClassName
                                                        )}
                                                    >
                                                        {flexRender(
                                                            cell.column
                                                                .columnDef.cell,
                                                            cell.getContext()
                                                        )}
                                                    </TableCell>
                                                ))}
                                        </TableRow>
                                    ))}
                            </React.Fragment>
                        ))}
                    </>
                )}

                {!groupedRows &&
                    !isLoading &&
                    !isEmpty &&
                    table.getRowModel().rows.map((row, index) => {
                        const rowData = row.original as any;
                        const isCustom = rowData.isCustom;
                        const firstCell = row.getVisibleCells()?.[0];

                        if (isCustom) {
                            return (
                                <TableRow key={row.id}>
                                    <TableCell
                                        colSpan={columns.length}
                                        className='bg-gray-200'
                                    >
                                        {flexRender(
                                            firstCell?.column.columnDef.cell,
                                            firstCell?.getContext()
                                        )}
                                    </TableCell>
                                </TableRow>
                            );
                        }

                        return (
                            <>
                                <TableRow
                                    key={row.id}
                                    data-state={
                                        row.getIsSelected() && 'selected'
                                    }
                                    className='h-[50px]'
                                    ref={
                                        index === data.length - 1
                                            ? ref
                                            : undefined
                                    }
                                >
                                    {row.getVisibleCells().map((cell) => (
                                        <TableCell
                                            key={cell.id}
                                            className={cn(
                                                'truncate px-4 text-xs',
                                                cell.column.columnDef.meta
                                                    ?.columnsClassName
                                            )}
                                        >
                                            {flexRender(
                                                cell.column.columnDef.cell,
                                                cell.getContext()
                                            )}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </>
                        );
                    })}

                {table.getRowModel().rows.length < 10 &&
                    !isLoading &&
                    !isEmpty && (
                        <TableRow className='h-full'>
                            <TableCell
                                colSpan={table.getAllColumns().length}
                                className='h-24 text-center'
                            ></TableCell>
                        </TableRow>
                    )}

                {isFetching && (
                    <TableRow>
                        <TableCell
                            className='text-center'
                            colSpan={table.getAllColumns().length}
                        >
                            Loading...
                        </TableCell>
                    </TableRow>
                )}
            </TableBody>
        </Table>
    );
}
