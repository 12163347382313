import { ServiceConfigInterface } from 'src/domain/interfaces/config.context.interface';
import { PurchaseStatisticsClient } from './purchase-statistics-client';

export class PurchaseStatisticsService {
    private server: PurchaseStatisticsClient;

    constructor(config: ServiceConfigInterface, token: string) {
        this.server = new PurchaseStatisticsClient(config, token);
    }

    list(workspace: string) {
        console.log(`service<purchase-statistics>| list(): Enter`);
        console.log(
            `service<purchase-statistics>| list(): $workspace = ${workspace}`
        );

        return this.server.list(workspace);
    }

    get(workspace: string, item: string) {
        console.log(`service<purchase-statistics>| get(): Enter`);
        console.log(
            `service<purchase-statistics>| get(): $workspace = ${workspace}`
        );
        console.log(`service<purchase-statistics>| get(): $item = ${item}`);

        return this.server.get(workspace, item);
    }

    getStatisticsByWorkspace(
        workspace: string,
        startDate: string,
        endDate: string
    ) {
        console.log(
            `service<purchase-statistics>| getStatisticsByWorkspace(): Enter`
        );
        console.log(
            `service<purchase-statistics>| getStatisticsByWorkspace(): $workspace = ${workspace}`
        );
        console.log(
            `service<purchase-statistics>| getStatisticsByWorkspace(): $startDate = ${startDate}`
        );

        return this.server.getStatisticsByWorkspace(
            workspace,
            startDate,
            endDate
        );
    }

    getSubstanceOrderInformation(
        workspace: string,
        substanceName: string,
        substanceCode: string
    ) {
        console.log(
            `service<purchase-statistics>| getSubstanceOrderInformation(): Enter`
        );
        console.log(
            `service<purchase-statistics>| getSubstanceOrderInformation(): $workspace = ${workspace}`
        );
        console.log(
            `service<purchase-statistics>| getSubstanceOrderInformation(): $substanceCode = ${substanceCode}`
        );
        console.log(
            `service<purchase-statistics>| getSubstanceOrderInformation(): $substanceName = ${substanceName}`
        );

        return this.server.getSubstanceOrderInformation(
            workspace,
            substanceName,
            substanceCode
        );
    }
}
