export const BASE_PRODUCT_QUERY_KEY = 'product';
export const BASE_PRODUCTS_QUERY_KEY = 'products';

export const PRODUCTS_QUERY_KEY = (workspace: string) => [
    BASE_PRODUCTS_QUERY_KEY,
    workspace,
];

export const PRODUCT_QUERY_KEY = (workspace: string, item: string) => [
    BASE_PRODUCT_QUERY_KEY,
    workspace,
    item,
];

export const PRODUCT_VERSION_QUERY_KEY = (
    workspace: string,
    item: string,
    version: string
) => [BASE_PRODUCT_QUERY_KEY, workspace, item, version];
