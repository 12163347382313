import { zodResolver } from '@hookform/resolvers/zod';
import axios from 'axios';
import { Loader2 } from 'lucide-react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';
import { FileCreateDataObject } from 'src/app/_api_adb2c/attachment/file-create-data-object';
import { useAttachmentUpload } from 'src/app/_api_adb2c/attachment/hooks/use-attachment-upload';
import { useAttachSalesOrderFiles } from 'src/app/_api_adb2c/sales/sales/hooks/use-attach-sales-order-files';
import { useSalesOrder } from 'src/app/_api_adb2c/sales/sales/hooks/use-sales-order';
import type { SalesOrderModel } from 'src/app/_api_adb2c/sales/sales/models/sales-order.model';
import { SalesOrderAttachDataObject } from 'src/app/_api_adb2c/sales/sales/request/sales-order-attach-data-object';
import { SalesOrderDocumentDataObject } from 'src/app/_api_adb2c/sales/sales/request/sales-order-document-data-object';
import { SupportDocType } from 'src/app/_api_adb2c/shared/support-doc-type.enum';
import { useBatchRulesets } from 'src/app/_api_adb2c/workspace/ruleset/hooks/use-batch-rulesets';
import { BaseDialog } from 'src/app/components-v2/base-dialog';
import { useContextStore } from 'src/app/stores/context-store';
import { cn } from 'src/app/utils/cn';
import { Button } from 'src/components/ui/button';
import { Form } from 'src/components/ui/form';
import { Skeleton } from 'src/components/ui/skeleton';
import { v4 as uuidv4 } from 'uuid';
import { z } from 'zod';
import { getRequiredDocuments } from '../../Purchases/DetailsV2/Documents/purchase-documents';
import { OrderOptionalDocumentsForm } from './order-optional-documents-form';
import { OrderRequiredDocumentsForm } from './order-required-documents-form';

interface Props {
    open: boolean;
    onClose: () => void;
    salesOrderId: string;
}

export interface RequiredDocument {
    type: string;
    file: File[] | null;
    existingDocuments?: SalesOrderModel['documents'];
    required?: boolean;
}

export const attachmentModelSchema = z.object({
    assetName: z.string(),
    container: z.string(),
    contentType: z.string(),
    extension: z.string(),
    originalName: z.string(),
    size: z.number(),
    readReleaseTime: z.number(),
});

export const salesOrderDocModelSchema = z.object({
    id: z.string().optional(),
    comment: z.string().optional(),
    file: attachmentModelSchema,
    type: z.string(),
    certificateNumber: z.string().optional(),
});

export const optionalItemSchema = z.object({
    _id: z.string().optional(),
    type: z.string(),
    file: z.instanceof(File).optional(),
    existingFile: attachmentModelSchema.optional(),
    required: z.literal(false),
});

export const requiredItemSchema = z.object({
    type: z.string(),
    file: z.array(z.instanceof(File)),
    existingFiles: z.array(salesOrderDocModelSchema).optional(),
    required: z.literal(true),
    _id: z.string().optional(),
});

const discriminatedUnionSchema = z.discriminatedUnion('required', [
    requiredItemSchema,
    optionalItemSchema,
]);

export const schema = z.object({
    items: z.array(discriminatedUnionSchema),
});

export function SalesOrderDocuments({ open, onClose, salesOrderId }: Props) {
    const context = useContextStore();
    const [searchParams] = useSearchParams();
    const delegateId = searchParams.get('delegateId') || '';
    const [removed, setRemoved] = useState<
        z.infer<typeof salesOrderDocModelSchema>[]
    >([]);

    const [loading, setLoading] = useState(false);
    const [rendering, setRendering] = useState(false);

    const { data: salesOrder } = useSalesOrder(salesOrderId, delegateId);
    const { data: rulesets } = useBatchRulesets(salesOrder?.rules || []);

    const { mutateAsync: upload } = useAttachmentUpload();

    const { mutateAsync: attach } = useAttachSalesOrderFiles();

    const form = useForm<z.infer<typeof schema>>({
        mode: 'onChange',
        resolver: zodResolver(schema),
        defaultValues: { items: [] },
    });

    const uploadAndReturnFile = async (type: string, file: File) => {
        const request: FileCreateDataObject = {
            contentType: file.type,
            size: file.size,
            name: file.name,
            genre: 'temporary',
            uploadedBy: context.user?._id || '',
            workspace: context.workspace?._id || '',
        };

        const token = await upload({
            file: request,
            assetType: 'salesOrder',
            delegateId: delegateId,
        });

        await axios.put(token.token, file, {
            headers: {
                'Content-Type': file.type,
                'x-ms-blob-type': 'BlockBlob',
            },
        });

        const response = {
            file: {
                assetName: token.attachment.assetName,
                autoResign: token.attachment.autoResign,
                container: token.attachment.container,
                contentType: token.attachment.contentType,
                extension: file.name.split('.').pop() || '',
                originalName: token.attachment.originalName,
                readReleaseTime: token.attachment.readReleaseTime,
                size: file.size,
            },
            type: type as SupportDocType,
        };

        return response;
    };

    const onSubmit = async (data: z.infer<typeof schema>) => {
        if (!salesOrder) return;

        setLoading(true);
        // Remove away the existing documents that are deleted
        const documents: SalesOrderDocumentDataObject[] = [];

        salesOrder?.documents
            .filter(
                (doc) =>
                    !removed.find(
                        (x) => x.file.assetName === doc.file.assetName
                    )
            )
            .forEach((doc) => documents.push(doc));

        const requiredDocuments = data.items.filter((x) => x.required);
        const optionalDocuments = data.items.filter((x) => !x.required);

        // Handle required documents upload path
        await Promise.all(
            requiredDocuments.map(async (doc) => {
                if (!doc.file) return;

                const isRequired = doc.required;

                if (!isRequired || doc.file.length === 0) return;

                for (const file of doc.file) {
                    const response = await uploadAndReturnFile(doc.type, file);

                    documents.push(response);
                }
            })
        );

        // Handle optional documents upload path
        await Promise.all(
            optionalDocuments.map(async (doc) => {
                if (!doc.file) return;
                const isOptional = doc.required;

                if (!isOptional) {
                    const response = await uploadAndReturnFile(
                        doc.type,
                        doc.file
                    );
                    documents.push(response);
                }
            })
        );

        const request: SalesOrderAttachDataObject = {
            documents: documents,
        };

        await attach({
            id: salesOrder?._id || '',
            body: request,
            delegateId: delegateId,
        });

        setLoading(false);
        reset();
    };

    const reset = useCallback(() => {
        setRemoved([]);
        onClose();
    }, [onClose]);

    const ruleset = useMemo(() => {
        if (!salesOrder || !rulesets) return undefined;

        if (salesOrder.rules?.length === 0) return undefined;

        return rulesets.find((x) => x._id === salesOrder.rules?.[0]);
    }, [salesOrder, rulesets]);

    const requiredDocuments = useMemo(() => {
        if (!ruleset) return [];

        const processes = salesOrder?.processes || [];
        return getRequiredDocuments(processes, ruleset);
    }, [ruleset, salesOrder]);

    const [mode, setMode] = useState<'required' | 'optional'>('required');

    useEffect(() => {
        setRendering(true);
        const consolidatedItems: RequiredDocument[] = [];
        const documents = salesOrder?.documents || [];

        requiredDocuments.forEach((doc) => {
            const relevantDocument = documents.filter((x) => x.type === doc);
            consolidatedItems.push({
                type: doc,
                file: [],
                existingDocuments: relevantDocument,
                required: true,
            });
        });

        const items: z.infer<typeof discriminatedUnionSchema>[] = [];

        const optionalFiles = documents.filter(
            (x) => !requiredDocuments.includes(x.type)
        );

        requiredDocuments.forEach((doc) => {
            const requiredFiles = documents.filter((x) => x.type === doc);

            items.push({
                type: doc,
                file: [],
                existingFiles: requiredFiles.map((x) => ({
                    id: x.id,
                    comment: x.comment,
                    createdOn: x.createdOn,
                    file: x.file,
                    lastUpdatedOn: x.lastUpdatedOn,
                    type: x.type,
                    certificateNumber: x.certificateNumber,
                })),
                required: true,
                _id: uuidv4(),
            });
        });

        optionalFiles.forEach((doc) => {
            items.push({
                type: doc.type,
                existingFile: doc.file,
                required: false,
                _id: uuidv4(),
            });
        });

        form.reset({
            items: items,
        });

        setRendering(false);
    }, [requiredDocuments, salesOrder, form]);

    return (
        <BaseDialog
            open={open}
            onClose={reset}
            title='Upload Documents'
            sizes='xl'
            className='h-3/5 w-3/5 max-w-[60%]'
            formName='add-purchase-document-requirement'
            contentClassName='overflow-auto h-full'
            showConfirmButton
            isLoading={loading}
        >
            <div className='grid h-full grid-cols-5 gap-4 divide-x text-xs'>
                <div className='col-span-1 flex flex-col gap-4 px-2'>
                    <Button
                        size='sm'
                        variant='outline'
                        id='required-button'
                        onClick={() => setMode('required')}
                        type='button'
                        className={cn(
                            mode === 'required' &&
                                'bg-red-800 text-white hover:bg-red-800 hover:text-white'
                        )}
                    >
                        Required
                    </Button>

                    <Button
                        size='sm'
                        variant='outline'
                        id='optional-button'
                        onClick={() => setMode('optional')}
                        type='button'
                        className={cn(
                            mode === 'optional' &&
                                'bg-red-800 text-white hover:bg-red-800 hover:text-white'
                        )}
                    >
                        Optional
                    </Button>
                </div>

                <div className='col-span-4 pl-6'>
                    {rendering && (
                        <Skeleton className='flex h-full w-full items-center justify-center gap-2'>
                            <Loader2 size={16} className='animate-spin' />
                            <span className='items-center justify-center text-center text-sm'>
                                Loading Document Data...
                            </span>
                        </Skeleton>
                    )}

                    {!rendering && (
                        <Form {...form}>
                            <form
                                id='add-purchase-document-requirement'
                                className='h-full'
                                onSubmit={form.handleSubmit(
                                    (value) => onSubmit(value),
                                    (error) => console.error(error)
                                )}
                            >
                                {mode === 'required' && (
                                    <OrderRequiredDocumentsForm
                                        removed={removed}
                                        setRemoved={setRemoved}
                                        requiredDocuments={requiredDocuments}
                                    />
                                )}

                                {mode === 'optional' && (
                                    <OrderOptionalDocumentsForm
                                        removed={removed}
                                        setRemoved={setRemoved}
                                        requiredDocuments={requiredDocuments}
                                    />
                                )}
                            </form>
                        </Form>
                    )}
                </div>
            </div>
        </BaseDialog>
    );
}
