import { useAccount } from '@azure/msal-react';
import { useMemo } from 'react';
import useAppConfig from 'src/infrastructure/hooks/use-config.hook';
import { SolicitService } from '../solicit.service';

export function useSolicitService() {
    const config = useAppConfig();
    const account = useAccount();

    const service = useMemo(() => {
        if (!account?.idToken) {
            throw new Error('Session Expired');
        }

        return new SolicitService(config, account.idToken);
    }, [account, config]);

    return { service };
}
