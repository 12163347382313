import { useDeleteMaterial } from 'src/app/_api_adb2c/product/material/hooks/use-delete-material';
import { MaterialModel } from 'src/app/_api_adb2c/product/material/models/material.model';
import { RemoveDialog } from '../Purchases/Details/delete-purchase';

interface Props {
    open: boolean;
    onClose: () => void;
    material: MaterialModel;
}

export const DeleteMaterial = ({ open, onClose, material }: Props) => {
    const { mutateAsync, isLoading } = useDeleteMaterial();

    const remove = async () => {
        await mutateAsync({
            id: material._id,
        });

        onClose();
    };

    return (
        <RemoveDialog
            title='Remove Material'
            description='Are you sure you want to remove this material?'
            open={open}
            onClose={onClose}
            onRemove={remove}
            isLoading={isLoading}
        />
    );
};
