import {
    Tooltip,
    TooltipContent,
    TooltipProvider,
    TooltipTrigger,
} from 'src/components/ui/tooltip';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
    children: React.ReactNode;
    label: React.ReactNode;
    asChild?: boolean;
}

export const TooltipWrapper = ({
    children,
    label,
    asChild = true,
    ...props
}: Props) => {
    return (
        <TooltipProvider delayDuration={300}>
            <Tooltip>
                <TooltipTrigger asChild={asChild}>{children}</TooltipTrigger>
                <TooltipContent {...props}>{label}</TooltipContent>
            </Tooltip>
        </TooltipProvider>
    );
};
