import { AttachmentModel } from '../../files/attachment.model';
import { CompanyProcessType } from '../../shared/company-process.type';
import { i18nModel } from '../../shared/i18n.model';
import { BaseSupportDocumentModel } from '../../shared/support-document.model';
import { AccountModel } from '../account/account.model';
import { AdaptationModel } from '../adaptation/adaptation.model';
import { CompanyCreateObject, CompanyModel } from '../company/company.model';
import { DelegationModel } from '../delegations/delegation.model';
import { DocumentModel } from '../documents/document.model';
import { RoleModel } from '../roles/roles.model';
import { UserModel } from '../users/user.model';

export enum DocumentStatus {
    DUPICATED = 'DUPLICATED',
    REJECTED = 'REJECTED',
    UNVERIFIED = 'UNVERIFIED',
    VERIFIED = 'VERIFIED',
}

export enum DocumentType {
    AUDIT_REPORTS = 'AUDIT_REPORTS',
    BANK_CERTIFICATE = 'BANK_CERTIFICATE',
    BUSINESS_REGISTRATION = 'BUSINESS_REGISTRATION',
    CERTIFICATION_AWARD = 'CERTIFICATION_AWARD',
    DISTRIBUTION_LICENSE = 'DISTRIBUTION_LICENSE',
    EXPORT_LICENSE = 'EXPORT_LICENSE',
    IMPORT_LICENSE = 'IMPORT_LICENSE',
    PROOF_OF_ADDRESS = 'PROOF_OF_ADDRESS',
    TAX_PAYMENT = 'TAX_PAYMENT',
    OTHER = 'OTHER',
    FACTORY_PROFILE = 'FACTORY_PROFILE',
    GEO_TAG = 'GEO_TAG',
    SEGREGATION_SUPPORT = 'SEGREGATION_SUPPORT',
}

export const DocumentTypeLabel = {
    [DocumentType.AUDIT_REPORTS]: 'Audit Reports',
    [DocumentType.BANK_CERTIFICATE]: 'Bank Certificate',
    [DocumentType.BUSINESS_REGISTRATION]: 'Business Registration',
    [DocumentType.CERTIFICATION_AWARD]: 'Certification Award',
    [DocumentType.DISTRIBUTION_LICENSE]: 'Distribution License',
    [DocumentType.EXPORT_LICENSE]: 'Export License',
    [DocumentType.IMPORT_LICENSE]: 'Import License',
    [DocumentType.PROOF_OF_ADDRESS]: 'Proof of Address',
    [DocumentType.TAX_PAYMENT]: 'Tax Payment',
    [DocumentType.OTHER]: 'Other',
    [DocumentType.FACTORY_PROFILE]: 'Factory Profile',
    [DocumentType.GEO_TAG]: 'Geo Tag',
    [DocumentType.SEGREGATION_SUPPORT]: 'Segregation Support',
};

export enum SupportDocType {
    AUDIT_REPORT = 'AUDIT_REPORT',
    BUSINESS_REGISTRATION = 'BUSINESS_REGISTRATION',
    BILL_OF_LADING = 'BILL_OF_LADING',
    CERTIFICATE_OF_ORIGIN = 'CERTIFICATE_OF_ORIGIN',
    CERTIFICATION_RECORD = 'CERTIFICATION_RECORD',
    PRODUCT_CERTIFICATE = 'PRODUCT_CERTIFICATE',
    COMMERCIAL_INVOICE = 'COMMERCIAL_INVOICE',
    CONTRACT = 'CONTRACT',
    CUSTOM_CLEARANCE = 'CUSTOM_CLEARANCE',
    DELIVERY_NOTE = 'DELIVERY_NOTE',
    INVOICE = 'INVOICE',
    MAP_SCREENSHOT = 'MAP_SCREENSHOT',
    PACKING_LIST = 'PACKING_LIST',
    PAYMENT_VOUCHER = 'PAYMENT_VOUCHER',
    PURCHASE_CONTRACT = 'PURCHASE_CONTRACT',
    PRODUCTION_RECORD = 'PRODUCTION_RECORD',
    PRODUCT_SPECIFICATIONS = 'PRODUCT_SPECIFICATIONS',
    TRANPORTATION_INVOICE = 'TRANPORTATION_INVOICE',
    WAREHOUSE_RECORD = 'WAREHOUSE_RECORD',
    OTHER = 'OTHER',
    SEGREGATION_SUPPORT = 'SEGREGATION_SUPPORT',
}

export interface WorkspaceModel {
    id: string;
    account: AccountModel;
    allowOperator: boolean;
    avatar: AttachmentModel;
    company: CompanyModel;
    createdOn: Date;
    deletedOn?: Date;
    displayName: string;
    divisions?: string[];
    documents: DocumentModel[];
    isCaseDisplayed: boolean;
    isEmailEnabled: boolean;
    isMasterWorkspace: boolean;
    inviter: SolicitationModel[];
    lastUpdatedOn: Date;
    roles: RoleModel[];
    members: MemberModel[];
    rulesets: RulesetModel[];
    solicitations: SolicitationModel[];
    supplyChainLoaderType: number;
    templates: AdaptationModel[];
    emulation: EmulationSessionModel[];
    delegate: DelegationModel;
    workspace: DelegationModel;
}

export interface WorkspaceCreateRequestObject {
    displayName: string;
    company?: CompanyCreateObject;
    divisions?: string[];
    allowOperator?: boolean;
    isProfileCompleted?: boolean;
}

export interface SolicitationModel {
    id: string;
    acceptedOn?: Date;
    contact: UserModel;
    createdBy: MemberModel;
    createdOn: Date;
    deletedOn?: Date;
    expiriesOn: Date;
    invitee: WorkspaceModel;
    inviteAs: string;
    isNominated: boolean;
    workspace: WorkspaceModel;
    delegate: DelegationModel;
}

export interface MemberModel {
    id: string;
    createdOn: Date;
    lastUpdatedOn: Date;
    notifications: NotificationMessageModel[];
    roles: RoleModel[];
    solicitations: SolicitationModel[];
    tasks: TaskModel[];
    title?: string;
    user: UserModel;
    workspace: WorkspaceModel;
}

export interface NotificationMessageModel {
    id: number;
    application: string;
    contents: i18nModel;
    createdOn: Date;
    deletedOn?: Date;
    lastUpdatedOn: Date;
    link?: string;
    owner: MemberModel;
    readOn?: Date;
    title: i18nModel;
}

interface TaskModel {
    id: string;
    completedOn?: Date;
    content: i18nModel;
    createdOn: Date;
    deletedOn?: Date;
    dueOn?: Date;
    lastUpdatedOn: Date;
    reference?: string;
    remindOn?: Date;
    sourceApplication: string;
    sourceType: string;
    sourceIdentity: string;
    title: i18nModel;
    user: MemberModel;
}

export interface ProfileModel {
    id: string;
    application: string;
    code: string;
    createdOn: Date;
    lastUpdatedOn: Date;
    owner: UserModel;
    parameter: PreferenceModel;
    value?: string;
}

interface PreferenceModel {
    id: number;
    application: string;
    code: string;
    createdOn: Date;
    defaultValue?: string;
    description: i18nModel;
    lastUpdatedOn: Date;
    name: string;
    profiles: ProfileModel[];
}

export interface EmulationSessionModel {
    id: string;
    workspace: WorkspaceModel;
    emulateAsUser: UserModel;
    createdBy: UserModel;
    consumedOn?: Date;
    acceptCode: string;
}

interface RulesetModel {
    id: string;
    appliesTo: string[];
    code: string;
    createdOn: Date;
    deletedOn?: Date;
    description: i18nModel;
    documentation: SupportDocumentModel[];
    lastUpdatedOn: Date;
    name: i18nModel;
    targets: CompanyProcessType[];
}

interface SupportDocumentModel extends BaseSupportDocumentModel {
    id: number;
    appliesTo: CompanyProcessType[];
    createdOn: Date;
    deletedOn?: Date;
    document: SupportDocType;
    isDealBreaker: boolean;
    isRequired: boolean;
    lastUpdatedOn: Date;
    ruleset: RulesetModel;
    submissionLeadTime: number;
}

export interface InvitationCreateRequestObject {
    target: {
        email: string;
        firstName: string;
        lastName: string;
        mobile?: string;
    };
    role?: string;
}
