import { LucideIcon } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { useSidebarStore } from 'src/app/stores/sidebar-store';
import { Button } from 'src/components/ui/button';
import {
    Tooltip,
    TooltipContent,
    TooltipProvider,
    TooltipTrigger,
} from 'src/components/ui/tooltip';
import { cn } from 'src/lib/utils';

interface DefaultButtonProps {
    href: string;
    label: string;
    active: boolean;
    Icon: LucideIcon;
    onClick?: () => void;
}

export function NavigationButton({
    href,
    label,
    active,
    Icon,
    onClick,
}: DefaultButtonProps) {
    const navigate = useNavigate();
    const { open } = useSidebarStore();

    return (
        <div className='w-full'>
            <TooltipProvider disableHoverableContent>
                <Tooltip delayDuration={100}>
                    <TooltipTrigger asChild>
                        <Button
                            variant={active ? 'secondary' : 'ghost'}
                            className='mb-1 h-10 w-full justify-start'
                            asChild
                            onClick={() => {
                                if (onClick) {
                                    onClick();
                                } else {
                                    navigate(href);
                                }
                            }}
                        >
                            <div>
                                <span className={cn('mr-4')}>
                                    <Icon size={18} />
                                </span>
                                <p
                                    className={cn(
                                        'max-w-[200px] truncate',
                                        'translate-x-0 opacity-100'
                                    )}
                                >
                                    {label}
                                </p>
                            </div>
                        </Button>
                    </TooltipTrigger>

                    {!open && (
                        <TooltipContent side='right'>{label}</TooltipContent>
                    )}
                </Tooltip>
            </TooltipProvider>
        </div>
    );
}
