import { useFormContext } from 'react-hook-form';
import {
    FormControl,
    FormField,
    FormItem,
    FormLabel,
    FormMessage,
} from 'src/components/ui/form';
import { RadioGroup, RadioGroupItem } from 'src/components/ui/radio-group';
import { cn } from 'src/lib/utils';

interface RadioProps extends React.HTMLAttributes<HTMLDivElement> {
    name: string;
    label?: string;
    placeholder?: string;
    options: { label: string; value: string }[];
}

export function RadioInput({
    name,
    label,
    placeholder,
    className,
    options,
    ...args
}: RadioProps) {
    const { control } = useFormContext();

    return (
        <>
            <FormField
                control={control}
                name={name}
                render={({ field }) => {
                    return (
                        <FormItem className='flex flex-1 flex-col'>
                            {label && (
                                <FormLabel className='text-xs'>
                                    {label}
                                </FormLabel>
                            )}

                            <FormControl>
                                <RadioGroup
                                    onValueChange={(value) => {
                                        field.onChange(
                                            value === 'true' ? true : false
                                        );
                                    }}
                                    value={field.value ? 'true' : 'false'}
                                    className={cn('flex gap-4 py-1', className)}
                                    defaultValue={field.value}
                                >
                                    {options.map((opt, index) => {
                                        return (
                                            <FormItem className='flex items-center space-x-3 space-y-0'>
                                                <FormControl>
                                                    <RadioGroupItem
                                                        value={opt.value}
                                                        id={opt.label}
                                                    />
                                                </FormControl>
                                                <FormLabel className='font-normal'>
                                                    {opt.label}
                                                </FormLabel>
                                            </FormItem>
                                        );
                                    })}
                                </RadioGroup>
                            </FormControl>

                            <FormMessage />
                        </FormItem>
                    );
                }}
            />
        </>
    );
}
