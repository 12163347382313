import { DateTime } from 'luxon';

export function getDateRangeDescription(
    startDate?: Date,
    endDate?: Date
): string {
    if (!startDate || !endDate) return 'No date range selected';

    const start = DateTime.fromJSDate(startDate);
    const end = DateTime.fromJSDate(endDate);
    const difference = endDate.getTime() - startDate.getTime();
    const isLargeRange = difference > 2 * 30 * 24 * 60 * 60 * 1000; // More than 2 months

    if (isLargeRange) {
        return `${start.toFormat('MMMM yyyy')} - ${end.toFormat('MMMM yyyy')}`;
    } else {
        return `${start.toFormat('MMM d')} - ${end.toFormat('MMM d, yyyy')}`;
    }
}
