import { useMutation, useQueryClient } from '@tanstack/react-query';
import { toast } from 'sonner';
import { useContextStore } from 'src/app/stores/context-store';
import { CompanyUpdateRequestObject } from '../company-update-request-object';
import { useWorkspaceService } from './use-workspace-service';

export function useWorkspaceCompanyUpdate() {
    const { service } = useWorkspaceService();
    const context = useContextStore();
    const client = useQueryClient();

    return useMutation({
        mutationFn: async (body: CompanyUpdateRequestObject) => {
            return await service.updateWorkspaceCompany(
                context.workspace?._id || '',
                body
            );
        },
        onSuccess: (res) => {
            client.invalidateQueries({
                queryKey: ['user-workspaces'],
            });

            toast.success('Company updated successfully');
        },
        onError: (err) => {
            const error = err as Error;

            toast.error(`Failed to update company: ${error.message}`);
        },
    });
}
