import { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import {
    Breadcrumb,
    BreadcrumbItem,
    BreadcrumbLink,
    BreadcrumbList,
    BreadcrumbSeparator,
} from 'src/components/ui/breadcrumb';
import { useBreadcrumbStore } from '../stores/breadcrumb-store';

interface IBreadcrumbItem {
    label: string;
    to: string;
}

export function useBreadcrumbs() {
    const [breadcrumbs, setBreadcrumbs] = useState<IBreadcrumbItem[]>([]);

    const updateBreadcrumbs = useCallback(
        (breadcrumbs: IBreadcrumbItem[]) => {
            setBreadcrumbs(breadcrumbs);
        },
        [setBreadcrumbs]
    );

    return { breadcrumbs, updateBreadcrumbs };
}

export const LocationBreadcrumb = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const { breadcrumbs, setBreadcrumbs } = useBreadcrumbStore();

    const delegateId = searchParams.get('delegateId');

    useEffect(() => {
        const pathnames = location.pathname.split('/').filter((x) => x);

        const path = pathnames[0];
        setBreadcrumbs([
            {
                label: path,
                navigateTo: `/${path}`,
            },
        ]);
    }, [location, setBreadcrumbs]);

    const handleRedirectToDetails = useCallback(
        (to: string) => {
            console.log(to);
            delegateId
                ? navigate(`${to}?delegateId=${delegateId}`)
                : navigate(`${to}`);
        },
        [navigate, delegateId]
    );

    return (
        <div className=''>
            <Breadcrumb className='mb-2'>
                <BreadcrumbList>
                    <BreadcrumbItem>
                        <BreadcrumbLink onClick={() => navigate('/')}>
                            Home
                        </BreadcrumbLink>
                    </BreadcrumbItem>

                    {breadcrumbs.map((breadcrumb) => {
                        return (
                            <>
                                <BreadcrumbSeparator />
                                <BreadcrumbItem>
                                    <BreadcrumbLink
                                        className='cursor-pointer capitalize hover:underline'
                                        onClick={() => {
                                            if (breadcrumb.navigateTo) {
                                                handleRedirectToDetails(
                                                    breadcrumb.navigateTo
                                                );
                                            }
                                        }}
                                    >
                                        {breadcrumb.label}
                                    </BreadcrumbLink>
                                </BreadcrumbItem>
                            </>
                        );
                    })}
                </BreadcrumbList>
            </Breadcrumb>
        </div>
    );
};
