import { useFormContext } from 'react-hook-form';
import {
    PurchaseOrderNature,
    PurchaseOrderNatureLabels,
} from 'src/app/_api_adb2c/purchase/purchase/enums/purchase-order-nature.enum';
import { useSuppliers } from 'src/app/_api_adb2c/purchase/suppliers/hooks/use-suppliers';
import {
    SupplyChainNodeType,
    SupplyChainNodeTypeLabel,
} from 'src/app/_api_adb2c/workspace/shared/enum/supply-chain-node-type.enum';
import { MultiSelectInputV2 } from 'src/app/components/Form/MultiSelectInputV2';
import { SelectInput } from 'src/app/components/Form/SelectInput';
import { TextInput } from 'src/app/components/Form/TextInput';

export const AddPurchaseRequiredFields = () => {
    const context = useFormContext();
    const currencies = ['USD', 'EUR', 'HKD', 'RMB', 'SGB'];

    const delegateId = context.watch('delegateId');

    const { data: suppliers } = useSuppliers(delegateId);
    const selectedSupplier = context.watch('supplier');

    return (
        <>
            <div className='flex w-full gap-4'>
                <SelectInput
                    label='Supplier'
                    name='supplier'
                    options={
                        suppliers?.map((x) => ({
                            label: x.seller.name,
                            value: x.seller._id,
                        })) || []
                    }
                />

                <SelectInput
                    label='Secondary Supplier'
                    name='secondarySupplier'
                    options={
                        suppliers
                            ?.filter((x) => x.seller._id !== selectedSupplier)
                            .map((x) => ({
                                label: x.seller.name,
                                value: x.seller._id,
                            })) || []
                    }
                />
            </div>
            <TextInput
                label='Delivery Address'
                inputName='shipToAddress'
                placeholder='Enter Shipping Address'
            />

            <TextInput
                label='Ref No.'
                inputName='externalDataId'
                placeholder='Enter reference number'
            />

            <SelectInput
                label='Currency'
                name='currency'
                options={currencies.map((x) => ({ label: x, value: x }))}
            />

            <SelectInput
                label='Order Nature (Optional)'
                name='orderNature'
                options={Object.values(PurchaseOrderNature).map((nature) => ({
                    label: PurchaseOrderNatureLabels[nature],
                    value: nature,
                }))}
            />

            <MultiSelectInputV2
                label='Purchase Processes'
                name='purchaseProcesses'
                options={Object.values(SupplyChainNodeType).map((x) => ({
                    label: SupplyChainNodeTypeLabel[x],
                    value: x,
                }))}
            />
        </>
    );
};
