import { useQueryClient } from '@tanstack/react-query';
import { toast } from 'sonner';
import { useCategoryService } from 'src/app/_api_adb2c/product/category/hooks/use-category-service';
import { useCreateProduct } from 'src/app/_api_adb2c/product/product/hooks/use-create-product';
import { useProductService } from 'src/app/_api_adb2c/product/product/hooks/use-product-service';
import {
    IndustryType,
    ProductCategoryModel,
} from 'src/app/_api_adb2c/product/product/models/product.model';
import { ProductUom } from 'src/app/_api_adb2c/purchase/purchase/enums/product-uom.enum';
import { PurchaseOrderNature } from 'src/app/_api_adb2c/purchase/purchase/enums/purchase-order-nature.enum';
import { useCreatePurchase } from 'src/app/_api_adb2c/purchase/purchase/hooks/use-create-purchase';
import { usePurchaseService } from 'src/app/_api_adb2c/purchase/purchase/hooks/use-purchase-service';
import {
    PurchaseModel,
    PurchaseOrderTraceModel,
} from 'src/app/_api_adb2c/purchase/purchase/models/purchase.model';
import { useSupplierService } from 'src/app/_api_adb2c/purchase/suppliers/hooks/use-supplier-service';
import { SupplierModel } from 'src/app/_api_adb2c/purchase/suppliers/models/supplier.model';
import { useSupplierConnect } from 'src/app/_api_adb2c/workspace/account/hooks/use-supplier-connect';
import { useSupplierInvite } from 'src/app/_api_adb2c/workspace/account/hooks/use-supplier-invite';
import { UserCreateDataObject } from 'src/app/_api_adb2c/workspace/users/user-create-data-object';
import { useWorkspaceService } from 'src/app/_api_adb2c/workspace/workspace/hooks/use-workspace-service';
import { WorkspaceModel } from 'src/app/_api_adb2c/workspace/workspace/workspace.model';
import { useContextStore } from 'src/app/stores/context-store';
import { read, utils } from 'xlsx';
import { ImportErrorMessage } from '../Details/purchase-cascade-import-error';
import { useState } from 'react';
import { ProductCategoriesModel } from 'src/app/_api_adb2c/product/category/category.model';
import { useSearchService } from 'src/app/_api_adb2c/workspace/search/hooks/use-search-service';
import countries from 'src/infrastructure/config/data/countries.json';
import { useDelegationService } from 'src/app/_api_adb2c/workspace/delegations/hooks/use-delegation-service';

interface SheetData {
    'PO Factory Name'?: string;
    'PO Factory License Number'?: string;
    'PO Factory Country'?: string;
    'PO Factory Address'?: string;
    'PO Factory Contact Name'?: string;
    'PO Factory Contact Email'?: string;
    'Main Category'?: string;
    'PO Number'?: string;
    'Item Number'?: string;
    'Product Description'?: string;
    'In-House / Sub-Contract / Component / Raw Material': string;
    'Production Process': string;
    Subcategory: string;
    Component: string;
    'Raw Material': string;
    'Factory Name': string;
    'Factory License Number': string;
    'Factory Country': string;
    'Factory Address': string;
    'Factory Contact First Name'?: string;
    'Factory Contact Last Name'?: string;
    'Factory Contact Email'?: string;
    __rowNum__: string;
    [key: string]: string | undefined;
}

enum CustomOrderNature {
    PROCESSING_INHOUSE = 'Production - In-house',
    PROCESSING_OUTSOURCE = 'Production - Outsource',
    COMPONENT = 'Component',
    RAW_MATERIAL = 'Raw Material',
}

const sheetName = 'PPMF Form Section B';

interface SupplierWithExtras extends SupplierModel {
    workspaceId: string;
    supplierId: string;
}

export function useImportAction() {
    const [isLoading, setIsLoading] = useState(false);

    const { service: workspaceService } = useWorkspaceService();
    const { service: purchaseService } = usePurchaseService();
    const { service: categoryService } = useCategoryService();
    const { service: productService } = useProductService();
    const { service: supplierService } = useSupplierService();
    const { service: searchService } = useSearchService();
    const { service: delegationService } = useDelegationService();

    const { mutateAsync: createProduct } = useCreateProduct();
    const { mutateAsync: invite } = useSupplierInvite();
    const { mutateAsync: connect } = useSupplierConnect();
    const { mutateAsync: createPurchase } = useCreatePurchase();

    const delay = (duration: number) =>
        new Promise((resolve) => setTimeout(resolve, duration));

    const store = useContextStore();
    const client = useQueryClient();

    const validateExcel = async (
        items: SheetData[],
        errorMessages: ImportErrorMessage[]
    ) => {
        const uniquePoFactory = Array.from(
            new Set(items.map((item) => item['PO Factory Name']))
        );

        if (uniquePoFactory.length > 1) {
            throw new Error(
                'The PO Factory Name must be the same for all items'
            );
        }

        const requiredFields = [
            'Factory Name',
            'Factory License Number',
            'Factory Country',
            'Factory Address',
            'In-House / Sub-Contract / Component / Raw Material',
        ];

        const validationErrors: string[] = [];

        items.forEach(async (item) => {
            const requiredError = requiredFields.some(
                (field) => !item[field] || item[field] === ''
            );

            if (requiredError) {
                validationErrors.push(
                    `Row ${item.__rowNum__} is missing required fields for ${
                        item['Factory Name']
                    }: ${requiredFields.join(', ')}`
                );

                return;
            }

            if (
                item['PO Factory Name'] &&
                !(
                    item['PO Factory Country'] &&
                    item['PO Factory License Number']
                )
            ) {
                validationErrors.push(
                    `Row ${item.__rowNum__} is missing required fields for ${item['PO Factory Name']}: PO Factory License Number, PO Factory Country`
                );

                return;
            }

            if (
                item['Factory Contact First Name'] &&
                !(
                    item['Factory Contact Last Name'] &&
                    item['Factory Contact Email']
                )
            ) {
                validationErrors.push(
                    `Row ${item.__rowNum__} is missing required field for ${item['Factory Name']}: Factory Contact Last Name`
                );

                return;
            }
        });

        if (validationErrors.length > 0) {
            throw new Error(validationErrors[0]);
        }
    };

    const findOrCreatePartner = async (
        workspaceId: string,
        vendorName: string,
        mode: 'poFactory' | 'factory',
        data: SheetData
        // purchase: PurchaseModel
    ) => {
        const isPartner = mode === 'poFactory';

        const companyName = isPartner
            ? data['PO Factory Name']
            : data['Factory Name'];
        const licenseNumber = isPartner
            ? data['PO Factory License Number']
            : data['Factory License Number'];
        const country = isPartner
            ? data['PO Factory Country']
            : data['Factory Country'];
        const address = isPartner
            ? data['PO Factory Address']
            : data['Factory Address'];

        const countryCode = countries.find(
            (x) => x.name.toLocaleLowerCase() === country?.toLocaleLowerCase()
        )?.['alpha-2'];

        const existingPartner = await findPartner(
            workspaceId,
            vendorName,
            licenseNumber
        );

        if (existingPartner) {
            return existingPartner;
        }

        // Find for workpsace by company name
        const workspaces: WorkspaceModel[] =
            await workspaceService.searchByCompanyName(vendorName, '1');

        let workspace = workspaces.find(
            (x) => x.company?.demographics.name === vendorName
        );

        // Find for workspace by registration
        if (!workspace) {
            workspace = await searchService.searchByRegistrationNumber(
                licenseNumber || '',
                countryCode || '',
                '1'
            );
        }

        if (workspace) {
            const delegation = await delegationService.list(workspaceId, '1');
            const isExist = delegation.find(
                (x) => x.delegatedTo._id === workspace?._id
            );

            if (!isExist) {
                await delegationService.create(
                    workspaceId,
                    {
                        delegatedTo: workspace._id,
                    },
                    '1'
                );
            }

            await connect({
                delegate: workspaceId,
                supplier: workspace._id,
                delegateId: workspaceId,
                diversion: '1',
            });
        } else {
            const contact: UserCreateDataObject[] = [];

            if (store.user) {
                contact.push({
                    contact: {
                        email: store.user.contact.email,
                        firstName: store.user.contact.firstName,
                        lastName: store.user.contact.lastName,
                        device: {},
                    },
                });
            }

            if (isPartner && data['PO Factory Contact Name']) {
                const [firstName, lastName] = data['PO Factory Contact Name']
                    ?.toString()
                    ?.trim()
                    .split(' ');

                contact.push({
                    contact: {
                        email:
                            data['PO Factory Contact Email']
                                ?.toString()
                                ?.trim() || '',
                        firstName: firstName,
                        lastName: lastName,
                        device: {},
                    },
                });
            }

            if (!isPartner && data['Factory Contact First Name']) {
                contact.push({
                    contact: {
                        email:
                            data['Factory Contact Email']?.toString()?.trim() ||
                            '',
                        firstName: data['Factory Contact First Name']
                            ?.toString()
                            ?.trim(),
                        lastName:
                            data['Factory Contact Last Name']
                                ?.toString()
                                ?.trim() || '',
                        device: {},
                    },
                });
            }

            await invite({
                body: {
                    company: companyName?.toString()?.trim() || '',
                    registrationNumber: licenseNumber?.toString()?.trim() || '',
                    country: country?.toString()?.trim() || '',
                    contact: contact.length > 0 ? contact : undefined,
                    address: address?.toString()?.trim() || '',
                },
                delegateId: workspaceId,
                diversion: '1',
            });
        }

        await delay(2000);

        const partner = await findPartner(workspaceId, vendorName);

        if (!partner) {
            throw new Error(`Failed to create partner: ${vendorName}`);
        }

        return partner;
    };

    const findPartner = async (
        workspaceId: string,
        name: string,
        registrationNumber?: string
    ) => {
        const partners: SupplierModel[] = await supplierService.list(
            workspaceId,
            '1'
        );

        const existingPartner = partners.find((partner) => {
            return (
                partner.seller?.name
                    ?.toLocaleLowerCase()
                    ?.toString()
                    ?.trim() ===
                    name?.toLocaleLowerCase()?.toString()?.trim() ||
                partner.seller?.registrationNumber?.toLocaleLowerCase() ===
                    registrationNumber?.toLocaleLowerCase()?.toString()?.trim()
            );
        });

        console.log(
            `test-ppmf: existingPartner = ${JSON.stringify(existingPartner)}`
        );

        const response = existingPartner
            ? {
                  ...existingPartner,
                  workspaceId: existingPartner?.seller?._id || '',
                  supplierId: existingPartner?._id || '',
              }
            : undefined;

        console.log(`test-ppmf: response = ${JSON.stringify(response)}`);

        return response;
    };

    const submit = (workspace: string, purchaseId: string, file: File) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            setIsLoading(true);

            reader.readAsArrayBuffer(file);

            reader.onload = async (e) => {
                try {
                    const buffer = e.target?.result as ArrayBuffer;
                    const wb = read(buffer, { type: 'array', cellDates: true });

                    const ws = wb.Sheets[sheetName];
                    const sheetData: SheetData[] = utils.sheet_to_json(ws);

                    const filteredSheetData = sheetData.filter(
                        (data) => data['Factory Name']
                    );

                    const errorMessages: ImportErrorMessage[] = [];
                    await validateExcel(filteredSheetData, errorMessages);

                    if (errorMessages.length > 0) {
                        reject(errorMessages);

                        return;
                    }

                    await prepareExcel(
                        workspace,
                        purchaseId,
                        filteredSheetData
                    );

                    client.invalidateQueries({
                        queryKey: ['purchase-trace'],
                    });

                    client.invalidateQueries({
                        queryKey: ['purchase'],
                    });

                    resolve(filteredSheetData);

                    toast.success(
                        'Successfully imported the template, please refresh the page'
                    );
                } catch (err) {
                    const error = err as Error;
                    toast.error(
                        `Failed to import the template: ${error.message}`
                    );

                    reject(err);
                } finally {
                    setIsLoading(false);
                }
            };
        });
    };

    const findOrCreateCategory = async (
        id: string,
        item?: SheetData,
        parentCode?: string,
        parentWorkspace?: string
    ) => {
        let parentCategory: ProductCategoryModel | undefined;
        let categoryName = item?.Subcategory || 'Placeholder Category';

        if (parentCode && parentWorkspace) {
            const parentProduct = await productService.get(
                parentWorkspace,
                parentCode,
                '1'
            );

            if (parentProduct) {
                parentCategory = parentProduct?.category;
                categoryName = parentCategory?.name || categoryName;
            }
        }

        const existingCategories = await categoryService.search(
            parentWorkspace || id,
            encodeURIComponent(parentCategory?.code || categoryName),
            '1'
        );
        let category: ProductCategoriesModel;

        if (existingCategories.length === 0) {
            category = await categoryService.create(
                id,
                {
                    code: parentCategory?.code || categoryName,
                    defaultUnitOfMeasurement:
                        parentCategory?.defaultUnitOfMeasurement ||
                        ProductUom.PCS,
                    name: parentCategory?.name || categoryName,
                    description: parentCategory?.description || '',
                    industry: IndustryType.HARDGOODS,
                    workspace: parentWorkspace || '',
                },
                '1'
            );
        } else {
            category = existingCategories[0];
        }

        return category;
    };

    const findOrCreateProduct = async (
        workspaceId: string,
        item: SheetData,
        categoryId: string
    ) => {
        let parsedName = '';
        const orderNature = item[
            'In-House / Sub-Contract / Component / Raw Material'
        ] as CustomOrderNature;

        const nameMapping = {
            [CustomOrderNature.COMPONENT]: 'Component',
            [CustomOrderNature.RAW_MATERIAL]: 'Raw Material',
            [CustomOrderNature.PROCESSING_OUTSOURCE]: 'Production Process',
            [CustomOrderNature.PROCESSING_INHOUSE]: 'Production Process',
        };

        if (
            orderNature === CustomOrderNature.PROCESSING_INHOUSE ||
            orderNature === CustomOrderNature.PROCESSING_OUTSOURCE
        ) {
            parsedName = `Service - ${item['Production Process']}`;
        } else {
            const mapping = nameMapping[orderNature];
            parsedName = (item[mapping] as string) || '';
        }

        let product = await productService.searchByName(
            workspaceId,
            parsedName.toLocaleUpperCase(),
            '1'
        );

        if (!product) {
            product = await createProduct({
                delegateId: workspaceId,
                body: {
                    category: categoryId,
                    externalReferences: item['Item Number']
                        ? [
                              {
                                  source: 'externalDataId',
                                  value: item['Item Number'],
                              },
                          ]
                        : [],
                    name: parsedName.toLocaleUpperCase(),
                    description: item['Product Description'] || '',
                    unitOfMeasurement: ProductUom.PCS,
                    loaderType: store.workspace?.supplyChainLoaderType || 0,
                    enabled: true,
                    tags: [],
                    workspace: workspaceId,
                    measureValue: 0,
                },
                diversion: '1',
            });

            await delay(2000);
        }

        return product;
    };

    const createPurchaseOrder = async (
        workspaceId: string,
        supplierId: string,
        item: SheetData,
        product: { id: string; cost: number },
        purchaseOrder: PurchaseModel,
        parentPurchase?: PurchaseModel,
        type: 'poFactory' | 'factory' = 'factory'
    ) => {
        const getCustomNature = (orderNature: string) => {
            switch (orderNature) {
                case 'Component':
                    return PurchaseOrderNature.COMPONENT;
                case 'Raw Material':
                    return PurchaseOrderNature.RAW_MATERIAL;
                case 'Production - In-house':
                    return PurchaseOrderNature.PROCESS;
                case 'Production - Outsource':
                    return PurchaseOrderNature.OUTSOURCE;
                default:
                    return PurchaseOrderNature.COMPONENT;
            }
        };

        const purchase = await createPurchase({
            body: {
                supplier: supplierId,
                currency: parentPurchase?.currency || 'USD',
                items: [
                    {
                        product: product.id,
                        ppu: product.cost,
                        quantity: 1,
                        unit: ProductUom.PCS,
                    },
                ],
                rules: purchaseOrder.rules?.[0]
                    ? [purchaseOrder.rules?.[0]]
                    : [],
                nature:
                    type === 'poFactory'
                        ? purchaseOrder.nature
                        : getCustomNature(
                              item[
                                  'In-House / Sub-Contract / Component / Raw Material'
                              ]
                          ),
                shipToAddress: 'Filler Address',
                parent: parentPurchase?._id,
            },
            delegateId: workspaceId,
            diversion: '1',
        });

        const version = await purchaseService.get(
            workspaceId,
            purchase.id,
            '1'
        );

        return version;
    };

    const prepareExcel = async (
        workspaceId: string,
        purchaseId: string,
        items: SheetData[]
    ) => {
        const purchase: PurchaseModel = await purchaseService.get(
            workspaceId,
            purchaseId,
            '1'
        );

        let parentPurchaseOrder: PurchaseModel = purchase;
        let poFactoryPartner: SupplierWithExtras | undefined;

        // Check if there is a PO Factory Name provided.
        const poFactoryItem = items.find((x) => x['PO Factory Name']);

        if (poFactoryItem) {
            poFactoryPartner = await findOrCreatePartner(
                // workspace?._id || '',
                purchase.supplier.seller._id,
                poFactoryItem['PO Factory Name']?.toString()?.trim() || '',
                'poFactory',
                poFactoryItem
            );

            const traces: PurchaseOrderTraceModel[] =
                await purchaseService.trace(workspaceId, purchaseId, '1');

            // Check if there are any traces for the PO yet.
            if (traces.length > 0) {
                parentPurchaseOrder = await purchaseService.get(
                    traces?.[0].workspace || '',
                    traces?.[0]._id || '',
                    '1'
                );
            } else {
                // Create the PO Factory
                const purchaseItem = purchase.versions[0].manifest[0];
                const product = await productService.get(
                    purchase.supplier.workspace._id,
                    purchaseItem.product._id,
                    '1'
                );

                // Create the Parent Category (Clone)
                let existingCategories = await categoryService.search(
                    purchase.supplier.seller._id,
                    product?.category?.code || '',
                    '1'
                );
                let category: ProductCategoriesModel;

                if (existingCategories.length === 0) {
                    category = await categoryService.create(
                        purchase.supplier.seller._id,
                        {
                            code: product?.category?.code || '',
                            defaultUnitOfMeasurement:
                                product?.category?.defaultUnitOfMeasurement ||
                                ProductUom.PCS,
                            name:
                                product?.category?.name ||
                                product?.category?.code ||
                                '',
                            description: product?.category?.description || '',
                            industry: IndustryType.HARDGOODS,
                            workspace: purchase.supplier.seller._id,
                        },
                        '1'
                    );
                } else {
                    category = existingCategories[0];
                }

                // Create the Parent Product
                let newProduct = await productService.search(
                    purchase.supplier.seller._id,
                    product?.name || '',
                    '1'
                );

                if (!newProduct._id) {
                    newProduct = await productService.create(
                        purchase.supplier.seller._id,
                        {
                            category: category._id,
                            externalReferences:
                                product?.externalReferences || [],
                            name: product?.name || '',
                            description: product?.description || '',
                            unitOfMeasurement: product?.unit || ProductUom.PCS,
                            loaderType: 1,
                            enabled: true,
                            tags: [],
                            workspace: purchase.supplier.seller._id,
                            measureValue: product.measureValue,
                        },
                        '1'
                    );
                }

                await delay(1500);

                // Create the Parent Purchase Order
                parentPurchaseOrder = await purchaseService.create(
                    purchase.supplier.seller._id,
                    {
                        supplier: poFactoryPartner.workspaceId,
                        currency: purchase.currency,
                        items: [
                            {
                                product: newProduct._id,
                                comment: `Cloned - ${newProduct.description}`,
                                quantity: purchaseItem.quantity,
                                ppu: purchaseItem.ppu,
                                unit: ProductUom.PCS,
                            },
                        ],
                        rules: purchase.rules || [],
                        nature: purchase.nature,
                        shippedOn: purchase.shippedOn,
                        shipToAddress: purchase.shipToAddress,
                        reference: purchase.reference,
                        parent: purchase._id,
                    },
                    '1'
                );
            }
        }

        for (let index = 0; index < items.length; index++) {
            console.log(`test-ppmf: index = ${index}`);

            const item = items[index];
            console.log(`test-ppmf: item = ${JSON.stringify(item)}`);

            const workspaceId = poFactoryPartner
                ? poFactoryPartner.workspaceId
                : store.workspace?._id || '';

            const factoryPartner = await findOrCreatePartner(
                workspaceId,
                item['Factory Name'] || '',
                'factory',
                item
            );
            delay(250);

            console.log(
                `test-ppmf: workspaceId = ${JSON.stringify(workspaceId)}`
            );

            const category = await findOrCreateCategory(workspaceId, item);

            // Creates the product with category info into the Factory Workspace
            const product = await findOrCreateProduct(
                workspaceId,
                item,
                category._id || ''
            );
            await delay(750);

            await createPurchaseOrder(
                workspaceId,
                factoryPartner.workspaceId,
                item,
                {
                    id: product._id || '',
                    cost: Number(product.versions?.[0]?.costOfMaterials || 0),
                },
                parentPurchaseOrder,
                parentPurchaseOrder,
                'factory'
            );
        }
    };

    return { submit, isLoading };
}
