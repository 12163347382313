import { useMutation, useQueryClient } from '@tanstack/react-query';
import { toast } from 'sonner';
import { useContextStore } from 'src/app/stores/context-store';
import { LINKS_QUERY_KEY } from './links-query-keys';
import { useLinkService } from './use-link-service';
import { SALES_ORDERS_QUERY_KEY } from 'src/app/_api_adb2c/sales/sales/hooks/sales-order-query-keys';

export function useRemoveLink() {
    const { service } = useLinkService();
    const client = useQueryClient();
    const context = useContextStore();

    const delay = (ms: number) => new Promise((res) => setTimeout(res, ms));

    return useMutation({
        mutationFn: async ({
            delegateId,
            orderId,
            purchaseId,
            diversion = '0',
        }: {
            delegateId?: string;
            orderId: string;
            purchaseId: string;
            diversion?: string;
        }) => {
            return await service.removeLink(
                delegateId || context.workspace?._id || '',
                orderId,
                purchaseId,
                diversion
            );
        },
        onSuccess: async (_, { delegateId }) => {
            await delay(1000);
            client.invalidateQueries(
                LINKS_QUERY_KEY(delegateId || context.workspace?._id || '')
            );

            client.invalidateQueries(
                SALES_ORDERS_QUERY_KEY(
                    delegateId || context.workspace?._id || ''
                )
            );

            toast.success('Successfully removed links');
        },
        onError: (err) => {
            const error = err as Error;

            toast.error(`Failed to remove links: ${error.message}`);
        },
    });
}
