import { useMutation, useQueryClient } from '@tanstack/react-query';
import { toast } from 'sonner';
import { SUPPLIERS_QUERY_KEY } from 'src/app/_api_adb2c/purchase/suppliers/hooks/supplier-query-keys';
import { useContextStore } from 'src/app/stores/context-store';
import { DELEGATIONS_QUERY_KEY } from '../../delegations/hooks/delegation-query-keys';
import { useAccountService } from './use-account-service';

export function useSupplierConnect() {
    const { service } = useAccountService();
    const context = useContextStore();
    const client = useQueryClient();

    return useMutation({
        mutationFn: async ({
            delegate,
            supplier,
            delegateId,
            diversion = '0',
        }: {
            delegateId?: string;
            supplier: string;
            delegate: string;
            diversion?: string;
        }) => {
            return await service.connect(
                delegateId || context.workspace?._id || '',
                supplier,
                delegate,
                diversion
            );
        },
        onSuccess: (_, { delegateId }) => {
            client.invalidateQueries(
                SUPPLIERS_QUERY_KEY(delegateId || context.workspace?._id || '')
            );

            client.invalidateQueries({
                queryKey: DELEGATIONS_QUERY_KEY(
                    delegateId || context.workspace?._id || ''
                ),
            });

            toast.success('Supplier connected successfully');
        },
        onError: (err) => {
            const error = err as Error;

            toast.error(`Failed to connect supplier: ${error.message}`);
        },
    });
}
