import { useSearchParams } from 'react-router-dom';
import { useMaterial } from 'src/app/_api_adb2c/product/material/hooks/use-material';
import { useUpdateMaterial } from 'src/app/_api_adb2c/product/material/hooks/use-update-material';
import { ProductMaterialDataObject } from 'src/app/_api_adb2c/product/material/request/product-material-data-object';
import { RemoveDialog } from 'src/app/pages-v2/Purchases/Details/delete-purchase';
import { VirtualComposition } from '..';

interface Props {
    open: boolean;
    onClose: () => void;
    materialId: string;
    composition: VirtualComposition;
}

export function DeleteSubstance({
    open,
    onClose,
    materialId,
    composition,
}: Props) {
    const [searchParams] = useSearchParams();
    const delegateId = searchParams.get('delegateId') || '';

    const { data: material } = useMaterial(materialId, delegateId);

    const { mutateAsync: update, isLoading } = useUpdateMaterial();
    const remove = async () => {
        if (!material) return;

        const request: ProductMaterialDataObject = { ...material };

        const updatedCompositions = (request.compositions || []).filter(
            (comp) => comp._id !== composition._id
        );

        await update({
            id: materialId,
            delegateId,
            body: {
                ...request,
                compositions: updatedCompositions,
            },
        });

        onClose();
    };

    if (!material) return <></>;

    return (
        <RemoveDialog
            open={open}
            title='Delete Substance'
            isLoading={isLoading}
            description='Are you sure you want to remove this substance?'
            onClose={onClose}
            onRemove={remove}
        ></RemoveDialog>
    );
}
