import { useQuery } from '@tanstack/react-query';
import { toast } from 'sonner';
import { useContextStore } from 'src/app/stores/context-store';
import { PURCHASES_QUERY_KEY } from './purchase-query-keys';
import { usePurchaseService } from './use-purchase-service';
import { PaginatedResponse } from '../../purchase-item/purchaseable.model';
import { PurchaseModel } from '../models/purchase.model';

interface QueryListParams {
    draft: boolean;
    page: number;
    limit: number;
}

export function usePurchases(
    queryParams: QueryListParams = {
        draft: true,
        page: 0,
        limit: 10,
    },
    delegateId?: string
) {
    const context = useContextStore();
    const { service } = usePurchaseService();

    return useQuery<PaginatedResponse<PurchaseModel>>({
        queryKey: PURCHASES_QUERY_KEY(
            delegateId || context.workspace?._id || ''
        ),
        queryFn: async () => {
            return await service.list(
                delegateId || context.workspace?._id || '',
                queryParams.draft,
                queryParams.page,
                queryParams.limit
            );
        },
        onError: (err) => {
            const error = err as Error;
            toast.error(`Failed to fetch purchases: ${error.message}`);
        },
    });
}
