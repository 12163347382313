import { ServiceConfigInterface } from 'src/domain/interfaces/config.context.interface';
import { ApiServer } from 'src/infrastructure/clients/api.server';

export class SolicitApiClient extends ApiServer {
    constructor(config: ServiceConfigInterface, token: string) {
        super(token, config, 'workspaces', 'solicit');
    }

    solicitDocuments(workspace: string, partner: string) {
        console.log(`client<solicit> solicitDocuments(): Enter`);
        console.log(
            `client<solicit> solicitDocuments(): workspace: ${workspace}`
        );
        console.log(`client<solicit> solicitDocuments(): partner: ${partner}`);

        return super.get('list', workspace, partner);
    }
}
