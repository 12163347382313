import { useQuery } from '@tanstack/react-query';
import { toast } from 'sonner';
import { useContextStore } from 'src/app/stores/context-store';
import { ProductApprovalRequest } from '../model/product-approval-request';
import { PRODUCT_APPROVAL_BY_WORKSPACE_ITEM_QUERY_KEY } from './product-approval-query-keys';
import { useProductApprovalService } from './use-product-approval-service';

export function useProductApprovalByWorkspaceItem(
    item: string,
    delegateId?: string
) {
    const { service } = useProductApprovalService();
    const context = useContextStore();

    return useQuery<ProductApprovalRequest>({
        queryKey: PRODUCT_APPROVAL_BY_WORKSPACE_ITEM_QUERY_KEY(
            delegateId || context.workspace?._id || '',
            item
        ),
        queryFn: async () => {
            return await service.getByWorkspaceItem(
                delegateId || context.workspace?._id || '',
                item
            );
        },
        onError: (err) => {
            const error = err as Error;
            toast.error(`Failed to fetch product approvals: ${error.message}`);
        },
    });
}
