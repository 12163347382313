import { useContextStore } from 'src/app/stores/context-store';
import { useProductStatisticsService } from './use-product-statistics-service';
import { useQuery } from '@tanstack/react-query';
import { PRODUCT_STATISTICS_REPORT_BY_CATEGORY_QUERY_KEY } from './product-statistic-query-key';
import { toast } from 'sonner';

interface ProductReportModel {
    _id: string;
    count: number;
    category?: string;
}

export function useProductReportByCategory(
    start: string,
    end: string,
    delegateId?: string
) {
    const { service } = useProductStatisticsService();
    const context = useContextStore();

    return useQuery<ProductReportModel[]>({
        queryKey: PRODUCT_STATISTICS_REPORT_BY_CATEGORY_QUERY_KEY(
            delegateId || context.workspace?._id || '',
            start,
            end
        ),
        queryFn: async () => {
            return await service.reportByCategory(
                delegateId || context.workspace?._id || '',
                start,
                end
            );
        },
        onError: (err) => {
            const error = err as Error;

            toast.error(
                `Failed to fetch product report by category: ${error.message}`
            );
        },
        enabled: !!start && !!end,
    });
}
