import { ServiceConfigInterface } from 'src/domain/interfaces/config.context.interface';
import { ApiServer } from 'src/infrastructure/clients/api.server';
import { RulesetCreateDataObject } from './ruleset-create-data-object';
import { RulesetUpdateDataObject } from './ruleset-update-data-object';

export class RulesetApiClient extends ApiServer {
    constructor(config: ServiceConfigInterface, token: string) {
        super(token, config, 'workspaces', 'ruleset');
    }

    create(workspace: string, body: RulesetCreateDataObject) {
        console.log(`service<ruleset>| create(): Enter`);
        console.log(`service<ruleset>| create(): $workspace = ${workspace}`);
        console.log(
            `service<ruleset>| create(): $body = ${JSON.stringify(body)}`
        );

        return super.post('create', workspace, body);
    }

    update(workspace: string, item: string, body: RulesetUpdateDataObject) {
        console.log(`service<ruleset>| update(): Enter`);
        console.log(`service<ruleset>| update(): $workspace = ${workspace}`);
        console.log(`service<ruleset>| update(): $item = ${item}`);
        console.log(
            `service<ruleset>| update(): $body = ${JSON.stringify(body)}`
        );

        return super.patch('update', workspace, item, body);
    }

    list(workspace: string) {
        console.log(`service<ruleset>| list(): Enter`);
        console.log(`service<ruleset>| list(): $workspace = ${workspace}`);

        return super.get('list', workspace);
    }

    get(workspace: string, item: string) {
        console.log(`service<ruleset>| get(): Enter`);
        console.log(`service<ruleset>| get(): $workspace = ${workspace}`);
        console.log(`service<ruleset>| get(): $item = ${item}`);

        return super.get('get', workspace, item);
    }

    remove(workspace: string, item: string) {
        console.log(`service<ruleset>| remove(): Enter`);
        console.log(`service<ruleset>| remove(): $workspace = ${workspace}`);
        console.log(`service<ruleset>| remove(): $item = ${item}`);

        return super.delete('delete', workspace, item);
    }

    batch(body: { targets: string[] }) {
        console.log(`service<ruleset>| batch(): Enter`);
        console.log(`service<ruleset>| batch(): $body = ${body}`);

        return super.post('batch', body);
    }
}
