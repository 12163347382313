import { zodResolver } from '@hookform/resolvers/zod';
import { useCallback, useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';
import { useBaseCategories } from 'src/app/_api_adb2c/product/category/hooks/use-base-categories';
import { useCategories } from 'src/app/_api_adb2c/product/category/hooks/use-categories';
import { useProduct } from 'src/app/_api_adb2c/product/product/hooks/use-product';
import { useUpdateProduct } from 'src/app/_api_adb2c/product/product/hooks/use-update-product';
import { IExternalReference } from 'src/app/_api_adb2c/product/product/models/product.model';
import { ProductVersionCreateDataObject } from 'src/app/_api_adb2c/product/product/requests/product-version-create-data-object';
import { DialogV2 } from 'src/app/components-v2/dialog-v2';
import { z } from 'zod';
import { AddProductOptionalFields } from './add-product-optional-fields';
import { AddProductRequiredFields } from './add-product-required-fields';

interface Props {
    product: string;
    open: boolean;
    onClose: () => void;
}

const schema = z.object({
    name: z.string(),
    cost: z.coerce.number(),
    collection: z.string().optional(),
    description: z.string().optional(),
    enabled: z.boolean().optional(),
    hsCode: z.string().optional(),
    sku: z.string().optional(),
    tags: z.array(z.string()).optional(),
    unit: z.string().optional(),
    upc: z.string().optional(),
    measureValue: z.number().optional(),
    externalDataId: z.string().optional(),
    externalReferenceCode: z.string().optional(),
    moqCode: z.string().optional(),
    weight: z.coerce.number().optional(),
    color: z.string().optional(),
    isActive: z.boolean().optional(),
    base: z.string(),
    category: z.string(),
});

export function EditProduct({ product, open, onClose }: Props) {
    const [searchParams] = useSearchParams();
    const delegateId = searchParams.get('delegateId') || '';

    const { data } = useProduct(product, delegateId);
    const { data: categories } = useCategories(delegateId);
    const { data: baseCategories } = useBaseCategories();

    const { mutateAsync: update, isLoading } = useUpdateProduct();

    const form = useForm<z.infer<typeof schema>>({
        mode: 'onChange',
        resolver: zodResolver(schema),
    });

    const onSubmit = async (params: z.infer<typeof schema>) => {
        if (!data) return;
        const externalReferences: IExternalReference[] = [];

        if (params.externalDataId) {
            externalReferences.push({
                source: 'externalDataId',
                value: params.externalDataId,
            });
        }

        if (params.externalReferenceCode) {
            externalReferences.push({
                source: 'customer',
                value: params.externalReferenceCode,
            });
        }

        if (params.moqCode) {
            externalReferences.push({
                source: 'moqCode',
                value: params.moqCode,
            });
        }

        const latestVersion = data.versions?.[data.versions.length - 1];

        const version: ProductVersionCreateDataObject = {
            billOfMaterials: (latestVersion?.billOfMaterials || [])?.map(
                (x) => {
                    return {
                        ...x,
                        materialCost: x.materialCost || 0,
                    };
                }
            ),
            costOfMaterials: latestVersion?.costOfMaterials || 0,
            // specifications: {
            //     weight: params.weight,
            // },
            version: (latestVersion?.version || 0) + 1,
        };

        await update({
            id: product,
            body: {
                category: params.category,
                collection: params.collection,
                color: params.color,
                description: params.description,
                enabled: params.enabled,
                externalReferences: externalReferences,
                hsCode: params.hsCode,
                name: params.name,
                sku: params.sku,
                tags: params.tags,
                unitOfMeasurement: params.unit,
                upc: params.upc,
                measureValue: params.measureValue,
                versions: [version],
            },
        });

        reset();
    };

    const reset = () => {
        form.reset();
        onClose();
    };

    const existingCategory = useMemo(() => {
        let category: string | undefined;
        let mode;

        category = categories?.find((x) => x._id === data?.category?._id)?._id;
        mode = 'custom';

        if (!category) {
            const baseCategory = baseCategories?.find(
                (x) => x._id === data?.category?._id
            );

            category = baseCategory?._id;
            mode = baseCategory?.base?.name?.locales?.[0]?.text || '';
        }

        return {
            category,
            mode,
        };
    }, [categories, baseCategories, data]);

    const resetForm = useCallback(() => {
        if (!form || !data) return;

        const latestVersion = data.versions?.[data.versions.length - 1];

        const externalDataId = data.externalReferences?.find(
            (x) => x.source === 'externalDataId'
        )?.value;

        const externalReferenceCode = data.externalReferences?.find(
            (x) => x.source === 'customer'
        )?.value;

        const moqCode = data.externalReferences?.find(
            (x) => x.source === 'moqCode'
        )?.value;

        form.reset({
            name: data.name,
            cost: latestVersion?.costOfMaterials || 0,
            collection: data.collection,
            description: data.description,
            enabled: data.enabled,
            hsCode: data.hsCode,
            sku: data.sku,
            tags: data.tags,
            unit: data.unitOfMeasurement,
            upc: data.upc || '',
            measureValue: data.measureValue,
            weight: latestVersion?.specifications?.weight,
            color: data.color,
            externalDataId: externalDataId,
            externalReferenceCode: externalReferenceCode,
            moqCode: moqCode,
            isActive: data.enabled,
            base: existingCategory.mode,
            category: existingCategory.category,
        });
    }, [form, data, existingCategory]);

    useEffect(() => {
        resetForm();
    }, [resetForm]);

    return (
        <DialogV2
            open={open}
            onClose={reset}
            form={form}
            onSubmit={onSubmit}
            title='Edit Product'
            isLoading={isLoading}
            isStepDialog
            steps={[
                {
                    title: 'Required Fields',
                    description: 'Enter the required fields for this product.',
                    content: <AddProductRequiredFields />,
                },
                {
                    title: 'Optional Fields',
                    description: 'Enter the optional fields for this product.',
                    content: <AddProductOptionalFields />,
                },
            ]}
        />
    );
}
