import { CaretSortIcon } from '@radix-ui/react-icons';
import { ColumnDef } from '@tanstack/react-table';
import { Check, Download, X } from 'lucide-react';
import { useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { WorkspaceModel } from 'src/app/_api_adb2c/purchase/suppliers/models/supplier.model';
import {
    SupportDocType,
    SupportDocTypeLabel,
} from 'src/app/_api_adb2c/shared/support-doc-type.enum';
import { useSolicitDocuments } from 'src/app/_api_adb2c/workspace/solicit/hooks/use-solicit-documents';
import { Button } from 'src/components/ui/button';
import { Checkbox } from 'src/components/ui/checkbox';
import { useDownload } from '../../Materials/Details/Substance';
import { ReusableTable } from '../../Purchases/reusable-table';

interface Props {
    supplier?: WorkspaceModel;
}

interface VirtualDocumentModel {
    file: string;
    name: string;
    type: string;
    isPrivate: boolean;
    isVerified: boolean;
}

export const SupplierDocuments = ({ supplier }: Props) => {
    const [searchParams] = useSearchParams({});
    const delegateId = searchParams.get('delegateId') || '';

    const { data } = useSolicitDocuments(supplier?._id || '', delegateId);

    const { download } = useDownload();

    const memoizedData = useMemo(() => {
        if (!data?.documents) return [];

        return data.documents.map((doc, index) => {
            const extension = data.extensions?.[index];

            return {
                file: doc.file,
                name: extension.name,
                isVerified: doc?.isVerified || false,
                isPrivate: doc?.isPrivate || false,
                type: doc.type,
            } satisfies VirtualDocumentModel;
        });
    }, [data]);

    const columns: ColumnDef<VirtualDocumentModel>[] = [
        {
            id: 'select',
            header: ({ table }) => (
                <Checkbox
                    checked={table.getIsAllPageRowsSelected()}
                    onCheckedChange={(value) =>
                        table.toggleAllPageRowsSelected(!!value)
                    }
                    aria-label='Select all'
                />
            ),
            cell: ({ row }) => (
                <Checkbox
                    checked={row.getIsSelected()}
                    onCheckedChange={(value) => row.toggleSelected(!!value)}
                    aria-label='Select row'
                />
            ),
            enableSorting: false,
            enableHiding: false,
            size: 0,
        },
        {
            id: 'document name',
            accessorKey: 'documentName',
            header: ({ column }) => {
                return (
                    <Button
                        variant='ghost'
                        className='text-xs'
                        onClick={() =>
                            column.toggleSorting(column.getIsSorted() === 'asc')
                        }
                    >
                        <span>Document Name</span>
                        <CaretSortIcon
                            className={`inline ${
                                column.getIsSorted() === 'asc'
                                    ? 'rotate-180 transform'
                                    : ''
                            }`}
                        />
                    </Button>
                );
            },
            accessorFn: (row) => row.name,
        },
        {
            id: 'type',
            accessorKey: 'type',
            header: ({ column }) => {
                return (
                    <Button
                        variant='ghost'
                        className='text-xs'
                        onClick={() =>
                            column.toggleSorting(column.getIsSorted() === 'asc')
                        }
                    >
                        <span>Type</span>
                        <CaretSortIcon
                            className={`inline ${
                                column.getIsSorted() === 'asc'
                                    ? 'rotate-180 transform'
                                    : ''
                            }`}
                        />
                    </Button>
                );
            },
            accessorFn: (row) =>
                SupportDocTypeLabel[row.type as SupportDocType],
        },
        {
            id: 'Private',
            accessorKey: 'Private',
            header: ({ column }) => {
                return (
                    <Button
                        variant='ghost'
                        className='text-xs'
                        onClick={() =>
                            column.toggleSorting(column.getIsSorted() === 'asc')
                        }
                    >
                        <span>Private</span>
                        <CaretSortIcon
                            className={`inline ${
                                column.getIsSorted() === 'asc'
                                    ? 'rotate-180 transform'
                                    : ''
                            }`}
                        />
                    </Button>
                );
            },
            cell: ({ row }) => {
                return (
                    <span className=''>
                        {row.original.isPrivate ? (
                            <Check size={16} />
                        ) : (
                            <X size={16} />
                        )}
                    </span>
                );
            },
        },
        {
            id: 'verified on',
            accessorKey: 'verified on',
            header: ({ column }) => {
                return (
                    <Button
                        variant='ghost'
                        className='text-xs'
                        onClick={() =>
                            column.toggleSorting(column.getIsSorted() === 'asc')
                        }
                    >
                        <span>Verified</span>
                        <CaretSortIcon
                            className={`inline ${
                                column.getIsSorted() === 'asc'
                                    ? 'rotate-180 transform'
                                    : ''
                            }`}
                        />
                    </Button>
                );
            },
            cell: ({ row }) => {
                return (
                    <span className=''>
                        {row.original.isVerified ? (
                            <Check size={16} />
                        ) : (
                            <X size={16} />
                        )}
                    </span>
                );
            },
        },
        {
            id: 'actions',
            header: 'Actions',
            cell: ({ row }) => {
                return (
                    <div>
                        <Download
                            size={16}
                            className='cursor-pointer hover:scale-125'
                            onClick={() => {
                                download(row.original.file, row.original.name);
                            }}
                        />
                    </div>
                );
            },
        },
    ];

    return (
        <>
            <div className='flex h-full flex-col'>
                <ReusableTable
                    title='Documents'
                    columns={columns}
                    data={memoizedData || []}
                    loading={false}
                    controls={{
                        enableDelegation: false,
                        enableSearch: false,
                    }}
                    actions={[]}
                />
            </div>
        </>
    );
};
