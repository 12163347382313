import { Edit } from 'lucide-react';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Button } from 'src/components/ui/button';
import {
    Dialog,
    DialogContent,
    DialogFooter,
    DialogHeader,
    DialogPortal,
    DialogTrigger,
} from 'src/components/ui/dialog';
import { Label } from 'src/components/ui/label';
import {
    Select,
    SelectContent,
    SelectItem,
    SelectTrigger,
    SelectValue,
} from 'src/components/ui/select';
import { useUpdatePurchase } from '../../../../_api/purchase/purchase-order/hooks/use-update-purchase';
import { PurchaseOrderVersionModel } from '../../../../_api/purchase/purchase-order/purchase.model';
import { useRulesets } from '../../../../_api/workspace/rulesets/hooks/use-rulesets';

type Props = {
    order?: PurchaseOrderVersionModel;
};

export const PurchaseDocumentRuleset: React.FC<Props> = ({ order }) => {
    const [searchParams] = useSearchParams();
    const [open, setOpen] = useState(false);
    const [ruleset, setRuleset] = useState('');

    const delegateId = searchParams.get('delegateId') || '';
    const { data: rulesets } = useRulesets(delegateId);

    const { mutateAsync } = useUpdatePurchase();

    const submit = async () => {
        await mutateAsync({
            id: order?.owner.id || '',
            data: {
                ...order,
                rules: [ruleset],
            },
        });

        setOpen(false);
    };

    useEffect(() => {
        if (open) {
            setRuleset(order?.owner.rules?.[0].id || '');
        }
    }, [order, open]);

    return (
        <Dialog open={open} onOpenChange={setOpen}>
            <DialogTrigger asChild className='' onClick={() => setOpen(true)}>
                <Button variant='ghost' className='p-2'>
                    <Edit className='h-4 w-4' />
                </Button>
            </DialogTrigger>

            <DialogPortal>
                <DialogContent className='text-sm'>
                    <DialogHeader className='font-bold'>
                        Update Document Set
                    </DialogHeader>

                    <div className='flex items-center gap-4'>
                        <Label htmlFor=''>Ruleset: </Label>
                        <Select
                            value={ruleset}
                            onValueChange={(value) => setRuleset(value)}
                        >
                            <SelectTrigger id='rulesetSelector'>
                                <SelectValue></SelectValue>
                            </SelectTrigger>

                            <SelectContent>
                                {rulesets?.map((x) => (
                                    <SelectItem value={x.id || ''}>
                                        {typeof x.name === 'object'
                                            ? x.name.locales
                                                ? x.name.locales[0]?.text
                                                : ''
                                            : typeof x.name === 'string'
                                            ? JSON.parse(x.name).locales[0].text
                                            : ''}
                                    </SelectItem>
                                ))}
                            </SelectContent>
                        </Select>
                    </div>

                    <DialogFooter>
                        <Button onClick={submit}>Submit</Button>
                    </DialogFooter>
                </DialogContent>
            </DialogPortal>
        </Dialog>
    );
};
