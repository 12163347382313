import { CaretSortIcon } from '@radix-ui/react-icons';
import { CheckIcon, InfoIcon } from 'lucide-react';
import { useState } from 'react';
import { Button } from 'src/components/ui/button';
import {
    Command,
    CommandEmpty,
    CommandGroup,
    CommandInput,
    CommandItem,
} from 'src/components/ui/command';
import {
    Popover,
    PopoverContent,
    PopoverTrigger,
} from 'src/components/ui/popover';
import {
    Tooltip,
    TooltipContent,
    TooltipProvider,
    TooltipTrigger,
} from 'src/components/ui/tooltip';
import { cn } from 'src/lib/utils';

interface Option {
    label: string;
    value: string;
    disabled?: boolean;
}

interface SelectProps {
    label?: string;
    options: Option[];
    value: string;
    onValueChange: (value: string) => void;
    disabled?: boolean;
    className?: string;
    placeholder?: string;
    optionClassName?: string;
    setCustomSearch?: (value: string) => void;
    description?: string;
    tooltip?: string;
}

export function Select({
    label,
    options,
    value,
    onValueChange,
    disabled = false,
    className,
    placeholder = '-',
    optionClassName,
    setCustomSearch,
    description,
    tooltip,
}: SelectProps) {
    const [open, setOpen] = useState(false);

    if (disabled) {
        return (
            <div className='flex flex-col gap-1.5'>
                {label && <label className='text-xs font-bold'>{label}</label>}
                <span className='flex h-7 w-full py-1 text-xs'>
                    {value || '-'}
                </span>
                {description && (
                    <p className='text-xs text-muted-foreground'>
                        {description}
                    </p>
                )}
            </div>
        );
    }

    return (
        <div className='flex flex-1 flex-col gap-1.5'>
            {label && (
                <label className='text-xs font-medium'>
                    <span>
                        {label}{' '}
                        {tooltip && (
                            <TooltipProvider>
                                <Tooltip delayDuration={100}>
                                    <TooltipTrigger type='button'>
                                        <InfoIcon className='h-3 w-3' />
                                    </TooltipTrigger>
                                    <TooltipContent>
                                        <p>{tooltip}</p>
                                    </TooltipContent>
                                </Tooltip>
                            </TooltipProvider>
                        )}
                    </span>
                </label>
            )}

            <Popover open={open} onOpenChange={setOpen}>
                <PopoverTrigger asChild>
                    <Button
                        variant='outline'
                        data-testid='select-input'
                        size='sm'
                        className={cn(
                            'w-full pl-3 text-left font-normal capitalize',
                            !value && 'text-muted-foreground',
                            className
                        )}
                    >
                        <span className='max-w-[75%] truncate text-xs'>
                            {value
                                ? options.find(
                                      (option) => option.value === value
                                  )?.label
                                : placeholder}
                        </span>
                        <CaretSortIcon className='ml-auto h-4 w-4 opacity-50' />
                    </Button>
                </PopoverTrigger>

                <PopoverContent className='p-0'>
                    <Command className='max-h-[200px]' loop>
                        <CommandInput
                            placeholder='Search...'
                            className='h-4 text-xs'
                            onValueChange={(search) => {
                                setCustomSearch?.(search);
                            }}
                        />
                        <CommandEmpty>No results found.</CommandEmpty>
                        <CommandGroup>
                            {options.map((option) => (
                                <CommandItem
                                    value={option.label}
                                    key={option.value}
                                    className={cn(
                                        'text-xs',
                                        optionClassName,
                                        option.disabled &&
                                            'cursor-not-allowed opacity-50'
                                    )}
                                    disabled={option.disabled}
                                    onSelect={() => {
                                        if (option.value === value) {
                                            onValueChange('');
                                        } else {
                                            onValueChange(option.value);
                                        }
                                        setOpen(false);
                                    }}
                                >
                                    {option.label}
                                    <CheckIcon
                                        className={cn(
                                            'ml-auto h-4 w-4',
                                            option.value === value
                                                ? 'opacity-100'
                                                : 'opacity-0'
                                        )}
                                    />
                                </CommandItem>
                            ))}
                        </CommandGroup>
                    </Command>
                </PopoverContent>
            </Popover>

            {description && (
                <p className='text-xs text-muted-foreground'>{description}</p>
            )}
        </div>
    );
}
