import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';
import { useCreateCategory } from 'src/app/_api_adb2c/product/category/hooks/use-create-category';
import { IndustryType } from 'src/app/_api_adb2c/product/product/models/product.model';
import {
    ProductUom,
    ProductUomLabels,
} from 'src/app/_api_adb2c/purchase/purchase/enums/product-uom.enum';
import { DialogV2 } from 'src/app/components-v2/dialog-v2';
import { DelegationInput } from 'src/app/components/Form/DelegationInput';
import { SelectInputV2 } from 'src/app/components/Form/SelectInputV2';
import { TextInputV2 } from 'src/app/components/Form/TextInputV2';
import { useContextStore } from 'src/app/stores/context-store';
import { z } from 'zod';

interface Props {
    open: boolean;
    onClose: () => void;
}

const formSchema = z.object({
    delegateId: z.string().optional(),
    name: z.string(),
    code: z.string(),
    unit: z.string(),
    description: z.string().optional(),
});

export function AddCategory({ open, onClose }: Props) {
    const [searchParams] = useSearchParams();
    const delegateId = searchParams.get('delegateId');
    const context = useContextStore();

    const { mutateAsync, isLoading: isCreateLoading } = useCreateCategory();

    const form = useForm<z.infer<typeof formSchema>>({
        mode: 'onChange',
        resolver: zodResolver(formSchema),
    });

    const reset = () => {
        form.reset();
        onClose();
    };

    const onSubmit = async (data: z.infer<typeof formSchema>) => {
        await mutateAsync({
            delegateId: delegateId || '',
            body: {
                code: data.code,
                defaultUnitOfMeasurement: data.unit,
                description: data.description,
                industry: IndustryType.OTHERS,
                name: data.name,
                workspace: context.workspace?._id || '',
            },
        });

        reset();
    };

    return (
        <DialogV2
            open={open}
            onClose={onClose}
            form={form}
            onSubmit={onSubmit}
            title='Add Category'
            isLoading={isCreateLoading}
            isStepDialog
            steps={[
                {
                    title: 'Delegation Controls',
                    description: 'Select the delegate for this category.',
                    content: (
                        <DelegationInput
                            label='On Behalf Of.'
                            name='delegateId'
                        />
                    ),
                },
                {
                    title: 'Category Details',
                    description: 'Enter the details for this category.',
                    content: (
                        <>
                            <TextInputV2 name='name' label='Category Name' />

                            <TextInputV2 name='code' label='Category Code' />

                            <SelectInputV2
                                name='unit'
                                label='Unit of Measurement'
                                options={Object.values(ProductUom).map(
                                    (unit) => ({
                                        label: ProductUomLabels[unit],
                                        value: unit,
                                    })
                                )}
                            ></SelectInputV2>

                            <TextInputV2
                                name='description'
                                label='Description'
                            />
                        </>
                    ),
                },
            ]}
        ></DialogV2>
    );
}
