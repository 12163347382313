import { ServiceConfigInterface } from 'src/domain/interfaces/config.context.interface';
import { ProductStatisticsClient } from './product-statistics-client';

export class ProductStatisticsService {
    server: ProductStatisticsClient;

    constructor(config: ServiceConfigInterface, token: string) {
        this.server = new ProductStatisticsClient(config, token);
    }

    reportByCategory(workspace: string, start: string, end: string) {
        console.log(`service<product-statistics>| reportByCategory(): Enter`);
        console.log(
            `service<product-statistics>| reportByCategory(): $workspace = ${workspace}`
        );
        console.log(
            `service<product-statistics>| reportByCategory(): $start = ${start}`
        );
        console.log(
            `service<product-statistics>| reportByCategory(): $end = ${end}`
        );

        return this.server.reportByCategory(workspace, start, end);
    }
}
