import { InfoIcon, Upload, X } from 'lucide-react';
import { ChangeEvent, useRef } from 'react';
import { Button } from 'src/components/ui/button';
import {
    Tooltip,
    TooltipContent,
    TooltipProvider,
    TooltipTrigger,
} from 'src/components/ui/tooltip';
import { cn } from 'src/lib/utils';

interface FileInputProps {
    value?: File[] | null;
    onValueChange: (files: File[] | null) => void;
    label?: string;
    accept?: string;
    placeholder?: string;
    disabled?: boolean;
    className?: string;
    tooltip?: string;
    description?: string;
    maxSize?: number; // in MB
    multiple?: boolean;
    maxFiles?: number;
}

export function FileInput({
    value = [],
    onValueChange,
    label,
    accept,
    placeholder = 'Select file(s)',
    disabled = false,
    className,
    tooltip,
    description,
    maxSize = 10, // default 10MB
    multiple = false,
    maxFiles = 5,
}: FileInputProps) {
    const inputRef = useRef<HTMLInputElement>(null);

    const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
        const files = Array.from(e.target.files || []);
        if (!files.length) return;

        // Check number of files
        if (multiple && value && value.length + files.length > maxFiles) {
            alert(`Maximum ${maxFiles} files allowed`);
            return;
        }

        // Check file sizes
        const oversizedFiles = files.filter(
            (file) => file.size > maxSize * 1024 * 1024
        );
        if (oversizedFiles.length > 0) {
            alert(
                `File${
                    oversizedFiles.length > 1 ? 's' : ''
                } too large. Maximum size is ${maxSize}MB`
            );
            return;
        }

        if (multiple) {
            onValueChange([...(value || []), ...files]);
        } else {
            onValueChange([files[0]]);
        }
    };

    const handleClear = () => {
        if (inputRef.current) {
            inputRef.current.value = '';
        }
        onValueChange(null);
    };

    const handleRemoveFile = (index: number) => {
        if (!value) return;
        const newFiles = value.filter((_, i) => i !== index);
        onValueChange(newFiles.length ? newFiles : null);
    };

    return (
        <div className='flex flex-col gap-1.5'>
            {label && (
                <label className='text-xs font-medium'>
                    <span>
                        {label}{' '}
                        {tooltip && (
                            <TooltipProvider>
                                <Tooltip delayDuration={100}>
                                    <TooltipTrigger type='button'>
                                        <InfoIcon className='h-3 w-3' />
                                    </TooltipTrigger>
                                    <TooltipContent>
                                        <p>{tooltip}</p>
                                    </TooltipContent>
                                </Tooltip>
                            </TooltipProvider>
                        )}
                    </span>
                </label>
            )}

            <div className='flex flex-col gap-2'>
                <div className='flex gap-2'>
                    <Button
                        variant='outline'
                        className={cn(
                            'h-8 w-full justify-start text-left text-xs font-normal',
                            !value?.length && 'text-muted-foreground',
                            className
                        )}
                        disabled={disabled}
                        onClick={() => inputRef.current?.click()}
                    >
                        <Upload className='mr-2 h-4 w-4' />
                        <span className='truncate'>
                            {value?.length
                                ? multiple
                                    ? `${value.length} file${
                                          value.length > 1 ? 's' : ''
                                      } selected`
                                    : value[0].name
                                : placeholder}
                        </span>
                    </Button>

                    {(value || []).length > 0 && (
                        <Button
                            variant='outline'
                            size='sm'
                            className='h-8 px-2'
                            onClick={handleClear}
                        >
                            <X className='h-4 w-4' />
                        </Button>
                    )}
                </div>

                {/* File list for multiple files */}
                {multiple && value && value.length > 0 && (
                    <ul className='space-y-1'>
                        {value.map((file, index) => (
                            <li
                                key={`${file.name}-${index}`}
                                className='flex items-center justify-between rounded-md border border-border bg-background px-3 py-1.5'
                            >
                                <span className='truncate text-xs'>
                                    {file.name}
                                </span>
                                <Button
                                    variant='ghost'
                                    size='sm'
                                    className='h-6 w-6 p-0'
                                    onClick={() => handleRemoveFile(index)}
                                >
                                    <X className='h-3 w-3' />
                                </Button>
                            </li>
                        ))}
                    </ul>
                )}
            </div>

            <input
                ref={inputRef}
                type='file'
                accept={accept}
                onChange={handleFileChange}
                className='hidden'
                disabled={disabled}
                multiple={multiple}
            />

            {description && (
                <p className='text-xs text-muted-foreground'>{description}</p>
            )}
        </div>
    );
}
