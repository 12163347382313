import { useMutation, useQueryClient } from '@tanstack/react-query';
import { toast } from 'sonner';
import { useContextStore } from 'src/app/stores/context-store';
import { ProductCloneDataObject } from '../requests/product-clone-data-object';
import { PRODUCT_QUERY_KEY, PRODUCTS_QUERY_KEY } from './product-query-keys';
import { useProductService } from './use-product-service';

export function useProductClone() {
    const { service } = useProductService();
    const client = useQueryClient();
    const context = useContextStore();

    return useMutation({
        mutationFn: async ({
            item,
            body,
            delegateId,
        }: {
            delegateId?: string;
            item: string;
            body: ProductCloneDataObject;
        }) => {
            return await service.clone(
                delegateId || context.workspace?._id || '',
                item,
                body
            );
        },
        onSuccess: (_, { item, delegateId }) => {
            client.invalidateQueries({
                queryKey: PRODUCTS_QUERY_KEY(
                    delegateId || context.workspace?._id || ''
                ),
            });

            client.invalidateQueries({
                queryKey: PRODUCT_QUERY_KEY(
                    delegateId || context.workspace?._id || '',
                    item
                ),
            });

            toast.success('Product cloned successfully');
        },
        onError: (err) => {
            const error = err as Error;

            toast.error(`Failed to clone product: ${error.message}`);
        },
    });
}
