import { useQuery } from '@tanstack/react-query';
import { toast } from 'sonner';
import useAppContext from '../../../../../infrastructure/hooks/use-context.hook';
import { PurchaseOrderVersionModel } from '../purchase.model';
import { PURCHASE_QUERY_KEY } from './purchase-query-keys';
import { usePurchaseService } from './use-purchase-service';

interface UsePurchaseInterface {
    id: string;
    customWorkspaceId?: string;
    delegateId?: string;
    enabled?: boolean;
}

export function usePurchase({
    id,
    customWorkspaceId,
    delegateId,
    enabled = true,
}: UsePurchaseInterface) {
    const context = useAppContext();
    const { service } = usePurchaseService();

    return useQuery<PurchaseOrderVersionModel>({
        queryKey: PURCHASE_QUERY_KEY(
            delegateId || context.workspace?.id || '',
            id
        ),
        queryFn: async () => {
            return service.get(
                delegateId || customWorkspaceId || context.workspace?.id || '',
                id,
                '1'
            );
        },
        onError: (err) => {
            const error = err as Error;

            toast.error(`Failed to fetch purchase: ${error.message}`);
        },
        enabled: enabled && !!id,
    });
}
