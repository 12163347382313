import { ServiceConfigInterface } from 'src/domain/interfaces/config.context.interface';
import { ApiServer } from 'src/infrastructure/clients/api.server';
import { RoleAssignRequestObject } from './role-assign-request-object';
import { PermissionGrantRequestObject } from './permission-grant-request-object';
import { RoleCreateRequestObject } from './role-create-request-object';

export class RolesApiClient extends ApiServer {
    constructor(config: ServiceConfigInterface, token: string) {
        super(token, config, 'workspaces', 'role');
    }

    assignMember(
        workspace: string,
        role: string,
        body: RoleAssignRequestObject
    ) {
        console.log(`client<role>| assignMember(): Enter`);
        console.log(`client<role>| assignMember(): $workspace = ${workspace}`);
        console.log(`client<role>| assignMember(): $role = ${role}`);
        console.log(
            `client<role>| assignMember(): $body = ${JSON.stringify(body)}`
        );

        return super.post('assignMember', workspace, role, body);
    }

    create(workspace: string, body: RoleCreateRequestObject) {
        console.log(`client<role>| create(): Enter`);
        console.log(`client<role>| create(): $workspace = ${workspace}`);
        console.log(`client<role>| create(): $body = ${JSON.stringify(body)}`);

        return super.post('create', workspace, body);
    }

    remove(workspace: string, id: string) {
        console.log(`client<role>| remove(): Enter`);
        console.log(`client<role>| remove(): $workspace = ${workspace}`);
        console.log(`client<role>| remove(): $id = ${id}`);

        return super.delete('delete', workspace, id);
    }

    getRoleMembers(workspace: string, role: string) {
        console.log(`client<role>| getRoleMembers(): Enter`);
        console.log(
            `client<role>| getRoleMembers(): $workspace = ${workspace}`
        );
        console.log(`client<role>| getRoleMembers(): $role = ${role}`);

        return super.get('getRoleMembers', workspace, role);
    }

    updateRolePermissions(
        workspace: string,
        role: string,
        body: PermissionGrantRequestObject
    ) {
        console.log(`client<role>| updateRolePermissions(): Enter`);
        console.log(
            `client<role>| updateRolePermissions(): $workspace = ${workspace}`
        );
        console.log(`client<role>| updateRolePermissions(): $role = ${role}`);
        console.log(
            `client<role>| updateRolePermissions(): $body = ${JSON.stringify(
                body
            )}`
        );

        return super.put('updatePermissions', workspace, role, body);
    }

    list(workspace: string) {
        console.log(`client<role>| list(): Enter`);
        console.log(`client<role>| list(): $workspace = ${workspace}`);

        return super.get('list', workspace);
    }

    unassignMember(
        workspace: string,
        role: string,
        body: RoleAssignRequestObject
    ) {
        console.log(`client<role>| unassignMember(): Enter`);
        console.log(
            `client<role>| unassignMember(): $workspace = ${workspace}`
        );
        console.log(`client<role>| unassignMember(): $role = ${role}`);
        console.log(
            `client<role>| unassignMember(): $body = ${JSON.stringify(body)}`
        );

        return super.put('unassign', workspace, role, body);
    }
}
