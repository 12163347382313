import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { useSupplierInvite } from 'src/app/_api_adb2c/workspace/account/hooks/use-supplier-invite';
import { DialogV2 } from 'src/app/components-v2/dialog-v2';
import { DelegationInput } from 'src/app/components/Form/DelegationInput';
import { z } from 'zod';
import { SupplierContactsFields } from './supplier-contact-fields';
import { SupplierRequiredFields } from './supplier-required-fields';

interface Props {
    open: boolean;
    onClose: () => void;
}

const formSchema = z.object({
    delegateId: z.string().optional(),
    firstName: z.string().optional(),
    lastName: z.string().optional(),
    email: z.string().email().optional(),
    registrationNumber: z.string().optional(),
    country: z.string().optional(),
    companyName: z.string().optional(),
    assignDelegate: z.boolean().default(false),
});

export const AddSupplier = ({ open, onClose }: Props) => {
    const form = useForm<z.infer<typeof formSchema>>({
        mode: 'onChange',
        resolver: zodResolver(formSchema),
    });

    const { mutateAsync: invite, isLoading: inviteLoading } =
        useSupplierInvite();

    const onSubmit = async (data: z.infer<typeof formSchema>) => {
        await invite({
            delegateId: data.delegateId,
            body: {
                country: data.country!,
                company: data.companyName!,
                registrationNumber: data.registrationNumber!,
                contact: [
                    {
                        contact: {
                            email: data.email!,
                            firstName: data.firstName!,
                            lastName: data.lastName!,
                            device: {},
                        },
                    },
                ],
                disableDelegate: false,
            },
        });

        reset();
    };

    const reset = () => {
        onClose();
    };

    return (
        <DialogV2
            title='Add Supplier'
            open={open}
            onClose={reset}
            form={form}
            onSubmit={onSubmit}
            isLoading={inviteLoading}
            isStepDialog
            steps={[
                {
                    title: 'On Behalf Of.',
                    description: 'Select the delegate for this supplier.',
                    content: (
                        <DelegationInput
                            name='delegateId'
                            label='On Behalf Of.'
                        />
                    ),
                },
                {
                    title: 'Supplier Information',
                    description:
                        'Enter the basic information for this supplier.',
                    content: <SupplierRequiredFields />,
                },
                {
                    title: 'Supplier Contacts',
                    description: 'Add the contacts for this supplier.',
                    content: <SupplierContactsFields />,
                },
            ]}
        />
    );
};
