import {
    ColumnDef,
    getCoreRowModel,
    useReactTable,
} from '@tanstack/react-table';
import { Edit, GanttChartSquare, Trash } from 'lucide-react';
import { useCallback, useMemo, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import {
    ProductUom,
    ProductUomLabels,
} from 'src/app/_api_adb2c/purchase/purchase/enums/product-uom.enum';
import { PurchaseOrderStatus } from 'src/app/_api_adb2c/purchase/purchase/enums/purchase-order-status.enum';
import { ManifestItemModel } from 'src/app/_api_adb2c/purchase/purchase/models/manifest-item.model';
import {
    PurchaseModel,
    PurchaseOrderTraceModel,
} from 'src/app/_api_adb2c/purchase/purchase/models/purchase.model';
import { DataTableV2 } from 'src/app/components-v2/table';
import { Button } from 'src/components/ui/button';
import { Checkbox } from 'src/components/ui/checkbox';
import { DeletePurchaseManifestItem } from './delete-purchase-manifest-item';
import { EditPurchaseManifestItem } from './edit-purchase-manifest-item';

interface Props {
    data?: PurchaseModel | PurchaseOrderTraceModel;
    allowNavigate?: boolean;
}

export const PurchaseManifest = ({ data, allowNavigate = true }: Props) => {
    const [searchParams] = useSearchParams();
    const delegateId = searchParams.get('delegateId') || '';

    const navigate = useNavigate();

    const [isEdit, setIsEdit] = useState(false);
    const [isDelete, setIsDelete] = useState(false);
    const [selectedItem, setSelectedItem] = useState<ManifestItemModel>();

    const isModifiable = useMemo(() => {
        return (
            data?.status !== PurchaseOrderStatus.CANCELLED &&
            data?.status !== PurchaseOrderStatus.DELIVERED
        );
    }, [data]);

    const handleRedirectToDetails = useCallback(
        (id: string) => {
            delegateId
                ? navigate(`/products/${id}?delegateId=${delegateId}`)
                : navigate(`/products/${id}`);
        },
        [delegateId, navigate]
    );

    const memoizedData = useMemo(() => {
        const version = data?.versions?.[data?.versions?.length - 1];

        if (!version) return [];

        return version.manifest;
    }, [data]);

    const columns: ColumnDef<ManifestItemModel>[] = useMemo(() => {
        const columns: ColumnDef<ManifestItemModel>[] = [
            {
                id: 'select',
                header: ({ table }) => (
                    <Checkbox
                        checked={table.getIsAllPageRowsSelected()}
                        onCheckedChange={(value) =>
                            table.toggleAllPageRowsSelected(!!value)
                        }
                        className='ml-2 mr-2'
                        aria-label='Select all'
                    />
                ),
                cell: ({ row }) => (
                    <Checkbox
                        checked={row.getIsSelected()}
                        onCheckedChange={(value) => row.toggleSelected(!!value)}
                        aria-label='Select row'
                        className='ml-2 mr-2 text-white'
                    />
                ),
                enableSorting: false,
                enableHiding: false,
                size: 50,
            },
            {
                id: 'product',
                header: 'Product',
                accessorFn: (row) => {
                    return row.product.name;
                },
            },
            {
                id: 'category',
                header: 'Category',
                accessorFn: (row) => {
                    return row.product.category.name;
                },
            },
            {
                id: 'uom',
                header: 'UOM',
                accessorFn: (row) =>
                    row.unit ? ProductUomLabels[row.unit as ProductUom] : '',
            },
            {
                id: 'quantity',
                header: 'Quantity',
                accessorFn: (row) => row.quantity,
            },
            { id: 'price', header: 'Price', accessorFn: (row) => row.ppu },
        ];

        if (allowNavigate) {
            columns.push({
                id: 'actions',
                header: 'Actions',
                cell: ({ row }) => {
                    return (
                        <div className='flex gap-1'>
                            {data && (
                                <div className='flex gap-4'>
                                    <Button variant='ghost' className='px-0'>
                                        <GanttChartSquare
                                            className='h-4 w-4'
                                            onClick={() => [
                                                handleRedirectToDetails(
                                                    row.original.product._id
                                                ),
                                            ]}
                                        />
                                    </Button>

                                    {isModifiable && (
                                        <>
                                            <Button
                                                variant='ghost'
                                                className='px-0'
                                                onClick={() => {
                                                    setIsEdit(true);
                                                    setSelectedItem(
                                                        row.original
                                                    );
                                                }}
                                            >
                                                <Edit size={16} />
                                            </Button>

                                            <Button
                                                variant='ghost'
                                                className='px-0'
                                                onClick={() => {
                                                    setIsDelete(true);
                                                    setSelectedItem(
                                                        row.original
                                                    );
                                                }}
                                            >
                                                <Trash size={16} />
                                            </Button>
                                        </>
                                    )}
                                </div>
                            )}
                        </div>
                    );
                },
            });
        }

        return columns;
    }, [allowNavigate, data, isModifiable, handleRedirectToDetails]);

    // const columns: ColumnDef<ManifestItemModel>[] = [
    //     {
    //         id: 'select',
    //         header: ({ table }) => (
    //             <Checkbox
    //                 checked={table.getIsAllPageRowsSelected()}
    //                 onCheckedChange={(value) =>
    //                     table.toggleAllPageRowsSelected(!!value)
    //                 }
    //                 className='ml-2 mr-2'
    //                 aria-label='Select all'
    //             />
    //         ),
    //         cell: ({ row }) => (
    //             <Checkbox
    //                 checked={row.getIsSelected()}
    //                 onCheckedChange={(value) => row.toggleSelected(!!value)}
    //                 aria-label='Select row'
    //                 className='ml-2 mr-2 text-white'
    //             />
    //         ),
    //         enableSorting: false,
    //         enableHiding: false,
    //         size: 50,
    //     },
    //     {
    //         id: 'product',
    //         header: 'Product',
    //         accessorFn: (row) => {
    //             return row.product.name;
    //         },
    //     },
    //     {
    //         id: 'category',
    //         header: 'Category',
    //         accessorFn: (row) => {
    //             return row.product.category.name;
    //         },
    //     },
    //     {
    //         id: 'uom',
    //         header: 'UOM',
    //         accessorFn: (row) =>
    //             row.unit ? ProductUomLabels[row.unit as ProductUom] : '',
    //     },
    //     {
    //         id: 'quantity',
    //         header: 'Quantity',
    //         accessorFn: (row) => row.quantity,
    //     },
    //     { id: 'price', header: 'Price', accessorFn: (row) => row.ppu },
    //     {
    //         id: 'actions',
    //         header: 'Actions',
    //         cell: ({ row }) => {
    //             return (
    //                 <div className='flex gap-1'>
    //                     {data && (
    //                         <div className='flex gap-4'>
    //                             <Button variant='ghost' className='px-0'>
    //                                 <GanttChartSquare
    //                                     className='h-4 w-4'
    //                                     onClick={() => [
    //                                         handleRedirectToDetails(
    //                                             row.original.product._id
    //                                         ),
    //                                     ]}
    //                                 />
    //                             </Button>

    //                             {isModifiable && (
    //                                 <>
    //                                     <Button
    //                                         variant='ghost'
    //                                         className='px-0'
    //                                         onClick={() => {
    //                                             setIsEdit(true);
    //                                             setSelectedItem(row.original);
    //                                         }}
    //                                     >
    //                                         <Edit size={16} />
    //                                     </Button>

    //                                     <Button
    //                                         variant='ghost'
    //                                         className='px-0'
    //                                         onClick={() => {
    //                                             setIsDelete(true);
    //                                             setSelectedItem(row.original);
    //                                         }}
    //                                     >
    //                                         <Trash size={16} />
    //                                     </Button>
    //                                 </>
    //                             )}
    //                         </div>
    //                     )}
    //                 </div>
    //             );
    //         },
    //     },
    // ];

    const table = useReactTable({
        data: memoizedData || [],
        columns,
        getCoreRowModel: getCoreRowModel(),
    });

    return (
        <>
            {/* <DataTable
                data={memoizedData || []}
                columns={columns}
                rowSelection={rowSelection}
                setRowSelection={setRowSelection}
                label='Purchase Manifest'
            /> */}
            <DataTableV2 table={table} label='Purchase Manifest' />

            {isEdit && (
                <EditPurchaseManifestItem
                    onClose={() => {
                        setIsEdit(false);
                        setSelectedItem(undefined);
                    }}
                    open={isEdit}
                    data={selectedItem}
                    order={data}
                />
            )}

            {isDelete && (
                <DeletePurchaseManifestItem
                    onClose={() => {
                        setIsDelete(false);
                        setSelectedItem(undefined);
                    }}
                    open={isDelete}
                    data={selectedItem}
                    order={data}
                />
            )}
        </>
    );
};
