import { ServiceConfigInterface } from 'src/domain/interfaces/config.context.interface';
import { ProductApprovalApiClient } from './product-approval.client';

export class ProductApprovalService {
    private server: ProductApprovalApiClient;

    constructor(config: ServiceConfigInterface, token: string) {
        this.server = new ProductApprovalApiClient(config, token);
    }

    getByWorkspace(workspace: string) {
        console.log(`service<product-approval>| getByWorkspace(): Enter`);
        console.log(
            `service<product-approval>| getByWorkspace(): $workspace = ${workspace}`
        );

        return this.server.getByWorkspace(workspace);
    }

    getByApprover(workspace: string) {
        console.log(`service<product-approval>| getByApprover(): Enter`);
        console.log(
            `service<product-approval>| getByApprover(): $workspace = ${workspace}`
        );

        return this.server.getByApprover(workspace);
    }

    get(workspace: string, item: string) {
        console.log(`service<product-approval>| get(): Enter`);
        console.log(
            `service<product-approval>| get(): $workspace = ${workspace}`
        );
        console.log(`service<product-approval>| get(): $item = ${item}`);

        return this.server.get(workspace, item);
    }

    create(workspace: string, body: any) {
        console.log(`service<product-approval>| create(): Enter`);
        console.log(
            `service<product-approval>| create(): $workspace = ${workspace}`
        );
        console.log(`service<product-approval>| create(): $body = ${body}`);

        return this.server.create(workspace, body);
    }

    approve(workspace: string, item: string) {
        console.log(`service<product-approval>| approve(): Enter`);
        console.log(
            `service<product-approval>| approve(): $workspace = ${workspace}`
        );
        console.log(`service<product-approval>| approve(): $item = ${item}`);

        return this.server.approve(workspace, item);
    }

    reject(workspace: string, item: string, body: any) {
        console.log(`service<product-approval>| reject(): Enter`);
        console.log(
            `service<product-approval>| reject(): $workspace = ${workspace}`
        );
        console.log(`service<product-approval>| reject(): $item = ${item}`);
        console.log(`service<product-approval>| reject(): $body = ${body}`);

        return this.server.reject(workspace, item, body);
    }

    remove(workspace: string, item: string) {
        console.log(`service<product-approval>| remove(): Enter`);
        console.log(
            `service<product-approval>| remove(): $workspace = ${workspace}`
        );
        console.log(`service<product-approval>| remove(): $item = ${item}`);

        return this.server.remove(workspace, item);
    }

    getByWorkspaceItem(workspace: string, item: string) {
        console.log(`service<product-approval>| getByWorkspaceItem(): Enter`);
        console.log(
            `service<product-approval>| getByWorkspaceItem(): $workspace = ${workspace}`
        );
        console.log(
            `service<product-approval>| getByWorkspaceItem(): $item = ${item}`
        );

        return this.server.getByWorkspaceItem(workspace, item);
    }
}
