import { useMemo } from 'react';
import { Bar, BarChart, CartesianGrid, YAxis } from 'recharts';
import { usePurchases } from 'src/app/_api_adb2c/purchase/purchase/hooks/use-purchases';
import { PurchaseModel } from 'src/app/_api_adb2c/purchase/purchase/models/purchase.model';
import {
    Card,
    CardContent,
    CardDescription,
    CardHeader,
    CardTitle,
} from 'src/components/ui/card';
import {
    ChartConfig,
    ChartContainer,
    ChartTooltip,
    ChartTooltipContent,
} from 'src/components/ui/chart';
import { getDateRangeDescription } from '../utils/get-date-range-description';

interface Props {
    startDate?: Date;
    endDate?: Date;
}

const chartConfig = {
    count: {
        // label: 'Count',
        color: 'hsl(var(--chart-1))',
    },
    label: {
        label: 'Supplier',
        color: 'hsl(var(--chart-2))',
    },
} satisfies ChartConfig;

export function OrderSupplierDistribution({ startDate, endDate }: Props) {
    const { data: purchases } = usePurchases({
        draft: false,
        page: 0,
        limit: 0,
    });

    const memoizedPurchases = useMemo(() => {
        if (!purchases?.data) return [];

        const responses: PurchaseModel[] = [];

        purchases.data.forEach((purchase) => {
            const createdDate = new Date(purchase.createdOn);

            if (startDate && endDate) {
                if (createdDate < startDate || createdDate > endDate) return;
            }

            responses.push(purchase);
        });

        return responses;
    }, [purchases, startDate, endDate]);

    const graphData = useMemo(() => {
        const supplierCounts: { label: string; count: number }[] = [];

        memoizedPurchases.forEach((purchase) => {
            const supplier = purchase.supplier;

            if (!supplier) return;

            const existing = supplierCounts.find(
                (x) => x.label === supplier.seller.name
            );

            if (existing) {
                existing.count += 1;
            } else {
                supplierCounts.push({
                    label: supplier.seller.name,
                    count: 1,
                });
            }
        });

        return supplierCounts.sort((a, b) => b.count - a.count);
    }, [memoizedPurchases]);

    return (
        <Card className='px-4 py-2 shadow'>
            <CardHeader>
                <CardTitle>Supplier Distribution</CardTitle>
                <CardDescription>
                    {getDateRangeDescription(startDate, endDate)}
                </CardDescription>
            </CardHeader>

            <CardContent>
                <ChartContainer config={chartConfig} className='h-56 w-full'>
                    <BarChart
                        accessibilityLayer
                        data={graphData}
                        margin={{
                            top: 40,
                        }}
                    >
                        <CartesianGrid vertical={false} />
                        <YAxis
                            dataKey='count'
                            tickLine={false}
                            axisLine={false}
                            tickMargin={8}
                        />
                        <ChartTooltip
                            cursor={false}
                            content={
                                <ChartTooltipContent payloadLabelKey='label' />
                            }
                        />
                        <Bar
                            dataKey='count'
                            fill='var(--color-count)'
                            radius={8}
                            barSize={40}
                        ></Bar>
                    </BarChart>
                </ChartContainer>
            </CardContent>
        </Card>
    );
}
