import { useNavigate } from 'react-router-dom';
import { cn } from '../utils/cn';

interface TitleProps extends React.HTMLAttributes<HTMLHeadingElement> {}
interface TypographyProps extends React.HTMLAttributes<HTMLParagraphElement> {}

export const Title = ({ children, className, ...props }: TitleProps) => {
    return (
        <span className={cn('text-xl font-bold', className)} {...props}>
            {children}
        </span>
    );
};

export const TypographySmall = ({
    children,
    className,
    ...props
}: TypographyProps) => {
    return (
        <p className={cn('text-xs', className)} {...props}>
            {children}
        </p>
    );
};

export const TypographyMuted = ({
    children,
    className,
    ...props
}: TypographyProps) => {
    return (
        <p
            className={cn(
                'text-xs font-light text-muted-foreground',
                className
            )}
            {...props}
        >
            {children}
        </p>
    );
};

interface NavigationLinkProps extends React.HTMLAttributes<HTMLSpanElement> {
    path: string;
    search?: string;
}

export const NavigationLink = ({
    path,
    search,
    children,
    className,
    ...props
}: NavigationLinkProps) => {
    const navigate = useNavigate();

    return (
        <TypographySmall
            {...props}
            onClick={() =>
                navigate({
                    pathname: path,
                    search: search,
                })
            }
            className={cn(
                'cursor-pointer truncate text-blue-800 hover:underline',
                className
            )}
            data-testid='navigation-link'
        >
            {children}
        </TypographySmall>
    );
};
