import { ColumnDef } from '@tanstack/react-table';
import { Trash } from 'lucide-react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { FieldArrayWithId, UseFieldArrayRemove } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';
import { DataTable } from 'src/app/components/DataTable';
import { SelectInput } from 'src/app/components/Form/SelectInput';
import { TextInput } from 'src/app/components/Form/TextInput';
import { Button } from 'src/components/ui/button';
import { z } from 'zod';
import { usePurchaseItems } from '../../_api/purchase/purchase-order/hooks/use-purchase-items';
import { formSchema, purchaseOrderItemDataObjectSchema } from './add-purchase';

interface AddPurchaseProductsProps {
    fields: FieldArrayWithId<z.infer<typeof formSchema>, 'items', 'id'>[];
    remove: UseFieldArrayRemove;
    customDelegateId?: string;
}

export const AddPurchaseProducts: React.FC<AddPurchaseProductsProps> = ({
    fields,
    remove,
    customDelegateId,
}) => {
    const name = 'items';
    const [rerender, setRerender] = useState(false);
    const [searchParams] = useSearchParams();
    const delegateId = searchParams.get('delegateId') || '';
    const { data: purchaseables } = usePurchaseItems(
        customDelegateId || delegateId
    );

    const removeAndRerender = useCallback(
        (index: number) => {
            remove(index);
            setRerender(!rerender);
        },
        [rerender, remove]
    );

    const columns: ColumnDef<
        z.infer<typeof purchaseOrderItemDataObjectSchema>
    >[] = useMemo(
        () => [
            {
                id: 'material',
                header: 'Material',
                accessor: 'material',
                cell: ({ row }) => {
                    return (
                        <SelectInput
                            // label='Material'
                            name={`${name}.${row.index}.material`}
                            options={(purchaseables || []).map((x) => ({
                                label: x.name,
                                value: x.id,
                            }))}
                        />
                    );
                },
            },
            {
                id: 'comment',
                header: 'Comment',
                accessor: 'comment',
                cell: ({ row }) => {
                    return (
                        <TextInput inputName={`${name}.${row.index}.comment`} />
                    );
                },
            },
            {
                id: 'ppu',
                header: 'PPU',
                accessor: 'ppu',
                cell: ({ row }) => {
                    return (
                        <TextInput
                            inputName={`${name}.${row.index}.ppu`}
                            type='number'
                        />
                    );
                },
            },
            {
                id: 'quantity',
                header: 'Quantity',
                accessor: 'quantity',
                cell: ({ row }) => {
                    return (
                        <TextInput
                            inputName={`${name}.${row.index}.quantity`}
                            type='number'
                        />
                    );
                },
            },
            {
                id: 'actions',
                header: 'Actions',
                cell: ({ row }) => {
                    return (
                        <Button
                            variant='outline'
                            className='text-red-800'
                            onClick={() => removeAndRerender(row.index)}
                        >
                            <Trash className='h-4 w-4' />
                        </Button>
                    );
                },
            },
        ],
        [purchaseables, removeAndRerender]
    );

    useEffect(() => {
        if (rerender) {
            setRerender(!rerender);
        }
    }, [rerender]);

    return (
        <div className='relative flex h-full w-full flex-1 flex-col overflow-auto rounded-md'>
            {!rerender && (
                <DataTable
                    label='Add Products'
                    columns={columns}
                    data={fields}
                    enablePagination={false}
                />
            )}
        </div>
    );
};
