import { useQuery } from '@tanstack/react-query';
import { toast } from 'sonner';
import { useContextStore } from 'src/app/stores/context-store';
import { MATERIAL_QUERY_KEY } from './material-query-keys';
import { useMaterialService } from './use-material-service';
import { MaterialModel } from '../models/material.model';

export function useMaterial(id: string, delegateId?: string) {
    const { service } = useMaterialService();
    const context = useContextStore();

    return useQuery<MaterialModel>({
        queryKey: MATERIAL_QUERY_KEY(
            delegateId || context.workspace?._id || '',
            id
        ),
        queryFn: async () => {
            return await service.get(
                delegateId || context.workspace?._id || '',
                id
            );
        },
        onError: (err) => {
            const error = err as Error;

            toast.error(`Failed to load material: ${error.message}`);
        },
        enabled: !!id,
    });
}
