import { zodResolver } from '@hookform/resolvers/zod';
import {
    ColumnDef,
    flexRender,
    getCoreRowModel,
    useReactTable,
} from '@tanstack/react-table';
import axios from 'axios';
import { Trash } from 'lucide-react';
import { useFieldArray, useForm } from 'react-hook-form';
import {
    DocumentType,
    DocumentTypeLabel,
} from 'src/app/_api/workspace/workspace/workspace.model';
import { useAttachmentUpload } from 'src/app/_api_adb2c/attachment/hooks/use-attachment-upload';
import { useCreateDocument } from 'src/app/_api_adb2c/workspace/documents/hooks/use-create-document';
import { SelectInput } from 'src/app/components/Form/SelectInput';
import { TextInput } from 'src/app/components/Form/TextInput';
import { Dropzone } from 'src/app/pages/Fulfillments/dropzone';
import { useContextStore } from 'src/app/stores/context-store';
import { Button } from 'src/components/ui/button';
import {
    Dialog,
    DialogContent,
    DialogFooter,
    DialogTitle,
} from 'src/components/ui/dialog';
import { Form } from 'src/components/ui/form';
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableHeader,
    TableRow,
} from 'src/components/ui/table';
import { z } from 'zod';

interface Props {
    open: boolean;
    onClose: () => void;
}

const documentSchema = z.object({
    comment: z.string().optional(),
    name: z.string(),
    nature: z.string(),
    contentType: z.string(),
    size: z.number(),
    isPrivate: z.boolean().default(false).optional(),
    file: z.instanceof(File).optional(),
});

const formSchema = z.object({
    documents: z.array(documentSchema),
});

export const CreateDocument = ({ open, onClose }: Props) => {
    const { workspace, user } = useContextStore();
    const { mutateAsync: upload, isLoading: uploadLoading } =
        useAttachmentUpload();
    const { mutateAsync: create, isLoading: createLoading } =
        useCreateDocument();

    const form = useForm<z.infer<typeof formSchema>>({
        mode: 'onChange',
        resolver: zodResolver(formSchema),
        defaultValues: {
            documents: [],
        },
    });

    const { fields, append, remove } = useFieldArray({
        control: form.control,
        name: 'documents',
    });

    const reset = () => {
        form.reset();
        onClose();
    };

    const onSubmit = async (data: z.infer<typeof formSchema>) => {
        await Promise.all(
            data.documents.map(async (doc) => {
                const token = await upload({
                    assetType: 'document',
                    file: {
                        contentType: doc.contentType,
                        genre: 'document',
                        name: doc.name,
                        size: doc.size,
                        uploadedBy: user?._id || '',
                        workspace: workspace?._id || '',
                    },
                });

                await axios.put(token.token, doc.file, {
                    headers: {
                        'x-ms-blob-type': 'BlockBlob',
                        'Content-Type': doc.contentType,
                    },
                });

                const splittedAssetName = token.attachment.assetName.split('/');

                const imageId =
                    splittedAssetName[splittedAssetName.length - 1].split(
                        '.'
                    )[0];

                return await create({
                    body: {
                        documents: [
                            {
                                description: doc.comment,
                                file: imageId,
                                isPrivate: doc.isPrivate || false,
                                type: doc.nature,
                            },
                        ],
                        extensions: [
                            {
                                isCertificate: false,
                                name: doc.name,
                                translatedName: [doc.name],
                                translatedDescription: doc.comment
                                    ? [doc.comment]
                                    : [],
                            },
                        ],
                    },
                });
            })
        );

        reset();
        console.log(data);
    };

    const columns: ColumnDef<z.infer<typeof documentSchema>>[] = [
        {
            id: 'name',
            header: 'Name',
            cell: ({ row }) => {
                return <TextInput inputName={`documents[${row.index}].name`} />;
            },
        },
        {
            id: 'nature',
            header: 'Nature',
            cell: ({ row }) => {
                return (
                    <SelectInput
                        name={`documents[${row.index}].nature`}
                        options={Object.values(DocumentType).map((value) => ({
                            value,
                            label: DocumentTypeLabel[value],
                        }))}
                    />
                );
            },
        },
        {
            id: 'comment',
            header: 'Comment',
            cell: ({ row }) => {
                return (
                    <TextInput inputName={`documents[${row.index}].comment`} />
                );
            },
        },
        {
            id: 'actions',
            header: 'Actions',
            size: 10,
            cell: ({ row }) => {
                return (
                    <Button onClick={() => remove(row.index)} variant='outline'>
                        <Trash size={16} />
                    </Button>
                );
            },
        },
    ];

    const table = useReactTable({
        data: fields,
        columns: columns,
        getCoreRowModel: getCoreRowModel(),
    });

    return (
        <Dialog open={open} onOpenChange={reset}>
            <DialogContent className='min-w-[750px]'>
                <DialogTitle className='text-md'>Upload Document</DialogTitle>

                <Dropzone
                    handleFileChange={(file) => {
                        append({
                            name: file.file.name,
                            nature: '',
                            comment: '',
                            isPrivate: false,
                            size: file.file.size,
                            contentType: file.contentType,
                            file: file.file,
                        });
                    }}
                />

                <Form {...form}>
                    <form id='my-form' onSubmit={form.handleSubmit(onSubmit)}>
                        <Table className='h-full border'>
                            <TableHeader className=''>
                                {table.getHeaderGroups().map((headerGroup) => (
                                    <>
                                        <TableRow
                                            key={headerGroup.id}
                                            className=''
                                        >
                                            {headerGroup.headers.map(
                                                (header) => {
                                                    return (
                                                        <>
                                                            <TableHead
                                                                key={header.id}
                                                                style={{
                                                                    width: header.getSize(),
                                                                }}
                                                                className='bg-red-800 text-white'
                                                            >
                                                                {header.isPlaceholder
                                                                    ? null
                                                                    : flexRender(
                                                                          header
                                                                              .column
                                                                              .columnDef
                                                                              .header,
                                                                          header.getContext()
                                                                      )}
                                                            </TableHead>
                                                        </>
                                                    );
                                                }
                                            )}
                                        </TableRow>
                                    </>
                                ))}
                            </TableHeader>

                            <TableBody className='bg-white'>
                                {table.getRowModel().rows?.length ? (
                                    table.getRowModel().rows.map((row) => (
                                        <>
                                            <TableRow
                                                className='h-[5%]'
                                                key={row.id}
                                                data-state={
                                                    row.getIsSelected() &&
                                                    'selected'
                                                }
                                            >
                                                {row
                                                    .getVisibleCells()
                                                    .map((cell) => (
                                                        <TableCell
                                                            key={cell.id}
                                                            className=''
                                                        >
                                                            {flexRender(
                                                                cell.column
                                                                    .columnDef
                                                                    .cell,
                                                                cell.getContext()
                                                            )}
                                                        </TableCell>
                                                    ))}
                                            </TableRow>
                                        </>
                                    ))
                                ) : (
                                    <TableRow className='h-full'>
                                        <TableCell
                                            colSpan={columns.length}
                                            className='h-24 text-center'
                                        >
                                            No data
                                        </TableCell>
                                    </TableRow>
                                )}

                                {table.getRowModel().rows?.length < 10 && (
                                    <TableRow className='h-full'></TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </form>
                </Form>

                <DialogFooter>
                    <div className='flex w-full justify-end'>
                        <div className='flex justify-end gap-2'>
                            <Button
                                variant='outline'
                                onClick={reset}
                                className='mr-2'
                            >
                                Cancel
                            </Button>

                            <Button
                                type='submit'
                                form='my-form'
                                disabled={!form.formState.isValid}
                                loading={createLoading || uploadLoading}
                            >
                                Submit
                            </Button>
                        </div>
                    </div>
                </DialogFooter>
            </DialogContent>
        </Dialog>
    );
};
