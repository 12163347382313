import { ColumnDef } from '@tanstack/react-table';
import {
    Calendar,
    Link,
    LoaderCircle,
    MessageCircleQuestion,
    Trash,
    Upload,
} from 'lucide-react';
import { useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useRemoveLink } from 'src/app/_api_adb2c/purchase/link/hooks/use-remove-link';
import { useSalesOrders } from 'src/app/_api_adb2c/sales/sales/hooks/use-sales-orders';
import { SalesOrderModel } from 'src/app/_api_adb2c/sales/sales/models/sales-order.model';
import { TableV2 } from 'src/app/components-v2/table-v2';
import { TooltipWrapper } from 'src/app/components/TooltipWrapper';
import { generateOrderTitle } from 'src/app/utils/generate-order-title';
import { Progress } from 'src/components/ui/progress';
import {
    Tooltip,
    TooltipContent,
    TooltipProvider,
    TooltipTrigger,
} from 'src/components/ui/tooltip';
import { SalesOrderDocuments } from './Documents';
import { SalesOrderLink } from './sales-order-link';
import { SalesOrderManageShipments } from './sales-order-manage-shipments';

export const SalesOrders = () => {
    const [searchParams] = useSearchParams();
    const delegateId = searchParams.get('delegateId');

    const { data, isLoading, isRefetching } = useSalesOrders(delegateId || '');
    const memoizedData = useMemo(() => data || [], [data]);

    const { mutateAsync: deleteLink, isLoading: isRemoving } = useRemoveLink();

    const [selectedItem, setSelectedItem] = useState<SalesOrderModel>();

    const [mode, setMode] = useState<'link' | 'upload' | 'shipment'>();

    const removeLink = async (id: string, order: string) => {
        await deleteLink({
            delegateId: delegateId || '',
            orderId: order,
            purchaseId: id,
        });
    };

    const columns: ColumnDef<SalesOrderModel>[] = [
        {
            id: 'Po Number',
            header: 'PO Number',
            accessorFn: (row) => generateOrderTitle(row),
        },
        {
            id: 'buyer',
            header: 'Buyer',
            accessorFn: (row) => row.customer.buyer.name,
        },
        {
            id: 'documentCompletion',
            header: 'Document Completion',
            cell: ({ row }) => {
                return <Progress value={100} />;
            },
        },
        {
            id: 'poLinked',
            header: 'PO Linked',
            cell: ({ row }) => (
                <div className='text-center'>
                    <TooltipProvider>
                        <Tooltip>
                            <TooltipTrigger asChild>
                                <div className='flex items-center justify-center gap-2 text-center'>
                                    {row.original.links?.length || 0}
                                    {row.original.links?.length !== 0 && (
                                        <MessageCircleQuestion
                                            size={16}
                                            className='hover:scale-125'
                                        />
                                    )}
                                    {/* <MessageCircleQuestion size={16} /> */}
                                </div>
                            </TooltipTrigger>

                            <TooltipContent className='flex w-56 border bg-white px-2 py-2 text-black shadow'>
                                <div className='flex w-full flex-col gap-2'>
                                    <span className='text-left text-sm underline decoration-red-800 decoration-2 underline-offset-2'>
                                        Linked Purchase Orders
                                    </span>

                                    <div className='flex flex-col gap-2'>
                                        {row.original.links?.map((link) => (
                                            <div className='flex items-center justify-between gap-2'>
                                                <span>
                                                    {generateOrderTitle(link)}
                                                </span>

                                                {isRemoving ? (
                                                    <>
                                                        <LoaderCircle
                                                            size={16}
                                                            className='animate-spin'
                                                        />
                                                    </>
                                                ) : (
                                                    <Trash
                                                        size={16}
                                                        className='hover:scale-125'
                                                        onClick={() => {
                                                            removeLink(
                                                                link._id,
                                                                row.original
                                                                    .purchaseOrderId
                                                            );
                                                        }}
                                                    />
                                                )}
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </TooltipContent>
                        </Tooltip>
                    </TooltipProvider>
                </div>
            ),
        },
        {
            id: 'documentCount',
            meta: {
                align: 'center',
            },
            header: 'Documents',
            cell: ({ row }) => (
                <div className='text-center'>
                    {row.original.documents.length}
                </div>
            ),
        },
        {
            id: 'shipments',
            header: 'Shipments',
            cell: ({ row }) => (
                <div className='text-center'>
                    {row.original.shippedOn?.length}
                </div>
            ),
        },
        {
            id: 'actions',
            header: 'Actions',
            cell: ({ row }) => {
                return (
                    <div className='flex items-center gap-4'>
                        <TooltipWrapper label='Link POs'>
                            <Link
                                size={16}
                                className='cursor-pointer hover:scale-125'
                                onClick={() => {
                                    setSelectedItem(row.original);
                                    setMode('link');
                                }}
                            />
                        </TooltipWrapper>

                        <TooltipWrapper label='Upload Documents'>
                            <Upload
                                size={16}
                                onClick={() => {
                                    setSelectedItem(row.original);
                                    setMode('upload');
                                }}
                                className='cursor-pointer hover:scale-125'
                            />
                        </TooltipWrapper>

                        <TooltipWrapper label='Manage Shipments'>
                            <Calendar
                                size={16}
                                onClick={() => {
                                    setSelectedItem(row.original);
                                    setMode('shipment');
                                }}
                                className='cursor-pointer hover:scale-125'
                            />
                        </TooltipWrapper>
                    </div>
                );
            },
        },
    ];

    return (
        <div className='flex h-full flex-col'>
            <TableV2
                label='Received Orders'
                columns={columns}
                data={memoizedData || []}
                isLoading={isLoading || isRefetching}
                controls={{
                    enableDelegation: true,
                    enableSearch: true,
                }}
            />

            {mode === 'link' && selectedItem && (
                <SalesOrderLink
                    open={true}
                    salesOrderId={selectedItem._id}
                    onClose={() => setMode(undefined)}
                />
            )}

            {mode === 'upload' && selectedItem && (
                <SalesOrderDocuments
                    open={true}
                    // data={selectedItem}
                    salesOrderId={selectedItem._id}
                    onClose={() => setMode(undefined)}
                />
            )}

            {mode === 'shipment' && selectedItem && (
                <SalesOrderManageShipments
                    open={true}
                    data={selectedItem}
                    onClose={() => setMode(undefined)}
                />
            )}
        </div>
    );
};
