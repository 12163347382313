export enum CompanyProcessType {
    AGENT = 'AGENT',
    ALUMINIUM_EXTRUSION = 'ALUMINIUM_EXTRUSION',
    ASSEMBLY = 'ASSEMBLY',
    BEAM_HOUSE = 'BEAM_HOUSE',
    BRAND = 'BRAND',
    BUTTON_SUPPLIER = 'BUTTON_SUPPLIER',
    CARE_LABEL_SUPPLIER = 'CARE_LABEL_SUPPLIER',
    CASTING = 'CASTING',
    CHEMICAL_SUPPLIER_RECYCLER = 'CHEMICAL_SUPPLIER_RECYCLER',
    CHEMICAL_SUPPLIER_FABRIC_PROCESSING = 'CHEMICAL_SUPPLIER_FABRIC_PROCESSING',
    CHEMICAL_SUPPLIER_RAW_MATERIAL_PROCESSING = 'CHEMICAL_SUPPLIER_RAW_MATERIAL_PROCESSING',
    CHEMICAL_SUPPLIER_YARN_PROCESSING = 'CHEMICAL_SUPPLIER_YARN_PROCESSING',
    COMBING_MILL = 'COMBING_MILL',
    CRUSTING_TANNERY = 'CRUSTING_TANNERY',
    CUSHION_SUPPLIER = 'CUSHION_SUPPLIER',
    CUT_MAKE_TRIM = 'CUT_MAKE_TRIM',
    DISTRIBUTOR = 'DISTRIBUTOR',
    ELASTIC_RIBBON_SUPPLIER = 'ELASTIC_RIBBON_SUPPLIER',
    EMBROIDERER = 'EMBROIDERER',
    FABRIC_COATING = 'FABRIC_COATING',
    FABRIC_DYEING = 'FABRIC_DYEING',
    FABRIC_FINISHING = 'FABRIC_FINISHING',
    FABRIC_MILL = 'FABRIC_MILL',
    FABRIC_PRINTING = 'FABRIC_PRINTING',
    FABRIC_TRADING = 'FABRIC_TRADING',
    FARM = 'FARM',
    FINISHED_GOODS_FACTORY = 'FINISHED_GOODS_FACTORY',
    FINISHED_GOODS_TRADER = 'FINISHED_GOODS_TRADER',
    FOAM_SUPPLIER = 'FOAM_SUPPLIER',
    FULFILLMENT_CENTER = 'FULFILLMENT_CENTER',
    HANGTAG_SUPPLIER = 'HANGTAG_SUPPLIER',
    GARMENT_CHEMCIAL_SUPPLIER = 'GARMENT_CHEMCIAL_SUPPLIER',
    GARMENT_PRINTER = 'GARMENT_PRINTER',
    GARMENT_FINISHING = 'GARMENT_FINISHING',
    GINNIMG_MILL = 'GINNIMG_MILL',
    INJECTION_MOLD_FACTORY = 'INJECTION_MOLD_FACTORY',
    LABEL_SUPPLIER = 'LABEL_SUPPLIER',
    LACES_SUPPLIER = 'LACES_SUPPLIER',
    LEATHER_CHEMICAL_SUPPLIER = 'LEATHER_CHEMICAL_SUPPLIER',
    LEATHER_FINISHIHG = 'LEATHER_FINISHIHG',
    LEATHER_TANNER = 'LEATHER_TANNER',
    LEATHER_TRADER = 'LEATHER_TRADER',
    LINING_SUPPLIER = 'LINING_SUPPLIER',
    KNITTING_MILL = 'KNITTING_MILL',
    LASER_PROCESSING = 'LASER_PROCESSING',
    LAUNDRY = 'LAUNDRY',
    MINE = 'MINE',
    METAL_GOODS_FACTORY = 'METAL_GOODS_FACTORY',
    PACKAGING_SUPPLIER = 'PACKAGING_SUPPLIER',
    PACKAGING_MATERIAL_SUPPLIER = 'PACKAGING_MATERIAL_SUPPLIER',
    PATTERN_MAKING = 'PATTERN_MAKING',
    PLATING = 'PLATING',
    POCKET_SUPPLIER = 'POCKET_SUPPLIER',
    POLISHING = 'POLISHING',
    PRINTER = 'PRINTER',
    QUALITY_ASSURANCE = 'QUALITY_ASSURANCE',
    RANCH = 'RANCH',
    RAW_MATERIAL_SUPPLIER = 'RAW_MATERIAL_SUPPLIER',
    RAW_MATERIAL_TRADING = 'RAW_MATERIAL_TRADING',
    RECYCLING_FACILITY = 'RECYCLING_FACILITY',
    REFINERY = 'REFINERY',
    RETAILER = 'RETAILER',
    RIVET_SUPPLIER = 'RIVET_SUPPLIER',
    SEWING = 'SEWING',
    SLAUTHER_HOUSE = 'SLAUTHER_HOUSE',
    SOLE_SUPPLIER = 'SOLE_SUPPLIER',
    SPINNING_MILL = 'SPINNING_MILL',
    THREAD_SUPPLIER = 'THREAD_SUPPLIER',
    TOP_MAKING = 'TOP_MAKING',
    WEAVING_MILL = 'WEAVING_MILL',
    WET_BLUE_TANNERY = 'WET_BLUE_TANNERY',
    WET_WHITE_TANNERY = 'WET_WHITE_TANNERY',
    YARN_DYEING = 'YARN_DYEING',
    YARN_SPINNER = 'YARN_SPINNER',
    YARN_TRADING = 'YARN_TRADING',
    ZIPPER_TRADING = 'ZIPPER_TRADING',
    WHOLESALER = 'WHOLESALER',
    OTHERS = 'OTHERS',
    /*********/
    RAW_MATERIAL_PRODUCER = 'RAW_MATERIAL_PRODUCER',
    MATERIAL_CALENDERING = 'MATERIAL_CALENDERING',
    TANNERY = 'TANNERY',
    PATCH_SUPPLIER = 'PATCH_SUPPLIER',
    ZIPPER_SUPPLIER = 'ZIPPER_SUPPLIER',
    ELASTICS_AND_RIBBON_SUPPLIER = 'ELASTICS_AND_RIBBON_SUPPLIER',
    SOURCING_AGENCY = 'SOURCING_AGENCY',
    /*********/
    INJECTION = 'INJECTION',
    CUTTING_AND_ASSEMBLY = 'CUTTING_AND_ASSEMBLY',
    GLUING_AND_CUTTING = 'GLUING_AND_CUTTING',
    DYEING = 'DYEING',
    QUALITY_CHECK = 'QUALITY_CHECK',
    FINAL_PACKING = 'FINAL_PACKING',
    FILL_THE_INK = 'FILL_THE_INK',
    PRINTING = 'PRINTING',
}

export const CompanyProcessTypeLabel: Record<CompanyProcessType, string> = {
    [CompanyProcessType.AGENT]: 'Agent',
    [CompanyProcessType.ALUMINIUM_EXTRUSION]: 'Aluminium Extrusion',
    [CompanyProcessType.ASSEMBLY]: 'Assembly',
    [CompanyProcessType.BEAM_HOUSE]: 'Beam House',
    [CompanyProcessType.BRAND]: 'Brand',
    [CompanyProcessType.BUTTON_SUPPLIER]: 'Button Supplier',
    [CompanyProcessType.CARE_LABEL_SUPPLIER]: 'Care Label Supplier',
    [CompanyProcessType.CASTING]: 'Casting',
    [CompanyProcessType.CHEMICAL_SUPPLIER_RECYCLER]:
        'Chemical Supplier Recycler',
    [CompanyProcessType.CHEMICAL_SUPPLIER_FABRIC_PROCESSING]:
        'Chemical Supplier Fabric Processing',
    [CompanyProcessType.CHEMICAL_SUPPLIER_RAW_MATERIAL_PROCESSING]:
        'Chemical Supplier Raw Material Processing',
    [CompanyProcessType.CHEMICAL_SUPPLIER_YARN_PROCESSING]:
        'Chemical Supplier Yarn Processing',
    [CompanyProcessType.COMBING_MILL]: 'Combing Mill',
    [CompanyProcessType.CRUSTING_TANNERY]: 'Crusting Tannery',
    [CompanyProcessType.CUSHION_SUPPLIER]: 'Cushion Supplier',
    [CompanyProcessType.CUT_MAKE_TRIM]: 'Cut Make Trim',
    [CompanyProcessType.DISTRIBUTOR]: 'Distributor',
    [CompanyProcessType.ELASTIC_RIBBON_SUPPLIER]: 'Elastic Ribbon Supplier',
    [CompanyProcessType.EMBROIDERER]: 'Embroiderer',
    [CompanyProcessType.FABRIC_COATING]: 'Fabric Coating',
    [CompanyProcessType.FABRIC_DYEING]: 'Fabric Dyeing',
    [CompanyProcessType.FABRIC_FINISHING]: 'Fabric Finishing',
    [CompanyProcessType.FABRIC_MILL]: 'Fabric Mill',
    [CompanyProcessType.FABRIC_PRINTING]: 'Fabric Printing',
    [CompanyProcessType.FABRIC_TRADING]: 'Fabric Trading',
    [CompanyProcessType.FARM]: 'Farm',
    [CompanyProcessType.FINISHED_GOODS_FACTORY]: 'Finished Goods Factory',
    [CompanyProcessType.FINISHED_GOODS_TRADER]: 'Finished Goods Trader',
    [CompanyProcessType.FOAM_SUPPLIER]: 'Foam Supplier',
    [CompanyProcessType.FULFILLMENT_CENTER]: 'Fulfillment Center',
    [CompanyProcessType.HANGTAG_SUPPLIER]: 'Hangtag Supplier',
    [CompanyProcessType.GARMENT_CHEMCIAL_SUPPLIER]: 'Garment Chemcial Supplier',
    [CompanyProcessType.GARMENT_PRINTER]: 'Garment Printer',
    [CompanyProcessType.GARMENT_FINISHING]: 'Garment Finishing',
    [CompanyProcessType.GINNIMG_MILL]: 'Ginnimg Mill',
    [CompanyProcessType.INJECTION_MOLD_FACTORY]: 'Injection Mold Factory',
    [CompanyProcessType.LABEL_SUPPLIER]: 'Label Supplier',
    [CompanyProcessType.LACES_SUPPLIER]: 'Laces Supplier',
    [CompanyProcessType.LEATHER_CHEMICAL_SUPPLIER]: 'Leather Chemical Supplier',
    [CompanyProcessType.LEATHER_FINISHIHG]: 'Leather Finishihg',
    [CompanyProcessType.LEATHER_TANNER]: 'Leather Tanner',
    [CompanyProcessType.LEATHER_TRADER]: 'Leather Trader',
    [CompanyProcessType.LINING_SUPPLIER]: 'Lining Supplier',
    [CompanyProcessType.KNITTING_MILL]: 'Knitting Mill',
    [CompanyProcessType.LASER_PROCESSING]: 'Laser Processing',
    [CompanyProcessType.LAUNDRY]: 'Laundry',
    [CompanyProcessType.MINE]: 'Mine',
    [CompanyProcessType.METAL_GOODS_FACTORY]: 'Metal Goods Factory',
    [CompanyProcessType.PACKAGING_SUPPLIER]: 'Packaging Supplier',
    [CompanyProcessType.PACKAGING_MATERIAL_SUPPLIER]:
        'Packaging Material Supplier',
    [CompanyProcessType.PATTERN_MAKING]: 'Pattern Making',
    [CompanyProcessType.PLATING]: 'Plating',
    [CompanyProcessType.POCKET_SUPPLIER]: 'Pocket Supplier',
    [CompanyProcessType.POLISHING]: 'Polishing',
    [CompanyProcessType.PRINTER]: 'Printer',
    [CompanyProcessType.QUALITY_ASSURANCE]: 'Quality Assurance',
    [CompanyProcessType.RANCH]: 'Ranch',
    [CompanyProcessType.RAW_MATERIAL_SUPPLIER]: 'Raw Material Supplier',
    [CompanyProcessType.RAW_MATERIAL_TRADING]: 'Raw Material Trading',

    [CompanyProcessType.RECYCLING_FACILITY]: 'Recycling Facility',
    [CompanyProcessType.REFINERY]: 'Refinery',
    [CompanyProcessType.RETAILER]: 'Retailer',
    [CompanyProcessType.RIVET_SUPPLIER]: 'Rivet Supplier',
    [CompanyProcessType.SEWING]: 'Sewing',
    [CompanyProcessType.SLAUTHER_HOUSE]: 'Slauther House',
    [CompanyProcessType.SOLE_SUPPLIER]: 'Sole Supplier',
    [CompanyProcessType.SPINNING_MILL]: 'Spinning Mill',
    [CompanyProcessType.THREAD_SUPPLIER]: 'Thread Supplier',
    [CompanyProcessType.TOP_MAKING]: 'Top Making',
    [CompanyProcessType.WEAVING_MILL]: 'Weaving Mill',
    [CompanyProcessType.WET_BLUE_TANNERY]: 'Wet Blue Tannery',
    [CompanyProcessType.WET_WHITE_TANNERY]: 'Wet White Tannery',
    [CompanyProcessType.YARN_DYEING]: 'Yarn Dyeing',
    [CompanyProcessType.YARN_SPINNER]: 'Yarn Spinner',
    [CompanyProcessType.YARN_TRADING]: 'Yarn Trading',
    [CompanyProcessType.ZIPPER_TRADING]: 'Zipper Trading',
    [CompanyProcessType.WHOLESALER]: 'Wholesaler',
    [CompanyProcessType.OTHERS]: 'Others',
    /*********/
    [CompanyProcessType.RAW_MATERIAL_PRODUCER]: 'Raw Material Producer',
    [CompanyProcessType.MATERIAL_CALENDERING]: 'Material Calendering',
    [CompanyProcessType.TANNERY]: 'Tannery',
    [CompanyProcessType.PATCH_SUPPLIER]: 'Patch Supplier',
    [CompanyProcessType.ZIPPER_SUPPLIER]: 'Zipper Supplier',
    [CompanyProcessType.ELASTICS_AND_RIBBON_SUPPLIER]:
        'Elastics And Ribbon Supplier',
    [CompanyProcessType.SOURCING_AGENCY]: 'Sourcing Agency',
    /*********/
    [CompanyProcessType.INJECTION]: 'Injection',
    [CompanyProcessType.CUTTING_AND_ASSEMBLY]: 'Cutting And Assembly',
    [CompanyProcessType.GLUING_AND_CUTTING]: 'Gluing And Cutting',
    [CompanyProcessType.DYEING]: 'Dyeing',
    [CompanyProcessType.QUALITY_CHECK]: 'Quality Check',
    [CompanyProcessType.FINAL_PACKING]: 'Final Packing',
    [CompanyProcessType.FILL_THE_INK]: 'Fill The Ink',
    [CompanyProcessType.PRINTING]: 'Printing',
};
