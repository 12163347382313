import { AttachmentModel } from 'src/app/_api_adb2c/attachment/attachment.model';
import { SupportDocType } from 'src/app/_api_adb2c/shared/support-doc-type.enum';
import { WorkspaceModel } from '../../category/category.model';
import { MaterialModel } from '../../material/models/material.model';

export enum IndustryType {
    APPAREL = 'APPAREL',
    HARDGOODS = 'HARDGOODS',
    OTHERS = 'OTHERS',
}

export enum UsageNature {
    PACKAGING = 'PACKAGING',
    PRODUCT = 'PRODUCT',
}

export const UsageNatureLabels = {
    [UsageNature.PACKAGING]: 'Packaging',
    [UsageNature.PRODUCT]: 'Product',
};

export const IndustryTypeLabels = {
    [IndustryType.APPAREL]: 'Apparel',
    [IndustryType.HARDGOODS]: 'Hardgoods',
    [IndustryType.OTHERS]: 'Others',
};

export interface ProductModel {
    _id: string;
    category: ProductCategoryModel;
    collection?: string;
    color?: string;
    comments?: IComments[];
    createdOn?: Date;
    delegatedOwner?: string;
    deletedOn?: Date;
    description?: string;
    documents: ProductDocModel[];
    enabled?: boolean;
    externalReferences?: IExternalReference[];
    hsCode?: string;
    isDelegated?: boolean;
    lastUpdatedOn: Date;
    name: string;
    pictures?: IFile[];
    sku?: string;
    tags?: string[];
    unitOfMeasurement?: string;
    measureValue: number;
    upc?: string;
    approvedBy?: string;
    approvedOn?: Date;
    status?: ProductStatus;
    versions?: ProductVersionModel[];
    workspace: WorkspaceModel;
    version: ProductVersionModel;
    isApparel?: boolean;
}

export enum ProductStatus {
    DRAFT = 'draft',
    PENDING = 'pending',
    RELEASED = 'released',
}

export const ProductStatusLabels: Record<ProductStatus, string> = {
    [ProductStatus.DRAFT]: 'Draft',
    [ProductStatus.PENDING]: 'Pending',
    [ProductStatus.RELEASED]: 'Released',
};

export interface ProductDocModel {
    _id: string;
    comment?: string;
    createdOn?: Date;
    deletedOn?: Date;
    file: AttachmentModel;
    lastUpdatedOn?: Date;
    type: SupportDocType;
    uploader: string;
    certificateNumber?: string;
}

interface IComments {
    author: string;
    comment: string;
    createdOn?: Date;
    rating?: number;
}

export interface IExternalReference {
    value: string;
    source: string;
}

export interface IFile {
    assetName: string;
    autoResign?: boolean;
    container: string;
    contentType: string;
    extension: string;
    originalName: string;
    readReleaseTime: number;
}

export interface IMaterialComposition {
    isCertificationRequired?: boolean;
    composition: string;
    percentage: number;
    weightPerUnit?: number;
}

export interface IProductProcess {
    name: string;
    translatedName?: ITranslatedValue[];
}

export interface ISpecifications {
    depth?: number;
    height?: number;
    weight?: number;
    width?: number;
}

export interface ITranslatedValue {
    locale: string;
    value: string;
}

export interface IProductProcessGroup {
    name: string;
    processes: IProductProcess[];
    sequence: number;
    translatedName: ITranslatedValue[];
}

export interface IProductProcessGroup {
    name: string;
    processes: IProductProcess[];
    sequence: number;
    translatedName: ITranslatedValue[];
}

export interface ProductVersionModel {
    _id: string;
    billOfMaterials?: ProductBillOfMaterialEntry[];
    costOfMaterials?: number;
    createdOn: Date;
    releasedOn?: Date;
    specifications?: ISpecifications;
    version: number;
}

export interface ProductCompositionEntry {
    isCertificationRequired: boolean;
    material: string;
    percentage: number;
    weight: number;
    t4sMaterialId: string;
    t4sProductId: string;
}

export interface ProductBillOfMaterialEntry {
    _id: string;
    comments?: string;
    consumption?: number;
    description?: string;
    documents: ProductDocModel[];
    isCertificationRequired: boolean;
    material: MaterialModel;
    materialCost?: number;
    name: string;
    resource: string;
    source: string; // local / external
    supplier?: string;
    unitOfMeasurement: string;
    unitToWeightFactor?: number;
    wastage?: number;
    weight?: number;
    isCloned?: boolean;
    usageNature?: UsageNature;
}

export interface IMaterialComposition {
    isCertificationRequired?: boolean;
    composition: string;
    percentage: number;
    weightPerUnit?: number;
}

export interface ProductCategoryModel {
    _id: string;
    code?: string;
    createdOn?: Date;
    defaultUnitOfMeasurement: string;
    description?: string;
    industry: IndustryType;
    lastUpdatedOn?: Date;
    name: string;
    processes?: IProductProcessGroup[];
    translatedName?: ITranslatedValue[];
    workspace: string;
}
