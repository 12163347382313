import { useDeleteDocument } from 'src/app/_api_adb2c/workspace/documents/hooks/use-delete-document';
import { WorkspaceFileModel } from './workspace-documents';
import { Button } from 'src/components/ui/button';
import {
    Dialog,
    DialogContent,
    DialogFooter,
    DialogTitle,
} from 'src/components/ui/dialog';

interface Props {
    open: boolean;
    onClose: () => void;
    document: WorkspaceFileModel;
}

export const DeleteDocument = ({ open, onClose, document }: Props) => {
    const { mutateAsync } = useDeleteDocument();

    const remove = async () => {
        await mutateAsync({
            documentId: document._id,
        });

        onClose();
    };

    return (
        <Dialog open={open} onOpenChange={onClose}>
            <DialogContent>
                <DialogTitle className='text-md'>Remove Ruleset</DialogTitle>

                <span className='text-sm'>
                    Are you sure you want to remove this ruleset?
                </span>

                <DialogFooter>
                    <Button size='sm' variant='destructive' onClick={remove}>
                        Remove
                    </Button>
                </DialogFooter>
            </DialogContent>
        </Dialog>
    );
};
