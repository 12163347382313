import { useQuery } from '@tanstack/react-query';
import { toast } from 'sonner';
import { PaginatedResponse } from 'src/app/_api_adb2c/purchase/purchase-item/purchaseable.model';
import { useContextStore } from 'src/app/stores/context-store';
import { MaterialModel } from '../models/material.model';
import { MATERIALS_QUERY_KEY } from './material-query-keys';
import { useMaterialService } from './use-material-service';

export function useMaterials(delegateId?: string) {
    const context = useContextStore();
    const { service } = useMaterialService();

    return useQuery<PaginatedResponse<MaterialModel>>({
        queryKey: MATERIALS_QUERY_KEY(
            delegateId || context.workspace?._id || ''
        ),
        queryFn: async () => {
            return await service.listByWorkspace(
                delegateId || context.workspace?._id || ''
            );
        },
        onError: (err) => {
            const error = err as Error;

            toast.error(`Failed to load materials: ${error.message}`);
        },
    });
}
