import { ServiceConfigInterface } from 'src/domain/interfaces/config.context.interface';
import { DelegationCreateObject } from './delegation-create-object';
import { DelegationApiClient } from './delegation.client';
import { WorkspaceDelegationModel } from './delegation.model';

export class DelegationService {
    private server: DelegationApiClient;

    constructor(config: ServiceConfigInterface, token: string) {
        this.server = new DelegationApiClient(config, token);
    }

    async create(
        workspace: string,
        body: DelegationCreateObject,
        diversion: string = '0'
    ) {
        console.log(`service<delegation>| create(): Enter`);
        console.log(`service<delegation>| create(): $workspace = ${workspace}`);
        console.log(
            `service<delegation>| create(): $body = ${JSON.stringify(body)}`
        );
        console.log(`service<delegation>| create(): $diversion = ${diversion}`);

        return await this.server.create(workspace, diversion, body);
    }

    async list(
        workspace: string,
        diversion: string = '0'
    ): Promise<WorkspaceDelegationModel[]> {
        console.log(`service<delegation>| list(): Enter`);
        console.log(`service<delegation>| list(): $workspace = ${workspace}`);
        console.log(`service<delegation>| list(): $diversion = ${diversion}`);

        return await this.server.list(workspace, diversion);
    }

    async update(
        workspace: string,
        item: string,
        body: DelegationCreateObject,
        diversion: string = '0'
    ) {
        console.log(`service<delegation>| update(): Enter`);
        console.log(`service<delegation>| update(): $workspace = ${workspace}`);
        console.log(`service<delegation>| update(): $item = ${item}`);
        console.log(
            `service<delegation>| update(): $body = ${JSON.stringify(body)}`
        );
        console.log(`service<delegation>| update(): $diversion = ${diversion}`);
        return await this.server.update(workspace, item, diversion, body);
    }

    async validate(workspace: string, item: string, diversion: string = '0') {
        console.log(`service<delegation>| validate(): Enter`);
        console.log(
            `service<delegation>| validate(): $workspace = ${workspace}`
        );
        console.log(`service<delegation>| validate(): $item = ${item}`);
        console.log(
            `service<delegation>| validate(): $diversion = ${diversion}`
        );

        return await this.server.validate(workspace, item, diversion);
    }

    async remove(workspace: string, item: string, diversion: string = '0') {
        console.log(`service<delegation>| remove(): Enter`);
        console.log(`service<delegation>| remove(): $workspace = ${workspace}`);
        console.log(`service<delegation>| remove(): $item = ${item}`);
        console.log(`service<delegation>| remove(): $diversion = ${diversion}`);

        return await this.server.remove(workspace, item, diversion);
    }
}
