import { useAccount } from '@azure/msal-react';
import { useMemo } from 'react';
import useAppConfig from 'src/infrastructure/hooks/use-config.hook';
import { ProductApprovalService } from '../product-approval.service';

export function useProductApprovalService() {
    const config = useAppConfig();
    const account = useAccount();

    const service = useMemo(() => {
        if (!account?.idToken) throw new Error('Session Expired');

        return new ProductApprovalService(config, account.idToken);
    }, [account, config]);

    return { service };
}
