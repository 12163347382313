import { useContextStore } from 'src/app/stores/context-store';
import { usePurchaseItemService } from './use-purchase-item-service';
import { useQuery } from '@tanstack/react-query';
import { PURCHASE_ITEMS_CASCADE_QUERY_KEY } from './purchase-item-query-keys';
import { toast } from 'sonner';
import { CommentModel } from '../../suppliers/models/comment,model';
import { ExternalReferenceModel } from '../../purchase/models/external-reference.model';

interface PurchaseItemCascade {
    order: string;
    reference: ExternalReferenceModel[];
    tiers: {
        tier: number;
        suppliers: ISupplier[];
    }[];
}

interface ISupplier {
    _id: string;
    comments: CommentModel[];
    createdOn: Date;
    deletedOn?: Date;
    engagedOn?: Date;
    isNominated: boolean;
    lastUpdatedOn?: Date;
    request: string;
    contact: string;
    seller: string;
    tags: string[];
    workspace: string;
    orders: {
        _id: string;
        parentOrderReference: string;
        nature: string;
        processes: string[];
        reference: ExternalReferenceModel[];
        rules: string[];
        supplier: {
            seller: {
                name: string;
            };
            name: string;
        };
        workspace: string;
    }[];
}

export function usePurchaseItemCascade(
    product: string,
    delegateId?: string,
    diversion: string = '0'
) {
    const { workspace } = useContextStore();
    const { service } = usePurchaseItemService();

    return useQuery<PurchaseItemCascade[]>({
        queryKey: PURCHASE_ITEMS_CASCADE_QUERY_KEY(
            delegateId || workspace?._id || '',
            product
        ),
        queryFn: async () =>
            await service.getPurchaseableCascadeMap(
                delegateId || workspace?._id || '',
                product,
                diversion
            ),
        onError: (err) => {
            const error = err as Error;

            toast.error(
                `Failed to fetch purchase item cascade: ${error.message}`
            );
        },
        enabled: !!product,
    });
}
