import { useEffect } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import CascadeImage from 'src/assets/cascade.png';
import DocumentImage from 'src/assets/document.png';
import ProductImage from 'src/assets/products.png';
import TrackingImage from 'src/assets/tracking.png';
import {
    Tabs,
    TabsContent,
    TabsList,
    TabsTrigger,
} from 'src/components/ui/tabs';
import { usePurchase } from '../../../_api/purchase/purchase-order/hooks/use-purchase';
import { usePurchaseReverse } from '../../../_api/purchase/purchase-order/hooks/use-purchase-reverse';
import { usePurchaseTrace } from '../../../_api/purchase/purchase-order/hooks/use-purchase-trace';
import { OrderNatureType } from '../../../_api/purchase/purchase-order/purchase.model';
import { DeletePurchase } from '../delete-purchase';
import { PurchaseCascade } from './Cascade/purchase-cascade';
import { PurchaseDocumentsV2 } from './Documents/purchase-documents-v2';
import { PurchaseImportCascade } from './Import/purchase-import-cascade';
import { PurchaseManifest } from './Manifest/purchase-manifest';
import { EditPurchase } from './edit-purchase';
import { PurchaseOverview } from './purchase-overview';
import { PurchaseTracking } from './purchase-tracking';

export const getOrderNature = (value: OrderNatureType | undefined) => {
    console.log(`$value (get): ${JSON.stringify(value)}`);
    switch (value) {
        case OrderNatureType.COMPONENT:
            return 'Component';
        case OrderNatureType.PROCESSING:
            return 'Processing Service';
        case OrderNatureType.OUTSOURCE:
            return 'Outsource Service';
        default:
            return 'N/A';
    }
};

enum MenuOptions {
    MANIFEST = 'manifest',
    DOCUMENTS = 'documents',
    CASCADE = 'cascade',
    TRACKING = 'tracking',
}

const menuOptions = [
    { title: MenuOptions.MANIFEST, icon: ProductImage, alt: 'Manifest' },
    { title: MenuOptions.DOCUMENTS, icon: DocumentImage, alt: 'Documents' },
    { title: MenuOptions.CASCADE, icon: CascadeImage, alt: 'Cascade' },
    { title: MenuOptions.TRACKING, icon: TrackingImage, alt: 'Tracking' },
];

export const PurchaseDetails: React.FC = () => {
    const { id } = useParams<{ id: string }>();
    const [searchParams, setSearchParams] = useSearchParams({
        menu: MenuOptions.MANIFEST,
    });

    const delegateId = searchParams.get('delegateId') || '';

    const { data } = usePurchase({
        id: id || '',
        delegateId,
    });

    const { data: traces } = usePurchaseTrace({
        id: id || '',
        delegateId,
    });

    const { data: tiers } = usePurchaseReverse({
        id: id || '',
        delegateId,
    });

    const switchMenu = (value: string) => {
        setSearchParams((prev) => {
            prev.set('menu', value);
            return prev;
        });
    };

    useEffect(() => {
        if (!searchParams.get('menu')) {
            setSearchParams((prev) => {
                prev.set('menu', MenuOptions.MANIFEST);
                return prev;
            });
        }
    }, [searchParams, setSearchParams]);

    return (
        <div className='mt-6 flex h-full flex-col gap-4'>
            <div
                id='details-header'
                className='flex w-full flex-col gap-4 rounded border bg-white p-4 pb-6 text-[10px]'
            >
                <div className='flex items-center justify-between'>
                    <span className='text-sm font-semibold'>
                        General Information
                    </span>

                    <div className='flex items-center'>
                        <DeletePurchase order={data} />
                        <EditPurchase order={data} />
                        <PurchaseImportCascade data={data} />
                    </div>
                </div>

                <PurchaseOverview data={data} />
            </div>

            <Tabs
                value={searchParams.get('menu') || MenuOptions.MANIFEST}
                className='h-full'
                onValueChange={switchMenu}
            >
                <TabsList className='my-4'>
                    {menuOptions.map((x, index) => {
                        return (
                            <TabsTrigger value={x.title} className='w-24'>
                                <figure className='flex cursor-pointer flex-col items-center rounded capitalize'>
                                    <img
                                        src={x.icon}
                                        alt={x.alt}
                                        className='min-h-8 min-w-8 max-w-8 max-h-8 rounded-l p-1'
                                        key={index}
                                        id={`${x.alt}-${index.toString()}`}
                                    />
                                    <figcaption className='text-sm text-gray-500 dark:text-gray-400'>
                                        {x.title}
                                    </figcaption>
                                </figure>
                            </TabsTrigger>
                        );
                    })}
                </TabsList>

                <TabsContent
                    key={`menu-${MenuOptions.MANIFEST}`}
                    value={MenuOptions.MANIFEST}
                >
                    <PurchaseManifest order={data} />
                </TabsContent>

                <TabsContent
                    key={`menu-${MenuOptions.DOCUMENTS}`}
                    value={MenuOptions.DOCUMENTS}
                    className='h-[80%]'
                >
                    <PurchaseDocumentsV2 order={data} traces={traces} />
                </TabsContent>

                <TabsContent
                    key={`menu-${MenuOptions.CASCADE}`}
                    value={MenuOptions.CASCADE}
                    className='h-full'
                >
                    <PurchaseCascade
                        order={data}
                        traces={traces}
                        links={tiers}
                    />
                </TabsContent>

                <TabsContent
                    key={`menu-${MenuOptions.TRACKING}`}
                    value={MenuOptions.TRACKING}
                    className='h-full'
                >
                    <PurchaseTracking order={data} traces={traces} />
                </TabsContent>
            </Tabs>
        </div>
    );
};
