import { zodResolver } from '@hookform/resolvers/zod';
import { Edit } from 'lucide-react';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';
import { Button } from 'src/components/ui/button';
import {
    Dialog,
    DialogContent,
    DialogFooter,
    DialogHeader,
    DialogPortal,
    DialogTrigger,
} from 'src/components/ui/dialog';
import {
    Form,
    FormControl,
    FormField,
    FormItem,
    FormLabel,
    FormMessage,
} from 'src/components/ui/form';
import { Input } from 'src/components/ui/input';
import { z } from 'zod';
import { useUpdatePurchase } from '../../../../_api/purchase/purchase-order/hooks/use-update-purchase';
import {
    PurchaseOrderItemModel,
    PurchaseOrderVersionModel,
} from '../../../../_api/purchase/purchase-order/purchase.model';

type EditPurchaseItemProps = {
    order: PurchaseOrderVersionModel;
    manifest: PurchaseOrderItemModel;
};

export const EditPurchaseItem: React.FC<EditPurchaseItemProps> = ({
    order,
    manifest,
}) => {
    const [searchParams] = useSearchParams();
    const delegateId = searchParams.get('delegateId') || '';
    const [open, setOpen] = useState(false);

    const { mutateAsync } = useUpdatePurchase();

    const formSchema = z.object({
        comment: z.string().optional(),
        material: z.string(),
        ppu: z.coerce.number(),
        quantity: z.coerce.number(),
    });

    const form = useForm<z.infer<typeof formSchema>>({
        mode: 'onChange',
        resolver: zodResolver(formSchema),
        defaultValues: {
            comment: manifest.comment,
            material: manifest.purchaseables.name,
            ppu: manifest.ppu,
            quantity: manifest.quantity,
        },
    });

    const onSubmit = async (data: z.infer<typeof formSchema>) => {
        console.log(data);
        const manifest = order?.manifest.map((x) => {
            if (x.purchaseables.name === data.material) {
                return {
                    ...x,
                    comment: data.comment,
                    ppu: data.ppu,
                    quantity: data.quantity,
                };
            }

            return x;
        });

        mutateAsync({
            id: order.owner.id,
            delegateId,
            data: {
                currency: order.owner.currency,
                rules: order.owner.rules?.map((x) => x.id) || [],
                shipToAddress: order.owner.shipToAddress,
                externalDataId: order.owner.externalDataId,
                purchaseProcesses:
                    order.owner.purchaseProcesses?.map((x) => x) || [],
                orderNature: order.owner.orderNature,
                items: manifest || [],
            },
        });

        reset();
    };

    const reset = () => {
        form.reset();
        setOpen(false);
    };

    return (
        <Dialog open={open} onOpenChange={setOpen}>
            <DialogTrigger>
                <Button variant='ghost' className='p-0 px-2'>
                    <Edit
                        className='h-4 w-4 text-blue-800'
                        onClick={() => setOpen(true)}
                    />
                </Button>
            </DialogTrigger>

            <DialogPortal>
                <DialogContent className='sm:max-w-[600px]'>
                    <DialogHeader>
                        <span className='font-bold'>Edit Product Category</span>
                    </DialogHeader>

                    <Form {...form}>
                        <form
                            onSubmit={form.handleSubmit(onSubmit)}
                            id='edit-manifest-form'
                            data-testid='edit-manifest-form'
                            className='flex flex-col gap-4'
                        >
                            <FormField
                                control={form.control}
                                name='material'
                                render={({ field }) => (
                                    <FormItem>
                                        <FormLabel>Name</FormLabel>
                                        <FormControl>
                                            <Input
                                                {...field}
                                                readOnly
                                                disabled
                                            />
                                        </FormControl>
                                        <FormMessage />
                                    </FormItem>
                                )}
                            />

                            <FormField
                                control={form.control}
                                name='comment'
                                render={({ field }) => (
                                    <FormItem>
                                        <FormLabel>Comment</FormLabel>
                                        <FormControl>
                                            <Input {...field} />
                                        </FormControl>
                                        <FormMessage />
                                    </FormItem>
                                )}
                            />

                            <div className='flex flex-1 gap-2'>
                                <FormField
                                    control={form.control}
                                    name='ppu'
                                    render={({ field }) => (
                                        <FormItem className='flex-1'>
                                            <FormLabel>
                                                Price Per Unit (USD)
                                            </FormLabel>
                                            <FormControl>
                                                <Input {...field} />
                                            </FormControl>
                                            <FormMessage />
                                        </FormItem>
                                    )}
                                />

                                <FormField
                                    control={form.control}
                                    name='quantity'
                                    render={({ field }) => (
                                        <FormItem className='flex-1'>
                                            <FormLabel>Quantity</FormLabel>
                                            <FormControl>
                                                <Input {...field} />
                                            </FormControl>
                                            <FormMessage />
                                        </FormItem>
                                    )}
                                />
                            </div>
                        </form>
                    </Form>

                    <DialogFooter>
                        <div className='flex justify-end gap-2'>
                            <Button variant='outline' onClick={reset}>
                                Cancel
                            </Button>

                            <Button type='submit' form='edit-manifest-form'>
                                Confirm
                            </Button>
                        </div>
                    </DialogFooter>
                </DialogContent>
            </DialogPortal>
        </Dialog>
    );
};
