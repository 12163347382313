import { ServiceConfigInterface } from 'src/domain/interfaces/config.context.interface';
import { TemplateCreateDataObject } from './template-create-data-object';
import { TemplateApiClient } from './template.client';

export class TemplateService {
    private server: TemplateApiClient;

    constructor(config: ServiceConfigInterface, token: string) {
        this.server = new TemplateApiClient(config, token);
    }

    async listBaseTemplates() {
        console.log(`service<template>| listBaseTemplates(): Enter`);

        return await this.server.listBaseTemplates();
    }

    async list(workspace: string) {
        console.log(`service<template>| list(): Enter`);
        console.log(`service<template>| list(): $workspace = ${workspace}`);

        return await this.server.list(workspace);
    }

    async get(workspace: string, item: string) {
        console.log(`service<template>| get(): Enter`);
        console.log(`service<template>| get(): $workspace = ${workspace}`);
        console.log(`service<template>| get(): $item = ${item}`);

        return await this.server.get(workspace, item);
    }

    async create(
        workspace: string,
        template: string,
        body: TemplateCreateDataObject
    ) {
        console.log(`service<template>| create(): Enter`);
        console.log(`service<template>| create(): $workspace = ${workspace}`);
        console.log(
            `service<template>| create(): $body = ${JSON.stringify(body)}`
        );

        return await this.server.create(workspace, template, body);
    }

    async update(
        workspace: string,
        item: string,
        body: TemplateCreateDataObject
    ) {
        console.log(`service<template>| update(): Enter`);
        console.log(`service<template>| update(): $workspace = ${workspace}`);
        console.log(`service<template>| update(): $item = ${item}`);
        console.log(
            `service<template>| update(): $body = ${JSON.stringify(body)}`
        );

        return await this.server.update(workspace, item, body);
    }

    async getTemplateParameters(workspace: string, item: string) {
        console.log(`service<template>| getTemplateParameters(): Enter`);
        console.log(
            `service<template>| getTemplateParameters(): $workspace = ${workspace}`
        );
        console.log(
            `service<template>| getTemplateParameters(): $item = ${item}`
        );

        return await this.server.getTemplateParameters(workspace, item);
    }

    async remove(workspace: string, item: string) {
        console.log(`service<template>| remove(): Enter`);
        console.log(`service<template>| remove(): $workspace = ${workspace}`);
        console.log(`service<template>| remove(): $item = ${item}`);

        return await this.server.remove(workspace, item);
    }
}
