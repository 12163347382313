import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';
import { useUpdateMaterial } from 'src/app/_api_adb2c/product/material/hooks/use-update-material';
import { MaterialModel } from 'src/app/_api_adb2c/product/material/models/material.model';
import {
    ProductUom,
    ProductUomLabels,
} from 'src/app/_api_adb2c/purchase/purchase/enums/product-uom.enum';
import { DialogV2, StepItem } from 'src/app/components-v2/dialog-v2';
import { SelectInputV2 } from 'src/app/components/Form/SelectInputV2';
import { TextInputV2 } from 'src/app/components/Form/TextInputV2';
import { z } from 'zod';

interface Props {
    open: boolean;
    data: MaterialModel;
    onClose: () => void;
}

const formSchema = z.object({
    delegateId: z.string().optional(),
    name: z.string().min(1, { message: 'Material name is required' }),
    description: z.string().optional(),
    uom: z.string(),
    weight: z.coerce.number().optional(),
    cost: z.coerce.number(),
    currency: z.string(),
    width: z.coerce.number().optional(),
    height: z.coerce.number().optional(),
    length: z.coerce.number().optional(),
    pictures: z.instanceof(FileList).optional(),
});

export function EditMaterialV2({ open, onClose, data }: Props) {
    const [searchParams] = useSearchParams();
    const delegateId = searchParams.get('delegateId') || '';
    const currencies = ['USD', 'EUR', 'HKD', 'RMB', 'SGB'];
    const { mutateAsync: update, isLoading } = useUpdateMaterial();

    const form = useForm<z.infer<typeof formSchema>>({
        mode: 'onChange',
        resolver: zodResolver(formSchema),
        defaultValues: {
            name: data.name,
            description: data.description,
            uom: data.unitOfMeasurement,
            cost: data.unitCost,
            currency: data.unitCostCurrency,
            weight: data.specifications?.weight,
            width: data.specifications?.width,
            height: data.specifications?.height,
            length: data.specifications?.length,
        },
    });

    const onSubmit = async (values: z.infer<typeof formSchema>) => {
        await update({
            id: data._id,
            delegateId: delegateId,
            body: {
                name: values.name,
                description: values.description,
                unitOfMeasurement: values.uom,
                unitCost: values.cost,
                unitCostCurrency: values.currency,
                specifications: {
                    weight: values.weight,
                    width: values.width,
                    height: values.height,
                    length: values.length,
                },
                workspace: data.workspace,
                pictures: data.pictures || [],
            },
        });

        reset();
    };

    const reset = () => {
        form.reset();
        onClose();
    };

    const steps: StepItem[] = [
        {
            title: 'Component Information',
            description: 'Information to be edited',
            content: (
                <div className='space-y-4'>
                    <TextInputV2 label='Component Name' name='name' />
                    <TextInputV2 label='Description' name='description' />

                    <div className='grid grid-cols-2 gap-4'>
                        <TextInputV2 label='Cost' name='cost' type='number' />
                        <SelectInputV2
                            label='Currency'
                            name='currency'
                            options={currencies.map((x) => ({
                                label: x,
                                value: x,
                            }))}
                        />
                    </div>

                    <SelectInputV2
                        label='Unit of Measurement'
                        name='uom'
                        options={Object.values(ProductUom).map((x) => ({
                            label: ProductUomLabels[x],
                            value: x,
                        }))}
                    />

                    <TextInputV2 label='Weight' name='weight' type='number' />

                    <div className='grid grid-cols-3 gap-4'>
                        <TextInputV2 label='Width' name='width' type='number' />
                        <TextInputV2
                            label='Height'
                            name='height'
                            type='number'
                        />
                        <TextInputV2
                            label='Length'
                            name='length'
                            type='number'
                        />
                    </div>
                </div>
            ),
        },
    ];

    return (
        <DialogV2
            title='Edit Component'
            open={open}
            onClose={onClose}
            form={form}
            onSubmit={onSubmit}
            isStepDialog
            size='lg'
            steps={steps}
            isLoading={isLoading}
        />
    );
}
