import { useUpdatePurchase } from 'src/app/_api_adb2c/purchase/purchase/hooks/use-update-purchase';
import { ManifestItemModel } from 'src/app/_api_adb2c/purchase/purchase/models/manifest-item.model';
import {
    PurchaseModel,
    PurchaseOrderTraceModel,
} from 'src/app/_api_adb2c/purchase/purchase/models/purchase.model';
import { Button } from 'src/components/ui/button';
import {
    Dialog,
    DialogContent,
    DialogFooter,
    DialogTitle,
} from 'src/components/ui/dialog';

interface Props {
    order?: PurchaseModel | PurchaseOrderTraceModel;
    data?: ManifestItemModel;
    onClose: () => void;
    open: boolean;
}

export const DeletePurchaseManifestItem = ({
    order,
    data,
    onClose,
    open,
}: Props) => {
    const { mutateAsync } = useUpdatePurchase();
    const remove = async () => {
        if (!order || !data) return;

        const version = order.versions[order.versions.length - 1];
        if (!version) return;

        const manifestItems = version.manifest.filter((item) => {
            return item.product !== data.product;
        });

        await mutateAsync({
            id: order._id,
            body: {
                items: manifestItems.map((x) => ({
                    ...x,
                    product: x.product._id,
                })),
            },
        });
    };

    return (
        <Dialog open={open} onOpenChange={onClose}>
            <DialogContent>
                <DialogTitle className='text-md'>
                    Remove Manifest Item
                </DialogTitle>

                <span className='text-sm'>
                    Are you sure you want to remove this item?
                </span>

                <DialogFooter>
                    <Button size='sm' variant='destructive' onClick={remove}>
                        Remove
                    </Button>
                </DialogFooter>
            </DialogContent>
        </Dialog>
    );
};
