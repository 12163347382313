import { ServiceConfigInterface } from 'src/domain/interfaces/config.context.interface';
import { ApiServer } from 'src/infrastructure/clients/api.server';
import { PurchaseOrderUpdateDataObject } from './requests/purchase-order-update-data-object';
import { PurchaseOrderCreateDataObject } from './requests/purchase-order-create-data-object';

export class PurchaseApiClient extends ApiServer {
    constructor(config: ServiceConfigInterface, token: string) {
        super(token, config, 'purchases', 'purchase-v2');
    }

    cancelOrder(workspace: string, order: string) {
        console.log(`client<purchase>| cancelOrder(): Enter`);
        console.log(
            `client<purchase>| cancelOrder(): $workspace = ${workspace}`
        );
        console.log(`client<purchase>| cancelOrder(): $order = ${order}`);

        return super.put('cancelOrder', workspace, order, {});
    }

    loadDocumentMap(workspace: string, order: string) {
        console.log(`client<purchase>| loadDocumentMap(): Enter`);
        console.log(
            `client<purchase>| loadDocumentMap(): $workspace = ${workspace}`
        );
        console.log(`client<purchase>| loadDocumentMap(): $order = ${order}`);

        return super.get('loadDocumentMap', workspace, order);
    }

    create(
        workspace: string,
        diversion: string,
        body: PurchaseOrderCreateDataObject
    ) {
        console.log(`client<purchase>| create(): Enter`);
        console.log(`client<purchase>| create(): $workspace = ${workspace}`);
        console.log(
            `client<purchase>| create(): $body = ${JSON.stringify(body)}`
        );
        console.log(`client<purchase>| create(): $diversion = ${diversion}`);

        return super.post('create', workspace, diversion, body);
    }

    copy(workspace: string, order: string, deep: boolean) {
        console.log(`client<purchase>| copy(): Enter`);
        console.log(`client<purchase>| copy(): $workspace = ${workspace}`);
        console.log(`client<purchase>| copy(): $order = ${order}`);
        console.log(`client<purchase>| copy(): $deep = ${deep}`);

        return super.post('copy', workspace, order, { deep });
    }

    downloadTraceDocuments(workspace: string, order: string) {
        console.log(`client<purchase>| downloadTraceDocuments(): Enter`);
        console.log(
            `client<purchase>| downloadTraceDocuments(): $workspace = ${workspace}`
        );
        console.log(
            `client<purchase>| downloadTraceDocuments(): $order = ${order}`
        );

        return super.get('downloadTraceDocuments', workspace, order);
    }

    get(workspace: string, order: string, diversion: string = '0') {
        console.log(`client<purchase>| get(): Enter`);
        console.log(`client<purchase>| get(): $workspace = ${workspace}`);
        console.log(`client<purchase>| get(): $order = ${order}`);

        return super.get('get', workspace, order, diversion);
    }

    update(
        workspace: string,
        order: string,
        body: PurchaseOrderUpdateDataObject
    ) {
        console.log(`client<purchase>| update(): Enter`);
        console.log(`client<purchase>| update(): $workspace = ${workspace}`);
        console.log(`client<purchase>| update(): $order = ${order}`);
        console.log(
            `client<purchase>| update(): $body = ${JSON.stringify(body)}`
        );

        return super.patch('update', workspace, order, body);
    }

    release(workspace: string, order: string) {
        console.log(`client<purchase>| release(): Enter`);
        console.log(`client<purchase>| release(): $workspace = ${workspace}`);
        console.log(`client<purchase>| release(): $order = ${order}`);

        return super.put('release', workspace, order);
    }

    remove(workspace: string, order: string, diversion = '0') {
        console.log(`client<purchase>| remove(): Enter`);
        console.log(`client<purchase>| remove(): $workspace = ${workspace}`);
        console.log(`client<purchase>| remove(): $order = ${order}`);
        console.log(`client<purchase>| remove(): $diversion = ${diversion}`);

        return super.delete('remove', workspace, order, diversion);
    }

    getVersions(workspace: string, order: string) {
        console.log(`client<purchase>| getVersions(): Enter`);
        console.log(
            `client<purchase>| getVersions(): $workspace = ${workspace}`
        );
        console.log(`client<purchase>| getVersions(): $order = ${order}`);

        return super.get('getVersions', workspace, order);
    }

    getVersion(workspace: string, order: string, version: string) {
        console.log(`client<purchase>| getVersion(): Enter`);
        console.log(
            `client<purchase>| getVersion(): $workspace = ${workspace}`
        );
        console.log(`client<purchase>| getVersion(): $order = ${order}`);
        console.log(`client<purchase>| getVersion(): $version = ${version}`);

        return super.get('getVersion', workspace, order, version);
    }

    reverse(workspace: string, order: string) {
        console.log(`client<purchase>| reverse(): Enter`);
        console.log(`client<purchase>| reverse(): $workspace = ${workspace}`);
        console.log(`client<purchase>| reverse(): $order = ${order}`);

        return super.get('reverse', workspace, order);
    }

    listByUser(workspace: string, draft = true, page = 0, limit = 10) {
        console.log(`client<purchase>| listByUser(): Enter`);
        console.log(
            `client<purchase>| listByUser(): $workspace = ${workspace}`
        );
        console.log(`client<purchase>| listByUser(): $draft = ${draft}`);
        console.log(`client<purchase>| listByUser(): $page = ${page}`);
        console.log(`client<purchase>| listByUser(): $limit = ${limit}`);

        return super.get('listByUser', workspace, draft, page, limit);
    }

    listBySupplier(
        workspace: string,
        supplier: string,
        draft = true,
        page = 0,
        limit = 10
    ) {
        console.log(`client<purchase>| listBySupplier(): Enter`);
        console.log(
            `client<purchase>| listBySupplier(): $workspace = ${workspace}`
        );
        console.log(
            `client<purchase>| listBySupplier(): $supplier = ${supplier}`
        );
        console.log(`client<purchase>| listBySupplier(): $draft = ${draft}`);
        console.log(`client<purchase>| listBySupplier(): $page = ${page}`);
        console.log(`client<purchase>| listBySupplier(): $limit = ${limit}`);

        return super.get(
            'listBySupplier',
            workspace,
            supplier,
            draft,
            page,
            limit
        );
    }

    list(
        workspace: string,
        draft = true,
        page = 0,
        limit = 10,
        diversion = '0'
    ) {
        console.log(`client<purchase>| list(): Enter`);
        console.log(`client<purchase>| list(): $workspace = ${workspace}`);
        console.log(`client<purchase>| list(): $draft = ${draft}`);
        console.log(`client<purchase>| list(): $page = ${page}`);
        console.log(`client<purchase>| list(): $limit = ${limit}`);
        console.log(`client<purchase>| list(): $diversion = ${diversion}`);

        return super.get('list', workspace, draft, page, limit, diversion);
    }

    trace(workspace: string, order: string, diversion: string = '0') {
        console.log(`client<purchase>| trace(): Enter`);
        console.log(`client<purchase>| trace(): $workspace = ${workspace}`);
        console.log(`client<purchase>| trace(): $order = ${order}`);
        console.log(`client<purchase>| trace(): $diversion = ${diversion}`);

        return super.get('trace', workspace, order, diversion);
    }

    getCottonWeight(workspace: string, order: string) {
        console.log(`client<purchase>| getCottonWeight(): Enter`);
        console.log(
            `client<purchase>| getCottonWeight(): $workspace = ${workspace}`
        );
        console.log(`client<purchase>| getCottonWeight(): $order = ${order}`);

        return super.get('getCottonWeight', workspace, order);
    }
}
