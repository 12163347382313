import { useQuery } from '@tanstack/react-query';
import { toast } from 'sonner';
import { useContextStore } from 'src/app/stores/context-store';
import { TEMPLATES_QUERY_KEY } from './template-query-keys';
import { useTemplateService } from './use-template-service';
import {
    BaseTemplateModel,
    WorkspaceAdaptationModel,
    WorkspaceTemplateVirtualModel,
} from '../template.model';

export function useTemplates(delegateId?: string) {
    const { service } = useTemplateService();
    const context = useContextStore();

    return useQuery<WorkspaceTemplateVirtualModel[]>({
        queryKey: TEMPLATES_QUERY_KEY(
            delegateId || context.workspace?._id || ''
        ),
        queryFn: async () => {
            const templates: WorkspaceAdaptationModel[] = await service.list(
                delegateId || context.workspace?._id || ''
            );

            const base: BaseTemplateModel[] = await service.listBaseTemplates();

            const responses: WorkspaceTemplateVirtualModel[] = [];

            base.forEach((b) => {
                const template = templates.find(
                    (t) => t.template === b.template
                );

                responses.push({
                    adaptation: template,
                    base: b,
                });
            });

            templates.forEach((t) => {
                if (
                    !responses.find(
                        (r) => r.adaptation?.template === t.template
                    )
                ) {
                    responses.push({
                        adaptation: t,
                    });
                }
            });

            return responses;
        },
        onError: (err) => {
            const error = err as Error;

            toast.error(`Failed to load templates: ${error.message}`);
        },
    });
}
