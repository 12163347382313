import { ServiceConfigInterface } from 'src/domain/interfaces/config.context.interface';
import { ApiServer } from 'src/infrastructure/clients/api.server';

export class FileApiClient extends ApiServer {
    constructor(config: ServiceConfigInterface, token: string) {
        super(token, config, 'workspaces', 'file');
    }

    getBatch(workspace: string, targets: string[]) {
        console.log(`client<file>| getBatch(): Enter`);
        console.log(`client<file>| getBatch(): $workspace = ${workspace}`);
        console.log(`client<file>| getBatch(): $targets = ${targets}`);

        return super.post('getBatch', workspace, { targets });
    }
}
