/**
 * Formats an enum value into a human readable string while preserving abbreviations
 * Examples:
 * - RECYCLED_CONTENT_STANDARD -> Recycled Content Standard
 * - PRODUCT_GHS_SIGNAL -> Product GHS Signal
 * - Glqceadjbdih9zd9dh -> Glqceadjbdih9zd9dh
 * @param value The enum value to format
 * @returns The formatted string
 */
export function formatEnumValue(value: string): string {
    if (!value) return '';

    // If the string doesn't contain underscores, return it as is
    if (!value.includes('_')) return value;

    return value
        .split('_')
        .map((word) => {
            // If word is an abbreviation (all caps and length <= 3), keep it uppercase
            if (word.length <= 3 && word === word.toUpperCase()) {
                return word;
            }
            // Otherwise capitalize first letter only
            return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
        })
        .join(' ');
}
