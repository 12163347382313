import { zodResolver } from '@hookform/resolvers/zod';
import axios from 'axios';
import { useForm } from 'react-hook-form';
import { AttachmentModel } from 'src/app/_api_adb2c/attachment/attachment.model';
import { FileCreateDataObject } from 'src/app/_api_adb2c/attachment/file-create-data-object';
import { useAttachmentUpload } from 'src/app/_api_adb2c/attachment/hooks/use-attachment-upload';
import { useCreateProduct } from 'src/app/_api_adb2c/product/product/hooks/use-create-product';
import { IExternalReference } from 'src/app/_api_adb2c/product/product/models/product.model';
import { DialogV2 } from 'src/app/components-v2/dialog-v2';
import { DelegationInput } from 'src/app/components/Form/DelegationInput';
import { FileInput } from 'src/app/components/Form/FileInput';
import { useContextStore } from 'src/app/stores/context-store';
import { z } from 'zod';
import { AddProductOptionalFields } from './add-product-optional-fields';
import { AddProductRequiredFields } from './add-product-required-fields';

interface Props {
    open: boolean;
    onClose: () => void;
}

const formScheme = z.object({
    name: z.string(),
    delegateId: z.string().optional(),
    file: z.instanceof(FileList).optional(),
    // cost: z.coerce.number(),
    base: z.string(),
    category: z.string(),
    unit: z.string(),
    measureValue: z.coerce.number(),
    isActive: z.boolean().default(true),
    description: z.string().optional(),
    weight: z.coerce.number().optional(),
    color: z.string().optional(),
    externalDataId: z.string().optional(),
    hsCode: z.string().optional(),
    sku: z.string().optional(),
    upc: z.string().optional(),
    externalReferenceCode: z.string().optional(),
    collection: z.string().optional(),
    moqCode: z.string().optional(),
});

export const AddProduct = ({ open, onClose }: Props) => {
    const { workspace, user } = useContextStore();

    const { mutateAsync, isLoading } = useCreateProduct();
    const { mutateAsync: upload } = useAttachmentUpload();

    const form = useForm<z.infer<typeof formScheme>>({
        mode: 'onChange',
        resolver: zodResolver(formScheme),
    });

    const fileRef = form.register('file');

    const reset = () => {
        onClose();
        form.reset();
    };

    const onSubmit = async (data: z.infer<typeof formScheme>) => {
        const externalRefeneces: IExternalReference[] = [];
        const images: AttachmentModel[] = [];

        if (data.externalDataId) {
            externalRefeneces.push({
                source: 'externalDataId',
                value: data.externalDataId,
            });
            externalRefeneces.push({
                source: 'xts',
                value: data.externalDataId,
            });
        }

        if (data.externalReferenceCode) {
            externalRefeneces.push({
                source: 'customer',
                value: data.externalReferenceCode,
            });
        }

        if (data.moqCode) {
            externalRefeneces.push({
                source: 'moqCode',
                value: data.moqCode,
            });
        }

        if (data.file) {
            const files = Array.from(data.file).map((file) => {
                const param: FileCreateDataObject = {
                    contentType: file.type,
                    size: file.size,
                    name: file.name,
                    uploadedBy: user?._id || '',
                    workspace: workspace?._id || '',
                    genre: 'temporary',
                    file: file,
                };

                return param;
            });

            await Promise.all(
                files.map(async (file) => {
                    const response = await upload({
                        file,
                        assetType: 'product',
                        delegateId: data.delegateId || '',
                    });

                    await axios.put(response.token, file.file, {
                        headers: {
                            'x-ms-blob-type': 'BlockBlob',
                            'Content-Type': file.contentType,
                        },
                    });

                    images.push({
                        assetName: response.attachment.assetName,
                        autoResign: response.attachment.autoResign,
                        container: response.attachment.container,
                        contentType: response.attachment.contentType,
                        extension:
                            response.attachment.originalName.split('.').pop() ||
                            '',
                        originalName: response.attachment.originalName,
                        readReleaseTime: response.attachment.readReleaseTime,
                        size: file.file?.size || 0,
                    });
                })
            );
        }

        await mutateAsync({
            delegateId: data.delegateId || '',
            body: {
                category: data.category,
                collection: data.collection,
                color: data.color,
                description: data.description || '',
                enabled: data.isActive,
                externalReferences: externalRefeneces,
                hsCode: data.hsCode,
                name: data.name,
                pictures: images,
                sku: data.sku,
                tags: [],
                unitOfMeasurement: data.unit,
                workspace: workspace?._id || '',
                loaderType: workspace?.supplyChainLoaderType || 0,
                measureValue: data.measureValue,
                upc: data.upc,
                versions: [
                    {
                        costOfMaterials: 0,
                        billOfMaterials: [],
                        specifications: {
                            weight: data.weight,
                        },
                        version: 1,
                    },
                ],
            },
        });

        reset();
    };

    return (
        <DialogV2
            open={open}
            onClose={onClose}
            form={form}
            onSubmit={onSubmit}
            title='Add Product'
            isLoading={isLoading}
            isStepDialog
            steps={[
                {
                    title: 'Delegation Controls',
                    description: 'Select the delegate for this product.',
                    content: (
                        <DelegationInput
                            label='On Behalf Of.'
                            name='delegateId'
                        />
                    ),
                },
                {
                    title: 'Required Fields',
                    description: 'Enter the required fields for this product.',
                    content: <AddProductRequiredFields />,
                },
                {
                    title: 'Optional Fields',
                    description: 'Enter the optional fields for this product.',
                    content: <AddProductOptionalFields />,
                },
                {
                    title: 'Images',
                    description: 'Upload the images for this product.',
                    content: (
                        <FileInput
                            label='Upload Images'
                            name='images'
                            multiple
                            inputRef={fileRef}
                        />
                    ),
                },
            ]}
        />
    );
};
