import { useCallback, useEffect, useState } from 'react';
import ReactFlow, {
    Background,
    Controls,
    Edge,
    Node,
    useEdgesState,
    useNodesState,
} from 'reactflow';
import { Label } from 'src/components/ui/label';
import { Switch } from 'src/components/ui/switch';
import { SupplyChainNodeType } from 'src/domain/enums/supplier-node.enum';
import { getProcessValue } from 'src/infrastructure/utils/process-labeler';
import { useGetDelegations } from '../../../../_api/workspace/delegations/hooks/useGetDelegations';
import {
    OrderNatureType,
    PurchaseOrderLinkModel,
    PurchaseOrderTraceModel,
    PurchaseOrderVersionModel,
} from '../../../../_api/purchase/purchase-order/purchase.model';
import {
    PurchaseCascadeNode,
    PurchaseCascadeNodeProps,
} from './purchase-cascade-node';
import {
    PurchaseCascadeNodeGroup,
    PurchaseCascadeNodeGroupProps,
} from './purchase-cascade-node-group';
import { AddPurchase } from '../../add-purchase';
import { Minus } from 'lucide-react';
import { PurchaseCascadeClearControl } from './purchase-cascade-clear-control';

type Props = {
    order?: PurchaseOrderVersionModel;
    traces?: PurchaseOrderTraceModel[];
    links?: PurchaseOrderLinkModel[];
};

type PurchaseCascadeItem = {
    [key: string]: PurchaseCascadeDefinition;
};

type PurchaseCascadeDefinition = {
    [key: string]: {
        id: string;
        traceId?: string;
        traceSupplierId?: string;
        traceSellerSupplierId?: string;
        traceSupplierName?: string;
        traceCost?: number;
        traceItemCount?: number;
        traceShippedOn?: Date[];
        traceSheetLabelId?: string;
        isDelegate?: boolean;
    }[];
};

enum CascadeNodeOptions {
    NODE_GROUP = 'node-group',
    NODE = 'node',
}

const nodeTypes = {
    [CascadeNodeOptions.NODE_GROUP]: PurchaseCascadeNodeGroup,
    [CascadeNodeOptions.NODE]: PurchaseCascadeNode,
};

const getOrderPurchaseProcess = (order?: PurchaseOrderVersionModel) => {
    if (!order) return '';

    let purchaseProcess = '';

    if (
        order.owner.purchaseProcesses &&
        order.owner.purchaseProcesses.length > 0
    ) {
        purchaseProcess = order.owner.purchaseProcesses[0];
    } else {
        purchaseProcess = order.owner.workspace.processes[0];
    }

    return getProcessValue(purchaseProcess as unknown as SupplyChainNodeType);
};

export const PurchaseCascade: React.FC<Props> = ({ order, traces, links }) => {
    const [cascade, setCascade] = useState<PurchaseCascadeItem>({});
    const [delegateId, setDelegateId] = useState('');
    const [selectedPurchase, setSelectedPurchase] = useState('');
    const [parent, setParent] = useState(0);
    const [mode, setMode] = useState(false);
    const [nodes, setNodes, onNodesChange] = useNodesState([]);
    const [edges, setEdges, onEdgesChange] = useEdgesState([]);

    const { delegations } = useGetDelegations();

    const constructInternalOrderText = useCallback((id?: string) => {
        return `Internal Order - ${id?.slice(0, 6)}`;
    }, []);

    console.log(selectedPurchase);

    useEffect(() => {
        const item: PurchaseCascadeItem = {};

        if (traces && traces.length > 0) {
            // The parent ID is the first item, first index in the path
            const parent = traces[0].path[0];

            traces.forEach((trace) => {
                const tier = trace.path.length - 1;

                if (!item[tier]) {
                    item[tier] = {};
                }

                const purchaseProcess =
                    trace.purchaseProcesses &&
                    trace.purchaseProcesses?.length > 0
                        ? trace.purchaseProcesses[0]
                        : trace.supplier.owner?.processes?.[0] || '';

                const sourceIndex = trace.path.indexOf(trace.to);
                const source = trace.path[sourceIndex];

                if (!item[tier][purchaseProcess]) {
                    item[tier][purchaseProcess] = [];
                }

                item[tier][purchaseProcess].push({
                    id: source.toString(),
                    traceId: trace.id,
                    traceSupplierId: trace.supplier.owner?.id,
                    traceSellerSupplierId: trace.supplier.seller?.id,
                    traceSupplierName: trace.supplier.seller?.name,
                    traceCost: trace.manifest.reduce(
                        (acc, item) => acc + item.ppu * item.quantity,
                        0
                    ),
                    traceItemCount: trace.manifest.length,
                    traceShippedOn: trace.shippedOn,
                    traceSheetLabelId:
                        trace.externalDataId ??
                        constructInternalOrderText(trace.id),
                    isDelegate: !!delegations.find(
                        (x) => x.workspace?.id === trace.supplier.seller?.id
                    ),
                });

                setParent(parent);
            });

            setCascade(item);
        }
    }, [traces, constructInternalOrderText, delegations]);

    useEffect(() => {
        const orderCost =
            order?.manifest.reduce(
                (acc, item) => acc + item.ppu * item.quantity,
                0
            ) || 0;

        const consolidatedNodes: Node[] = [
            {
                id: `group-${parent}`,
                type: CascadeNodeOptions.NODE_GROUP,
                position: { x: 20, y: 20 },
                data: {
                    label: getOrderPurchaseProcess(order),
                    tier: 0,
                    height: 160,
                } as PurchaseCascadeNodeGroupProps['data'],
            },
            {
                id: `node-${parent}`,
                type: CascadeNodeOptions.NODE,
                position: { x: 8, y: 40 },
                parentNode: `group-${parent}`,
                data: {
                    label: order?.owner.supplier?.seller?.name || '',
                    purchaseId: order?.owner.id,
                    supplierId: order?.owner.supplier?.id,
                    supplierSellerId: order?.owner.supplier?.seller?.id,
                    sheetLabel: mode
                        ? order?.owner.supplier.seller?.name
                        : order?.owner.externalDataId ??
                          constructInternalOrderText(order?.owner.id),
                    totalCost:
                        orderCost +
                        (traces?.reduce(
                            (acc, item) =>
                                acc +
                                item.manifest.reduce(
                                    (acc, item) =>
                                        acc + item.ppu * item.quantity,
                                    0
                                ),
                            0
                        ) || 0),
                    totalItems: traces?.reduce(
                        (acc, item) => acc + item.manifest.length,
                        order?.manifest.length || 0
                    ),
                    mode: mode ? 'supplier' : 'order',
                    isDelegated: true,
                    setDelegateId: setDelegateId,
                    setSelectedPurchase: setSelectedPurchase,
                } as PurchaseCascadeNodeProps['data'],
                draggable: false,
            },
        ];

        Object.entries(cascade).forEach(
            ([parentKey, parentValue], parentIndex) => {
                // This is to keep track of how tall the previous node is
                let previousNodeHeight = 0;

                Object.entries(parentValue).forEach(
                    ([childKey, childValue], childIndex) => {
                        // This creates the group node for the child process
                        const groupNode: Node = {
                            id: `group-${parentKey}-${childKey}`,
                            type: CascadeNodeOptions.NODE_GROUP,
                            position: {
                                x: 20 + 250 * (parentIndex + 1),
                                y: 20 + 60 * childIndex + previousNodeHeight,
                            },
                            data: {
                                label: getProcessValue(
                                    childKey as unknown as SupplyChainNodeType
                                ),
                                height: 160 * childValue.length,
                            } as PurchaseCascadeNodeGroupProps['data'],
                        };

                        previousNodeHeight += groupNode.data.height;

                        consolidatedNodes.push(groupNode);

                        // This creates the node for the child process
                        childValue.forEach((node, childNodeIndex) => {
                            const childNode: Node = {
                                id: `node-${node.id}`,
                                parentNode: `group-${parentKey}-${childKey}`,
                                type: CascadeNodeOptions.NODE,
                                position: {
                                    x: 8,
                                    y: 40 + 160 * childNodeIndex,
                                },
                                data: {
                                    mode: mode ? 'supplier' : 'order',
                                    label: node.traceSupplierName || '',
                                    sheetLabel: mode
                                        ? node.traceSupplierName
                                        : node.traceSheetLabelId,
                                    purchaseId: node.traceId || '',
                                    supplierId: node.traceSupplierId || '',
                                    supplierSellerId:
                                        node.traceSellerSupplierId,
                                    totalCost: node.traceCost,
                                    totalItems: node.traceItemCount,
                                    shippedOn: node.traceShippedOn,
                                    isDelegated: node.isDelegate,
                                    setDelegateId: setDelegateId,
                                    setSelectedPurchase: setSelectedPurchase,
                                } as PurchaseCascadeNodeProps['data'],
                                draggable: false,
                            };

                            consolidatedNodes.push(childNode);
                        });
                    }
                );
            }
        );

        setNodes(consolidatedNodes);
    }, [
        cascade,
        setNodes,
        parent,
        order,
        mode,
        traces,
        constructInternalOrderText,
    ]);

    useEffect(() => {
        const edges: Edge[] = [];

        const getColour = (type: OrderNatureType) => {
            switch (type) {
                case OrderNatureType.OUTSOURCE:
                    return '#FF9400';
                case OrderNatureType.PROCESSING:
                    return '#007C77';
                default:
                    return '#000';
            }
        };

        traces?.forEach((item) => {
            const { path } = item;
            const source = path[path.indexOf(item.to) - 1];
            const target = path[path.indexOf(item.to)];

            edges.push({
                id: `${order?.id}-${item.id}`,
                source: `node-${source}`,
                target: `node-${target}`,
                zIndex: 1,
                type: 'smoothstep',
                style: {
                    stroke: getColour(item.orderNature),
                },
            });
        });

        setEdges(edges);
    }, [setEdges, traces, order, nodes, parent]);

    return (
        <>
            <div className='relative h-full'>
                <div className='mb-2 flex items-center justify-between'>
                    <span className='text-xs font-bold'>
                        Order (Supplier) Chain Mapping
                    </span>

                    <div className='flex items-center space-x-2'>
                        <Label htmlFor='switch' className='text-xs'>
                            Order
                        </Label>
                        <Switch
                            id='switch'
                            checked={mode}
                            defaultChecked={mode}
                            onCheckedChange={setMode}
                        ></Switch>
                        <Label htmlFor='switch' className='text-xs'>
                            Supplier
                        </Label>
                    </div>
                </div>

                <ReactFlow
                    className='h-[90%] rounded-lg border bg-white'
                    proOptions={{
                        hideAttribution: true,
                    }}
                    nodes={nodes}
                    nodeTypes={nodeTypes}
                    edges={edges}
                    minZoom={0.001}
                    onNodesChange={onNodesChange}
                    onEdgesChange={onEdgesChange}
                    defaultViewport={{
                        zoom: 0.5,
                        x: 0,
                        y: 0,
                    }}
                >
                    <Background />
                    <Controls>
                        <PurchaseCascadeClearControl
                            id={order?.owner.id || ''}
                        ></PurchaseCascadeClearControl>
                    </Controls>
                </ReactFlow>

                <div className='absolute right-4 top-12 w-40 shadow'>
                    <div className='border-b bg-gray-400 px-4 font-bold text-white'>
                        Legend
                    </div>
                    <div className='bg-white px-4'>
                        <div className='flex items-center justify-between'>
                            <Minus className='h-8 w-1/5 text-component' />
                            <span className='text-sm'>Component</span>
                        </div>

                        <div className='flex items-center justify-between'>
                            <Minus className='h-8 w-1/5 text-outsourced' />
                            <span className='text-sm'>Outsourced</span>
                        </div>

                        <div className='flex items-center justify-between'>
                            <Minus className='h-8 w-1/5 text-processing' />
                            <span className='text-sm'>Processing</span>
                        </div>
                    </div>
                </div>

                <AddPurchase
                    delegateId={delegateId}
                    setDelegateId={setDelegateId}
                    cascadeInfo={{
                        hideTrigger: true,
                        fromCascade: true,
                        poId: selectedPurchase,
                    }}
                />
            </div>
        </>
    );
};
