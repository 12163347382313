import { ServiceConfigInterface } from 'src/domain/interfaces/config.context.interface';
import { ApiServer } from 'src/infrastructure/clients/api.server';
import { VerificationRequestUpdateDataObject } from './verification-request-update-data-object';
import { DocumentUpdateDataObject } from './document-update-data-object';
import { DocumentCreateDataObject } from './document-create-data-object';

export class DocumentApiClient extends ApiServer {
    constructor(config: ServiceConfigInterface, token: string) {
        super(token, config, 'workspaces', 'document');
    }

    create(workspace: string, body: DocumentCreateDataObject) {
        console.log(`client<document>| create(): Enter`);
        console.log(`client<document>| create(): $workspace = ${workspace}`);
        console.log(`client<document>| create(): $body = ${body}`);

        return super.post('create', workspace, body);
    }

    list(workspace: string) {
        console.log(`client<document>| list(): Enter`);
        console.log(`client<document>| list(): $workspace = ${workspace}`);

        return super.get('list', workspace);
    }

    get(workspace: string, item: string) {
        console.log(`client<document>| get(): Enter`);
        console.log(`client<document>| get(): $workspace = ${workspace}`);
        console.log(`client<document>| get(): $item = ${item}`);

        return super.get('get', workspace, item);
    }

    update(workspace: string, item: string, body: DocumentUpdateDataObject) {
        console.log(`client<document>| update(): Enter`);
        console.log(`client<document>| update(): $workspace = ${workspace}`);
        console.log(`client<document>| update(): $item = ${item}`);
        console.log(`client<document>| update(): $body = ${body}`);

        return super.patch('update', workspace, item, body);
    }

    remove(workspace: string, item: string) {
        console.log(`client<document>| remove(): Enter`);
        console.log(`client<document>| remove(): $workspace = ${workspace}`);
        console.log(`client<document>| remove(): $item = ${item}`);

        return super.delete('remove', workspace, item);
    }

    getPartnerDocuments(workspace: string, partner: string) {
        console.log(`client<document>| getPartnerDocuments(): Enter`);
        console.log(
            `client<document>| getPartnerDocuments(): $workspace = ${workspace}`
        );
        console.log(
            `client<document>| getPartnerDocuments(): $partner = ${partner}`
        );

        return super.get('getPartnerDocuments', workspace, partner);
    }

    rejectVerificationRequest(workspace: string, item: string) {
        console.log(`client<document>| rejectVerificationRequest(): Enter`);
        console.log(
            `client<document>| rejectVerificationRequest(): $workspace = ${workspace}`
        );
        console.log(
            `client<document>| rejectVerificationRequest(): $item = ${item}`
        );

        return super.patch('rejectVerificationRequest', workspace, item);
    }

    verify(
        workspace: string,
        item: string,
        body: VerificationRequestUpdateDataObject
    ) {
        console.log(`client<document>| verify(): Enter`);
        console.log(`client<document>| verify(): $workspace = ${workspace}`);
        console.log(`client<document>| verify(): $item = ${item}`);
        console.log(`client<document>| verify(): $body = ${body}`);

        return super.patch('verify', workspace, item, body);
    }
}
