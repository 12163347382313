import { ColumnDef } from '@tanstack/react-table';
import { GanttChartSquare, Plus, Trash } from 'lucide-react';
import { useCallback, useMemo, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { ProductCategoriesModel } from 'src/app/_api_adb2c/product/category/category.model';
import { useCategories } from 'src/app/_api_adb2c/product/category/hooks/use-categories';
import {
    IndustryType,
    IndustryTypeLabels,
} from 'src/app/_api_adb2c/product/product/models/product.model';
import { TableV2 } from 'src/app/components-v2/table-v2';
import { NavigationLink } from 'src/app/components-v2/typography';
import { TooltipWrapper } from 'src/app/components/TooltipWrapper';
import { Checkbox } from 'src/components/ui/checkbox';
import { UnitType, UnitTypeLabel } from 'src/domain/enums/unit-type.enum';
import { AddCategory } from './add-category';
import { DeleteCategory } from './delete-category';

export const Categories = () => {
    const [searchParams] = useSearchParams();
    const delegateId = searchParams.get('delegateId');

    const navigate = useNavigate();

    const { data: categories, isLoading } = useCategories(delegateId || '');
    const memoizedData = useMemo(() => categories || [], [categories]);

    const [isCreate, setIsCreate] = useState(false);
    const [isDelete, setIsDelete] = useState(false);

    const [selectedItem, setSelectedItem] = useState<ProductCategoriesModel>();

    const handleRedirectToDetails = useCallback(
        (id: string) => {
            delegateId
                ? navigate(`/categories/${id}?delegateId=${delegateId}`)
                : navigate(`/categories/${id}`);
        },
        [navigate, delegateId]
    );

    const columns: ColumnDef<ProductCategoriesModel>[] = [
        {
            id: 'select',
            size: 100,
            header: ({ table }) => (
                <Checkbox
                    checked={table.getIsAllPageRowsSelected()}
                    onCheckedChange={(value) =>
                        table.toggleAllPageRowsSelected(!!value)
                    }
                    aria-label='Select all'
                />
            ),
            cell: ({ row }) => (
                <Checkbox
                    checked={row.getIsSelected()}
                    onCheckedChange={(value) => row.toggleSelected(!!value)}
                    aria-label='Select row'
                />
            ),
            enableSorting: false,
            enableHiding: false,
        },
        {
            id: 'name',
            accessorFn: (row) => row.name,
            header: 'Name',
            cell: ({ row }) => {
                return (
                    <NavigationLink path={`/categories/${row.original._id}`}>
                        {row.original.name}
                    </NavigationLink>
                );
            },
        },
        {
            id: 'code',
            header: 'Code',
            accessorFn: (row) => row.code,
        },
        {
            id: 'description',
            header: 'Description',
            accessorFn: (row) => row.description,
        },
        {
            id: 'unit of measurement',
            header: 'Unit of Measurement',
            accessorFn: (row) => {
                return row.defaultUnitOfMeasurement
                    ? UnitTypeLabel[
                          row.defaultUnitOfMeasurement as unknown as UnitType
                      ]
                    : '';
            },
        },
        {
            id: 'type',
            header: 'Type',
            accessorFn: (row) =>
                row.industry
                    ? IndustryTypeLabels[row.industry as IndustryType]
                    : '',
        },
        {
            id: 'actions',
            header: 'Actions',
            cell: ({ row }) => {
                const id = row.original._id;

                return (
                    <div className='flex h-full items-center gap-2'>
                        <TooltipWrapper label='View Category'>
                            <GanttChartSquare
                                data-testid='view-category-button'
                                className='cursor-pointer hover:scale-125'
                                size={16}
                                onClick={() =>
                                    handleRedirectToDetails(id || '')
                                }
                            />
                        </TooltipWrapper>

                        <TooltipWrapper label='Delete Category'>
                            <Trash
                                data-testid='delete-category-button'
                                className='cursor-pointer text-red-800 hover:scale-125'
                                size={16}
                                onClick={() => {
                                    setSelectedItem(row.original);
                                    setIsDelete(true);
                                }}
                            />
                        </TooltipWrapper>
                    </div>
                );
            },
        },
    ];

    return (
        <div className='flex h-full flex-col'>
            <TableV2
                label='Available Categories'
                columns={columns}
                data={memoizedData || []}
                isLoading={isLoading}
                controls={{
                    enableDelegation: true,
                    enableSearch: true,
                }}
                disabledTooltipColumns={['actions', 'select']}
                actions={[
                    {
                        id: 'add',
                        label: 'Add Category',
                        icon: <Plus size={16} />,
                        onClick: () => setIsCreate(true),
                    },
                ]}
            />

            {isCreate && (
                <AddCategory
                    open={isCreate}
                    onClose={() => setIsCreate(false)}
                />
            )}

            {isDelete && (
                <DeleteCategory
                    open={isDelete}
                    onClose={() => setIsDelete(false)}
                    onRemove={() => {}}
                    categoryId={selectedItem?._id || ''}
                />
            )}
        </div>
    );
};
