import { useFormContext } from 'react-hook-form';
import { MaterialModel } from 'src/app/_api_adb2c/product/material/models/material.model';
import { TextInputV2 } from 'src/app/components/Form/TextInputV2';

export function AboutSubstance({ material }: { material?: MaterialModel }) {
    const form = useFormContext();

    const calculateProjectedWeight = (value: number) => {
        const materialWeight = material?.specifications?.weight || 0;
        const projectedWeight = materialWeight * (value / 100);

        return projectedWeight;
    };

    return (
        <>
            <TextInputV2
                name='cas'
                label='CAS Number'
                placeholder='Enter CAS Number'
                readOnly
                className='cursor-not-allowed'
                tooltip='This is dependent on the substance type'
            />

            <TextInputV2
                name='percentage'
                label='% Of Component Weight'
                placeholder='Enter Percentage'
                onValueChange={(value) => {
                    const projectedWeight = calculateProjectedWeight(
                        Number(value)
                    );

                    form.setValue('projectedWeight', projectedWeight);
                }}
            />

            <TextInputV2
                name='projectedWeight'
                label='Projected Weight (KG)'
                placeholder='Enter Projected Weight'
                tooltip='This is dependent on the % of component weight'
                readOnly
                className='cursor-not-allowed'
            />
        </>
    );
}
