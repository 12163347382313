import { CaretSortIcon } from '@radix-ui/react-icons';
import { ColumnDef } from '@tanstack/react-table';
import { Check, LoaderCircle, X } from 'lucide-react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'sonner';
import { WorkspaceModel } from 'src/app/_api_adb2c/product/category/category.model';
import { useApproveApprovalRequest } from 'src/app/_api_adb2c/product/product-approval/hooks/use-approve-approval-request';
import { useProductApprovalByWorkspaceItem } from 'src/app/_api_adb2c/product/product-approval/hooks/use-product-approval-by-workspace-item';
import { useProduct } from 'src/app/_api_adb2c/product/product/hooks/use-product';
import {
    ProductBillOfMaterialEntry,
    ProductStatusLabels,
} from 'src/app/_api_adb2c/product/product/models/product.model';
import {
    ProductUom,
    ProductUomLabels,
} from 'src/app/_api_adb2c/purchase/purchase/enums/product-uom.enum';
import { DetailLabel } from 'src/app/components-v2/detail-label';
import { TableV2 } from 'src/app/components-v2/table-v2';
import {
    ConsolidatedComposition,
    VirtualWorkspaceComposition,
} from 'src/app/pages-v2/Products/Details/Substances';
import {
    generateSubstanceColumns,
    generateSubstanceFromProduct,
    SubstanceDownloadButton,
} from 'src/app/utils/substance';
import { Button } from 'src/components/ui/button';
import { cn } from 'src/lib/utils';
import { ProductRejection } from '../../Approval/product-rejection';

export const ProductApprovalsDetails = () => {
    const { id, workspace } = useParams<{ id: string; workspace: string }>();
    const [isRejected, setIsRejected] = useState(false);

    const { data: request } = useProductApprovalByWorkspaceItem(
        id || '',
        workspace
    );
    const { mutateAsync: approve, isLoading } = useApproveApprovalRequest();

    const { data } = useProduct(
        request?.product?._id || '',
        request?.workspace?._id || ''
    );
    const [items, setItems] = useState<ConsolidatedComposition[]>([]);

    const isCompleted = useMemo(() => {
        if (!request) return false;

        return request.approvedOn || request.rejectedOn;
    }, [request]);

    const externalReference = useMemo(() => {
        const reference = data?.externalReferences?.find((x) => {
            return x.source === 'externalReferenceCode';
        })?.value;

        return reference;
    }, [data]);

    const productNumber = useMemo(() => {
        const reference = data?.externalReferences?.find((x) => {
            return x.source === 'externalDataId';
        })?.value;

        return reference;
    }, [data]);

    const latestVersion = useMemo(() => {
        const version = data?.versions?.[data.versions.length - 1];

        if (!version) {
            return undefined;
        }

        return version;
    }, [data]);

    const memoizedMaterials = useMemo<ProductBillOfMaterialEntry[]>(() => {
        const version = data?.versions?.[data.versions.length - 1];

        if (!version) return [];

        return version.billOfMaterials || [];
    }, [data]);

    const grossWeight = useMemo(() => {
        const version = data?.versions?.[data?.versions.length - 1];

        if (!version) return 0;

        const grossWeight = version.billOfMaterials?.reduce((acc, x) => {
            return acc + (x.weight || 0) / (x.consumption || 1);
        }, 0);

        return grossWeight || 0;
    }, [data]);

    const memoizedSubstances = useMemo(() => {
        const version = data?.versions?.[data?.versions.length - 1];

        if (!version) return [];
        if (!version.billOfMaterials) return [];

        const response =
            version.billOfMaterials
                .map((x) => x.material.compositions)
                .filter((x) => x)
                .flatMap((x) => {
                    return x?.map((y) => {
                        return {
                            ...y,
                        };
                    });
                }) || [];

        return response as VirtualWorkspaceComposition[];
    }, [data]);

    const isMaxRatio = useMemo(() => {
        if (!memoizedSubstances) return false;

        const ratio = memoizedSubstances.reduce((acc, curr) => {
            return acc + curr.weight;
        }, 0);

        return ratio >= grossWeight;
    }, [memoizedSubstances, grossWeight]);

    const triggerApproval = useCallback(() => {
        if (!request) return;

        if (!isMaxRatio) {
            toast.error(`The ratio of the substance is not equal to 100%`);
            return;
        }

        approve({
            item: request._id,
            delegateId: request.approver,
        });
    }, [approve, request, isMaxRatio]);

    const materialColumns: ColumnDef<ProductBillOfMaterialEntry>[] = [
        {
            id: 'component',
            header: ({ column }) => {
                return (
                    <Button
                        size='sm'
                        variant='ghost'
                        onClick={() => {
                            column.toggleSorting(
                                column.getIsSorted() === 'asc'
                            );
                        }}
                    >
                        <span>Component</span>
                        <CaretSortIcon width={16} />
                    </Button>
                );
            },
            accessorFn: (row) => {
                return row.name;
            },
        },
        {
            id: 'description',
            header: ({ column }) => {
                return (
                    <Button
                        size='sm'
                        variant='ghost'
                        onClick={() => {
                            column.toggleSorting(
                                column.getIsSorted() === 'asc'
                            );
                        }}
                    >
                        <span>Description</span>
                        <CaretSortIcon width={16} />
                    </Button>
                );
            },
            accessorFn: (row) => {
                return row.description ?? row.material.description;
            },
        },
        {
            id: 'consumption',
            header: ({ column }) => {
                return (
                    <Button
                        size='sm'
                        variant='ghost'
                        onClick={() => {
                            column.toggleSorting(
                                column.getIsSorted() === 'asc'
                            );
                        }}
                    >
                        <span>Consumption</span>
                        <CaretSortIcon width={16} />
                    </Button>
                );
            },
            accessorFn: (row) => {
                return row.consumption;
            },
        },
        {
            id: 'uom',
            header: ({ column }) => {
                return (
                    <Button
                        size='sm'
                        variant='ghost'
                        onClick={() => {
                            column.toggleSorting(
                                column.getIsSorted() === 'asc'
                            );
                        }}
                    >
                        <span>UOM</span>
                        <CaretSortIcon width={16} />
                    </Button>
                );
            },
            accessorFn: (row) => {
                return row.unitOfMeasurement
                    ? ProductUomLabels[row.unitOfMeasurement as ProductUom]
                    : '';
            },
        },
        {
            id: 'weight',
            header: ({ column }) => {
                return (
                    <Button
                        size='sm'
                        variant='ghost'
                        onClick={() => {
                            column.toggleSorting(
                                column.getIsSorted() === 'asc'
                            );
                        }}
                    >
                        <span>Weight</span>
                        <CaretSortIcon width={16} />
                    </Button>
                );
            },
            accessorFn: (row) => {
                return row.weight;
            },
        },
        {
            id: 'cost',
            header: ({ column }) => {
                return (
                    <Button
                        size='sm'
                        variant='ghost'
                        onClick={() => {
                            column.toggleSorting(
                                column.getIsSorted() === 'asc'
                            );
                        }}
                    >
                        <span>Cost (USD)</span>
                        <CaretSortIcon width={16} />
                    </Button>
                );
            },
            accessorFn: (row) => {
                return row.materialCost;
            },
        },
    ];

    useEffect(() => {
        const latest = data?.versions?.[data.versions.length - 1];
        if (!latest) return;

        const consolidatedCompositions: Record<
            string,
            ConsolidatedComposition
        > = {};

        latest.billOfMaterials?.forEach((bill) => {
            bill?.material?.compositions?.forEach((composition) => {
                const name = composition.substanceCode || composition.name;
                const supplier =
                    composition.supplier as unknown as WorkspaceModel;

                if (!consolidatedCompositions[name]) {
                    consolidatedCompositions[name] = {
                        ...composition,
                        name: composition.name,
                        weight: composition.weight || 0,
                        actualWeight: composition.actualWeight || 0,
                        supplier: supplier ? [supplier] : undefined,
                        sdsFileId: composition.sdsFileId
                            ? [composition.sdsFileId]
                            : [],
                    };

                    return;
                }

                consolidatedCompositions[name].weight += composition?.weight;
                consolidatedCompositions[name].actualWeight +=
                    composition?.actualWeight;

                if (composition.sdsFileId) {
                    consolidatedCompositions[name].sdsFileId?.push(
                        composition.sdsFileId
                    );
                }
            });
        });

        setItems(Object.values(consolidatedCompositions));
    }, [data]);

    console.debug(`items`, items);
    const memoizedSubstanceItems = useMemo(() => {
        if (!data) return [];
        const substances = generateSubstanceFromProduct(data);

        return substances;
    }, [data]);

    console.debug(`memoizedSubstanceItems`, memoizedSubstanceItems);

    if (!data) return <></>;

    return (
        <>
            <div className=''>
                <div className='grid h-[90%] w-full grid-cols-1 gap-4'>
                    <div className='grid grid-cols-1 justify-between rounded-lg bg-white px-4 py-2 shadow-xl'>
                        <div className='flex items-center justify-between py-2'>
                            <span className='text-xs font-bold underline decoration-red-800 decoration-2 underline-offset-2'>
                                Product Information
                            </span>

                            {isCompleted ? (
                                <div>
                                    <span className='text-xs font-bold'>
                                        Status:{' '}
                                        <span
                                            className={cn(
                                                'text-xs font-bold',
                                                request?.approvedOn
                                                    ? 'text-green-600'
                                                    : 'text-red-600'
                                            )}
                                        >
                                            {request?.approvedOn
                                                ? 'Approved'
                                                : 'Rejected'}
                                        </span>
                                    </span>
                                </div>
                            ) : (
                                <div className='flex gap-2'>
                                    <Button
                                        className='flex items-center gap-2'
                                        variant='ghost'
                                        size='sm'
                                        onClick={
                                            () => triggerApproval()
                                            // triggerApproval({
                                            //     item: request?._id || '',
                                            // })
                                        }
                                    >
                                        {isLoading ? (
                                            <>
                                                <LoaderCircle
                                                    size={16}
                                                    className='animate-spin'
                                                />
                                                <span>Loading...</span>
                                            </>
                                        ) : (
                                            <>
                                                <Check size='16' />
                                                <span>Approve</span>
                                            </>
                                        )}
                                    </Button>

                                    <Button
                                        className='flex items-center gap-2'
                                        variant='ghost'
                                        size='sm'
                                        disabled={isLoading}
                                        onClick={() => setIsRejected(true)}
                                    >
                                        <X size='16' />
                                        <span>Reject</span>
                                    </Button>
                                </div>
                            )}
                        </div>

                        <div className='grid grid-cols-4 gap-4'>
                            <DetailLabel title='Product Number'>
                                {productNumber}
                            </DetailLabel>
                            <DetailLabel title='Unit of Measurement'>
                                {data.unitOfMeasurement
                                    ? ProductUomLabels[
                                          data.unitOfMeasurement as ProductUom
                                      ]
                                    : '--'}
                            </DetailLabel>
                            <DetailLabel title='External Sys Number'>
                                {externalReference}
                            </DetailLabel>
                            <DetailLabel title='Available Processes'></DetailLabel>

                            <DetailLabel title='Product Name'>
                                {data.name}
                            </DetailLabel>
                            <DetailLabel title='Gross Weight'>
                                {grossWeight}
                                {/* {latestVersion?.specifications?.weight} */}
                            </DetailLabel>
                            <DetailLabel
                                title='Variant'
                                className='col-span-2'
                            ></DetailLabel>

                            <DetailLabel title='Product Family'></DetailLabel>
                            <DetailLabel title='UPC/EAN'>
                                {data.upc}
                            </DetailLabel>
                            <DetailLabel title='Season/Collection'>
                                {data.collection}
                            </DetailLabel>
                            <DetailLabel title='Status'>
                                {data.status
                                    ? ProductStatusLabels[data?.status]
                                    : '--'}
                            </DetailLabel>

                            <DetailLabel title='Product Category'>
                                {data?.category?.name || data.category?.code}
                            </DetailLabel>
                            <DetailLabel title='HS Code'>
                                {data?.hsCode}
                            </DetailLabel>
                            <DetailLabel title='Cost' className='col-span-2'>
                                {latestVersion?.costOfMaterials}
                            </DetailLabel>
                        </div>
                    </div>

                    <TableV2
                        label='Bill of Materials'
                        columns={materialColumns}
                        data={memoizedMaterials}
                        fixedHeight='300px'
                    />

                    <TableV2
                        label='Bill of Substances'
                        columns={generateSubstanceColumns(({ row }) => (
                            <div className='flex items-center gap-2'>
                                <SubstanceDownloadButton
                                    substance={row.original}
                                />
                            </div>
                        ))}
                        data={memoizedSubstanceItems}
                        subRowsField='subCompositions'
                        fixedHeight='300px'
                        disabledTooltipColumns={['actions']}
                    />
                </div>
            </div>

            <ProductRejection
                open={isRejected}
                approvalId={request?._id || ''}
                onClose={() => setIsRejected(false)}
                delegateId={request?.approver}
            />
        </>
    );
};
