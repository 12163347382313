import { PublicClientApplication } from '@azure/msal-browser';
import { useMsal } from '@azure/msal-react';
import { Bell, User2 } from 'lucide-react';
import { Outlet } from 'react-router-dom';
import Logo from 'src/assets/lf-logo.png';
import { Avatar, AvatarFallback, AvatarImage } from 'src/components/ui/avatar';
import { useAzureB2CAuth } from '../auth/use-azure-b2c-auth';
import { useSidebarStore } from '../stores/sidebar-store';
import { cn } from '../utils/cn';
import { LocationBreadcrumb } from './location-breadcrumb';
import { SiderV2 } from './menu/sider-v2';
import { TypographySmall } from './typography';

export const LoadingSpinner = ({ className }: { className?: string }) => {
    const { instance } = useMsal();

    const onSignOut = () => {
        instance.logoutRedirect();
    };

    return (
        <div className='flex h-screen w-full flex-col items-center justify-center gap-4'>
            <img alt='Logo' src={Logo}></img>

            <div className='flex flex-col items-center gap-4'>
                {/* <TypographySmall>Loading...</TypographySmall> */}
                <span>Wait a minute while we load the app...</span>
                <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='36'
                    height='36'
                    viewBox='0 0 24 24'
                    fill='none'
                    stroke='currentColor'
                    strokeWidth='2'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    className={cn('animate-spin', className)}
                >
                    <path d='M21 12a9 9 0 1 1-6.219-8.56' />
                </svg>
            </div>

            <TypographySmall
                onClick={() => {
                    onSignOut();
                }}
                className='text-blue-600 hover:cursor-pointer hover:underline dark:text-blue-500'
            >
                Sign Out
            </TypographySmall>
        </div>
    );
};

export const Layout = ({ pca }: { pca: PublicClientApplication }) => {
    const { isLoading } = useAzureB2CAuth(pca);
    const { open } = useSidebarStore();

    if (isLoading) {
        return <LoadingSpinner />;
    }

    // if (!isCompleted && !isLoading) {
    //     return <Onboarding />;
    // }

    return (
        <>
            <SiderV2 />

            <main
                className={cn(
                    'flex h-screen min-h-screen flex-col bg-gray-100 px-4 py-2 pl-6 transition-[margin-left] duration-300 ease-in-out dark:bg-zinc-900',
                    open ? 'ml-60' : 'ml-[90px]'
                )}
            >
                <div className='flex w-full items-center justify-between'>
                    <LocationBreadcrumb />

                    <div className='flex justify-end gap-2'>
                        <Avatar className=''>
                            <AvatarImage src='' alt='@shadcn' />
                            <AvatarFallback>
                                <Bell className='h-5' />
                            </AvatarFallback>
                        </Avatar>

                        <Avatar className='order rounded-full bg-white'>
                            <AvatarImage src='' alt='@shadcn' />
                            <AvatarFallback className='bg-white'>
                                <User2 className='h-5 bg-white' />
                            </AvatarFallback>
                        </Avatar>
                    </div>
                </div>

                <div className='flex h-full w-full flex-1 flex-col overflow-x-hidden overscroll-none'>
                    <Outlet />
                </div>
            </main>
        </>
    );
};
