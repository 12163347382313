import { ColumnDef } from '@tanstack/react-table';
import { useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { DataTable } from 'src/app/components/DataTable';
import { getUnitValue } from 'src/domain/enums/unit-type.enum';
import { ProductVersionModel } from '../../../_api/products/products/product.model';
import { usePurchases } from '../../../_api/purchase/purchase-order/hooks/use-purchases';
import { PurchaseOrderModel } from '../../../_api/purchase/purchase-order/purchase.model';

export const ProductOrders = ({
    product,
}: {
    product?: ProductVersionModel;
}) => {
    const [rowSelection, setRowSelection] = useState({});
    const [searchParams] = useSearchParams();
    const delegateId = searchParams.get('delegateId') || '';
    const { data } = usePurchases(delegateId);

    const filteredOrders = useMemo(
        () =>
            (data || [])
                .map((x) => x)
                .filter((x) => {
                    const exist = x.versions[0].manifest.find(
                        (y) => y.purchaseables.id === product?.product.id
                    );

                    return exist;
                }),
        [data, product]
    );

    const columns: ColumnDef<PurchaseOrderModel>[] = [
        {
            id: 'order',
            header: 'Order',
            cell: ({ row }) => {
                return (
                    row.original.externalDataId || row.original.versions[0].id
                );
            },
        },
        {
            id: 'buyer',
            header: 'Buyer',
            cell: ({ row }) => {
                return row.original.supplier.owner?.companyName;
            },
        },
        {
            id: 'seller',
            header: 'Seller',
            cell: ({ row }) => {
                return row.original.supplier.seller?.companyName;
            },
        },
        {
            id: 'uom',
            header: 'Unit of Measurement',
            cell: ({ row }) => {
                return product?.product.unit
                    ? getUnitValue(product.product.unit || '')
                    : '';
            },
        },
        {
            id: 'quantity',
            header: 'Quantity',
            cell: ({ row }) => {
                const exist = row.original.versions[0].manifest.find(
                    (y) => y.purchaseables.id === product?.product.id
                );

                return exist?.quantity;
            },
        },
        {
            id: 'lastUpdated',
            header: 'Last Updated',
            cell: ({ row }) => {
                return row.original.versions[0].lastUpdatedOn
                    ? new Date(
                          row.original.versions[0].lastUpdatedOn
                      ).toLocaleDateString()
                    : '';
            },
        },
    ];

    return (
        <div className='flex h-full flex-col'>
            <DataTable
                label='Orders'
                data={filteredOrders || []}
                columns={columns}
                rowSelection={rowSelection}
                setRowSelection={setRowSelection}
                bodyClassName='bg-white'
            />
        </div>
    );
};
