import axios from 'axios';
import {
    CloudUpload,
    Download,
    LoaderCircle,
    Paperclip,
    Trash,
} from 'lucide-react';
import { useCallback, useMemo, useState } from 'react';
import { FileCreateDataObject } from 'src/app/_api_adb2c/attachment/file-create-data-object';
import { useAttachmentUpload } from 'src/app/_api_adb2c/attachment/hooks/use-attachment-upload';
import { useAttachSalesOrderFiles } from 'src/app/_api_adb2c/sales/sales/hooks/use-attach-sales-order-files';
import { SalesOrderAttachDataObject } from 'src/app/_api_adb2c/sales/sales/request/sales-order-attach-data-object';
import { SalesOrderDocumentDataObject } from 'src/app/_api_adb2c/sales/sales/request/sales-order-document-data-object';
import {
    SupportDocType,
    SupportDocTypeLabel,
} from 'src/app/_api_adb2c/shared/support-doc-type.enum';
import { FileInput, FileUploader } from 'src/app/components-v2/file-dropzone';
import { TooltipWrapper } from 'src/app/components/TooltipWrapper';
import { useDownload } from 'src/app/pages-v2/Materials/Details/Substance';
import { dropZoneConfig } from 'src/app/pages-v2/Materials/Details/Substance/substance-sds-file-upload';
import { useContextStore } from 'src/app/stores/context-store';
import { cn } from 'src/app/utils/cn';
import {
    Popover,
    PopoverContent,
    PopoverTrigger,
} from 'src/components/ui/popover';
import { getFileIdFromAssetName } from 'src/infrastructure/utils/extract-asset-id';
import { PurchaseDocumentItem } from './purchase-documents';

interface Props {
    item: PurchaseDocumentItem;
    documentType: string;
}

export function PurchaseSupportDocuments({ item, documentType }: Props) {
    const context = useContextStore();
    const [open, setOpen] = useState(false);
    const [files, setFiles] = useState<File[] | null>(null);

    const { mutateAsync: upload, isLoading: uploadLoading } =
        useAttachmentUpload();

    const { mutateAsync: attach, isLoading: attachLoading } =
        useAttachSalesOrderFiles();

    const { download } = useDownload();

    const isUploaded = useMemo(() => {
        return item.salesOrder?.documents?.some((x) => x.type === documentType);
    }, [item, documentType]);

    const isRequired = useMemo(() => {
        return item.requiredDocuments?.some((x) => x === documentType);
    }, [item, documentType]);

    const label = useMemo(() => {
        return SupportDocTypeLabel[documentType as SupportDocType];
    }, [documentType]);

    const documents = useMemo(() => {
        const documents = item.salesOrder?.documents || [];

        const filteredDocuments = documents.filter(
            (x) => x.type === documentType
        );

        return filteredDocuments;
    }, [item, documentType]);

    const remove = useCallback(
        async (id: string) => {
            const filteredDocuments = documents.filter((x) => x.id !== id);

            const request: SalesOrderAttachDataObject = {
                documents: filteredDocuments,
            };

            await attach({
                id: item.salesOrder?._id || '',
                body: request,
                delegateId: item.salesOrder?.workspace,
                diversion: '1',
            });
        },
        [documents, attach, item]
    );

    const submit = useCallback(async () => {
        if (!files) return;

        const documents: SalesOrderDocumentDataObject[] = [];

        item.salesOrder?.documents?.forEach((doc) => {
            documents.push(doc);
        });

        await Promise.all(
            files.map(async (file) => {
                const request: FileCreateDataObject = {
                    contentType: file.type,
                    size: file.size,
                    name: file.name,
                    genre: 'temporary',
                    uploadedBy: context.user?._id || '',
                    workspace: context.workspace?._id || '',
                };

                const token = await upload({
                    file: request,
                    assetType: 'salesOrder',
                    diversion: '1',
                    delegateId: item.workspace?._id,
                });

                await axios.put(token.token, file, {
                    headers: {
                        'Content-Type': file.type,
                        'x-ms-blob-type': 'BlockBlob',
                    },
                });

                documents.push({
                    file: {
                        assetName: token.attachment.assetName,
                        autoResign: token.attachment.autoResign,
                        container: token.attachment.container,
                        contentType: token.attachment.contentType,
                        extension: file.name.split('.').pop() || '',
                        originalName: token.attachment.originalName,
                        readReleaseTime: token.attachment.readReleaseTime,
                        size: file.size,
                    },
                    type: documentType as SupportDocType,
                });
            })
        );

        const request: SalesOrderAttachDataObject = {
            documents: documents,
        };

        await attach({
            id: item.salesOrder?._id || '',
            body: request,
            delegateId: item.salesOrder?.workspace,
            diversion: '1',
        });
    }, [files, item, attach, documentType, upload, context]);

    return (
        <Popover open={open} onOpenChange={setOpen}>
            <PopoverTrigger asChild onClick={() => setOpen(true)}>
                <div className='cursor-pointer'>
                    {!isRequired && !isUploaded && (
                        <span
                            className={cn(
                                '',
                                isUploaded ? 'text-green-600' : 'text-gray-400'
                            )}
                        >
                            {isUploaded ? 'Uploaded' : 'Upload'}
                        </span>
                    )}

                    {(isUploaded || isRequired) && (
                        <span
                            className={cn(
                                '',
                                isUploaded ? 'text-green-600' : 'text-red-600'
                            )}
                        >
                            {isUploaded ? 'Uploaded' : 'Upload'}
                        </span>
                    )}
                </div>
            </PopoverTrigger>

            <PopoverContent side='top' className='flex flex-col gap-2 '>
                <span className='font-bold underline decoration-red-800 underline-offset-2'>
                    {label}
                </span>

                {documents.length === 0 && !files && (
                    <div className='flex flex-col gap-2'>
                        <span>No documents found for this type.</span>
                    </div>
                )}

                {documents.length > 0 && (
                    <div className='flex flex-col gap-2'>
                        <span className='font-bold'>Uploaded Documents</span>

                        <div className='flex flex-col gap-2'>
                            {documents.map((doc, index) => (
                                <div
                                    className='grid grid-cols-4 justify-between gap-4'
                                    key={index}
                                >
                                    <div className='col-span-3 flex gap-1 hover:underline hover:underline-offset-2'>
                                        <Paperclip className='h-4 w-4 flex-none stroke-current' />
                                        <TooltipWrapper
                                            label={doc.file.originalName}
                                        >
                                            <span className='truncate'>
                                                {doc.file.originalName}
                                            </span>
                                        </TooltipWrapper>
                                    </div>

                                    <div className='col-span-1 flex justify-end gap-2'>
                                        <TooltipWrapper label='Download File'>
                                            <Download
                                                size={16}
                                                className='text-blue-600 hover:scale-125'
                                                onClick={() => {
                                                    const fileId =
                                                        getFileIdFromAssetName(
                                                            doc.file.assetName
                                                        );

                                                    download(
                                                        fileId,
                                                        doc.file.originalName
                                                    );
                                                }}
                                            />
                                        </TooltipWrapper>

                                        <TooltipWrapper label='Remove Document'>
                                            <Trash
                                                size={16}
                                                className='text-red-600 hover:scale-125'
                                                onClick={() =>
                                                    remove(doc.id || '')
                                                }
                                            />
                                        </TooltipWrapper>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                )}

                {files && files.length > 0 && (
                    <div className='my-2 flex flex-col gap-2'>
                        <span className='font-bold'>Unsubmitted Documents</span>
                        <div className='flex flex-col gap-2'>
                            {files.map((file, index) => (
                                <div
                                    className='grid grid-cols-4 justify-between gap-4'
                                    key={index}
                                >
                                    <div className='col-span-3 flex gap-1 hover:underline hover:underline-offset-2'>
                                        <Paperclip className='h-4 w-4 flex-none stroke-current' />
                                        <TooltipWrapper label={file.name}>
                                            <span className='truncate'>
                                                {file.name}
                                            </span>
                                        </TooltipWrapper>
                                    </div>

                                    <Trash
                                        size={16}
                                        className='col-span-1 justify-self-end text-red-600 hover:scale-125'
                                        onClick={() => {
                                            if (files) {
                                                setFiles(
                                                    files.filter(
                                                        (x) =>
                                                            x.name !== file.name
                                                    )
                                                );
                                            }
                                        }}
                                    />
                                </div>
                            ))}
                        </div>
                    </div>
                )}

                <div className='flex items-center justify-between'>
                    <FileUploader
                        value={files}
                        onValueChange={setFiles}
                        dropzoneOptions={dropZoneConfig}
                    >
                        <FileInput>
                            <div className='flex cursor-pointer items-center gap-1 text-blue-400 hover:underline hover:underline-offset-2'>
                                <CloudUpload size={16} />
                                <span>Upload More</span>
                            </div>
                        </FileInput>
                    </FileUploader>

                    {!uploadLoading && !attachLoading && (
                        <div
                            className='group flex cursor-pointer gap-1 hover:underline'
                            onClick={submit}
                        >
                            Finish
                        </div>
                    )}

                    {uploadLoading ||
                        (attachLoading && (
                            <LoaderCircle className='animate-spin' />
                        ))}
                </div>
            </PopoverContent>
        </Popover>
    );
}
