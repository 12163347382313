import { useAccount } from '@azure/msal-react';
import { useMemo } from 'react';
import useAppConfig from 'src/infrastructure/hooks/use-config.hook';
import { TemplateService } from '../template.service';

export function useTemplateService() {
    const config = useAppConfig();
    const account = useAccount();

    const service = useMemo(() => {
        if (account?.idToken) {
            return new TemplateService(config, account.idToken);
        } else {
            throw new Error('useTemplateService(): No token found in context');
        }
    }, [account?.idToken, config]);

    return { service };
}
