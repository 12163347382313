export const RULESET_QUERY_KEY = (workspace: string, item: string) => [
    'ruleset',
    workspace,
    item,
];

export const RULESETS_QUERY_KEY = (workspace: string) => [
    'rulesets',
    workspace,
];

export const BATCH_RULESETS_QUERY_KEY = (
    workspace: string,
    batch: string[]
) => ['batch-rulesets', workspace, ...batch];
