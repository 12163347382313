import { ExternalLink, PlusCircle } from 'lucide-react';
import { useState } from 'react';
import { Handle, Position } from 'reactflow';
import SupplierShippedImage from 'src/assets/cascade-shipped.png';
import SupplierCascadeImage from 'src/assets/cascade-supplier.png';
import SupplierFactoryImage from 'src/assets/factory.png';
import { Card, CardContent } from 'src/components/ui/card';
import {
    Sheet,
    SheetContent,
    SheetDescription,
    SheetHeader,
    SheetTitle,
    SheetTrigger,
} from 'src/components/ui/sheet';
import { usePurchase } from '../../../../_api/purchase/purchase-order/hooks/use-purchase';
import { CompanyProcessType } from '../../../../_api/shared/company-process.type';
import { PurchaseManifest } from '../Manifest/purchase-manifest';
import { PurchaseCascadeNodeOverview } from './purchase-cascade-node-overview';

export type PurchaseCascadeNodeProps = {
    data: {
        mode?: 'supplier' | 'order';
        label: CompanyProcessType;
        sheetLabel: string;
        purchaseId: string;
        supplierId?: string;
        supplierSellerId?: string;
        totalCost?: number;
        totalItems?: number;
        shippedOn?: Date[];
        isDelegated?: boolean;
        setDelegateId: (id: string) => void;
        setSelectedPurchase: (id: string) => void;
    };
};

export const PurchaseCascadeNode: React.FC<PurchaseCascadeNodeProps> = ({
    data,
}) => {
    const {
        label,
        purchaseId,
        supplierId,
        supplierSellerId,
        totalCost,
        mode,
        totalItems,
        sheetLabel,
        shippedOn,
        isDelegated,
        setDelegateId,
        setSelectedPurchase,
    } = data;

    const [open, setOpen] = useState(false);

    const { data: order } = usePurchase({
        id: purchaseId,
        customWorkspaceId: supplierId,
        enabled: open,
    });

    const displayDate = (date: Date | undefined) => {
        if (!date) return 'N/A';
        return date.toString().slice(0, 10);
    };

    return (
        <div className='h-28 w-44 cursor-default rounded-md border-2 border-gray-400'>
            <Handle type='target' position={Position.Left}></Handle>

            <Sheet
                open={open}
                onOpenChange={(open) => {
                    setOpen(open);
                }}
            >
                <SheetTrigger className='flex w-full justify-between gap-4 border-b border-gray-400 bg-gray-200 p-1'>
                    <span className='truncate text-xs font-semibold text-gray-500'>
                        {sheetLabel}
                    </span>

                    <div className='cursor-pointer'>
                        <ExternalLink className='h-4 w-4 flex-1 text-gray-700' />
                    </div>
                </SheetTrigger>

                <SheetContent className='min-w-[800px]'>
                    <SheetHeader>
                        <SheetTitle>
                            Purchase Order
                            <SheetDescription>
                                {order?.owner.externalDataId}
                            </SheetDescription>
                        </SheetTitle>
                    </SheetHeader>

                    <div className='mt-8 flex flex-col gap-8'>
                        <div>
                            <span className='text-sm font-bold'>
                                General Information
                            </span>

                            <Card className='px-2 py-4'>
                                <CardContent className='flex gap-2 overflow-auto'>
                                    {/* <PurchaseOverview
                                        data={order}
                                        wrap={false}
                                        statisticsCardClass='max-w-[200px]'
                                    /> */}
                                    <PurchaseCascadeNodeOverview />
                                </CardContent>
                            </Card>
                        </div>

                        <PurchaseManifest order={order} />
                    </div>
                </SheetContent>
            </Sheet>

            <div className='flex flex-col gap-2 px-2 py-2'>
                {mode === 'order' && (
                    <div className='flex gap-2'>
                        <img
                            src={SupplierFactoryImage}
                            alt=''
                            className='h-4 w-4'
                        />

                        <span className='w-4/5 truncate text-xs font-semibold text-gray-500'>
                            {label}
                        </span>
                    </div>
                )}

                <div className='flex gap-2'>
                    <img
                        src={SupplierCascadeImage}
                        alt=''
                        className='h-4 w-4'
                    />

                    <span className='w-4/5 truncate text-xs font-semibold text-gray-500'>
                        {totalCost || 0} ({totalItems || 0} items)
                    </span>
                </div>

                <div className='flex justify-between'>
                    <div className='flex gap-2'>
                        <img
                            src={SupplierShippedImage}
                            alt=''
                            className='h-4 w-4'
                        />

                        <span className='w-4/5 truncate text-xs font-semibold text-gray-500'>
                            {displayDate(shippedOn?.[0])}
                        </span>
                    </div>

                    {isDelegated && (
                        <PlusCircle
                            onClick={() => {
                                setDelegateId(supplierSellerId || '');
                                setSelectedPurchase(purchaseId);
                            }}
                            className='h-4 w-4'
                        />
                    )}
                </div>
            </div>

            <Handle type='source' position={Position.Right}></Handle>
        </div>
    );
};
