export const BASE_SUPPLIERS_QUERY_KEY = ['suppliers'];
export const BASE_SUPPLIER_QUERY_KEY = ['supplier'];

export const SUPPLIERS_QUERY_KEY = (workspace: string) => [
    ...BASE_SUPPLIERS_QUERY_KEY,
    workspace,
];

export const SUPPLIER_QUERY_KEY = (workspace: string, supplierId: string) => [
    ...BASE_SUPPLIER_QUERY_KEY,
    workspace,
    supplierId,
];
