import { useQuery } from '@tanstack/react-query';
import { toast } from 'sonner';
import { useContextStore } from 'src/app/stores/context-store';
import { PURCHASE_ITEMS_QUERY_KEY } from './purchase-item-query-keys';
import { usePurchaseItemService } from './use-purchase-item-service';
import { PaginatedResponse, PurchaseItemModel } from '../purchaseable.model';

export function usePurchaseItems(delegateId?: string) {
    const context = useContextStore();
    const { service } = usePurchaseItemService();

    return useQuery<PaginatedResponse<PurchaseItemModel>>({
        queryKey: PURCHASE_ITEMS_QUERY_KEY(
            delegateId || context.workspace?._id || ''
        ),
        queryFn: async () => {
            return await service.list(
                delegateId || context.workspace?._id || ''
            );
        },
        onError: (err) => {
            const error = err as Error;

            toast.error(`Failed to fetch purchase items: ${error.message}`);
        },
    });
}
