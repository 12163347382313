import { useMutation, useQueryClient } from '@tanstack/react-query';
import { toast } from 'sonner';
import { useContextStore } from 'src/app/stores/context-store';
import { PermissionGrantRequestObject } from '../permission-grant-request-object';
import { ROLES_QUERY_KEY } from './roles-query';
import { useRolesService } from './use-roles-service';

export function useUpdateRolePermissions() {
    const client = useQueryClient();
    const { service } = useRolesService();
    const { workspace } = useContextStore();

    return useMutation({
        mutationFn: async ({
            id,
            body,
            delegateId,
        }: {
            id: string;
            body: PermissionGrantRequestObject;
            delegateId?: string;
        }) => {
            return await service.updateRolePermissions(
                delegateId || workspace?._id || '',
                id,
                body
            );
        },
        onSuccess: () => {
            client.invalidateQueries(ROLES_QUERY_KEY(workspace?._id || ''));

            toast.success('Successfully updated role permissions');
        },
        onError: (err) => {
            const error = err as Error;

            toast.error(`Failed to update role permissions: ${error.message}`);
        },
    });
}
