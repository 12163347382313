import { useQuery } from '@tanstack/react-query';
import { useRolesService } from './use-roles-service';
import { WorkspaceRoleModel } from '../roles.model';
import { ROLES_QUERY_KEY } from './roles-query';
import { useContextStore } from 'src/app/stores/context-store';

export function useRoles(delegateId?: string) {
    const { workspace } = useContextStore();
    const { service } = useRolesService();

    return useQuery<WorkspaceRoleModel[]>({
        queryKey: ROLES_QUERY_KEY(delegateId || workspace?._id || ''),
        queryFn: async () => {
            return await service.list(delegateId || workspace?._id || '');
        },
        enabled: !!workspace?._id,
    });
}
