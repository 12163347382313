import { format } from 'date-fns';
import { CalendarIcon } from 'lucide-react';
import { Button } from 'src/components/ui/button';
import { Calendar } from 'src/components/ui/calendar';
import {
    Popover,
    PopoverContent,
    PopoverTrigger,
} from 'src/components/ui/popover';
import { cn } from 'src/lib/utils';

interface Props
    extends Omit<React.HTMLAttributes<HTMLButtonElement>, 'onSelect'> {
    mode?: 'single' | 'multiple';
    date?: Date;
    onSelect?: (date: Date) => void;
    minDate?: Date;
    maxDate?: Date;
}

export function DatePicker({
    mode = 'single',
    id,
    className,
    date,
    onSelect,
    minDate,
    maxDate,
}: Props) {
    return (
        <Popover>
            <PopoverTrigger asChild>
                <Button
                    id={id}
                    variant={'outline'}
                    className={cn(
                        'w-[280px] justify-start text-left font-normal',
                        !date && 'text-muted-foreground',
                        className
                    )}
                >
                    <CalendarIcon className='mr-2 h-4 w-4 flex-none' />
                    <span className='flex-1 truncate'>
                        {date ? format(date, 'PPP') : 'Pick a date'}
                    </span>
                </Button>
            </PopoverTrigger>

            <PopoverContent className='w-auto p-0'>
                <Calendar
                    mode='single'
                    selected={date}
                    onSelect={(date) => {
                        if (onSelect && date) {
                            onSelect(date);
                        }
                    }}
                    initialFocus
                    fromDate={minDate}
                    toDate={maxDate}
                />
            </PopoverContent>
        </Popover>
    );
}
