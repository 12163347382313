import { PublicClientApplication } from '@azure/msal-browser';
import { useAzureB2CAuth } from '../auth/use-azure-b2c-auth';
import { LoadingSpinner } from './layout';

interface Props {
    pca: PublicClientApplication;
    children?: React.ReactNode;
}

export function BlankLayout({ pca, children }: Props) {
    const { isLoading } = useAzureB2CAuth(pca);

    if (isLoading) {
        return <LoadingSpinner />;
    }

    return <>{children}</>;
}
