export enum PurchaseOrderStatus {
    DRAFT = 'draft',
    RELEASED = 'released',
    DETAINED = 'detained',
    PARTIAL_DELIVERED = 'partial_delivered',
    DELIVERED = 'delivered',
    CANCELLED = 'cancelled',
}

export const PurchaseOrderStatusLabels = {
    [PurchaseOrderStatus.DRAFT]: 'Draft',
    [PurchaseOrderStatus.RELEASED]: 'Released',
    [PurchaseOrderStatus.DETAINED]: 'Detained',
    [PurchaseOrderStatus.PARTIAL_DELIVERED]: 'Partial Delivered',
    [PurchaseOrderStatus.DELIVERED]: 'Delivered',
    [PurchaseOrderStatus.CANCELLED]: 'Cancelled',
};
