import { DateTime } from 'luxon';
import { useMemo } from 'react';
import { CartesianGrid, Line, LineChart, XAxis, YAxis } from 'recharts';
import { usePurchases } from 'src/app/_api_adb2c/purchase/purchase/hooks/use-purchases';
import {
    Card,
    CardContent,
    CardDescription,
    CardHeader,
    CardTitle,
} from 'src/components/ui/card';
import {
    ChartConfig,
    ChartContainer,
    ChartTooltip,
    ChartTooltipContent,
} from 'src/components/ui/chart';
import { getDateRangeDescription } from '../utils/get-date-range-description';

interface Props {
    startDate?: Date;
    endDate?: Date;
}

const chartConfig = {
    count: {
        label: 'Count',
        color: 'hsl(var(--chart-1))',
    },
} satisfies ChartConfig;

export function OrderTrends({ startDate, endDate }: Props) {
    const { data: purchases } = usePurchases({
        draft: false,
        page: 0,
        limit: 0,
    });

    const isLargeRange = useMemo(() => {
        if (!startDate || !endDate) return false;
        const difference = endDate.getTime() - startDate.getTime();
        return difference > 2 * 30 * 24 * 60 * 60 * 1000; // More than 2 months
    }, [startDate, endDate]);

    const xAxis = useMemo(() => {
        if (!startDate || !endDate) return [];

        const start = new Date(startDate);
        const end = new Date(endDate);
        const dates: Date[] = [];

        if (isLargeRange) {
            // Monthly intervals
            while (start <= end) {
                dates.push(new Date(start));
                start.setMonth(start.getMonth() + 1);
                start.setDate(1);
            }
        } else {
            // Weekly intervals
            while (start <= end) {
                dates.push(new Date(start));
                start.setDate(start.getDate() + 7);
            }
        }

        return dates;
    }, [isLargeRange, startDate, endDate]);

    const graphData = useMemo(() => {
        if (!startDate || !endDate || !xAxis.length || !purchases?.data)
            return [];

        return xAxis.map((date) => {
            const count =
                purchases?.data
                    ?.filter((item) => {
                        const createdOn = new Date(item.createdOn);

                        return (
                            startDate &&
                            endDate &&
                            createdOn >= startDate &&
                            createdOn <= endDate
                        );
                    })
                    .filter((item) => {
                        const createdOn = new Date(item.createdOn);

                        if (isLargeRange) {
                            return (
                                createdOn.getUTCMonth() ===
                                    date.getUTCMonth() &&
                                createdOn.getUTCFullYear() ===
                                    date.getUTCFullYear()
                            );
                        } else {
                            const nextDate = new Date(date);
                            nextDate.setDate(nextDate.getDate() + 7);
                            return createdOn >= date && createdOn < nextDate;
                        }
                    }).length || 0;

            return {
                label: isLargeRange
                    ? DateTime.fromJSDate(date).toFormat('MMM')
                    : DateTime.fromJSDate(date).toFormat('M/dd'),
                count: count,
            };
        });
    }, [purchases, endDate, startDate, xAxis, isLargeRange]);

    const dateRangeDescription = useMemo(
        () => getDateRangeDescription(startDate, endDate),
        [startDate, endDate]
    );

    return (
        <Card className='px-4 py-2 shadow'>
            <CardHeader>
                <CardTitle>Order Trends</CardTitle>
                <CardDescription>{dateRangeDescription}</CardDescription>
            </CardHeader>

            <CardContent>
                <ChartContainer config={chartConfig} className='h-56 w-full'>
                    <LineChart
                        accessibilityLayer
                        data={graphData}
                        margin={{
                            left: 12,
                            right: 12,
                            top: 8,
                        }}
                    >
                        <CartesianGrid vertical={false} />
                        <XAxis
                            dataKey='label'
                            tickLine={false}
                            axisLine={false}
                            tickMargin={8}
                        />
                        <YAxis
                            tickLine={false}
                            axisLine={false}
                            tickMargin={8}
                        />
                        <ChartTooltip
                            cursor={false}
                            content={<ChartTooltipContent hideLabel />}
                        />
                        <Line
                            dataKey='count'
                            type='linear'
                            label='Supplier Count'
                            stroke='var(--color-count)'
                            strokeWidth={2}
                            dot={false}
                        />
                    </LineChart>
                </ChartContainer>
            </CardContent>
        </Card>
    );
}
