import { ColumnDef } from '@tanstack/react-table';
import { Edit, GanttChartSquare, Plus, Trash } from 'lucide-react';
import { useCallback, useMemo, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useMaterials } from 'src/app/_api_adb2c/product/material/hooks/use-materials';
import { MaterialModel } from 'src/app/_api_adb2c/product/material/models/material.model';
import {
    ProductUom,
    ProductUomLabels,
} from 'src/app/_api_adb2c/purchase/purchase/enums/product-uom.enum';
import { TableV2 } from 'src/app/components-v2/table-v2';
import { NavigationLink } from 'src/app/components-v2/typography';
import { TooltipWrapper } from 'src/app/components/TooltipWrapper';
import { Checkbox } from 'src/components/ui/checkbox';
import { AddMaterialV2 } from './add-material';
import { DeleteMaterial } from './delete-material';
import { EditMaterialV2 } from './edit-material';

export const Materials = () => {
    const navigate = useNavigate();

    const [searchParams] = useSearchParams();
    const delegateId = searchParams.get('delegateId') || '';

    const [actionMode, setActionMode] = useState<
        'create' | 'edit' | 'delete'
    >();
    const [selectedItem, setSelectedItem] = useState<MaterialModel>();

    const handleRedirectToDetails = useCallback(
        (id: string) => {
            delegateId
                ? navigate(`/components/${id}?delegateId=${delegateId}`)
                : navigate(`/components/${id}`);
        },
        [navigate, delegateId]
    );

    const { data, isLoading } = useMaterials();
    const memoizedData = useMemo(
        () =>
            data?.data?.sort((a, b) => {
                const aDate = new Date(a.createdOn).getTime();
                const bDate = new Date(b.createdOn).getTime();
                return bDate - aDate;
            }) || [],
        [data]
    );

    const columns: ColumnDef<MaterialModel>[] = useMemo(
        () => [
            {
                id: 'select',
                size: 50,
                header: ({ table }) => (
                    <Checkbox
                        checked={table.getIsAllPageRowsSelected()}
                        onCheckedChange={(value) =>
                            table.toggleAllPageRowsSelected(!!value)
                        }
                        aria-label='Select all'
                        data-testid='select-all-button'
                    />
                ),
                cell: ({ row }) => (
                    <Checkbox
                        checked={row.getIsSelected()}
                        onCheckedChange={(value) => row.toggleSelected(!!value)}
                        aria-label='Select row'
                        data-testid='select-row-button'
                    />
                ),
                enableSorting: false,
                enableHiding: false,
            },
            {
                id: 'name',
                accessorFn: (row) => row.name,
                header: 'Name',
                cell: ({ row }) => {
                    return (
                        <NavigationLink
                            path={`/components/${row.original._id}`}
                        >
                            {row.original.name}
                        </NavigationLink>
                    );
                },
            },
            {
                id: 'description',
                header: 'Description',
                accessorFn: (row) => row.description,
            },
            {
                id: 'uom',
                accessorFn: (row) => row.unitOfMeasurement,
                header: 'Unit of Measurement',
                cell: ({ row }) => {
                    console.log(row.original);
                    return ProductUomLabels[
                        row.original.unitOfMeasurement as ProductUom
                    ];
                },
            },
            {
                id: 'weight',
                header: 'Weight (Kg)',
                accessorFn: (row) => row.specifications?.weight || 0,
            },
            {
                id: 'actions',
                header: 'Actions',
                cell: ({ row }) => {
                    return (
                        <div className='flex h-full items-center gap-2'>
                            <TooltipWrapper label='View Material'>
                                <GanttChartSquare
                                    className='cursor-pointer hover:scale-125'
                                    size={16}
                                    onClick={() =>
                                        handleRedirectToDetails(
                                            row.original._id
                                        )
                                    }
                                    data-testid='view-material-button'
                                />
                            </TooltipWrapper>

                            <TooltipWrapper label='Edit Material'>
                                <Edit
                                    onClick={() => {
                                        setSelectedItem(row.original);
                                        setActionMode('edit');
                                    }}
                                    data-testid='edit-material-button'
                                    className='cursor-pointer text-blue-800 hover:scale-125'
                                    size={16}
                                />
                            </TooltipWrapper>

                            <TooltipWrapper label='Delete Material'>
                                <Trash
                                    className='cursor-pointer text-red-800 hover:scale-125'
                                    size={16}
                                    onClick={() => {
                                        setSelectedItem(row.original);
                                        setActionMode('delete');
                                    }}
                                    data-testid='delete-material-button'
                                />
                            </TooltipWrapper>
                        </div>
                    );
                },
            },
        ],
        [handleRedirectToDetails]
    );

    return (
        <div className='flex h-full flex-col'>
            <TableV2
                label='Available Components'
                columns={columns}
                data={memoizedData || []}
                isLoading={isLoading}
                controls={{
                    enableDelegation: true,
                    enableSearch: true,
                }}
                disabledTooltipColumns={['select', 'actions']}
                actions={[
                    {
                        id: 'add',
                        label: 'Add Component',
                        icon: <Plus size={16} />,
                        onClick: () => setActionMode('create'),
                    },
                ]}
            />

            {actionMode === 'create' && (
                <AddMaterialV2
                    open={actionMode === 'create'}
                    onClose={() => setActionMode(undefined)}
                />
            )}

            {actionMode === 'edit' && selectedItem && (
                <EditMaterialV2
                    open={actionMode === 'edit'}
                    data={selectedItem}
                    onClose={() => setActionMode(undefined)}
                />
            )}

            {actionMode === 'delete' && selectedItem && (
                <DeleteMaterial
                    open={actionMode === 'delete'}
                    material={selectedItem}
                    onClose={() => setActionMode(undefined)}
                />
            )}
        </div>
    );
};
