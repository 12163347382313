import { zodResolver } from '@hookform/resolvers/zod';
import {
    ColumnDef,
    flexRender,
    getCoreRowModel,
    useReactTable,
} from '@tanstack/react-table';
import { Plus, Trash } from 'lucide-react';
import React, { useMemo } from 'react';
import {
    DeepPartial,
    FieldArrayWithId,
    FieldErrors,
    FieldValues,
    FormProvider,
    SubmitHandler,
    useFieldArray,
    useForm,
} from 'react-hook-form';
import { toast } from 'sonner';
import {
    SupportDocType,
    SupportDocTypeLabel,
} from 'src/app/_api_adb2c/shared/support-doc-type.enum';
import { useCreateRuleset } from 'src/app/_api_adb2c/workspace/ruleset/hooks/use-create-ruleset';
import { useRulesets } from 'src/app/_api_adb2c/workspace/ruleset/hooks/use-rulesets';
import {
    SupplyChainNodeType,
    SupplyChainNodeTypeLabel,
} from 'src/app/_api_adb2c/workspace/shared/enum/supply-chain-node-type.enum';
import { FormStepperDialog } from 'src/app/components-v2/stepper-form';
import { DelegationInput } from 'src/app/components/Form/DelegationInput';
import { MultiSelectInputV2 } from 'src/app/components/Form/MultiSelectInputV2';
import { SelectInput } from 'src/app/components/Form/SelectInput';
import { TextInput } from 'src/app/components/Form/TextInput';
import { Button } from 'src/components/ui/button';
import {
    Dialog,
    DialogClose,
    DialogContent,
    DialogDescription,
    DialogFooter,
    DialogHeader,
    DialogTitle,
} from 'src/components/ui/dialog';
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableHeader,
    TableRow,
} from 'src/components/ui/table';
import { cn } from 'src/lib/utils';
import { z, ZodSchema } from 'zod';

function parseErrorMessages(errors: FieldErrors): string {
    const invalidTypeFields: string[] = [];

    const traverseErrors = (errorObj: FieldErrors, path: string[] = []) => {
        for (const key in errorObj) {
            if (errorObj.hasOwnProperty(key)) {
                const error = errorObj[key];
                const currentPath = [...path, key];

                if (
                    error?.type === 'invalid_type' &&
                    typeof error === 'object' &&
                    'type' in error
                ) {
                    invalidTypeFields.push(currentPath.join(' > '));
                    traverseErrors(error as FieldErrors, currentPath);
                }
            }
        }
    };

    traverseErrors(errors);

    if (invalidTypeFields.length > 0) {
        const formattedFields = invalidTypeFields.map((field) =>
            field
                .split(' > ')
                .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                .join(' > ')
        );
        const lastField = formattedFields.pop();
        const fieldMessage =
            formattedFields.length > 0
                ? `${formattedFields.join(', ')}, and ${lastField}`
                : lastField;
        return `${fieldMessage} are required but empty`;
    }

    return '';
}

interface FormDialogProps<T extends FieldValues>
    extends Omit<React.HTMLAttributes<HTMLDivElement>, 'onSubmit'> {
    open: boolean;
    onClose: () => void;
    schema: ZodSchema<T>;
    onSubmit: SubmitHandler<T>;
    title: string;
    description?: string;
    size?: 'small' | 'medium' | 'large'; // default size
    isLoading?: boolean;
    defaultValues?: DeepPartial<T>;
}

export const FormDialog = <T extends FieldValues>({
    open,
    onClose,
    schema,
    onSubmit,
    title,
    description,
    children,
    size = 'medium', // default size
    className,
    isLoading,
    defaultValues,
}: FormDialogProps<T> & { size?: 'small' | 'medium' | 'large' }) => {
    const methods = useForm<T>({
        resolver: zodResolver(schema),
        // @ts-ignore
        defaultValues,
    });

    const getSizeClass = () => {
        switch (size) {
            case 'small':
                return 'max-w-md max-h-96'; // small size
            case 'large':
                return 'max-w-4xl max-h-[90vh]'; // large size
            case 'medium':
            default:
                return 'max-w-2xl max-h-[75vh]'; // medium size
        }
    };

    return (
        <Dialog open={open} onOpenChange={onClose}>
            <DialogContent className={getSizeClass()}>
                <DialogHeader>
                    <DialogTitle className='text-sm font-bold'>
                        {title}
                    </DialogTitle>
                    {description && (
                        <DialogDescription>{description}</DialogDescription>
                    )}
                </DialogHeader>
                <FormProvider {...methods}>
                    <form
                        onSubmit={methods.handleSubmit(
                            (value) => {
                                onSubmit(value);
                            },
                            (error) => {
                                console.error(parseErrorMessages(error));
                                toast.error(parseErrorMessages(error));
                            }
                        )}
                        className={cn(className, 'text-xs')}
                    >
                        {children}
                        <DialogFooter>
                            <DialogClose asChild>
                                <Button size='sm' variant='outline'>
                                    Cancel
                                </Button>
                            </DialogClose>

                            <Button
                                size='sm'
                                type='submit'
                                disabled={isLoading}
                                loading={isLoading}
                            >
                                Submit
                            </Button>
                        </DialogFooter>
                    </form>
                </FormProvider>
            </DialogContent>
        </Dialog>
    );
};

interface Props {
    open: boolean;
    onClose: () => void;
}

const formSchema = z.object({
    name: z.string(),
    documents: z.array(
        z.object({
            _id: z.string().optional(),
            document: z.string(),
            processes: z.array(z.string()),
        })
    ),
    code: z.string(),
    description: z.string().optional(),
    delegateId: z.string().optional(),
});

export const CreateRuleset = ({ open, onClose }: Props) => {
    const { data: rules } = useRulesets();
    const { mutateAsync, isLoading } = useCreateRuleset();

    const form = useForm<z.infer<typeof formSchema>>({
        mode: 'onChange',
        resolver: zodResolver(formSchema),
        defaultValues: {
            documents: [],
        },
    });

    const checkForExistingRuleset = (name: string) => {
        return rules?.some((rule) => rule._id === name);
    };

    const reset = () => {
        form.reset();
        onClose();
    };

    const onSubmit = async (data: z.infer<typeof formSchema>) => {
        const isExist = checkForExistingRuleset(data.name);

        if (isExist) {
            toast.warning(`Ruleset with name ${data.name} already exists`);
            return;
        }

        // Check for any empty document
        const isEmptyDocument = data.documents.some((d) => d.document === '');

        if (isEmptyDocument) {
            toast.warning('Document cannot be empty');
            return;
        }

        await mutateAsync({
            delegateId: data.delegateId,
            body: {
                regions: ['*'],
                rules: data.documents.map((d) => ({
                    processes: d.processes,
                    document: d.document,
                })),
                code: data.code,
                name: data.name,
                description: data.description,
            },
        });

        reset();
    };

    const { fields, append, remove } = useFieldArray({
        control: form.control,
        name: 'documents',
    });

    const columns: ColumnDef<
        FieldArrayWithId<
            {
                name: string;
                documents: {
                    document: string;
                    processes: string[];
                    _id?: string | undefined;
                }[];
                code: string;
                description?: string | undefined;
            },
            'documents',
            'id'
        >
    >[] = useMemo(
        () => [
            {
                id: 'document',
                size: 150,
                header: 'Document Type',
                cell: ({ row }) => (
                    <div className='w-full'>
                        <SelectInput
                            name={`documents[${row.index}].document`}
                            options={Object.values(SupportDocType).map((v) => ({
                                label: SupportDocTypeLabel[v],
                                value: v,
                            }))}
                        />
                    </div>
                ),
            },
            {
                id: 'processes',
                header: 'Applies To',
                size: 300,
                cell: ({ row }) => (
                    <div className='w-full'>
                        <MultiSelectInputV2
                            name={`documents[${row.index}].processes`}
                            options={Object.values(SupplyChainNodeType).map(
                                (value) => ({
                                    label: SupplyChainNodeTypeLabel[value],
                                    value,
                                })
                            )}
                        />
                    </div>
                ),
            },
            {
                id: 'actions',
                header: 'Actions',
                size: 1,
                cell: ({ row }) => (
                    <div className='flex w-full max-w-[80px] justify-center'>
                        <Button
                            onClick={() => remove(row.index)}
                            variant='outline'
                            size='sm'
                        >
                            <Trash className='h-4 w-4' />
                        </Button>
                    </div>
                ),
            },
        ],
        [remove]
    );

    const table = useReactTable({
        columns,
        data: fields,
        getCoreRowModel: getCoreRowModel(),
    });

    return (
        <FormStepperDialog
            open={open}
            onClose={reset}
            className='h-7/10'
            label='Create Ruleset'
            isLoading={isLoading}
            tabs={[
                {
                    label: 'On Behalf Of',
                    content: (
                        <DelegationInput
                            name='delegateId'
                            label='On Behalf Of.'
                        />
                    ),
                },
                {
                    label: 'Basic Information',
                    content: (
                        <div className='grid grid-rows-[auto_auto_auto_1fr] gap-4'>
                            <TextInput inputName='name' label='Name' />
                            <TextInput inputName='code' label='Code' />
                            <TextInput
                                inputName='description'
                                label='Description'
                            />

                            <Table
                                className='min-h-[240px] w-full border'
                                parentClassName='h-full max-h-[240px]'
                            >
                                <TableHeader>
                                    {table
                                        .getHeaderGroups()
                                        .map((headerGroup) => (
                                            <TableRow key={headerGroup.id}>
                                                {headerGroup.headers.map(
                                                    (header) => (
                                                        <TableHead
                                                            key={header.id}
                                                            className='bg-red-800 text-xs text-white'
                                                            style={{
                                                                width: header.column.getSize()
                                                                    ? `${header.column.getSize()}px`
                                                                    : 'auto',
                                                            }}
                                                        >
                                                            {header.isPlaceholder
                                                                ? null
                                                                : flexRender(
                                                                      header
                                                                          .column
                                                                          .columnDef
                                                                          .header,
                                                                      header.getContext()
                                                                  )}
                                                        </TableHead>
                                                    )
                                                )}
                                            </TableRow>
                                        ))}
                                </TableHeader>

                                <TableBody>
                                    {table.getRowModel().rows?.length ? (
                                        table.getRowModel().rows.map((row) => (
                                            <TableRow
                                                key={row.id}
                                                data-state={
                                                    row.getIsSelected() &&
                                                    'selected'
                                                }
                                            >
                                                {row
                                                    .getVisibleCells()
                                                    .map((cell) => (
                                                        <TableCell
                                                            key={cell.id}
                                                            className='overflow-hidden align-top text-xs'
                                                            style={{
                                                                width: cell.column.getSize()
                                                                    ? `${cell.column.getSize()}px`
                                                                    : 'auto',
                                                            }}
                                                        >
                                                            {flexRender(
                                                                cell.column
                                                                    .columnDef
                                                                    .cell,
                                                                cell.getContext()
                                                            )}
                                                        </TableCell>
                                                    ))}
                                            </TableRow>
                                        ))
                                    ) : (
                                        <TableRow>
                                            <TableCell
                                                colSpan={columns.length}
                                                className='h-24 text-center'
                                            >
                                                No data
                                            </TableCell>
                                        </TableRow>
                                    )}
                                </TableBody>
                            </Table>

                            <div>
                                <Button
                                    size='sm'
                                    variant={'outline'}
                                    onClick={() => {
                                        append({
                                            document: '',
                                            processes: [],
                                        });
                                    }}
                                >
                                    <Plus className='h-4 w-4' />
                                </Button>
                            </div>
                        </div>
                    ),
                },
            ]}
            form={form}
            onSubmit={onSubmit}
        />
    );
};
