export const BASE_MATERIALS_QUERY_KEY = 'materials';
export const BASE_MATERIAL_CATEGORY_QUERY_KEY = 'material-categories';
export const BASE_MATERIAL_QUERY_KEY = 'material';

export const MATERIALS_QUERY_KEY = (workspace: string) => [
    BASE_MATERIALS_QUERY_KEY,
    workspace,
];

export const MATERIAL_QUERY_KEY = (workspace: string, materialId: string) => [
    BASE_MATERIAL_QUERY_KEY,
    workspace,
    materialId,
];
