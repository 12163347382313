import { ServiceConfigInterface } from 'src/domain/interfaces/config.context.interface';
import { WorkspaceSearchClient } from './search.client';

export class WorkspaceSearchService {
    private server: WorkspaceSearchClient;

    constructor(config: ServiceConfigInterface, token: string) {
        this.server = new WorkspaceSearchClient(config, token);
    }

    async searchByName(name: string, country: string) {
        console.log(`service<search>| searchByName(): Enter`);
        console.log(`service<search>| searchByName(): $name = ${name}`);
        console.log(`service<search>| searchByName(): $country = ${country}`);

        return await this.server.searchByName(name, country);
    }

    async searchByRegistrationNumber(
        registrationNumber: string,
        country: string,
        diversion = '0'
    ) {
        console.log(`service<search>| searchByRegistrationNumber(): Enter`);
        console.log(
            `service<search>| searchByRegistrationNumber(): $registrationNumber = ${registrationNumber}`
        );
        console.log(
            `service<search>| searchByRegistrationNumber(): $country = ${country}`
        );
        console.log(
            `service<search>| searchByRegistrationNumber(): $diversion = ${diversion}`
        );

        return await this.server.searchByRegistrationNumber(
            registrationNumber,
            country,
            diversion
        );
    }
}
