import { ColumnDef } from '@tanstack/react-table';
import { GanttChartSquare } from 'lucide-react';
import { useCallback, useMemo } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { ProductModel } from 'src/app/_api_adb2c/product/product/models/product.model';
import {
    ProductUom,
    ProductUomLabels,
} from 'src/app/_api_adb2c/purchase/purchase/enums/product-uom.enum';
import { usePurchases } from 'src/app/_api_adb2c/purchase/purchase/hooks/use-purchases';
import { PurchaseModel } from 'src/app/_api_adb2c/purchase/purchase/models/purchase.model';
import { useSuppliers } from 'src/app/_api_adb2c/purchase/suppliers/hooks/use-suppliers';
import { VirtualTable } from 'src/app/components-v2/virtual-table';
import { Button } from 'src/components/ui/button';

interface Props {
    data?: ProductModel;
}

export const ProductOrders = ({ data }: Props) => {
    const [searchParams] = useSearchParams();
    const delegateId = searchParams.get('delegateId') || '';
    const navigate = useNavigate();

    const { data: suppliers } = useSuppliers(delegateId);
    const { data: purchases } = usePurchases(
        {
            draft: true,
            page: 0,
            limit: 0,
        },
        delegateId
    );

    const handleRedirectToDetails = useCallback(
        (id: string) => {
            delegateId
                ? navigate(`/purchases/${id}?delegateId=${delegateId}`)
                : navigate(`/purchases/${id}`);
        },
        [navigate, delegateId]
    );

    const memoizedData = useMemo<PurchaseModel[]>(() => {
        if (!purchases?.data) return [];

        const response = purchases.data
            .filter((x) => {
                const latestVersion = x.versions[x.versions.length - 1];

                if (!latestVersion) return false;

                return latestVersion?.manifest?.find(
                    (y) => y?.product?._id === data?._id
                );
            })
            .map((x) => {
                return {
                    ...x,
                    supplierModel: suppliers?.find(
                        (y) => y?.seller?._id === x?.supplier?._id
                    )?.seller,
                };
            });

        return response;
    }, [purchases, data, suppliers]);

    const columns: ColumnDef<PurchaseModel>[] = [
        {
            id: 'order',
            header: 'Order',
            cell: ({ row }) => {
                const lfOrderNo = row.original.reference.find(
                    (x) => x.source === 'externalDataId'
                )?.value;
                const customerOrderNo = row.original.reference.find(
                    (x) => x.source === 'customer'
                )?.value;
                return (
                    customerOrderNo ||
                    lfOrderNo ||
                    `Internal Order: ${row.original._id.slice(0, 6)}`
                );
            },
        },
        {
            id: 'seller',
            header: 'Seller',
            cell: ({ row }) => {
                return row.original.supplier.seller.name;
            },
        },
        {
            id: 'uom',
            header: 'Unit of Measurement',
            cell: ({ row }) => {
                return data?.unitOfMeasurement
                    ? ProductUomLabels[data?.unitOfMeasurement as ProductUom]
                    : '--';
            },
        },
        {
            id: 'quantity',
            header: 'Quantity',
            cell: ({ row }) => {
                const exist = row.original.versions[0].manifest.find(
                    (y) => y.product._id === data?._id
                );
                return exist?.quantity;
            },
        },
        {
            id: 'actions',
            header: 'Actions',
            cell: ({ row }) => {
                return (
                    <div>
                        <Button
                            variant='ghost'
                            size='sm'
                            onClick={() =>
                                handleRedirectToDetails(row.original._id)
                            }
                        >
                            <GanttChartSquare size={16} />
                        </Button>
                    </div>
                );
            },
        },
    ];

    return (
        <>
            <div className='flex h-full flex-col'>
                <div className='flex w-full items-center justify-between rounded-t-lg border bg-red-800 px-4 py-2'>
                    <span className='text-sm font-bold text-white'>
                        Product Orders
                    </span>

                    <div className='flex gap-4' id='buttonGroup'></div>
                </div>

                <VirtualTable columns={columns} data={memoizedData} />
            </div>
        </>
    );
};
