import {
    ColumnDef,
    CellContext,
    ColumnDefTemplate,
} from '@tanstack/react-table';
import axios from 'axios';
import saveAs from 'file-saver';
import JSZip from 'jszip';
import { CloudDownload, Loader2 } from 'lucide-react';
import { useState } from 'react';
import { useAttachmentDownload } from '../_api_adb2c/attachment/hooks/use-attachment-download';
import { WorkspaceModel } from '../_api_adb2c/product/category/category.model';
import { ProductModel } from '../_api_adb2c/product/product/models/product.model';
import { ProductModel as PurchaseProductModel } from '../_api_adb2c/purchase/purchase/models/manifest-item.model';
import { RenderCircle } from '../pages-v2/Materials/Details/Substance';

export interface BaseSubstance {
    substanceName: string;
    substanceCode: string;
    weight: number;
    ratio: string;
    supplierId: string;
    supplierName: string;
    substanceRegCode: string;
    isManMade: boolean;
    sdsFileId: string[];
    subCompositions: CustomSubstance[];
    isCustom?: boolean;
    customName?: string;
}

export type CustomSubstance = {
    isCustom?: boolean;
    customName?: string;
} & (
    | ({ isCustom: true } & Partial<BaseSubstance>)
    | ({ isCustom: false } & BaseSubstance)
);

const getTotalWeight = (
    product: ProductModel | PurchaseProductModel,
    fixedVersion?: number
) => {
    let latest = product.versions?.[product.versions.length - 1];
    if (fixedVersion) {
        latest = product.versions?.find((v) => v.version === fixedVersion);
    }

    console.debug(`latest`, latest);

    if (!latest) return 0;

    return (latest.billOfMaterials || []).reduce(
        (acc, curr) => acc + (curr.weight || 0) / (curr.consumption || 1),
        0
    );
};

export const generateSubstanceFromProduct = (
    product: ProductModel | PurchaseProductModel,
    fixedVersion?: number
) => {
    let latest = product.versions?.[product.versions.length - 1];

    if (fixedVersion) {
        latest = product.versions?.find((v) => v.version === fixedVersion);
    }

    if (!latest) return [];

    const totalWeight = getTotalWeight(product, fixedVersion);
    console.debug(`totalWeight`, totalWeight);

    const processComposition = (
        comp: any,
        result: CustomSubstance[],
        parentWeight?: number
    ): void => {
        const name = comp.substanceName || comp.name;
        const supplier = comp.supplier as unknown as WorkspaceModel;

        const weightBase = parentWeight || totalWeight;
        const isExist = result.find((x) => x.substanceName === name);

        if (isExist) {
            isExist.weight += comp.weight || 0;
            isExist.ratio =
                (((isExist.weight || 1) / weightBase) * 100).toFixed(2) + '%';
            if (comp.sdsFileId) {
                isExist.sdsFileId?.push(comp.sdsFileId);
            }
        } else {
            const substanceWeight = comp.weight || 0;

            const newSubstance: CustomSubstance = {
                isCustom: false,
                substanceName: name,
                substanceCode: comp.substanceCode || '',
                weight: substanceWeight,
                ratio: ((substanceWeight / weightBase) * 100).toFixed(2) + '%',
                supplierId: supplier?._id || '',
                supplierName: supplier?.name || '',
                substanceRegCode: comp.substanceRegCode || '',
                isManMade: comp.isManMade || false,
                sdsFileId: comp.sdsFileId ? [comp.sdsFileId] : [],
                subCompositions: [],
            };

            if (comp.subCompositions && Array.isArray(comp.subCompositions)) {
                comp.subCompositions.forEach((subComp: any) => {
                    processComposition(
                        subComp,
                        newSubstance.subCompositions,
                        substanceWeight
                    );
                });
            }

            result.push(newSubstance);
        }
    };

    const result: CustomSubstance[] = [];

    latest.billOfMaterials?.forEach((bom) => {
        bom.material.compositions?.forEach((comp) => {
            processComposition(comp, result);
        });
    });

    return result;
};

const useDownloadBundle = () => {
    const { mutateAsync: download } = useAttachmentDownload();

    const getAllSdsFileIds = (
        substance: CustomSubstance
    ): { substanceName: string; sdsFile: string }[] => {
        let fileIds: { substanceName: string; sdsFile: string }[] = [
            ...(substance.sdsFileId || []),
        ].map((id) => ({
            substanceName: substance.substanceName || '',
            sdsFile: id,
        }));

        if (substance.subCompositions && substance.subCompositions.length > 0) {
            substance.subCompositions.forEach((subComp) => {
                fileIds = [...fileIds, ...getAllSdsFileIds(subComp)];
            });
        }

        return [...new Set(fileIds)];
    };

    const downloadBundle = async (substance: CustomSubstance) => {
        const allFileIds = getAllSdsFileIds(substance);
        if (!allFileIds.length) return;

        const files = await Promise.all(
            allFileIds.map(async (id, index) => {
                const token = await download({ body: { id: id.sdsFile } });
                const fileResp = await axios.get(token.token, {
                    responseType: 'blob',
                });
                return {
                    url: fileResp.data,
                    name: `${id.substanceName} - ${token.attachment.originalName}`,
                };
            })
        );

        const zip = new JSZip();
        files.forEach((file) => zip.file(file.name, file.url));
        const zipFile = await zip.generateAsync({ type: 'blob' });

        saveAs(zipFile, `${substance.substanceName}.zip`);
    };

    return { download: downloadBundle };
};

export const SubstanceDownloadButton = ({
    substance,
}: {
    substance: CustomSubstance;
}) => {
    const [isLoading, setIsLoading] = useState(false);
    const { download } = useDownloadBundle();

    const handleDownload = async () => {
        if (!substance.sdsFileId?.length) return;
        setIsLoading(true);

        await download(substance);
        setIsLoading(false);
    };

    if (isLoading) return <Loader2 size={16} className='animate-spin' />;

    return (
        <CloudDownload
            size={16}
            onClick={handleDownload}
            className='cursor-pointer hover:scale-125'
        />
    );
};

export const generateSubstanceColumns = (
    actions?: ColumnDefTemplate<CellContext<CustomSubstance, unknown>>
): ColumnDef<CustomSubstance>[] => {
    const columns: ColumnDef<CustomSubstance>[] = [
        {
            id: 'substanceName',
            header: 'Substance Name',
            accessorFn: (row) => row.substanceName,
        },
        {
            id: 'substanceCode',
            header: 'Substance Code',
            accessorFn: (row) => row.substanceCode,
        },
        {
            id: 'weight',
            header: 'Weight',
            accessorFn: (row) => row.weight,
        },
        {
            id: 'ratio',
            header: 'Ratio',
            cell: ({ row }) => {
                return row.original.ratio;
            },
        },
        {
            id: 'supplierName',
            header: 'Supplier',
            accessorFn: (row) => row.supplierName,
        },
        {
            id: 'reachCode',
            header: 'REG',
            accessorFn: (row) => row.substanceRegCode,
        },
        {
            id: 'isManMade',
            header: 'Man-Made',
            cell: ({ row }) => {
                return row.original.isManMade ? (
                    <RenderCircle color='completed' />
                ) : (
                    <RenderCircle color='empty' />
                );
            },
        },
        {
            id: 'sdsFileId',
            header: 'SDS',
            cell: ({ row }) => {
                return row.original.sdsFileId &&
                    row.original.sdsFileId.length > 0 ? (
                    <RenderCircle color='completed' />
                ) : (
                    <RenderCircle color='empty' />
                );
            },
        },
    ];

    if (actions)
        columns.push({ id: 'actions', header: 'Actions', cell: actions });

    return columns;
};
