import { useMutation, useQueryClient } from '@tanstack/react-query';
import { toast } from 'sonner';
import { useContextStore } from 'src/app/stores/context-store';
import { TEMPLATES_QUERY_KEY } from './template-query-keys';
import { useTemplateService } from './use-template-service';

export function useRemoveTemplate() {
    const context = useContextStore();
    const client = useQueryClient();
    const { service } = useTemplateService();

    return useMutation({
        mutationFn: async ({
            delegateId,
            item,
        }: {
            delegateId?: string;
            item: string;
        }) => {
            if (
                !item ||
                !context.workspace?._id ||
                (!delegateId && !context.workspace?._id)
            ) {
                throw new Error(
                    `useRemoveTemplate(): Missing required arguments`
                );
            }

            return await service.remove(
                delegateId || context.workspace?._id,
                item
            );
        },
        onSuccess: (_, { delegateId }) => {
            client.invalidateQueries({
                queryKey: TEMPLATES_QUERY_KEY(
                    delegateId || context.workspace?._id || ''
                ),
            });

            toast.success('Successfully deleted template');
        },
        onError: (err) => {
            const error = err as Error;

            toast.error(`Failed to delete template: ${error.message}`);
        },
    });
}
