import { RadioGroup, RadioGroupItem } from 'src/components/ui/radio-group';
import { Label } from 'src/components/ui/label';
import { cn } from 'src/lib/utils';

interface RadioProps
    extends Omit<React.HTMLAttributes<HTMLDivElement>, 'onChange'> {
    name: string;
    label?: string;
    value: boolean;
    onValueChange: (value: string) => void;
    options: { label: string; value: string }[];
}

export function Radio({
    name,
    label,
    className,
    options,
    value,
    onValueChange: onChange,
    ...args
}: RadioProps) {
    return (
        <div className='flex flex-1 flex-col'>
            {label && <Label className='text-xs'>{label}</Label>}

            <RadioGroup
                onValueChange={onChange}
                value={value ? 'true' : 'false'}
                className={cn('flex gap-4 py-1', className)}
            >
                {options.map((opt) => (
                    <div
                        key={opt.value}
                        className='flex items-center space-x-3 space-y-0'
                    >
                        <RadioGroupItem value={opt.value} id={opt.label} />
                        <Label htmlFor={opt.label} className='font-normal'>
                            {opt.label}
                        </Label>
                    </div>
                ))}
            </RadioGroup>
        </div>
    );
}
