import { ServiceConfigInterface } from '../../../../domain/interfaces/config.context.interface';
import { PurchaseApiClient } from './purchase.client';
import {
    PurchaseOrderLinkModel,
    PurchaseOrderTraceModel,
    PurchaseOrderUpdateDataObject,
} from './purchase.model';

/**
 * @class
 * @name PurchaseService
 * @description The Purchase service for LFX
 * @author Ian Neo <ian.neo@ecloudvalley.com>
 */
export class PurchaseService {
    private server: PurchaseApiClient;

    constructor(config: ServiceConfigInterface, token: string) {
        this.server = new PurchaseApiClient(config, token);
    }

    async create(workspace: string, purchase: any) {
        console.log(`service<purchase>| create(): Enter`);
        console.log(`service<purchase>| create(): $workspace = ${workspace}`);
        console.log(
            `service<purchase>| create(): $data = ${JSON.stringify(purchase)}`
        );

        return await this.server.create(workspace, purchase);
    }

    async get(workspace: string, item: string, diversion: string = '0') {
        console.log(`service<purchase>| get(): Enter`);
        console.log(`service<purchase>| get(): $workspace = ${workspace}`);
        console.log(`service<purchase>| get(): $item = ${item}`);
        console.log(
            `service<purchase>| get(): $diversion = ${JSON.stringify(
                diversion
            )}`
        );

        return await this.server.get(workspace, item, diversion);
    }

    async list(workspace: string) {
        console.log(`service<purchase>| list(): Enter`);
        console.log(`service<purchase>| list(): $workspace = ${workspace}`);

        return await this.server.list(workspace);
    }

    async listItems(workspace: string) {
        console.log(`service<purchase>| listItems(): Enter`);
        console.log(
            `service<purchase>| listItems(): $workspace = ${workspace}`
        );

        return await this.server.listItems(workspace);
    }

    async update(
        workspace: string,
        item: string,
        purchase: PurchaseOrderUpdateDataObject
    ) {
        console.log(`service<purchase>| update(): Enter`);
        console.log(`service<purchase>| update(): $workspace = ${workspace}`);
        console.log(`service<purchase>| update(): $item = ${item}`);
        console.log(
            `service<purchase>| update(): $data = ${JSON.stringify(purchase)}`
        );

        return await this.server.update(workspace, item, purchase);
    }

    async delete(workspace: string, item: string) {
        console.log(`service<purchase>| delete(): Enter`);
        console.log(`service<purchase>| delete(): $workspace = ${workspace}`);
        console.log(`service<purchase>| delete(): $item = ${item}`);

        return await this.server.delete(workspace, item);
    }

    async trace(
        workspace: string,
        item: string
    ): Promise<PurchaseOrderTraceModel[]> {
        console.log(`service<purchase>| trace(): Enter`);
        console.log(`service<purchase>| trace(): $workspace = ${workspace}`);
        console.log(`service<purchase>| trace(): $item = ${item}`);

        return await this.server.trace(workspace, item);
    }

    async download(workspace: string, item: string) {
        console.log(`service<purchase>| download(): Enter`);
        console.log(`service<purchase>| download(): $workspace = ${workspace}`);
        console.log(`service<purchase>| download(): $item = ${item}`);

        return await this.server.download(workspace, item);
    }

    async reverse(
        workspace: string,
        id: string
    ): Promise<PurchaseOrderLinkModel[]> {
        console.log(`service<purchase>| reverse(): Enter`);
        console.log(`service<purchase>| reverse(): $workspace = ${workspace}`);
        console.log(`service<purchase>| reverse(): $id = ${id}`);

        return await this.server.reverse(workspace, id);
    }

    async listByBatch({ orderIds }: { orderIds: string[] }) {
        console.log(`service<purchase>| listByBatch(): Enter`);
        console.log(`service<purchase>| listByBatch(): $ids = ${orderIds}`);

        return await this.server.listByBatch(orderIds);
    }
}
