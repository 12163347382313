import { useContextStore } from 'src/app/stores/context-store';
import { useSolicitService } from './use-solicit-service';
import { useQuery } from '@tanstack/react-query';
import { SOLICIT_DOCUMENTS_QUERY_KEY } from './solicit-query-keys';
import { toast } from 'sonner';
import { WorkspaceLibraryModel } from '../../workspace/workspace.model';

export function useSolicitDocuments(
    id: string,
    delegateId?: string,
    enabled = true
) {
    const { service } = useSolicitService();
    const context = useContextStore();

    return useQuery<WorkspaceLibraryModel>({
        queryKey: SOLICIT_DOCUMENTS_QUERY_KEY(
            delegateId || context.workspace?._id || '',
            id
        ),
        queryFn: async () => {
            return await service.list(
                delegateId || context.workspace?._id || '',
                id
            );
        },
        onError: (err) => {
            const error = err as Error;

            toast.error(`Failed to fetch solicit documents: ${error.message}`);
        },
        enabled: !!id && enabled,
    });
}
