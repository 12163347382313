import { useQuery } from '@tanstack/react-query';
import { toast } from 'sonner';
import { useContextStore } from 'src/app/stores/context-store';
import { SALES_ORDER_QUERY_KEY } from './sales-order-query-keys';
import { useSalesOrderService } from './use-sales-order-service';
import { SalesOrderModel } from '../models/sales-order.model';

export function useSalesOrderByPurchase(
    purchaseId: string,
    delegateId?: string,
    enabled = true
) {
    const context = useContextStore();
    const { service } = useSalesOrderService();

    return useQuery<SalesOrderModel>({
        queryKey: SALES_ORDER_QUERY_KEY(
            delegateId || context.workspace?._id || '',
            purchaseId
        ),
        queryFn: async () => {
            return await service.getByPurchaseOrder(
                delegateId || context.workspace?._id || '',
                purchaseId
            );
        },
        onError: (err) => {
            const error = err as Error;

            toast.error(`Failed to fetch sales order: ${error.message}`);
        },
        enabled: enabled && !!purchaseId,
    });
}
