import { useMutation, useQueryClient } from '@tanstack/react-query';
import { toast } from 'sonner';
import { useContextStore } from 'src/app/stores/context-store';
import { DELEGATIONS_QUERY_KEY } from './delegation-query-keys';
import { useDelegationService } from './use-delegation-service';

export function useDeleteDelegation() {
    const { service } = useDelegationService();
    const context = useContextStore();
    const client = useQueryClient();

    return useMutation({
        mutationKey: ['delegations', 'delete'],
        mutationFn: async ({
            delegateId,
            id,
        }: {
            delegateId?: string;
            id: string;
        }) => {
            return await service.remove(
                delegateId || context.workspace?._id || '',
                id
            );
        },
        onSuccess: (_, { delegateId }) => {
            client.invalidateQueries({
                queryKey: DELEGATIONS_QUERY_KEY(
                    delegateId || context.workspace?._id || ''
                ),
            });

            toast.success('Successfully deleted delegation');
        },
        onError: (err) => {
            const error = err as Error;

            toast.error(`Failed to delete delegation: ${error.message}`);
        },
    });
}
