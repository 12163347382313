import { zodResolver } from '@hookform/resolvers/zod';
import { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';
import { ProductModel } from 'src/app/_api_adb2c/product/product/models/product.model';
import { useCreatePurchase } from 'src/app/_api_adb2c/purchase/purchase/hooks/use-create-purchase';
import { ExternalReferenceModel } from 'src/app/_api_adb2c/purchase/purchase/models/external-reference.model';
import { DialogV2 } from 'src/app/components-v2/dialog-v2';
import { DelegationInput } from 'src/app/components/Form/DelegationInput';
import { z } from 'zod';
import { AddPurchaseDocumentRequirements } from './add-purchase-document-requirements';
import { AddPurchaseItemFields } from './add-purchase-item-fields';
import { AddPurchaseRequiredFields } from './add-purchase-required-fields';

export interface CascadeNodeProps {
    delegateId: string;
    purchaseOrderId: string;
    workspaceId: string;
}

interface Props {
    open: boolean;
    onClose: () => void;
    product?: ProductModel;
    cascade?: CascadeNodeProps;
}

export const purchaseOrderItemDataObjectSchema = z.object({
    product: z.string(),
    ppu: z.coerce.number(),
    quantity: z.coerce.number(),
    unit: z.string(),
    sequence: z.coerce.number().optional(),
});

export const formSchema = z.object({
    currency: z.string(),
    externalDataId: z.string().optional(),
    items: z.array(purchaseOrderItemDataObjectSchema),
    parentOrderReference: z.string().optional(),
    rules: z.string().optional(),
    shippedOn: z.array(z.date()).optional(),
    shipToAddress: z.string().optional(),
    purchaseProcesses: z.array(z.string()).optional(),
    orderNature: z.string().optional(),
    supplier: z.string(),
    supplierProducer: z.string().optional(),
    delegateId: z.string().optional(),
});

export const AddPurchase = ({ open, onClose, product, cascade }: Props) => {
    const [searchParams] = useSearchParams();
    const delegateId = searchParams.get('delegateId') || '';
    const { mutateAsync, isLoading } = useCreatePurchase();

    const defaultItems = useMemo(() => {
        const items = [];
        if (product && !cascade) {
            items.push({
                product: product._id,
                ppu: 0,
                quantity: 1,
                unit: product.unitOfMeasurement,
            });
        }

        return items;
    }, [product, cascade]);

    const form = useForm<z.infer<typeof formSchema>>({
        mode: 'onChange',
        resolver: zodResolver(formSchema),
        defaultValues: {
            delegateId: cascade?.delegateId || delegateId,
            items: defaultItems,
        },
    });

    const reset = () => {
        onClose();
        form.reset();
    };

    const onSubmit = async (data: z.infer<typeof formSchema>) => {
        const references: ExternalReferenceModel[] = [];

        if (data.items.length === 0) return;
        if (data.items.some((x) => !x.product)) return;

        if (data.externalDataId) {
            references.push({
                source: 'externalDataId',
                value: data.externalDataId,
            });
        }

        await mutateAsync({
            body: {
                currency: data.currency,
                isFullyShipped: false,
                nature: data.orderNature ? data.orderNature : undefined,
                processes:
                    (data.purchaseProcesses || []).length > 0
                        ? data.purchaseProcesses
                        : undefined,
                rules: data.rules ? [data.rules] : [],
                shipToAddress: data.shipToAddress || '-',
                supplier: data.supplier,
                items: data.items.map((x) => ({
                    ...x,
                    shippedQuantity: x.quantity,
                })),
                reference: references,
                virtualRoot: product?._id,
                isVirtual: !!product?._id,
                parent: cascade?.purchaseOrderId,
            },
            delegateId: cascade?.delegateId || data.delegateId,
        });

        reset();
    };

    return (
        <DialogV2
            open={open}
            onClose={onClose}
            form={form}
            onSubmit={onSubmit}
            title='Add Purchase Order'
            isLoading={isLoading}
            isStepDialog
            size='lg'
            steps={[
                {
                    title: 'Delegation Controls',
                    description: 'Select the delegate for this purchase order.',
                    content: (
                        <DelegationInput
                            name='delegateId'
                            label='On Behalf Of.'
                            delegateId={cascade?.workspaceId}
                        />
                    ),
                },
                {
                    title: 'Basic Information',
                    description:
                        'Enter the basic information for this purchase order.',
                    content: <AddPurchaseRequiredFields />,
                },
                {
                    title: 'Items',
                    description: 'Add the items for this purchase order.',
                    content: <AddPurchaseItemFields />,
                },
                {
                    title: 'Purchase Ruleset',
                    description:
                        'Add the purchase rules for this purchase order.',
                    content: <AddPurchaseDocumentRequirements />,
                },
            ]}
        ></DialogV2>
    );
};
