import { DateTime } from 'luxon';
import { useEffect, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { DatePicker } from 'src/app/components-v2/date-picker';
import { Label } from 'src/components/ui/label';
import { ProductByCategory } from './product-by-category';
import { ProductAssortmentByCategory } from './product-assortment-by-category';
import { ProductDistributionByOrderedQuantity } from './product-distribution-by-ordered-quantity';

export function ProductsReport() {
    const [searchParams, setSearchParams] = useSearchParams();
    const startDate = useMemo(() => {
        const startDate = searchParams.get('startDate');

        if (!startDate) return undefined;

        return DateTime.fromISO(startDate).toJSDate();
    }, [searchParams]);

    const endDate = useMemo(() => {
        const endDate = searchParams.get('endDate');

        if (!endDate) return undefined;

        return DateTime.fromISO(endDate).toJSDate();
    }, [searchParams]);

    useEffect(() => {
        const paramStartDate = searchParams.get('startDate');
        const paramEndDate = searchParams.get('endDate');

        if (!paramStartDate || !paramEndDate) {
            setSearchParams((prev) => {
                const now = DateTime.now();
                prev.set(
                    'startDate',
                    now.startOf('month').toFormat('yyyy-MM-dd')
                );
                prev.set('endDate', now.toFormat('yyyy-MM-dd'));


                return prev;
            });
        }
    }, [searchParams, setSearchParams]);

    return (
        <div className='grid h-full grid-cols-1 grid-rows-[auto_1fr] gap-8'>
            <div className='grid grid-cols-1 gap-4 rounded-lg border bg-white px-4 py-4 shadow'>
                <span className='text-sm font-bold uppercase'>
                    Report Filter
                </span>

                <div className='mb-2 grid grid-cols-6 gap-4'>
                    <div className='flex flex-col gap-2'>
                        <Label
                            htmlFor='startDate'
                            className='text-xs font-normal uppercase text-gray-400'
                        >
                            From
                        </Label>
                        <DatePicker
                            id='startDate'
                            className='w-full text-xs'
                            date={startDate}
                            maxDate={endDate}
                            onSelect={(date) => {
                                setSearchParams((prev) => {
                                    const newDate =
                                        DateTime.fromJSDate(date).toFormat(
                                            'yyyy-MM-dd'
                                        );

                                    prev.set('startDate', newDate);

                                    return prev;
                                });
                            }}
                        />
                    </div>

                    <div className='flex flex-col gap-2'>
                        <Label
                            htmlFor='endDate'
                            className='text-xs font-normal uppercase text-gray-400'
                        >
                            Until
                        </Label>
                        <DatePicker
                            id='endDate'
                            className='w-full text-xs'
                            date={endDate}
                            minDate={startDate}
                            onSelect={(date) => {
                                setSearchParams((prev) => {
                                    const newDate =
                                        DateTime.fromJSDate(date).toFormat(
                                            'yyyy-MM-dd'
                                        );

                                    prev.set('endDate', newDate);

                                    return prev;
                                });
                            }}
                        />
                    </div>
                </div>
            </div>

            <div className='grid grid-cols-2 grid-rows-2 gap-4'>
                <ProductByCategory startDate={startDate} endDate={endDate} />

                <ProductAssortmentByCategory
                    startDate={startDate}
                    endDate={endDate}
                />

                <ProductDistributionByOrderedQuantity
                    startDate={startDate}
                    endDate={endDate}
                />
            </div>
        </div>
    );
}
