export const BASE_CATEGORY_QUERY_KEY = 'category';
export const BASE_CATEGORIES_QUERY_KEY = 'categories';
export const BASE_SEARCH_CATEGORY_QUERY_KEY = 'search-category';
export const BASE_CATEGORY_PRODUCT_QUERY_KEY = 'category-product';

export const CATEGORY_QUERY_KEY = (workspace: string, item: string) => [
    BASE_CATEGORY_QUERY_KEY,
    workspace,
    item,
];

export const CATEGORIES_QUERY_KEY = (workspace: string) => [
    BASE_CATEGORIES_QUERY_KEY,
    workspace,
];

export const SEARCH_CATEGORY_QUERY_KEY = (
    workspace: string,
    search: string
) => [BASE_SEARCH_CATEGORY_QUERY_KEY, workspace, search];

export const CATEGORY_PRODUCT_QUERY_KEY = (
    workspace: string,
    category: string
) => [BASE_CATEGORY_PRODUCT_QUERY_KEY, workspace, category];
