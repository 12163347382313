import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useContextStore } from 'src/app/stores/context-store';
import { useTemplateService } from './use-template-service';
import { TemplateCreateDataObject } from '../template-create-data-object';
import { TEMPLATES_QUERY_KEY } from './template-query-keys';
import { toast } from 'sonner';

export function useCreateTemplate() {
    const client = useQueryClient();
    const context = useContextStore();
    const { service } = useTemplateService();

    return useMutation({
        mutationFn: async ({
            body,
            delegateId,
            template,
        }: {
            body: TemplateCreateDataObject;
            delegateId?: string;
            template: string;
        }) => {
            return await service.create(
                delegateId || context.workspace?._id || '',
                template,
                body
            );
        },
        onSuccess: (_, { delegateId }) => {
            client.invalidateQueries(
                TEMPLATES_QUERY_KEY(delegateId || context.workspace?._id || '')
            );

            toast.success('Template created');
        },
        onError: (err) => {
            const error = err as Error;

            toast.error(`Failed to create template: ${error.message}`);
        },
    });
}
