import { useMutation, useQueryClient } from '@tanstack/react-query';
import { toast } from 'sonner';
import { useContextStore } from 'src/app/stores/context-store';
import { RulesetUpdateDataObject } from '../ruleset-update-data-object';
import { RULESETS_QUERY_KEY, RULESET_QUERY_KEY } from './ruleset-query-keys';
import { useRulesetService } from './use-ruleset-service';

export function useUpdateRuleset() {
    const context = useContextStore();
    const { service } = useRulesetService();
    const client = useQueryClient();

    return useMutation({
        mutationFn: async ({
            delegateId,
            id,
            body,
        }: {
            delegateId?: string;
            id: string;
            body: RulesetUpdateDataObject;
        }) => {
            return await service.update(
                delegateId || context.workspace?._id || '',
                id,
                body
            );
        },
        onSuccess: (_, { delegateId, id }) => {
            client.invalidateQueries(
                RULESETS_QUERY_KEY(delegateId || context.workspace?._id || '')
            );

            client.invalidateQueries(
                RULESET_QUERY_KEY(
                    delegateId || context.workspace?._id || '',
                    id
                )
            );

            toast.success('Successfully updated ruleset');
        },
        onError: (err) => {
            const error = err as Error;
            toast.error(`Failed to create ruleset: ${error.message}`);
        },
    });
}
