import { useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { WorkspaceModel } from 'src/app/_api_adb2c/product/category/category.model';
import { MaterialComposition } from 'src/app/_api_adb2c/product/material/request/product-material-data-object';
import { useProduct } from 'src/app/_api_adb2c/product/product/hooks/use-product';
import { FileModel } from 'src/app/_api_adb2c/workspace/file/file.model';
import { TableV2 } from 'src/app/components-v2/table-v2';
import {
    CustomSubstance,
    generateSubstanceColumns,
    generateSubstanceFromProduct,
    SubstanceDownloadButton,
} from 'src/app/utils/substance';
import { VirtualComposition } from '../../../Materials/Details/Substance';

interface Props {
    productId: string;
}

export interface VirtualWorkspaceComposition
    extends Omit<MaterialComposition, 'supplier'> {
    supplier?: WorkspaceModel;
    document?: FileModel;
    netWeight?: number;
}

export interface ConsolidatedComposition
    extends Omit<VirtualComposition, 'supplier' | 'sdsFileId'> {
    supplier?: WorkspaceModel[];
    sdsFileId?: any[];
}

export function ProductSubstances({ productId }: Props) {
    const [searchParams] = useSearchParams();
    const delegateId = searchParams.get('delegateId') || '';

    const { data: product } = useProduct(productId, delegateId);

    const memoizedItems: CustomSubstance[] = useMemo(() => {
        if (!product) return [];

        return generateSubstanceFromProduct(product);
    }, [product]);

    return (
        <>
            <div className='flex h-full flex-col'>
                <TableV2
                    columns={generateSubstanceColumns(({ row }) => (
                        <div className='flex items-center gap-2'>
                            <SubstanceDownloadButton substance={row.original} />
                        </div>
                    ))}
                    data={memoizedItems}
                    label='Bill of Substance'
                    subRowsField='subCompositions'
                    disabledTooltipColumns={['actions']}
                />
            </div>
        </>
    );
}
