import {
    EventType,
    InteractionRequiredAuthError,
    PublicClientApplication,
} from '@azure/msal-browser';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import useAppConfig from 'src/infrastructure/hooks/use-config.hook';
import { UserService } from '../_api_adb2c/workspace/users/user.service';
import { WorkspaceModel } from '../_api_adb2c/workspace/workspace/workspace.model';
import { useContextStore } from '../stores/context-store';
import { CustomNavigationClient } from '../utils/navigation-client';

export const useAzureB2CAuth = (pca: PublicClientApplication) => {
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const config = useAppConfig();
    const { setWorkspace, setUser, setToken } = useContextStore();
    const { instance } = useMsal();
    const isAuthenticated = useIsAuthenticated();
    const [isLoading, setIsLoading] = useState(true);
    const [isCompleted, setIsCompleted] = useState(false);

    useEffect(() => {
        console.log('these are the search params', searchParams);
        console.log(searchParams.get('w'));
    }, [searchParams]);

    const navigationClient = new CustomNavigationClient(navigate);
    pca.setNavigationClient(navigationClient);
    pca.enableAccountStorageEvents();

    const account = useMemo(() => {
        const accounts = pca.getAllAccounts();
        const account = accounts[0];

        pca.setActiveAccount(account);

        return pca.getActiveAccount();
    }, [pca]);

    const verifyCompleted = useCallback(
        async (workspace: WorkspaceModel) => {
            const mandatoryFields = [
                ['company', 'demographics', 'name'],
                ['company', 'demographics', 'address', 'country'],
                ['company', 'demographics', 'registrationNumber'],
                ['company', 'demographics', 'processes'],
                ['company', 'demographics', 'phone'],
                ['company', 'demographics', 'address', 'street'],
                ['company', 'demographics', 'address', 'city'],
                ['company', 'demographics', 'address', 'state'],
                ['company', 'demographics', 'address', 'postCode'],
                ['company', 'demographics', 'since'],
            ];

            const isCompleted = mandatoryFields.every((field) => {
                if (Array.isArray(field)) {
                    // Handle nested properties
                    const reducedValue = field.reduce((obj, key) => {
                        return obj && obj[key];
                    }, workspace);

                    const isCompleted = reducedValue !== undefined;

                    return isCompleted;
                }

                return (
                    workspace[field] !== undefined &&
                    workspace[field] !== null &&
                    workspace[field] !== ''
                );
            });

            setIsCompleted(isCompleted);
        },
        [setIsCompleted]
    );

    const setupAccount = useCallback(async () => {
        const accounts = pca.getAllAccounts();
        const account = accounts[0];

        pca.setActiveAccount(account);
        pca.getActiveAccount();

        if (!account) return;

        try {
            const token = await pca.acquireTokenSilent({
                account: account,
                scopes: ['openid'],
            });

            setToken(token.idToken);

            const service = new UserService(config, token.idToken);

            const user = await service.getProfile();
            const workspaces = await service.getWorkspaces();

            const paramsWorkspace =
                searchParams.get('workspace') || searchParams.get('w');

            const searchParamsWorkspace = workspaces.find(
                (w) => w._id === paramsWorkspace
            );

            const predeterminedWorkspaceId = sessionStorage.getItem(
                'predeterminedWorkspace'
            );
            const predeterminedWorkspace = workspaces.find(
                (w) => w._id === predeterminedWorkspaceId
            );

            if (predeterminedWorkspace) {
                sessionStorage.removeItem('predeterminedWorkspace');
            }

            const sessionWorkspaceId = sessionStorage.getItem('workspace');
            const sessionWorkspace = workspaces.find(
                (w) => w._id === sessionWorkspaceId
            );

            const redirectWorkspaceId = searchParams.get('workspace');
            const redirectWorkspace = workspaces.find(
                (w) => w._id === redirectWorkspaceId
            );

            const workspace =
                searchParamsWorkspace ||
                predeterminedWorkspace ||
                redirectWorkspace ||
                sessionWorkspace ||
                workspaces[0];

            sessionStorage.setItem('workspace', workspace._id);

            if (!workspace) return;

            setUser(user);
            setWorkspace(workspace);

            verifyCompleted(workspace);
            setIsLoading(false);
        } catch (err) {
            if (err instanceof InteractionRequiredAuthError) {
                console.log('interaction required', err);
                await pca.acquireTokenRedirect({
                    scopes: ['openid'],
                    account: account,
                });
            }
        }
    }, [
        pca,
        config,
        setUser,
        setWorkspace,
        setToken,
        searchParams,
        verifyCompleted,
    ]);

    useEffect(() => {
        setupAccount();
    }, [setupAccount, account?.idToken, isAuthenticated]);

    useEffect(() => {
        // This will be run on component mount
        const callbackId = instance.addEventCallback((message) => {
            // This will be run every time an event is emitted after registering this callback
            if (message.eventType === EventType.LOGIN_SUCCESS) {
                const result = message.payload;
                console.log('login success', result);
                // Do something with the result
            }
        });

        return () => {
            // This will be run on component unmount
            if (callbackId) {
                instance.removeEventCallback(callbackId);
            }
        };
    }, [instance]);

    return { account, isLoading, isCompleted };
};
