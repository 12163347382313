import { useFormContext } from 'react-hook-form';
import { useRulesets } from 'src/app/_api_adb2c/workspace/ruleset/hooks/use-rulesets';
import { SelectInput } from 'src/app/components/Form/SelectInput';

export const AddPurchaseDocumentRequirements = () => {
    const form = useFormContext();
    const delegateId = form.watch('delegateId');

    const { data } = useRulesets(delegateId);
    console.log(data);

    return (
        <>
            <SelectInput
                label='Ruleset'
                name='rules'
                options={(data || []).map((x) => ({
                    label: x.name,
                    value: x._id,
                }))}
            />
        </>
    );
};
