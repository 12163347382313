import { useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { usePurchaseTrace } from 'src/app/_api_adb2c/purchase/purchase/hooks/use-purchase-trace';
import { useVirtualRoot } from 'src/app/_api_adb2c/purchase/virtual-purchase/hooks/use-virtual-root';

interface Props {
    productId: string;
}

export function ProductTraceMapInformation({ productId }: Props) {
    const [searchParams] = useSearchParams();
    const delegateId = searchParams.get('delegateId') || '';

    // const { data: product } = useProduct(productId, delegateId);
    const { data: order } = useVirtualRoot(productId, delegateId);
    const { data: traces } = usePurchaseTrace(order?._id || '', delegateId);

    const nodeInformation = useMemo(() => {
        const nodes: string[] = [];
        const suppliers: string[] = [];

        if (order) {
            nodes.push(order._id);
            suppliers.push(order.supplier.seller._id);
        }

        traces?.forEach((trace) => {
            nodes.push(trace._id);
            suppliers.push(trace.supplier._id);
        });

        return {
            total: Array.from(new Set(nodes)).length,
            suppliers: Array.from(new Set(suppliers)).length,
        };
    }, [order, traces]);

    return (
        <div className='h-full w-full rounded-lg border'>
            <div className='flex flex-col gap-2 px-4 py-2'>
                <span className='text-sm font-semibold underline underline-offset-4'>
                    Map Summary
                </span>

                <div className='flex flex-col gap-2 text-xs'>
                    <div className='flex justify-between'>
                        <span>Entities</span>
                        <span>{nodeInformation.total}</span>
                    </div>

                    <div className='flex justify-between'>
                        <span>Suppliers</span>
                        <span>{nodeInformation.suppliers}</span>
                    </div>
                </div>
            </div>
        </div>
    );
}
