import { cn } from 'src/app/utils/cn';

export interface GroupNodeProps {
    data: GroupNodeDataProps;
}

interface GroupNodeDataProps extends React.HTMLAttributes<HTMLDivElement> {
    label: string;
    height: number;
}

export function PurchaseCascadeGroupNode({ ...props }: GroupNodeProps) {
    const { label, className, height } = props.data;

    return (
        <div
            className={cn(
                `w-48 rounded-lg border-2 border-gray-400`,
                className
            )}
            // Not sure why tailwind can't grab dynamic height so we have to do it manually
            style={{ height: `${height}px` }}
        >
            <div className='w-full bg-red-800 px-2'>
                <span className='text-sm font-bold text-white'>{label}</span>
            </div>
        </div>
    );
}
