import { ColumnDef } from '@tanstack/react-table';
import { Paperclip, Trash2, Upload } from 'lucide-react';
import { useFormContext } from 'react-hook-form';
import { CertificationType } from 'src/app/_api_adb2c/workspace/workspace/hooks/use-workspace-certification-types';
import { FileInput, FileUploader } from 'src/app/components-v2/file-dropzone';
import { TableV2 } from 'src/app/components-v2/table-v2';
import { Select } from 'src/app/components/Inputs/Select';
import { cn } from 'src/app/utils/cn';
import { Button } from 'src/components/ui/button';
import { z } from 'zod';
import { schema } from './add-product-material-v3';

interface Props {
    certificateTypes: CertificationType[];
    className?: string;
}

export interface CertificateUpload {
    file: File;
    certificateTypeId: string;
}

const dropZoneConfig = {
    maxFiles: 10,
    maxSize: 1024 * 1024 * 10, // 10MB
    multiple: true,
    accept: {
        'application/pdf': ['.pdf'],
        'application/msword': ['.doc'],
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
            ['.docx'],
    },
};

export function AddProductCertificate({ certificateTypes, className }: Props) {
    const { setValue, watch } = useFormContext<z.infer<typeof schema>>();
    const certificates = watch('file') || [];

    const handleFileChange = (files: File[] | null) => {
        if (!files) return;

        const newCertificates = files.map((file) => ({
            file,
            certificateTypeId: '',
        }));

        setValue('file', [...certificates, ...newCertificates]);
    };

    const handleTypeChange = (index: number, typeId: string) => {
        const updatedCertificates = [...certificates];
        updatedCertificates[index] = {
            ...updatedCertificates[index],
            certificateTypeId: typeId,
        };
        setValue('file', updatedCertificates);
    };

    const handleRemove = (index: number) => {
        const updatedCertificates = certificates.filter((_, i) => i !== index);
        setValue('file', updatedCertificates);
    };

    const columns: ColumnDef<CertificateUpload>[] = [
        {
            accessorKey: 'file',
            header: 'Document',
            cell: ({ row }) => (
                <div className='flex items-center gap-2'>
                    <Paperclip className='h-4 w-4' />
                    <span>{row.original.file.name}</span>
                </div>
            ),
        },
        {
            accessorKey: 'certificateTypeId',
            header: 'Certificate Type',
            cell: ({ row }) => (
                <Select
                    value={row.original.certificateTypeId}
                    onValueChange={(value) =>
                        handleTypeChange(row.index, value)
                    }
                    options={certificateTypes.map((type) => ({
                        label: type.name,
                        value: type.id,
                    }))}
                />
            ),
        },
        {
            id: 'actions',
            size: 10,
            cell: ({ row }) => (
                <Button
                    variant='ghost'
                    size='sm'
                    onClick={() => handleRemove(row.index)}
                    className='hover:text-destructive'
                >
                    <Trash2 className='h-4 w-4' />
                </Button>
            ),
        },
    ];

    return (
        <div className={cn('space-y-4', className)}>
            <FileUploader
                value={null}
                onValueChange={handleFileChange}
                dropzoneOptions={dropZoneConfig}
                reSelect
                className='min-h-[120px]'
            >
                <FileInput className='group h-full border border-dashed border-gray-400 bg-gray-50/50 transition-colors hover:border-primary/20 hover:bg-gray-50'>
                    <div className='flex h-full w-full flex-col items-center justify-center gap-2 p-4'>
                        <Upload className='h-6 w-6 text-gray-400 group-hover:text-gray-500' />
                        <div className='text-center'>
                            <span className='text-sm text-gray-500'>
                                <span className='font-semibold text-primary hover:underline'>
                                    Click to upload
                                </span>{' '}
                                or drag and drop
                            </span>
                            <p className='mt-1 text-xs text-gray-400'>
                                PDF, DOC, or DOCX (max. 10MB)
                            </p>
                        </div>
                    </div>
                </FileInput>
            </FileUploader>

            {certificates.length > 0 && (
                <TableV2
                    columns={columns}
                    data={certificates}
                    disableTooltips
                />
            )}
        </div>
    );
}
