import { ServiceConfigInterface } from 'src/domain/interfaces/config.context.interface';
import { DelegationApiClient } from './delegation.client';
import {
    CreateDelegationParams,
    DelegationModel,
    UpdateDelegationParams,
} from './delegation.model';

export class DelegationService {
    private server: DelegationApiClient;

    constructor(config: ServiceConfigInterface, token: string) {
        this.server = new DelegationApiClient(config, token);
    }

    async create(
        workspace: string,
        data: CreateDelegationParams
    ): Promise<DelegationModel> {
        console.log(`service<delegation>| create(): Enter`);
        console.log(`service<delegation>| create(): $workspace = ${workspace}`);
        console.log(
            `service<delegation>| create(): $data = ${JSON.stringify(data)}`
        );

        return await this.server.create(workspace, data);
    }

    async list(
        workspace: string
    ): Promise<DelegationModel | DelegationModel[]> {
        console.log(`service<delegation>| list(): Enter`);
        console.log(`service<delegation>| list(): $workspace = ${workspace}`);

        return await this.server.list(workspace);
    }

    async remove(workspace: string, id: number): Promise<void> {
        console.log(`service<delegation>| remove(): Enter`);
        console.log(`service<delegation>| remove(): $workspace = ${workspace}`);
        console.log(`service<delegation>| remove(): $id = ${id}`);

        await this.server.remove(workspace, id);
    }

    async update(
        workspace: string,
        id: number,
        data: UpdateDelegationParams
    ): Promise<DelegationModel> {
        console.log(`service<delegation>| update(): Enter`);
        console.log(`service<delegation>| update(): $workspace = ${workspace}`);
        console.log(`service<delegation>| update(): $id = ${id}`);
        console.log(
            `service<delegation>| update(): $data = ${JSON.stringify(data)}`
        );

        return await this.server.update(workspace, id, data);
    }
}
