import { zodResolver } from '@hookform/resolvers/zod';
import { useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';
import { useGetDelegations } from 'src/app/_api/workspace/delegations/hooks/useGetDelegations';
import { Button } from 'src/components/ui/button';
import {
    Dialog,
    DialogContent,
    DialogFooter,
    DialogHeader,
} from 'src/components/ui/dialog';
import { Form } from 'src/components/ui/form';
import useAppContext from 'src/infrastructure/hooks/use-context.hook';
import { z } from 'zod';
import { SelectInput } from '../../Form/SelectInput';

interface Props {
    open: boolean;
    setOpen: (open: boolean) => void;
}

const formSchema = z.object({
    delegateId: z.string(),
});

export const DelegationFilter = ({ open, setOpen }: Props) => {
    const context = useAppContext();
    const [searchParams, setSearchParams] = useSearchParams();

    const { delegations } = useGetDelegations();
    const delegateId = useMemo(
        () => searchParams.get('delegateId') || '',
        [searchParams]
    );

    const form = useForm<z.infer<typeof formSchema>>({
        mode: 'onChange',
        resolver: zodResolver(formSchema),
        defaultValues: {
            delegateId: context.workspace?.id || '',
        },
    });

    const handleSubmit = async (data: z.infer<typeof formSchema>) => {
        setSearchParams((params) => {
            if (data.delegateId === context.workspace?.id) {
                params.delete('delegateId');
            } else {
                params.set('delegateId', data.delegateId);
            }

            return params;
        });

        reset();
    };

    const reset = () => {
        // Resets to the default workspace if no delegation has been selected previously.
        form.reset();
        setOpen(false);
    };

    useEffect(() => {
        form.setValue('delegateId', delegateId || context.workspace?.id || '');
    }, [delegateId, context.workspace?.id, form]);

    return (
        <Dialog
            open={open}
            onOpenChange={(value) => {
                if (!value) {
                    reset();
                }
            }}
        >
            <DialogContent>
                <DialogHeader>
                    <span className='text-md font-bold'>Select Delegation</span>
                </DialogHeader>

                <Form {...form}>
                    <form
                        id='my-form'
                        onSubmit={form.handleSubmit(handleSubmit)}
                    >
                        <SelectInput
                            label='Delegation'
                            name='delegateId'
                            options={[
                                {
                                    label:
                                        context.workspace?.company.name || '',
                                    value: context.workspace?.id || '',
                                },
                                ...delegations.map((x) => ({
                                    label: x.workspace?.company.name || '',
                                    value: x.workspace?.id || '',
                                })),
                            ]}
                        />
                    </form>
                </Form>

                <DialogFooter>
                    <Button size='sm' variant='outline' onClick={reset}>
                        Cancel
                    </Button>

                    <Button
                        size='sm'
                        variant='default'
                        type='submit'
                        form='my-form'
                    >
                        Confirm
                    </Button>
                </DialogFooter>
            </DialogContent>
        </Dialog>
    );
};
