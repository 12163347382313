export const BASE_PURCHASE_QUERY_KEY = ['purchase'];
export const BASE_PURCHASES_QUERY_KEY = ['purchases'];
export const BASE_PURCHASE_TRACE_QUERY_KEY = ['purchase-trace'];

export const PURCHASE_QUERY_KEY = (workspace: string, id: string) => [
    ...BASE_PURCHASE_QUERY_KEY,
    workspace,
    id,
];

export const PURCHASE_VERSION_QUERY_KEY = (
    workspace: string,
    id: string,
    version: string
) => [...BASE_PURCHASE_QUERY_KEY, workspace, id, version];

export const PURCHASES_QUERY_KEY = (workspace: string) => [
    ...BASE_PURCHASES_QUERY_KEY,
    workspace,
];

export const PURCHASE_TRACE_QUERY_KEY = (workspace: string, id: string) => [
    ...BASE_PURCHASE_TRACE_QUERY_KEY,
    workspace,
    id,
];

export const PURCHASE_COTTON_WEIGHT_QUERY_KEY = (
    workspace: string,
    id: string
) => [...PURCHASE_TRACE_QUERY_KEY(workspace, id), 'cotton-weight'];

export const INFINITE_PURCHASES_QUERY_KEY = (workspace: string) => [
    ...BASE_PURCHASES_QUERY_KEY,
    workspace,
    'infinite',
];
