import { ServiceConfigInterface } from 'src/domain/interfaces/config.context.interface';
import { UserApiClient } from './user.client';
import { UserModel } from './user.model';
import { WorkspaceModel } from '../workspace/workspace.model';
import { InvitationCreateRequestObject } from './invitation-create-request-object';

export class UserService {
    private server: UserApiClient;

    constructor(
        config: ServiceConfigInterface,
        private readonly token: string
    ) {
        this.server = new UserApiClient(config, token);
    }

    async getProfile(): Promise<UserModel> {
        console.log(`service<user>| getProfile(): Enter`);

        return this.server.getProfile();
    }

    async getWorkspaces(): Promise<WorkspaceModel[]> {
        console.log(`service<user>| getWorkspaces(): Enter`);

        return this.server.getWorkspaces();
    }

    async inviteUser(workspace: string, body: InvitationCreateRequestObject) {
        console.log(`service<user>| inviteUser(): Enter`);
        console.log(`service<user>| inviteUser(): $workspace = ${workspace}`);
        console.log(
            `service<user>| inviteUser(): $body = ${JSON.stringify(body)}`
        );

        return this.server.inviteUser(workspace, body);
    }
}
