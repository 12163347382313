import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useDocuments } from 'src/app/_api_adb2c/workspace/documents/hooks/use-documents';
import { useRoles } from 'src/app/_api_adb2c/workspace/roles/hooks/use-roles';
import { useWorkspaceMembers } from 'src/app/_api_adb2c/workspace/workspace/hooks/use-workspace-members';
import { WorkspaceDetails } from 'src/app/pages-v2/Settings/workspace-details';
import { WidgetCard, WidgetSize } from 'src/app/pages/Dashboard/widget';
import {
    Tabs,
    TabsContent,
    TabsList,
    TabsTrigger,
} from 'src/components/ui/tabs';
import CertImage from '../../../assets/certificate.png';
import DelegationsIcon from '../../../assets/delegations.png';
import DocImage from '../../../assets/document.png';
import EnterpriseImage from '../../../assets/enterprise.png';
import RolesIcon from '../../../assets/roles.png';
import RulesetIcon from '../../../assets/ruleset.png';
import TemplatesIcon from '../../../assets/templates.png';
import UsersIcon from '../../../assets/users.png';
// import WorkspaceIcon from '../../../assets/workspaces.png';
import { WorkspaceCertifications } from './Certificate/workspace-certifications';
import { WorkspaceDelegations } from './Delegations/workspace-delegations';
import { WorkspaceDocuments } from './Documents/workspace-documents';
import { WorkspaceRoles } from './Roles/workspace-roles';
import { WorkspaceRulesets } from './Rulesets/workspace-rulesets';
import { WorkspaceTemplates } from './Templates/workspace-templates';
import { WorkspaceUsers } from './Members/workspace-users';

export enum MenuOption {
    DETAILS = 'details',
    DOCS = 'docs',
    CERTS = 'certs',
    TEMPLATES = 'templates',
    USERS = 'users',
    ROLES = 'roles',
    DELEGATIONS = 'delegations',
    RULESETS = 'rulesets',
    WORKSPACES = 'workspaces',
}

const menuOptions = [
    {
        title: MenuOption.DETAILS,
        icon: EnterpriseImage,
        alt: 'details-icon',
    },
    {
        title: MenuOption.DOCS,
        icon: DocImage,
        alt: 'docs-icon',
    },
    {
        title: MenuOption.CERTS,
        icon: CertImage,
        alt: 'certs-icon',
    },
    {
        title: MenuOption.TEMPLATES,
        icon: TemplatesIcon,
        alt: 'templated-icon',
    },
    {
        title: MenuOption.USERS,
        icon: UsersIcon,
        alt: 'users-icon',
    },
    {
        title: MenuOption.ROLES,
        icon: RolesIcon,
        alt: 'roles-icon',
    },
    {
        title: MenuOption.DELEGATIONS,
        icon: DelegationsIcon,
        alt: 'delegations-icon',
    },
    {
        title: MenuOption.RULESETS,
        icon: RulesetIcon,
        alt: 'rulesets-icon',
    },
    // {
    //     title: MenuOption.WORKSPACES,
    //     icon: WorkspaceIcon,
    //     alt: 'workspaces-icon',
    // },
];

export const Settings = () => {
    const [searchParams, setSearchParams] = useSearchParams({});

    const { data: users } = useWorkspaceMembers();
    const { data: roles } = useRoles();
    const { data: documents } = useDocuments();

    const handleSelect = (menu: MenuOption) => {
        setSearchParams({ menu });
    };

    const getRenderedContent = (menu: MenuOption): React.ReactNode => {
        const contentMap: Record<MenuOption, React.ReactNode> = {
            [MenuOption.DETAILS]: <WorkspaceDetails />,
            [MenuOption.DOCS]: <WorkspaceDocuments />,
            [MenuOption.CERTS]: <WorkspaceCertifications />,
            [MenuOption.TEMPLATES]: <WorkspaceTemplates />,
            [MenuOption.USERS]: <WorkspaceUsers />,
            [MenuOption.ROLES]: <WorkspaceRoles />,
            [MenuOption.DELEGATIONS]: <WorkspaceDelegations />,
            [MenuOption.RULESETS]: <WorkspaceRulesets />,
            [MenuOption.WORKSPACES]: <>Workspace Controls</>,
        };

        return contentMap[menu];
    };

    useEffect(() => {
        if (!searchParams.get('menu')) {
            setSearchParams({ menu: MenuOption.DETAILS });
        }
    }, [setSearchParams, searchParams]);

    return (
        <div className='flex h-full flex-col gap-4'>
            <span className='p-1 text-2xl font-bold'>Workspace</span>

            <WidgetCard
                enableAdd={false}
                size={WidgetSize.SMALL}
                widgets={[
                    {
                        name: 'Total Users',
                        count: users?.length || 0,
                    },
                    {
                        name: 'Total Roles',
                        count: roles?.length || 0,
                    },
                    {
                        name: 'Total Documents',
                        count: documents?.documents.length || 0,
                    },
                    // {
                    //     name: 'Total Certificates',
                    //     count: certificates?.length || 0,
                    // },
                ]}
            />

            <span className='text-md p-1 font-semibold'>My Company</span>

            <Tabs
                value={searchParams.get('menu') as MenuOption}
                className='flex h-full flex-col justify-start'
                onValueChange={(value) => handleSelect(value as MenuOption)}
            >
                <TabsList className='min-h-[60px] justify-start'>
                    {menuOptions.map((x, index) => {
                        return (
                            <TabsTrigger value={x.title} className='w-24'>
                                <figure className='flex cursor-pointer flex-col items-center rounded capitalize'>
                                    <img
                                        src={x.icon}
                                        alt={x.alt}
                                        className='min-h-8 min-w-8 max-w-8 max-h-8 rounded-l p-1'
                                        key={index}
                                        id={`${x.alt}-${index.toString()}`}
                                    />
                                    <figcaption className='text-sm text-gray-500 dark:text-gray-400'>
                                        {x.title}
                                    </figcaption>
                                </figure>
                            </TabsTrigger>
                        );
                    })}
                </TabsList>

                {menuOptions.map((x) => {
                    return (
                        <TabsContent
                            className='h-full flex-1 bg-white p-4'
                            key={`menu-${x.title}`}
                            value={x.title}
                        >
                            {getRenderedContent(x.title)}
                        </TabsContent>
                    );
                })}
            </Tabs>
        </div>
    );
};
