import { useMutation, useQueryClient } from '@tanstack/react-query';
import { toast } from 'sonner';
import { useContextStore } from 'src/app/stores/context-store';
import { PurchaseOrderCreateDataObject } from '../requests/purchase-order-create-data-object';
import { PURCHASES_QUERY_KEY } from './purchase-query-keys';
import { usePurchaseService } from './use-purchase-service';

export function useCreatePurchase() {
    const client = useQueryClient();
    const { service } = usePurchaseService();
    const context = useContextStore();

    return useMutation({
        mutationFn: async ({
            delegateId,
            body,
            diversion = '0',
        }: {
            delegateId?: string;
            body: PurchaseOrderCreateDataObject;
            diversion?: string;
        }) => {
            return await service.create(
                delegateId || context.workspace?._id || '',
                body,
                diversion
            );
        },
        onSuccess: (_, { delegateId }) => {
            client.invalidateQueries(
                PURCHASES_QUERY_KEY(delegateId || context.workspace?._id || '')
            );

            toast.success('Purchase created successfully');
        },
        onError: (err) => {
            const error = err as Error;
            toast.error(`Failed to create purchase: ${error.message}`);
        },
    });
}
