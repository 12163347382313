import { WorkspaceTemplateVirtualModel } from 'src/app/_api_adb2c/workspace/template/template.model';
import { Button } from 'src/components/ui/button';
import {
    Dialog,
    DialogContent,
    DialogFooter,
    DialogTitle,
} from 'src/components/ui/dialog';

interface Props {
    open: boolean;
    template: WorkspaceTemplateVirtualModel;
    onSubmit: (template: string) => void;
    onClose: () => void;
}

export const DeleteTemplate = ({
    template,
    open,
    onSubmit,
    onClose,
}: Props) => {
    const title = template
        ? template?.adaptation?.localisations?.[0].subject
        : '';

    const handleDelete = async (template: string) => {
        await onSubmit(template);
    };

    return (
        <Dialog open={open} onOpenChange={onClose}>
            <DialogContent>
                <DialogTitle>Template: {title}</DialogTitle>

                <span className='text-sm'>
                    Are you sure you want to remove this adaptation?
                </span>

                <DialogFooter>
                    <Button
                        size='sm'
                        variant='destructive'
                        onClick={() =>
                            handleDelete(template.adaptation?.template || '')
                        }
                    >
                        Remove
                    </Button>
                </DialogFooter>
            </DialogContent>
        </Dialog>
    );
};
