import { useState } from 'react';
import { FieldValues, UseFormReturn } from 'react-hook-form';
import { Button } from 'src/components/ui/button';
import {
    Dialog,
    DialogContent,
    DialogFooter,
    DialogHeader,
} from 'src/components/ui/dialog';
import { Form } from 'src/components/ui/form';
import { cn } from 'src/lib/utils';

interface Props<T extends FieldValues>
    extends Omit<React.HTMLAttributes<HTMLDivElement>, 'onSubmit'> {
    open: boolean;
    tabs: {
        label: string;
        content: React.ReactNode;
    }[];
    onClose: () => void;
    form: UseFormReturn<T>;
    onSubmit: (data: T) => void;
    label: string;
    extraControls?: React.ReactNode;
    isLoading?: boolean;
    formName?: string;
}

export function FormStepperDialog<T extends FieldValues>({
    open,
    tabs,
    form,
    onSubmit,
    onClose,
    label,
    extraControls,
    isLoading,
    formName,
    className,
}: Props<T>) {
    const [current, setCurrent] = useState(0);

    const reset = () => {
        onClose();
        setCurrent(0);

        form.reset();
    };

    return (
        <Dialog open={open} onOpenChange={reset} data-testid='stepper-dialog'>
            <DialogContent
                className={cn(
                    'grid h-3/5 max-h-[60%] min-w-[750px] grid-rows-[auto_1fr_auto]',
                    className
                )}
                data-testid='stepper-dialog-content'
            >
                <DialogHeader className='text-sm font-bold'>
                    {label}
                </DialogHeader>

                <div className='grid grid-cols-8 gap-4 overflow-auto'>
                    <div className='col-span-2 grid grid-cols-1'>
                        <div className='flex flex-col gap-4'>
                            {tabs.map((tab, index) => (
                                <div className='flex items-center gap-2'>
                                    <div className='flex h-6 w-6 gap-2 rounded-full bg-red-200'>
                                        <div
                                            className={cn(
                                                'flex h-6 w-6 items-center justify-center rounded-full text-xs',
                                                current === index
                                                    ? 'bg-red-800 text-white'
                                                    : 'bg-gray-200 text-gray-800'
                                            )}
                                        >
                                            {index + 1}
                                        </div>
                                    </div>

                                    <span className='truncate text-xs'>
                                        {tab.label}
                                    </span>
                                </div>
                            ))}
                        </div>
                    </div>

                    <div className='col-span-6 overflow-auto rounded-lg border-l px-4 py-2'>
                        <Form {...form}>
                            <form
                                // id='my-form'
                                id={formName || 'my-form'}
                                onSubmit={form.handleSubmit(
                                    (value) => {
                                        onSubmit(value);
                                        // reset();
                                    },
                                    (err) => console.error(err)
                                )}
                            >
                                {tabs.map((tab, index) => {
                                    return (
                                        <div
                                            key={index}
                                            className={cn(
                                                'flex flex-col gap-4',
                                                index === current
                                                    ? 'flex'
                                                    : 'hidden'
                                            )}
                                        >
                                            {tab.content}
                                        </div>
                                    );
                                })}
                                {/* {tabs[current]?.content} */}
                            </form>
                        </Form>
                    </div>
                </div>

                <DialogFooter>
                    {/* {extraControls} */}
                    <div
                        className={cn(
                            'flex w-full justify-between gap-2',
                            current === 0 && 'justify-end'
                        )}
                    >
                        {current > 0 && (
                            <div className='flex flex-[5] gap-2'>
                                <Button
                                    size='sm'
                                    variant='outline'
                                    onClick={() => setCurrent(current - 1)}
                                    data-testid='back-button'
                                >
                                    Back
                                </Button>
                            </div>
                        )}

                        <div className='flex w-full flex-[11] justify-between'>
                            <div>{extraControls}</div>

                            <div className='flex gap-2'>
                                <Button
                                    size='sm'
                                    variant='outline'
                                    onClick={() => {
                                        reset();
                                    }}
                                    data-testid='cancel-button'
                                >
                                    Cancel
                                </Button>

                                {current < tabs.length - 1 && (
                                    <Button
                                        size='sm'
                                        onClick={() => setCurrent(current + 1)}
                                    >
                                        Next
                                    </Button>
                                )}

                                <Button
                                    variant='default'
                                    size='sm'
                                    type='submit'
                                    form={formName || 'my-form'}
                                    className={`${
                                        current === tabs.length - 1
                                            ? 'block'
                                            : 'hidden'
                                    }`}
                                    data-testid='submit-button'
                                    loading={isLoading}
                                >
                                    Submit
                                </Button>
                            </div>
                        </div>
                    </div>
                </DialogFooter>
            </DialogContent>
        </Dialog>
    );
}
