import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';
import { ProductCategoriesModel } from 'src/app/_api_adb2c/product/category/category.model';
import { useUpdateCategory } from 'src/app/_api_adb2c/product/category/hooks/use-update-category';
import {
    ProductUom,
    ProductUomLabels,
} from 'src/app/_api_adb2c/purchase/purchase/enums/product-uom.enum';
import { DialogV2 } from 'src/app/components-v2/dialog-v2';
import { SelectInputV2 } from 'src/app/components/Form/SelectInputV2';
import { TextInputV2 } from 'src/app/components/Form/TextInputV2';
import { z } from 'zod';

interface Props {
    open: boolean;
    data: ProductCategoriesModel;
    onClose: () => void;
}

const formSchema = z.object({
    name: z.string(),
    code: z.string(),
    unit: z.string(),
    description: z.string().optional(),
});

export const EditCategory = ({ open, onClose, data }: Props) => {
    const [searchParams] = useSearchParams();
    const delegateId = searchParams.get('delegateId') || '';
    const { mutateAsync, isLoading } = useUpdateCategory();

    const form = useForm<z.infer<typeof formSchema>>({
        mode: 'onChange',
        resolver: zodResolver(formSchema),
        defaultValues: {
            name: data?.name || '',
            code: data?.code || '',
            unit: data?.defaultUnitOfMeasurement || '',
            description: data?.description || '',
        },
    });

    const onSubmit = async (body: z.infer<typeof formSchema>) => {
        await mutateAsync({
            id: data._id,
            delegateId: delegateId,
            body: {
                name: body.name,
                code: body.code,
                defaultUnitOfMeasurement: body.unit,
                description: body.description,
                industry: data.industry,
            },
        });

        reset();
    };

    const reset = () => {
        form.reset();
        onClose();
    };

    return (
        <DialogV2
            title='Edit Category'
            open={open}
            onClose={onClose}
            form={form}
            onSubmit={onSubmit}
            isStepDialog
            isLoading={isLoading}
            steps={[
                {
                    title: 'Category Information',
                    description:
                        'Enter the information for this category to be edited.',
                    content: (
                        <div>
                            <TextInputV2 label='Name' name='name' />

                            <TextInputV2 label='Code' name='code' />

                            <SelectInputV2
                                label='Unit'
                                name='unit'
                                options={Object.values(ProductUom).map(
                                    (unit) => ({
                                        label: ProductUomLabels[unit],
                                        value: unit,
                                    })
                                )}
                            />

                            <TextInputV2
                                label='Description'
                                name='description'
                            />
                        </div>
                    ),
                },
            ]}
        />
    );
};
