import { useQuery } from '@tanstack/react-query';
import { toast } from 'sonner';
import { useContextStore } from 'src/app/stores/context-store';
import { SalesOrderModel } from '../models/sales-order.model';
import { BATCH_SALES_ORDERS_QUERY_KEY } from './sales-order-query-keys';
import { useSalesOrderService } from './use-sales-order-service';

export function useSalesOrderBatch(
    targets: string[],
    delegateId?: string,
    enabled = true,
    diversion = '0'
) {
    const { service } = useSalesOrderService();
    const context = useContextStore();

    return useQuery<SalesOrderModel[]>({
        queryKey: BATCH_SALES_ORDERS_QUERY_KEY(
            delegateId || context.workspace?._id || '',
            targets
        ),
        queryFn: async () => {
            return await service.batch(
                delegateId || context.workspace?._id || '',
                { targets },
                diversion
            );
        },
        onError: (err) => {
            const error = err as Error;

            toast.error(`Failed to fetch sales orders: ${error.message}`);
        },
        enabled: !!targets && targets.length > 0 && enabled,
    });
}
