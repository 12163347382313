import { AttachmentCreateParams } from 'src/app/_api/files/attachment.model';
import { ProductCategoryModel } from 'src/app/_api/products/categories/category.model';
import { useCategoryService } from 'src/app/_api/products/categories/hooks/use-category-service';
import { useCreateProduct } from 'src/app/_api/products/products/hooks/use-create-product';
import { useProductService } from 'src/app/_api/products/products/hooks/use-product-service';
import { useCreatePurchase } from 'src/app/_api/purchase/purchase-order/hooks/use-create-purchase';
import { usePurchaseService } from 'src/app/_api/purchase/purchase-order/hooks/use-purchase-service';
import {
    OrderNatureType,
    PurchaseOrderTraceModel,
    PurchaseOrderVersionModel,
} from 'src/app/_api/purchase/purchase-order/purchase.model';
import { useSupplierService } from 'src/app/_api/purchase/suppliers/hooks/useSupplierService';
import { SupplierModel } from 'src/app/_api/purchase/suppliers/supplier.model';
import { useConnectSupplier } from 'src/app/_api/workspace/account/hooks/use-connect-supplier';
import { useInviteSupplier } from 'src/app/_api/workspace/account/hooks/use-invite-supplier';
import { useWorkspaceService } from 'src/app/_api/workspace/workspace/hooks/use-workspace-service';
import { WorkspaceModel } from 'src/app/_api/workspace/workspace/workspace.model';
import { UnitType } from 'src/domain/enums/unit-type.enum';
import useAppContext from 'src/infrastructure/hooks/use-context.hook';
import { read, utils } from 'xlsx';

const sheetName = 'PPMF Form Section B';

enum CustomOrderNature {
    PROCESSING_INHOUSE = 'Production - In-house',
    PROCESSING_OUTSOURCE = 'Production - Outsource',
    COMPONENT = 'Component',
    RAW_MATERIAL = 'Raw Material',
}

interface SheetData {
    'PO Factory Name'?: string;
    'PO Factory License Number'?: string;
    'PO Factory Country'?: string;
    'PO Factory Address'?: string;
    'PO Factory Contact Name'?: string;
    'PO Factory Contact Email'?: string;
    'Main Category'?: string;
    'PO Number'?: string;
    'Item Number'?: string;
    'Product Description'?: string;
    'In-House / Sub-Contract / Component / Raw Material': string;
    'Production Process': string;
    Subcategory: string;
    Component: string;
    'Raw Material': string;
    'Factory Name': string;
    'Factory License Number': string;
    'Factory Country': string;
    'Factory Address': string;
    'Factory Contact First Name'?: string;
    'Factory Contact Last Name'?: string;
    'Factory Contact Email'?: string;
    __rowNum__: number;
    [key: string]: string | number | undefined;
}

export const useImportActionTemplate = (
    purchaseOrder?: PurchaseOrderVersionModel
) => {
    const context = useAppContext();
    const { service: partnerService } = useSupplierService();
    const { service: workspaceService } = useWorkspaceService();
    const { service: productService } = useProductService();
    const { service: categoryService } = useCategoryService();
    const { service: purchaseService } = usePurchaseService();

    const { mutateAsync: invitePartner } = useInviteSupplier();
    const { mutateAsync: connectPartner } = useConnectSupplier();
    const { mutateAsync: createProduct } = useCreateProduct();
    const { mutateAsync: createPurchase } = useCreatePurchase();

    const delay = (duration: number) =>
        new Promise((resolve) => setTimeout(resolve, duration));

    const findPartner = async (workspaceId: string, name: string) => {
        const partners = await partnerService.list(workspaceId);

        const existingPartner = partners.find((partner) => {
            return (
                partner.seller?.companyName?.toLocaleLowerCase() ===
                name?.toLocaleLowerCase()
            );
        });

        return existingPartner
            ? {
                  ...existingPartner,
                  workspaceId: existingPartner.seller?.id || '',
                  supplierId: existingPartner.id || '',
              }
            : undefined;
    };

    const findOrCreatePartner = async (
        id: string,
        vendorName: string,
        mode: 'poFactory' | 'factory',
        data: SheetData
        // purchaseOrder: PurchaseOrderVersionModel
    ) => {
        let parentWorkspace;
        const isPartner = mode === 'poFactory';

        const workspaceId = isPartner
            ? purchaseOrder?.owner?.supplier?.seller?.id || ''
            : id;

        // Check if the partner already exists
        const existingPartner = await findPartner(workspaceId, vendorName);

        if (existingPartner) {
            return existingPartner;
        }

        if (mode === 'factory') {
            const existingVendors = await workspaceService.getByCompanyName(
                data['PO Factory Name'] || ''
            );

            parentWorkspace = existingVendors?.[0] || context.workspace;
        }

        const workspaces = await workspaceService.getByCompanyName(vendorName);

        if (workspaces.length > 0) {
            const matchingWorkspace = workspaces.find(
                (w) => w.displayName === vendorName
            );
            await connectPartner({
                partnerWorkspace: matchingWorkspace,
                supplierWorkspace:
                    mode === 'factory'
                        ? parentWorkspace
                        : (purchaseOrder?.owner?.supplier
                              ?.seller as unknown as WorkspaceModel),
                delegateWorkspace: context.workspace,
            });
        } else {
            const contact = [
                // Currently added just for testing purposed to be able to switch into the workspace.
                {
                    email: context.user?.email,
                    firstName: context.user?.firstName || '',
                    lastName: context.user?.lastName || '',
                },
            ];

            if (isPartner && data['PO Factory Contact Name']) {
                const [firstName, lastName] =
                    data['PO Factory Contact Name'].split(' ');

                contact.push({
                    firstName,
                    lastName,
                    email: data['PO Factory Contact Email'],
                });
            } else if (!isPartner && data['Factory Contact First Name']) {
                contact.push({
                    firstName: data['Factory Contact First Name'],
                    lastName: data['Factory Contact Last Name'] || '',
                    email: data['Factory Contact Email'],
                });
            }

            await invitePartner({
                solicitation: {
                    workspaceId: workspaceId,
                    company: isPartner
                        ? data['PO Factory Name']
                        : data['Factory Name'],
                    registrationNumber: isPartner
                        ? data['PO Factory License Number']
                        : data['Factory License Number'],
                    country: isPartner
                        ? data['PO Factory Country']
                        : data['Factory Country'],
                    contact: contact.length > 0 ? contact : undefined,
                    isNominated: false,
                    address: isPartner
                        ? data['PO Factory Address']
                        : data['Factory Address'],
                    delegate: [
                        {
                            delegate: isPartner
                                ? context.workspace?.id || ''
                                : workspaceId,
                        },
                    ],
                },
            });
        }
        await delay(2000);

        console.log(`test-ppmf: workspaceId = ${JSON.stringify(workspaceId)}`);
        console.log(`test-ppmf: vendorName = ${JSON.stringify(vendorName)}`);

        const partner = await findPartner(workspaceId, vendorName);
        console.log(`test-ppmf: partner = ${JSON.stringify(partner)}`);

        if (!partner) {
            throw new Error('Failed to create partner');
        }

        return partner;
    };

    const createPurchaseOrder = async (
        workspace: string,
        supplier: string,
        item: SheetData,
        product: { id: string; cost: number },
        parentPurchase?: PurchaseOrderVersionModel,
        type: 'poFactory' | 'factory' = 'factory',
        purchaseOrder?: PurchaseOrderVersionModel
    ) => {
        const getCustomNature = (orderNature: string) => {
            switch (orderNature) {
                case 'Component':
                    return OrderNatureType.COMPONENT;
                case 'Raw Material':
                    return OrderNatureType.RAW_MATERIAL;
                case 'Production - In-house':
                    return OrderNatureType.PROCESSING;
                case 'Production - Outsource':
                    return OrderNatureType.OUTSOURCE;
                default:
                    return OrderNatureType.COMPONENT;
            }
        };

        const purchase = await createPurchase({
            params: {
                supplier,
                currency: purchaseOrder?.owner?.currency || 'USD',
                items: [
                    {
                        material: product.id,
                        comment: 'Cloned',
                        quantity: 1,
                        ppu: product.cost,
                        unit: UnitType.PCS,
                    },
                ],
                // To be reviewed, not sure if need to create the ruleset for the purchase order
                rules: purchaseOrder?.owner?.rules?.[0].id
                    ? [purchaseOrder.owner?.rules?.[0].id]
                    : [],
                orderNature:
                    type === 'poFactory'
                        ? purchaseOrder?.owner?.orderNature
                        : getCustomNature(
                              item[
                                  'In-House / Sub-Contract / Component / Raw Material'
                              ]
                          ),
                workspaceId: workspace,
                parentOrderReference: parentPurchase?.owner?.id,
            },
        });

        const version = await purchaseService.get(workspace, purchase.id);

        return version;
    };

    const findOrCreateProduct = async (
        workspaceId: string,
        item: SheetData,
        categoryId: string
    ) => {
        let parsedName = '';
        const orderNature = item[
            'In-House / Sub-Contract / Component / Raw Material'
        ] as CustomOrderNature;

        const nameMapping = {
            [CustomOrderNature.COMPONENT]: 'Component',
            [CustomOrderNature.RAW_MATERIAL]: 'Raw Material',
            [CustomOrderNature.PROCESSING_OUTSOURCE]: 'Production Process',
            [CustomOrderNature.PROCESSING_INHOUSE]: 'Production Process',
        };

        if (
            orderNature === CustomOrderNature.PROCESSING_INHOUSE ||
            orderNature === CustomOrderNature.PROCESSING_OUTSOURCE
        ) {
            parsedName = `Service - ${item['Production Process']}`;
        } else {
            const mapping = nameMapping[orderNature];
            parsedName = (item[mapping] as string) || '';
        }

        let product = await productService.search(
            workspaceId,
            encodeURIComponent(parsedName.toLocaleUpperCase())
        );

        if (!product) {
            product = await createProduct({
                delegateId: workspaceId,
                product: {
                    category: categoryId,
                    externalDataId: parsedName.toLocaleUpperCase(),
                    name: parsedName.toLocaleUpperCase(),
                    description: '',
                    unit: UnitType.PCS,
                },
            });

            await delay(1500);
        }

        return product;
    };

    const findOrCreateCategory = async (
        id: string,
        item: SheetData,
        parentCode?: string,
        parentWorkspace?: string
    ) => {
        let parentCategory: ProductCategoryModel | undefined;
        let categoryName = item.Subcategory || 'Placeholder Category';

        if (parentCode && parentWorkspace) {
            const parentProduct = await productService.get(
                parentWorkspace,
                parentCode
            );

            if (parentProduct) {
                parentCategory = parentProduct.product?.category;
            }
        }

        let category = await categoryService.searchByCode(
            id,
            encodeURIComponent(parentCategory?.code || categoryName)
        );

        if (!category.id) {
            category = await categoryService.create(id, {
                code: parentCategory?.code || categoryName,
                unit: parentCategory?.unit || UnitType.PCS,
                name: parentCategory?.name || {
                    locales: [{ localeName: 'en', text: categoryName }],
                },
                description: parentCategory?.description || {
                    locales: [{ localeName: 'en', text: '' }],
                },
            });
        }

        return category;
    };

    const importItems = async (
        items: SheetData[]
        // purchaseOrder: PurchaseOrderVersionModel
    ) => {
        let parentPurchaseOrder = purchaseOrder;
        let poFactoryPartner: SupplierModel | undefined;
        let parentOrderTrace: PurchaseOrderTraceModel | undefined;
        let poFactoryParentPurchaseOrder: PurchaseOrderVersionModel | undefined;

        const trace = await purchaseService.trace(
            context.workspace?.id || '',
            purchaseOrder?.owner?.id || ''
        );

        if (trace.length > 0) {
            parentPurchaseOrder = await purchaseService.get(
                trace?.[0].workspaceId || '',
                trace?.[0].id || ''
            );
        }

        for (const item of items.filter((x) => x['PO Factory Name'])) {
            if (!poFactoryPartner) {
                // Creates the PO Factory
                poFactoryPartner = await findOrCreatePartner(
                    context.workspace?.id || '',
                    item['PO Factory Name'] || '',
                    'poFactory',
                    item
                );
            }

            if (!parentOrderTrace) {
                const traces = await purchaseService.trace(
                    context.workspace?.id || '',
                    purchaseOrder?.owner?.id || ''
                );

                const parentTrace = traces.find(
                    (x) =>
                        x.supplier?.owner?.companyName ===
                        parentPurchaseOrder?.owner?.supplier?.owner?.companyName
                );

                parentOrderTrace = parentTrace;

                if (parentTrace) {
                    poFactoryParentPurchaseOrder = await purchaseService.get(
                        parentPurchaseOrder?.owner?.supplier?.owner?.id || '',
                        parentTrace.id || ''
                    );
                }
            }

            if (!poFactoryParentPurchaseOrder) {
                // Creates the Parent PO for the PO Factory using the first manifest item of the PO
                debugger;
                const purchase = await createPurchaseOrder(
                    purchaseOrder?.owner?.supplier?.seller?.id || '',
                    poFactoryPartner.supplierId || '',
                    item,
                    {
                        id: purchaseOrder?.manifest?.[0].purchaseables.id || '',
                        cost: Number(purchaseOrder?.manifest?.[0].ppu || 0),
                    },
                    purchaseOrder,
                    'poFactory'
                );

                poFactoryParentPurchaseOrder = purchase;
            }
        }

        let purchase;
        console.log(`test-ppmf: total items = ${items.length}`);

        for (let index = 0; index < items.length; index++) {
            console.log(`test-ppmf: index = ${index}`);
            const item = items[index];
            const workspaceId = item['PO Factory Name']
                ? poFactoryPartner?.workspaceId || ''
                : context.workspace?.id || '';

            const factoryPartner = await findOrCreatePartner(
                workspaceId,
                item['Factory Name'] || '',
                'factory',
                item
            );
            delay(250);

            const category = await findOrCreateCategory(workspaceId, item);

            // Creates the product with category info into the Factory Workspace
            const product = await findOrCreateProduct(
                workspaceId,
                item,
                category.id || ''
            );
            await delay(750);

            const parentPurchase = item['PO Factory Name']
                ? poFactoryParentPurchaseOrder
                : parentPurchaseOrder;
            console.log(
                `test-ppmf: parent-puchase = ${JSON.stringify(
                    parentPurchase?.id
                )}`
            );

            purchase = await createPurchaseOrder(
                workspaceId,
                factoryPartner.supplierId,
                item,
                {
                    id: product.id || '',
                    cost: product.versions?.[0].cost || 0,
                },
                parentPurchase,
                'factory'
            );
            console.log(`test-ppmf: purchase = ${JSON.stringify(purchase.id)}`);
        }
    };

    const submit = (
        file: AttachmentCreateParams,
        purchaseOrder: PurchaseOrderVersionModel
    ) => {
        if (!file) return;

        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsArrayBuffer(file.file);
            reader.onload = async (e) => {
                try {
                    const buffer = e.target?.result as ArrayBuffer;
                    const wb = read(buffer, { type: 'array', cellDates: true });

                    const ws = wb.Sheets[sheetName];
                    const sheetData: SheetData[] = utils.sheet_to_json(ws);

                    const filteredSheetData = sheetData.filter(
                        (data) => data['Factory Name']
                    );

                    await importItems(filteredSheetData);

                    resolve(filteredSheetData);
                } catch (err) {
                    reject(err);
                }
            };
        });
    };

    return { importAction: submit };
};
