import { ServiceConfigInterface } from 'src/domain/interfaces/config.context.interface';
import { ApiServer } from 'src/infrastructure/clients/api.server';
import { ProductCategoryCreateDataObject } from './product-category-create-data-object';
import { ProductCategoryUpdateDataObject } from './product-category-update-data-object';

export class CategoryApiClient extends ApiServer {
    constructor(config: ServiceConfigInterface, token: string) {
        super(token, config, 'products', 'category-v2');
    }

    create(
        workspace: string,
        diversion: string,
        body: ProductCategoryCreateDataObject
    ) {
        console.log(`client<category>| create(): Enter`);
        console.log(`client<category>| create(): $workspace = ${workspace}`);
        console.log(`client<category>| create(): $diversion = ${diversion}`);
        console.log(
            `client<category>| create(): $body = ${JSON.stringify(body)}`
        );

        return super.post('create', workspace, diversion, body);
    }

    get(workspace: string, item: string) {
        console.log(`client<category>| get(): Enter`);
        console.log(`client<category>| get(): $workspace = ${workspace}`);
        return super.get('get', workspace, item);
    }

    remove(workspace: string, item: string) {
        console.log(`client<category>| remove(): Enter`);
        console.log(`client<category>| remove(): $workspace = ${workspace}`);
        console.log(`client<category>| remove(): $item = ${item}`);
        return super.delete('remove', workspace, item);
    }

    update(
        workspace: string,
        item: string,
        body: ProductCategoryUpdateDataObject
    ) {
        console.log(`client<category>| update(): Enter`);
        console.log(`client<category>| update(): $workspace = ${workspace}`);
        console.log(`client<category>| update(): $item = ${item}`);
        return super.patch('update', workspace, item, body);
    }

    loadCategories(workspace: string) {
        console.log(`client<category>| loadCategories(): Enter`);
        console.log(
            `client<category>| loadCategories(): $workspace = ${workspace}`
        );

        return super.get('loadCategories', workspace);
    }

    search(workspace: string, query: string, diversion: string = '0') {
        console.log(`client<category>| search(): Enter`);
        console.log(`client<category>| search(): $workspace = ${workspace}`);
        console.log(`client<category>| search(): $query = ${query}`);
        console.log(`client<category>| search(): $diversion = ${diversion}`);

        return super.get('search', workspace, query, diversion);
    }

    getCustomCategoryProducts(workspace: string, category: string) {
        console.log(`client<category>| getCustomCategoryProducts(): Enter`);
        console.log(
            `client<category>| getCustomCategoryProducts(): $workspace = ${workspace}`
        );
        console.log(
            `client<category>| getCustomCategoryProducts(): $category = ${category}`
        );

        return super.get('getCustomCategoryProducts', workspace, category);
    }

    loadBaseCategories(base: string) {
        console.log(`client<category>| loadBaseCategories(): Enter`);
        console.log(`client<category>| loadBaseCategories(): $base = ${base}`);

        return super.get('loadBaseCategories', base);
    }

    searchByCode(workspace: string, code: string) {
        console.log(`client<category>| searchByCode(): Enter`);
        console.log(
            `client<category>| searchByCode(): $workspace = ${workspace}`
        );
        console.log(`client<category>| searchByCode(): $code = ${code}`);

        return super.get('searchByCode', workspace, code);
    }
}
