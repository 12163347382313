import { Configuration, LogLevel } from '@azure/msal-browser';

export const msalConfig: Configuration = {
    auth: {
        clientId:
            process.env.REACT_APP_AZURE_ADB2C_CLIENT_ID ||
            '304badc2-0dcb-49b8-8ed8-f24b1d4a9c52', // This is the ONLY mandatory field that you need to supply.
        authority:
            process.env.REACT_APP_AZURE_ADB2C_AUTHORITY ||
            'https://lifungb2ctest.b2clogin.com/lifungb2ctest.onmicrosoft.com/b2c_1_testing2/',
        knownAuthorities: [
            process.env.REACT_APP_AZURE_ADB2C_KNOWN_AUTHORITIES ??
                'https://lifungb2ctest.b2clogin.com',
        ],
        redirectUri:
            process.env.REACT_APP_AZURE_ADB2C_REDIRECT_URI ||
            'http://localhost:3000',
        postLogoutRedirectUri: '/',
        navigateToLoginRequestUrl: true,
    },
    cache: {
        cacheLocation: 'localStorage', // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
        storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    },
    system: {
        loggerOptions: {
            loggerCallback: (level, message, containsPii) => {
                if (containsPii) {
                    return;
                }
                switch (level) {
                    case LogLevel.Error:
                        console.error(message);
                        return;
                    case LogLevel.Info:
                        console.info(message);
                        return;
                    case LogLevel.Verbose:
                        console.debug(message);
                        return;
                    case LogLevel.Warning:
                        console.warn(message);
                        return;
                    default:
                        return;
                }
            },
        },
    },
};
