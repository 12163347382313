import { DateTime } from 'luxon';
import { useMemo } from 'react';
import { Pie, PieChart } from 'recharts';
import { useProductReportByCategory } from 'src/app/_api_adb2c/product/statistics/hooks/use-product-report-by-category';
import {
    Card,
    CardContent,
    CardDescription,
    CardHeader,
    CardTitle,
} from 'src/components/ui/card';
import {
    ChartConfig,
    ChartContainer,
    ChartTooltip,
    ChartTooltipContent,
} from 'src/components/ui/chart';
import { renderCustomizedLabel } from '../Supplier/supplier-country';
import { EmptyChart } from '../empty-chart';
import { generateColors, observable12 } from '../Supplier';
import { getDateRangeDescription } from '../utils/get-date-range-description';

interface Props {
    startDate?: Date;
    endDate?: Date;
}

const chartConfig = {
    count: {
        label: 'Count',
        color: 'hsl(var(--chart-1))',
    },
} satisfies ChartConfig;

export function ProductAssortmentByCategory({ startDate, endDate }: Props) {
    const colors = generateColors(observable12);
    const { data: report } = useProductReportByCategory(
        startDate
            ? DateTime.fromJSDate(startDate).toFormat('yyyy-MM-dd')
            : DateTime.now().toFormat('yyyy-MM-dd'),
        endDate
            ? DateTime.fromJSDate(endDate).toFormat('yyyy-MM-dd')
            : DateTime.now().minus({ months: 2 }).toFormat('yyyy-MM-dd')
    );

    const dateRangeDescription = useMemo(
        () => getDateRangeDescription(startDate, endDate),
        [startDate, endDate]
    );

    const graphData = useMemo(() => {
        const categoryCounts: { label: string; count: number; fill: string }[] =
            [];

        report
            ?.filter((x) => x.category)
            .forEach((item, index) => {
                const existing = categoryCounts.find(
                    (x) => x.label === item.category
                );

                if (existing) {
                    existing.count += item.count;
                } else {
                    categoryCounts.push({
                        label: item.category || '',
                        count: item.count,
                        fill: colors[index % colors.length],
                    });
                }
            });

        return categoryCounts;
    }, [report, colors]);

    return (
        <Card className='px-4 py-2 shadow'>
            <CardHeader>
                <CardTitle>Product Assortment By Category</CardTitle>
                <CardDescription>{dateRangeDescription}</CardDescription>
            </CardHeader>

            <CardContent>
                {graphData.length > 0 ? (
                    <ChartContainer
                        config={chartConfig}
                        className='mx-auto aspect-square h-64 w-full'
                    >
                        <PieChart margin={{ top: 10, bottom: 10 }}>
                            <ChartTooltip
                                cursor={false}
                                content={<ChartTooltipContent hideLabel />}
                            />

                            <Pie
                                data={graphData}
                                dataKey='count'
                                nameKey='label'
                                innerRadius={60}
                                paddingAngle={2}
                                labelLine
                                label={renderCustomizedLabel}
                            />
                        </PieChart>
                    </ChartContainer>
                ) : (
                    <EmptyChart />
                )}
            </CardContent>
        </Card>
    );
}
