import { ServiceConfigInterface } from 'src/domain/interfaces/config.context.interface';
import { ApiServer } from 'src/infrastructure/clients/api.server';

export class ProductStatisticsClient extends ApiServer {
    constructor(config: ServiceConfigInterface, token: string) {
        super(token, config, 'products', 'product-statistics');
    }

    reportByCategory(workspace: string, start: string, end: string) {
        console.log(`client<product-statistics>| reportByCategory(): Enter`);
        console.log(
            `client<product-statistics>| reportByCategory(): $workspace = ${workspace}`
        );
        console.log(
            `client<product-statistics>| reportByCategory(): $start = ${start}`
        );
        console.log(
            `client<product-statistics>| reportByCategory(): $end = ${end}`
        );

        return super.get('reportByCategory', workspace, start, end);
    }
}
