import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useContextStore } from 'src/app/stores/context-store';
import { WORKSPACE_MEMBERS_QUERY_KEY } from '../../workspace/hooks/workspace-query-key';
import { InvitationCreateRequestObject } from '../invitation-create-request-object';
import { useUserService } from './use-user-service';
import { toast } from 'sonner';

export function useUserInvite() {
    const { service } = useUserService();
    const client = useQueryClient();
    const context = useContextStore();

    return useMutation({
        mutationFn: async ({
            delegateId,
            body,
        }: {
            body: InvitationCreateRequestObject;
            delegateId?: string;
        }) => {
            return await service.inviteUser(
                delegateId || context.workspace?._id || '',
                body
            );
        },
        onSuccess: (_, { delegateId }) => {
            client.invalidateQueries(
                WORKSPACE_MEMBERS_QUERY_KEY(
                    delegateId || context.workspace?._id || ''
                )
            );

            toast.success('User invited successfully');
        },
        onError: (error) => {
            const err = error as Error;

            toast.error(`Failed to invite user: ${err.message}`);
        },
    });
}
