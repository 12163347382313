import { useAccount } from '@azure/msal-react';
import { useMemo } from 'react';
import useAppConfig from 'src/infrastructure/hooks/use-config.hook';
import { VirtualPurchaseService } from '../virtual-purchase.service';

export function useVirtualPurchaseService() {
    const account = useAccount();
    const config = useAppConfig();

    const service = useMemo(() => {
        if (!account?.idToken) throw new Error('Session Expired');

        return new VirtualPurchaseService(config, account.idToken);
    }, [account, config]);

    return { service };
}
