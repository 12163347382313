import { ServiceConfigInterface } from 'src/domain/interfaces/config.context.interface';
import { ApiServer } from 'src/infrastructure/clients/api.server';

export class VirtualPurchaseApiClient extends ApiServer {
    constructor(config: ServiceConfigInterface, token: string) {
        super(token, config, 'purchases', 'virtual-purchase');
    }

    list(workspace: string, page: number, limit: number) {
        console.log(`client<purchase>| list(): Enter`);
        console.log(`client<purchase>| list(): $workspace = ${workspace}`);

        return super.get('list', workspace, page, limit);
    }

    getRoot(workspace: string, product: string) {
        console.log(`client<purchase>| getRoot(): Enter`);
        console.log(`client<purchase>| getRoot(): $workspace = ${workspace}`);
        console.log(`client<purchase>| getRoot(): $product = ${product}`);

        return super.get('getRoot', workspace, product);
    }

    create(workspace: string, body: any) {
        console.log(`client<purchase>| create(): Enter`);
        console.log(`client<purchase>| create(): $workspace = ${workspace}`);
        console.log(
            `client<purchase>| create(): $body = ${JSON.stringify(body)}`
        );

        return super.post('create', workspace, body);
    }
}
