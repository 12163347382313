import { CaretSortIcon } from '@radix-ui/react-icons';
import { ColumnDef } from '@tanstack/react-table';
import { Trash } from 'lucide-react';
import { DateTime } from 'luxon';
import { useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useSuppliers } from 'src/app/_api_adb2c/purchase/suppliers/hooks/use-suppliers';
import { WorkspaceDelegationModel } from 'src/app/_api_adb2c/workspace/delegations/delegation.model';
import { useDelegations } from 'src/app/_api_adb2c/workspace/delegations/hooks/use-delegations';
import { useDeleteDelegation } from 'src/app/_api_adb2c/workspace/delegations/hooks/use-delete-delegation';
import { Button } from 'src/components/ui/button';
import { Checkbox } from 'src/components/ui/checkbox';
import { ReusableTable } from '../../Purchases/reusable-table';

interface VirtualWorkspaceDelegation extends WorkspaceDelegationModel {
    name: string;
}

export const WorkspaceDelegations = () => {
    const [searchParams] = useSearchParams();
    const delegateId = searchParams.get('delegateId') || '';

    const { data } = useDelegations(delegateId);
    const { data: suppliers } = useSuppliers();
    const { mutateAsync: remove } = useDeleteDelegation();

    const memoizedData = useMemo(() => {
        if (!data || !suppliers) return [];

        console.log('suppliers', suppliers);
        console.log('data', data);
        return data.map((x) => {
            const supplier = suppliers.find(
                (y) => y.seller._id === x.delegatedTo._id
            );

            return {
                ...x,
                name: supplier?.seller.name || '',
            };
        });
    }, [data, suppliers]);

    console.log(memoizedData);

    const [rowSelection, setRowSelection] = useState({});

    const columns: ColumnDef<VirtualWorkspaceDelegation>[] = [
        {
            id: 'select',
            header: ({ table }) => (
                <Checkbox
                    checked={table.getIsAllPageRowsSelected()}
                    onCheckedChange={(value) =>
                        table.toggleAllPageRowsSelected(!!value)
                    }
                    aria-label='Select all'
                />
            ),
            cell: ({ row }) => (
                <Checkbox
                    checked={row.getIsSelected()}
                    onCheckedChange={(value) => row.toggleSelected(!!value)}
                    aria-label='Select row'
                />
            ),
            enableSorting: false,
            enableHiding: false,
            size: 0,
        },
        {
            id: 'workspace name',
            accessorKey: 'workspaceName',
            header: ({ column }) => {
                return (
                    <Button
                        variant='ghost'
                        className='text-xs'
                        onClick={() =>
                            column.toggleSorting(column.getIsSorted() === 'asc')
                        }
                    >
                        Workspace
                        <CaretSortIcon
                            className={`inline ${
                                column.getIsSorted() === 'asc'
                                    ? 'rotate-180 transform'
                                    : ''
                            }`}
                        />
                    </Button>
                );
            },
            accessorFn: (row) => {
                return row.name;
            },
            size: 500,
        },
        {
            id: 'expires on',
            accessorFn: (row) =>
                row.expiresOn
                    ? new Date(row.expiresOn).toLocaleDateString()
                    : '',
            header: ({ column }) => {
                return (
                    <Button
                        variant='ghost'
                        className='text-xs'
                        onClick={() =>
                            column.toggleSorting(column.getIsSorted() === 'asc')
                        }
                    >
                        Expires on
                        <CaretSortIcon
                            className={`inline ${
                                column.getIsSorted() === 'asc'
                                    ? 'rotate-180 transform'
                                    : ''
                            }`}
                        />
                    </Button>
                );
            },
            cell: ({ row }) => {
                const expiryOn = row.original.expiresOn
                    ? DateTime.fromJSDate(
                          new Date(row.original.expiresOn)
                      ).toFormat('yyyy-MM-dd')
                    : null;

                return expiryOn ? expiryOn : '--';
            },
        },
        {
            id: 'actions',
            header: ({ column }) => {
                return (
                    <Button
                        variant='ghost'
                        className='text-xs'
                        onClick={() =>
                            column.toggleSorting(column.getIsSorted() === 'asc')
                        }
                    >
                        Actions
                        <CaretSortIcon
                            className={`inline ${
                                column.getIsSorted() === 'asc'
                                    ? 'rotate-180 transform'
                                    : ''
                            }`}
                        />
                    </Button>
                );
            },
            cell: ({ row }) => {
                return (
                    <div className='flex items-center'>
                        <Button
                            variant='ghost'
                            className='text-xs'
                            onClick={() => {
                                remove({
                                    id: row.original.delegatedTo._id,
                                });
                            }}
                        >
                            <Trash size={16} />
                        </Button>
                    </div>
                );
            },
        },
    ];

    return (
        <div className='flex h-full flex-col'>
            <ReusableTable
                rowSelection={rowSelection}
                setRowSelection={setRowSelection}
                columns={columns}
                data={memoizedData || []}
                title='Delegations'
                controls={{
                    enableDelegation: true,
                    enableSearch: true,
                }}
            />
        </div>
    );
};
