import { useAccount } from '@azure/msal-react';
import { useMemo } from 'react';
import useAppConfig from 'src/infrastructure/hooks/use-config.hook';
import { SalesOrderService } from '../sales-order.service';

export function useSalesOrderService() {
    const account = useAccount();
    const config = useAppConfig();

    const service = useMemo(() => {
        if (account?.idToken) {
            return new SalesOrderService(config, account.idToken);
        } else {
            throw new Error('Session Expired.');
        }
    }, [account, config]);

    return { service };
}
