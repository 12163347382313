import { useMutation, useQueryClient } from '@tanstack/react-query';
import { toast } from 'sonner';
import { useContextStore } from 'src/app/stores/context-store';
import { RulesetCreateDataObject } from '../ruleset-create-data-object';
import { RULESETS_QUERY_KEY } from './ruleset-query-keys';
import { useRulesetService } from './use-ruleset-service';

export function useCreateRuleset() {
    const context = useContextStore();
    const { service } = useRulesetService();
    const client = useQueryClient();

    return useMutation({
        mutationFn: async ({
            delegateId,
            body,
        }: {
            delegateId?: string;
            body: RulesetCreateDataObject;
        }) => {
            return await service.create(
                delegateId || context.workspace?._id || '',
                body
            );
        },
        onSuccess: (_, { delegateId }) => {
            client.invalidateQueries(
                RULESETS_QUERY_KEY(delegateId || context.workspace?._id || '')
            );

            toast.success('Successfully created ruleset');
        },
        onError: (err) => {
            const error = err as Error;
            toast.error(`Failed to create ruleset: ${error.message}`);
        },
    });
}
