import { zodResolver } from '@hookform/resolvers/zod';
import { Plus, PlusIcon } from 'lucide-react';
import { useEffect, useMemo, useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { Steps } from 'src/app/components/Steps';
import { Button } from 'src/components/ui/button';
import {
    Dialog,
    DialogContent,
    DialogFooter,
    DialogHeader,
    DialogPortal,
    DialogTrigger,
} from 'src/components/ui/dialog';
import { Form } from 'src/components/ui/form';
import { z } from 'zod';
import { DelegationForm } from '../Components/Delegations/DelegationForm';
import { useCreateLink } from '../../_api/purchase/links/hooks/use-create-link';
import {
    CreatePurchaseParams,
    useCreatePurchase,
} from '../../_api/purchase/purchase-order/hooks/use-create-purchase';
import { OrderNatureType } from '../../_api/purchase/purchase-order/purchase.model';
import { useSalesOrders } from '../../_api/sales/sales-order/hooks/use-sales-orders';
import { CompanyProcessType } from '../../_api/shared/company-process.type';
import { useGetDelegations } from '../../_api/workspace/delegations/hooks/useGetDelegations';
import { AddPurchaseBasicInformation } from './add-purchase-basic-info';
import { AddPurchaseDocumentRequirements } from './add-purchase-document-requirements';
import { AddPurchaseProducts } from './add-purchase-items';

export const purchaseOrderItemDataObjectSchema = z.object({
    comment: z.string().optional(),
    material: z.string(),
    ppu: z.coerce.number(),
    quantity: z.coerce.number(),
    sequence: z.coerce.number().optional(),
});

export const formSchema = z.object({
    currency: z.string(),
    externalDataId: z.string().optional(),
    items: z.array(purchaseOrderItemDataObjectSchema).optional(),
    parentOrderReference: z.string().optional(),
    rules: z.string().optional(),
    shippedOn: z.array(z.date()).optional(),
    shipToAddress: z.string().optional(),
    purchaseProcesses: z
        .array(
            z.object({
                label: z.string(),
                value: z.nativeEnum(CompanyProcessType),
            })
        )
        .optional(),
    orderNature: z.nativeEnum(OrderNatureType).optional(),
    supplier: z.string(),
    supplierProducer: z.string().optional(),
    delegated: z.string().optional(),
});

type Props = {
    delegateId?: string;
    setDelegateId?: (id: string) => void;
    cascadeInfo?: FromCascade;
};

type FromCascade = {
    hideTrigger?: boolean;
    fromCascade?: boolean;
    poId?: string;
};

export const AddPurchase = ({
    delegateId,
    setDelegateId,
    cascadeInfo,
}: Props) => {
    const [open, setOpen] = useState(false);
    const [current, setCurrent] = useState(0);
    const [state, setState] = useState('');
    const { delegations } = useGetDelegations();

    const { mutateAsync } = useCreatePurchase();
    const { mutateAsync: link } = useCreateLink();

    const { data: orders } = useSalesOrders({ delegateId });

    const salesOrder = useMemo(() => {
        return orders?.find((x) => x.purchaseOrderId === cascadeInfo?.poId);
    }, [orders, cascadeInfo?.poId]);

    const steps = [
        'On Behalf Of.',
        'Basic Information',
        'Products',
        'Documents',
    ];

    const form = useForm<z.infer<typeof formSchema>>({
        mode: 'onChange',
        resolver: zodResolver(formSchema),
        defaultValues: {
            purchaseProcesses: [],
        },
    });

    const { fields, append, remove } = useFieldArray({
        control: form.control,
        name: 'items',
        // keyName: 'material',
    });

    const onSubmit = async (data: z.infer<typeof formSchema>) => {
        const request: CreatePurchaseParams['params'] = {
            currency: data.currency,
            supplier: data.supplier,
            externalDataId: data.externalDataId,
            rules: data.rules ? [data.rules] : [],
            items: data.items || [],
            supplierProducer: data.supplierProducer,
            purchaseProcesses: data.purchaseProcesses?.map((x) => x.value),
            orderNature: data.orderNature,
        };

        const response = await mutateAsync({ delegateId, params: request });

        if (cascadeInfo?.fromCascade) {
            await link({
                id: salesOrder?.id || '',
                orders: {
                    purchaseOrders: [response.id],
                },
                delegateId: delegateId,
            });
        }

        reset();
    };

    const reset = () => {
        form.reset();
        setDelegateId?.('');
        setOpen(false);
        setCurrent(0);
        setState('');

        fields.forEach((_, index) => {
            remove(index);
        });
    };

    const prev = () => {
        setCurrent(current - 1);
    };

    const next = () => {
        form.trigger().then((isValid) => {
            if (isValid) {
                setCurrent(current + 1);
            }
        });
        setCurrent(current + 1);
    };

    useEffect(() => {
        if (open) return;
        setState(
            delegations
                .find((x) => x.workspace?.id === delegateId)
                ?.id?.toString() || ''
        );
    }, [delegateId, delegations, open]);

    return (
        <Dialog
            onOpenChange={(value) => {
                if (!value) {
                    reset();
                }

                setOpen(value);
            }}
            open={open || !!delegateId}
        >
            <DialogTrigger asChild className='' onClick={() => setOpen(true)}>
                {!cascadeInfo?.hideTrigger && <PlusIcon className='h-4 w-4' />}
            </DialogTrigger>

            <DialogPortal>
                <DialogContent className='h-[600px] min-w-[800px] sm:max-w-[600px]'>
                    <DialogHeader>
                        <span className='font-bold'>Add Purchase Order</span>
                    </DialogHeader>

                    <div className='flex flex-1 justify-between gap-2 overflow-hidden'>
                        <div className='flex-[2] border-r border-r-gray-300'>
                            <Steps steps={steps} current={current} />
                        </div>

                        <div className='flex h-full flex-[4] flex-col justify-between gap-4 overflow-auto pl-2 pr-2'>
                            <Form {...form}>
                                <form
                                    id='my-form'
                                    onSubmit={form.handleSubmit(
                                        (onValid) => onSubmit(onValid),
                                        (onInvalid) => console.log(onInvalid)
                                    )}
                                    className='flex h-full flex-1 flex-col gap-2 overflow-auto'
                                >
                                    {current === 0 && (
                                        <DelegationForm
                                            state={state}
                                            setState={setState}
                                            fromCascade={
                                                cascadeInfo?.fromCascade
                                            }
                                        />
                                    )}

                                    {current === 1 && (
                                        <AddPurchaseBasicInformation
                                            customDelegateId={delegateId}
                                        />
                                    )}

                                    {current === 2 && (
                                        <AddPurchaseProducts
                                            fields={fields}
                                            remove={remove}
                                            customDelegateId={delegateId}
                                        />
                                    )}

                                    {current === 3 && (
                                        <AddPurchaseDocumentRequirements
                                            customDelegateId={delegateId}
                                        />
                                    )}
                                </form>
                            </Form>

                            <DialogFooter>
                                <div
                                    className={`flex w-full ${
                                        current !== 2
                                            ? 'justify-end'
                                            : 'justify-between'
                                    }`}
                                >
                                    <Button
                                        onClick={() => {
                                            append({
                                                material: '',
                                                ppu: 0,
                                                quantity: 0,
                                            });
                                        }}
                                        variant={'outline'}
                                        className={`${
                                            current !== 2 && 'hidden'
                                        }`}
                                    >
                                        <Plus className='h-4 w-4' />
                                    </Button>

                                    <div className='flex gap-4'>
                                        {current > 0 && (
                                            <Button
                                                variant='outline'
                                                onClick={() => {
                                                    prev();
                                                }}
                                            >
                                                Previous
                                            </Button>
                                        )}
                                        {current < steps.length - 1 && (
                                            <Button
                                                type='submit'
                                                onClick={() => {
                                                    next();
                                                }}
                                            >
                                                Next
                                            </Button>
                                        )}

                                        {current === steps.length - 1 && (
                                            <Button
                                                type='submit'
                                                form='my-form'
                                            >
                                                Confirm
                                            </Button>
                                        )}
                                    </div>
                                </div>
                            </DialogFooter>
                        </div>
                    </div>
                </DialogContent>
            </DialogPortal>
        </Dialog>
    );
};
