import { File } from 'lucide-react';
import { useState } from 'react';
import { toast } from 'sonner';
import { AttachmentCreateParams } from 'src/app/_api/files/attachment.model';
import { PurchaseModel } from 'src/app/_api_adb2c/purchase/purchase/models/purchase.model';
import { BaseDialog } from 'src/app/components-v2/base-dialog';
import { TypographySmall } from 'src/app/components-v2/typography';
import { useImportAction } from 'src/app/pages-v2/Purchases/DetailsV2/use-import-action';
import { useImportCascade } from 'src/app/pages-v2/Purchases/DetailsV2/use-import-cascade';
import { Dropzone } from 'src/app/pages/Fulfillments/dropzone';
import { useContextStore } from 'src/app/stores/context-store';

interface Props {
    open: boolean;
    order?: PurchaseModel;
    onClose: () => void;
}

export function ImportProductCascade({ open, order, onClose }: Props) {
    const [files, setFiles] = useState<AttachmentCreateParams[]>([]);
    const context = useContextStore();

    const { submit: importDefault, isLoading: isImportDefaultLoading } =
        useImportCascade();

    const { submit: importAction, isLoading: isImportLoading } =
        useImportAction();

    const importCascade = async () => {
        if (files.length < 1) return;

        if (!order) {
            toast.error(`Please create a purchase order first`);
            return;
        }

        const supplyChainLoaderType =
            context.workspace?.supplyChainLoaderType || 0;

        try {
            const file = files[0];

            if (supplyChainLoaderType === 0) {
                await importDefault(order.workspace, order._id, file.file);
            } else {
                await importAction(order.workspace, order._id, file.file);
            }
        } catch (err) {
            const error = err as Error;

            toast.error(`Failed to import purchase: ${error.message}`);
        }
    };

    return (
        <BaseDialog
            title='Import Purchase'
            open={open}
            onClose={onClose}
            onConfirm={importCascade}
            isLoading={isImportDefaultLoading || isImportLoading}
        >
            <Dropzone
                handleFileChange={(file) => {
                    setFiles([file]);
                }}
            />

            {files && files.length > 0 && (
                <TypographySmall className='flex gap-2'>
                    <File size={16} />
                    {files[0].filename}
                </TypographySmall>
            )}
        </BaseDialog>
    );
}
