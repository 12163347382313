export function EmptyChart() {
    return (
        <div className='flex flex-col items-center justify-center gap-6 p-8 md:p-12'>
            <div className='flex items-center justify-center rounded-full bg-muted p-6'>
                <BarChartIcon className='h-12 w-12 text-muted-foreground' />
            </div>
            <div className='space-y-2 text-center'>
                <h3 className='text-2xl font-semibold'>No Data to Display</h3>
                <p className='text-muted-foreground'>
                    It looks like there is no data available to generate the
                    chart. Please check your data source and try again.
                </p>
            </div>
        </div>
    );
}

interface BarChartIconProps extends React.SVGProps<SVGSVGElement> {}

function BarChartIcon(props: BarChartIconProps) {
    return (
        <svg
            {...props}
            xmlns='http://www.w3.org/2000/svg'
            width='24'
            height='24'
            viewBox='0 0 24 24'
            fill='none'
            stroke='currentColor'
            strokeWidth='2'
            strokeLinecap='round'
            strokeLinejoin='round'
        >
            <line x1='12' x2='12' y1='20' y2='10' />
            <line x1='18' x2='18' y1='20' y2='4' />
            <line x1='6' x2='6' y1='20' y2='16' />
        </svg>
    );
}
