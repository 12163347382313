import { ServiceConfigInterface } from 'src/domain/interfaces/config.context.interface';
import { FileApiClient } from './file.client';

export class FileService {
    private server: FileApiClient;

    constructor(config: ServiceConfigInterface, token: string) {
        this.server = new FileApiClient(config, token);
    }

    batchGet(workspace: string, targets: string[]) {
        console.log(`service<file>| batchGet(): Enter`);
        console.log(`service<file>| batchGet(): $targets = ${targets}`);

        return this.server.getBatch(workspace, targets);
    }
}
