import { useDeleteRole } from 'src/app/_api_adb2c/workspace/roles/hooks/use-delete-role';
import { WorkspaceRoleModel } from 'src/app/_api_adb2c/workspace/roles/roles.model';
import { Button } from 'src/components/ui/button';
import {
    Dialog,
    DialogContent,
    DialogFooter,
    DialogTitle,
} from 'src/components/ui/dialog';

interface Props {
    open: boolean;
    role: WorkspaceRoleModel;
    onClose: () => void;
}

export const DeleteRole = ({ open, role, onClose }: Props) => {
    const { mutateAsync } = useDeleteRole();

    const remove = async () => {
        await mutateAsync({ roleId: role._id });
        onClose();
    };

    return (
        <Dialog open={open} onOpenChange={onClose}>
            <DialogContent>
                <DialogTitle className='text-md'>Remove Role</DialogTitle>

                <span className='text-sm'>
                    Are you sure you want to remove this role?
                </span>

                <DialogFooter>
                    <Button size='sm' variant='destructive' onClick={remove}>
                        Remove
                    </Button>
                </DialogFooter>
            </DialogContent>
        </Dialog>
    );
};
