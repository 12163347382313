import { ColumnDef } from '@tanstack/react-table';
import { CloudUpload, Download, Paperclip, Trash2 } from 'lucide-react';
import { Dispatch, SetStateAction, useMemo } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import {
    SupportDocType,
    SupportDocTypeLabel,
} from 'src/app/_api_adb2c/shared/support-doc-type.enum';
import {
    FileInput,
    FileUploader,
    FileUploaderContent,
    FileUploaderItem,
} from 'src/app/components-v2/file-dropzone';
import { VirtualTable } from 'src/app/components-v2/virtual-table';
import { cn } from 'src/app/utils/cn';
import { getFileIdFromAssetName } from 'src/infrastructure/utils/extract-asset-id';
import { z } from 'zod';
import { requiredItemSchema, salesOrderDocModelSchema, schema } from '.';
import { useDownload } from '../../Materials/Details/Substance';
import { dropZoneConfig } from '../../Materials/Details/Substance/substance-sds-file-upload';

interface Props {
    removed: z.infer<typeof salesOrderDocModelSchema>[];
    setRemoved: Dispatch<
        SetStateAction<z.infer<typeof salesOrderDocModelSchema>[]>
    >;
    requiredDocuments: string[];
}

export function OrderRequiredDocumentsForm({
    removed,
    setRemoved,
    requiredDocuments,
}: Props) {
    const form = useFormContext<z.infer<typeof schema>>();
    const { download } = useDownload();

    const { fields, update } = useFieldArray({
        control: form.control,
        name: 'items',
    });

    const columns: ColumnDef<z.infer<typeof requiredItemSchema>>[] =
        useMemo(() => {
            const columns: ColumnDef<z.infer<typeof requiredItemSchema>>[] = [
                {
                    id: 'type',
                    header: 'Type',
                    size: 30,
                    accessorFn: (row) => {
                        return SupportDocTypeLabel[row.type as SupportDocType];
                    },
                },
                {
                    id: 'files',
                    header: 'Files',
                    size: 70,
                    cell: ({ row }) => {
                        return (
                            <FileUploader
                                value={row.original.file}
                                dropzoneOptions={dropZoneConfig}
                                onValueChange={(value) => {
                                    const current = row.original;
                                    const itemIndex = fields.findIndex(
                                        (x) => x._id === row.original._id
                                    );

                                    if (
                                        !value ||
                                        itemIndex === undefined ||
                                        itemIndex === -1
                                    )
                                        return;
                                    current.file = value;
                                    console.log('current', current.file);

                                    update(itemIndex, current);
                                }}
                            >
                                <FileInput>
                                    <div className='flex cursor-pointer items-center gap-1 px-2 text-blue-600 hover:underline hover:underline-offset-2'>
                                        <CloudUpload size={16} />
                                        <span>Upload More</span>
                                    </div>
                                </FileInput>

                                <FileUploaderContent className='overflow-auto'>
                                    {row.original.existingFiles
                                        ?.filter((x) => {
                                            const existing = removed.find(
                                                (y) =>
                                                    y.file.assetName ===
                                                    x.file.assetName
                                            );

                                            return !existing;
                                        })
                                        ?.map((doc, index) => (
                                            <div
                                                // className='mt-1 grid max-w-[80%] grid-cols-6 hover:bg-gray-100 hover:text-gray-900'
                                                className={cn(
                                                    'mt-1 grid grid-cols-6 hover:bg-gray-100 hover:text-gray-900'
                                                    // row.original.file.length > 0
                                                    //     ? 'max-w-[80%]'
                                                    //     : ''
                                                )}
                                                key={index}
                                            >
                                                <div className='col-span-5 flex h-6 items-center gap-1 p-1'>
                                                    <Paperclip className='h-4 w-4 flex-none stroke-current' />
                                                    <span className='truncate'>
                                                        {doc.file.originalName}
                                                    </span>
                                                </div>

                                                <div className='col-span-1 flex items-center gap-2 justify-self-end pr-1'>
                                                    <Download
                                                        className='h-4 w-4 hover:text-blue-600'
                                                        onClick={() => {
                                                            const fileId =
                                                                getFileIdFromAssetName(
                                                                    doc.file
                                                                        .assetName
                                                                );

                                                            download(
                                                                fileId,
                                                                doc.file
                                                                    .originalName
                                                            );
                                                        }}
                                                    />

                                                    <Trash2
                                                        className='h-4 w-4  hover:stroke-destructive'
                                                        onClick={() => {
                                                            setRemoved(
                                                                (prev) => {
                                                                    return [
                                                                        ...prev,
                                                                        doc,
                                                                    ];
                                                                }
                                                            );
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        ))}

                                    {row.original.file &&
                                        row.original.file.length > 0 &&
                                        row.original.file.map((file, i) => (
                                            <FileUploaderItem
                                                key={i}
                                                index={i}
                                                // className='max-w-[80%]'
                                            >
                                                <Paperclip className='h-4 w-4 stroke-current' />
                                                <span className='max-w-[80%] truncate text-xs font-normal'>
                                                    {file.name}
                                                </span>
                                            </FileUploaderItem>
                                        ))}
                                </FileUploaderContent>
                            </FileUploader>
                        );
                    },
                },
            ];

            return columns;
        }, [download, removed, setRemoved, update, fields]);

    return (
        <>
            <VirtualTable
                columns={columns}
                data={
                    fields.filter((x) =>
                        requiredDocuments.includes(x.type)
                    ) as z.infer<typeof requiredItemSchema>[]
                }
                className='table-fixed'
                headerClassName='bg-red-800 text-white'
            />
        </>
    );
}
