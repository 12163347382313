import { ColumnDef } from '@tanstack/react-table';
import { GanttChartSquare } from 'lucide-react';
import { DateTime } from 'luxon';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
    PurchaseOrderStatus,
    PurchaseOrderStatusLabels,
} from 'src/app/_api_adb2c/purchase/purchase/enums/purchase-order-status.enum';
import { PurchaseModel } from 'src/app/_api_adb2c/purchase/purchase/models/purchase.model';
import { usePurchaseStatisticsService } from 'src/app/_api_adb2c/purchase/statistics/hooks/use-purchase-statistics-service';
import { DetailLabel } from 'src/app/components-v2/detail-label';
import { TableV2 } from 'src/app/components-v2/table-v2';
import { useContextStore } from 'src/app/stores/context-store';
import { generateOrderTitleForList } from 'src/app/utils/generate-order-title';

interface SubstanceItem {
    purchase: PurchaseModel;
    traces: SubstanceItem[];
}

interface SubstanceInformationResponse {
    purchases: SubstanceItem[];
    substanceName: string;
    substanceCode: string;
}

export function SubstanceDetails() {
    const { name, cas } = useParams<{ name: string; cas: string }>();
    const navigate = useNavigate();
    const { workspace: w } = useContextStore();
    const { service } = usePurchaseStatisticsService();
    const [data, setData] = useState<SubstanceInformationResponse>();
    const [isLoading, setIsLoading] = useState(false);

    const getSubstanceInformation = useCallback(async () => {
        setIsLoading(true);
        const result = await service.getSubstanceOrderInformation(
            w?.id || '',
            name || '',
            cas || ''
        );

        setData({
            purchases: result.purchases,
            substanceName: result.substanceName,
            substanceCode: result.substanceCode,
        });

        setIsLoading(false);
    }, [service, w?.id, name, cas]);

    useEffect(() => {
        getSubstanceInformation();
    }, [getSubstanceInformation]);

    const calculateSubstanceWeight = useCallback(
        (item: SubstanceItem): number => {
            let totalWeight = 0;

            // Calculate weight for current purchase
            const latestPurchaseVersion =
                item.purchase.versions[item.purchase.versions.length - 1];
            if (latestPurchaseVersion) {
                latestPurchaseVersion.manifest.forEach((manifest) => {
                    const product = manifest.product;
                    if (!product) return;

                    const productVersion =
                        product.versions?.[product.versions.length - 1];
                    if (!productVersion?.billOfMaterials) return;

                    productVersion.billOfMaterials.forEach((bom) => {
                        bom.material?.compositions?.forEach((composition) => {
                            if (
                                composition.substanceCode === cas ||
                                composition.substanceName === cas
                            ) {
                                totalWeight +=
                                    composition.weight ||
                                    composition.actualWeight ||
                                    0;
                            }
                        });
                    });
                });
            }

            // Recursively calculate weights from traces
            if (item.traces && item.traces.length > 0) {
                item.traces.forEach((trace) => {
                    totalWeight += calculateSubstanceWeight(trace);
                });
            }

            return totalWeight;
        },
        [cas]
    );

    const handleRedirectToDetails = useCallback(
        (id: string) => {
            navigate(`/purchases/${id}`);
        },
        [navigate]
    );

    const getPurchaseOrderItemCategory = useCallback(
        (order: PurchaseModel) => {
            if (order.versions) {
                const version = order.versions.find(
                    (v) => (v.manifest || []).length > 0
                );
                if (version?.manifest) {
                    const manifest = version.manifest[0];
                    if (!manifest) {
                        return '--';
                    } else if (manifest.product) {
                        const purchaseable = manifest.product;
                        if (purchaseable.category) {
                            const categoryBase =
                                w?.features.supplyChainLoaderType === 0
                                    ? purchaseable.category.code
                                          ?.split('::_-')
                                          ?.pop()
                                    : purchaseable.category.code
                                          ?.split('::')
                                          ?.shift();
                            if (categoryBase) {
                                return categoryBase
                                    .split(' ')
                                    .map((i) =>
                                        i
                                            .split('_')
                                            .map(
                                                (j) =>
                                                    j
                                                        .slice(0, 1)
                                                        .toUpperCase() +
                                                    j.slice(1).toLowerCase()
                                            )
                                            .join(' ')
                                    )
                                    .join(' ');
                            } else {
                                return '--';
                            }
                        } else {
                            return '--';
                        }
                    } else {
                        return '--';
                    }
                }
            } else {
                return '--';
            }
        },
        [w?.features.supplyChainLoaderType]
    );

    const getOrderTotal = useCallback(
        (order: PurchaseModel) => {
            const version = order.versions[order.versions.length - 1];

            if (version) {
                const total = version.manifest.reduce((acc, item) => {
                    return acc + item.ppu * item.quantity;
                }, 0);

                if (w?.features?.supplyChainLoaderType === 0) {
                    return total.toFixed(2);
                } else {
                    const formatter = new Intl.NumberFormat('en-US', {
                        style: 'decimal',
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                    });
                    return formatter.format(total);
                }
            }
        },
        [w?.features.supplyChainLoaderType]
    );

    const newColumns: ColumnDef<SubstanceItem>[] = useMemo(() => {
        return [
            {
                id: 'purchase',
                header: 'Purchase',
                accessorFn: (row) => generateOrderTitleForList(row.purchase),
            },
            {
                id: 'case',
                header: 'Case',
                accessorFn: (row) =>
                    row.purchase.reference.find(
                        (r) => r.source === 'externalDataId'
                    )?.value,
            },
            {
                id: 'substanceWeight',
                header: 'Tonnage (Kg)',
                accessorFn: (row) => {
                    const weight = calculateSubstanceWeight(row);
                    return weight > 0 ? weight : '--';
                },
            },
            {
                id: 'supplier',
                header: 'Supplier',
                accessorFn: (row) => row.purchase.supplier.seller.name,
            },
            {
                id: 'primary Category',
                header: 'Primary Category',
                accessorFn: (row) => getPurchaseOrderItemCategory(row.purchase),
            },
            {
                id: 'item Number',
                header: 'Item Number',
                accessorFn: (row) =>
                    row.purchase.versions[row.purchase.versions.length - 1]
                        .manifest[0].product.name,
            },
            {
                id: 'currency',
                header: 'Currency',
                accessorFn: (row) => row.purchase.currency,
            },
            {
                id: 'buy Date',
                header: 'Buy Date',
                accessorFn: (row) =>
                    DateTime.fromJSDate(
                        new Date(row.purchase.createdOn)
                    ).toFormat('yyyy-MM-dd'),
            },
            {
                id: 'total',
                header: 'Total',
                accessorFn: (row) => getOrderTotal(row.purchase),
            },
            {
                id: 'quantity',
                header: 'Quantity',
                cell: ({ row }) => {
                    if (row.getIsGrouped()) {
                        return null;
                    }

                    return row.original.purchase.versions[
                        row.original.purchase.versions.length - 1
                    ].manifest[0].quantity;
                },
            },
            {
                id: 'status',
                header: 'Status',
                accessorFn: (row) =>
                    PurchaseOrderStatusLabels[
                        row.purchase.status as PurchaseOrderStatus
                    ],
            },
            {
                id: 'updated On',
                header: 'Updated On',
                accessorFn: (row) =>
                    DateTime.fromJSDate(
                        new Date(row.purchase.lastUpdatedOn)
                    ).toFormat('yyyy-MM-dd'),
            },
            {
                id: 'actions',
                header: 'Actions',
                disableSorting: true,
                enableColumnFilter: false,
                cell: ({ row }) => {
                    if (row.getIsGrouped()) {
                        return null;
                    }

                    let depth = row.depth;

                    if (depth > 0) {
                        return null;
                    }

                    return (
                        <GanttChartSquare
                            className='h-4 w-4 hover:scale-125'
                            onClick={() =>
                                handleRedirectToDetails(
                                    row.original.purchase._id || ''
                                )
                            }
                        />
                    );
                },
            },
        ];
    }, [
        calculateSubstanceWeight,
        getOrderTotal,
        getPurchaseOrderItemCategory,
        handleRedirectToDetails,
    ]);

    return (
        <div className='flex h-full flex-col gap-6'>
            <div className='grid grid-cols-12 gap-4'>
                <div className='col-span-12 grid grid-cols-12 gap-6 rounded-lg border border-gray-200 bg-white px-4 py-4'>
                    <div className='col-span-12 flex w-full items-center justify-between'>
                        <span className='text-xs font-bold underline decoration-red-800 decoration-2 underline-offset-8'>
                            General Information
                        </span>
                    </div>

                    <div className='col-span-8 grid grid-cols-3 gap-4'>
                        <DetailLabel title='Name'>{name}</DetailLabel>
                        <DetailLabel title='CAS'>{cas}</DetailLabel>
                    </div>
                </div>
            </div>

            <TableV2
                columns={newColumns}
                data={data?.purchases ?? []}
                isLoading={isLoading}
                label='Orders'
                subRowsField='traces'
                // groupBy='purchase.supplier.seller.name'
                tanstackGroupBy={'supplier'}
                defaultExpanded
                // defaultExpanded={false}
            />
        </div>
    );
}
