import { ServiceConfigInterface } from 'src/domain/interfaces/config.context.interface';
import { ApiServer } from 'src/infrastructure/clients/api.server';

export class PurchaseItemApiClient extends ApiServer {
    constructor(config: ServiceConfigInterface, token: string) {
        super(token, config, 'purchases', 'purchase-items');
    }

    list(workspace: string) {
        console.log(`client<purchase-item>| list(): Enter`);
        console.log(`client<purchase-item>| list(): $workspace = ${workspace}`);
        return super.get('list', workspace);
    }

    searchPurchaseables(workspace: string, query: string) {
        console.log(`client<purchase-item>| searchPurchaseables(): Enter`);
        console.log(
            `client<purchase-item>| searchPurchaseables(): $workspace = ${workspace}`
        );
        console.log(
            `client<purchase-item>| searchPurchaseables(): $query = ${query}`
        );
        return super.get('searchPurchaseables', workspace, query);
    }

    getPurchaseableCascadeMap(
        workspace: string,
        product: string,
        diversion: string = '0'
    ) {
        console.log(
            `client<purchase-item>| getPurchaseableCascadeMap(): Enter`
        );
        console.log(
            `client<purchase-item>| getPurchaseableCascadeMap(): $workspace = ${workspace}`
        );
        console.log(
            `client<purchase-item>| getPurchaseableCascadeMap(): $product = ${product}`
        );
        console.log(
            `client<purchase-item>| getPurchaseableCascadeMap(): $diversion = ${diversion}`
        );

        return super.get('cascade', workspace, product, diversion);
    }
}
