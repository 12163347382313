import { useFormContext } from 'react-hook-form';
import {
    FormControl,
    FormField,
    FormItem,
    FormLabel,
} from 'src/components/ui/form';
import { Textarea } from 'src/components/ui/textarea';
import { cn } from 'src/lib/utils';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
    label?: string;
    name: string;
    placeholder?: string;
    itemClassName?: string;
}

export function TextareaInput({
    label,
    name,
    placeholder,
    itemClassName,
    className,
}: Props) {
    const { control } = useFormContext();

    return (
        <FormField
            control={control}
            name={name}
            render={({ field }) => {
                return (
                    <FormItem
                        className={cn('flex flex-col gap-2', itemClassName)}
                    >
                        {label && (
                            <FormLabel className='text-xs font-bold'>
                                {label}
                            </FormLabel>
                        )}

                        <FormControl>
                            <Textarea
                                {...field}
                                placeholder={placeholder || '-'}
                                className={cn('text-xs', className)}
                                onChange={(e) => field.onChange(e.target.value)}
                            />
                        </FormControl>
                    </FormItem>
                );
            }}
        />
    );
}
