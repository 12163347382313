import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { useRoles } from 'src/app/_api_adb2c/workspace/roles/hooks/use-roles';
import { useUserInvite } from 'src/app/_api_adb2c/workspace/users/hooks/use-user-invite';
import { InvitationCreateRequestObject } from 'src/app/_api_adb2c/workspace/users/invitation-create-request-object';
import { FormStepperDialog } from 'src/app/components-v2/stepper-form';
import { DelegationInput } from 'src/app/components/Form/DelegationInput';
import { SelectInput } from 'src/app/components/Form/SelectInput';
import { TextInput } from 'src/app/components/Form/TextInput';
import { z } from 'zod';

interface Props {
    open: boolean;
    onClose: () => void;
}

const formSchema = z.object({
    target: z.object({
        contact: z.object({
            device: z.object({}),
            email: z.string(),
            firstName: z.string(),
            lastName: z.string().optional(),
        }),
    }),
    delegateId: z.string().optional(),
    role: z.string(),
}) satisfies z.ZodType<InvitationCreateRequestObject>;

export const CreateMember = ({ open, onClose }: Props) => {
    const { data: roles } = useRoles();
    const { mutateAsync, isLoading } = useUserInvite();

    const form = useForm<z.infer<typeof formSchema>>({
        mode: 'onChange',
        resolver: zodResolver(formSchema),
        defaultValues: {
            target: {
                contact: {
                    device: {},
                },
            },
        },
    });

    const onSubmit = async (data: z.infer<typeof formSchema>) => {
        await mutateAsync({
            body: data,
            delegateId: data.delegateId,
        });

        reset();
    };

    const reset = () => {
        form.reset();
        onClose();
    };

    return (
        <FormStepperDialog
            open={open}
            onClose={reset}
            label='Add User'
            isLoading={isLoading}
            tabs={[
                {
                    label: 'On Behalf Of.',
                    content: (
                        <DelegationInput
                            name='delegateId'
                            label='On Behalf Of.'
                        />
                    ),
                },
                {
                    label: 'User Information',
                    content: (
                        <>
                            <TextInput
                                label='Email'
                                inputName='target.contact.email'
                            />

                            <TextInput
                                label='First Name'
                                inputName='target.contact.firstName'
                            />

                            <TextInput
                                label='Last Name'
                                inputName='target.contact.lastName'
                            />

                            <SelectInput
                                label='Role'
                                name='role'
                                options={(roles || []).map((role) => ({
                                    label: role.name,
                                    value: role.code,
                                }))}
                            />
                        </>
                    ),
                },
            ]}
            form={form}
            onSubmit={onSubmit}
        />
    );
};
