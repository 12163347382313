import { ServiceConfigInterface } from 'src/domain/interfaces/config.context.interface';
import { ApiServer } from 'src/infrastructure/clients/api.server';
import { ProductCreateDataObject } from './requests/product-create-data-object';
import { ProductUpdateDataObject } from './requests/product-update-data-object';
import { ProductCloneDataObject } from './requests/product-clone-data-object';

export class ProductApiClient extends ApiServer {
    constructor(config: ServiceConfigInterface, token: string) {
        super(token, config, 'products', 'products-v2');
    }

    create(workspace: string, body: ProductCreateDataObject, diversion = '0') {
        console.log(`client<product>| create(): Enter`);
        console.log(`client<product>| create(): $workspace = ${workspace}`);
        console.log(
            `client<product>| create(): $body = ${JSON.stringify(body)}`
        );
        console.log(`client<product>| create(): $diversion = ${diversion}`);

        return super.post('create', workspace, diversion, body);
    }

    list(workspace: string) {
        console.log(`client<product>| list(): Enter`);
        console.log(`client<product>| list(): $workspace = ${workspace}`);
        return super.get('list', workspace);
    }

    get(workspace: string, item: string, diversion = '0') {
        console.log(`client<product>| get(): Enter`);
        console.log(`client<product>| get(): $workspace = ${workspace}`);
        console.log(`client<product>| get(): $item = ${item}`);
        console.log(`client<product>| get(): $diversion = ${diversion}`);
        return super.get('get', workspace, item, diversion);
    }

    getByVersion(workspace: string, item: string, version: string) {
        console.log(`client<product>| getByVersion(): Enter`);
        console.log(
            `client<product>| getByVersion(): $workspace = ${workspace}`
        );
        console.log(`client<product>| getByVersion(): $item = ${item}`);
        console.log(`client<product>| getByVersion(): $version = ${version}`);

        return super.get('getByVersion', workspace, item, version);
    }

    update(workspace: string, item: string, body: ProductUpdateDataObject) {
        console.log(`client<product>| update(): Enter`);
        console.log(`client<product>| update(): $workspace = ${workspace}`);
        console.log(`client<product>| update(): $item = ${item}`);
        return super.patch('update', workspace, item, body);
    }

    remove(workspace: string, item: string) {
        console.log(`client<product>| remove(): Enter`);
        console.log(`client<product>| remove(): $workspace = ${workspace}`);
        console.log(`client<product>| remove(): $item = ${item}`);
        return super.delete('remove', workspace, item);
    }

    compose(
        workspace: string,
        item: string,
        material: ProductUpdateDataObject
    ) {
        console.log(`client<product>| compose(): Enter`);
        console.log(`client<product>| compose(): $workspace = ${workspace}`);
        console.log(`client<product>| compose(): $item = ${item}`);
        return super.post('compose', workspace, item, material);
    }

    getLatestVersion(workspace: string, item: string) {
        console.log(`client<product>| getLatestVersion(): Enter`);
        console.log(
            `client<product>| getLatestVersion(): $workspace = ${workspace}`
        );
        console.log(`client<product>| getLatestVersion(): $item = ${item}`);

        return super.get('getLatestVersion', workspace, item);
    }

    search(workspace: string, name: string, diversion = '0') {
        console.log(`client<product>| search(): Enter`);
        console.log(`client<product>| search(): $workspace = ${workspace}`);
        console.log(`client<product>| search(): $name = ${name}`);
        console.log(`client<product>| search(): $diversion = ${diversion}`);
        return super.get('search', workspace, name, diversion);
    }

    clone(workspace: string, item: string, body: ProductCloneDataObject) {
        console.log(`client<product>| clone(): Enter`);
        console.log(`client<product>| clone(): $workspace = ${workspace}`);
        console.log(`client<product>| clone(): $item = ${item}`);
        console.log(
            `client<product>| clone(): $body = ${JSON.stringify(body)}`
        );

        return super.post('clone', workspace, item, body);
    }

    searchByName(workspace: string, name: string, diversion = '0') {
        console.log(`client<product>| searchByName(): Enter`);
        console.log(
            `client<product>| searchByName(): $workspace = ${workspace}`
        );
        console.log(`client<product>| searchByName(): $name = ${name}`);
        console.log(
            `client<product>| searchByName(): $diversion = ${diversion}`
        );

        return super.post('searchByName', workspace, diversion, { name });
    }
}
