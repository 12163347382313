import { ServiceConfigInterface } from 'src/domain/interfaces/config.context.interface';
import { SupplierUpdateDataObject } from './requests/supplier-update-data-object';
import { SupplierApiClient } from './supplier.client';

export class SupplierService {
    private server: SupplierApiClient;

    constructor(config: ServiceConfigInterface, token: string) {
        this.server = new SupplierApiClient(config, token);
    }

    get(workspace: string, item: string, diversion: string = '0') {
        console.log(`service<supplier>| get(): Enter`);
        console.log(`service<supplier>| get(): $workspace = ${workspace}`);
        console.log(`service<supplier>| get(): $item = ${item}`);
        console.log(`service<supplier>| get(): $diversion = ${diversion}`);
        return this.server.get(workspace, item, diversion);
    }

    list(workspace: string, diversion: string = '0') {
        console.log(`service<supplier>| list(): Enter`);
        console.log(`service<supplier>| list(): $workspace = ${workspace}`);
        console.log(`service<supplier>| list(): $diversion = ${diversion}`);

        return this.server.list(workspace, diversion);
    }

    remove(workspace: string, item: string, diversion: string = '0') {
        console.log(`service<supplier>| remove(): Enter`);
        console.log(`service<supplier>| remove(): $workspace = ${workspace}`);
        console.log(`service<supplier>| remove(): $item = ${item}`);
        console.log(`service<supplier>| remove(): $diversion = ${diversion}`);

        return this.server.remove(workspace, item, diversion);
    }

    update(
        workspace: string,
        item: string,
        supplier: SupplierUpdateDataObject,
        diversion: string = '0'
    ) {
        console.log(`service<supplier>| update(): Enter`);
        console.log(`service<supplier>| update(): $workspace = ${workspace}`);
        console.log(`service<supplier>| update(): $item = ${item}`);
        console.log(`service<supplier>| update(): $diversion = ${diversion}`);
        console.log(
            `service<supplier>| update(): $supplier = ${JSON.stringify(
                supplier
            )}`
        );

        return this.server.update(workspace, item, diversion, supplier);
    }
}
