import {
    PaginationState,
    Table as TanstackTable,
    flexRender,
} from '@tanstack/react-table';
import { Loader2 } from 'lucide-react';
import { useEffect, useRef, useState } from 'react';
import { Button } from 'src/components/ui/button';
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableHeader,
    TableRow,
} from 'src/components/ui/table';
import { cn } from 'src/lib/utils';

interface DataTableProps<TData> extends React.HTMLAttributes<HTMLDivElement> {
    /**
     * The table instance returned from useDataTable hook with pagination, sorting, filtering, etc.
     * @type TanstackTable<TData>
     */
    table: TanstackTable<TData>;

    /**
     * The floating bar to render at the bottom of the table on row selection.
     * @default null
     * @type React.ReactNode | null
     * @example floatingBar={<TasksTableFloatingBar table={table} />}
     */
    floatingBar?: React.ReactNode | null;

    label?: string;
    actions?: ButtonActions[];
    loading?: boolean;
    paginationConfig?: PaginationState;
    hideHeader?: boolean;
    customRowFilter?: (row: TData) => boolean;
}

interface ButtonActions extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    label: React.ReactElement;
    testId?: string;
}

export function DataTableV2<TData>({
    table,
    floatingBar,
    className,
    actions = [],
    label,
    children,
    loading = false,
    paginationConfig,
    hideHeader = false,
    customRowFilter,
    ...props
}: DataTableProps<TData>) {
    const parentRef = useRef<HTMLTableSectionElement>(null);
    const [childHeight, setChildHeight] = useState(0);

    const calculateChildHeight = () => {
        if (parentRef.current) {
            const parentHeight = parentRef.current.offsetHeight;
            setChildHeight(parentHeight * 0.1);
        }
    };

    useEffect(() => {
        calculateChildHeight();

        // Add event listener on mount
        window.addEventListener('resize', calculateChildHeight);

        return () => {
            window.removeEventListener('resize', calculateChildHeight);
        };
    }, []);

    return (
        <div className='flex h-full flex-1 flex-col overflow-auto'>
            <div className='mb-4'>{floatingBar}</div>

            {!hideHeader && (
                <div className='flex h-10 items-center justify-between bg-red-800 px-2 py-1 text-white'>
                    <span className='font-bold'>{label}</span>

                    <div>
                        {actions.map((action) => {
                            return (
                                <Button
                                    variant='ghost'
                                    size='sm'
                                    onClick={action.onClick}
                                    data-testid={action.testId}
                                >
                                    {action.label}
                                </Button>
                            );
                        })}
                    </div>
                </div>
            )}

            <Table
                parentClassName='flex-1 rounded-md h-full '
                className='h-full border bg-white'
            >
                <TableHeader className='text-xs'>
                    {table.getHeaderGroups().map((headerGroup) => (
                        <TableRow key={headerGroup.id}>
                            {headerGroup.headers.map((header) => {
                                return (
                                    <TableHead
                                        key={header.id}
                                        className={
                                            hideHeader
                                                ? 'bg-red-800 text-white'
                                                : ''
                                        }
                                    >
                                        {header.isPlaceholder
                                            ? null
                                            : flexRender(
                                                  header.column.columnDef
                                                      .header,
                                                  header.getContext()
                                              )}
                                    </TableHead>
                                );
                            })}
                        </TableRow>
                    ))}
                </TableHeader>

                <TableBody ref={parentRef} className='bg-white'>
                    {loading ? (
                        <>
                            <TableRow className='text-xs'>
                                <TableCell
                                    colSpan={table.getAllColumns().length}
                                    className='h-24 text-center'
                                >
                                    <span className='flex items-center justify-center gap-2'>
                                        <Loader2 className='inline animate-spin' />
                                        Loading...
                                    </span>
                                </TableCell>
                            </TableRow>
                        </>
                    ) : (
                        <>
                            {table.getRowModel().rows?.length ? (
                                <>
                                    {table
                                        .getRowModel()
                                        .rows.filter((row) => {
                                            if (customRowFilter) {
                                                return customRowFilter(
                                                    row.original
                                                );
                                            }

                                            return true;
                                        })
                                        .map((row) => (
                                            <TableRow
                                                key={row.id}
                                                className={`h-[${childHeight}px]`}
                                                data-state={
                                                    row.getIsSelected() &&
                                                    'selected'
                                                }
                                            >
                                                {row
                                                    .getVisibleCells()
                                                    .map((cell) => (
                                                        <TableCell
                                                            key={cell.id}
                                                            // className='truncate px-4 text-xs'
                                                            className={cn(
                                                                'truncate px-4 text-xs',
                                                                cell.column
                                                                    .columnDef
                                                                    .meta
                                                                    ?.columnsClassName
                                                            )}
                                                        >
                                                            {flexRender(
                                                                cell.column
                                                                    .columnDef
                                                                    .cell,
                                                                cell.getContext()
                                                            )}
                                                        </TableCell>
                                                    ))}
                                            </TableRow>
                                        ))}

                                    <TableRow className='h-full'></TableRow>
                                    {/* {table.getRowModel().rows.length <
                                        (paginationConfig?.pageSize || 10) && (
                                        <>
                                            <TableRow className='h-full'></TableRow>
                                        </>
                                    )} */}
                                </>
                            ) : (
                                <TableRow>
                                    <TableCell
                                        colSpan={table.getAllColumns().length}
                                        className='h-24 text-center'
                                    >
                                        No results.
                                    </TableCell>
                                </TableRow>
                            )}
                        </>
                    )}
                </TableBody>
            </Table>

            {paginationConfig && (
                <div className='flex items-center justify-end space-x-2 py-4'>
                    <Button
                        variant='outline'
                        size='sm'
                        onClick={() => table.previousPage()}
                        disabled={!table.getCanPreviousPage()}
                    >
                        Previous
                    </Button>

                    <Button
                        variant='outline'
                        size='sm'
                        onClick={() => {
                            table.nextPage();
                        }}
                        disabled={!table.getCanNextPage()}
                    >
                        Next
                    </Button>
                </div>
            )}
        </div>
    );
}
