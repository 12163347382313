import { useMutation } from '@tanstack/react-query';
import { toast } from 'sonner';
import { useContextStore } from 'src/app/stores/context-store';
import { FileGetDataObject } from '../file-get-data-object';
import { useAttachmentService } from './use-attachment-service';
import { FileResultObject } from '../file-result-object';

export function useAttachmentDownload() {
    const context = useContextStore();
    const { service } = useAttachmentService();

    return useMutation({
        mutationFn: async ({
            delegateId,
            body,
            diversion,
        }: {
            delegateId?: string;
            body: FileGetDataObject;
            diversion?: string;
        }): Promise<FileResultObject> => {
            return await service.download(
                delegateId || context.workspace?._id || '',
                body,
                diversion
            );
        },
        onSuccess: () => {
            // toast.success('Successfully downloaded file');
        },
        onError: (err) => {
            const error = err as Error;
            toast.error(`Failed to download file: ${error.message}`);
        },
    });
}
