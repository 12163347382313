import {
    ColumnDef,
    flexRender,
    getCoreRowModel,
    useReactTable,
} from '@tanstack/react-table';
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableHeader,
    TableRow,
} from 'src/components/ui/table';

interface TableProps<T> {
    data: T[];
    columns: ColumnDef<T>[];
}

export const DisplayTable = ({ data, columns }: TableProps<any>) => {
    const table = useReactTable({
        data,
        columns,
        getCoreRowModel: getCoreRowModel(),
    });

    return (
        <Table className='border'>
            <TableHeader>
                {table.getHeaderGroups().map((headerGroup) => (
                    <TableRow key={headerGroup.id} className='bg-red-600'>
                        {headerGroup.headers.map((header) => {
                            return (
                                <TableHead
                                    key={header.id}
                                    style={{
                                        width: header.getSize(),
                                    }}
                                    className='bg-red-700 text-xs font-bold text-white'
                                >
                                    {header.isPlaceholder
                                        ? null
                                        : flexRender(
                                              header.column.columnDef.header,
                                              header.getContext()
                                          )}
                                </TableHead>
                            );
                        })}
                    </TableRow>
                ))}
            </TableHeader>

            <TableBody className='bg-white'>
                {table.getRowModel().rows?.length ? (
                    table.getRowModel().rows.map((row) => (
                        <TableRow
                            className='h-[5%]'
                            key={row.id}
                            data-state={row.getIsSelected() && 'selected'}
                        >
                            {row.getVisibleCells().map((cell) => (
                                <TableCell key={cell.id} className='text-xs'>
                                    {flexRender(
                                        cell.column.columnDef.cell,
                                        cell.getContext()
                                    )}
                                </TableCell>
                            ))}
                        </TableRow>
                    ))
                ) : (
                    <TableRow className='h-full'>
                        <TableCell
                            colSpan={columns.length}
                            className='h-24 text-center text-xs'
                        >
                            No results.
                        </TableCell>
                    </TableRow>
                )}

                {table.getRowModel().rows?.length < 10 && (
                    <TableRow className='h-full'></TableRow>
                )}
            </TableBody>
        </Table>
    );
};
