import { ColumnDef } from '@tanstack/react-table';
import { CloudUpload, Download, Trash2 } from 'lucide-react';
import { Dispatch, SetStateAction, useMemo } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import {
    SupportDocType,
    SupportDocTypeLabel,
} from 'src/app/_api_adb2c/shared/support-doc-type.enum';
import { FileInput, FileUploader } from 'src/app/components-v2/file-dropzone';
import { Select } from 'src/app/components-v2/select';
import { VirtualTable } from 'src/app/components-v2/virtual-table';
import { cn } from 'src/app/utils/cn';
import { getFileIdFromAssetName } from 'src/infrastructure/utils/extract-asset-id';
import { v4 as uuidv4 } from 'uuid';
import { z } from 'zod';
import { optionalItemSchema, salesOrderDocModelSchema, schema } from '.';
import { useDownload } from '../../Materials/Details/Substance';
import { dropZoneConfig } from '../../Materials/Details/Substance/substance-sds-file-upload';

interface Props {
    removed: z.infer<typeof salesOrderDocModelSchema>[];
    setRemoved: Dispatch<
        SetStateAction<z.infer<typeof salesOrderDocModelSchema>[]>
    >;
    requiredDocuments: string[];
}

export function OrderOptionalDocumentsForm({
    removed,
    requiredDocuments,
    setRemoved,
}: Props) {
    const form = useFormContext<z.infer<typeof schema>>();
    const { download } = useDownload();

    const { fields, update, append, remove } = useFieldArray({
        control: form.control,
        name: 'items',
    });

    const columns: ColumnDef<z.infer<typeof optionalItemSchema>>[] =
        useMemo(() => {
            const columns: ColumnDef<z.infer<typeof optionalItemSchema>>[] = [
                {
                    id: 'name',
                    header: 'Name',
                    size: 80,
                    accessorFn: (row) => {
                        if (row.file) {
                            return row.file.name;
                        }

                        return row.existingFile?.originalName || '';
                    },
                },
                {
                    id: 'type',
                    header: 'Type',
                    size: 50,
                    cell: ({ row }) => {
                        const type = row.original.type;

                        return (
                            <Select
                                options={Object.values(SupportDocType)
                                    .filter(
                                        (x) => !requiredDocuments.includes(x)
                                    )
                                    .map((x) => ({
                                        label: SupportDocTypeLabel[x],
                                        value: x,
                                    }))}
                                value={type}
                                setValue={(value) => {
                                    const itemIndex = fields.findIndex(
                                        (x) => x._id === row.original._id
                                    );

                                    if (
                                        itemIndex === undefined ||
                                        itemIndex === -1
                                    ) {
                                        return;
                                    }

                                    update(itemIndex, {
                                        type: value,
                                        required: false,
                                        file: row.original.file,
                                        _id: row.original._id,
                                    });
                                }}
                            />
                        );
                    },
                },
                {
                    id: 'actions',
                    header: 'Actions',
                    size: 0,
                    cell: ({ row }) => {
                        return (
                            <div className='flex gap-4'>
                                <Download
                                    size={16}
                                    onClick={() => {
                                        if (row.original.existingFile) {
                                            const fileId =
                                                getFileIdFromAssetName(
                                                    row.original.existingFile
                                                        .assetName
                                                );

                                            download(
                                                fileId,
                                                row.original.existingFile
                                                    .originalName
                                            );
                                        }
                                    }}
                                    className={cn(
                                        'cursor-pointer hover:scale-125',
                                        row.original.existingFile
                                            ? 'block'
                                            : 'cursor-auto opacity-0'
                                    )}
                                    type='button'
                                />

                                <Trash2
                                    size={16}
                                    type='button'
                                    className='cursor-pointer hover:scale-125'
                                    onClick={() => {
                                        if (row.original.existingFile) {
                                            const file =
                                                row.original?.existingFile;

                                            setRemoved((prev) => {
                                                return [
                                                    ...prev,
                                                    {
                                                        file: file,
                                                        type: row.original.type,
                                                    },
                                                ];
                                            });
                                        } else {
                                            remove(row.index);
                                        }
                                    }}
                                />
                            </div>
                        );
                    },
                },
            ];

            return columns;
        }, [requiredDocuments, update, remove, setRemoved, download, fields]);

    return (
        <div className='flex h-full flex-col gap-4'>
            <FileUploader
                value={[]}
                onValueChange={(value) => {
                    if (!value) return;

                    append({
                        type: SupportDocType.AUDIT_REPORT,
                        required: false,
                        file: value[0],
                        _id: uuidv4(),
                    });
                }}
                dropzoneOptions={dropZoneConfig}
            >
                <FileInput className='group h-full min-h-fit border border-dashed border-gray-400 hover:bg-gray-200'>
                    <div className='flex h-full w-full flex-col items-center justify-center gap-4 pb-4 pt-3 text-center '>
                        <CloudUpload size={30} />
                        <span className='text-xs text-gray-500'>
                            Click to SELECT FILE or DRAG & DROP FILES here
                            <br />
                            Only files in{' '}
                            <span className='font-bold'>
                                PDF, TIFF or JPEG
                            </span>{' '}
                            format will be accepted
                            <br />
                            Max File Size: 10MB
                        </span>
                    </div>
                </FileInput>
            </FileUploader>

            <VirtualTable
                columns={columns}
                data={
                    fields
                        .filter((x) => {
                            if (x.required) return false;

                            const existing = removed.find(
                                (y) =>
                                    y.file.assetName ===
                                    x.existingFile?.assetName
                            );

                            return !existing;
                        })
                        .filter(
                            (x) => !requiredDocuments.includes(x.type)
                        ) as z.infer<typeof optionalItemSchema>[]
                }
                className='table-fixed'
                headerClassName='bg-red-800 text-white'
            />
        </div>
    );
}
