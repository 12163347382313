import { useSearchParams } from 'react-router-dom';
import { useDeleteRuleset } from 'src/app/_api_adb2c/workspace/ruleset/hooks/use-delete-ruleset';
import { WorkspaceRulesetModel } from 'src/app/_api_adb2c/workspace/ruleset/ruleset.model';
import { Button } from 'src/components/ui/button';
import {
    Dialog,
    DialogContent,
    DialogFooter,
    DialogTitle,
} from 'src/components/ui/dialog';

interface Props {
    open: boolean;
    onClose: () => void;
    ruleset: WorkspaceRulesetModel;
}

export const DeleteRuleset = ({ open, onClose, ruleset }: Props) => {
    const [searchParams] = useSearchParams();
    const delegateId = searchParams.get('delegateId') || '';

    const { mutateAsync } = useDeleteRuleset();

    const remove = async () => {
        await mutateAsync({ id: ruleset._id, delegateId: delegateId });
        onClose();
    };

    return (
        <Dialog open={open} onOpenChange={onClose}>
            <DialogContent>
                <DialogTitle className='text-md'>Remove Ruleset</DialogTitle>

                <span className='text-sm'>
                    Are you sure you want to remove this ruleset?
                </span>

                <DialogFooter>
                    <Button size='sm' variant='destructive' onClick={remove}>
                        Remove
                    </Button>
                </DialogFooter>
            </DialogContent>
        </Dialog>
    );
};
