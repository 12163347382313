import { useMutation, useQueryClient } from '@tanstack/react-query';
import { toast } from 'sonner';
import { useContextStore } from 'src/app/stores/context-store';
import { ROLES_QUERY_KEY } from './roles-query';
import { useRolesService } from './use-roles-service';

export function useDeleteRole() {
    const client = useQueryClient();
    const { service } = useRolesService();
    const { workspace } = useContextStore();

    return useMutation({
        mutationFn: async ({
            roleId,
            delegateId,
        }: {
            roleId: string;
            delegateId?: string;
        }) => {
            return await service.delete(
                delegateId || workspace?._id || '',
                roleId
            );
        },
        onSuccess: (_, { delegateId }) => {
            client.invalidateQueries(
                ROLES_QUERY_KEY(delegateId || workspace?._id || '')
            );

            toast.success('Successfully deleted role');
        },
        onError: (err) => {
            const error = err as Error;

            toast.error(`Failed to delete role: ${error.message}`);
        },
    });
}
