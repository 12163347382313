import { useQuery } from '@tanstack/react-query';
import { toast } from 'sonner';
import { useContextStore } from 'src/app/stores/context-store';
import { BATCH_FILE_QUERY_KEYS } from './file-query-keys';
import { useFileService } from './use-file-service';
import { FileModel } from '../file.model';

export function useBatchGetFiles(targets: string[]) {
    const { service } = useFileService();
    const context = useContextStore();

    return useQuery<FileModel[]>({
        queryKey: BATCH_FILE_QUERY_KEYS(targets),
        queryFn: async () => {
            return service.batchGet(context.workspace?._id || '', targets);
        },
        onError: (error) => {
            const err = error as Error;

            toast.error(`Failed to get files: ${err.message}`);
        },
    });
}
