export const PURCHASE_BASE_KEY = 'purchase';
export const PURCHASES_BASE_KEY = 'purchases';
export const PURCHASE_TRACE_BASE_KEY = 'purchase-trace';
export const PURCHASE_REVERSE_BASE_KEY = 'purchase-reverse';

export const PURCHASES_QUERY_KEY = (workspace: string) => [
    PURCHASES_BASE_KEY,
    workspace,
];

export const PURCHASE_QUERY_KEY = (workspace: string, id: string) => [
    PURCHASE_BASE_KEY,
    workspace,
    id,
];

export const PURCHASE_TRACE_QUERY_KEY = (workspace: string, id: string) => [
    PURCHASE_TRACE_BASE_KEY,
    workspace,
    id,
];

export const PURCHASE_REVERSE_QUERY_KEY = (workspace: string, id: string) => [
    PURCHASE_REVERSE_BASE_KEY,
    workspace,
    id,
];

export const PURCHASE_ITEMS_QUERY_KEY = (workspace: string) => [
    'purchase-items',
    workspace,
];
