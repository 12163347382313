import { useQuery } from '@tanstack/react-query';
import { useEffect } from 'react';
import { toast } from 'sonner';
import useAppContext from 'src/infrastructure/hooks/use-context.hook';
import { DelegationModel } from '../delegation.model';
import { useDelegationService } from './useDelegationService';
import { DELEGATIONS_QUERY_KEY } from './delegation-query-keys';

export function useGetDelegations(delegateId?: string, enabled = true) {
    const context = useAppContext();
    const service = useDelegationService();

    const { data, isError } = useQuery({
        queryKey: DELEGATIONS_QUERY_KEY(
            delegateId || context.workspace?.id || ''
        ),
        queryFn: async () => {
            return await service.list(
                delegateId || context.workspace?.id || ''
            );
        },
        enabled: enabled,
    });

    useEffect(() => {
        if (isError) {
            toast.error('Failed to get delegations');
        }
    }, [isError]);

    return {
        delegations: data ? (data as DelegationModel[]) : [],
        isError,
    };
}
