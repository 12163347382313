import { Badge } from 'src/components/ui/badge';
import { cn } from 'src/lib/utils';
import { getOrderNature } from '.';
import { DisplayLabel } from '../../Products/Detail';
import { PurchaseOrderVersionModel } from '../../../_api/purchase/purchase-order/purchase.model';

type Props = {
    data?: PurchaseOrderVersionModel;
    wrap?: boolean;
    statisticsCardClass?: string;
};

type StatisticsCardProps = {
    label: string;
    subtitle?: string;
    value?: string | number;
};

export const StatisticsCard: React.FC<StatisticsCardProps> = ({
    label,
    subtitle,
    value,
}) => {
    return (
        <div className='flex max-h-[70px] min-h-[70px] min-w-[150px] max-w-[150px] flex-col gap-4 rounded-lg border px-2 py-2 text-[10px] font-bold text-gray-500'>
            <span className='uppercase'>{label}</span>

            <span className='flex items-end justify-end gap-1'>
                <span className='truncate text-xs font-bold text-black'>
                    {value}
                </span>

                <span className='uppercase'>{subtitle}</span>
            </span>
        </div>
    );
};

export const PurchaseOverview: React.FC<Props> = ({
    data,
    wrap = true,
    statisticsCardClass = '',
}) => {
    const totalOrderQuantity = data?.manifest
        ?.reduce((acc, curr) => acc + curr.quantity, 0)
        .toString();

    const totalOrderAmount = data?.manifest
        ?.reduce((acc, curr) => acc + curr.quantity * curr.ppu, 0)
        .toString();

    return (
        <>
            <div id='details' className='flex gap-8'>
                <div className='flex max-w-[150px] flex-col flex-wrap gap-3 text-xs'>
                    <DisplayLabel
                        label='Supplier'
                        value={data?.owner?.supplier.seller?.name}
                    />

                    <DisplayLabel
                        label='Supplier (Secondary)'
                        value={data?.owner?.supplierProducer?.name}
                    />

                    <DisplayLabel
                        label='Nature'
                        value={
                            data?.owner?.orderNature
                                ? getOrderNature(data?.owner?.orderNature)
                                : '-'
                        }
                    />

                    <DisplayLabel
                        label='Currency'
                        value={data?.owner.currency}
                    />
                </div>

                <div className='flex max-w-[150px] flex-col flex-wrap gap-3 text-xs'>
                    <DisplayLabel
                        label='Issued On'
                        value={
                            data?.createdOn
                                ? new Date(data.createdOn)
                                      .toLocaleDateString()
                                      .slice(0, 10)
                                : '-'
                        }
                    />

                    <DisplayLabel
                        label='1st Delivery (Expected)'
                        // value={data?.owner?.supplier.seller?.name}
                    />

                    <DisplayLabel
                        label='Applicable Processes'
                        value={
                            <div className='flex max-w-[180px] flex-wrap gap-2'>
                                {data?.owner.purchaseProcesses?.map((x) => {
                                    return (
                                        <Badge
                                            variant='outline'
                                            className='min-w-[80px] max-w-[80px] justify-center truncate bg-gray-300 text-[8px]'
                                        >
                                            <span className='truncate'>
                                                {x}
                                            </span>
                                        </Badge>
                                    );
                                })}
                            </div>
                        }
                    />
                </div>

                <div className='flex max-w-[150px] flex-col flex-wrap gap-3 text-xs'>
                    <DisplayLabel
                        label='Ship To (Delivery) Address'
                        value={data?.owner?.supplier.seller?.name}
                        className='flex-[2]'
                    />

                    <DisplayLabel label='Shipment Dates' />
                </div>

                <div className='flex flex-col flex-wrap gap-3 text-xs'>
                    <DisplayLabel
                        label='Key Statistics'
                        value={
                            <div
                                className={cn(
                                    `flex gap-2 overflow-auto ${statisticsCardClass}`,
                                    wrap ? 'flex-nowrap' : `flex-nowrap`
                                )}
                            >
                                <StatisticsCard
                                    label='Order Total'
                                    subtitle='USD'
                                    value={totalOrderAmount || 0}
                                />

                                <StatisticsCard
                                    label='Order Quantities'
                                    subtitle='PCS'
                                    value={
                                        parseInt(totalOrderQuantity || '0') || 0
                                    }
                                />

                                <StatisticsCard
                                    label='Cascade Levels'
                                    subtitle='Tiers'
                                    value='7'
                                />
                            </div>
                        }
                    />

                    <DisplayLabel
                        label='Mandatory Doc. Readiness'
                        value={data?.owner?.supplier.seller?.name}
                    />
                </div>
            </div>
        </>
    );
};
