import { Paperclip, Upload } from 'lucide-react';
import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import {
    FileInput,
    FileUploader,
    FileUploaderContent,
    FileUploaderItem,
} from 'src/app/components-v2/file-dropzone';
import { z } from 'zod';
import { schema } from './AddSubstance/add-substance-v3';

export const dropZoneConfig = {
    maxFiles: 5,
    maxSize: 1024 * 1024 * 4,
    multiple: true,
};

interface Props {
    name: keyof z.infer<typeof schema>;
}

export function SdsDocumentUpload({ name }: Props) {
    const { setValue, watch } = useFormContext<z.infer<typeof schema>>();
    const [files, setFiles] = useState<File[] | null>(null);

    const existingFile = watch(name);

    useEffect(() => {
        if (existingFile && existingFile instanceof File) {
            setFiles([existingFile]);
        }
    }, [existingFile]);

    useEffect(() => {
        if (files) {
            setValue(name, files.length > 0 ? files[0] : undefined);
        }
    }, [files, setValue, name]);

    return (
        <FileUploader
            value={files}
            onValueChange={(value) => {
                console.log(value);
                setFiles(value);
            }}
            dropzoneOptions={dropZoneConfig}
            reSelect
            className='h-[400px]'
        >
            <FileInput className='group h-full border border-dashed border-gray-400 hover:bg-gray-200'>
                <div className='flex h-full w-full flex-col items-center justify-center gap-4 pb-4 pt-3 '>
                    {/* <FileSvgDraw /> */}
                    <Upload size={30} />
                    <span className='text-sm text-gray-500'>
                        <span className='font-bold'>Click to Upload</span> or to
                        Drag and Drop
                    </span>
                </div>
            </FileInput>
            <FileUploaderContent>
                {files &&
                    files.length > 0 &&
                    files.map((file, i) => (
                        <FileUploaderItem key={i} index={i}>
                            <Paperclip className='h-4 w-4 stroke-current' />
                            <span>{file.name}</span>
                        </FileUploaderItem>
                    ))}
            </FileUploaderContent>
        </FileUploader>
    );
}
