import { useAccount } from '@azure/msal-react';
import { useMemo } from 'react';
import useAppConfig from 'src/infrastructure/hooks/use-config.hook';
import { PurchaseService } from '../purchase.service';

export function usePurchaseService() {
    const config = useAppConfig();
    const account = useAccount();

    const service = useMemo(() => {
        if (!account?.idToken) throw new Error('Session Expired');

        return new PurchaseService(config, account.idToken);
    }, [config, account]);

    return { service };
}
