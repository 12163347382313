export enum UnitType {
    PCS = 'pcs',
    BALES = 'bales',
    DOZENS = 'dozens',
    STONE = 'stone',
    SET = 'set',
    KG = 'kg',
    G = 'g',
    T = 't',
    LB = 'lb',
    ML = 'ml',
    L = 'l',
    M = 'm',
    MM = 'mm',
    CM = 'cm',
    KM = 'km',
    IN = 'in',
    YD = 'yd',
    FT = 'ft',
}

export const UnitTypeLabel = {
    [UnitType.PCS]: 'Pieces',
    [UnitType.BALES]: 'Bales',
    [UnitType.DOZENS]: 'Dozens',
    [UnitType.STONE]: 'Stone',
    [UnitType.SET]: 'Set',
    [UnitType.KG]: 'Kilogram',
    [UnitType.G]: 'Gram',
    [UnitType.T]: 'Ton',
    [UnitType.LB]: 'Pound',
    [UnitType.ML]: 'Milliliter',
    [UnitType.L]: 'Liter',
    [UnitType.M]: 'Meter',
    [UnitType.MM]: 'Millimeter',
    [UnitType.CM]: 'Centimeter',
    [UnitType.KM]: 'Kilometer',
    [UnitType.IN]: 'Inch',
    [UnitType.YD]: 'Yard',
    [UnitType.FT]: 'Feet',
};

// Get by the value which will be like pcs
export const getUnitValue = (value: string) => {
    return UnitTypeLabel[value as UnitType];
};
