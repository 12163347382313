import { useMemo } from 'react';
import { useSuppliers } from 'src/app/_api_adb2c/purchase/suppliers/hooks/use-suppliers';
import { useDelegations } from 'src/app/_api_adb2c/workspace/delegations/hooks/use-delegations';
import { SelectInput } from './SelectInput';

interface Props {
    name: string;
    delegateId?: string;
    label?: string;
}

export const DelegationInput = ({ name, delegateId, label }: Props) => {
    const { data: delegations } = useDelegations(delegateId);
    const { data: suppliers } = useSuppliers(delegateId);

    const delegationOptions = useMemo(
        () =>
            (delegations || []).map((delegate) => {
                const supplier = suppliers?.find(
                    (x) => x.seller._id === delegate.delegatedTo._id
                );

                return {
                    label: supplier?.seller.name || '',
                    value: delegate.delegatedTo,
                };
            }),
        [delegations, suppliers]
    );

    return (
        <SelectInput
            label={label}
            name={name}
            data-testid='delegation-input'
            options={delegationOptions.map((delegate) => ({
                label: delegate.label,
                value: delegate.value._id,
            }))}
        ></SelectInput>
    );
};
