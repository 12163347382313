export enum ProductUom {
    PCS = 'pcs',
    BALES = 'bales',
    DOZENS = 'dozens',
    STONE = 'stone',
    SET = 'set',
    KG = 'kg',
    G = 'g',
    T = 't',
    LB = 'lb',
    ML = 'ml',
    L = 'l',
    M = 'm',
    MM = 'mm',
    CM = 'cm',
    KM = 'km',
    IN = 'in',
    YD = 'yd',
    FT = 'ft',
}

export const ProductUomLabels = {
    [ProductUom.PCS]: 'Pieces',
    [ProductUom.SET]: 'Set',
    [ProductUom.KG]: 'Kg',
    [ProductUom.G]: 'Grams',
    [ProductUom.T]: 'Tons',
    [ProductUom.LB]: 'Pounds',
    [ProductUom.ML]: 'Mililiters',
    [ProductUom.L]: 'Liters',
    [ProductUom.M]: 'Meters',
    [ProductUom.MM]: 'Milimeters',
    [ProductUom.CM]: 'Centimeters',
    [ProductUom.KM]: 'Kilo Meters',
    [ProductUom.IN]: 'Inches',
    [ProductUom.YD]: 'Yards',
    [ProductUom.FT]: 'Feet',
    [ProductUom.BALES]: 'Bales',
    [ProductUom.DOZENS]: 'Dozens',
    [ProductUom.STONE]: 'Stones',
};

export function getProductUom(uom: string): ProductUom | undefined {
    return (ProductUom as any)[uom.toUpperCase()];
}
