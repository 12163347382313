import { useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useProducts } from 'src/app/_api_adb2c/product/product/hooks/use-products';
import BlankImage from 'src/assets/blank-image.png';

interface Props {
    materialId: string;
}

export function MaterialProduct({ materialId }: Props) {
    const [searchParams] = useSearchParams();
    const delegateId = searchParams.get('delegateId') || '';

    const { data: products } = useProducts(delegateId);

    const memoizedData = useMemo(() => {
        if (!products) return [];

        return products.data.filter((x) => {
            const latestVersion = x.versions?.[x.versions.length - 1];

            return latestVersion?.billOfMaterials?.find((y) => {
                return y.material?._id === materialId;
            });
        });
    }, [products, materialId]);

    return (
        <div className='flex flex-wrap gap-4'>
            {memoizedData.map((product) => {
                return (
                    <div
                        key={product?._id}
                        className='grid w-[260px] max-w-[260px] grid-cols-3 gap-4 rounded-lg bg-white px-4 py-3 text-xs shadow'
                    >
                        <div>
                            <img src={BlankImage} alt='' className='w-full' />
                        </div>

                        <div className='col-span-2 flex flex-col gap-1 text-xs'>
                            <span className='truncate text-xs font-bold'>
                                {product.name}
                            </span>

                            <span className='truncate font-light'>
                                {product?.category?.name ||
                                    product?.category?.code}
                            </span>

                            <span className='font-extralight'>
                                {product.description || '--'}
                            </span>
                        </div>
                    </div>
                );
            })}
        </div>
    );
}
