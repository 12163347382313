import { useMemo } from 'react';
import { Bar, BarChart, CartesianGrid, LabelList, XAxis } from 'recharts';
import {
    PurchaseOrderNature,
    PurchaseOrderNatureLabels,
} from 'src/app/_api_adb2c/purchase/purchase/enums/purchase-order-nature.enum';
import { usePurchases } from 'src/app/_api_adb2c/purchase/purchase/hooks/use-purchases';
import { PurchaseModel } from 'src/app/_api_adb2c/purchase/purchase/models/purchase.model';
import {
    Card,
    CardContent,
    CardDescription,
    CardHeader,
    CardTitle,
} from 'src/components/ui/card';
import {
    ChartConfig,
    ChartContainer,
    ChartTooltip,
    ChartTooltipContent,
} from 'src/components/ui/chart';
import { getDateRangeDescription } from '../utils/get-date-range-description';

interface Props {
    startDate?: Date;
    endDate?: Date;
}

const chartConfig = {
    count: {
        label: 'Count',
        color: 'hsl(var(--chart-1))',
    },
} satisfies ChartConfig;

export function OrderType({ startDate, endDate }: Props) {
    const { data: purchases } = usePurchases({
        draft: false,
        page: 0,
        limit: 0,
    });

    const memoizedPurchases = useMemo(() => {
        if (!purchases?.data) return [];

        const responses: PurchaseModel[] = [];

        purchases.data.forEach((purchase) => {
            const createdDate = new Date(purchase.createdOn);

            if (startDate && endDate) {
                if (createdDate < startDate || createdDate > endDate) return;
            }

            responses.push(purchase);
        });

        return responses;
    }, [purchases, startDate, endDate]);

    const graphData = useMemo(() => {
        const typeCounts: { label: string; count: number }[] = [];

        memoizedPurchases.forEach((purchase) => {
            if (!purchase.nature) {
                return;
            }

            const formattedNature =
                PurchaseOrderNatureLabels[
                    purchase.nature as PurchaseOrderNature
                ];

            const existing = typeCounts.find(
                (x) => x.label === formattedNature
            );

            if (existing) {
                existing.count += 1;
            } else {
                typeCounts.push({
                    label: formattedNature,
                    count: 1,
                });
            }
        });

        return typeCounts;
    }, [memoizedPurchases]);

    return (
        <Card className='px-4 py-2 shadow'>
            <CardHeader>
                <CardTitle>Order Trends</CardTitle>
                <CardDescription>
                    {getDateRangeDescription(startDate, endDate)}
                </CardDescription>
            </CardHeader>

            <CardContent>
                <ChartContainer config={chartConfig} className='h-56 w-full'>
                    <BarChart
                        accessibilityLayer
                        data={graphData}
                        margin={{
                            top: 20,
                        }}
                    >
                        <CartesianGrid vertical={false} />
                        <XAxis
                            dataKey='label'
                            tickLine={false}
                            tickMargin={10}
                            axisLine={false}
                        />
                        <ChartTooltip
                            cursor={false}
                            content={<ChartTooltipContent hideLabel />}
                        />
                        <Bar
                            dataKey='count'
                            fill='var(--color-count)'
                            radius={8}
                        >
                            <LabelList
                                position='top'
                                offset={12}
                                className='fill-foreground'
                                fontSize={12}
                            />
                        </Bar>
                    </BarChart>
                </ChartContainer>
            </CardContent>
        </Card>
    );
}
