import { useSearchParams } from 'react-router-dom';
import { useDeletePurchase } from 'src/app/_api_adb2c/purchase/purchase/hooks/use-delete-purchase';
import { usePurchase } from 'src/app/_api_adb2c/purchase/purchase/hooks/use-purchase';
import { BaseDialog } from 'src/app/components-v2/base-dialog';

interface Props {
    purchaseId: string;
    open: boolean;
    onClose: () => void;
}

export function DeletePurchase({ purchaseId, open, onClose }: Props) {
    const [searchParams] = useSearchParams();
    const delegateId = searchParams.get('delegateId') || '';

    const { data: purchase } = usePurchase(purchaseId, delegateId);
    const { mutateAsync, isLoading } = useDeletePurchase();

    const deletePurchase = async () => {
        if (!purchase) return;

        await mutateAsync({
            id: purchase._id,
            delegateId: delegateId,
        });

        onClose();
    };

    return (
        <BaseDialog
            open={open}
            onClose={onClose}
            onConfirm={deletePurchase}
            title='Delete Purchase'
            isLoading={isLoading}
        >
            <div className='flex flex-col gap-2'>
                <span className='text-sm'>
                    Are you sure you want to delete this purchase?
                </span>

                <span className='text-xs font-light'>
                    * This action cannot be undone. This will permanently delete
                    the selected order.
                </span>
            </div>
        </BaseDialog>
    );
}
