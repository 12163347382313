import { cn } from 'src/lib/utils';

export interface ApparelGroupNodeProps {
    data: ApparelGroupNodeDataProps;
}

interface ApparelGroupNodeDataProps
    extends React.HTMLAttributes<HTMLDivElement> {
    label: string;
    height?: number;
}

export function PurchaseCascadeApparelGroupNode({
    data,
}: ApparelGroupNodeProps) {
    const { label, className, height } = data;

    return (
        <div
            className={cn(
                `w-52 rounded-md border-2 border-gray-400 `,
                className
            )}
            // Not sure why tailwind can't grab dynamic height so we have to do it manually
            style={{ height: `${height}px` }}
        >
            <div className='w-full border border-gray-400 bg-gray-400 px-2'>
                <span className='text-sm font-bold text-white'>{label}</span>
            </div>
        </div>
    );
}
