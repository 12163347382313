import { zodResolver } from '@hookform/resolvers/zod';
import { ColumnDef } from '@tanstack/react-table';
import axios from 'axios';
import { CloudUpload, Trash } from 'lucide-react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';
import { FileCreateDataObject } from 'src/app/_api_adb2c/attachment/file-create-data-object';
import { useAttachmentDownload } from 'src/app/_api_adb2c/attachment/hooks/use-attachment-download';
import { useAttachmentUpload } from 'src/app/_api_adb2c/attachment/hooks/use-attachment-upload';
import { useProduct } from 'src/app/_api_adb2c/product/product/hooks/use-product';
import { useUpdateProduct } from 'src/app/_api_adb2c/product/product/hooks/use-update-product';
import {
    ProductDocObject,
    ProductUpdateDataObject,
} from 'src/app/_api_adb2c/product/product/requests/product-update-data-object';
import {
    SupportDocType,
    SupportDocTypeLabel,
} from 'src/app/_api_adb2c/shared/support-doc-type.enum';
import { DialogV2 } from 'src/app/components-v2/dialog-v2';
import { FileInput, FileUploader } from 'src/app/components-v2/file-dropzone';
import { TableV2 } from 'src/app/components-v2/table-v2';
import { SelectInputV2 } from 'src/app/components/Form/SelectInputV2';
import { TextInputV2 } from 'src/app/components/Form/TextInputV2';
import { useContextStore } from 'src/app/stores/context-store';
import { getFileIdFromAssetName } from 'src/infrastructure/utils/extract-asset-id';
import { z } from 'zod';

interface Props {
    product: string;
    open: boolean;
    onClose: () => void;
}

const itemsSchema = z.object({
    document: z.string().min(1),
    type: z.string(),
    file: z.instanceof(File),
    comment: z.string().optional(),
});

const schema = z.object({
    items: z.array(itemsSchema),
});

export function ProductDocumentUpload({ product, open, onClose }: Props) {
    const context = useContextStore();
    const [searchParams] = useSearchParams();
    const delegateId = searchParams.get('delegateId') || '';

    const [files, setFiles] = useState<File[] | null>(null);
    const [loading, setLoading] = useState(false);
    const [isPopulating, setIsPopulating] = useState(false);
    const [isInitialized, setIsInitialized] = useState(false);

    const { mutateAsync: upload } = useAttachmentUpload();
    const { mutateAsync: update } = useUpdateProduct();
    const { mutateAsync: download } = useAttachmentDownload();

    const dropZoneConfig = {
        maxFiles: 5,
        maxSize: 1024 * 1024 * 4,
        multiple: true,
    };

    const { data } = useProduct(product, delegateId);

    const form = useForm<z.infer<typeof schema>>({
        mode: 'onChange',
        resolver: zodResolver(schema),
        defaultValues: {
            items: [],
        },
    });

    const { fields, append, replace } = useFieldArray({
        control: form.control,
        name: 'items',
    });

    const fetchFileById = useCallback(
        async (id: string, delegateId?: string) => {
            const fileResponse = await download({
                delegateId: delegateId || '',
                body: { id: id },
            });

            const fileBlob = await axios.get(fileResponse.token, {
                responseType: 'blob',
            });

            return new File(
                [fileBlob.data],
                fileResponse.attachment.originalName,
                {
                    type: fileResponse.attachment.contentType,
                }
            );
        },
        [download]
    );

    const onSubmit = async (params: z.infer<typeof schema>) => {
        setLoading(true);

        const documents: ProductDocObject[] = [];

        await Promise.all(
            params.items.map(async (item) => {
                const request: FileCreateDataObject = {
                    contentType: item.file.type,
                    name: item.file.name,
                    genre: 'temporary',
                    size: item.file.size,
                    uploadedBy: context.user?._id || '',
                    workspace: delegateId || context.workspace?._id || '',
                };

                const response = await upload({
                    file: request,
                    assetType: 'product',
                    delegateId: delegateId,
                });

                await axios.put(response.token, item.file, {
                    headers: {
                        'Content-Type': item.file.type,
                        'x-ms-blob-type': 'BlockBlob',
                    },
                });

                documents.push({
                    file: {
                        assetName: response.attachment.assetName,
                        autoResign: response.attachment.autoResign,
                        container: response.attachment.container,
                        contentType: response.attachment.contentType,
                        extension:
                            response.attachment.originalName.split('.').pop() ||
                            '',
                        originalName: response.attachment.originalName,
                        readReleaseTime: response.attachment.readReleaseTime,
                        size: response.attachment.size,
                    },
                    type: item.type,
                    uploader: context.user?._id || '',
                });
            })
        );

        const request: ProductUpdateDataObject = {
            name: data?.name || '',
            documents: documents,
        };

        await update({
            id: product,
            body: request,
        });

        reset();
    };

    const reset = () => {
        form.reset();
        onClose();
        setLoading(false);
    };

    const columns: ColumnDef<z.infer<typeof itemsSchema>>[] = useMemo(() => {
        const columns: ColumnDef<z.infer<typeof itemsSchema>>[] = [
            {
                id: 'document',
                header: 'Document',
                cell: ({ row }) => {
                    return (
                        <TextInputV2
                            className='w-full'
                            name={`items.${row.index}.document`}
                        />
                    );
                },
            },
            {
                id: 'type',
                header: 'Type',
                cell: ({ row }) => {
                    return (
                        <SelectInputV2
                            name={`items.${row.index}.type`}
                            options={Object.keys(SupportDocType).map((key) => ({
                                label: SupportDocTypeLabel[
                                    key as SupportDocType
                                ],
                                value: key,
                            }))}
                        />
                    );
                },
            },
            {
                id: 'actions',
                header: 'Actions',
                size: 20,
                cell: ({ row }) => {
                    return (
                        <div className='flex h-full items-center gap-2'>
                            <Trash
                                size={16}
                                className='text-red-500 hover:scale-125 hover:text-red-700'
                                onClick={() => {
                                    const newItems = fields.filter(
                                        (_, i) => i !== row.index
                                    );

                                    replace(newItems);
                                    setFiles(newItems.map((x) => x.file));
                                }}
                            />
                        </div>
                    );
                },
            },
        ];

        return columns;
    }, [replace, fields]);

    useEffect(() => {
        if (files && files.length > 0) {
            const filteredFiles = files.filter((file) => {
                return !fields.find((x) => x.file === file);
            });

            filteredFiles.forEach((file) => {
                append({
                    document: file.name,
                    type: '',
                    file: file,
                });
            });
        }
    }, [files, append, fields]);

    const resetFormWithValues = useCallback(async () => {
        if (!data) return;

        const files: z.infer<typeof itemsSchema>[] = [];
        setIsPopulating(true);

        await Promise.all(
            (data?.documents || []).map(async (doc) => {
                const file = await fetchFileById(
                    getFileIdFromAssetName(doc.file.assetName),
                    delegateId
                );

                files.push({
                    document: doc.file.originalName,
                    type: doc.type,
                    file: file,
                });
            })
        );

        setFiles(files.map((x) => x.file));
        form.reset({
            items: files,
        });
        setIsPopulating(false);
    }, [data, delegateId, fetchFileById, form]);

    useEffect(() => {
        if (open && !isInitialized) {
            resetFormWithValues();
            setIsInitialized(true);
        }
    }, [resetFormWithValues, open, isInitialized]);

    return (
        <DialogV2
            open={open}
            onClose={reset}
            title='Upload Documents'
            isStepDialog
            form={form}
            isLoading={loading}
            onSubmit={onSubmit}
            size='lg'
            steps={[
                {
                    title: 'Upload Documents',
                    description: 'Upload the documents for the product',
                    content: (
                        <div className='flex flex-col gap-4'>
                            <FileUploader
                                value={files}
                                onValueChange={setFiles}
                                dropzoneOptions={dropZoneConfig}
                                className='overflow-unset h-[150px] min-h-[150px] bg-gray-200'
                            >
                                <FileInput className='group h-full min-h-fit border border-dashed border-gray-400 hover:bg-gray-200'>
                                    <div className='flex h-full w-full flex-col items-center justify-center gap-4 pb-4 pt-3 text-center '>
                                        <CloudUpload size={30} />
                                        <span className='text-xs text-gray-500'>
                                            Click to SELECT FILE or DRAG & DROP
                                            FILES here
                                            <br />
                                            Only files in{' '}
                                            <span className='font-bold'>
                                                PDF, TIFF or JPEG
                                            </span>{' '}
                                            format will be accepted
                                            <br />
                                            Max File Size: 10MB
                                        </span>
                                    </div>
                                </FileInput>
                            </FileUploader>

                            <TableV2
                                label='Uploaded Files'
                                data={fields}
                                isLoading={isPopulating}
                                columns={columns}
                                fixedHeight='325px'
                                disableTooltips
                            />
                        </div>
                    ),
                },
            ]}
        ></DialogV2>
    );
}
