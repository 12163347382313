import {
    Dialog,
    DialogContent,
    DialogFooter,
    DialogHeader,
    DialogPortal,
    DialogTrigger,
} from 'src/components/ui/dialog';
import {
    PurchaseOrderItemModel,
    PurchaseOrderVersionModel,
} from '../../../../_api/purchase/purchase-order/purchase.model';
import { useSearchParams } from 'react-router-dom';
import { useState } from 'react';
import { useUpdatePurchase } from '../../../../_api/purchase/purchase-order/hooks/use-update-purchase';
import { Trash } from 'lucide-react';
import { Button } from 'src/components/ui/button';

type DeletePurchaseItemProps = {
    order: PurchaseOrderVersionModel;
    manifest: PurchaseOrderItemModel;
};

export const DeletePurchaseItem: React.FC<DeletePurchaseItemProps> = ({
    order,
    manifest,
}) => {
    const [searchParams] = useSearchParams();
    const delegateId = searchParams.get('delegateId') || '';
    const [open, setOpen] = useState(false);

    const { mutateAsync } = useUpdatePurchase();

    const removeItem = async () => {
        const items = order.manifest?.filter(
            (x) => x.purchaseables.id !== manifest.purchaseables.id
        );

        mutateAsync({
            id: order.owner.id,
            delegateId,
            data: {
                currency: order.owner.currency,
                rules: order.owner.rules?.map((x) => x.id) || [],
                shipToAddress: order.owner.shipToAddress,
                externalDataId: order.owner.externalDataId,
                purchaseProcesses:
                    order.owner.purchaseProcesses?.map((x) => x) || [],
                orderNature: order.owner.orderNature,
                items: items || [],
            },
        });

        reset();
    };

    const reset = () => {
        setOpen(false);
    };

    return (
        <>
            <Dialog open={open} onOpenChange={setOpen}>
                <DialogTrigger onClick={() => setOpen(true)}>
                    <Button className='px-2' variant='ghost'>
                        <Trash className='h-4 w-4 text-red-800' />
                    </Button>
                </DialogTrigger>

                <DialogPortal>
                    <DialogContent>
                        <DialogHeader className='font-bold'>
                            Delete Purchase Item
                        </DialogHeader>

                        <div className='flex flex-col gap-2'>
                            <span>
                                Are you sure you want to delete this,{' '}
                                <span className='font-bold text-red-800'>
                                    {manifest.purchaseables.name}
                                </span>
                                ?
                            </span>

                            <span>
                                This action cannot be undone. This will
                                permanently delete the selected product.
                            </span>
                        </div>

                        <DialogFooter>
                            <Button
                                variant='outline'
                                type='submit'
                                className='bg-red-800 text-white'
                                onClick={async () => {
                                    await removeItem();
                                }}
                            >
                                Confirm
                            </Button>
                        </DialogFooter>
                    </DialogContent>
                </DialogPortal>
            </Dialog>
        </>
    );
};
