import React, { Component, ErrorInfo, ReactNode } from 'react';

interface Props {
    children: ReactNode;
    fallback?: ReactNode;
}

interface State {
    hasError: boolean;
}

class ErrorBoundary extends Component<Props, State> {
    public state: State = {
        hasError: false,
    };

    public static getDerivedStateFromError(_: Error): State {
        return { hasError: true };
    }

    public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
        console.error('Error caught by error boundary:', error, errorInfo);

        if (process.env.NODE_ENV === 'development') {
            const overlay = document.getElementById(
                'webpack-dev-server-client-overlay'
            );
            if (overlay) {
                overlay.style.display = 'none';
            }
        }
    }

    public render() {
        if (this.state.hasError) {
            return (
                this.props.fallback || (
                    <div className='flex min-h-[400px] flex-col items-center justify-center p-4'>
                        <h2 className='mb-2 text-xl font-semibold text-red-600'>
                            Something went wrong
                        </h2>
                        <p className='text-gray-600'>
                            Please try refreshing the page
                        </p>
                        <button
                            onClick={() => window.location.reload()}
                            className='mt-4 rounded bg-red-600 px-4 py-2 text-white hover:bg-red-700'
                        >
                            Refresh Page
                        </button>
                    </div>
                )
            );
        }

        return this.props.children;
    }
}

export default ErrorBoundary;
