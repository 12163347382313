import { useMutation, useQueryClient } from '@tanstack/react-query';
import { toast } from 'sonner';
import { useContextStore } from 'src/app/stores/context-store';
import { TemplateCreateDataObject } from '../template-create-data-object';
import { TEMPLATES_QUERY_KEY, TEMPLATE_QUERY_KEY } from './template-query-keys';
import { useTemplateService } from './use-template-service';

export function useUpdateTemplate() {
    const client = useQueryClient();
    const context = useContextStore();
    const { service } = useTemplateService();

    return useMutation({
        mutationFn: async ({
            id,
            body,
            delegateId,
        }: {
            id: string;
            body: TemplateCreateDataObject;
            delegateId?: string;
        }) => {
            return await service.update(
                delegateId || context.workspace?._id || '',
                id,
                body
            );
        },
        onSuccess: (_, { delegateId, id }) => {
            client.invalidateQueries(
                TEMPLATES_QUERY_KEY(delegateId || context.workspace?._id || '')
            );

            client.invalidateQueries(
                TEMPLATE_QUERY_KEY(
                    delegateId || context.workspace?._id || '',
                    id
                )
            );

            toast.success('Template updated');
        },
        onError: (err) => {
            const error = err as Error;

            toast.error(`Failed to update template: ${error.message}`);
        },
    });
}
