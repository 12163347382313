import { useQuery } from '@tanstack/react-query';
import { toast } from 'sonner';
import { useContextStore } from 'src/app/stores/context-store';
import { WorkspaceLibraryModel } from '../../workspace/workspace.model';
import { DOCUMENTS_QUERY_KEY } from './documents-query-key';
import { useDocumentService } from './use-document-service';

export function useDocuments(delegateId?: string) {
    const { service } = useDocumentService();
    const context = useContextStore();

    return useQuery<WorkspaceLibraryModel>({
        queryKey: DOCUMENTS_QUERY_KEY(
            delegateId || context.workspace?._id || ''
        ),
        queryFn: async () => {
            return await service.list(
                delegateId || context.workspace?._id || ''
            );
        },
        onError: (err) => {
            const error = err as Error;

            toast.error(`Failed to load documents: ${error.message}`);
        },
    });
}
