import { CalendarIcon } from 'lucide-react';
import { format } from 'date-fns';
import { cn } from 'src/lib/utils';
import { Button } from 'src/components/ui/button';
import { Calendar } from 'src/components/ui/calendar';
import {
    Popover,
    PopoverContent,
    PopoverTrigger,
} from 'src/components/ui/popover';
import {
    Tooltip,
    TooltipContent,
    TooltipProvider,
    TooltipTrigger,
} from 'src/components/ui/tooltip';
import { InfoIcon } from 'lucide-react';

interface DatePickerProps {
    value?: Date;
    onValueChange: (date?: Date) => void;
    label?: string;
    placeholder?: string;
    disabled?: boolean;
    className?: string;
    tooltip?: string;
    description?: string;
}

export function DatePicker({
    value,
    onValueChange,
    label,
    placeholder = 'Pick a date',
    disabled = false,
    className,
    tooltip,
    description,
}: DatePickerProps) {
    return (
        <div className='flex flex-col gap-1.5'>
            {label && (
                <label className='text-xs font-medium'>
                    <span>
                        {label}{' '}
                        {tooltip && (
                            <TooltipProvider>
                                <Tooltip delayDuration={100}>
                                    <TooltipTrigger type='button'>
                                        <InfoIcon className='h-3 w-3' />
                                    </TooltipTrigger>
                                    <TooltipContent>
                                        <p>{tooltip}</p>
                                    </TooltipContent>
                                </Tooltip>
                            </TooltipProvider>
                        )}
                    </span>
                </label>
            )}

            <Popover>
                <PopoverTrigger asChild>
                    <Button
                        variant={'outline'}
                        className={cn(
                            'h-8 w-full justify-start text-left text-xs font-normal',
                            !value && 'text-muted-foreground',
                            className
                        )}
                        disabled={disabled}
                    >
                        <CalendarIcon className='mr-2 h-4 w-4' />
                        {value ? (
                            format(value, 'PPP')
                        ) : (
                            <span>{placeholder}</span>
                        )}
                    </Button>
                </PopoverTrigger>
                <PopoverContent className='w-[280px] p-3' align='start'>
                    <Calendar
                        mode='single'
                        selected={value}
                        onSelect={onValueChange}
                        initialFocus
                        className='rounded-md border'
                    />
                </PopoverContent>
            </Popover>

            {description && (
                <p className='text-xs text-muted-foreground'>{description}</p>
            )}
        </div>
    );
}
