import { ServiceConfigInterface } from 'src/domain/interfaces/config.context.interface';
import { VirtualPurchaseApiClient } from './virtual-purchase.client';
import { PurchaseOrderCreateDataObject } from '../purchase/requests/purchase-order-create-data-object';

export class VirtualPurchaseService {
    private server: VirtualPurchaseApiClient;

    constructor(config: ServiceConfigInterface, token: string) {
        this.server = new VirtualPurchaseApiClient(config, token);
    }

    list(workspace: string, page: number, limit: number) {
        console.log(`service<purchase>| list(): Enter`);
        console.log(`service<purchase>| list(): $workspace = ${workspace}`);
        console.log(`service<purchase>| list(): $page = ${page}`);
        console.log(`service<purchase>| list(): $limit = ${limit}`);

        return this.server.list(workspace, page, limit);
    }

    getRoot(workspace: string, product: string) {
        console.log(`service<purchase>| getRoot(): Enter`);
        console.log(`service<purchase>| getRoot(): $workspace = ${workspace}`);
        console.log(`service<purchase>| getRoot(): $product = ${product}`);

        return this.server.getRoot(workspace, product);
    }

    create(workspace: string, body: PurchaseOrderCreateDataObject) {
        console.log(`service<purchase>| create(): Enter`);
        console.log(`service<purchase>| create(): $workspace = ${workspace}`);
        console.log(
            `service<purchase>| create(): $body = ${JSON.stringify(body)}`
        );

        return this.server.create(workspace, body);
    }
}
