import { DateTime } from 'luxon';
import { useMemo } from 'react';
import { Bar, BarChart, CartesianGrid, XAxis, YAxis } from 'recharts';
import { useProductReportByCategory } from 'src/app/_api_adb2c/product/statistics/hooks/use-product-report-by-category';
import {
    Card,
    CardContent,
    CardDescription,
    CardHeader,
    CardTitle,
} from 'src/components/ui/card';
import {
    ChartConfig,
    ChartContainer,
    ChartTooltip,
    ChartTooltipContent,
} from 'src/components/ui/chart';
import { EmptyChart } from '../empty-chart';
import { getDateRangeDescription } from '../utils/get-date-range-description';

interface Props {
    startDate?: Date;
    endDate?: Date;
}

const chartConfig = {
    count: {
        label: 'Count',
        color: 'hsl(var(--chart-1))',
    },
} satisfies ChartConfig;

export function ProductByCategory({ startDate, endDate }: Props) {
    const { data: report } = useProductReportByCategory(
        startDate
            ? DateTime.fromJSDate(startDate).toFormat('yyyy-MM-dd')
            : DateTime.now().toFormat('yyyy-MM-dd'),
        endDate
            ? DateTime.fromJSDate(endDate).toFormat('yyyy-MM-dd')
            : DateTime.now().minus({ months: 2 }).toFormat('yyyy-MM-dd')
    );

    const dateRangeDescription = useMemo(
        () => getDateRangeDescription(startDate, endDate),
        [startDate, endDate]
    );

    const graphData = useMemo(() => {
        const categoryCounts: { label: string; count: number }[] = [];

        report
            ?.filter((x) => x.category)
            .forEach((item) => {
                const existing = categoryCounts.find(
                    (x) => x.label === item.category
                );

                if (existing) {
                    existing.count += item.count;
                } else {
                    categoryCounts.push({
                        label: item.category || '',
                        count: item.count,
                    });
                }
            });

        return categoryCounts;
    }, [report]);

    return (
        <Card className='px-4 py-2 shadow'>
            <CardHeader>
                <CardTitle>Product By Category</CardTitle>
                <CardDescription>{dateRangeDescription}</CardDescription>
            </CardHeader>

            <CardContent>
                {graphData.length > 0 ? (
                    <ChartContainer config={chartConfig}>
                        <BarChart
                            accessibilityLayer
                            data={graphData}
                            layout='vertical'
                            margin={{
                                left: 20,
                            }}
                        >
                            <CartesianGrid />
                            <XAxis
                                type='number'
                                dataKey='count'
                                axisLine={false}
                                tickLine={false}
                            />
                            <YAxis
                                dataKey='label'
                                type='category'
                                tickLine={false}
                                tickMargin={10}
                                axisLine={false}
                                tickFormatter={(value) => value.slice(0, 8)}
                            />
                            <ChartTooltip
                                cursor={false}
                                content={<ChartTooltipContent />}
                            />
                            <Bar
                                dataKey='count'
                                fill='var(--color-count)'
                                radius={1}
                                barSize={20}
                            />
                        </BarChart>
                    </ChartContainer>
                ) : (
                    <EmptyChart />
                )}
            </CardContent>
        </Card>
    );
}
