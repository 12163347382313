import { useContextStore } from 'src/app/stores/context-store';
import { useRulesetService } from './use-ruleset-service';
import { useQuery } from '@tanstack/react-query';
import { BATCH_RULESETS_QUERY_KEY } from './ruleset-query-keys';
import { toast } from 'sonner';

export function useBatchRulesets(batch: string[]) {
    const { service } = useRulesetService();
    const context = useContextStore();

    return useQuery({
        queryKey: BATCH_RULESETS_QUERY_KEY(context.workspace?._id || '', batch),
        queryFn: async () => {
            return await service.batch({
                targets: batch,
            });
        },
        onError: (err) => {
            const error = err as Error;

            toast.error(`Failed to fetch rulesets: ${error.message}`);
        },
        enabled: batch.length > 0,
    });
}
