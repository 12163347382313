import { ServiceConfigInterface } from '../../../../domain/interfaces/config.context.interface';
import { ApiServer } from '../../../../infrastructure/clients/api.server';
import { DocumentModel } from '../documents/document.model';
import { WorkspaceModel } from '../workspace/workspace.model';

export class CompanyApiClient extends ApiServer<any> {
    constructor(config: ServiceConfigInterface, token: string) {
        super(token, config, 'workspaces', 'company');
    }

    load(workspace: string) {
        console.log(`client<company>| load(): Enter`);
        console.log(`client<company>| load(): $nworkspaceame = ${workspace}`);
        return super.get('get', workspace);
    }

    search(name?: string) {
        console.log(`client<company>| search(): Enter`);
        console.log(`client<company>| search(): $name = ${name}`);
        return super.get('search', name);
    }

    update(workspace: string, company: WorkspaceModel['company']) {
        console.log(`client<company>| search(): Enter`);
        console.log(`client<company>| search(): $workspace = ${workspace}`);
        console.log(
            `client<company>| search(): $company = ${JSON.stringify(company)}`
        );
        return super.patch('update', workspace, company);
    }

    solicitDocs(workspace: string, partner: string): Promise<DocumentModel[]> {
        console.log(`client<company>| solicitDocs(): Enter`);
        console.log(
            `client<company>| solicitDocs(): $workspace = ${workspace}`
        );
        console.log(`client<company>| solicitDocs(): $partner = ${partner}`);

        return super.get('solicitDocuments', workspace, partner);
    }
}
