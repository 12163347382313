import { useFormContext } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';
import { useSuppliers } from 'src/app/_api_adb2c/purchase/suppliers/hooks/use-suppliers';
import { z } from 'zod';
import { schema } from './add-substance-v3';
import { SelectInputV2 } from 'src/app/components/Form/SelectInputV2';
import { DetailLabel } from 'src/app/components-v2/detail-label';
import { TextInputV2 } from 'src/app/components/Form/TextInputV2';

export function AddSubstanceSource() {
    const [searchParams] = useSearchParams();
    const delegateId = searchParams.get('delegateId') || '';
    const { watch } = useFormContext<z.infer<typeof schema>>();

    const { data: suppliers } = useSuppliers(delegateId);
    const supplier = watch('supplier');

    return (
        <div className='grid grid-cols-2 gap-4'>
            <SelectInputV2
                label='Supplier'
                name='supplier'
                options={[
                    { label: '+ Add New', value: 'new' },
                    ...(suppliers || []).map((x) => ({
                        label: x.seller.name,
                        value: x.seller._id,
                    })),
                ]}
            />

            <DetailLabel title='Country Of Origin' className='py-2'>
                <span className='block pt-2'>
                    {suppliers?.find((x) => x.seller._id === supplier)?.seller
                        .country || '--'}
                </span>
            </DetailLabel>

            <TextInputV2
                name='reachRegistrationNumber'
                label='Reach Registration Number'
                placeholder='Enter Reach Registration Number'
            />
        </div>
    );
}
