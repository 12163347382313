import { AttachmentModel } from '../../files/attachment.model';
import { CompanyProcessType } from '../../shared/company-process.type';
import { i18nModel } from '../../shared/i18n.model';
import { BaseSupportDocumentModel } from '../../shared/support-document.model';
import { TagModel } from '../../shared/tag.model';
import { DelegationModel } from '../../workspace/delegations/delegation.model';
import { SupplierModel } from '../suppliers/supplier.model';

export enum OrderNatureType {
    COMPONENT = 'COMPONENT',
    PROCESSING = 'PROCESSING',
    OUTSOURCE = 'OUTSOURCE',
    RAW_MATERIAL = 'RAW_MATERIAL',
}

export const OrderNatureTypeLabel = {
    [OrderNatureType.COMPONENT]: 'Component',
    [OrderNatureType.PROCESSING]: 'Processing',
    [OrderNatureType.OUTSOURCE]: 'Outsource',
    [OrderNatureType.RAW_MATERIAL]: 'Raw Material',
};

export enum PurchaseOrderStatus {
    CANCELLED = 'CANCELLED',
    CLOSED = 'CLOSED',
    DELIVERED = 'DELIVERED',
    DETAINED = 'DETAINED',
    DISCARDED = 'DISCARDED',
    DRAFT = 'DRAFT',
    RELEASED = 'RELEASED',
    SETTLED = 'SETTLED',
    WITHHELD = 'WITHHEAD',
}

export const PurchaseOrderStatusLabel = {
    [PurchaseOrderStatus.CANCELLED]: 'Cancelled',
    [PurchaseOrderStatus.CLOSED]: 'Closed',
    [PurchaseOrderStatus.DELIVERED]: 'Delivered',
    [PurchaseOrderStatus.DETAINED]: 'Detained',
    [PurchaseOrderStatus.DISCARDED]: 'Discarded',
    [PurchaseOrderStatus.DRAFT]: 'Draft',
    [PurchaseOrderStatus.RELEASED]: 'Released',
    [PurchaseOrderStatus.SETTLED]: 'Settled',
    [PurchaseOrderStatus.WITHHELD]: 'Withheld',
};

export enum PurchaseOrderVersionStatus {
    DRAFT = 'DRAFT',
    RELEASED = 'RELEASED',
}

export interface PurchaseOrderModel {
    id: string;
    closedOn?: Date;
    createdOn: Date;
    currency: string;
    deletedOn?: Date;
    externalDataId?: string;
    flags?: FlagModel[];
    lastUpdatedOn: Date;
    link?: PurchaseOrderLinkModel;
    orderTotal?: number;
    owner: UserModel;
    parentOrderReference?: string;
    releasedOn?: Date;
    rules?: RuleModel[];
    status: PurchaseOrderStatus;
    supplierProducer?: WorkspaceModel;
    supplier: SupplierModel;
    versions: PurchaseOrderVersionModel[];
    workspace: WorkspaceModel;
    shippedOn?: Date[];
    shipToAddress?: string;
    purchaseProcesses?: string[];
    orderNature: OrderNatureType;
    delegated?: WorkspaceModel;
    externalCustomerReference?: string;
}

export interface PurchaseOrderCreateDataObject {
    currency: string;
    externalDataId?: string;
    items: PurchaseOrderItemDataObject[];
    parentOrderReference?: string;
    rules?: string[];
    shippedOn?: Date[];
    shipToAddress?: string;
    supplier: string;
    supplierProducer?: string;
    purchaseProcesses?: (CompanyProcessType | string)[];
    orderNature: OrderNatureType;
    delegated?: string;
}

export interface PurchaseOrderUpdateDataObject {
    currency?: string;
    externalDataId?: string;
    items?: (PurchaseOrderItemDataObject | PurchaseOrderItemModel)[];
    rules?: string[];
    shippedOn?: Date[];
    shipToAddress?: string;
    purchaseProcesses?: (CompanyProcessType | string)[];
    orderNature?: OrderNatureType;
}

export interface PurchaseOrderItemDataObject {
    comment?: string;
    material: string;
    ppu: number;
    quantity: number;
    sequence?: number;
}

export interface PurchaseOrderVersionModel {
    id: string;
    createdOn: Date;
    deliveryDate?: Date;
    lastUpdatedOn: Date;
    manifest: PurchaseOrderItemModel[];
    notes?: string;
    owner: PurchaseOrderModel;
    releasedOn?: Date;
    status: PurchaseOrderVersionStatus;
    delegated?: WorkspaceModel;
}

export interface PurchaseOrderItemModel {
    id: number;
    comment?: string;
    createdOn: Date;
    deletedOn?: Date;
    purchaseables: PurchaseItemModel;
    ppu: number;
    quantity: number;
    sequence?: number;
    unit: string;
    version: PurchaseOrderVersionModel;
}

export interface PurchaseItemModel {
    id: string;
    category?: string;
    collection?: string;
    compositions: CompositionModel[];
    createdOn: Date;
    deletedOn?: Date;
    externalDataId?: string;
    genre?: string;
    lastUpdatedOn: Date;
    name: string;
    pictures?: AttachmentModel[];
    purchases?: PurchaseOrderItemModel[];
    specifications: TagModel[];
    unit: string;
    workspace: WorkspaceModel;
}

interface CompositionModel {
    id: number;
    createdOn: Date;
    item: PurchaseItemModel;
    lastUpdatedOn: Date;
    material: string;
    name: i18nModel;
    weight: number;
    certificatesRequired: string[];
}

interface FlagModel {
    id: string;
    clearedOn?: Date;
    createdOn: Date;
    source: string;
    readOn?: Date;
    reader: true;
    reportedOn?: Date;
    reporter?: string;
    rule: RuleModel;
    subject: string;
    triggerTo: PurchaseOrderModel;
}

export interface RuleModel {
    id: string;
    applicables: PurchaseOrderModel[];
    code: string;
    createdOn: Date;
    documentation: SupportDocumentModel[];
    lastUpatedOn?: Date;
    targets: CompanyProcessType[];
    triggers: FlagModel[];
    workspace: WorkspaceModel;
}

export interface SupportDocumentModel extends BaseSupportDocumentModel {
    rule: RuleModel;
}

export interface PurchaseOrderLinkModel {
    id: number;
    createdOn: Date;
    lastUpdatedOn: Date;
    owner: PurchaseOrderModel;
    salesOrder?: number;
}

export interface PurchaseOrderTraceModel {
    closedOn?: Date;
    createdOn: Date;
    currency: string;
    delegatedId?: string;
    deletedOn?: Date;
    externalDataId?: string;
    externalCustomerReference?: string;
    from: number;
    id: string;
    lastUpdatedOn: Date;
    manifest: PurchaseOrderItemModel[];
    orderNature: OrderNatureType;
    ownerId: string;
    parentOrderReference?: string;
    path: number[];
    purchaseProcesses?: string[];
    releasedOn?: Date;
    salesOrder: number;
    shipToAddress?: string;
    shippedOn?: Date[];
    status: PurchaseOrderStatus;
    supplier: SupplierModel;
    supplierId: string;
    tier: number;
    to: number;
    workspaceId: string;
}

interface UserModel {
    id: string;
    buyerContacts?: SupplierModel[];
    createdOn: Date;
    deletedOn?: Date;
    email: string;
    externalDataId?: string;
    firstName?: string;
    lastName?: string;
    lastUpdatedOn: Date;
    purchaseOrders?: PurchaseOrderModel[];
    readFlags?: FlagModel[];
    suppliers?: SupplierModel[];
    token?: string;
}

interface WorkspaceModel {
    id?: string;
    buyers: SupplierModel[];
    companyName?: string;
    complianceLevel?: string;
    complianceRating?: string;
    complianceExpiresOn?: Date;
    country?: string;
    createdOn: Date;
    dbaName?: string;
    dbaRegistration?: string;
    dbaReference?: string;
    deletedOn?: Date;
    lfSupplierCode?: string;
    industry?: string;
    lastUpdatedDate: Date;
    name: string;
    isTier0?: boolean;
    nominatedLists?: NominatedListModel[];
    isProfileCompleted?: boolean;
    processes: CompanyProcessType[];
    purchaseables: PurchaseItemModel[];
    purchaseOrders: PurchaseOrderModel[];
    suppliers: SupplierModel[];
    supportingPurchaseOrders: PurchaseOrderModel[];
    purchaseOrderFormat: string;
    rulesets: RuleModel[];
    templates: TemplateModel[];
    delegate: DelegationModel;
    workspace: DelegationModel;
    purchaseOrderDelegated: PurchaseOrderModel;
    versionsDelegated: PurchaseOrderVersionModel;
}

interface TemplateModel {
    id: number;
    channel: string;
    code: string;
    content: string;
    createdOn: Date;
    description?: string;
    lastUpdatedOn: Date;
    locale: string;
    name: string;
    subject: string;
    workspace: WorkspaceModel;
}

interface NominatedListModel {
    id: number;
    createdOn: Date;
    lastUpdatedOn: Date;
    name: string;
    owner: WorkspaceModel;
    suppliers: SupplierModel[];
    assigned: SupplierModel[];
}
