import { useSearchParams } from 'react-router-dom';
import { ProductVersionModel } from '../../_api/products/products/product.model';
import { StepDialog } from 'src/app/components/StepDialog';
import { z } from 'zod';
import { UnitType, UnitTypeLabel } from 'src/domain/enums/unit-type.enum';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { useBaseCategories } from '../../_api/products/categories/hooks/use-base-categories';
import { useCategories } from '../../_api/products/categories/hooks/use-categories';
import { useEffect, useMemo, useState } from 'react';
import { Edit } from 'lucide-react';
import { TextInput } from 'src/app/components/Form/TextInput';
import { SelectInput } from 'src/app/components/Form/SelectInput';
import { SwitchInput } from 'src/app/components/Form/SwitchInput';
import { useUpdateProduct } from '../../_api/products/products/hooks/use-update-product';

interface Props {
    product: ProductVersionModel;
}

const formSchema = z.object({
    name: z.string(),
    cost: z.coerce.number(),
    base: z.string(),
    category: z.string(),
    description: z.string().optional(),
    unit: z.nativeEnum(UnitType),
    measureValue: z.coerce.number(),
    isActive: z.boolean().default(true).optional(),
    weight: z.coerce.number().optional(),
    color: z.string().optional(),
    externalDataId: z.string().optional(),
    hsCode: z.string().optional(),
    sku: z.string().optional(),
    upc: z.string().optional(),
    code: z.string().optional(),
    collection: z.string().optional(),
});

export const EditProduct = ({ product }: Props) => {
    const [searchParams] = useSearchParams();
    const [open, setOpen] = useState(false);
    const delegateId = useMemo(
        () => searchParams.get('delegateId') || '',
        [searchParams]
    );

    const { mutateAsync: update } = useUpdateProduct();

    const form = useForm<z.infer<typeof formSchema>>({
        mode: 'onChange',
        resolver: zodResolver(formSchema),
        defaultValues: {
            name: product.product.name,
            cost: product.cost,
            base: product.product.category.base?.id?.toString(),
            category: product.product.category?.id?.toString(),
            unit: product.product.unit as UnitType,
            measureValue: product.product.measureValue,
            isActive: product.product.isActive,
            weight: product.weight,
            color: product.product.color,
            externalDataId: product.product.externalDataId,
            hsCode: product.product.hsCode,
            sku: product.product.sku,
            upc: product.product.upc,
            code: product.product.code,
            collection: product.product.collection,
            description: product.product.description || '',
        },
    });

    const { data: baseCategories } = useBaseCategories();
    const { data: categories } = useCategories(delegateId);

    const onSubmit = async (data: z.infer<typeof formSchema>) => {
        await update({
            id: product.product.id || '',
            delegateId,
            params: data,
        });
    };

    useEffect(() => {
        if (open) {
            form.reset({
                name: product.product.name,
                cost: product.cost,
                base: product.product.category.base.id.toString(),
                category: product.product.category.id.toString(),
                unit: product.product.unit as UnitType,
                measureValue: product.product.measureValue,
                isActive: product.product.isActive,
                weight: product.weight,
                color: product.product.color,
                externalDataId: product.product.externalDataId,
                hsCode: product.product.hsCode,
                sku: product.product.sku,
                upc: product.product.upc,
                code: product.product.code,
                collection: product.product.collection,
                description: product.product.description,
            });
        }
    }, [form, open, product]);

    return (
        <StepDialog
            header='Edit Product'
            open={open}
            setOpen={setOpen}
            form={form}
            onSubmit={onSubmit}
            steps={[
                {
                    label: 'Required Fields',
                    content: (
                        <>
                            <TextInput
                                label='Name'
                                inputName='name'
                                readOnly
                                disabled
                            />

                            <TextInput
                                inputName='cost'
                                label='Cost'
                                type='number'
                            />

                            <div className='flex gap-4'>
                                <SelectInput
                                    label='Base Category'
                                    name='base'
                                    options={(baseCategories || []).map(
                                        (category) => ({
                                            label:
                                                category.name?.locales?.[0]
                                                    .text || '',
                                            value: category.id.toString() || '',
                                        })
                                    )}
                                />

                                <SelectInput
                                    label='Sub Category'
                                    name='category'
                                    options={(categories || [])
                                        .filter((category) => {
                                            return (
                                                category.base?.id.toString() ===
                                                form.getValues().base
                                            );
                                        })
                                        .map((category) => ({
                                            label:
                                                category.name?.locales?.[0]
                                                    .text || '',
                                            value: category.id || '',
                                        }))}
                                />
                            </div>

                            <div className='flex gap-4'>
                                <SelectInput
                                    label='Unit'
                                    name='unit'
                                    options={Object.values(UnitType).map(
                                        (unit) => ({
                                            label: UnitTypeLabel[unit],
                                            value: unit,
                                        })
                                    )}
                                />

                                <TextInput
                                    inputName='measureValue'
                                    label='Measure Value'
                                    type='number'
                                />
                            </div>
                        </>
                    ),
                },
                {
                    label: 'Optional Fields',
                    content: (
                        <>
                            <SwitchInput name='isActive' label='Active' />
                            <TextInput
                                inputName='description'
                                label='Description'
                            />
                            <TextInput
                                inputName='weight'
                                label='Weight'
                                type='number'
                            />
                            <TextInput inputName='color' label='Color' />
                            <TextInput
                                inputName='externalDataId'
                                label='External Data Id'
                            />
                            <TextInput inputName='hsCode' label='HS Code' />
                            <TextInput inputName='sku' label='SKU' />
                            <TextInput inputName='upc' label='UPC' />
                            <TextInput inputName='code' label='Code' />
                            <TextInput
                                inputName='collection'
                                label='Collection'
                            />
                        </>
                    ),
                },
            ]}
            trigger={{
                icon: <Edit className='h-4 w-4' />,
                variant: 'ghost',
                size: 'sm',
            }}
        />
    );
};
