import { useMutation, useQueryClient } from '@tanstack/react-query';
import { toast } from 'sonner';
import { useContextStore } from 'src/app/stores/context-store';
import { RoleAssignRequestObject } from '../role-assign-request-object';
import { ROLES_QUERY_KEY } from './roles-query';
import { useRolesService } from './use-roles-service';

export function useUnassignMember() {
    const client = useQueryClient();
    const { workspace } = useContextStore();
    const { service } = useRolesService();

    return useMutation({
        mutationFn: async ({
            id,
            delegateId,
            body,
        }: {
            delegateId?: string;
            body: RoleAssignRequestObject;
            id: string;
        }) => {
            return await service.unassignMember(
                delegateId || workspace?._id || '',
                id,
                body
            );
        },
        onSuccess: () => {
            client.invalidateQueries(ROLES_QUERY_KEY(workspace?._id || ''));

            toast.success('Successfully unassigned member');
        },
        onError: (err) => {
            const error = err as Error;

            toast.error(`Failed to unassign member: ${error.message}`);
        },
    });
}
