import { toast } from 'sonner';
import { useDeleteProduct } from 'src/app/_api_adb2c/product/product/hooks/use-delete-product';
import { ProductModel } from 'src/app/_api_adb2c/product/product/models/product.model';
import { usePurchases } from 'src/app/_api_adb2c/purchase/purchase/hooks/use-purchases';
import { RemoveDialog } from '../Purchases/Details/delete-purchase';

interface Props {
    product: ProductModel;
    onClose: () => void;
}

export const DeleteProduct = ({ product, onClose }: Props) => {
    const { data: purchases } = usePurchases({
        draft: false,
        page: 0,
        limit: 0,
    });
    const { mutateAsync, isLoading } = useDeleteProduct();

    const checkIfProductIsUsed = () => {
        return purchases?.data.some((purchase) => {
            return purchase.versions.some((version) => {
                return version.manifest.some((manifest) => {
                    return manifest?.product?._id === product?._id;
                });
            });
        });
    };

    const remove = async () => {
        if (checkIfProductIsUsed()) {
            toast.error(
                'Unable to remove product. Product is used in a purchase.'
            );
            return;
        }

        await mutateAsync({ item: product._id });
        onClose();
    };

    return (
        <RemoveDialog
            isLoading={isLoading}
            open={true}
            description='Are you sure you want to remove this product?'
            onClose={onClose}
            onRemove={remove}
            title='Delete Product'
        />
    );
};
