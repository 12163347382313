import { zodResolver } from '@hookform/resolvers/zod';
import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import ReactQuill from 'react-quill';
import { TemplateCreateDataObject } from 'src/app/_api_adb2c/workspace/template/template-create-data-object';
import { WorkspaceTemplateVirtualModel } from 'src/app/_api_adb2c/workspace/template/template.model';
import { TextInput } from 'src/app/components/Form/TextInput';
import { Button } from 'src/components/ui/button';
import {
    Dialog,
    DialogContent,
    DialogFooter,
    DialogTitle,
} from 'src/components/ui/dialog';
import { Form } from 'src/components/ui/form';
import { z } from 'zod';

interface Props {
    open: boolean;
    template: WorkspaceTemplateVirtualModel;
    onSubmit: (template: TemplateCreateDataObject) => void;
    onClose: () => void;
}

const formSchema = z.object({
    channel: z.string(),
    code: z.string(),
    content: z.string().optional(),
    description: z.string().optional(),
    locale: z.string(),
    name: z.string(),
    subject: z.string(),
});

export const EditTemplate = ({ template, open, onClose, onSubmit }: Props) => {
    const title = template
        ? template?.adaptation?.localisations?.[0].subject
        : '';
    const [content, setContent] = useState('');

    const adaptation = useMemo(
        () => template?.adaptation?.localisations?.[0],
        [template]
    );

    const form = useForm<z.infer<typeof formSchema>>({
        mode: 'onChange',
        resolver: zodResolver(formSchema),
        defaultValues: {
            code: template?.adaptation?.template || template.base?.template,
            subject: adaptation?.subject || template.base?.subject,
            name: adaptation?.subject || template.base?.subject,
            channel: template?.adaptation?.channel || 'email',
            locale: 'en',
            content: adaptation?.content || template.base?.content,
        },
    });

    const handleSubmit = async (data: z.infer<typeof formSchema>) => {
        await onSubmit(data);
    };

    useEffect(() => {
        setContent(adaptation?.content || template.base?.content || '');
    }, [adaptation?.content, template.base?.content]);

    return (
        <Dialog open={open} onOpenChange={onClose}>
            <DialogContent>
                <DialogTitle>Template: {title}</DialogTitle>

                <Form {...form}>
                    <form
                        id='my-form'
                        onSubmit={form.handleSubmit(handleSubmit)}
                    >
                        <TextInput inputName='subject' label='Subject' />
                        <TextInput inputName='channel' label='Channel' />

                        <ReactQuill
                            className='mt-4'
                            value={content}
                            onChange={setContent}
                        />
                    </form>
                </Form>

                <DialogFooter>
                    <Button size='sm' variant='outline' onClick={onClose}>
                        Cancel
                    </Button>

                    <Button size='sm' form='my-form' type='submit'>
                        Submit
                    </Button>
                </DialogFooter>
            </DialogContent>
        </Dialog>
    );
};
