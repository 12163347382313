import { ColumnFiltersState, Table } from '@tanstack/react-table';
import { Columns, Replace } from 'lucide-react';
import { useState } from 'react';
import { Button } from 'src/components/ui/button';
import {
    DropdownMenu,
    DropdownMenuCheckboxItem,
    DropdownMenuContent,
    DropdownMenuTrigger,
} from 'src/components/ui/dropdown-menu';
import { TooltipWrapper } from '../../TooltipWrapper';
import { DelegationFilter } from './delegation-filter';
import { SearchFilter } from './search-filter';

interface Props<TData> {
    table: Table<TData>;
    columnFilters: ColumnFiltersState;
}

export function FilterControls<TData>({ table, columnFilters }: Props<TData>) {
    const [delegationOpen, setDelegationOpen] = useState(false);

    return (
        <div className='ml-1 flex items-center justify-between'>
            <div className='flex items-center py-4'>
                <SearchFilter table={table} columnFilters={columnFilters} />
            </div>

            <div className='flex gap-4'>
                <DropdownMenu>
                    <TooltipWrapper label='Delegation'>
                        <DropdownMenuTrigger asChild>
                            <Button
                                variant='outline'
                                className='ml-auto'
                                onClick={() => setDelegationOpen(true)}
                            >
                                <Replace className='h-4 w-4' />
                            </Button>
                        </DropdownMenuTrigger>
                    </TooltipWrapper>
                </DropdownMenu>

                {/* Column visibility */}
                <DropdownMenu>
                    <TooltipWrapper label='Toggle Columns'>
                        <DropdownMenuTrigger asChild>
                            <Button variant='outline' className='ml-auto'>
                                <Columns className='h-4 w-4' />
                            </Button>
                        </DropdownMenuTrigger>
                    </TooltipWrapper>

                    <DropdownMenuContent align='end'>
                        {table
                            .getAllColumns()
                            .filter((column) => column.getCanHide())
                            .map((column) => {
                                return (
                                    <DropdownMenuCheckboxItem
                                        key={column.id}
                                        className='text-xs capitalize'
                                        checked={column.getIsVisible()}
                                        onCheckedChange={(value) =>
                                            column.toggleVisibility(!!value)
                                        }
                                    >
                                        {column.id}
                                    </DropdownMenuCheckboxItem>
                                );
                            })}
                    </DropdownMenuContent>
                </DropdownMenu>
            </div>

            <DelegationFilter
                open={delegationOpen}
                setOpen={setDelegationOpen}
            />
        </div>
    );
}
