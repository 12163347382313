import { TextInputV2 } from 'src/app/components/Form/TextInputV2';

export const AddProductOptionalFields = () => {
    return (
        <div className='flex flex-col gap-4'>
            <TextInputV2 name='description' label='Description' />
            <TextInputV2 name='color' label='Color' />
            <TextInputV2 name='externalDataId' label='Item Number' />
            <TextInputV2 name='hsCode' label='HS Code' />
            <TextInputV2 name='sku' label='SKU' />
            <TextInputV2 name='upc' label='UPC' />
            <TextInputV2 name='externalReferenceCode' label='Code' />
            <TextInputV2 name='moqCode' label='Minimum Order Quantity (MOQ)' />
            <TextInputV2 name='collection' label='Collection' />
        </div>
    );
};
