import { useMutation, useQueryClient } from '@tanstack/react-query';
import { toast } from 'sonner';
import { useContextStore } from 'src/app/stores/context-store';
import { DelegationCreateObject } from '../delegation-create-object';
import { DELEGATIONS_QUERY_KEY } from './delegation-query-keys';
import { useDelegationService } from './use-delegation-service';

export function useCreateDelegation() {
    const { service } = useDelegationService();
    const client = useQueryClient();
    const context = useContextStore();

    return useMutation({
        mutationFn: async ({
            delegateId,
            body,
        }: {
            delegateId?: string;
            body: DelegationCreateObject;
        }) => {
            return await service.create(
                delegateId || context.workspace?._id || '',
                body
            );
        },
        onSuccess: (_, { delegateId }) => {
            client.invalidateQueries(
                DELEGATIONS_QUERY_KEY(
                    delegateId || context.workspace?._id || ''
                )
            );

            toast.success('Successfully created delegation');
        },
        onError: (err) => {
            const error = err as Error;

            toast.error(`Failed to create delegation: ${error.message}`);
        },
    });
}
