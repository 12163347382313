import { Edit, Trash } from 'lucide-react';
import { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { toast } from 'sonner';
import { useCategory } from 'src/app/_api_adb2c/product/category/hooks/use-category';
import { useDeleteCategory } from 'src/app/_api_adb2c/product/category/hooks/use-delete-category';
import { useProducts } from 'src/app/_api_adb2c/product/product/hooks/use-products';
import {
    IndustryType,
    IndustryTypeLabels,
} from 'src/app/_api_adb2c/product/product/models/product.model';
import {
    ProductUom,
    ProductUomLabels,
} from 'src/app/_api_adb2c/purchase/purchase/enums/product-uom.enum';
import BlankImage from 'src/assets/blank-image.png';
import { Badge } from 'src/components/ui/badge';
import { Button } from 'src/components/ui/button';
import { DeleteDialog } from './delete-category';
import { EditCategory } from './edit-category';
import { useBreadcrumbStore } from 'src/app/stores/breadcrumb-store';

export const CategoriesDetails = () => {
    const { setBreadcrumbs } = useBreadcrumbStore();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const delegateId = searchParams.get('delegateId') || '';

    const { id } = useParams<{ id: string }>();
    const { data } = useCategory(id || '', delegateId);
    const { data: products } = useProducts(delegateId);

    const memoizedProducts = useMemo(() => {
        if (!products) return [];

        return products?.data.filter((x) => x.category?._id === data?._id);
    }, [data, products]);

    const [isEdit, setIsEdit] = useState(false);
    const [isDelete, setIsDelete] = useState(false);

    const { mutateAsync: remove } = useDeleteCategory();

    const deleteCategory = async () => {
        if (memoizedProducts.length > 0) {
            toast.error('Cannot delete category as it has products');
            return;
        }

        await remove({
            id: data?._id || '',
            delegateId: delegateId || '',
        });
    };

    const navigateToProducts = (id: string) => {
        navigate(
            delegateId
                ? { pathname: '/products', search: `?delegateId=${delegateId}` }
                : `/products/${id}`
        );
    };

    useEffect(() => {
        if (!data) return;

        setBreadcrumbs([
            {
                label: 'Categories',
                navigateTo: '/categories',
            },
            {
                label: data.name,
            },
        ]);
    }, [data, setBreadcrumbs]);

    return (
        <div className='mt-6 flex flex-col gap-6'>
            <h1 className='text-xl font-semibold'>
                {/* {data?.name?.locales?.find((x) => x.localeName === 'en')?.text} */}
                {data?.name}
            </h1>

            <div className='grid grid-cols-4 gap-4 rounded border border-r-4 bg-white px-4 py-4 shadow'>
                <div className='col-span-1 grid grid-cols-1 gap-4'>
                    <div className='prose flex flex-col'>
                        <label className='prose-slate text-xs font-bold'>
                            Name
                        </label>
                        <span className='prose-sm text-xs'>{data?.name}</span>
                    </div>

                    <div className='prose flex flex-col'>
                        <label className='prose-slate text-xs font-bold'>
                            Code
                        </label>
                        <span className='prose-sm text-xs'>{data?.code}</span>
                    </div>

                    <div className='prose flex flex-col'>
                        <label className='prose-slate text-xs font-bold'>
                            Unit of Measurement
                        </label>
                        <span className='prose-sm text-xs'>
                            {data?.defaultUnitOfMeasurement
                                ? ProductUomLabels[
                                      data?.defaultUnitOfMeasurement as ProductUom
                                  ]
                                : '--'}
                        </span>
                    </div>

                    <div className='prose flex flex-col'>
                        <label className='prose-slate text-xs font-bold'>
                            Industry
                        </label>
                        <span className='prose-sm text-xs'>
                            {data?.industry
                                ? IndustryTypeLabels[
                                      data.industry as IndustryType
                                  ]
                                : '--'}
                        </span>
                    </div>
                </div>

                <div className='col-span-1 grid grid-cols-1 gap-4'>
                    <div className='prose flex flex-col'>
                        <label className='prose-slate text-xs font-bold'>
                            Description
                        </label>
                        <span className='prose-sm text-xs'>
                            {data?.description || '--'}
                        </span>
                    </div>

                    <div className='prose flex flex-col'>
                        <label className='prose-slate text-xs font-bold'>
                            Workspace
                        </label>
                        <span className='prose-sm text-xs'>
                            {data?.workspace.name || '--'}
                        </span>
                    </div>

                    <div className='prose flex flex-col'>
                        <label className='prose-slate text-xs font-bold'>
                            Operations
                        </label>
                        <span className='prose-sm text-xs'>
                            {data?.processes?.join(', ') || '--'}
                        </span>
                    </div>

                    <div></div>
                </div>

                <div className='col-start-4 items-end justify-self-end'>
                    <Button
                        variant={'ghost'}
                        size='sm'
                        onClick={() => setIsEdit(true)}
                        data-testid='edit-category-button'
                    >
                        <Edit size={16} />
                    </Button>

                    <Button
                        variant={'ghost'}
                        size='sm'
                        onClick={() => setIsDelete(true)}
                        disabled={memoizedProducts.length > 0}
                        data-testid='delete-category-button'
                    >
                        <Trash size={16} />
                    </Button>
                </div>
            </div>

            <div className='flex flex-col gap-2'>
                <h3 className='text-lg font-medium'>Available Products</h3>

                <div className='flex flex-wrap gap-4'>
                    {memoizedProducts.map((x) => (
                        <div className='flex max-h-[120px] min-w-[300px] max-w-[300px] gap-2 rounded-lg border bg-white p-4'>
                            <div>
                                <img
                                    className='max-h-[80px] min-h-[80px] min-w-[80px] max-w-[80px] rounded-l p-1'
                                    src={BlankImage}
                                    alt=''
                                />
                            </div>

                            <div className='flex flex-col gap-2 overflow-hidden pt-2'>
                                <div className='flex flex-col'>
                                    <span
                                        data-testid='view-product-button'
                                        onClick={() =>
                                            navigateToProducts(x._id || '')
                                        }
                                        className='cursor-pointer truncate text-[10px] font-bold text-blue-600'
                                    >
                                        {x.name}
                                    </span>

                                    <span className='truncate text-[10px] text-gray-400'>
                                        {x._id || 'N/a'}
                                    </span>
                                </div>

                                <div className='flex flex-wrap gap-2 overflow-auto'>
                                    {x.workspace?.operations?.map((x) => (
                                        <Badge
                                            variant='outline'
                                            className='px-1 py-0 text-[8px]'
                                        >
                                            {x}
                                        </Badge>
                                    ))}
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>

            {isEdit && data && (
                <EditCategory
                    open={isEdit}
                    data={data}
                    onClose={() => setIsEdit(false)}
                />
            )}

            {isDelete && (
                <DeleteDialog
                    open={isDelete}
                    label='Category'
                    onClose={() => setIsDelete(false)}
                    onSubmit={deleteCategory}
                    navigationOptions={{
                        enabled: true,
                        path: '/categories',
                    }}
                />
            )}
        </div>
    );
};
