import { ServiceConfigInterface } from 'src/domain/interfaces/config.context.interface';
import { MaterialApiClient } from './material.client';
import { ProductMaterialDataObject } from './request/product-material-data-object';

export class MaterialService {
    private server: MaterialApiClient;

    constructor(config: ServiceConfigInterface, token: string) {
        this.server = new MaterialApiClient(config, token);
    }

    getCategories() {
        console.log(`service<material>| getCategories(): Enter`);

        return this.server.getCategories();
    }

    getMaterialsByCategories(category: string) {
        console.log(`service<material>| getMaterialsByCategories(): Enter`);
        console.log(
            `service<material>| getMaterialsByCategories(): $category = ${category}`
        );

        return this.server.getMaterialsByCategories(category);
    }

    list() {
        console.log(`service<material>| list(): Enter`);

        return this.server.list();
    }

    create(workspace: string, body: any) {
        console.log(`service<material>| create(): Enter`);
        console.log(`service<material>| create(): $workspace = ${workspace}`);
        console.log(
            `service<material>| create(): $body = ${JSON.stringify(body)}`
        );

        return this.server.create(workspace, body);
    }

    listByWorkspace(workspace: string) {
        console.log(`service<material>| listByWorkspace(): Enter`);
        console.log(
            `service<material>| listByWorkspace(): $workspace = ${workspace}`
        );

        return this.server.listByWorkspace(workspace);
    }

    remove(workspace: string, item: string) {
        console.log(`service<material>| remove(): Enter`);
        console.log(`service<material>| remove(): $workspace = ${workspace}`);
        console.log(`service<material>| remove(): $item = ${item}`);

        return this.server.remove(workspace, item);
    }

    update(workspace: string, item: string, body: any) {
        console.log(`service<material>| update(): Enter`);
        console.log(`service<material>| update(): $workspace = ${workspace}`);
        console.log(`service<material>| update(): $item = ${item}`);
        console.log(
            `service<material>| update(): $body = ${JSON.stringify(body)}`
        );

        return this.server.update(workspace, item, body);
    }

    get(workspace: string, item: string) {
        console.log(`service<material>| get(): Enter`);
        console.log(`service<material>| get(): $workspace = ${workspace}`);
        console.log(`service<material>| get(): $item = ${item}`);

        return this.server.get(workspace, item);
    }

    clone(workspace: string, item: string, body: ProductMaterialDataObject) {
        console.log(`service<material>| clone(): Enter`);
        console.log(`service<material>| clone(): $workspace = ${workspace}`);
        console.log(`service<material>| clone(): $item = ${item}`);
        console.log(
            `service<material>| clone(): $body = ${JSON.stringify(body)}`
        );

        return this.server.clone(workspace, item, body);
    }
}
