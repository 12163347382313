import { ColumnDef } from '@tanstack/react-table';
import { Edit2, InfoIcon, PlusIcon, Trash } from 'lucide-react';
import { useMemo, useState } from 'react';
import {
    ProductBillOfMaterialEntry,
    ProductModel,
    UsageNatureLabels,
} from 'src/app/_api_adb2c/product/product/models/product.model';
import {
    ProductUom,
    ProductUomLabels,
} from 'src/app/_api_adb2c/purchase/purchase/enums/product-uom.enum';
import { TableV2 } from 'src/app/components-v2/table-v2';
import { TooltipWrapper } from 'src/app/components/TooltipWrapper';
import { AddProductMaterialV3 } from './add-product-material-v3';
import { DeleteProductMaterial } from './delete-product-material';
import { EditProductMaterial } from './edit-product-material';
import { EditBaseMaterial } from './edit-base-material';

interface Props {
    data?: ProductModel;
}

export const ProductBillOfMaterials = ({ data }: Props) => {
    const [isCreate, setIsCreate] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [isDelete, setIsDelete] = useState(false);
    const [selectedRow, setSelectedRow] =
        useState<ProductBillOfMaterialEntry>();
    const [isEditBase, setIsEditBase] = useState(false);

    const memoizedData = useMemo<ProductBillOfMaterialEntry[]>(() => {
        const version = data?.versions?.[data.versions.length - 1];

        if (!version) return [];

        return version.billOfMaterials || [];
    }, [data]);

    console.debug('memoizedData', memoizedData);

    const columns: ColumnDef<ProductBillOfMaterialEntry>[] = [
        {
            id: 'component',
            header: 'Component',
            accessorFn: (row) => {
                return row.name;
            },
        },
        {
            id: 'description',
            header: 'Description',
            accessorFn: (row) => {
                return row?.description ?? row?.material?.description;
            },
        },
        {
            id: 'consumption',
            header: 'Consumption',
            accessorFn: (row) => {
                return row.consumption;
            },
        },
        {
            id: 'uom',
            header: 'UOM',
            accessorFn: (row) => {
                return row.unitOfMeasurement
                    ? ProductUomLabels[row.unitOfMeasurement as ProductUom]
                    : '';
            },
        },
        {
            id: 'weight',
            header: 'Weight (Kg / Unit)',
            accessorFn: (row) => {
                return row.weight;
            },
        },
        {
            id: 'cost',
            header: 'Cost (USD / Unit)',
            accessorFn: (row) => {
                return row.materialCost;
            },
        },
        {
            id: 'usage',
            header: 'Usage',
            accessorFn: (row) => {
                return row.usageNature
                    ? UsageNatureLabels[row.usageNature]
                    : '--';
            },
        },
        {
            id: 'actions',
            header: 'Actions',
            cell: ({ row }) => {
                const isCloned = row.original.isCloned;
                const isBaseMaterial = !row.original.resource;

                return (
                    <>
                        {!isCloned && (
                            <div className='flex items-center gap-4'>
                                <Edit2
                                    size={16}
                                    className='hover:scale-125'
                                    onClick={() => {
                                        if (isBaseMaterial) {
                                            setIsEditBase(true);
                                        } else {
                                            setIsEdit(true);
                                        }
                                        setSelectedRow(row.original);
                                    }}
                                />

                                <Trash
                                    size={16}
                                    className='hover:scale-125'
                                    onClick={() => {
                                        setIsDelete(true);
                                        setSelectedRow(row.original);
                                    }}
                                />
                            </div>
                        )}

                        {isCloned && (
                            <>
                                <TooltipWrapper label='Unable to modify a cloned material'>
                                    <InfoIcon
                                        size={16}
                                        className='hover:scale-125'
                                    />
                                </TooltipWrapper>
                            </>
                        )}
                    </>
                );
            },
        },
    ];

    if (!data) {
        return <></>;
    }

    return (
        <>
            <div className='flex h-full flex-col'>
                <TableV2
                    columns={columns}
                    data={memoizedData}
                    label='Bill of Materials'
                    tableContainerClassName='h-full'
                    groupBy='usageNature'
                    groupLabels={{
                        PRODUCT: 'Products',
                        PACKAGING: 'Packaging',
                    }}
                    actions={[
                        {
                            id: 'add-component',
                            label: 'Add Component',
                            icon: <PlusIcon size={16} />,
                            onClick: () => setIsCreate(true),
                        },
                    ]}
                />
            </div>

            <AddProductMaterialV3
                open={isCreate}
                productId={data._id}
                onClose={() => setIsCreate(false)}
            />

            {isDelete && selectedRow && (
                <DeleteProductMaterial
                    open={isDelete}
                    product={data}
                    data={selectedRow}
                    onClose={() => {
                        setIsDelete(false);
                        setSelectedRow(undefined);
                    }}
                />
            )}

            {isEditBase && selectedRow && !selectedRow.resource && (
                <EditBaseMaterial
                    open={isEditBase}
                    materialId={selectedRow._id}
                    onClose={() => {
                        setIsEditBase(false);
                        setSelectedRow(undefined);
                    }}
                    productId={data._id}
                    bomData={selectedRow}
                />
            )}

            {isEdit && selectedRow && selectedRow.resource && (
                <EditProductMaterial
                    open={isEdit}
                    product={data}
                    data={selectedRow}
                    onClose={() => {
                        setIsEdit(false);
                        setSelectedRow(undefined);
                    }}
                />
            )}
        </>
    );
};
