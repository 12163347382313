import { RedirectRequest } from '@azure/msal-browser';
import { useMsal } from '@azure/msal-react';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';
import Logo from 'src/assets/lf-logo.png';
import OnboardingLogo from 'src/assets/onboarding.svg';
import { AspectRatio } from 'src/components/ui/aspect-ratio';
import { Button } from 'src/components/ui/button';
import { Form } from 'src/components/ui/form';
import { z } from 'zod';
import { TextInput } from '../components/Form/TextInput';

const formSchema = z.object({
    email: z.string().email(),
});

export const LoginForm = () => {
    const [searchParams] = useSearchParams();
    const { instance } = useMsal();

    const predeterminedWorkspace = searchParams.get('workspace');

    const form = useForm<z.infer<typeof formSchema>>({
        mode: 'onChange',
        resolver: zodResolver(formSchema),
    });

    const onSubmit = async (data: z.infer<typeof formSchema>) => {
        const request: RedirectRequest = {
            loginHint: data.email,
            scopes: ['openid'],
        };

        if (predeterminedWorkspace) {
            sessionStorage.setItem(
                'predeterminedWorkspace',
                predeterminedWorkspace
            );
        }

        await instance.loginRedirect(request);
    };

    return (
        <div className='h-screen w-full lg:grid lg:min-h-[600px] lg:grid-cols-2 xl:min-h-[800px]'>
            <div className='hidden dark:bg-gray-800 lg:block'>
                <div className='flex h-full flex-1 flex-col justify-center overflow-hidden'>
                    <div className='m-4 w-44'>
                        <AspectRatio ratio={16 / 9}>
                            <img
                                alt='Loading...'
                                src={Logo}
                                className='rounded-md object-cover'
                            />
                        </AspectRatio>
                    </div>

                    <div className='flex flex-col items-center justify-center'>
                        <div className='w-[500px]'>
                            <AspectRatio ratio={2 / 2} className=''>
                                <img
                                    alt='Loading...'
                                    src={OnboardingLogo}
                                    className='rounded-md object-cover'
                                />
                            </AspectRatio>
                        </div>
                    </div>
                </div>
            </div>
            <div className='flex items-center justify-center py-12'>
                <div className='mx-auto w-[350px] space-y-6'>
                    <div className='space-y-2 text-center'>
                        <h1 className='text-3xl font-bold'>Login</h1>
                        <p className='text-gray-500 dark:text-gray-400'>
                            Enter your email below to login to your account
                        </p>
                    </div>
                    <div className='space-y-4'>
                        <Form {...form}>
                            <form
                                id='my-form'
                                onSubmit={form.handleSubmit(onSubmit)}
                            >
                                <TextInput
                                    size='sm'
                                    label='Email'
                                    inputName='email'
                                />
                            </form>
                        </Form>

                        <Button className='w-full' type='submit' form='my-form'>
                            Login
                        </Button>

                        <Button
                            className='w-full'
                            variant='outline'
                            onClick={() => {
                                instance.loginRedirect({
                                    scopes: ['openid'],
                                    loginHint: form.getValues('email'),
                                    prompt: 'login',
                                });
                            }}
                        >
                            Login with Google
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
};
