import { useContextStore } from 'src/app/stores/context-store';
import { useMaterialService } from './use-material-service';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { toast } from 'sonner';
import { ProductMaterialDataObject } from '../request/product-material-data-object';
import { MATERIALS_QUERY_KEY } from './material-query-keys';

export function useCreateMaterial() {
    const queryClient = useQueryClient();
    const { service } = useMaterialService();
    const context = useContextStore();

    return useMutation({
        mutationFn: async ({
            body,
            delegateId,
        }: {
            body: ProductMaterialDataObject;
            delegateId?: string;
        }) => {
            return await service.create(context.workspace?._id || '', body);
        },
        onSuccess: (_, { delegateId }) => {
            queryClient.invalidateQueries(
                MATERIALS_QUERY_KEY(delegateId || context.workspace?._id || '')
            );

            toast.success('Material created successfully');
        },
        onError: (err) => {
            const error = err as Error;

            toast.error(`Failed to create material: ${error.message}`);
        },
    });
}
