import { ServiceConfigInterface } from 'src/domain/interfaces/config.context.interface';
import { ApiServer } from 'src/infrastructure/clients/api.server';

export class PurchaseStatisticsClient extends ApiServer {
    constructor(config: ServiceConfigInterface, token: string) {
        super(token, config, 'purchases', 'purchase-statistics');
    }

    list(workspace: string) {
        console.log(`client<purchase-statistics>| list(): Enter`);
        console.log(
            `client<purchase-statistics>| list(): $workspace = ${workspace}`
        );

        return super.get('list', workspace);
    }

    get(workspace: string, item: string) {
        console.log(`client<purchase-statistics>| get(): Enter`);
        console.log(
            `client<purchase-statistics>| get(): $workspace = ${workspace}`
        );
        console.log(`client<purchase-statistics>| get(): $item = ${item}`);

        return super.get('get', workspace, item);
    }

    getStatisticsByWorkspace(
        workspace: string,
        startDate: string,
        endDate: string
    ) {
        console.log(
            `client<purchase-statistics>| getStatisticsByWorkspace(): Enter`
        );
        console.log(
            `client<purchase-statistics>| getStatisticsByWorkspace(): $workspace = ${workspace}`
        );
        console.log(
            `client<purchase-statistics>| getStatisticsByWorkspace(): $startDate = ${startDate}`
        );
        console.log(
            `client<purchase-statistics>| getStatisticsByWorkspace(): $endDate = ${endDate}`
        );

        return super.get(
            'getStatisticsByWorkspace',
            workspace,
            startDate,
            endDate
        );
    }

    getSubstanceOrderInformation(
        workspace: string,
        substanceName: string,
        substanceCode: string
    ) {
        console.log(
            `client<purchase-statistics>| getSubstanceOrderInformation(): Enter`
        );
        console.log(
            `client<purchase-statistics>| getSubstanceOrderInformation(): $workspace = ${workspace}`
        );
        console.log(
            `client<purchase-statistics>| getSubstanceOrderInformation(): $substanceCode = ${substanceCode}`
        );
        console.log(
            `client<purchase-statistics>| getSubstanceOrderInformation(): $substanceName = ${substanceName}`
        );

        return super.get(
            'getSubstanceOrderInformation',
            workspace,
            substanceName,
            substanceCode
        );
    }
}
