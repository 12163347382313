import { CaretSortIcon } from '@radix-ui/react-icons';
import { ColumnDef } from '@tanstack/react-table';
import { Check, Edit, Plus, Trash, X } from 'lucide-react';
import { useState } from 'react';
import { useCreateTemplate } from 'src/app/_api_adb2c/workspace/template/hooks/use-create-template';
import { useRemoveTemplate } from 'src/app/_api_adb2c/workspace/template/hooks/use-remove-template';
import { useTemplates } from 'src/app/_api_adb2c/workspace/template/hooks/use-templates';
import { useUpdateTemplate } from 'src/app/_api_adb2c/workspace/template/hooks/use-update-template';
import { TemplateCreateDataObject } from 'src/app/_api_adb2c/workspace/template/template-create-data-object';
import { WorkspaceTemplateVirtualModel } from 'src/app/_api_adb2c/workspace/template/template.model';
import { Button } from 'src/components/ui/button';
import { Checkbox } from 'src/components/ui/checkbox';
import { ReusableTable } from '../../Purchases/reusable-table';
import { CreateTemplate } from './create-template';
import { DeleteTemplate } from './delete-template';
import { EditTemplate } from './edit-template';
import { useSearchParams } from 'react-router-dom';

export const WorkspaceTemplates = () => {
    const [searchParams] = useSearchParams();
    const delegateId = searchParams.get('delegateId') || '';

    const { data } = useTemplates(delegateId);
    const [rowSelection, setRowSelection] = useState({});

    const [isCreate, setIsCreate] = useState(false);
    const [isUpdate, setIsUpdate] = useState(false);
    const [isDelete, setIsDelete] = useState(false);
    const [template, setTemplate] = useState<WorkspaceTemplateVirtualModel>();

    const { mutateAsync: createTemplate } = useCreateTemplate();
    const { mutateAsync: editTemplate } = useUpdateTemplate();
    const { mutateAsync: deleteTemplate } = useRemoveTemplate();

    const onCreate = async (template: TemplateCreateDataObject) => {
        await createTemplate({
            body: template,
            template: template.code,
        });

        reset();
    };

    const onEdit = async (template: TemplateCreateDataObject) => {
        await editTemplate({
            id: template.code,
            body: {
                ...template,
            },
        });

        reset();
    };

    const onDelete = async (template: string) => {
        await deleteTemplate({
            item: template,
        });

        reset();
    };

    const reset = () => {
        setIsUpdate(false);
        setIsDelete(false);
        setTemplate(undefined);
    };

    const columns: ColumnDef<WorkspaceTemplateVirtualModel>[] = [
        {
            id: 'select',
            header: ({ table }) => (
                <Checkbox
                    checked={table.getIsAllPageRowsSelected()}
                    onCheckedChange={(value) =>
                        table.toggleAllPageRowsSelected(!!value)
                    }
                    aria-label='Select all'
                />
            ),
            cell: ({ row }) => (
                <Checkbox
                    checked={row.getIsSelected()}
                    onCheckedChange={(value) => row.toggleSelected(!!value)}
                    aria-label='Select row'
                />
            ),
            enableSorting: false,
            enableHiding: false,
            size: 40,
        },
        {
            id: 'name',
            accessorKey: 'name',
            accessorFn: (row) =>
                row.adaptation?.localisations?.[0].subject || row.base?.subject,
            header: ({ column }) => {
                return (
                    <Button
                        variant='ghost'
                        className='text-xs'
                        onClick={() =>
                            column.toggleSorting(column.getIsSorted() === 'asc')
                        }
                    >
                        Name
                        <CaretSortIcon
                            className={`inline ${
                                column.getIsSorted() === 'asc'
                                    ? 'rotate-180 transform'
                                    : ''
                            }`}
                        />
                    </Button>
                );
            },
        },
        {
            id: 'code',
            accessorKey: 'code',
            accessorFn: (row) => row.adaptation?.template || row.base?.template,
            header: ({ column }) => {
                return (
                    <Button
                        variant='ghost'
                        className='text-xs'
                        onClick={() =>
                            column.toggleSorting(column.getIsSorted() === 'asc')
                        }
                    >
                        Code
                        <CaretSortIcon
                            className={`inline ${
                                column.getIsSorted() === 'asc'
                                    ? 'rotate-180 transform'
                                    : ''
                            }`}
                        />
                    </Button>
                );
            },
        },
        {
            id: 'adaptation',
            cell: ({ row }) => {
                return row.original.adaptation ? (
                    <Check className='h-4 pl-4' />
                ) : (
                    <X className='h-4 pl-4' />
                );
            },
            enableSorting: false,
            header: ({ column }) => {
                return (
                    <Button
                        variant='ghost'
                        className='text-xs'
                        onClick={() =>
                            column.toggleSorting(column.getIsSorted() === 'asc')
                        }
                    >
                        Adaptation
                        {column.getCanSort() && column.getIsSorted() && (
                            <CaretSortIcon
                                className={`ml-2 h-4 w-4 ${
                                    column.getIsSorted() === 'asc'
                                        ? 'rotate-180 transform'
                                        : ''
                                }`}
                            />
                        )}
                    </Button>
                );
            },
        },
        {
            id: 'actions',
            enableSorting: false,
            header: ({ column }) => {
                return (
                    <Button variant='ghost' className='text-xs'>
                        Actions
                        {column.getCanSort() && column.getIsSorted() && (
                            <CaretSortIcon
                                className={`ml-2 h-4 w-4 ${
                                    column.getIsSorted() === 'asc'
                                        ? 'rotate-180 transform'
                                        : ''
                                }`}
                            />
                        )}
                    </Button>
                );
            },
            cell: ({ row }) => (
                <div className='flex'>
                    <Button
                        size='sm'
                        variant='ghost'
                        onClick={() => {
                            setIsUpdate(true);
                            setTemplate(row.original);
                        }}
                    >
                        <Edit className='h-4 w-4' />
                    </Button>

                    <Button
                        size='sm'
                        variant='ghost'
                        onClick={() => {
                            setIsDelete(true);
                            setTemplate(row.original);
                        }}
                    >
                        <Trash className='h-4 w-4' />
                    </Button>
                </div>
            ),
        },
    ];

    return (
        <div className='grid h-full grid-cols-1'>
            <ReusableTable
                title='Templates'
                columns={columns}
                data={data || []}
                loading={false}
                controls={{
                    enableSearch: true,
                }}
                rowSelection={rowSelection}
                setRowSelection={setRowSelection}
                actions={[
                    {
                        id: 'add',
                        icon: <Plus size={16} />,
                        onClick: () => setIsCreate(true),
                    },
                ]}
            />

            {isUpdate && template && (
                <EditTemplate
                    onSubmit={onEdit}
                    onClose={reset}
                    open={isUpdate}
                    template={template}
                />
            )}

            {isDelete && template && (
                <DeleteTemplate
                    template={template}
                    onSubmit={onDelete}
                    onClose={reset}
                    open={isDelete}
                />
            )}

            {isCreate && (
                <CreateTemplate
                    onSubmit={onCreate}
                    onClose={() => setIsCreate(false)}
                    open={isCreate}
                />
            )}
        </div>
    );
};
