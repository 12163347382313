import { ServiceConfigInterface } from 'src/domain/interfaces/config.context.interface';
import { AccountApiClient } from './account.client';
import { SolicitationCreateRequestObject } from './solicitation-create-request-object';

export class AccountService {
    private server: AccountApiClient;

    constructor(config: ServiceConfigInterface, token: string) {
        this.server = new AccountApiClient(config, token);
    }

    invite(
        workspace: string,
        body: SolicitationCreateRequestObject,
        diversion = '0'
    ) {
        console.log(`service<account>| invite(): Enter`);
        console.log(`service<account>| invite(): $workspace = ${workspace}`);
        console.log(
            `service<account>| invite(): $body = ${JSON.stringify(body)}`
        );

        return this.server.invite(workspace, diversion, body);
    }

    connect(
        workspace: string,
        supplier: string,
        delegate: string,
        diversion = '0'
    ) {
        console.log(`service<account>| connect(): Enter`);
        console.log(`service<account>| connect(): $workspace = ${workspace}`);
        console.log(`service<account>| connect(): $supplier = ${supplier}`);
        console.log(`service<account>| connect(): $delegate = ${delegate}`);

        return this.server.connect(workspace, supplier, delegate, diversion);
    }
}
