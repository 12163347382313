import { ServiceConfigInterface } from 'src/domain/interfaces/config.context.interface';
import { RulesetCreateDataObject } from './ruleset-create-data-object';
import { RulesetUpdateDataObject } from './ruleset-update-data-object';
import { RulesetApiClient } from './ruleset.client';
import { WorkspaceRulesetModel } from './ruleset.model';

export class RulesetService {
    private server: RulesetApiClient;

    constructor(config: ServiceConfigInterface, token: string) {
        this.server = new RulesetApiClient(config, token);
    }

    async create(workspace: string, body: RulesetCreateDataObject) {
        console.log(`service<ruleset>| create(): Enter`);
        console.log(`service<ruleset>| create(): $workspace = ${workspace}`);
        console.log(
            `service<ruleset>| create(): $body = ${JSON.stringify(body)}`
        );

        return await this.server.create(workspace, body);
    }

    async update(
        workspace: string,
        item: string,
        body: RulesetUpdateDataObject
    ) {
        console.log(`service<ruleset>| update(): Enter`);
        console.log(`service<ruleset>| update(): $workspace = ${workspace}`);
        console.log(`service<ruleset>| update(): $item = ${item}`);
        console.log(
            `service<ruleset>| update(): $body = ${JSON.stringify(body)}`
        );

        return await this.server.update(workspace, item, body);
    }

    async list(workspace: string) {
        console.log(`service<ruleset>| list(): Enter`);
        console.log(`service<ruleset>| list(): $workspace = ${workspace}`);

        return await this.server.list(workspace);
    }

    async get(workspace: string, item: string) {
        console.log(`service<ruleset>| get(): Enter`);
        console.log(`service<ruleset>| get(): $workspace = ${workspace}`);
        console.log(`service<ruleset>| get(): $item = ${item}`);

        return await this.server.get(workspace, item);
    }

    async remove(workspace: string, item: string) {
        console.log(`service<ruleset>| remove(): Enter`);
        console.log(`service<ruleset>| remove(): $workspace = ${workspace}`);
        console.log(`service<ruleset>| remove(): $item = ${item}`);

        return await this.server.remove(workspace, item);
    }

    async batch(body: { targets: string[] }): Promise<WorkspaceRulesetModel[]> {
        console.log(`service<ruleset>| batch(): Enter`);
        console.log(`service<ruleset>| batch(): $body = ${body}`);

        return await this.server.batch(body);
    }
}
