import { ServiceConfigInterface } from 'src/domain/interfaces/config.context.interface';
import { ApiServer } from 'src/infrastructure/clients/api.server';
import { TemplateCreateDataObject } from './template-create-data-object';

export class TemplateApiClient extends ApiServer {
    constructor(config: ServiceConfigInterface, token: string) {
        super(token, config, 'workspaces', 'template');
    }

    listBaseTemplates() {
        console.log(`service<template>| listBaseTemplates(): Enter`);

        return super.get('base');
    }

    list(workspace: string) {
        console.log(`service<template>| list(): Enter`);
        console.log(`service<template>| list(): $workspace = ${workspace}`);

        return super.get('list', workspace);
    }

    get(workspace: string, item: string) {
        console.log(`service<template>| get(): Enter`);
        console.log(`service<template>| get(): $workspace = ${workspace}`);
        console.log(`service<template>| get(): $item = ${item}`);

        return super.get('get', workspace, item);
    }

    create(
        workspace: string,
        template: string,
        body: TemplateCreateDataObject
    ) {
        console.log(`service<template>| create(): Enter`);
        console.log(`service<template>| create(): $workspace = ${workspace}`);
        console.log(
            `service<template>| create(): $body = ${JSON.stringify(body)}`
        );

        return super.post('create', workspace, template, body);
    }

    update(workspace: string, item: string, body: TemplateCreateDataObject) {
        console.log(`service<template>| update(): Enter`);
        console.log(`service<template>| update(): $workspace = ${workspace}`);
        console.log(`service<template>| update(): $item = ${item}`);
        console.log(
            `service<template>| update(): $body = ${JSON.stringify(body)}`
        );

        return super.put('update', workspace, item, body);
    }

    getTemplateParameters(workspace: string, item: string) {
        console.log(`service<template>| getTemplateParameters(): Enter`);
        console.log(
            `service<template>| getTemplateParameters(): $workspace = ${workspace}`
        );
        console.log(
            `service<template>| getTemplateParameters(): $item = ${item}`
        );

        return super.get('parameters', workspace, item);
    }

    remove(workspace: string, item: string) {
        console.log(`service<template>| remove(): Enter`);
        console.log(`service<template>| remove(): $workspace = ${workspace}`);
        console.log(`service<template>| remove(): $item = ${item}`);

        return super.delete('delete', workspace, item);
    }
}
