import { ServiceConfigInterface } from 'src/domain/interfaces/config.context.interface';
import { CategoryApiClient } from './category.client';
import { ProductCategoryCreateDataObject } from './product-category-create-data-object';
import { ProductCategoryUpdateDataObject } from './product-category-update-data-object';

export class CategoryService {
    private server: CategoryApiClient;

    constructor(config: ServiceConfigInterface, token: string) {
        this.server = new CategoryApiClient(config, token);
    }

    create(
        workspace: string,
        body: ProductCategoryCreateDataObject,
        diversion: string = '0'
    ) {
        console.log(`service<category>| create(): Enter`);
        console.log(`service<category>| create(): $workspace = ${workspace}`);
        console.log(
            `service<category>| create(): $body = ${JSON.stringify(body)}`
        );
        console.log(`service<category>| create(): $diversion = ${diversion}`);
        return this.server.create(workspace, diversion, body);
    }

    get(workspace: string, item: string) {
        console.log(`service<category>| get(): Enter`);
        console.log(`service<category>| get(): $workspace = ${workspace}`);
        console.log(`service<category>| get(): $item = ${item}`);

        return this.server.get(workspace, item);
    }

    remove(workspace: string, item: string) {
        console.log(`service<category>| remove(): Enter`);
        console.log(`service<category>| remove(): $workspace = ${workspace}`);
        console.log(`service<category>| remove(): $item = ${item}`);

        return this.server.remove(workspace, item);
    }

    update(
        workspace: string,
        item: string,
        body: ProductCategoryUpdateDataObject
    ) {
        console.log(`service<category>| update(): Enter`);
        console.log(`service<category>| update(): $workspace = ${workspace}`);
        console.log(`service<category>| update(): $item = ${item}`);
        console.log(
            `service<category>| update(): $body = ${JSON.stringify(body)}`
        );

        return this.server.update(workspace, item, body);
    }

    loadCategories(workspace: string) {
        console.log(`service<category>| loadCategories(): Enter`);
        console.log(
            `service<category>| loadCategories(): $workspace = ${workspace}`
        );

        return this.server.loadCategories(workspace);
    }

    search(workspace: string, query: string, diversion: string = '0') {
        console.log(`service<category>| search(): Enter`);
        console.log(`service<category>| search(): $workspace = ${workspace}`);
        console.log(`service<category>| search(): $query = ${query}`);
        console.log(`service<category>| search(): $diversion = ${diversion}`);
        return this.server.search(workspace, query, diversion);
    }

    getCustomCategoryProducts(workspace: string, category: string) {
        console.log(`service<category>| getCustomCategoryProducts(): Enter`);
        console.log(
            `service<category>| getCustomCategoryProducts(): $workspace = ${workspace}`
        );
        console.log(
            `service<category>| getCustomCategoryProducts(): $category = ${category}`
        );

        return this.server.getCustomCategoryProducts(workspace, category);
    }

    loadBaseCategories(base: string) {
        console.log(`service<category>| loadBaseCategories(): Enter`);
        console.log(`service<category>| loadBaseCategories(): $base = ${base}`);

        return this.server.loadBaseCategories(base);
    }

    async searchByCode(workspace: string, code: string) {
        console.log(`service<category>| searchByCode(): Enter`);
        console.log(
            `service<category>| searchByCode(): $workspace = ${workspace}`
        );
        console.log(`service<category>| searchByCode(): $code = ${code}`);
        return await this.server.searchByCode(workspace, code);
    }
}
