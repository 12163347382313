import { ColumnDef } from '@tanstack/react-table';
import { GanttChartSquare } from 'lucide-react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { DateTime } from 'luxon';
import { useNavigate } from 'react-router-dom';
import { usePurchaseStatisticsService } from 'src/app/_api_adb2c/purchase/statistics/hooks/use-purchase-statistics-service';
import { useContextStore } from 'src/app/stores/context-store';
import { ReusableTable } from '../../Purchases/reusable-table';

interface Item {
    category: string[];
    cumulativeWeight: number;
    purchaseOrders: { purchaseOrderId: string; tier: number }[];
    substanceName: string;
    substanceCode: string;
    shippedOn: string[];
}

export function SubstanceReportTableV2() {
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const context = useContextStore();

    const [items, setItems] = useState<Item[]>([]);
    const [isLoading, setIsLoading] = useState(false);

    const startDate = searchParams.get('startDate') || '';
    const endDate = searchParams.get('endDate') || '';
    const thresholdWeight = parseFloat(
        searchParams.get('thresholdWeight') || '0'
    );
    const category = searchParams.get('category') || '';
    const substanceName = searchParams.get('substanceName') || '';
    const casNumber = searchParams.get('casNumber') || '';

    const { service } = usePurchaseStatisticsService();

    const fetchStatistics = useCallback(async () => {
        if (!context.workspace) return;
        if (!startDate || !endDate) return;

        setIsLoading(true);

        const response: Item[] = await service.getStatisticsByWorkspace(
            context.workspace?._id || '',
            startDate || DateTime.now().startOf('month').toISO(),
            endDate || DateTime.now().endOf('month').toISO()
        );

        setItems(response);
        setIsLoading(false);
    }, [service, startDate, endDate, context.workspace]);

    const filteredItems = useMemo(() => {
        let filteredItems = items;
        if (thresholdWeight > 0) {
            filteredItems = filteredItems.filter((item) => {
                return item.cumulativeWeight >= thresholdWeight;
            });
        }

        if (category) {
            filteredItems = filteredItems.filter((item) => {
                return item.category.includes(category);
            });
        }

        if (substanceName) {
            filteredItems = filteredItems.filter((item) => {
                return item.substanceName.includes(substanceName);
            });
        }

        if (casNumber) {
            filteredItems = filteredItems.filter((item) => {
                return item.substanceCode.includes(casNumber);
            });
        }

        return filteredItems;
    }, [items, category, substanceName, thresholdWeight, casNumber]);

    useEffect(() => {
        fetchStatistics();
    }, [fetchStatistics]);

    const columns: ColumnDef<Item>[] = useMemo(() => {
        return [
            {
                header: 'Substance',
                // @ts-ignore
                accessorFn: (row) => row.substanceName || row.name || '--',
            },
            {
                header: 'CAS',
                accessorFn: (row) => row.substanceCode,
            },
            {
                header: 'Weight',
                accessorFn: (row) => row.cumulativeWeight.toFixed(2),
            },
            {
                header: 'Category',
                accessorFn: (row) => row.category.join(', '),
            },
            {
                header: 'Shipped On',
                accessorFn: (row) => row.shippedOn?.[0] || '--',
            },
            {
                id: 'actions',
                header: 'Actions',
                cell: ({ row }) => {
                    return (
                        <div className='flex gap-2'>
                            <GanttChartSquare
                                size={16}
                                className='cursor-pointer hover:scale-125'
                                onClick={() => {
                                    const parsedName = encodeURIComponent(
                                        row.original.substanceName
                                    );
                                    const parsedCas = encodeURIComponent(
                                        row.original.substanceCode || '--'
                                    );

                                    navigate(
                                        `/report/substances/${parsedName}/${parsedCas}`
                                    );
                                }}
                            />
                        </div>
                    );
                },
            },
        ];
    }, [navigate]);

    return (
        <ReusableTable
            title='Substance Report'
            loading={isLoading}
            columns={columns}
            data={filteredItems}
        />
    );
}
