export enum SupportDocType {
    AUDIT_REPORT = 'AUDIT_REPORT',
    BANK_CERTIFICATE = 'BANK_CERTIFICATE',
    BUSINESS_REGISTRATION = 'BUSINESS_REGISTRATION',
    BILL_OF_LADING = 'BILL_OF_LADING',
    CERTIFICATE_OF_ORIGIN = 'CERTIFICATE_OF_ORIGIN',
    CERTIFICATION_RECORD = 'CERTIFICATION_RECORD',
    CERTIFICATION_AWARD = 'CERTIFICATION_AWARD',
    COMMERCIAL_INVOICE = 'COMMERCIAL_INVOICE',
    CONTRACT = 'CONTRACT',
    CUSTOM_CLEARANCE = 'CUSTOM_CLEARANCE',
    DISTRIBUTION_LICENSE = 'DISTRIBUTION_LICENSE',
    EXPORT_LICENSE = 'EXPORT_LICENSE',
    IMPORT_LICENSE = 'IMPORT_LICENSE',
    PROOF_OF_ADDRESS = 'PROOF_OF_ADDRESS',
    TAX_PAYMENT = 'TAX_PAYMENT',
    FACTORY_PROFILE = 'FACTORY_PROFILE',
    GEO_TAG = 'GEO_TAG',
    SEGREGATION_SUPPORT = 'SEGREGATION_SUPPORT',
    DELIVERY_NOTE = 'DELIVERY_NOTE',
    INVOICE = 'INVOICE',
    MAP_SCREENSHOT = 'MAP_SCREENSHOT',
    PACKING_LIST = 'PACKING_LIST',
    PAYMENT_VOUCHER = 'PAYMENT_VOUCHER',
    PURCHASE_CONTRACT = 'PURCHASE_CONTRACT',
    PRODUCTION_RECORD = 'PRODUCTION_RECORD',
    PRODUCT_SPECIFICATIONS = 'PRODUCT_SPECIFICATIONS',
    TRANPORTATION_INVOICE = 'TRANPORTATION_INVOICE',
    WAREHOUSE_RECORD = 'WAREHOUSE_RECORD',
    PRODUCT_CERTIFICATE = 'PRODUCT_CERTIFICATE',
    OTHER = 'OTHER',
    SAFETY_DATA_SHEET = 'SAFETY_DATA_SHEET',
}

export const SupportDocTypeLabel = {
    [SupportDocType.AUDIT_REPORT]: 'Audit Report',
    [SupportDocType.BANK_CERTIFICATE]: 'Bank Certificate',
    [SupportDocType.BUSINESS_REGISTRATION]: 'Business Registration',
    [SupportDocType.BILL_OF_LADING]: 'Bill of Lading',
    [SupportDocType.CERTIFICATE_OF_ORIGIN]: 'Certificate of Origin',
    [SupportDocType.CERTIFICATION_AWARD]: 'Certification Award',
    [SupportDocType.CERTIFICATION_RECORD]: 'Certification Record',
    [SupportDocType.COMMERCIAL_INVOICE]: 'Commercial Invoice',
    [SupportDocType.CONTRACT]: 'Contract',
    [SupportDocType.CUSTOM_CLEARANCE]: 'Custom Clearance',
    [SupportDocType.DISTRIBUTION_LICENSE]: 'Distribution License',
    [SupportDocType.EXPORT_LICENSE]: 'Export License',
    [SupportDocType.IMPORT_LICENSE]: 'Import License',
    [SupportDocType.PROOF_OF_ADDRESS]: 'Proof of Address',
    [SupportDocType.TAX_PAYMENT]: 'Tax Payment',
    [SupportDocType.FACTORY_PROFILE]: 'Factory Profile',
    [SupportDocType.GEO_TAG]: 'Geo Tag',
    [SupportDocType.SEGREGATION_SUPPORT]: 'Segregation Support',
    [SupportDocType.DELIVERY_NOTE]: 'Delivery Note',
    [SupportDocType.INVOICE]: 'Invoice',
    [SupportDocType.MAP_SCREENSHOT]: 'Map Screenshot',
    [SupportDocType.PACKING_LIST]: 'Packing List',
    [SupportDocType.PAYMENT_VOUCHER]: 'Payment Voucher',
    [SupportDocType.PURCHASE_CONTRACT]: 'Purchase Contract',
    [SupportDocType.PRODUCTION_RECORD]: 'Production Record',
    [SupportDocType.PRODUCT_SPECIFICATIONS]: 'Product Specifications',
    [SupportDocType.TRANPORTATION_INVOICE]: 'Transportation Invoice',
    [SupportDocType.WAREHOUSE_RECORD]: 'Warehouse Record',
    [SupportDocType.PRODUCT_CERTIFICATE]: 'Product Certificate',
    [SupportDocType.OTHER]: 'Other',
    [SupportDocType.SAFETY_DATA_SHEET]: 'Safety Data Sheet',
};
