export const BASE_TEMPLATES_QUERY_KEY = () => ['base-templates'];

export const TEMPLATES_QUERY_KEY = (workspace: string) => [
    'templates',
    workspace,
];

export const TEMPLATE_QUERY_KEY = (workspace: string, template: string) => [
    'template',
    workspace,
    template,
];
