import { useMutation, useQueryClient } from '@tanstack/react-query';
import { toast } from 'sonner';
import { useContextStore } from 'src/app/stores/context-store';
import { ProductCategoryCreateDataObject } from '../product-category-create-data-object';
import { CATEGORIES_QUERY_KEY } from './category-query-keys';
import { useCategoryService } from './use-category-service';

export function useCreateCategory() {
    const { service } = useCategoryService();
    const client = useQueryClient();
    const context = useContextStore();

    return useMutation({
        mutationFn: async ({
            delegateId,
            body,
            diversion = '0',
        }: {
            delegateId?: string;
            body: ProductCategoryCreateDataObject;
            diversion?: string;
        }) => {
            return await service.create(
                delegateId || context.workspace?._id || '',
                body,
                diversion
            );
        },
        onSuccess: (_, { delegateId }) => {
            client.invalidateQueries(
                CATEGORIES_QUERY_KEY(delegateId || context.workspace?._id || '')
            );

            toast.success('Category created successfully');
        },
        onError: (err) => {
            const error = err as Error;

            toast.error(`Failed to create category: ${error.message}`);
        },
    });
}
