import { DocumentModel } from '../documents/document.model';
import { ContactModel } from '../shared/model/contact.model';

export enum CompanySourceType {
    DUNS = 'duns',
    GLN = 'gln',
    HIGG = 'higg',
    LEI = 'lei',
    LFX = 'lfx',
    OAR = 'oar',
    VAT = 'vat',
    XTS = 'xts',
    ZDHC = 'zdhc',
}

export interface CompanyModel {
    _id: string;
    account: AccountModel;
    createdOn: Date;
    compliance: CompanyComplianceModel[];
    demographics: CompanyDemographicsModel;
    documents: DocumentModel[];
    lastUpdatedOn: Date;
    deletedOn?: Date;
    links: CompanyProfileModel[];
    verifiedOn?: Date;
}

export interface AccountModel {
    _id: string;
    admins: string[];
    admin?: string;
    createdOn: Date;
    deletedOn: Date;
    lastUpdatedOn: Date;
    name: string;
}

export interface CompanyComplianceModel {
    _id: string;
    expiresOn?: Date;
    rating: string;
    source: string;
}

export interface CompanyDemographicsModel {
    _id: string;
    address: AddressModel;
    capacity?: number;
    complianceContact: ContactModel;
    dba: string;
    employees?: number;
    fax?: string;
    industry?: string;
    logo?: string;
    name: string;
    processes: string[];
    phone?: string;
    primaryContact: ContactModel;
    registrationNumber: string;
    since: string;
    website?: string;
    yearInOperation?: number;
}

export interface AddressModel {
    _id: string;
    building?: string;
    city?: string;
    country: string;
    floor?: string;
    geocode: GeocodeModel;
    postCode?: string;
    state?: string;
    street?: string;
    suburb?: string;
    unit?: string;
}

export interface GeocodeModel {
    _id: string;
    type: string;
    coordinates: number[];
}

export interface CompanyProfileModel {
    _id?: string;
    source: CompanySourceType;
    identity: string;
}
