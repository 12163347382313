import { useMutation, useQueryClient } from '@tanstack/react-query';
import { usePurchaseService } from './use-purchase-service';
import { useContextStore } from 'src/app/stores/context-store';
import { PURCHASES_QUERY_KEY, PURCHASE_QUERY_KEY } from './purchase-query-keys';
import { toast } from 'sonner';

export function useDeletePurchase() {
    const client = useQueryClient();
    const { service } = usePurchaseService();
    const context = useContextStore();

    return useMutation({
        mutationFn: async ({
            id,
            delegateId,
            diversion = '0',
        }: {
            id: string;
            delegateId?: string;
            diversion?: string;
        }) => {
            return await service.remove(
                delegateId || context.workspace?._id || '',
                id,
                diversion
            );
        },
        onSuccess: (_, { id, delegateId }) => {
            client.invalidateQueries(
                PURCHASE_QUERY_KEY(
                    delegateId || context.workspace?._id || '',
                    id
                )
            );

            client.invalidateQueries(
                PURCHASES_QUERY_KEY(delegateId || context.workspace?._id || '')
            );

            toast.success('Purchase order has been successfully deleted');
        },
        onError: (err) => {
            const error = err as Error;

            toast.error(`Failed to delete purchase order: ${error.message}`);
        },
    });
}
