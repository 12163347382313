import { ClearOutlined } from '@ant-design/icons';
import { useMemo } from 'react';
import { ControlButton } from 'reactflow';
import { useResetCascadeLink } from 'src/app/_api/purchase/links/hooks/use-reset-cascade-link';
import {
    Tooltip,
    TooltipContent,
    TooltipProvider,
    TooltipTrigger,
} from 'src/components/ui/tooltip';
import useAppContext from 'src/infrastructure/hooks/use-context.hook';

interface Props {
    id: string;
    workspaceId?: string;
}

export const PurchaseCascadeClearControl = ({ id, workspaceId }: Props) => {
    const context = useAppContext();
    const { mutateAsync } = useResetCascadeLink();

    const workspace = useMemo(() => {
        return workspaceId ? workspaceId : context.workspace?.id || '';
    }, [context.workspace?.id, workspaceId]);

    const handleClear = async () => {
        await mutateAsync({ workspace, item: id });
    };

    return (
        <ControlButton onClick={handleClear}>
            <TooltipProvider>
                <Tooltip>
                    <TooltipTrigger>
                        <ClearOutlined />
                    </TooltipTrigger>
                    <TooltipContent>Clear Cascade</TooltipContent>
                </Tooltip>
            </TooltipProvider>
        </ControlButton>
    );
};
