import { ServiceConfigInterface } from 'src/domain/interfaces/config.context.interface';
import { SalesOrderAttachDataObject } from './request/sales-order-attach-data-object';
import { SalesOrderDocCollectDataObject } from './request/sales-order-doc-collection-data-object';
import { SalesOrderShippedRequestObject } from './request/sales-order-shipped-request-object';
import { SalesOrderApiClient } from './sales-order.client';

export class SalesOrderService {
    private server: SalesOrderApiClient;

    constructor(config: ServiceConfigInterface, token: string) {
        this.server = new SalesOrderApiClient(config, token);
    }

    attachFiles(
        workspace: string,
        id: string,
        diversion: string,
        body: SalesOrderAttachDataObject
    ) {
        console.log(`service<sales>| attachFiles(): Enter`);
        console.log(`service<sales>| attachFiles(): $workspace = ${workspace}`);
        console.log(`service<sales>| attachFiles(): $id = ${id}`);
        console.log(`service<sales>| attachFiles(): $diversion = ${diversion}`);
        console.log(
            `service<sales>| attachFiles(): $body = ${JSON.stringify(body)}`
        );

        return this.server.attachFiles(workspace, id, diversion, body);
    }

    statistics(workspace: string) {
        console.log(`service<sales>| statistics(): Enter`);
        console.log(`service<sales>| statistics(): $workspace = ${workspace}`);

        return this.server.statistics(workspace);
    }

    search(workspace: string, body: string[]) {
        console.log(`service<sales>| search(): Enter`);
        console.log(`service<sales>| search(): $workspace = ${workspace}`);
        console.log(
            `service<sales>| search(): $body = ${JSON.stringify(body)}`
        );

        return this.server.search(workspace, body);
    }

    collectDocuments(body: SalesOrderDocCollectDataObject) {
        console.log(`service<sales>| collectDocuments(): Enter`);
        console.log(
            `service<sales>| collectDocuments(): $body = ${JSON.stringify(
                body
            )}`
        );

        return this.server.collectDocuments(body);
    }

    get(workspace: string, id: string) {
        console.log(`service<sales>| get(): Enter`);
        console.log(`service<sales>| get(): $workspace = ${workspace}`);
        console.log(`service<sales>| get(): $id = ${id}`);

        return this.server.get(workspace, id);
    }

    list(workspace: string) {
        console.log(`service<sales>| list(): Enter`);
        console.log(`service<sales>| list(): $workspace = ${workspace}`);

        return this.server.list(workspace);
    }

    shipped(
        workspace: string,
        id: string,
        body: SalesOrderShippedRequestObject
    ) {
        console.log(`service<sales>| shipped(): Enter`);
        console.log(`service<sales>| shipped(): $workspace = ${workspace}`);
        console.log(`service<sales>| shipped(): $id = ${id}`);
        console.log(
            `service<sales>| shipped(): $body = ${JSON.stringify(body)}`
        );

        return this.server.shipped(workspace, id, body);
    }

    getByPurchaseOrder(workspace: string, purchaseOrder: string) {
        console.log(`service<sales>| getByPurchaseOrder(): Enter`);
        console.log(
            `service<sales>| getByPurchaseOrder(): $workspace = ${workspace}`
        );
        console.log(
            `service<sales>| getByPurchaseOrder(): $purchaseOrder = ${purchaseOrder}`
        );

        return this.server.getByPurchaseOrder(workspace, purchaseOrder);
    }

    batch(
        workspace: string,
        body: { targets: string[] },
        diversion: string = '0'
    ) {
        console.log(`service<sales>| batch(): Enter`);
        console.log(`service<sales>| batch(): $workspace = ${workspace}`);
        console.log(`service<sales>| batch(): $body = ${JSON.stringify(body)}`);
        console.log(`service<sales>| batch(): $diversion = ${diversion}`);
        return this.server.batch(workspace, body, diversion);
    }
}
