import { ServiceConfigInterface } from 'src/domain/interfaces/config.context.interface';
import { ApiServer } from 'src/infrastructure/clients/api.server';
import { UserUpdateRequestObject } from './user-update-request-object';
import { InvitationCreateRequestObject } from './invitation-create-request-object';

export class UserApiClient extends ApiServer {
    constructor(config: ServiceConfigInterface, token: string) {
        super(token, config, 'workspaces', 'user');
    }

    getProfile() {
        console.log(`service<user>| getProfile(): Enter`);

        return super.get('profile');
    }

    updateProfile(body: UserUpdateRequestObject) {
        console.log(`service<user>| updateProfile(): Enter`);
        console.log(
            `service<user>| updateProfile(): $body = ${JSON.stringify(body)}`
        );

        return super.patch('profile', body);
    }

    consumeInvitation() {
        console.log(`service<user>| consumeInvitation(): Enter`);

        return super.put('consume');
    }

    getWorkspaces() {
        console.log(`service<user>| getWorkspaces(): Enter`);

        return super.get('workspaces');
    }

    inviteUser(workspace: string, body: InvitationCreateRequestObject) {
        console.log(`service<user>| inviteUser(): Enter`);
        console.log(`service<user>| inviteUser(): $workspace = ${workspace}`);
        console.log(
            `service<user>| inviteUser(): $body = ${JSON.stringify(body)}`
        );

        return super.post('invite', workspace, body);
    }
}
