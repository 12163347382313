import { useMutation, useQueryClient } from '@tanstack/react-query';
import { toast } from 'sonner';
import { useContextStore } from 'src/app/stores/context-store';
import { DocumentUpdateDataObject } from '../document-update-data-object';
import { DOCUMENTS_QUERY_KEY, DOCUMENT_QUERY_KEY } from './documents-query-key';
import { useDocumentService } from './use-document-service';

export function useUpdateDocument() {
    const { service } = useDocumentService();
    const context = useContextStore();
    const client = useQueryClient();

    return useMutation({
        mutationFn: async ({
            delegateId,
            id,
            body,
        }: {
            delegateId?: string;
            id: string;
            body: DocumentUpdateDataObject;
        }): Promise<any> => {
            return await service.update(
                delegateId || context.workspace?._id || '',
                id,
                body
            );
        },
        onSuccess: (_, { delegateId, id }) => {
            toast.success('Successfully updated document');

            client.invalidateQueries({
                queryKey: DOCUMENTS_QUERY_KEY(
                    delegateId || context.workspace?._id || ''
                ),
            });

            client.invalidateQueries({
                queryKey: DOCUMENT_QUERY_KEY(
                    delegateId || context.workspace?._id || '',
                    id
                ),
            });
        },
        onError: (err) => {
            const error = err as Error;

            toast.error(`Failed to update document: ${error.message}`);
        },
    });
}
