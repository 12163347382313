import { zodResolver } from '@hookform/resolvers/zod';
import { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';
import { toast } from 'sonner';
import { useComposeProduct } from 'src/app/_api_adb2c/product/product/hooks/use-componse-product';
import { useProduct } from 'src/app/_api_adb2c/product/product/hooks/use-product';
import {
    ProductUom,
    ProductUomLabels,
} from 'src/app/_api_adb2c/purchase/purchase/enums/product-uom.enum';
import { DialogV2 } from 'src/app/components-v2/dialog-v2';
import { SelectInputV2 } from 'src/app/components/Form/SelectInputV2';
import { TextInputV2 } from 'src/app/components/Form/TextInputV2';
import { z } from 'zod';
import PackagingUsageJson from './packaging-usage-type.json';
import ProductUsageJson from './product-usage-types.json';
import {
    createVersionRequest,
    mapExistingBOM,
    MaterialFormData,
} from './add-product-material-v3';

interface Props {
    open: boolean;
    onClose: () => void;
    materialId: string;
    productId: string;
    bomData: any; // Replace with proper type
}

const schema = z.object({
    baseMaterial: z.string().min(1, 'Material is required'),
    cost: z.coerce.number().min(0, 'Cost must be greater than or equal to 0'),
    weight: z.coerce
        .number()
        .min(0, 'Weight must be greater than or equal to 0'),
    consumption: z.coerce.number().min(0, 'Consumption must be greater than 0'),
    unitOfMeasurement: z.string().min(1, 'Unit of measurement is required'),
    usageNature: z.enum(['PRODUCT', 'PACKAGING'], {
        required_error: 'Usage nature is required',
    }),
});

export function EditBaseMaterial({
    open,
    onClose,
    materialId,
    productId,
    bomData,
}: Props) {
    const [searchParams] = useSearchParams();
    const delegateId = searchParams.get('delegateId') || '';

    const { data: product } = useProduct(productId, delegateId);
    const { mutateAsync: update, isLoading } = useComposeProduct();

    console.debug(bomData);

    const form = useForm<MaterialFormData>({
        resolver: zodResolver(schema),
        defaultValues: {
            baseMaterial:
                bomData?.usageNature === 'PRODUCT'
                    ? `${findMaterialType(bomData?.name)} - ${bomData?.name}`
                    : bomData?.name || '',
            cost: bomData?.materialCost || 0,
            weight: bomData?.weight || 0,
            consumption: bomData?.consumption || 0,
            unitOfMeasurement: bomData?.unitOfMeasurement || '',
            usageNature: bomData?.usageNature || 'PRODUCT',
            isBaseMaterial: 'base',
        },
    });

    const { watch } = form;
    const usageNature = watch('usageNature');

    const memoizedProductUsageJson = useMemo(() => {
        const options: { label: string; value: string }[] = [];

        ProductUsageJson.forEach((x) => {
            x.materials.forEach((material) => {
                options.push({
                    label: `${x.type} - ${material}`,
                    value: `${x.type} - ${material}`,
                });
            });
        });

        return options;
    }, []);

    const onSubmit = async (data: MaterialFormData) => {
        if (!product) {
            toast.error('Product not found');
            return;
        }

        const latestVersion = product.versions?.[product.versions.length - 1];
        if (!latestVersion) {
            toast.error('Product version not found');
            return;
        }

        const updatedComponents = (latestVersion.billOfMaterials || [])?.map(
            (bom) => {
                if (bom._id === materialId) {
                    const splittedBaseMaterial = data?.baseMaterial
                        ?.split(' - ')[1]
                        ?.trim();
                    return {
                        ...mapExistingBOM(bom),
                        materialCost: data.cost || 0,
                        name: splittedBaseMaterial || '',
                        source: 'base',
                        unitOfMeasurement: data.unitOfMeasurement,
                        consumption: data.consumption,
                        weight: data.weight || 0,
                        usageNature: data.usageNature,
                        // resource: bomData?.material._id || '',
                    };
                }
                return mapExistingBOM(bom);
            }
        );

        const versionRequest = createVersionRequest(
            updatedComponents,
            product.versions?.length || 0,
            latestVersion
        );

        try {
            await update({
                id: product._id,
                body: {
                    versions: [versionRequest],
                    name: product.name,
                },
            });
            onClose();
        } catch (error) {
            toast.error('Failed to update material');
        }
    };

    return (
        <DialogV2
            open={open}
            onClose={onClose}
            title='Edit Base Material'
            form={form}
            onSubmit={onSubmit}
            isLoading={isLoading}
            isStepDialog
            steps={[
                {
                    title: 'Edit Material',
                    description: 'Edit the base material details',
                    content: (
                        <div className='flex flex-col gap-4'>
                            <SelectInputV2
                                name='usageNature'
                                options={[
                                    { label: 'Product', value: 'PRODUCT' },
                                    { label: 'Packaging', value: 'PACKAGING' },
                                ]}
                                label='Usage Nature'
                            />

                            <SelectInputV2
                                name='baseMaterial'
                                label='Material'
                                options={
                                    usageNature === 'PRODUCT'
                                        ? memoizedProductUsageJson
                                        : PackagingUsageJson.map((x) => ({
                                              label: x.type,
                                              value: x.value,
                                          }))
                                }
                            />

                            <div className='grid grid-cols-2 gap-4'>
                                <TextInputV2
                                    name='cost'
                                    label='Cost per Unit (USD)'
                                    type='number'
                                />

                                <TextInputV2
                                    name='weight'
                                    label='Weight (Kg)'
                                    type='number'
                                />
                            </div>

                            <TextInputV2
                                name='consumption'
                                label='Consumption'
                                type='number'
                            />

                            <SelectInputV2
                                label='UOM'
                                name='unitOfMeasurement'
                                options={Object.values(ProductUom).map((x) => ({
                                    label: ProductUomLabels[x],
                                    value: x,
                                }))}
                            />
                        </div>
                    ),
                },
            ]}
        />
    );
}

const findMaterialType = (materialName: string): string => {
    const material = ProductUsageJson.find((category) =>
        category.materials.some((m) => m === materialName)
    );
    return material?.type || '';
};
