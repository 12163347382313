import { useContextStore } from 'src/app/stores/context-store';
import { useCategoryService } from './use-category-service';
import { useQuery } from '@tanstack/react-query';
import { CATEGORY_QUERY_KEY } from './category-query-keys';
import { toast } from 'sonner';
import { ProductCategoriesModel } from '../category.model';

export function useCategory(id: string, delegateId?: string) {
    const { service } = useCategoryService();
    const context = useContextStore();

    return useQuery<ProductCategoriesModel>({
        queryKey: CATEGORY_QUERY_KEY(
            delegateId || context.workspace?._id || '',
            id
        ),
        queryFn: async () => {
            return await service.get(
                delegateId || context.workspace?._id || '',
                id
            );
        },
        onError: (err) => {
            const error = err as Error;

            toast.error(`Failed to load category: ${error.message}`);
        },
    });
}
