import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useContextStore } from 'src/app/stores/context-store';
import { useSupplierService } from './use-supplier-service';
import { SUPPLIERS_QUERY_KEY } from './supplier-query-keys';
import { toast } from 'sonner';

export function useDeleteSupplier() {
    const client = useQueryClient();
    const context = useContextStore();
    const { service } = useSupplierService();

    return useMutation({
        mutationFn: async ({
            id,
            delegateId,
        }: {
            id: string;
            delegateId?: string;
        }) => {
            return await service.remove(
                delegateId || context.workspace?._id || '',
                id
            );
        },
        onSuccess: (_, { delegateId }) => {
            client.invalidateQueries(
                SUPPLIERS_QUERY_KEY(delegateId || context.workspace?._id || '')
            );

            toast.success('Supplier deleted successfully');
        },
        onError: (err) => {
            const error = err as Error;

            toast.error(`Failed to delete supplier: ${error.message}`);
        },
    });
}
