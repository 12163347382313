import { zodResolver } from '@hookform/resolvers/zod';
import { useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';
import { useAttachmentUpload } from 'src/app/_api_adb2c/attachment/hooks/use-attachment-upload';
import { useMaterials } from 'src/app/_api_adb2c/product/material/hooks/use-materials';
import { useComposeProduct } from 'src/app/_api_adb2c/product/product/hooks/use-componse-product';
import { useProduct } from 'src/app/_api_adb2c/product/product/hooks/use-product';
import {
    ProductUom,
    ProductUomLabels,
} from 'src/app/_api_adb2c/purchase/purchase/enums/product-uom.enum';
import { useWorkspaceCertificationTypes } from 'src/app/_api_adb2c/workspace/workspace/hooks/use-workspace-certification-types';
import { DialogV2 } from 'src/app/components-v2/dialog-v2';
import { SelectInputV2 } from 'src/app/components/Form/SelectInputV2';
import { TextInputV2 } from 'src/app/components/Form/TextInputV2';
import { useContextStore } from 'src/app/stores/context-store';
import { Separator } from 'src/components/ui/separator';
import { z } from 'zod';
import { AddProductCertificate } from './add-product-certificate';
import { MaterialSelection } from './material-selection';
import PackagingUsageJson from './packaging-usage-type.json';
import ProductUsageJson from './product-usage-types.json';
import { ProductBillOfMaterialDataObject } from 'src/app/_api_adb2c/product/product/requests/product-bill-of-material-data-object';
import { ProductVersionCreateDataObject } from 'src/app/_api_adb2c/product/product/requests/product-version-create-data-object';
import { ProductDocObject } from 'src/app/_api_adb2c/product/product/requests/product-update-data-object';
import { FileCreateDataObject } from 'src/app/_api_adb2c/attachment/file-create-data-object';
import axios from 'axios';

interface Props {
    open: boolean;
    productId: string;
    onClose: () => void;
}

export type MaterialSource = 'base' | 'custom';

export interface BaseMaterial {
    type: string;
    name: string;
    cost: number;
    weight: number;
}

export interface CustomMaterial {
    _id: string;
    name: string;
    unitCost: number;
    weight: number;
}

export type UsageNatureType = 'PRODUCT' | 'PACKAGING';

export interface MaterialFormData {
    materialId?: string;
    baseMaterial?: string;
    cost?: number;
    usageNature: UsageNatureType;
    weight?: number;
    isBaseMaterial: MaterialSource;
    consumption: number;
    unitOfMeasurement: string;
    file?: Array<{
        file: File;
        certificateTypeId: string;
    }>;
}

export interface ProductUsageType {
    type: string;
    materials: string[];
    certifications: string[];
}

export interface PackagingUsageType {
    type: string;
    value: string;
}

export function mapExistingBOM(
    bom: ProductBillOfMaterialDataObject
): ProductBillOfMaterialDataObject {
    return {
        materialCost: bom.materialCost,
        name: bom.name,
        source: bom.source,
        unitOfMeasurement: bom.unitOfMeasurement,
        consumption: bom.consumption,
        resource: bom.resource,
        weight: bom.weight,
        usageNature: bom.usageNature,
    };
}

export function createNewBOMEntry(
    data: MaterialFormData,
    materials: any[] | undefined,
    mode: MaterialSource
): ProductBillOfMaterialDataObject {
    const material = materials?.find((x) => x._id === data.materialId);
    // Split if there is a '-' else return the baseMaterial

    const splittedBaseMaterial = data.baseMaterial?.includes('-')
        ? data.baseMaterial?.split('-')?.[1]?.trim()
        : data.baseMaterial;

    return {
        materialCost:
            mode === 'custom' ? material?.unitCost || 0 : data.cost || 0,
        name:
            mode === 'custom'
                ? material?.name || ''
                : splittedBaseMaterial || '',
        source: mode === 'custom' ? 'local' : 'base',
        unitOfMeasurement: data.unitOfMeasurement,
        consumption: data.consumption,
        resource: mode === 'custom' ? material?._id : '',
        weight:
            mode === 'custom'
                ? material?.weight || 0
                : (data.weight || 0) * (data.consumption || 0),
        usageNature: data.usageNature,
    };
}

export function createVersionRequest(
    consolidatedComponents: ProductBillOfMaterialDataObject[],
    currentVersionCount: number,
    latestVersion: any
): ProductVersionCreateDataObject {
    const costOfMaterials = consolidatedComponents.reduce(
        (acc, curr) => acc + (curr.materialCost || 0),
        0
    );

    return {
        version: currentVersionCount + 1,
        billOfMaterials: consolidatedComponents,
        costOfMaterials: costOfMaterials,
        specifications: {
            height: latestVersion?.specifications?.height || 0,
            width: latestVersion?.specifications?.width || 0,
            weight: latestVersion?.specifications?.weight || 0,
            depth: latestVersion?.specifications?.depth || 0,
        },
        releasedOn: new Date(),
    };
}

export async function handleDocumentUploads(
    files: MaterialFormData['file'] | undefined,
    upload: (params: {
        file: FileCreateDataObject;
        assetType: string;
        delegateId: string;
    }) => Promise<any>,
    context: { user?: { _id?: string }; workspace?: { _id?: string } },
    delegateId: string
): Promise<ProductDocObject[]> {
    if (!files || files.length === 0) return [];

    const documents: ProductDocObject[] = [];

    await Promise.all(
        files.map(async (file) => {
            const request: FileCreateDataObject = {
                contentType: file.file.type,
                name: file.file.name,
                genre: 'temporary',
                size: file.file.size,
                uploadedBy: context.user?._id || '',
                workspace: delegateId || context.workspace?._id || '',
            };

            const response = await upload({
                file: request,
                assetType: 'product',
                delegateId: delegateId,
            });

            await axios.put(response.token, file.file, {
                headers: {
                    'Content-Type': file.file.type,
                    'x-ms-blob-type': 'BlockBlob',
                },
            });

            documents.push({
                file: {
                    assetName: response.attachment.assetName,
                    autoResign: response.attachment.autoResign,
                    container: response.attachment.container,
                    contentType: response.attachment.contentType,
                    extension:
                        response.attachment.originalName.split('.').pop() || '',
                    originalName: response.attachment.originalName,
                    readReleaseTime: response.attachment.readReleaseTime,
                    size: response.attachment.size,
                },
                type: file.certificateTypeId,
                uploader: context.user?._id || '',
            });
        })
    );

    return documents;
}

export const schema = z.object({
    materialId: z.string().optional(),
    baseMaterial: z.string().optional(),
    cost: z.coerce.number().optional(),
    usageNature: z.enum(['PRODUCT', 'PACKAGING'], {
        required_error: 'Usage nature is required',
    }),
    weight: z.coerce.number().optional(),
    isBaseMaterial: z.enum(['base', 'custom'], {
        required_error: 'Material type is required',
    }),
    consumption: z.coerce.number().min(0, 'Consumption must be greater than 0'),
    unitOfMeasurement: z.string().min(1, 'Unit of measurement is required'),
    file: z
        .array(
            z.object({
                file: z.instanceof(File),
                certificateTypeId: z
                    .string()
                    .min(1, 'Certificate type is required'),
            })
        )
        .optional()
        .refine(
            (files) => {
                if (!files) return true;
                return files.every((file) => file.certificateTypeId !== '');
            },
            {
                message: 'All certificates must have a type selected',
            }
        ),
});

export function AddProductMaterialV3({ open, onClose, productId }: Props) {
    const [searchParams] = useSearchParams();
    const context = useContextStore();
    const delegateId = searchParams.get('delegateId') || '';

    const [isLoading, setIsLoading] = useState(false);

    const { data: product } = useProduct(productId, delegateId);
    const { data: materials } = useMaterials(delegateId);
    const { data: certificationTypes } = useWorkspaceCertificationTypes();
    const { mutateAsync: compose } = useComposeProduct();
    const { mutateAsync: upload } = useAttachmentUpload();

    const form = useForm<MaterialFormData>({
        resolver: zodResolver(schema),
        mode: 'onChange',
    });

    const { watch } = form;

    const usageNature = watch('usageNature');
    const baseMaterialValue = watch('baseMaterial');
    const isBaseMaterial = watch('isBaseMaterial');

    const onSubmit = async (data: MaterialFormData) => {
        if (!product) return;
        setIsLoading(true);

        const mode = data.isBaseMaterial;
        const latestVersion = product.versions?.[product.versions.length - 1];

        const consolidatedComponents = [
            ...(latestVersion?.billOfMaterials || []).map((bom) => ({
                ...mapExistingBOM(bom),
                materialCost: bom.materialCost || 0,
            })),
            createNewBOMEntry(data, materials?.data, mode),
        ];

        const versionRequest = createVersionRequest(
            consolidatedComponents,
            product.versions?.length || 0,
            latestVersion
        );

        const documents = await handleDocumentUploads(
            data.file,
            upload,
            context,
            delegateId
        );

        await compose({
            id: product._id,
            body: {
                versions: [versionRequest],
                name: product.name,
                documents: [...(product.documents || []), ...documents],
            },
        });

        reset();
    };

    const reset = () => {
        onClose();
        form.reset();
        setIsLoading(false);
    };

    const usageOptions = [
        { label: 'Product', value: 'PRODUCT' },
        { label: 'Packaging', value: 'PACKAGING' },
    ];

    const usageCertifications = useMemo(() => {
        if (usageNature !== 'PRODUCT') return [];

        const type = baseMaterialValue?.split('-')[0];
        const existingProductUsage = ProductUsageJson.find(
            (x) => x.type === type?.trim()
        );

        return existingProductUsage?.certifications || [];
    }, [baseMaterialValue, usageNature]);

    const matchingCertificationTypes = useMemo(() => {
        return certificationTypes?.filter((x) =>
            usageCertifications.includes(x.name)
        );
    }, [certificationTypes, usageCertifications]);

    const memoizedProductUsageJson = useMemo(() => {
        const options: { label: string; value: string }[] = [];

        ProductUsageJson.forEach((x) => {
            x.materials.forEach((material) => {
                options.push({
                    label: `${x.type} - ${material}`,
                    value: `${x.type} - ${material}`,
                });
            });
        });

        return options;
    }, []);

    return (
        <DialogV2
            open={open}
            onClose={reset}
            title='Add Product Bill Of Materials'
            form={form}
            onSubmit={onSubmit}
            isLoading={isLoading}
            isStepDialog
            steps={[
                {
                    title: 'Select Usage Nature',
                    description: 'Select the usage nature of the material',
                    content: (
                        <>
                            <SelectInputV2
                                name='usageNature'
                                options={usageOptions}
                                label='Usage Nature'
                            />
                            <Separator className='my-4' />

                            {usageNature && (
                                <div className='flex flex-col gap-2'>
                                    <SelectInputV2
                                        name='isBaseMaterial'
                                        options={[
                                            {
                                                label: 'Base Material',
                                                value: 'base',
                                            },
                                            {
                                                label: 'Custom Material',
                                                value: 'custom',
                                            },
                                        ]}
                                        label='Material Type'
                                    />

                                    <MaterialSelection
                                        usageNature={usageNature}
                                        materialType={isBaseMaterial}
                                        productUsageOptions={
                                            memoizedProductUsageJson
                                        }
                                        packagingUsageOptions={PackagingUsageJson.map(
                                            (x) => ({
                                                label: x.type,
                                                value: x.value,
                                            })
                                        )}
                                        customMaterialOptions={(
                                            materials?.data || []
                                        ).map((x) => ({
                                            label: x.name,
                                            value: x._id,
                                        }))}
                                    />

                                    {isBaseMaterial === 'base' && (
                                        <div className='grid grid-cols-2 gap-4'>
                                            <TextInputV2
                                                name='cost'
                                                label='Cost per Unit (USD)'
                                                type='number'
                                            />

                                            <TextInputV2
                                                name='weight'
                                                label='Weight (Kg)'
                                                type='number'
                                            />
                                        </div>
                                    )}

                                    <TextInputV2
                                        name='consumption'
                                        label='Consumption'
                                        type='number'
                                    />

                                    <SelectInputV2
                                        label='UOM'
                                        name='unitOfMeasurement'
                                        options={Object.values(ProductUom).map(
                                            (x) => ({
                                                label: ProductUomLabels[x],
                                                value: x,
                                            })
                                        )}
                                    />
                                </div>
                            )}
                        </>
                    ),
                },
                {
                    title: 'Upload Certificate(s)',
                    description: 'Upload the certificate(s) for the material',
                    content: (
                        <AddProductCertificate
                            certificateTypes={matchingCertificationTypes || []}
                        />
                    ),
                    hidden:
                        matchingCertificationTypes &&
                        matchingCertificationTypes.length === 0,
                },
            ]}
        />
    );
}
