import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { i18n } from 'src/app/_api_adb2c/shared/i18n.model';
import { useCreateRole } from 'src/app/_api_adb2c/workspace/roles/hooks/use-create-role';
import { FormStepperDialog } from 'src/app/components-v2/stepper-form';
import { DelegationInput } from 'src/app/components/Form/DelegationInput';
import { TextInput } from 'src/app/components/Form/TextInput';
import { z } from 'zod';

interface Props {
    open: boolean;
    onClose: () => void;
}

const formSchema = z.object({
    name: z.string(),
    description: z.string().optional(),
    code: z.string(),
    delegateId: z.string().optional(),
});

export const CreateRole = ({ open, onClose }: Props) => {
    const { mutateAsync, isLoading } = useCreateRole();

    const form = useForm<z.infer<typeof formSchema>>({
        mode: 'onChange',
        resolver: zodResolver(formSchema),
    });

    const onSubmit = async (data: z.infer<typeof formSchema>) => {
        const descriptions: i18n = {
            locales: [],
        };

        if (data.description) {
            descriptions.locales.push({
                localeName: 'en',
                text: data.description,
            });
        }

        await mutateAsync({
            delegateId: data.delegateId,
            body: {
                ...data,
                names: { locales: [{ localeName: 'en', text: data.name }] },
                descriptions: descriptions,
            },
        });

        reset();
    };

    const reset = () => {
        form.reset();
        onClose();
    };

    return (
        <FormStepperDialog
            open={open}
            onClose={reset}
            form={form}
            onSubmit={onSubmit}
            isLoading={isLoading}
            tabs={[
                {
                    label: 'On Behalf Of.',
                    content: (
                        <DelegationInput
                            name='delegateId'
                            label='On Behalf Of.'
                        />
                    ),
                },
                {
                    label: 'Basic Information',
                    content: (
                        <>
                            <TextInput label='Name' inputName='name' />
                            <TextInput
                                label='Description'
                                inputName='description'
                            />
                            <TextInput label='Code' inputName='code' />
                        </>
                    ),
                },
            ]}
            label='Create Role'
        ></FormStepperDialog>
    );
};
