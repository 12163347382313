import { zodResolver } from '@hookform/resolvers/zod';
import { ColumnDef } from '@tanstack/react-table';
import { DateTime } from 'luxon';
import { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';
import { useSalesOrderShipped } from 'src/app/_api_adb2c/sales/sales/hooks/use-sales-order-shipped';
import { SalesOrderModel } from 'src/app/_api_adb2c/sales/sales/models/sales-order.model';
import { DisplayTable } from 'src/app/components/DisplayTable';
import { DateInput } from 'src/app/components/Form/DateInput';
import { Form } from 'src/components/ui/form';
import { z } from 'zod';
import { BaseDialog } from 'src/app/components-v2/base-dialog';

interface Props {
    open: boolean;
    onClose: () => void;
    data: SalesOrderModel;
}

const formSchema = z.object({
    shipment: z.date(),
});

export const SalesOrderManageShipments = ({
    data: order,
    open,
    onClose,
}: Props) => {
    const [searchParams] = useSearchParams();
    const delegateId = searchParams.get('delegateId') || '';

    const { mutateAsync } = useSalesOrderShipped();

    const memoizedData = useMemo(() => {
        return order.shippedOn.map((date, index) => ({
            date: date,
            id: index,
        }));
    }, [order.shippedOn]);

    const form = useForm<z.infer<typeof formSchema>>({
        mode: 'onChange',
        resolver: zodResolver(formSchema),
        defaultValues: {},
    });

    const onSubmit = async (data: z.infer<typeof formSchema>) => {
        await mutateAsync({
            delegateId: delegateId,
            id: order._id,
            body: {
                shipDate: data.shipment,
                shipToAddress: order.shipToAddress,
            },
        });

        reset();
    };

    const reset = () => {
        form.reset();
        onClose();
    };

    const columns: ColumnDef<{ id: number; date: Date }>[] = [
        {
            id: 'id',
            header: 'ID',
            size: 10,
            cell: ({ row }) => {
                return row.original.id + 1;
            },
        },
        {
            id: 'date',
            header: 'Date',
            size: 200,
            cell: ({ row }) => {
                return DateTime.fromJSDate(
                    new Date(row.original.date)
                ).toFormat('yyyy-MM-dd');
            },
        },
    ];

    return (
        <BaseDialog
            title='Manage Shipments'
            open={open}
            onClose={onClose}
            sizes='md'
        >
            <Form {...form}>
                <form
                    id='my-form'
                    className='flex flex-col gap-4'
                    onSubmit={form.handleSubmit(
                        (value) => {
                            onSubmit(value);
                            // reset();
                        },
                        (err) => console.error(err)
                    )}
                >
                    <div className='w-1/2'>
                        <DateInput name='shipment' label='Shipment Date' />
                    </div>

                    <DisplayTable data={memoizedData} columns={columns} />
                </form>
            </Form>
        </BaseDialog>
    );
};
