import { useAccount } from '@azure/msal-react';
import { useMemo } from 'react';
import useAppConfig from 'src/infrastructure/hooks/use-config.hook';
import { AttachmentApiClient } from '../attachment.client';

export function useAttachmentService() {
    const account = useAccount();
    const config = useAppConfig();

    const service = useMemo(() => {
        if (account?.idToken) {
            return new AttachmentApiClient(config, account.idToken);
        } else {
            throw new Error('Account not found');
        }
    }, [account, config]);

    return { service };
}
