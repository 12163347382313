import { ServiceConfigInterface } from 'src/domain/interfaces/config.context.interface';
import { ApiServer } from 'src/infrastructure/clients/api.server';

export class WorkspaceSearchClient extends ApiServer {
    constructor(config: ServiceConfigInterface, token: string) {
        super(token, config, 'workspaces', 'workspace-search');
    }

    searchByName(name: string, country: string) {
        console.log(`client<search>| searchByName(): Enter`);
        console.log(`client<search>| searchByName(): $name = ${name}`);
        console.log(`client<search>| searchByName(): $country = ${country}`);

        return super.get('searchByName', name, country);
    }

    searchByRegistrationNumber(
        registrationNumber: string,
        country: string,
        diversion = '0'
    ) {
        console.log(`client<search>| searchByRegistrationNumber(): Enter`);
        console.log(
            `client<search>| searchByRegistrationNumber(): $registrationNumber = ${registrationNumber}`
        );
        console.log(
            `client<search>| searchByRegistrationNumber(): $country = ${country}`
        );
        console.log(
            `client<search>| searchByRegistrationNumber(): $diversion = ${diversion}`
        );

        return super.get(
            'searchByRegistrationNumber',
            country,
            registrationNumber,
            diversion
        );
    }
}
