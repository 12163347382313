import { DownloadCloud } from 'lucide-react';
import { useMemo } from 'react';
import { WorkspaceModel } from 'src/app/_api_adb2c/workspace/workspace/workspace.model';
import { useDownload } from 'src/app/pages-v2/Materials/Details/Substance';
import { WorkspaceFileModel } from 'src/app/pages-v2/Settings/Documents/workspace-documents';
import {
    Popover,
    PopoverContent,
    PopoverTrigger,
} from 'src/components/ui/popover';

interface Props {
    workspace?: WorkspaceModel;
}

export function PurchaseCompanyDocuments({ workspace }: Props) {
    const { download } = useDownload();
    const documents = useMemo(() => {
        if (!workspace) return [];

        const documents: WorkspaceFileModel[] = [];

        workspace.library.extensions.forEach((doc, index) => {
            const document = workspace.library.documents?.[index];

            if (!document) return;

            documents.push({
                ...doc,
                ...document,
            });
        });

        return documents;
    }, [workspace]);

    if (!workspace) return <>--</>;

    return (
        <div>
            <Popover>
                <PopoverTrigger asChild>
                    <span className='cursor-pointer text-xs text-blue-400 hover:underline hover:underline-offset-2'>
                        Documents
                    </span>
                </PopoverTrigger>

                <PopoverContent side='top' className='flex flex-col gap-2'>
                    <div className='flex flex-col gap-2'>
                        <span className='font-bold underline decoration-red-800 underline-offset-2 '>
                            Company Documents
                        </span>

                        {documents.length === 0 && (
                            <div className='flex flex-col gap-2'>
                                <span>No documents uploaded for company.</span>
                            </div>
                        )}

                        <div className='flex flex-col gap-1'>
                            {documents?.map((x) => {
                                return (
                                    <div
                                        className='group flex items-center justify-between gap-2'
                                        key={x.file}
                                    >
                                        <span className='group-hover:underline'>
                                            {x.name}
                                        </span>

                                        <DownloadCloud
                                            size={16}
                                            className='hover:scale-125'
                                            onClick={() => {
                                                download(x.file, x.name);
                                            }}
                                        />
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </PopoverContent>
            </Popover>
        </div>
    );
}
