import { useFormContext } from 'react-hook-form';
import {
    FormControl,
    FormField,
    FormItem,
    FormLabel,
    FormMessage,
} from 'src/components/ui/form';
import { Switch } from 'src/components/ui/switch';

interface Props {
    label?: string;
    name: string;
    placeholder?: string;
}

export const SwitchInput = ({ label, name, placeholder }: Props) => {
    const { control } = useFormContext();

    return (
        <FormField
            control={control}
            name={name}
            render={({ field }) => (
                <FormItem className='mt-0 flex flex-1 items-center gap-2 space-y-0'>
                    {label && (
                        <FormLabel className='text-xs'>{label}</FormLabel>
                    )}

                    <FormControl>
                        <Switch
                            className='my-0'
                            checked={field.value}
                            onCheckedChange={field.onChange}
                            data-testid='switch-input'
                        />
                    </FormControl>

                    <FormMessage />
                </FormItem>
            )}
        />
    );
};
