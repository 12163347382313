import { UseFormRegisterReturn, useFormContext } from 'react-hook-form';
import {
    FormControl,
    FormField,
    FormItem,
    FormLabel,
    FormMessage,
} from 'src/components/ui/form';
import { Input } from 'src/components/ui/input';

interface Props {
    name: string;
    label?: string;
    multiple?: boolean;
    inputRef: UseFormRegisterReturn;
}

export const FileInput = ({
    name,
    label,
    inputRef,
    multiple = false,
}: Props) => {
    const { control } = useFormContext();

    return (
        <FormField
            control={control}
            name={name}
            render={() => {
                return (
                    <FormItem className='flex-1'>
                        {label && (
                            <FormLabel className='text-xs'>{label}</FormLabel>
                        )}

                        <FormControl className=''>
                            <Input
                                className='py-[6px] text-xs'
                                type='file'
                                data-testid='file-input'
                                multiple={multiple}
                                {...inputRef}
                            />
                        </FormControl>

                        <FormMessage />
                    </FormItem>
                );
            }}
        ></FormField>
    );
};
