import { useContextStore } from 'src/app/stores/context-store';
import { useSupplierService } from './use-supplier-service';
import { useQuery } from '@tanstack/react-query';
import { SUPPLIER_QUERY_KEY } from './supplier-query-keys';
import { toast } from 'sonner';
import { SupplierModel } from '../models/supplier.model';

export function useSupplier(id: string, delegateId?: string) {
    const context = useContextStore();
    const { service } = useSupplierService();

    return useQuery<SupplierModel>({
        queryKey: SUPPLIER_QUERY_KEY(
            delegateId || context.workspace?._id || '',
            id
        ),
        queryFn: async () => {
            return await service.get(
                delegateId || context.workspace?._id || '',
                id
            );
        },
        onError: (err) => {
            const error = err as Error;

            toast.error(`Failed to fetch suppliers: ${error.message}`);
        },
    });
}
