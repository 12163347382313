import { zodResolver } from '@hookform/resolvers/zod';
import { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';
import { useCreateApproval } from 'src/app/_api_adb2c/product/product-approval/hooks/use-create-approval';
import { ProductApprovalRequestCreateDto } from 'src/app/_api_adb2c/product/product-approval/request/product-approval-request-create-dto';
import { useSalesOrders } from 'src/app/_api_adb2c/sales/sales/hooks/use-sales-orders';
import { DialogV2 } from 'src/app/components-v2/dialog-v2';
import { SelectInput } from 'src/app/components/Form/SelectInput';
import { generateOrderTitle } from 'src/app/utils/generate-order-title';
import { z } from 'zod';

interface Props {
    productId: string;
    open: boolean;
    onClose: () => void;
}

const schema = z.object({
    salesOrder: z.string(),
    productId: z.string(),
});

export function RequestApprovalProduct({ productId, open, onClose }: Props) {
    const [searchParams] = useSearchParams();
    const delegateId = searchParams.get('delegateId') || '';

    const { data: salesOrders } = useSalesOrders(delegateId);

    const { mutateAsync: create, isLoading } = useCreateApproval();

    const form = useForm<z.infer<typeof schema>>({
        mode: 'onChange',
        resolver: zodResolver(schema),
    });

    const salesOrder = form.watch('salesOrder');
    const selectedProduct = form.watch('productId');
    const selectedSalesOrder = useMemo(() => {
        return salesOrders?.find((x) => x._id === salesOrder);
    }, [salesOrders, salesOrder]);

    const purchaseItems = useMemo(() => {
        if (!selectedSalesOrder) return [];

        const latest =
            selectedSalesOrder.purchase?.versions?.[
                selectedSalesOrder.purchase.versions.length - 1
            ];
        if (!latest) return [];

        return latest.manifest.map((x) => ({
            label: x.product.name,
            value: x.product._id,
        }));
    }, [selectedSalesOrder]);

    const onSubmit = async (data: z.infer<typeof schema>) => {
        const request: ProductApprovalRequestCreateDto = {
            product: productId,
            salesOrder: data.salesOrder,
            customerProduct: data.productId,
        };

        await create({ body: request });

        reset();
    };

    const reset = () => {
        form.reset();
        onClose();
    };

    return (
        <DialogV2
            open={open}
            onClose={onClose}
            formName='request-approval-form'
            form={form}
            isLoading={isLoading}
            onSubmit={onSubmit}
            title='Request Product Approval'
            isStepDialog
            steps={[
                {
                    title: 'Identify the Customer',
                    description: 'Select the sales order for the product',
                    content: (
                        <>
                            <SelectInput
                                name='salesOrder'
                                label='Sales Order'
                                options={(salesOrders || [])?.map((x) => ({
                                    label: x.purchase
                                        ? generateOrderTitle(x.purchase)
                                        : x.purchaseOrderId,
                                    value: x._id,
                                }))}
                            />

                            {selectedSalesOrder?.purchase && (
                                <SelectInput
                                    name='productId'
                                    label='Purchase Item'
                                    options={purchaseItems}
                                />
                            )}
                        </>
                    ),
                },
                {
                    title: 'Verify and Confirm',
                    description: 'Verify the details and confirm the request',
                    content: (
                        <div className='grid grid-cols-1 gap-4'>
                            <div className='flex flex-col gap-2 text-xs'>
                                <span className='font-light text-gray-500'>
                                    Sales Order
                                </span>
                                <span>
                                    {selectedSalesOrder?.purchaseOrderId}
                                </span>
                            </div>

                            <div className='flex flex-col gap-2 text-xs'>
                                <span className='font-light text-gray-500'>
                                    Approving Customer Name
                                </span>
                                <span>
                                    {selectedSalesOrder?.customer.buyer.name}
                                </span>
                            </div>

                            <div className='flex flex-col gap-2 text-xs'>
                                <span className='font-light text-gray-500'>
                                    Approving Purchase Item
                                </span>
                                <span>
                                    {
                                        purchaseItems?.find(
                                            (x) => x.value === selectedProduct
                                        )?.label
                                    }
                                </span>
                            </div>
                        </div>
                    ),
                },
            ]}
        ></DialogV2>
    );
}
