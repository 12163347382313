import { useAccount } from '@azure/msal-react';
import { useMemo } from 'react';
import useAppConfig from 'src/infrastructure/hooks/use-config.hook';
import { UserService } from '../user.service';

export function useUserService() {
    const account = useAccount();
    const config = useAppConfig();

    const service = useMemo(() => {
        if (account?.idToken) {
            return new UserService(config, account?.idToken);
        } else {
            throw new Error('Session Expired');
        }
    }, [config, account?.idToken]);

    return { service };
}
