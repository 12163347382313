import { zodResolver } from '@hookform/resolvers/zod';
import { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useProducts } from 'src/app/_api_adb2c/product/product/hooks/use-products';
import { usePurchaseItems } from 'src/app/_api_adb2c/purchase/purchase-item/hooks/use-purchase-items';
import {
    ProductUom,
    ProductUomLabels,
} from 'src/app/_api_adb2c/purchase/purchase/enums/product-uom.enum';
import { useUpdatePurchase } from 'src/app/_api_adb2c/purchase/purchase/hooks/use-update-purchase';
import { ManifestItemModel } from 'src/app/_api_adb2c/purchase/purchase/models/manifest-item.model';
import {
    PurchaseModel,
    PurchaseOrderTraceModel,
} from 'src/app/_api_adb2c/purchase/purchase/models/purchase.model';
import { SelectInput } from 'src/app/components/Form/SelectInput';
import { TextInput } from 'src/app/components/Form/TextInput';
import { Button } from 'src/components/ui/button';
import {
    Dialog,
    DialogContent,
    DialogFooter,
    DialogHeader,
} from 'src/components/ui/dialog';
import { Form } from 'src/components/ui/form';
import { z } from 'zod';

interface Props {
    order?: PurchaseModel | PurchaseOrderTraceModel;
    data?: ManifestItemModel;
    onClose: () => void;
    open: boolean;
}

const schema = z.object({
    product: z.string(),
    quantity: z.coerce.number(),
    ppu: z.coerce.number(),
    unit: z.string(),
});

export const EditPurchaseManifestItem = ({
    order,
    open,
    data,
    onClose,
}: Props) => {
    const { data: products } = useProducts();
    const { data: purchaseItems } = usePurchaseItems();

    const memoizedProductOptions = useMemo(() => {
        if (!products || !purchaseItems) return [];

        return purchaseItems.data.map((item) => {
            const product = products?.data.find((p) => p._id === item._id);

            return {
                label: product?.name || '',
                value: product?._id || '',
            };
        });
    }, [products, purchaseItems]);

    const { mutateAsync } = useUpdatePurchase();

    const form = useForm<z.infer<typeof schema>>({
        mode: 'onChange',
        resolver: zodResolver(schema),
        defaultValues: {
            product: data?.product._id,
            quantity: data?.quantity,
            ppu: data?.ppu,
            unit: data?.unit,
        },
    });

    const onSubmit = async (data: z.infer<typeof schema>) => {
        if (!order) return;

        const version = order.versions[order.versions.length - 1];
        if (!version) return;

        const manifestItems = version.manifest.map((item) => {
            if (item.product._id === data.product) {
                return {
                    ppu: data.ppu,
                    quantity: data.quantity,
                    unit: data.unit,
                    product: item.product._id,
                };
            }

            return {
                ...item,
                product: item.product._id,
            };
        });

        await mutateAsync({
            id: order._id,
            body: {
                items: manifestItems,
            },
        });

        reset();
    };

    const reset = () => {
        form.reset();
        onClose();
    };

    return (
        <Dialog
            open={open}
            onOpenChange={(value) => {
                reset();
            }}
        >
            <DialogContent>
                <DialogHeader>
                    <span className='font-bold'>Edit Manifest Item</span>
                </DialogHeader>

                <Form {...form}>
                    <form
                        id='my-form'
                        onSubmit={form.handleSubmit(
                            (value) => {
                                onSubmit(value);
                                // reset();
                            },
                            (err) => console.error(err)
                        )}
                    >
                        <SelectInput
                            name='product'
                            label='Product'
                            options={memoizedProductOptions.map((product) => ({
                                label: product.label,
                                value: product.value,
                            }))}
                        />

                        <SelectInput
                            name='unit'
                            label='Unit Of Measurement'
                            options={Object.values(ProductUom).map((unit) => ({
                                label: ProductUomLabels[unit],
                                value: unit,
                            }))}
                        />

                        <TextInput
                            inputName='ppu'
                            label='Price Per Unit'
                            type='number'
                        />

                        <TextInput
                            inputName='quantity'
                            label='Quantity'
                            type='number'
                        />
                    </form>
                </Form>

                <DialogFooter>
                    <Button
                        type='reset'
                        variant={'outline'}
                        size='sm'
                        onClick={reset}
                    >
                        Cancel
                    </Button>

                    <Button type='submit' size='sm' form='my-form'>
                        Submit
                    </Button>
                </DialogFooter>
            </DialogContent>
        </Dialog>
    );
};
