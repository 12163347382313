import { DropdownMenuArrow } from '@radix-ui/react-dropdown-menu';
import { ChevronDown, Dot, LucideIcon } from 'lucide-react';
import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { SubmenuItem } from 'src/app/router/menu-list';
import { useSidebarStore } from 'src/app/stores/sidebar-store';
import { Button } from 'src/components/ui/button';
import {
    Collapsible,
    CollapsibleContent,
    CollapsibleTrigger,
} from 'src/components/ui/collapsible';
import {
    DropdownMenu,
    DropdownMenuLabel,
    DropdownMenuContent,
    DropdownMenuTrigger,
    DropdownMenuSeparator,
    DropdownMenuItem,
} from 'src/components/ui/dropdown-menu';
import { TooltipContent } from 'src/components/ui/tooltip';
import {
    Tooltip,
    TooltipProvider,
    TooltipTrigger,
} from 'src/components/ui/tooltip';
import { cn } from 'src/lib/utils';

interface CollapseMenuButtonProps {
    Icon: LucideIcon;
    label: string;
    active: boolean;
    submenus: SubmenuItem[];
    onClick?: () => void;
}

export function CollapsibleNavigationButton({
    Icon,
    label,
    active,
    submenus,
    onClick,
}: CollapseMenuButtonProps) {
    const { open } = useSidebarStore();
    const navigate = useNavigate();
    const location = useLocation();
    const pathname = location.pathname;

    const isSubmenuActive = submenus.some((submenu) =>
        submenu.active === undefined
            ? submenu.href === pathname
            : submenu.active
    );
    const [isCollapsed, setIsCollapsed] = useState<boolean>(isSubmenuActive);

    if (!open) {
        return (
            <DropdownMenu>
                <TooltipProvider disableHoverableContent>
                    <Tooltip delayDuration={100}>
                        <TooltipTrigger asChild>
                            <DropdownMenuTrigger asChild>
                                <Button
                                    variant={active ? 'secondary' : 'ghost'}
                                    className='mb-1 h-10 w-full justify-start'
                                >
                                    <div className='flex w-full items-center justify-between'>
                                        <div className='flex items-center'>
                                            <span
                                                className={cn(
                                                    open === false ? '' : 'mr-4'
                                                )}
                                            >
                                                <Icon size={18} />
                                            </span>
                                            <p
                                                className={cn(
                                                    'max-w-[200px] truncate',
                                                    open === false
                                                        ? 'opacity-0'
                                                        : 'opacity-100'
                                                )}
                                            >
                                                {label}
                                            </p>
                                        </div>
                                    </div>
                                </Button>
                            </DropdownMenuTrigger>
                        </TooltipTrigger>
                        <TooltipContent
                            side='right'
                            align='start'
                            alignOffset={2}
                        >
                            {label}
                        </TooltipContent>
                    </Tooltip>
                </TooltipProvider>

                <DropdownMenuContent side='right' sideOffset={25} align='start'>
                    <DropdownMenuLabel className='max-w-[190px] truncate'>
                        {label}
                    </DropdownMenuLabel>
                    <DropdownMenuSeparator />
                    {submenus.map(({ href, label, active }, index) => (
                        <DropdownMenuItem key={index} asChild>
                            <span
                                className={`cursor-pointer ${
                                    ((active === undefined &&
                                        pathname === href) ||
                                        active) &&
                                    'bg-secondary'
                                }`}
                                onClick={() => {
                                    if (onClick) {
                                        onClick();
                                    } else {
                                        navigate(href);
                                    }
                                }}
                            >
                                <p className='max-w-[180px] truncate'>
                                    {label}
                                </p>
                            </span>
                        </DropdownMenuItem>
                    ))}
                    <DropdownMenuArrow className='fill-border' />
                </DropdownMenuContent>
            </DropdownMenu>
        );
    }

    return (
        <Collapsible
            open={isCollapsed}
            onOpenChange={setIsCollapsed}
            className='w-full text-xs'
        >
            <CollapsibleTrigger
                className='mb-1 [&[data-state=open]>div>div>svg]:rotate-180'
                asChild
            >
                <Button
                    variant={active ? 'secondary' : 'ghost'}
                    className='h-10 w-full justify-start'
                >
                    <div className='flex w-full items-center justify-between'>
                        <div className='flex items-center'>
                            <span className='mr-4'>
                                <Icon size={18} />
                            </span>
                            <p
                                className={cn(
                                    'max-w-[150px] truncate',
                                    'translate-x-0 opacity-100'
                                )}
                            >
                                {label}
                            </p>
                        </div>
                        <div
                            className={cn(
                                'whitespace-nowrap',
                                isCollapsed
                                    ? 'translate-x-0 text-xs opacity-100'
                                    : 'translate-x-0 text-xs opacity-100'
                            )}
                        >
                            <ChevronDown
                                size={18}
                                className='transition-transform duration-200'
                            />
                        </div>
                    </div>
                </Button>
            </CollapsibleTrigger>

            <CollapsibleContent className='data-[state=closed]:animate-collapsible-up data-[state=open]:animate-collapsible-down overflow-hidden'>
                {submenus.map(({ href, label, active }, index) => (
                    <Button
                        key={index}
                        variant={
                            (active === undefined && pathname === href) ||
                            active
                                ? 'secondary'
                                : 'ghost'
                        }
                        className='mb-1 h-10 w-full justify-start'
                        asChild
                        onClick={() => {
                            if (onClick) {
                                onClick();
                            } else {
                                navigate(href);
                            }
                        }}
                    >
                        <div>
                            <span className='ml-2 mr-4'>
                                <Dot size={18} />
                            </span>
                            <p
                                className={cn(
                                    'max-w-[170px] truncate',
                                    'translate-x-0 opacity-100'
                                )}
                            >
                                {label}
                            </p>
                        </div>
                    </Button>
                ))}
            </CollapsibleContent>
        </Collapsible>
    );
}
