import { PlusOutlined } from '@ant-design/icons';
import { toast } from 'sonner';

type Widget = {
    name: string;
    count: number;
};

export enum WidgetSize {
    SMALL = 'SMALL',
    DEFAULT = 'DEFAULT',
}

type WidgetProps = {
    widgets: Widget[];
    enableAdd?: boolean;
    size?: WidgetSize;
};

export const WidgetCard: React.FC<WidgetProps> = ({
    widgets,
    enableAdd = true,
    size = WidgetSize.DEFAULT,
}) => {
    const getWidgetSize = () => {
        switch (size) {
            case WidgetSize.SMALL:
                return 'max-h-[100px] min-h-[100px] min-w-[100px] max-w-[100px]';
            case WidgetSize.DEFAULT:
                return 'max-h-[150px] min-h-[150px] min-w-[150px] max-w-[150px]';
        }
    };

    const getWidgetPadding = () => {
        switch (size) {
            case WidgetSize.SMALL:
                return 'p-4';
            case WidgetSize.DEFAULT:
                return 'p-6';
        }
    };

    return (
        <div className='flex flex-wrap gap-4'>
            {widgets.map((widget, index) => {
                return (
                    <div
                        key={index}
                        className={`flex ${getWidgetSize()} flex-col justify-between rounded-lg bg-white ${getWidgetPadding()} sm:max-width-[50px] sm:max-height-[50px] shadow`}
                    >
                        <span className='w-full overflow-hidden text-ellipsis text-xs font-semibold text-gray-500'>
                            {widget.name}
                        </span>
                        <span className='text-right align-bottom text-xl font-bold'>
                            {widget.count}
                        </span>
                    </div>
                );
            })}

            {enableAdd && (
                <div
                    onClick={() =>
                        toast.info('This feature is not available yet.')
                    }
                    className='flex max-h-[150px] min-h-[150px] min-w-[150px] max-w-[150px] flex-col justify-between rounded-lg border-dashed border-gray-400 p-6 text-gray-400 shadow hover:border-gray-800 hover:text-gray-800'
                >
                    <PlusOutlined className='h-full w-full justify-center text-3xl ' />
                </div>
            )}
        </div>
    );
};
