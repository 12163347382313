import { useQuery } from '@tanstack/react-query';
import { toast } from 'sonner';
import { useLinkService } from 'src/app/_api_adb2c/purchase/link/hooks/use-link-service';
import { useRulesetService } from 'src/app/_api_adb2c/workspace/ruleset/hooks/use-ruleset-service';
import { useContextStore } from 'src/app/stores/context-store';
import { SalesOrderModel } from '../models/sales-order.model';
import { SALES_ORDERS_QUERY_KEY } from './sales-order-query-keys';
import { useSalesOrderService } from './use-sales-order-service';
import { usePurchaseService } from 'src/app/_api_adb2c/purchase/purchase/hooks/use-purchase-service';

export function useSalesOrders(delegateId?: string) {
    const { service } = useSalesOrderService();
    const { service: linkService } = useLinkService();
    const { service: rulesetService } = useRulesetService();
    const { service: purchaseService } = usePurchaseService();
    const context = useContextStore();

    return useQuery<SalesOrderModel[]>({
        queryKey: SALES_ORDERS_QUERY_KEY(
            delegateId || context.workspace?._id || ''
        ),
        queryFn: async () => {
            const orders: SalesOrderModel[] = await service.list(
                delegateId || context.workspace?._id || ''
            );

            const r = await Promise.allSettled(
                orders.map(async (order) => {
                    const links = await linkService.getLinked(
                        delegateId || context.workspace?._id || '',
                        order.purchaseOrderId
                    );

                    const purchase = await purchaseService.get(
                        order.customer.buyer._id,
                        order.purchaseOrderId,
                        '1'
                    );

                    return {
                        ...order,
                        links,
                        purchase: purchase,
                    };
                })
            );

            const linkedOrders = r.filter((x) => x.status === 'fulfilled');

            const rulesId = linkedOrders
                .map((order) => {
                    const resp =
                        order as PromiseFulfilledResult<SalesOrderModel>;

                    return resp.value.rules?.[0];
                })
                .filter((x) => x);

            const rulesets = await rulesetService.batch({
                targets: rulesId,
            });

            const responses = linkedOrders.map((order) => {
                const o = order as PromiseFulfilledResult<SalesOrderModel>;

                const rule = o.value.rules?.[0];

                return {
                    ...o.value,
                    ruleset: rulesets.find((x) => x._id === rule),
                };
            });

            return responses;
        },
        onError: (err) => {
            const error = err as Error;

            toast.error(`Failed to fetch sales orders: ${error.message}`);
        },
    });
}
