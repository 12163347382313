import { useAccount } from '@azure/msal-react';
import useAppConfig from 'src/infrastructure/hooks/use-config.hook';
import { LinkService } from '../link.service';
import { useMemo } from 'react';

export function useLinkService() {
    const config = useAppConfig();
    const account = useAccount();

    const service = useMemo(() => {
        if (!account?.idToken) throw new Error('Session Expired');

        return new LinkService(config, account.idToken);
    }, [account, config]);

    return { service };
}
