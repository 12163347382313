import { useQuery } from '@tanstack/react-query';
import { toast } from 'sonner';
import { useContextStore } from 'src/app/stores/context-store';
import { WorkspaceRulesetModel } from '../ruleset.model';
import { RULESETS_QUERY_KEY } from './ruleset-query-keys';
import { useRulesetService } from './use-ruleset-service';

export function useRulesets(delegateId?: string) {
    const { service } = useRulesetService();
    const context = useContextStore();

    return useQuery<WorkspaceRulesetModel[]>({
        queryKey: RULESETS_QUERY_KEY(
            delegateId || context.workspace?._id || ''
        ),
        queryFn: async () => {
            return await service.list(
                delegateId || context.workspace?._id || ''
            );
        },
        onError: (err) => {
            const error = err as Error;
            toast.error(`Failed to load rulesets: ${error.message}`);
        },
    });
}
