import { zodResolver } from '@hookform/resolvers/zod';
import { ColumnFiltersState, Table } from '@tanstack/react-table';
import { X } from 'lucide-react';
import { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';
import { Badge } from 'src/components/ui/badge';
import { Button } from 'src/components/ui/button';
import { Form } from 'src/components/ui/form';
import { z } from 'zod';
import { SelectInput } from '../../Form/SelectInput';
import { TextInput } from '../../Form/TextInput';

interface Props<TData> {
    table: Table<TData>;
    columnFilters: ColumnFiltersState;
}

const formSchema = z.object({
    label: z.string().optional(),
    value: z.string().optional(),
});

function capitalizeFirstLetter(string: string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export function SearchFilter<TData>({ table, columnFilters }: Props<TData>) {
    const [, setSearchParams] = useSearchParams();

    const columns = useMemo(
        () =>
            table.getAllColumns().filter((col) => {
                return col.getCanFilter();
            }),
        [table]
    );

    const form = useForm<z.infer<typeof formSchema>>({
        mode: 'onChange',
        resolver: zodResolver(formSchema),
    });

    const handleSubmit = async (data: z.infer<typeof formSchema>) => {
        if (!data.label && !data.value) return;

        table.getColumn(data.label || '')?.setFilterValue(data.value);

        setSearchParams((params) => {
            params.set(data.label || '', data.value || '');

            return params;
        });

        reset();
    };

    const reset = () => {
        form.reset({
            label: '',
            value: '',
        });
    };

    return (
        <div>
            <Form {...form}>
                <form
                    id='my-form-filter'
                    onSubmit={form.handleSubmit(handleSubmit)}
                >
                    <div className='flex items-center gap-4'>
                        <div className='flex'>
                            <SelectInput
                                name='label'
                                className='w-24 min-w-[96px]'
                                optionClassName='capitalize'
                                options={columns.map((column) => ({
                                    label: capitalizeFirstLetter(column.id),
                                    value: column.id,
                                }))}
                            />

                            <TextInput
                                inputName='value'
                                className='w-32 min-w-[128px]'
                            />
                        </div>

                        <Button
                            size='sm'
                            variant='default'
                            type='submit'
                            form='my-form-filter'
                        >
                            Submit
                        </Button>
                    </div>
                </form>
            </Form>

            {columnFilters.map((filter) => {
                return (
                    <Badge className='gap-2' variant='outline'>
                        <span className='text-xs capitalize'>
                            {filter.id}: {filter.value as string}
                        </span>
                        <X
                            className='h-4 w-4'
                            onClick={() =>
                                table.getColumn(filter.id)?.setFilterValue('')
                            }
                        />
                    </Badge>
                );
            })}
        </div>
    );
}
