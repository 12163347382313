import { zodResolver } from '@hookform/resolvers/zod';
import {
    ColumnDef,
    flexRender,
    getCoreRowModel,
    useReactTable,
} from '@tanstack/react-table';
import { Plus, Trash } from 'lucide-react';
import { useFieldArray, useForm } from 'react-hook-form';
import { toast } from 'sonner';
import {
    SupportDocType,
    SupportDocTypeLabel,
} from 'src/app/_api_adb2c/shared/support-doc-type.enum';
import { useRulesets } from 'src/app/_api_adb2c/workspace/ruleset/hooks/use-rulesets';
import { useUpdateRuleset } from 'src/app/_api_adb2c/workspace/ruleset/hooks/use-update-ruleset';
import {
    WorkspaceRulesetEntryModel,
    WorkspaceRulesetModel,
} from 'src/app/_api_adb2c/workspace/ruleset/ruleset.model';
import {
    SupplyChainNodeType,
    SupplyChainNodeTypeLabel,
} from 'src/app/_api_adb2c/workspace/shared/enum/supply-chain-node-type.enum';
import { MultiSelectInputV2 } from 'src/app/components/Form/MultiSelectInputV2';
import { SelectInput } from 'src/app/components/Form/SelectInput';
import { TextInput } from 'src/app/components/Form/TextInput';
import { Button } from 'src/components/ui/button';
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableHeader,
    TableRow,
} from 'src/components/ui/table';
import { z } from 'zod';
import { FormDialog } from './create-ruleset';
import { useSearchParams } from 'react-router-dom';

interface Props {
    open: boolean;
    onClose: () => void;
    ruleset: WorkspaceRulesetModel;
}

const formSchema = z.object({
    name: z.string(),
    documents: z.array(
        z.object({
            _id: z.string(),
            document: z.string(),
            processes: z.array(z.string()),
        })
    ),
    code: z.string(),
    description: z.string().optional(),
});

export const EditRuleset = ({ open, onClose, ruleset }: Props) => {
    const [searchParams] = useSearchParams();
    const delegateId = searchParams.get('delegateId') || '';
    const { data: rulesets } = useRulesets();
    const { mutateAsync, isLoading } = useUpdateRuleset();

    const form = useForm<z.infer<typeof formSchema>>({
        mode: 'onChange',
        resolver: zodResolver(formSchema),
        defaultValues: {
            name: ruleset.name,
            documents: ruleset.rules,
            code: ruleset.code,
            description: ruleset.description,
        },
    });

    const validateRulesetName = (
        ruleset: WorkspaceRulesetModel,
        name: string
    ) => {
        if (ruleset.name === name) {
            return false;
        }

        return rulesets?.find((r) => r.name === name);
    };

    const onSubmit = async (data: z.infer<typeof formSchema>) => {
        const isExist = validateRulesetName(ruleset, data.name);

        if (isExist) {
            toast.warning(`Ruleset with name ${data.name} already exists`);
            return;
        }

        await mutateAsync({
            id: ruleset._id,
            delegateId: delegateId,
            body: {
                rules: data.documents,
                name: data.name,
                code: data.code,
                description: data.description,
            },
        });

        reset();
    };

    const { fields, append, remove } = useFieldArray({
        control: form.control,
        name: 'documents',
    });

    const columns: ColumnDef<WorkspaceRulesetEntryModel>[] = [
        {
            id: 'document',
            header: 'Document Type',
            size: 100,
            cell: ({ row }) => {
                return (
                    <SelectInput
                        name={`documents[${row.index}].document`}
                        options={Object.values(SupportDocType).map((v) => ({
                            label: SupportDocTypeLabel[v],
                            value: v,
                        }))}
                    />
                );
            },
        },
        {
            id: 'processes',
            header: 'Applies To',
            size: 200,
            cell: ({ row }) => {
                return (
                    <MultiSelectInputV2
                        name={`documents[${row.index}].processes`}
                        className='overflow-hidden'
                        options={Object.values(SupplyChainNodeType).map(
                            (value) => ({
                                label: SupplyChainNodeTypeLabel[value],
                                value,
                            })
                        )}
                    />
                );
            },
        },
        {
            id: 'actions',
            header: 'Actions',
            size: 10,
            cell: ({ row }) => {
                return (
                    <Button onClick={() => remove(row.index)} variant='outline'>
                        <Trash className='h-4 w-4' />
                    </Button>
                );
            },
        },
    ];

    const table = useReactTable({
        columns,
        data: fields,
        getCoreRowModel: getCoreRowModel(),
    });

    const reset = () => {
        form.reset();
        onClose();
        // setOpen(false);
    };

    return (
        <FormDialog
            open={open}
            onClose={reset}
            title='Edit Ruleset'
            schema={formSchema}
            onSubmit={onSubmit}
            size='medium' // specify the size here
            className='grid grid-cols-1 gap-4'
            isLoading={isLoading}
            defaultValues={form.getValues()}
        >
            <TextInput inputName='name' label='Name' />
            <TextInput inputName='code' label='Code' />
            <TextInput inputName='description' label='Description' />

            <div className='overflow-x-auto'>
                <Table
                    className='min-h-[240px] w-full border'
                    parentClassName='max-h-[240px]'
                >
                    <TableHeader>
                        {table.getHeaderGroups().map((headerGroup) => (
                            <TableRow key={headerGroup.id}>
                                {headerGroup.headers.map((header) => (
                                    <TableHead
                                        key={header.id}
                                        className='bg-red-800 text-xs text-white'
                                        style={{
                                            width: header.column.getSize()
                                                ? `${header.column.getSize()}px`
                                                : 'auto',
                                        }}
                                    >
                                        {header.isPlaceholder
                                            ? null
                                            : flexRender(
                                                  header.column.columnDef
                                                      .header,
                                                  header.getContext()
                                              )}
                                    </TableHead>
                                ))}
                            </TableRow>
                        ))}
                    </TableHeader>

                    <TableBody>
                        {table.getRowModel().rows?.length ? (
                            table.getRowModel().rows.map((row) => (
                                <TableRow
                                    key={row.id}
                                    data-state={
                                        row.getIsSelected() && 'selected'
                                    }
                                >
                                    {row.getVisibleCells().map((cell) => (
                                        <TableCell
                                            key={cell.id}
                                            className='overflow-hidden align-top text-xs'
                                            style={{
                                                width: cell.column.getSize()
                                                    ? `${cell.column.getSize()}px`
                                                    : 'auto',
                                            }}
                                        >
                                            {flexRender(
                                                cell.column.columnDef.cell,
                                                cell.getContext()
                                            )}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            ))
                        ) : (
                            <TableRow>
                                <TableCell
                                    colSpan={columns.length}
                                    className='h-24 text-center'
                                >
                                    No data
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </div>

            <Button
                size='sm'
                variant={'outline'}
                className='absolute bottom-[25px]'
                type='button'
                onClick={() =>
                    append({
                        _id: '',
                        document: '',
                        processes: [],
                    })
                }
            >
                <Plus size={16} />
            </Button>
        </FormDialog>
    );
};
