import { CaretSortIcon } from '@radix-ui/react-icons';
import { ColumnDef } from '@tanstack/react-table';
import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useProduct } from 'src/app/_api_adb2c/product/product/hooks/use-product';
import { ProductBillOfMaterialEntry } from 'src/app/_api_adb2c/product/product/models/product.model';
import {
    ProductUom,
    ProductUomLabels,
} from 'src/app/_api_adb2c/purchase/purchase/enums/product-uom.enum';
import { DetailLabel } from 'src/app/components-v2/detail-label';
import { VirtualTable } from 'src/app/components-v2/virtual-table';
import { Button } from 'src/components/ui/button';

export const ProductApproval = () => {
    const { id } = useParams<{ id: string }>();
    const { data } = useProduct(id || '');

    const externalReference = useMemo(() => {
        return data?.externalReferences?.find(
            (x) => x.source === 'externalReferenceCode'
        )?.value;
    }, [data]);

    const productNumber = useMemo(() => {
        return data?.externalReferences?.find(
            (x) => x.source === 'externalDataId'
        )?.value;
    }, [data]);

    const grossWeight = useMemo(() => {
        const version = data?.versions?.[data?.versions.length - 1];
        if (!version) return 0;

        const grossWeight = version.billOfMaterials?.reduce((acc, x) => {
            return acc + (x.weight || 0) / (x.consumption || 1);
        }, 0);

        return grossWeight || 0;
    }, [data]);

    const memoizedMaterials = useMemo<ProductBillOfMaterialEntry[]>(() => {
        const version = data?.versions?.[data.versions.length - 1];
        if (!version) return [];
        return version.billOfMaterials || [];
    }, [data]);

    const materialColumns: ColumnDef<ProductBillOfMaterialEntry>[] = [
        {
            id: 'component',
            header: ({ column }) => (
                <Button
                    size='sm'
                    variant='ghost'
                    onClick={() =>
                        column.toggleSorting(column.getIsSorted() === 'asc')
                    }
                >
                    <span>Component</span>
                    <CaretSortIcon width={16} />
                </Button>
            ),
            accessorFn: (row) => row.name,
        },
        {
            id: 'description',
            header: ({ column }) => (
                <Button
                    size='sm'
                    variant='ghost'
                    onClick={() =>
                        column.toggleSorting(column.getIsSorted() === 'asc')
                    }
                >
                    <span>Description</span>
                    <CaretSortIcon width={16} />
                </Button>
            ),
            accessorFn: (row) => row.description ?? row.material.description,
        },
        {
            id: 'consumption',
            header: ({ column }) => (
                <Button
                    size='sm'
                    variant='ghost'
                    onClick={() =>
                        column.toggleSorting(column.getIsSorted() === 'asc')
                    }
                >
                    <span>Consumption</span>
                    <CaretSortIcon width={16} />
                </Button>
            ),
            accessorFn: (row) => row.consumption,
        },
        {
            id: 'uom',
            header: ({ column }) => (
                <Button
                    size='sm'
                    variant='ghost'
                    onClick={() =>
                        column.toggleSorting(column.getIsSorted() === 'asc')
                    }
                >
                    <span>UOM</span>
                    <CaretSortIcon width={16} />
                </Button>
            ),
            accessorFn: (row) =>
                row.unitOfMeasurement
                    ? ProductUomLabels[row.unitOfMeasurement as ProductUom]
                    : '',
        },
        {
            id: 'weight',
            header: ({ column }) => (
                <Button
                    size='sm'
                    variant='ghost'
                    onClick={() =>
                        column.toggleSorting(column.getIsSorted() === 'asc')
                    }
                >
                    <span>Weight</span>
                    <CaretSortIcon width={16} />
                </Button>
            ),
            accessorFn: (row) => row.weight,
        },
        {
            id: 'cost',
            header: ({ column }) => (
                <Button
                    size='sm'
                    variant='ghost'
                    onClick={() =>
                        column.toggleSorting(column.getIsSorted() === 'asc')
                    }
                >
                    <span>Cost (USD)</span>
                    <CaretSortIcon width={16} />
                </Button>
            ),
            accessorFn: (row) => row.materialCost,
        },
    ];

    if (!data) return null;

    return (
        <div className='container mx-auto p-4'>
            <div className='grid grid-cols-1 gap-4'>
                <div className='grid grid-cols-1 rounded-lg bg-white px-4 py-2 shadow-xl'>
                    <div className='py-2'>
                        <span className='text-xs font-bold underline decoration-red-800 decoration-2 underline-offset-2'>
                            Product Information
                        </span>
                    </div>

                    <div className='grid grid-cols-4 gap-4'>
                        <DetailLabel title='Product Number'>
                            {productNumber}
                        </DetailLabel>
                        <DetailLabel title='Unit of Measurement'>
                            {data.unitOfMeasurement
                                ? ProductUomLabels[
                                      data.unitOfMeasurement as ProductUom
                                  ]
                                : '--'}
                        </DetailLabel>
                        <DetailLabel title='External Sys Number'>
                            {externalReference}
                        </DetailLabel>
                        <DetailLabel title='Product Name'>
                            {data.name}
                        </DetailLabel>
                        <DetailLabel title='Gross Weight'>
                            {grossWeight}
                        </DetailLabel>
                        <DetailLabel title='UPC/EAN'>{data.upc}</DetailLabel>
                        <DetailLabel title='Collection'>
                            {data.collection}
                        </DetailLabel>
                        <DetailLabel title='Product Category'>
                            {data?.category?.name || data.category?.code}
                        </DetailLabel>
                        <DetailLabel title='HS Code'>
                            {data?.hsCode}
                        </DetailLabel>
                    </div>
                </div>

                <div className='flex h-full flex-col'>
                    <div className='flex w-full items-center justify-between rounded-t-lg border bg-red-800 px-4 py-2'>
                        <span className='text-sm font-bold text-white'>
                            Bill of Material
                        </span>
                    </div>

                    <VirtualTable
                        columns={materialColumns}
                        data={memoizedMaterials}
                        parentClassName='flex-1 max-h-[400px]'
                    />
                </div>
            </div>
        </div>
    );
};
