import { create } from 'zustand';
import { persist } from 'zustand/middleware';

interface IBreadcrumbItem {
    label: string;
    navigateTo?: string;
}

interface BreadcrumbState {
    breadcrumbs: IBreadcrumbItem[];
    setBreadcrumbs: (breadcrumb: IBreadcrumbItem[]) => void;
}

export const useBreadcrumbStore = create<BreadcrumbState>()(
    persist(
        (set) => ({
            breadcrumbs: [],
            setBreadcrumbs: (breadcrumbs: IBreadcrumbItem[]) =>
                set({ breadcrumbs }),
        }),
        {
            name: 'breadcrumb-store',
            getStorage: () => sessionStorage,
        }
    )
);
