import { useQuery } from '@tanstack/react-query';
import { toast } from 'sonner';
import { useContextStore } from 'src/app/stores/context-store';
import { PurchaseOrderTraceModel } from '../models/purchase.model';
import { PURCHASE_TRACE_QUERY_KEY } from './purchase-query-keys';
import { usePurchaseService } from './use-purchase-service';

export function usePurchaseTrace(
    id: string,
    delegateId?: string,
    diversion?: string
) {
    const { service } = usePurchaseService();
    const context = useContextStore();

    return useQuery<PurchaseOrderTraceModel[]>({
        queryKey: PURCHASE_TRACE_QUERY_KEY(
            delegateId || context.workspace?._id || '',
            id
        ),
        queryFn: async () => {
            return await service.trace(
                delegateId || context.workspace?._id || '',
                id,
                diversion
            );
        },
        onError: (err) => {
            const error = err as Error;

            toast.error(`Failed to load purchase trace: ${error.message}`);
        },
        enabled: !!id,
    });
}
