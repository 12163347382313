import { zodResolver } from '@hookform/resolvers/zod';
import { Ticket } from 'lucide-react';
import { useEffect, useState } from 'react';
import { useForm, useFormContext } from 'react-hook-form';
import { ContactModel } from 'src/app/_api_adb2c/workspace/shared/model/contact.model';
import { TextInput } from 'src/app/components/Form/TextInput';
import { Button } from 'src/components/ui/button';
import {
    Dialog,
    DialogContent,
    DialogFooter,
    DialogHeader,
    DialogTrigger,
} from 'src/components/ui/dialog';
import {
    Form,
    FormField,
    FormItem,
    FormLabel,
    FormMessage,
} from 'src/components/ui/form';
import { z } from 'zod';

interface Props {
    name: string;
    label: string;
    header: string;
    disabled?: boolean;
    readOnly?: boolean;
}

const formSchema = z.object({
    email: z.string().email().nullable().optional(),
    firstName: z.string().nullable(),
    lastName: z.string().nullable(),
    phone: z.string().nullable(),
});

export const ContactInput = ({
    name,
    label,
    header,
    disabled = false,
    readOnly = false,
}: Props) => {
    const [open, setOpen] = useState(false);
    const { control, setValue, getValues } = useFormContext();

    const existingValues: ContactModel = getValues(name);

    const form = useForm<z.infer<typeof formSchema>>({
        mode: 'onChange',
        resolver: zodResolver(formSchema),
        defaultValues: {
            firstName: existingValues?.firstName || '',
            lastName: existingValues?.lastName || '',
            email: existingValues?.email || '',
        },
    });

    const onSubmit = (data: z.infer<typeof formSchema>) => {
        setValue(name, data);
        reset();
    };

    const reset = () => {
        setOpen(false);
        form.reset();
    };

    useEffect(() => {
        if (open) {
            form.reset(existingValues);
        }
    }, [open, existingValues, form]);

    if (disabled && !readOnly) {
        return (
            <FormField
                control={control}
                name={name}
                render={({ field }) => (
                    <FormItem className=''>
                        <FormLabel className='text-xs font-bold'>
                            {label}
                        </FormLabel>
                        <br />
                        <span className='flex h-7 w-full py-1 text-xs'>
                            {field.value?.email || '-'}
                        </span>
                    </FormItem>
                )}
            />
        );
    }

    return (
        <Dialog
            open={open}
            onOpenChange={(value) => {
                if (!value) {
                    reset();
                }
            }}
        >
            <DialogTrigger asChild onClick={() => setOpen(true)}>
                <FormItem>
                    <FormLabel className='text-xs'>{label}</FormLabel>
                    <Button
                        type='button'
                        variant='outline'
                        className='flex w-full gap-2 text-xs'
                        data-testid='contact-input-button'
                    >
                        <span>{label}</span>
                        <Ticket className='h-4 w-4' />
                    </Button>
                    <FormMessage />
                </FormItem>
            </DialogTrigger>

            <DialogContent>
                <DialogHeader>
                    <span className='font-bold'>{header}</span>
                </DialogHeader>

                <Form {...form}>
                    <form id='inner-form'>
                        <TextInput inputName='email' label='Email' />
                        <TextInput inputName='firstName' label='First Name' />
                        <TextInput inputName='lastName' label='Last Name' />
                        {/* <TextInput name='phoneNumber' label='Phone Number' /> */}
                    </form>
                </Form>

                <DialogFooter>
                    <Button
                        type='button'
                        data-testid='contact-input-cancel'
                        variant='outline'
                        onClick={reset}
                    >
                        Cancel
                    </Button>

                    <Button
                        // Reason for this not being type submit is because submit will trigger outer form too.
                        data-testid='contact-input-submit'
                        type='button'
                        onClick={() => onSubmit(form.getValues())}
                    >
                        Submit
                    </Button>
                </DialogFooter>
            </DialogContent>
        </Dialog>
    );
};
