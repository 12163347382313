import { InfoIcon } from 'lucide-react';
import { Input, InputProps } from 'src/components/ui/input';
import {
    Tooltip,
    TooltipContent,
    TooltipProvider,
    TooltipTrigger,
} from 'src/components/ui/tooltip';
import { cn } from 'src/lib/utils';

const sizes = {
    xs: 'text-xs',
    sm: 'text-sm',
    md: 'text-base',
};

interface StateTextInputProps extends Omit<InputProps, 'size'> {
    label?: string;
    description?: string;
    size?: keyof typeof sizes;
    tooltip?: string;
    value: string | number;
    onValueChange: (value: string) => void;
}

export function TextInput({
    label,
    description,
    size = 'xs',
    placeholder = '-',
    tooltip,
    value,
    onValueChange,
    className,
    ...props
}: StateTextInputProps) {
    return (
        <div className='flex flex-col gap-1.5'>
            {label && (
                <label className={cn('font-medium', sizes[size])}>
                    <span>
                        {label}{' '}
                        {tooltip && (
                            <TooltipProvider>
                                <Tooltip delayDuration={100}>
                                    <TooltipTrigger type='button'>
                                        <InfoIcon className='h-3 w-3' />
                                    </TooltipTrigger>
                                    <TooltipContent>
                                        <p>{tooltip}</p>
                                    </TooltipContent>
                                </Tooltip>
                            </TooltipProvider>
                        )}
                    </span>
                </label>
            )}
            <Input
                {...props}
                className={cn('h-8', sizes[size], className)}
                value={value}
                onChange={(e) => onValueChange(e.target.value)}
                placeholder={placeholder}
            />
            {description && (
                <p className={cn('text-xs text-muted-foreground')}>
                    {description}
                </p>
            )}
        </div>
    );
}
