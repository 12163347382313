import { ServiceConfigInterface } from 'src/domain/interfaces/config.context.interface';
import { ApiServer } from 'src/infrastructure/clients/api.server';
import {
    CreateDelegationParams,
    DelegationModel,
    UpdateDelegationParams,
} from './delegation.model';

export class DelegationApiClient extends ApiServer<DelegationModel> {
    constructor(config: ServiceConfigInterface, token: string) {
        super(token, config, 'workspaces', 'delegations');
    }

    create(workspace: string, data: CreateDelegationParams) {
        console.log(`client<delegation>| create(): Enter`);
        console.log(`client<delegation>| create(): $workspace = ${workspace}`);
        console.log(
            `client<delegation>| create(): $data = ${JSON.stringify(data)}`
        );

        return super.post('create', workspace, data);
    }

    update(workspace: string, id: number, data: UpdateDelegationParams) {
        console.log(`client<delegation>| update(): Enter`);
        console.log(`client<delegation>| update(): $workspace = ${workspace}`);
        console.log(`client<delegation>| update(): $id = ${id}`);
        console.log(
            `client<delegation>| update(): $data = ${JSON.stringify(data)}`
        );

        return super.patch('update', workspace, id, data);
    }

    remove(workspace: string, id: number) {
        console.log(`client<delegation>| remove(): Enter`);
        console.log(`client<delegation>| remove(): $workspace = ${workspace}`);
        console.log(`client<delegation>| remove(): $id = ${id}`);

        return super.delete('remove', workspace, id);
    }

    list(workspace: string) {
        console.log(`client<delegation>| list(): Enter`);
        console.log(`client<delegation>| list(): $workspace = ${workspace}`);

        return super.get('list', workspace);
    }
}
