import { useQueryClient } from '@tanstack/react-query';
import { toast } from 'sonner';
import { useCategoryService } from 'src/app/_api_adb2c/product/category/hooks/use-category-service';
import { useCreateProduct } from 'src/app/_api_adb2c/product/product/hooks/use-create-product';
import { useProductService } from 'src/app/_api_adb2c/product/product/hooks/use-product-service';
import {
    IExternalReference,
    IndustryType,
} from 'src/app/_api_adb2c/product/product/models/product.model';
import { ProductUom } from 'src/app/_api_adb2c/purchase/purchase/enums/product-uom.enum';
import { PurchaseOrderNature } from 'src/app/_api_adb2c/purchase/purchase/enums/purchase-order-nature.enum';
import { useCreatePurchase } from 'src/app/_api_adb2c/purchase/purchase/hooks/use-create-purchase';
import { usePurchaseService } from 'src/app/_api_adb2c/purchase/purchase/hooks/use-purchase-service';
import { PurchaseModel } from 'src/app/_api_adb2c/purchase/purchase/models/purchase.model';
import { useSupplierService } from 'src/app/_api_adb2c/purchase/suppliers/hooks/use-supplier-service';
import { SupplierModel } from 'src/app/_api_adb2c/purchase/suppliers/models/supplier.model';
import { useSupplierConnect } from 'src/app/_api_adb2c/workspace/account/hooks/use-supplier-connect';
import { useSupplierInvite } from 'src/app/_api_adb2c/workspace/account/hooks/use-supplier-invite';
import { UserCreateDataObject } from 'src/app/_api_adb2c/workspace/users/user-create-data-object';
import { useWorkspaceService } from 'src/app/_api_adb2c/workspace/workspace/hooks/use-workspace-service';
import { WorkspaceModel } from 'src/app/_api_adb2c/workspace/workspace/workspace.model';
import { useContextStore } from 'src/app/stores/context-store';
import { read, utils } from 'xlsx';
import countries from '../../../../infrastructure/config/data/countries.json';
import { ImportErrorMessage } from '../Details/purchase-cascade-import-error';
import { useState } from 'react';
import { ProductCategoriesModel } from 'src/app/_api_adb2c/product/category/category.model';
import { useSearchService } from 'src/app/_api_adb2c/workspace/search/hooks/use-search-service';
import { useDelegationService } from 'src/app/_api_adb2c/workspace/delegations/hooks/use-delegation-service';

export interface SheetData {
    'PO Factory Name': string;
    'Main Category [Optional]': string;
    'PO Number [Optional]': string;
    'Item Number [Optional]': string;
    'Product Name': string;
    'Company Role/Purchase Process for Factory (Material Supplier)': string;
    'Factory Name (Material Supplier)': string;
    'Factory License Number (Material Supplier)': string;
    'Factory Country (Material Supplier)': string;
    'Factory Address (Material Supplier)': string;
    'Factory Contact First Name (Material Supplier) [Optional]': string;
    'Factory Contact Last Name (Material Supplier) [Optional]': string;
    'Factory Contact Email (Material Supplier) [Optional]': string;
    'Upper Tier Row Number [Optional]': string;
    __rowNum__: string;
    children: SheetData[];
    [key: string]: string | SheetData[];
}

function flatMapChildren(item: SheetData) {
    const flatList: SheetData[] = [];

    function recurse(item: SheetData) {
        flatList.push(item);
        item.children.forEach((child) => recurse(child));
    }

    recurse(item);

    return flatList;
}

const SheetName = 'Supplier Template';

export const convertToNested = (
    data: SheetData[],
    errorMessages: ImportErrorMessage[]
) => {
    const result: SheetData[] = [];
    const lookup: { [key: string]: SheetData } = {};

    const dataWithoutUpperTier = data.filter(
        (x) => !x['Upper Tier Row Number [Optional]']
    );

    const upperTierData = data
        .filter((x) => x['Upper Tier Row Number [Optional]'])
        .sort((a, b) => {
            const aSum =
                Number(a['Upper Tier Row Number [Optional]']) +
                Number(a['__rowNum__']);
            const bSum =
                Number(b['Upper Tier Row Number [Optional]']) +
                Number(b['__rowNum__']);
            return aSum - bSum;
        });

    for (const item of dataWithoutUpperTier) {
        const exist =
            lookup[
                item['Factory Name (Material Supplier)']?.toString()?.trim()
            ];

        if (!exist) {
            lookup[
                item['Factory Name (Material Supplier)']?.toString()?.trim()
            ] = {
                ...item,
                children: [],
            };
        }

        if (!item['PO Factory Name']?.toString()?.trim()) {
            lookup[
                item['Factory Name (Material Supplier)']?.toString()?.trim()
            ] = {
                ...item,
                children: [],
            };
        }
    }

    for (const item of dataWithoutUpperTier) {
        if (!item['PO Factory Name']?.toString()?.trim()) {
            result.push(
                lookup[
                    item['Factory Name (Material Supplier)']?.toString()?.trim()
                ]
            );
            continue;
        }

        const parent = lookup[item['PO Factory Name']?.toString()?.trim()];
        if (parent) {
            const parentProcess =
                parent[
                    'Company Role/Purchase Process for Factory (Material Supplier)'
                ];
            const childProcess =
                item[
                    'Company Role/Purchase Process for Factory (Material Supplier)'
                ];

            if (
                parent['Factory Name (Material Supplier)'] ===
                    item['Factory Name (Material Supplier)'] &&
                parentProcess === childProcess
            ) {
                errorMessages.push({
                    rowNum: Number(item.__rowNum__) + 1,
                    message: `Circular reference detected: ${item['PO Factory Name']} -> ${item['Factory Name (Material Supplier)']}`,
                });
            }

            const existing = result.find(
                (x) =>
                    x[
                        'Factory Name (Material Supplier)'?.toString()?.trim()
                    ] === item['PO Factory Name'?.toString()?.trim()]
            );

            if (existing) {
                existing.children.push({
                    ...item,
                    __rowNum__: item.__rowNum__,
                    children: [],
                });
            } else {
                const children = result.flatMap((x) => flatMapChildren(x));

                const existingChild = children.find(
                    (x) =>
                        x['Factory Name (Material Supplier)']
                            ?.toString()
                            ?.trim() ===
                        item['PO Factory Name']?.toString()?.trim()
                );

                if (existingChild) {
                    existingChild.children.push({
                        ...item,
                        __rowNum__: item.__rowNum__,
                        children: [],
                    });
                }
            }
        }
    }

    const flattenedResults = result.flatMap((res) => flatMapChildren(res));

    for (const item of upperTierData) {
        const existing = flattenedResults.find(
            (x) => x.__rowNum__ === item['Upper Tier Row Number [Optional]']
        );

        if (existing) {
            existing.children.push({
                ...item,
                __rowNum__: item.__rowNum__,
                children: [],
            });
        } else {
            result.forEach((x) => {
                findAndUpdateUpperTierRow(x, item);
            });
        }
    }

    return result;
};

const findAndUpdateUpperTierRow = (data: SheetData, item: SheetData) => {
    data.children.forEach((x) => {
        if (x.__rowNum__ === item['Upper Tier Row Number [Optional]']) {
            x.children.push({
                ...item,
                children: [],
            });
        } else {
            findAndUpdateUpperTierRow(x, item);
        }
    });
};

const validateExcel = async (
    data: SheetData[],
    errorMessages: ImportErrorMessage[]
) => {
    const validationErrors: ImportErrorMessage[] = [];

    data.forEach((item, index) => {
        const requiredFields = [
            'Factory Name (Material Supplier)',
            'Factory License Number (Material Supplier)',
            'Factory Country (Material Supplier)',
            'Factory Address (Material Supplier)',
            'Company Role/Purchase Process for Factory (Material Supplier)',
            'Product Name',
        ];

        requiredFields.forEach((field) => {
            if (!item[field]) {
                validationErrors.push({
                    rowNum: index + 2,
                    message: `${field} is required`,
                });
            }
        });
    });

    if (validationErrors.length > 0) {
        errorMessages.push(...validationErrors);
    }
};

export function useImportCascade() {
    const [isLoading, setIsLoading] = useState(false);

    const { service: workspaceService } = useWorkspaceService();
    const { service: purchaseService } = usePurchaseService();
    const { service: categoryService } = useCategoryService();
    const { service: productService } = useProductService();
    const { service: supplierService } = useSupplierService();
    const { service: searchService } = useSearchService();
    const { service: delegationService } = useDelegationService();

    const { mutateAsync: createProduct } = useCreateProduct();
    const { mutateAsync: invite } = useSupplierInvite();
    const { mutateAsync: connect } = useSupplierConnect();
    const { mutateAsync: createPurchase } = useCreatePurchase();

    const delay = (duration: number) =>
        new Promise((resolve) => setTimeout(resolve, duration));

    const store = useContextStore();
    const client = useQueryClient();

    const processExcel = async (
        data: SheetData[],
        purchaseOrder: PurchaseModel,
        workspace: WorkspaceModel
    ) => {
        for (const item of data) {
            console.debug(
                `Processing item: ${item['Factory Name (Material Supplier)']}`
            );
            console.debug(`processExcel() $data: ${data}`);
            const factoryPartner = await findOrCreatePartner(
                // workspace?._id || '',
                purchaseOrder.supplier.seller._id,
                item['Factory Name (Material Supplier)']?.toString()?.trim(),
                item,
                workspace,
                {
                    email: item[
                        'Factory Contact Email (Material Supplier) [Optional]'
                    ],
                    firstName:
                        item[
                            'Factory Contact First Name (Material Supplier) [Optional]'
                        ],
                    lastName:
                        item[
                            'Factory Contact Last Name (Material Supplier) [Optional]'
                        ],
                }
            );

            console.debug(
                `Processing category: ${item['Main Category [Optional]']}`
            );
            const category = await findOrCreateCategory(
                // purchaseOrder?.owner?.supplier?.seller?.id || '',
                purchaseOrder.supplier.seller._id,
                item
            );

            console.debug(`Processing product: ${item['Product Name']}`);
            // Creates the product with category info into the Factory Workspace
            const product = await findOrCreateProduct(
                // purchaseOrder?.owner?.supplier?.seller?.id || '',
                purchaseOrder.supplier.seller._id,
                item,
                category._id || ''
            );

            const purchase = await createPurchaseOrder(
                // purchaseOrder?.owner?.supplier?.seller?.id || '',
                purchaseOrder.supplier.seller._id,
                factoryPartner.workspaceId,
                item,
                {
                    id: product._id || '',
                    cost:
                        product.versions?.[product.versions.length - 1]
                            .costOfMaterials || 0,
                },
                purchaseOrder,
                purchaseOrder
            );

            if (item.children.length > 0) {
                await processExcel(item.children, purchase, workspace);
            }
        }
    };

    const createPurchaseOrder = async (
        workspaceId: string,
        supplierId: string,
        item: SheetData,
        product: { id: string; cost: number },
        purchaseOrder: PurchaseModel,
        parentPurchase?: PurchaseModel
    ) => {
        const purchase = await createPurchase({
            body: {
                supplier: supplierId,
                currency: parentPurchase?.currency || 'USD',
                items: [
                    {
                        product: product.id,
                        ppu: product.cost,
                        quantity: 1,
                        unit: ProductUom.PCS,
                    },
                ],
                rules: purchaseOrder.rules?.[0]
                    ? [purchaseOrder.rules?.[0]]
                    : [],
                processes:
                    item[
                        'Company Role/Purchase Process for Factory (Material Supplier)'
                    ].split(','),
                reference: item['PO Number [Optional]']
                    ? [
                          {
                              source: 'externalDataId',
                              value: item['PO Number [Optional]'],
                          },
                      ]
                    : [],
                shipToAddress: 'Filler Address',
                parent: parentPurchase?._id,
            },
            delegateId: workspaceId,
            diversion: '1',
        });

        const version = await purchaseService.get(
            workspaceId,
            purchase.id,
            '1'
        );

        return version;
    };

    const findPartner = async (
        workspaceId: string,
        name: string,
        registrationNumber: string
    ) => {
        const partners: SupplierModel[] = await supplierService.list(
            workspaceId,
            '1'
        );

        const existingPartner = partners.find((partner) => {
            return (
                partner.seller?.name?.toLocaleLowerCase() ===
                    name?.toLocaleLowerCase() ||
                partner.seller?.registrationNumber?.toLocaleLowerCase() ===
                    registrationNumber?.toLocaleLowerCase()
            );
        });

        return existingPartner
            ? {
                  ...existingPartner,
                  workspaceId: existingPartner.seller?._id || '',
                  supplierId: existingPartner._id || '',
              }
            : undefined;
    };

    const findOrCreatePartner = async (
        workspaceId: string,
        vendorName: string,
        data: SheetData,
        parentWorkspace: WorkspaceModel,
        contactInfo?: {
            email: string;
            firstName: string;
            lastName: string;
        }
    ) => {
        console.debug(`findOrCreatePartner() $data: ${JSON.stringify(data)}`);
        console.debug(`findOrCreatePartner() $vendorName: ${vendorName}`);
        console.debug(`findOrCreatePartner() $workspaceId: ${workspaceId}`);
        const parsedCountry = data['Factory Country (Material Supplier)']
            ?.toLocaleLowerCase()
            ?.toString()
            ?.trim();
        const country = countries.find(
            (x) =>
                x.name.toLocaleLowerCase() === parsedCountry ||
                x['alpha-2'].toLocaleLowerCase() === parsedCountry
        )?.['alpha-2'];
        console.debug(
            `findOrCreatePartner() $searchCountry: ${data['Factory Country (Material Supplier)']}`
        );
        console.debug(`findOrCreatePartner() $country: ${country}`);

        const registrationNumber = data[
            'Factory License Number (Material Supplier)'
        ]
            ?.toString()
            ?.trim();
        console.debug(
            `findOrCreatePartner() $registrationNumber: ${registrationNumber}`
        );

        const existingPartner = await findPartner(
            workspaceId,
            vendorName,
            registrationNumber
        );
        console.debug(
            `findOrCreatePartner() $existingPartner: ${existingPartner}`
        );

        if (existingPartner) {
            console.debug(
                `findOrCreatePartner() returning existingPartner: ${existingPartner}`
            );
            return existingPartner;
        }

        // Create Partner
        const workspaces: WorkspaceModel[] =
            await workspaceService.searchByCompanyName(
                encodeURIComponent(vendorName)
            );

        let workspace = workspaces.find(
            (x) => x.company?.demographics.name === vendorName
        );
        console.debug(`findOrCreatePartner() $workspace: ${workspace}`);

        if (!workspace) {
            console.debug(
                `findOrCreatePartner() searching by registration number: ${registrationNumber}`
            );
            workspace = await searchService.searchByRegistrationNumber(
                registrationNumber,
                country || ''
            );
            console.debug(`findOrCreatePartner() $workspace: ${workspace}`);
        }

        if (workspace) {
            console.debug(`findOrCreatePartner() checking delegation`);
            const delegation = await delegationService.list(workspaceId, '1');
            const isExist = delegation.find(
                (x) => x.delegatedTo._id === workspace?._id
            );

            console.debug(`findOrCreatePartner() $isExist: ${isExist}`);
            if (!isExist) {
                console.debug(`findOrCreatePartner() creating delegation`);
                await delegationService.create(
                    workspaceId,
                    {
                        delegatedTo: workspace._id,
                    },
                    '1'
                );
            }

            console.debug(`findOrCreatePartner() running connect`);
            // Run Connect
            await connect({
                delegate: workspaceId,
                supplier: workspace._id,
                delegateId: workspaceId,
                diversion: '1',
            });
        } else {
            // Run Invite
            const contact: UserCreateDataObject[] = [];

            if (store.user) {
                contact.push({
                    contact: {
                        email: store.user.contact.email,
                        firstName: store.user.contact.firstName,
                        lastName: store.user.contact.lastName,
                        device: {},
                    },
                });
            }

            if (contactInfo?.email && contactInfo?.firstName) {
                contact.push({
                    contact: {
                        email: contactInfo.email,
                        firstName: contactInfo.firstName,
                        lastName: contactInfo.lastName,
                        device: {},
                    },
                });
            }

            const country = countries.find(
                (x) =>
                    x.name.toLocaleLowerCase() ===
                    data['Factory Country (Material Supplier)']
                        .toLocaleLowerCase()
                        ?.toString()
                        ?.trim()
            )?.['alpha-2'];

            await invite({
                body: {
                    company: data['Factory Name (Material Supplier)']
                        ?.toString()
                        ?.trim(),
                    registrationNumber: data[
                        'Factory License Number (Material Supplier)'
                    ]
                        ?.toString()
                        ?.trim(),
                    country: country || '',
                    contact: contact.length > 0 ? contact : undefined,
                },
                delegateId: workspaceId,
                diversion: '1',
            });
        }

        await delay(2000);

        const partner = await findPartner(
            workspaceId,
            vendorName,
            registrationNumber
        );

        if (!partner) {
            throw new Error(`Failed to create partner: ${vendorName}`);
        }

        return partner;
    };

    const findOrCreateCategory = async (
        workspaceId: string,
        item: SheetData
    ) => {
        const categoryName =
            item['Main Category [Optional]'] || 'Placeholder Category';
        const parsedCategoryName = categoryName?.toString()?.trim();

        const existingCategories: ProductCategoriesModel[] =
            await categoryService.search(
                workspaceId,
                encodeURIComponent(parsedCategoryName),
                '1'
            );

        let category: ProductCategoriesModel;

        if (existingCategories.length === 0) {
            category = await categoryService.create(
                workspaceId,
                {
                    code: parsedCategoryName,
                    defaultUnitOfMeasurement: ProductUom.PCS,
                    name: parsedCategoryName,
                    description: `Category created from template - ${parsedCategoryName}`,
                    industry: IndustryType.OTHERS,
                    workspace: workspaceId,
                },
                '1'
            );
        } else {
            category = existingCategories[0];
        }

        return category;
    };

    const findOrCreateProduct = async (
        workspaceId: string,
        item: SheetData,
        categoryId: string
    ) => {
        const orderNature =
            item[
                'Company Role/Purchase Process for Factory (Material Supplier)'
            ];
        const name: string =
            orderNature === PurchaseOrderNature.PROCESS
                ? `Service - ${item['Product Name']}`
                : item['Product Name'];

        const parsedName = name?.toString()?.trim();

        let product = await productService.searchByName(
            workspaceId,
            parsedName
        );

        if (!product) {
            const externalReferences: IExternalReference[] = [];
            if (item['Item Number [Optional]']) {
                externalReferences.push({
                    source: 'externalDataId',
                    value: item['Item Number [Optional]'],
                });
            }

            product = await createProduct({
                delegateId: workspaceId,
                body: {
                    category: categoryId,
                    externalReferences,
                    name: parsedName,
                    description: '',
                    unitOfMeasurement: ProductUom.PCS,
                    loaderType: store.workspace?.supplyChainLoaderType || 0,
                    enabled: true,
                    tags: [],
                    workspace: workspaceId,
                    measureValue: 0,
                },
                diversion: '1',
            });

            await delay(2000);
        }

        return product;
    };

    const submit = async (id: string, purchaseOrderId: string, file: File) => {
        return new Promise((resolve, reject) => {
            setIsLoading(true);
            const fileReader = new FileReader();

            fileReader.readAsArrayBuffer(file);

            fileReader.onload = async (e) => {
                try {
                    const buffer = e.target?.result as ArrayBuffer;
                    const wb = read(buffer, { type: 'buffer' });

                    // const wsname = wb.SheetNames[0];
                    const ws = wb.Sheets[SheetName];

                    // /* Convert array of arrays */
                    const data: SheetData[] = utils.sheet_to_json(ws);
                    const filteredData = data
                        .filter(
                            (x: SheetData) =>
                                x['Factory Name (Material Supplier)'] ||
                                x['PO Factory Name']
                        )
                        .map((x) => ({
                            ...x,
                            __rowNum__: x.__rowNum__ + 1,
                        }));

                    const errorMessages: ImportErrorMessage[] = [];

                    const workspace = await workspaceService.get(id, '1');
                    const purchaseOrder: PurchaseModel =
                        await purchaseService.get(id, purchaseOrderId, '1');

                    await validateExcel(filteredData, errorMessages);

                    const nestedData = await convertToNested(
                        filteredData,
                        errorMessages
                    );

                    if (errorMessages.length > 0) {
                        reject(errorMessages);

                        return;
                    }

                    await processExcel(nestedData, purchaseOrder, workspace);

                    client.invalidateQueries({
                        queryKey: ['purchaseTrace'],
                    });

                    client.invalidateQueries({
                        queryKey: ['purchase'],
                    });

                    client.invalidateQueries({
                        queryKey: ['order'],
                    });

                    client.invalidateQueries({
                        queryKey: ['purchase-reverse'],
                    });

                    resolve(data);

                    toast.success(
                        'Successfully imported the template, please refresh the page'
                    );
                } catch (error) {
                    const err = error as Error;
                    toast.error(
                        `Failed to import the template: ${err.message}`
                    );

                    reject(err);
                } finally {
                    setIsLoading(false);
                }
            };

            fileReader.onerror = (error) => {
                reject(error); // Reject the promise if there's an error
            };
        });
    };

    return { submit, isLoading };
}
