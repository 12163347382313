import { useAccount } from '@azure/msal-react';
import { useMemo } from 'react';
import useAppConfig from 'src/infrastructure/hooks/use-config.hook';
import { DocumentService } from '../document.service';

export function useDocumentService() {
    const account = useAccount();
    const config = useAppConfig();

    const service = useMemo(() => {
        if (account?.idToken) {
            return new DocumentService(config, account.idToken);
        } else {
            throw new Error(
                'useDocumentService(): No account found in context'
            );
        }
    }, [account?.idToken, config]);

    return { service };
}
