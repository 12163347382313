import { useQuery } from '@tanstack/react-query';
import { toast } from 'sonner';
import { useContextStore } from 'src/app/stores/context-store';
import { useVirtualPurchaseService } from './use-virtual-purchase-service';
import { VIRTUAL_PURCHASE_ROOT_QUERY_KEY } from './virtual-purchase-query-key';
import { PurchaseModel } from '../../purchase/models/purchase.model';

export function useVirtualRoot(productId: string, delegateId?: string) {
    const { service } = useVirtualPurchaseService();
    const context = useContextStore();

    return useQuery<PurchaseModel>({
        queryKey: VIRTUAL_PURCHASE_ROOT_QUERY_KEY(
            delegateId || context.workspace?._id || '',
            productId
        ),
        queryFn: async () => {
            return await service.getRoot(
                delegateId || context.workspace?._id || '',
                productId
            );
        },
        onError: (error) => {
            const err = error as Error;

            toast.error(`Failed to fetch virtual root: ${err.message}`);
        },
    });
}
