import { ServiceConfigInterface } from 'src/domain/interfaces/config.context.interface';
import { ApiServer } from 'src/infrastructure/clients/api.server';
import { SolicitationCreateRequestObject } from './solicitation-create-request-object';

export class AccountApiClient extends ApiServer {
    constructor(config: ServiceConfigInterface, token: string) {
        super(token, config, 'workspaces', 'account');
    }

    invite(
        workspace: string,
        diversion: string = '0',
        body: SolicitationCreateRequestObject
    ) {
        console.log(`client<account>| invite(): Enter`);
        console.log(`client<account>| invite(): $workspace = ${workspace}`);
        console.log(
            `client<account>| invite(): $body = ${JSON.stringify(body)}`
        );
        console.log(`client<account>| invite(): $diversion = ${diversion}`);

        return super.post('invite', workspace, diversion, body);
    }

    connect(
        workspace: string,
        supplier: string,
        delegate: string,
        diversion = '0'
    ) {
        console.log(`client<account>| connect(): Enter`);
        console.log(`client<account>| connect(): $workspace = ${workspace}`);
        console.log(`client<account>| connect(): $supplier = ${supplier}`);
        console.log(`client<account>| connect(): $delegate = ${delegate}`);
        console.log(`client<account>| connect(): $diversion = ${diversion}`);

        return super.post('connect', workspace, supplier, delegate, {});
    }
}
