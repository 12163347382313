import { CaretSortIcon } from '@radix-ui/react-icons';
import { useCallback, useMemo, useState } from 'react';
import { useDebounce } from 'src/app/utils/use-debounce';
import {
    Accordion,
    AccordionContent,
    AccordionItem,
    AccordionTrigger,
} from 'src/components/ui/accordion';
import { Button } from 'src/components/ui/button';
import { Checkbox } from 'src/components/ui/checkbox';
import { Command, CommandInput } from 'src/components/ui/command';
import {
    Popover,
    PopoverContent,
    PopoverTrigger,
} from 'src/components/ui/popover';
import { cn } from 'src/lib/utils';
import { TreeNode } from '../Form/Tree';

interface Props extends Omit<React.HTMLAttributes<HTMLDivElement>, 'onChange'> {
    label?: string;
    options: TreeNode[];
    placeholder?: string;
    size?: keyof typeof sizes;
    value: TreeNode[];
    onChange: (nodes: TreeNode[], node: TreeNode) => void;
}

const sizes = {
    xs: 'text-xs',
    sm: 'text-sm',
    md: 'text-base',
};

export function TreeSelect({
    label,
    placeholder,
    className,
    options,
    onChange,
    value,
    size = 'xs',
    ...args
}: Props) {
    const [search, setSearch] = useState('');
    const [expandedItems, setExpandedItems] = useState<string[]>([]);
    const debouncedSearch = useDebounce(search, 500);

    const generateSelectedItemLabel = () => {
        return value?.map((item) => item.label).join(' > ');
    };

    const isNodeSelected = (node: TreeNode, trace: TreeNode[]) => {
        if (!value) return false;
        if (value.length !== trace.length) return false;

        return value.every((item, index) => item.value === trace[index].value);
    };

    const filterNodes = useCallback(
        (nodes: TreeNode[], searchTerm: string): TreeNode[] => {
            const response = nodes
                .map((node) => {
                    if (node.children) {
                        const filteredChildren = filterNodes(
                            node.children,
                            searchTerm
                        );

                        if (
                            filteredChildren.length > 0 ||
                            node.label
                                .toLowerCase()
                                .includes(searchTerm.toLowerCase())
                        ) {
                            return { ...node, children: filteredChildren };
                        }
                    } else if (
                        node.label
                            .toLowerCase()
                            .includes(searchTerm.toLowerCase())
                    ) {
                        return node;
                    }

                    return null;
                })
                .filter((node): node is TreeNode => node !== null);

            return response;
        },
        []
    );

    const filteredData = useMemo(
        () => filterNodes(options, debouncedSearch),
        [options, debouncedSearch, filterNodes]
    );

    const handleCheckboxChange = (node: TreeNode, trace: TreeNode[]) => {
        onChange(
            trace.map((x) => ({
                value: x.value,
                label: x.label,
            })),
            node
        );
    };

    const renderTree = (nodes: TreeNode[], trace: TreeNode[] = []) => {
        return (
            <Accordion
                type='multiple'
                value={expandedItems}
                onValueChange={setExpandedItems}
                className='overflow-auto'
            >
                {nodes.map((node) => {
                    const currentTrace = [...trace, node];

                    return node.children && node.children.length > 0 ? (
                        <AccordionItem value={node.value} key={node.value}>
                            <AccordionTrigger className='bg-red-light flex items-center justify-between rounded-lg fill-white px-2 py-2'>
                                <span className='text-xs font-bold uppercase'>
                                    {node.label}
                                </span>
                            </AccordionTrigger>
                            <AccordionContent className='px-4 pb-0'>
                                <div className='flex flex-col gap-2'>
                                    {renderTree(node.children, currentTrace)}
                                </div>
                            </AccordionContent>
                        </AccordionItem>
                    ) : (
                        <div
                            className='flex items-center gap-2 px-4 py-2 text-xs capitalize'
                            key={node.value}
                        >
                            <Checkbox
                                checked={isNodeSelected(node, currentTrace)}
                                onCheckedChange={() =>
                                    handleCheckboxChange(node, currentTrace)
                                }
                                disabled={node.disabled}
                                className={cn(
                                    'text-red-500',
                                    node.disabled &&
                                        'cursor-not-allowed opacity-50'
                                )}
                            />
                            <span>{node.label}</span>
                        </div>
                    );
                })}
            </Accordion>
        );
    };

    return (
        <div className={className} {...args}>
            {label && (
                <label className={cn('text-xs', sizes[size])}>{label}</label>
            )}

            <Popover>
                <PopoverTrigger asChild className='px-0'>
                    <Button
                        variant='outline'
                        size='sm'
                        data-testid='select-input'
                        className={cn(
                            'h-8 w-full pl-3 text-left font-normal capitalize',
                            !value && 'text-muted-foreground'
                        )}
                    >
                        <span className='max-w-[75%] truncate text-xs'>
                            {value && value.length > 0
                                ? generateSelectedItemLabel()
                                : 'Select'}
                        </span>
                        <CaretSortIcon className='ml-auto h-4 w-4 opacity-50' />
                    </Button>
                </PopoverTrigger>

                <PopoverContent className='p-0'>
                    <Command className='max-h-[300px] w-full overflow-auto'>
                        <CommandInput
                            placeholder='Search...'
                            className='h-4 text-xs'
                            onValueChange={(value) => {
                                setSearch(value);
                            }}
                        />
                        {renderTree(filteredData)}
                    </Command>
                </PopoverContent>
            </Popover>
        </div>
    );
}
