import { useQuery } from '@tanstack/react-query';
import { toast } from 'sonner';
import { useContextStore } from 'src/app/stores/context-store';
import { WorkspaceModel } from '../../workspace/workspace.model';
import { useUserService } from './use-user-service';
import { USER_WORKSPACES_QUERY_KEY } from './user-query-keys';

export function useUserWorkspaces() {
    const context = useContextStore();
    const { service } = useUserService();

    return useQuery<WorkspaceModel[]>({
        queryKey: USER_WORKSPACES_QUERY_KEY(context.workspace?._id || ''),
        queryFn: async () => {
            return await service.getWorkspaces();
        },
        onError: (err) => {
            const error = err as Error;

            toast.error(`Failed to fetch user information: ${error.message}`);
        },
    });
}
