import { zodResolver } from '@hookform/resolvers/zod';
import { ColumnDef } from '@tanstack/react-table';
import {
    ArrowLeftIcon,
    ArrowRightIcon,
    Check,
    LoaderCircle,
    X,
} from 'lucide-react';
import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';
import { useCreateLink } from 'src/app/_api_adb2c/purchase/link/hooks/use-create-link';
import { PurchaseOrderDataLinkDataObject } from 'src/app/_api_adb2c/purchase/link/requests/purchase-order-link-data-object';
import { usePurchases } from 'src/app/_api_adb2c/purchase/purchase/hooks/use-purchases';
import { ManifestItemModel } from 'src/app/_api_adb2c/purchase/purchase/models/manifest-item.model';
import { useSalesOrders } from 'src/app/_api_adb2c/sales/sales/hooks/use-sales-orders';
import { DetailLabel } from 'src/app/components-v2/detail-label';
import { VirtualTable } from 'src/app/components-v2/virtual-table';
import { SelectInput } from 'src/app/components/Form/SelectInput';
import { TooltipWrapper } from 'src/app/components/TooltipWrapper';
import { cn } from 'src/app/utils/cn';
import { generateOrderTitle } from 'src/app/utils/generate-order-title';
import { Dialog, DialogContent, DialogHeader } from 'src/components/ui/dialog';
import { Form } from 'src/components/ui/form';
import { z } from 'zod';

interface Props {
    open: boolean;
    onClose: () => void;
    salesOrderId: string;
}

const schema = z.object({
    purchase: z.string(),
});

export function SalesOrderLink({ salesOrderId, open, onClose }: Props) {
    const [searchParams] = useSearchParams();
    const delegateId = searchParams.get('delegateId') || '';

    const [current, setCurrent] = useState(0);
    const { data: salesOrders } = useSalesOrders(delegateId);
    const { data: purchases } = usePurchases();

    const form = useForm<z.infer<typeof schema>>({
        mode: 'onChange',
        resolver: zodResolver(schema),
    });

    const selectedPurchaseOrder = form.watch('purchase');

    const memoizedSalesOrder = useMemo(() => {
        return salesOrders?.find((x) => x._id === salesOrderId);
    }, [salesOrders, salesOrderId]);

    useEffect(() => {
        console.log(memoizedSalesOrder?.links);
    }, [memoizedSalesOrder]);

    const selectedPurchase = useMemo(() => {
        return purchases?.data?.find((x) => x._id === selectedPurchaseOrder);
    }, [selectedPurchaseOrder, purchases]);

    const selectedPurchaseInformation = useMemo(() => {
        const purchase = purchases?.data?.find(
            (x) => x._id === selectedPurchaseOrder
        );

        if (!purchase) return [];

        const latestVersion = purchase.versions[purchase.versions.length - 1];
        if (!latestVersion) return [];

        return latestVersion.manifest;
    }, [selectedPurchaseOrder, purchases]);

    const columns: ColumnDef<ManifestItemModel>[] = [
        {
            id: 'product',
            header: 'Product',
            accessorFn: (row) => {
                return row.product.name;
            },
        },
        {
            id: 'quantity',
            header: 'Quantity',
            accessorFn: (row) => row.quantity,
        },
        { id: 'price', header: 'Price', accessorFn: (row) => row.ppu },
    ];

    const steps = [
        {
            label: 'Select a Purchase',
            content: (
                <div className='grid h-full grid-rows-[auto_1fr] gap-4 overflow-auto px-2'>
                    <SelectInput
                        className='w-1/2'
                        name='purchase'
                        label='Purchase Order'
                        options={(purchases?.data || [])
                            .filter((x) => {
                                return !memoizedSalesOrder?.links?.some(
                                    (y) => y._id === x._id
                                );
                            })
                            .map((x) => {
                                return {
                                    label: generateOrderTitle(x),
                                    value: x._id,
                                };
                            })}
                    />

                    <VirtualTable
                        data={selectedPurchaseInformation || []}
                        columns={columns}
                        headerClassName='bg-red-800 text-white'
                        parentClassName='h-full'
                    />
                </div>
            ),
        },
        {
            label: 'Verify & Confirm',
            content: (
                <div className='flex h-full flex-col gap-8 px-2'>
                    <DetailLabel title='Link Purchase Order'>
                        {selectedPurchase
                            ? generateOrderTitle(selectedPurchase)
                            : '--'}
                    </DetailLabel>

                    <DetailLabel title='To Buyer Purchase Order'>
                        {memoizedSalesOrder?.purchase
                            ? generateOrderTitle(memoizedSalesOrder.purchase)
                            : '--'}
                    </DetailLabel>
                </div>
            ),
        },
    ];

    const { mutateAsync, isLoading } = useCreateLink();

    const onSubmit = async (data: z.infer<typeof schema>) => {
        const request: PurchaseOrderDataLinkDataObject = {
            purchaseOrders: [
                ...(memoizedSalesOrder?.links?.map((x) => x._id) || []),
                selectedPurchaseOrder,
            ],
        };

        await mutateAsync({
            body: request,
            order: memoizedSalesOrder?.purchaseOrderId || '',
            delegateId: delegateId,
        });

        reset();
    };

    const reset = () => {
        onClose();
        setCurrent(0);
    };

    const nextPage = () => {
        setCurrent((prev) => prev + 1);
    };

    const prevPage = () => {
        setCurrent((prev) => prev - 1);
    };

    return (
        <Dialog open={open} onOpenChange={onClose}>
            <DialogContent className='grid h-2/5 w-full max-w-[60%] grid-rows-[auto_1fr_auto]'>
                <DialogHeader className='text-md font-bold'>
                    Linking Purchase Order
                </DialogHeader>

                <div className='grid grid-cols-12 divide-x text-xs'>
                    <div className='col-span-3 flex flex-col justify-between px-2'>
                        <div className='flex flex-col gap-2'>
                            {steps.map((x, i) => {
                                return (
                                    <div
                                        className='flex items-center gap-2'
                                        key={i}
                                    >
                                        <div
                                            className={`flex h-6 w-6 rounded-full ${
                                                i <= current
                                                    ? 'bg-red-800 text-white'
                                                    : 'bg-gray-200 text-gray-800'
                                            }`}
                                        >
                                            <span className='flex w-full items-center justify-center text-sm'>
                                                {i + 1}
                                            </span>
                                        </div>

                                        <TooltipWrapper label={x.label}>
                                            <span
                                                className={`max-w-[70%] truncate text-xs font-semibold ${
                                                    i <= current
                                                        ? 'text-red-800'
                                                        : 'text-gray-400'
                                                }`}
                                            >
                                                {x.label}
                                            </span>
                                        </TooltipWrapper>
                                    </div>
                                );
                            })}
                        </div>

                        <div
                            id='button-container'
                            className='flex justify-between'
                        >
                            <X
                                size={16}
                                className='flex-none rounded-full border p-1 hover:scale-125'
                                onClick={reset}
                            />

                            <div id='button-controls' className='flex gap-2'>
                                <ArrowLeftIcon
                                    className={cn(
                                        'rounded-full border p-1 hover:scale-125',
                                        current === 0 ? 'hidden' : 'block'
                                    )}
                                    size={16}
                                    onClick={prevPage}
                                />

                                <ArrowRightIcon
                                    size={16}
                                    // className='rounded-full border p-1 hover:scale-125'
                                    className={cn(
                                        'rounded-full border p-1 hover:scale-125',
                                        current === steps.length - 1
                                            ? 'hidden'
                                            : 'block'
                                    )}
                                    onClick={nextPage}
                                />

                                <button form='add-substance-form' type='submit'>
                                    {isLoading ? (
                                        <LoaderCircle
                                            size={16}
                                            className='animate-spin'
                                        />
                                    ) : (
                                        <Check
                                            className={cn(
                                                'rounded-full border p-1 hover:scale-125',
                                                current !== steps.length - 1
                                                    ? 'hidden'
                                                    : 'block'
                                            )}
                                            size={16}
                                        />
                                    )}
                                </button>
                            </div>
                        </div>
                    </div>

                    <div className='col-span-9 h-full px-2'>
                        <Form {...form}>
                            <form
                                id='add-substance-form'
                                onSubmit={form.handleSubmit(onSubmit)}
                                className='h-full overflow-auto'
                            >
                                {steps[current].content}
                            </form>
                        </Form>
                    </div>
                </div>
            </DialogContent>
        </Dialog>
    );
}
