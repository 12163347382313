import { useMutation, useQueryClient } from '@tanstack/react-query';
import { toast } from 'sonner';
import { useContextStore } from 'src/app/stores/context-store';
import { ProductMaterialDataObject } from '../request/product-material-data-object';
import { MATERIALS_QUERY_KEY, MATERIAL_QUERY_KEY } from './material-query-keys';
import { useMaterialService } from './use-material-service';
import {
    BASE_PRODUCT_QUERY_KEY,
    BASE_PRODUCTS_QUERY_KEY,
} from '../../product/hooks/product-query-keys';

export function useUpdateMaterial() {
    const client = useQueryClient();
    const { service } = useMaterialService();
    const context = useContextStore();

    return useMutation({
        mutationFn: async ({
            delegateId,
            body,
            id,
        }: {
            delegateId?: string;
            id: string;
            body: ProductMaterialDataObject;
        }) => {
            return await service.update(
                delegateId || context.workspace?._id || '',
                id,
                body
            );
        },
        onSuccess: (_, { delegateId, id }) => {
            client.invalidateQueries({
                queryKey: MATERIALS_QUERY_KEY(
                    delegateId || context.workspace?._id || ''
                ),
            });

            client.invalidateQueries({
                queryKey: MATERIAL_QUERY_KEY(
                    delegateId || context.workspace?._id || '',
                    id
                ),
            });

            client.invalidateQueries({
                queryKey: [BASE_PRODUCTS_QUERY_KEY],
            });

            client.invalidateQueries({
                queryKey: [BASE_PRODUCT_QUERY_KEY],
            });

            toast.success('Material updated successfully');
        },
        onError: (err) => {
            const error = err as Error;

            toast.error(`Failed to update material: ${error.message}`);
        },
    });
}
