import { Button } from 'src/components/ui/button';
import {
    Dialog,
    DialogContent,
    DialogFooter,
    DialogHeader,
} from 'src/components/ui/dialog';
import { cn } from 'src/lib/utils';

interface DialogProps
    extends Omit<React.HTMLAttributes<HTMLDivElement>, 'onSubmit' | 'title'> {
    open: boolean;
    onClose: () => void;
    onConfirm?: (data?: any) => void;
    children?: React.ReactNode;
    title: string | React.ReactNode;
    sizes?: 'sm' | 'md' | 'lg' | 'xl';
    extraControls?: React.ReactNode;
    isLoading?: boolean;
    formName?: string;
    contentClassName?: string;
    showConfirmButton?: boolean;
}

export const BaseDialog = ({
    open,
    onClose,
    children,
    title,
    onConfirm,
    sizes = 'md',
    extraControls,
    isLoading,
    className,
    formName,
    contentClassName,
    showConfirmButton = true,
}: DialogProps) => {
    const dialogSizes = {
        sm: 'min-w-[250px]',
        md: 'min-w-[500px]',
        lg: 'min-w-[750px]',
        xl: 'w-4/5',
    };

    const reset = () => {
        onClose();
    };

    return (
        <Dialog open={open} onOpenChange={onClose}>
            <DialogContent
                className={cn(
                    dialogSizes[sizes],
                    'grid grid-rows-[auto_1fr_auto]',
                    className
                )}
            >
                <DialogHeader className='text-sm font-bold'>
                    {title}
                </DialogHeader>

                <div
                    className={cn(
                        'flex w-full flex-1 flex-col gap-4 overflow-auto py-2',
                        contentClassName
                    )}
                >
                    {children}
                </div>

                <DialogFooter>
                    <div className='flex w-full flex-[11] justify-between'>
                        <div>{extraControls}</div>

                        <div className='flex gap-2'>
                            <Button
                                size='sm'
                                variant='outline'
                                onClick={() => {
                                    reset();
                                }}
                                data-testid='cancel-button'
                            >
                                Cancel
                            </Button>

                            {showConfirmButton && (
                                <Button
                                    variant='default'
                                    size='sm'
                                    type='submit'
                                    form={formName || 'my-form'}
                                    data-testid='submit-button'
                                    onClick={() => {
                                        if (onConfirm) {
                                            onConfirm();
                                        }
                                    }}
                                    loading={isLoading}
                                >
                                    Submit
                                </Button>
                            )}
                        </div>
                    </div>
                </DialogFooter>
            </DialogContent>
        </Dialog>
    );
};
