import { useMutation, useQueryClient } from '@tanstack/react-query';
import { toast } from 'sonner';
import { useContextStore } from 'src/app/stores/context-store';
import { DocumentCreateDataObject } from '../document-create-data-object';
import { DocumentModel } from '../document.model';
import { DOCUMENTS_QUERY_KEY } from './documents-query-key';
import { useDocumentService } from './use-document-service';

export function useCreateDocument() {
    const { service } = useDocumentService();
    const context = useContextStore();
    const client = useQueryClient();

    return useMutation({
        mutationFn: async ({
            delegateId,
            body,
        }: {
            delegateId?: string;
            body: DocumentCreateDataObject;
        }): Promise<DocumentModel> => {
            return await service.create(
                delegateId || context.workspace?._id || '',
                body
            );
        },
        onSuccess: (_, { delegateId }) => {
            toast.success('Successfully created document');

            client.invalidateQueries({
                queryKey: DOCUMENTS_QUERY_KEY(
                    delegateId || context.workspace?._id || ''
                ),
            });
        },
        onError: (err) => {
            const error = err as Error;

            toast.error(`Failed to create document: ${error.message}`);
        },
    });
}
