import { CaretSortIcon } from '@radix-ui/react-icons';
import { ColumnDef } from '@tanstack/react-table';
import axios from 'axios';
import { saveAs } from 'file-saver';
import { Download, Edit, Plus, Trash } from 'lucide-react';
import { useCallback, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useAttachmentDownload } from 'src/app/_api_adb2c/attachment/hooks/use-attachment-download';
import {
    SupportDocType,
    SupportDocTypeLabel,
} from 'src/app/_api_adb2c/shared/support-doc-type.enum';
import { DocumentModel } from 'src/app/_api_adb2c/workspace/documents/document.model';
import { useDocuments } from 'src/app/_api_adb2c/workspace/documents/hooks/use-documents';
import { WorkspaceDocumentTypeModel } from 'src/app/_api_adb2c/workspace/workspace/workspace.model';
import { Button } from 'src/components/ui/button';
import { Checkbox } from 'src/components/ui/checkbox';
import { ReusableTable } from '../../Purchases/reusable-table';
import { CreateDocument } from './create-document';
import { DeleteDocument } from './delete-document';
import { EditDocument } from './edit-document';

export interface WorkspaceFileModel
    extends DocumentModel,
        WorkspaceDocumentTypeModel {}

export const WorkspaceDocuments = () => {
    const [searchParams] = useSearchParams();
    const delegateId = searchParams.get('delegateId') || '';

    const { data } = useDocuments(delegateId);
    const [isCreate, setIsCreate] = useState(false);

    const [isEdit, setIsEdit] = useState(false);
    const [isDelete, setIsDelete] = useState(false);
    const [selectedItem, setSelectedItem] = useState<WorkspaceFileModel>();

    const { mutateAsync } = useAttachmentDownload();

    const downloadAttachment = useCallback(
        async (row: string) => {
            console.log(row);

            const attachment = await mutateAsync({
                delegateId: delegateId || '',
                body: {
                    id: row,
                    genre: 'temporary',
                },
            });

            const blob = await axios.get(attachment.token, {
                responseType: 'blob',
            });

            saveAs(blob.data, attachment.attachment.originalName);
        },
        [delegateId, mutateAsync]
    );

    const memoizedData = useMemo(() => {
        const documents: WorkspaceFileModel[] = [];

        data?.extensions.forEach((ext, index) => {
            const document = data.documents?.[index];

            if (!document) return;

            documents.push({
                ...ext,
                ...document,
            });
        });

        return documents.filter((x) => !x.isCertificate);
    }, [data]);

    const columns: ColumnDef<WorkspaceFileModel>[] = [
        {
            id: 'select',
            header: ({ table }) => (
                <Checkbox
                    checked={table.getIsAllPageRowsSelected()}
                    onCheckedChange={(value) =>
                        table.toggleAllPageRowsSelected(!!value)
                    }
                    aria-label='Select all'
                />
            ),
            cell: ({ row }) => (
                <Checkbox
                    checked={row.getIsSelected()}
                    onCheckedChange={(value) => row.toggleSelected(!!value)}
                    aria-label='Select row'
                />
            ),
            enableSorting: false,
            enableHiding: false,
            size: 0,
        },
        {
            id: 'document name',
            accessorKey: 'documentName',
            header: ({ column }) => {
                return (
                    <Button
                        variant='ghost'
                        className='text-xs'
                        onClick={() =>
                            column.toggleSorting(column.getIsSorted() === 'asc')
                        }
                    >
                        <span>Document Name</span>
                        <CaretSortIcon
                            className={`inline ${
                                column.getIsSorted() === 'asc'
                                    ? 'rotate-180 transform'
                                    : ''
                            }`}
                        />
                    </Button>
                );
            },
            accessorFn: (row) => row.name,
        },
        {
            id: 'type',
            accessorKey: 'type',
            header: ({ column }) => {
                return (
                    <Button
                        variant='ghost'
                        className='text-xs'
                        onClick={() =>
                            column.toggleSorting(column.getIsSorted() === 'asc')
                        }
                    >
                        <span>Type</span>
                        <CaretSortIcon
                            className={`inline ${
                                column.getIsSorted() === 'asc'
                                    ? 'rotate-180 transform'
                                    : ''
                            }`}
                        />
                    </Button>
                );
            },
            accessorFn: (row) =>
                row.type
                    ? SupportDocTypeLabel[row.type as SupportDocType]
                    : row.type,
        },
        {
            id: 'comment',
            accessorKey: 'comment',
            header: ({ column }) => {
                return (
                    <Button
                        variant='ghost'
                        className='text-xs'
                        onClick={() =>
                            column.toggleSorting(column.getIsSorted() === 'asc')
                        }
                    >
                        <span>Comment</span>
                        <CaretSortIcon
                            className={`inline ${
                                column.getIsSorted() === 'asc'
                                    ? 'rotate-180 transform'
                                    : ''
                            }`}
                        />
                    </Button>
                );
            },
            accessorFn: (row) => {
                return row.description;
            },
        },
        {
            id: 'Private',
            accessorKey: 'Private',
            header: ({ column }) => {
                return (
                    <Button
                        variant='ghost'
                        className='text-xs'
                        onClick={() =>
                            column.toggleSorting(column.getIsSorted() === 'asc')
                        }
                    >
                        <span>Private</span>
                        <CaretSortIcon
                            className={`inline ${
                                column.getIsSorted() === 'asc'
                                    ? 'rotate-180 transform'
                                    : ''
                            }`}
                        />
                    </Button>
                );
            },
            accessorFn: (row) => (row.isPrivate ? 'True' : 'False'),
        },
        {
            id: 'actions',
            size: 10,
            header: ({ column }) => {
                return (
                    <Button variant='ghost' className='text-xs'>
                        <span>Actions</span>
                    </Button>
                );
            },
            cell: ({ row }) => {
                return (
                    <div className='flex'>
                        <Button
                            variant='ghost'
                            className='text-xs'
                            onClick={() => {
                                setIsEdit(true);
                                setSelectedItem(row.original);
                            }}
                        >
                            <Edit size={16} />
                        </Button>

                        <Button
                            variant='ghost'
                            className='text-xs'
                            onClick={() =>
                                downloadAttachment(row.original.file)
                            }
                        >
                            <Download size={16} />
                        </Button>

                        <Button
                            variant='ghost'
                            className='text-xs'
                            onClick={() => {
                                setIsDelete(true);
                                setSelectedItem(row.original);
                            }}
                        >
                            <Trash size={16} />
                        </Button>
                    </div>
                );
            },
        },
    ];

    return (
        <div className='flex h-full flex-col'>
            <ReusableTable
                title='Documents'
                columns={columns}
                data={memoizedData}
                actions={[
                    {
                        id: 'add',
                        icon: <Plus size={16} />,
                        onClick: () => setIsCreate(true),
                    },
                ]}
                controls={{
                    enableSearch: true,
                }}
            />

            {isCreate && (
                <CreateDocument
                    onClose={() => setIsCreate(false)}
                    open={isCreate}
                />
            )}

            {isEdit && selectedItem && (
                <EditDocument
                    open={isEdit}
                    onClose={() => setIsEdit(false)}
                    document={selectedItem}
                    documents={memoizedData}
                />
            )}

            {isDelete && selectedItem && (
                <DeleteDocument
                    open={isDelete}
                    onClose={() => setIsDelete(false)}
                    document={selectedItem}
                />
            )}
        </div>
    );
};
