import { ServiceConfigInterface } from 'src/domain/interfaces/config.context.interface';
import { PurchaseApiClient } from './purchase.client';

export class PurchaseService {
    private server: PurchaseApiClient;

    constructor(config: ServiceConfigInterface, token: string) {
        this.server = new PurchaseApiClient(config, token);
    }

    cancelOrder(workspace: string, order: string) {
        console.log(`service<purchase>| cancelOrder(): Enter`);
        console.log(
            `service<purchase>| cancelOrder(): $workspace = ${workspace}`
        );
        console.log(`service<purchase>| cancelOrder(): $order = ${order}`);

        return this.server.cancelOrder(workspace, order);
    }

    loadDocumentMap(workspace: string, order: string) {
        console.log(`service<purchase>| loadDocumentMap(): Enter`);
        console.log(
            `service<purchase>| loadDocumentMap(): $workspace = ${workspace}`
        );
        console.log(`service<purchase>| loadDocumentMap(): $order = ${order}`);

        return this.server.loadDocumentMap(workspace, order);
    }

    create(workspace: string, body: any, diversion = '0') {
        console.log(`service<purchase>| create(): Enter`);
        console.log(`service<purchase>| create(): $workspace = ${workspace}`);
        console.log(
            `service<purchase>| create(): $body = ${JSON.stringify(body)}`
        );
        console.log(`service<purchase>| create(): $diversion = ${diversion}`);

        return this.server.create(workspace, diversion, body);
    }

    copy(workspace: string, order: string, deep: boolean) {
        console.log(`service<purchase>| copy(): Enter`);
        console.log(`service<purchase>| copy(): $workspace = ${workspace}`);
        console.log(`service<purchase>| copy(): $order = ${order}`);
        console.log(`service<purchase>| copy(): $deep = ${deep}`);

        return this.server.copy(workspace, order, deep);
    }

    downloadTraceDocuments(workspace: string, order: string) {
        console.log(`service<purchase>| downloadTraceDocuments(): Enter`);
        console.log(
            `service<purchase>| downloadTraceDocuments(): $workspace = ${workspace}`
        );
        console.log(
            `service<purchase>| downloadTraceDocuments(): $order = ${order}`
        );

        return this.server.downloadTraceDocuments(workspace, order);
    }

    get(workspace: string, order: string, diversion: string = '0') {
        console.log(`service<purchase>| get(): Enter`);
        console.log(`service<purchase>| get(): $workspace = ${workspace}`);
        console.log(`service<purchase>| get(): $order = ${order}`);

        return this.server.get(workspace, order, diversion);
    }

    update(workspace: string, order: string, body: any) {
        console.log(`service<purchase>| update(): Enter`);
        console.log(`service<purchase>| update(): $workspace = ${workspace}`);
        console.log(`service<purchase>| update(): $order = ${order}`);
        console.log(
            `service<purchase>| update(): $body = ${JSON.stringify(body)}`
        );

        return this.server.update(workspace, order, body);
    }

    release(workspace: string, order: string) {
        console.log(`service<purchase>| release(): Enter`);
        console.log(`service<purchase>| release(): $workspace = ${workspace}`);
        console.log(`service<purchase>| release(): $order = ${order}`);

        return this.server.release(workspace, order);
    }

    remove(workspace: string, order: string, diversion = '0') {
        console.log(`service<purchase>| remove(): Enter`);
        console.log(`service<purchase>| remove(): $workspace = ${workspace}`);
        console.log(`service<purchase>| remove(): $order = ${order}`);
        console.log(`service<purchase>| remove(): $diversion = ${diversion}`);

        return this.server.remove(workspace, order, diversion);
    }

    getVersions(workspace: string, order: string) {
        console.log(`service<purchase>| getVersions(): Enter`);
        console.log(
            `service<purchase>| getVersions(): $workspace = ${workspace}`
        );
        console.log(`service<purchase>| getVersions(): $order = ${order}`);

        return this.server.getVersions(workspace, order);
    }

    getVersion(workspace: string, order: string, version: string) {
        console.log(`service<purchase>| getVersion(): Enter`);
        console.log(
            `service<purchase>| getVersion(): $workspace = ${workspace}`
        );
        console.log(`service<purchase>| getVersion(): $order = ${order}`);
        console.log(`service<purchase>| getVersion(): $version = ${version}`);

        return this.server.getVersion(workspace, order, version);
    }

    list(
        workspace: string,
        draft = true,
        page = 0,
        limit = 10,
        diversion = '0'
    ) {
        console.log(`service<purchase>| list(): Enter`);
        console.log(`service<purchase>| list(): $workspace = ${workspace}`);
        console.log(`service<purchase>| list(): $draft = ${draft}`);
        console.log(`service<purchase>| list(): $page = ${page}`);
        console.log(`service<purchase>| list(): $limit = ${limit}`);
        console.log(`service<purchase>| list(): $diversion = ${diversion}`);

        return this.server.list(workspace, draft, page, limit, diversion);
    }

    reverse(workspace: string, order: string) {
        console.log(`service<purchase>| reverse(): Enter`);
        console.log(`service<purchase>| reverse(): $workspace = ${workspace}`);
        console.log(`service<purchase>| reverse(): $order = ${order}`);

        return this.server.reverse(workspace, order);
    }

    listByUser(workspace: string, draft = true, page = 0, limit = 10) {
        console.log(`service<purchase>| listByUser(): Enter`);
        console.log(
            `service<purchase>| listByUser(): $workspace = ${workspace}`
        );
        console.log(`service<purchase>| listByUser(): $draft = ${draft}`);
        console.log(`service<purchase>| listByUser(): $page = ${page}`);
        console.log(`service<purchase>| listByUser(): $limit = ${limit}`);

        return this.server.listByUser(workspace, draft, page, limit);
    }

    listBySupplier(
        workspace: string,
        supplier: string,
        draft = true,
        page = 0,
        limit = 10
    ) {
        console.log(`service<purchase>| listBySupplier(): Enter`);
        console.log(
            `service<purchase>| listBySupplier(): $workspace = ${workspace}`
        );
        console.log(
            `service<purchase>| listBySupplier(): $supplier = ${supplier}`
        );
        console.log(`service<purchase>| listBySupplier(): $draft = ${draft}`);
        console.log(`service<purchase>| listBySupplier(): $page = ${page}`);
        console.log(`service<purchase>| listBySupplier(): $limit = ${limit}`);

        return this.server.listBySupplier(
            workspace,
            supplier,
            draft,
            page,
            limit
        );
    }

    trace(workspace: string, order: string, diversion: string = '0') {
        console.log(`service<purchase>| trace(): Enter`);
        console.log(`service<purchase>| trace(): $workspace = ${workspace}`);
        console.log(`service<purchase>| trace(): $order = ${order}`);
        console.log(`service<purchase>| trace(): $diversion = ${diversion}`);

        return this.server.trace(workspace, order, diversion);
    }

    getCottonWeight(workspace: string, order: string) {
        console.log(`service<purchase>| getCottonWeight(): Enter`);
        console.log(
            `service<purchase>| getCottonWeight(): $workspace = ${workspace}`
        );
        console.log(`service<purchase>| getCottonWeight(): $order = ${order}`);

        return this.server.getCottonWeight(workspace, order);
    }
}
