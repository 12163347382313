import { useAccount } from '@azure/msal-react';
import { useMemo } from 'react';
import useAppConfig from 'src/infrastructure/hooks/use-config.hook';
import { CategoryService } from '../category.service';

export function useCategoryService() {
    const config = useAppConfig();
    const account = useAccount();

    const service = useMemo(() => {
        if (account?.idToken) {
            return new CategoryService(config, account.idToken);
        } else {
            throw new Error('Session Expired');
        }
    }, [config, account?.idToken]);

    return { service };
}
