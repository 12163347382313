import { useMemo } from 'react';
import { Pie, PieChart } from 'recharts';
import { useBaseCategories } from 'src/app/_api_adb2c/product/category/hooks/use-base-categories';
import { useCategories } from 'src/app/_api_adb2c/product/category/hooks/use-categories';
import { useProducts } from 'src/app/_api_adb2c/product/product/hooks/use-products';
import { usePurchases } from 'src/app/_api_adb2c/purchase/purchase/hooks/use-purchases';
import {
    Card,
    CardContent,
    CardDescription,
    CardHeader,
    CardTitle,
} from 'src/components/ui/card';
import {
    ChartConfig,
    ChartContainer,
    ChartTooltip,
    ChartTooltipContent,
} from 'src/components/ui/chart';
import { EmptyChart } from '../empty-chart';
import { generateColors, observable12 } from '../Supplier';
import { renderCustomizedLabel } from '../Supplier/supplier-country';
import { getDateRangeDescription } from '../utils/get-date-range-description';

interface Props {
    startDate?: Date;
    endDate?: Date;
}

const chartConfig = {
    count: {
        label: 'Count',
        color: 'hsl(var(--chart-1))',
    },
} satisfies ChartConfig;

export function ProductDistributionByOrderedQuantity({
    startDate,
    endDate,
}: Props) {
    const colors = generateColors(observable12);
    const { data: products } = useProducts();
    const { data: purchases } = usePurchases();
    const { data: baseCategories } = useBaseCategories();
    const { data: categories } = useCategories();

    const graphData = useMemo(() => {
        const categoryCounts: { label: string; count: number; fill: string }[] =
            [];

        products?.data
            .filter((x) => x.category)
            .forEach((product, index) => {
                if (!product.createdOn) return;

                const createdDate = new Date(product.createdOn);

                if (
                    startDate &&
                    endDate &&
                    (createdDate < startDate || createdDate > endDate)
                ) {
                    return;
                }

                const itemQuantity = purchases?.data
                    ?.filter((x) => {
                        const latestVersion =
                            x.versions?.[x.versions.length - 1];

                        return latestVersion?.manifest.some(
                            (x) => x.product?._id === product?._id
                        );
                    })
                    .map((x) => {
                        const latestVersion =
                            x.versions?.[x.versions.length - 1];

                        const manifestItem = latestVersion?.manifest.find(
                            (x) => x.product?._id === product?._id
                        );

                        return {
                            quantity: manifestItem?.quantity || 0,
                        };
                    })
                    .reduce((acc, curr) => {
                        return acc + curr.quantity;
                    }, 0);

                const isPurchaseItem = purchases?.data?.some((x) => {
                    const latestVersion = x.versions?.[x.versions.length - 1];

                    if (!latestVersion) return false;

                    return latestVersion.manifest.some(
                        (x) => x.product?._id === product?._id
                    );
                });

                if (isPurchaseItem) {
                    let category = categories?.find(
                        (x) => x?._id === product.category?._id
                    )?.name;

                    if (!category) {
                        category = baseCategories
                            ?.find((x) => x?._id === product.category?._id)
                            ?.name.locales.find(
                                (x) => x.localeName === 'en'
                            )?.text;
                    }

                    const existing = categoryCounts.find(
                        (x) => x.label === category
                    );

                    if (existing) {
                        existing.count += itemQuantity || 0;
                    } else {
                        categoryCounts.push({
                            label: category || '',
                            count: itemQuantity || 0,
                            fill: colors[index % colors.length],
                        });
                    }
                }
            });

        return categoryCounts;
    }, [
        products,
        purchases,
        endDate,
        startDate,
        baseCategories,
        categories,
        colors,
    ]);

    const dateRangeDescription = useMemo(
        () => getDateRangeDescription(startDate, endDate),
        [startDate, endDate]
    );
    return (
        <Card className='px-4 py-2 shadow'>
            <CardHeader>
                <CardTitle>Product Distribution By Ordered Quantity</CardTitle>
                <CardDescription>{dateRangeDescription}</CardDescription>
            </CardHeader>

            <CardContent>
                {graphData.length > 0 ? (
                    <ChartContainer
                        config={chartConfig}
                        className='mx-auto aspect-square h-56 w-full'
                    >
                        <PieChart>
                            <ChartTooltip
                                cursor={false}
                                content={<ChartTooltipContent hideLabel />}
                            />

                            <Pie
                                data={graphData}
                                dataKey='count'
                                nameKey='label'
                                innerRadius={60}
                                paddingAngle={2}
                                labelLine
                                label={renderCustomizedLabel}
                            />
                        </PieChart>
                    </ChartContainer>
                ) : (
                    <EmptyChart />
                )}
            </CardContent>
        </Card>
    );
}
