import { zodResolver } from '@hookform/resolvers/zod';
import { useCallback, useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';
import {
    CompanyProfileModel,
    CompanySourceType,
} from 'src/app/_api_adb2c/workspace/company/company.model';
import { ContactType } from 'src/app/_api_adb2c/workspace/shared/enum/contact-type.enum';
import {
    SupplyChainNodeType,
    SupplyChainNodeTypeLabel,
} from 'src/app/_api_adb2c/workspace/shared/enum/supply-chain-node-type.enum';
import {
    AddressDataObject,
    CompanyComplianceDataObject,
    CompanyUpdateRequestObject,
    ContactDataObject,
} from 'src/app/_api_adb2c/workspace/workspace/company-update-request-object';
import { useWorkspaceCompanyUpdate } from 'src/app/_api_adb2c/workspace/workspace/hooks/use-workspace-company-update';
import { ContactInput } from 'src/app/components/Form/ContactInput';
import { DateInput } from 'src/app/components/Form/DateInput';
import { MultiSelectInputV2 } from 'src/app/components/Form/MultiSelectInputV2';
import { SelectInput } from 'src/app/components/Form/SelectInput';
import { TextInput } from 'src/app/components/Form/TextInput';
import { useContextStore } from 'src/app/stores/context-store';
import { cn } from 'src/app/utils/cn';
import { Button } from 'src/components/ui/button';
import { Form } from 'src/components/ui/form';
import { z } from 'zod';
import countries from '../../../infrastructure/config/data/countries.json';

const formSchema = z.object({
    name: z.string(),
    addressLine1: z.string(),
    suburb: z.string().nullish().optional(),
    city: z.string().nullish().optional(),
    state: z.string().nullish().optional(),
    country: z.string(),
    postCode: z.string().nullish().optional(),
    website: z.string().nullish().optional(),
    lfx: z.string().nullish().optional(),
    registrationNumber: z.string().nullish().optional(),
    createdOn: z.date(),
    duns: z.string().nullish().optional(),
    higg: z.string().nullish().optional(),
    gln: z.string().nullish().optional(),
    oar: z.string().nullish().optional(),
    capacity: z.coerce.number().nullish().optional(),
    employees: z.coerce.number().nullish().optional(),
    complianceLevel: z.string().nullish().optional(),
    complianceRating: z.string().nullish().optional(),
    complianceExpiresOn: z.date().nullish().optional(),
    phone: z.string(),
    supplyChainNodeTypes: z.array(z.string()).optional(),
    fax: z.string().nullish().optional(),
    contactCompliance: z.any().nullish().optional(),
    contactBusiness: z.any().nullish().optional(),
    zdhc: z.string().nullish().optional(),
});

const retriveProfileInfo = (
    links: CompanyProfileModel[],
    value: CompanySourceType
) => {
    return links.find((x) => x.source === value)?.identity;
};

export const WorkspaceDetails = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const editMode = useMemo(
        () => searchParams.get('edit') === 'true',
        [searchParams]
    );
    const { workspace } = useContextStore();
    const { mutateAsync, isLoading } = useWorkspaceCompanyUpdate();

    const company = useMemo(() => workspace?.company, [workspace?.company]);
    const links = useMemo(() => company?.links || [], [company?.links]);

    const toggleEditMode = () => {
        setSearchParams((params) => {
            if (editMode) {
                params.delete('edit');
            } else {
                params.set('edit', 'true');
            }
            return params;
        });
    };

    const form = useForm<z.infer<typeof formSchema>>({
        mode: 'onChange',
        resolver: zodResolver(formSchema),
        defaultValues: {
            name: company?.demographics.name,
            addressLine1: company?.demographics.address.unit,
            suburb: company?.demographics.address.suburb,
            city: company?.demographics.address.city,
            state: company?.demographics.address.state,
            country: company?.demographics.address.country,
            postCode: company?.demographics.address.postCode,
            website: company?.demographics.website,
            lfx: retriveProfileInfo(links, CompanySourceType.LFX),
            registrationNumber: company?.demographics.registrationNumber,
            createdOn: company?.createdOn
                ? new Date(company.createdOn)
                : undefined,
            duns: retriveProfileInfo(links, CompanySourceType.DUNS),
            higg: retriveProfileInfo(links, CompanySourceType.HIGG),
            gln: retriveProfileInfo(links, CompanySourceType.GLN),
            oar: retriveProfileInfo(links, CompanySourceType.OAR),
            capacity: company?.demographics.capacity,
            employees: company?.demographics.employees,
            complianceRating: company?.compliance.find(
                (x) => x.source === 'complianceRating'
            )?.rating,
            complianceLevel: company?.compliance.find(
                (x) => x.source === 'complianceLevel'
            )?.rating,
            phone: company?.demographics.phone,
            fax: company?.demographics.fax,
            supplyChainNodeTypes: company?.demographics.processes,
            zdhc: retriveProfileInfo(links, CompanySourceType.ZDHC),
            contactCompliance: company?.demographics.complianceContact,
            contactBusiness: company?.demographics.primaryContact,
        },
    });

    const onSubmit = async (data: z.infer<typeof formSchema>) => {
        if (!company) return;

        const linkFields = [
            CompanySourceType.LFX,
            CompanySourceType.DUNS,
            CompanySourceType.HIGG,
            CompanySourceType.GLN,
            CompanySourceType.OAR,
            CompanySourceType.ZDHC,
        ];

        const updatedLinks = linkFields
            .map((x) => ({
                source: x,
                // @ts-ignore
                identity: data[x.toLowerCase()],
            }))
            .filter((x) => x.identity);

        const address: AddressDataObject = {
            building: company.demographics.address.building,
            city: data.city ? data.city : company.demographics.address.city,
            country: data.country
                ? data.country
                : company.demographics.address.country,
            floor: company.demographics.address.floor,
            geocode: company.demographics.address.geocode,
            postCode: data.postCode
                ? data.postCode
                : company.demographics.address.postCode,
            state: data.state ? data.state : company.demographics.address.state,
            street: data.addressLine1
                ? data.addressLine1
                : company.demographics.address.street,
            suburb: data.suburb
                ? data.suburb
                : company.demographics.address.suburb,
            unit: company.demographics.address.unit,
        };

        const complianceContact: ContactDataObject = {
            firstName: data.contactCompliance?.firstName,
            lastName: data.contactCompliance?.lastName,
            email: data.contactCompliance?.email,
            type: ContactType.EXTERNAL,
        };

        const primaryContact: ContactDataObject = {
            firstName: data.contactBusiness?.firstName,
            lastName: data.contactBusiness?.lastName,
            email: data.contactBusiness?.email,
            type: ContactType.EXTERNAL,
        };

        const compliances: CompanyComplianceDataObject[] = [];

        if (data.complianceLevel) {
            compliances.push({
                source: 'complianceLevel',
                rating: data.complianceLevel,
                expiresOn: data.complianceExpiresOn
                    ? data.complianceExpiresOn
                    : undefined,
            });
        }

        if (data.complianceRating) {
            compliances.push({
                expiresOn: data.complianceExpiresOn
                    ? data.complianceExpiresOn
                    : undefined,
                source: 'complianceRating',
                rating: data.complianceRating,
            });
        }

        const request: CompanyUpdateRequestObject = {
            id: company._id,
            compliance: compliances,
            demographics: {
                address: address,
                capacity: data.capacity
                    ? data.capacity
                    : company.demographics.capacity,
                complianceContact: complianceContact.email
                    ? complianceContact
                    : undefined,
                dba: company.demographics.dba,
                employees: data.employees
                    ? data.employees
                    : company.demographics.employees,
                fax: data.fax ? data.fax : company.demographics.fax,
                industry: company.demographics.industry,
                name: data.name ? data.name : company.demographics.name,
                processes: data.supplyChainNodeTypes
                    ? data.supplyChainNodeTypes
                    : company.demographics.processes,
                phone: data.phone ? data.phone : company.demographics.phone,
                primaryContact: primaryContact.email
                    ? primaryContact
                    : undefined,
                registrationNumber: data.registrationNumber
                    ? data.registrationNumber
                    : company.demographics.registrationNumber,
                since: company.demographics.since,
                website: data.website
                    ? data.website
                    : company.demographics.website,
                yearInOperation: company.demographics.yearInOperation,
            },
            documents: company.documents,
            links: updatedLinks,
        };

        await mutateAsync({
            ...request,
        });

        reset();
    };

    const reset = () => {
        form.reset();

        setSearchParams((params) => {
            params.delete('edit');
            return params;
        });
    };

    const getComplianceExpiresOn = useCallback(
        (compliances?: CompanyComplianceDataObject[]) => {
            if (!compliances) return undefined;

            const compliancesWithExpiresOn = compliances.filter(
                (x) => x.expiresOn
            );

            if (compliancesWithExpiresOn.length > 0) {
                return compliancesWithExpiresOn[0].expiresOn;
            }

            return undefined;
        },
        []
    );

    useEffect(() => {
        const complianceExpiry = getComplianceExpiresOn(company?.compliance);

        form.reset({
            name: company?.demographics.name,
            addressLine1: company?.demographics.address.street,
            suburb: company?.demographics.address.suburb,
            city: company?.demographics.address.city,
            state: company?.demographics.address.state,
            country: company?.demographics.address.country,
            postCode: company?.demographics.address.postCode,
            website: company?.demographics.website,
            lfx: retriveProfileInfo(links, CompanySourceType.LFX),
            registrationNumber: company?.demographics.registrationNumber,
            createdOn: company?.createdOn
                ? new Date(company.createdOn)
                : undefined,
            duns: retriveProfileInfo(links, CompanySourceType.DUNS),
            higg: retriveProfileInfo(links, CompanySourceType.HIGG),
            gln: retriveProfileInfo(links, CompanySourceType.GLN),
            oar: retriveProfileInfo(links, CompanySourceType.OAR),
            zdhc: retriveProfileInfo(links, CompanySourceType.ZDHC),
            capacity: company?.demographics.capacity,
            employees: company?.demographics.employees,
            phone: company?.demographics.phone,
            fax: company?.demographics.fax,
            supplyChainNodeTypes: company?.demographics.processes,
            contactCompliance: company?.demographics.complianceContact,
            contactBusiness: company?.demographics.primaryContact,
            complianceRating: company?.compliance.find(
                (x) => x.source === 'complianceRating'
            )?.rating,
            complianceLevel: company?.compliance.find(
                (x) => x.source === 'complianceLevel'
            )?.rating,
            complianceExpiresOn: complianceExpiry
                ? new Date(complianceExpiry)
                : undefined,
        });
    }, [form, company, links, getComplianceExpiresOn]);

    return (
        <>
            <Form {...form}>
                <form
                    id='my-form'
                    onSubmit={form.handleSubmit(onSubmit)}
                    className='flex h-full flex-col gap-4 px-4'
                >
                    <div className='flex justify-end gap-4'>
                        <Button
                            type='submit'
                            form='my-form'
                            loading={isLoading}
                            size='sm'
                            className={`${cn(!editMode && 'hidden')}`}
                        >
                            Save
                        </Button>

                        <Button
                            onClick={() => toggleEditMode()}
                            type='button'
                            size='sm'
                            className={`${cn(editMode && 'hidden')}`}
                        >
                            Edit
                        </Button>
                    </div>

                    <div className='grid grid-cols-5 gap-8'>
                        <div className='flex flex-col gap-4'>
                            <span className='text-md font-bold underline decoration-red-800 underline-offset-8'>
                                Basic Information
                            </span>

                            <div>
                                <TextInput
                                    label='Company Name'
                                    inputName='name'
                                    disabled={!editMode}
                                />

                                <TextInput
                                    label='Address'
                                    inputName='addressLine1'
                                    disabled={!editMode}
                                />

                                <TextInput
                                    label='City'
                                    inputName='city'
                                    disabled={!editMode}
                                />

                                <TextInput
                                    label='Suburb'
                                    inputName='suburb'
                                    disabled={!editMode}
                                />

                                <TextInput
                                    label='State'
                                    inputName='state'
                                    disabled={!editMode}
                                />

                                <SelectInput
                                    label='Country'
                                    name='country'
                                    options={countries.map((x) => ({
                                        label: x.name,
                                        value: x['alpha-2'],
                                    }))}
                                    disabled={!editMode}
                                />

                                <TextInput
                                    label='Post Code'
                                    inputName='postCode'
                                    disabled={!editMode}
                                />

                                <TextInput
                                    label='Website'
                                    inputName='website'
                                    disabled={!editMode}
                                />
                            </div>
                        </div>

                        <div className='flex flex-col gap-4'>
                            <span className='text-md font-bold underline decoration-red-800 underline-offset-8'>
                                Business Information
                            </span>

                            <div>
                                <TextInput
                                    label='LF Supplier Code'
                                    inputName='lfx'
                                    disabled={!editMode}
                                />
                                <TextInput
                                    label='Business Registration'
                                    inputName='registrationNumber'
                                    disabled={!editMode}
                                />

                                <DateInput
                                    label='T4S Member Since'
                                    name='createdOn'
                                    disabled={!editMode}
                                />

                                <TextInput
                                    label='DUNS Number'
                                    inputName='duns'
                                    disabled={!editMode}
                                />
                                <TextInput
                                    label='Worldly (HIGGS) ID'
                                    inputName='higg'
                                    disabled={!editMode}
                                />
                                <TextInput
                                    label='ZDHC ID'
                                    inputName='zdhc'
                                    disabled={!editMode}
                                />
                                <TextInput
                                    label='Global Location Number (GLN)'
                                    inputName='gln'
                                    disabled={!editMode}
                                />
                                <TextInput
                                    label='Open Apparel Registry (OAR) ID'
                                    inputName='oar'
                                    disabled={!editMode}
                                />
                            </div>
                        </div>

                        <div className='flex flex-col gap-4'>
                            <span className='text-md font-bold underline decoration-red-800 underline-offset-8'>
                                Contact Information
                            </span>
                            <div>
                                <TextInput
                                    label='Phone Number'
                                    inputName='phone'
                                    disabled={!editMode}
                                />
                                <TextInput
                                    label='Fax Number'
                                    inputName='fax'
                                    disabled={!editMode}
                                />

                                <ContactInput
                                    header='Add Compliance Contact'
                                    name='contactCompliance'
                                    label='Compliance Contacts'
                                    disabled={!editMode}
                                />

                                <ContactInput
                                    header='Add Business Contact'
                                    name='contactBusiness'
                                    label='Business Contacts'
                                    disabled={!editMode}
                                />
                            </div>
                        </div>

                        <div className='flex flex-col gap-4'>
                            <span className='text-md font-bold underline decoration-red-800 underline-offset-8'>
                                Capacity Information
                            </span>
                            <div>
                                <TextInput
                                    label='Monthly Capacity'
                                    inputName='capacity'
                                    endAdornment={
                                        <span className='text-[10px]'>
                                            UNITS
                                        </span>
                                    }
                                    disabled={!editMode}
                                />

                                <TextInput
                                    label='No. Of Employees'
                                    inputName='employees'
                                    endAdornment={
                                        <span className='text-[10px]'>
                                            STAFF
                                        </span>
                                    }
                                    disabled={!editMode}
                                />
                                <TextInput
                                    label='Compliance Level'
                                    inputName='complianceLevel'
                                    endAdornment={
                                        <span className='text-[10px]'>
                                            OF 5
                                        </span>
                                    }
                                    disabled={!editMode}
                                />
                                <TextInput
                                    label='Compliance Rating'
                                    inputName='complianceRating'
                                    disabled={!editMode}
                                />
                                <DateInput
                                    label='Compliance Expires On'
                                    name='complianceExpiresOn'
                                    disabled={!editMode}
                                />
                            </div>
                        </div>
                        <div className='flex flex-col gap-4'>
                            <span className='text-md font-bold underline decoration-red-800 underline-offset-8'>
                                Available Processes
                            </span>

                            <MultiSelectInputV2
                                name='supplyChainNodeTypes'
                                label='Process Types'
                                options={Object.values(SupplyChainNodeType).map(
                                    (x) => ({
                                        label: SupplyChainNodeTypeLabel[x],
                                        value: x,
                                    })
                                )}
                                className='h-auto bg-white'
                                creatable
                                disabled={!editMode}
                            />
                        </div>
                    </div>
                </form>
            </Form>
        </>
    );
};
