import { CaretSortIcon } from '@radix-ui/react-icons';
import { CheckIcon } from 'lucide-react';
import { useState } from 'react';
import { Button } from 'src/components/ui/button';
import {
    Command,
    CommandEmpty,
    CommandGroup,
    CommandInput,
    CommandItem,
} from 'src/components/ui/command';
import { Label } from 'src/components/ui/label';
import {
    Popover,
    PopoverContent,
    PopoverTrigger,
} from 'src/components/ui/popover';
import { cn } from 'src/lib/utils';

interface SelectProps extends React.HTMLAttributes<HTMLDivElement> {
    value: string | number;
    options: { label: string; value: string | number }[];
    placeholder?: string;
    setValue: (value: string) => void;
    label?: string;
}

export const Select = ({
    value,
    options,
    label,
    setValue,
    placeholder,
    className,
}: SelectProps) => {
    const [open, setOpen] = useState(false);

    return (
        <div className='flex flex-col gap-2'>
            {label && (
                <Label className='text-sm text-gray-500' htmlFor={label}>
                    {label}
                </Label>
            )}

            <Popover open={open} onOpenChange={setOpen}>
                <PopoverTrigger id={label}>
                    <Button
                        variant='outline'
                        role='combobox'
                        type='button'
                        className={cn(
                            cn('w-full pl-3 text-left font-normal'),
                            !value && 'text-muted-foreground',
                            className
                        )}
                    >
                        <span className='max-w-[75%] truncate text-xs'>
                            {value
                                ? options.find(
                                      (option) => option.value === value
                                  )?.label
                                : placeholder || '-'}
                        </span>
                        <CaretSortIcon className='ml-auto h-4 w-4 opacity-50' />
                    </Button>
                </PopoverTrigger>

                <PopoverContent className='w-[200px] p-0'>
                    <Command
                        className='max-h-[200px]'
                        filter={(value, item) => {
                            if (value.includes('new partner')) return 1;

                            if (
                                value
                                    .toLocaleLowerCase()
                                    .includes(item.toLocaleLowerCase())
                            ) {
                                return 1;
                            }

                            return 0;
                        }}
                    >
                        <CommandInput
                            placeholder='Search...'
                            className='h-4 text-xs'
                        />
                        <CommandEmpty>No results found.</CommandEmpty>
                        <CommandGroup>
                            {options.map((option) => (
                                <CommandItem
                                    value={option.label}
                                    key={option.value}
                                    className={cn('text-xs', '')}
                                    onSelect={() => {
                                        if (option.value === value) {
                                            setValue('');
                                        } else {
                                            setValue(option.value.toString());
                                        }

                                        setOpen(false);
                                    }}
                                >
                                    {option.label}
                                    <CheckIcon
                                        className={cn(
                                            'ml-auto h-4 w-4',
                                            option.value === value
                                                ? 'opacity-100'
                                                : 'opacity-0'
                                        )}
                                    />
                                </CommandItem>
                            ))}
                        </CommandGroup>
                    </Command>
                </PopoverContent>
            </Popover>
        </div>
    );
};
