import { cloneElement, useMemo, useState } from 'react';
import { Cell, Label, Pie, PieChart } from 'recharts';
import { useSuppliers } from 'src/app/_api_adb2c/purchase/suppliers/hooks/use-suppliers';
import {
    SupplyChainNodeType,
    SupplyChainNodeTypeLabel,
} from 'src/app/_api_adb2c/workspace/shared/enum/supply-chain-node-type.enum';
import {
    Card,
    CardContent,
    CardDescription,
    CardHeader,
    CardTitle,
} from 'src/components/ui/card';
import {
    ChartConfig,
    ChartContainer,
    ChartTooltip,
    ChartTooltipContent,
} from 'src/components/ui/chart';
import { generateColors, observable12 } from '.';
import { EmptyChart } from '../empty-chart';
import { getDateRangeDescription } from '../utils/get-date-range-description';

const chartConfig = {
    count: {
        label: 'Count',
        color: 'hsl(var(--chart-1))',
    },
} satisfies ChartConfig;

interface TooltipProps {
    content: string;
    children: React.ReactElement;
}

export const Tooltip: React.FC<TooltipProps> = ({ content, children }) => {
    const [isVisible, setIsVisible] = useState(false);

    return (
        <div className='relative inline-block'>
            {cloneElement(children, {
                onMouseEnter: () => setIsVisible(true),
                onMouseLeave: () => setIsVisible(false),
            })}
            {isVisible && (
                <div className='absolute -top-8 left-1/2 z-10 -translate-x-1/2 transform rounded-md bg-black px-2 py-1 text-sm text-white shadow-md'>
                    {content}
                </div>
            )}
        </div>
    );
};

interface Props {
    startDate?: Date;
    endDate?: Date;
}

export function SupplierCapabilitiyDistribution({ startDate, endDate }: Props) {
    const { data: suppliers } = useSuppliers();
    const colors = generateColors(observable12);

    const graphData = useMemo(() => {
        const capabilityCounts: {
            label: string;
            count: number;
            fill: string;
        }[] = [];

        suppliers?.forEach((supplier, index) => {
            if (!supplier.seller.processes) return;

            if (startDate && endDate) {
                const createdDate = new Date(supplier.createdOn);

                if (createdDate < startDate || createdDate > endDate) return;
            }

            const process = supplier.seller.processes?.[0] || 'No Process';

            if (!process) return;

            const existing = capabilityCounts.find((x) =>
                x.label
                    .toLocaleLowerCase()
                    .includes(process.toLocaleLowerCase())
            );

            // Get color based of last digit of index
            const color = colors[index % colors.length];

            if (existing) {
                existing.count++;
            } else {
                capabilityCounts.push({
                    label: process,
                    count: 1,
                    fill: color,
                });
            }
        });

        return capabilityCounts.map((x) => ({
            ...x,
            label:
                SupplyChainNodeTypeLabel[x.label as SupplyChainNodeType] ||
                'No Process',
        }));
    }, [suppliers, colors, startDate, endDate]);

    const dateRangeDescription = useMemo(
        () => getDateRangeDescription(startDate, endDate),
        [startDate, endDate]
    );

    return (
        <Card className='px-4 py-2 shadow'>
            <CardHeader>
                <CardTitle>Capability Distribution</CardTitle>
                <CardDescription>{dateRangeDescription}</CardDescription>
            </CardHeader>

            <CardContent>
                {graphData.length > 0 ? (
                    <ChartContainer
                        config={chartConfig}
                        className='mx-auto aspect-square h-56 w-full'
                    >
                        <PieChart>
                            <ChartTooltip
                                cursor={false}
                                content={<ChartTooltipContent />}
                            />

                            <Pie
                                data={graphData}
                                dataKey='count'
                                nameKey='label'
                                innerRadius={60}
                            >
                                {graphData.map((entry, index) => (
                                    <Cell
                                        key={`cell-${index}`}
                                        fill={entry.fill}
                                    />
                                ))}

                                <Label
                                    content={({ viewBox }) => {
                                        if (
                                            viewBox &&
                                            'cx' in viewBox &&
                                            'cy' in viewBox
                                        ) {
                                            return (
                                                <text
                                                    x={viewBox.cx}
                                                    y={viewBox.cy}
                                                    textAnchor='middle'
                                                    dominantBaseline='middle'
                                                >
                                                    <tspan
                                                        x={viewBox.cx}
                                                        y={viewBox.cy}
                                                        className='fill-foreground text-3xl font-bold'
                                                    >
                                                        {graphData.length}
                                                    </tspan>
                                                    <tspan
                                                        x={viewBox.cx}
                                                        y={
                                                            (viewBox.cy || 0) +
                                                            24
                                                        }
                                                        className='fill-muted-foreground'
                                                    >
                                                        Capabilities
                                                    </tspan>
                                                </text>
                                            );
                                        }
                                    }}
                                />
                            </Pie>
                        </PieChart>
                    </ChartContainer>
                ) : (
                    <EmptyChart />
                )}
            </CardContent>
        </Card>
    );
}
