import { ColumnDef } from '@tanstack/react-table';
import { GanttChartSquare } from 'lucide-react';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { DataTable } from 'src/app/components/DataTable';
import { Button } from 'src/components/ui/button';
import { Checkbox } from 'src/components/ui/checkbox';
import { UnitType } from 'src/domain/enums/unit-type.enum';
import { getUnitValue } from 'src/infrastructure/utils/unit-type-labeler';
import {
    PurchaseOrderItemModel,
    PurchaseOrderVersionModel,
} from '../../../../_api/purchase/purchase-order/purchase.model';
import { DeletePurchaseItem } from './purchase-manifest-delete-item';
import { EditPurchaseItem } from './purchase-manifest-edit-item';

type Props = {
    order?: PurchaseOrderVersionModel;
    readonly?: boolean;
};

export const PurchaseManifest: React.FC<Props> = ({ order }) => {
    const navigate = useNavigate();
    const [rowSelection, setRowSelection] = useState({});

    const columns: ColumnDef<PurchaseOrderItemModel>[] = [
        {
            id: 'select',
            header: ({ table }) => (
                <Checkbox
                    checked={table.getIsAllPageRowsSelected()}
                    onCheckedChange={(value) =>
                        table.toggleAllPageRowsSelected(!!value)
                    }
                    className='ml-2 mr-2'
                    aria-label='Select all'
                />
            ),
            cell: ({ row }) => (
                <Checkbox
                    checked={row.getIsSelected()}
                    onCheckedChange={(value) => row.toggleSelected(!!value)}
                    aria-label='Select row'
                    className='ml-2 mr-2 text-white'
                />
            ),
            enableSorting: false,
            enableHiding: false,
            size: 50,
        },
        {
            id: 'product',
            header: 'Product',
            accessorFn: (row) => row.purchaseables.name,
        },
        {
            id: 'category',
            header: 'Category',
            accessorFn: (row) => row.purchaseables.category,
        },
        {
            id: 'uom',
            header: 'UOM',
            accessorFn: (row) =>
                row.purchaseables.unit
                    ? getUnitValue(row.purchaseables.unit as UnitType)
                    : '',
        },
        {
            id: 'quantity',
            header: 'Quantity',
            accessorFn: (row) => row.quantity,
        },
        { id: 'price', header: 'Price', accessorFn: (row) => row.ppu },
        { id: 'comment', header: 'Comment', accessorFn: (row) => row.comment },
        {
            id: 'lastUpdated',
            header: 'Last Updated',
            accessorFn: (row) =>
                row.purchaseables.lastUpdatedOn
                    ? new Date(
                          row.purchaseables.lastUpdatedOn
                      ).toLocaleDateString()
                    : '',
        },
        {
            id: 'actions',
            header: 'Actions',
            cell: ({ row }) => {
                return (
                    <div className='flex gap-1'>
                        {order && (
                            <>
                                <Button variant='ghost' className='p-0 px-2'>
                                    <GanttChartSquare
                                        className='h-4 w-4'
                                        onClick={() => [
                                            navigate(
                                                `/products/${row.original.purchaseables.id}`
                                            ),
                                        ]}
                                    />
                                </Button>

                                <EditPurchaseItem
                                    order={order}
                                    manifest={row.original}
                                />

                                <DeletePurchaseItem
                                    order={order}
                                    manifest={row.original}
                                />
                            </>
                        )}
                    </div>
                );
            },
        },
    ];

    return (
        <DataTable
            data={order?.manifest || []}
            columns={columns}
            rowSelection={rowSelection}
            setRowSelection={setRowSelection}
            label='Purchase Manifest'
            // headerClassName='bg-red-800 text-white'
        />
    );
};
