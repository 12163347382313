export const SALES_ORDER_CLEAR_KEY = ['sales-orders'];
export const SALES_ORDERS_QUERY_KEY = (workspaceId: string) => [
    ...SALES_ORDER_CLEAR_KEY,
    workspaceId,
];

export const SALES_ORDER_SEARCH_CLEAR_KEY = ['sales-orders-search'];
export const SALES_ORDER_SEARCH_QUERY_KEY = (
    workspaceId: string,
    ids: number[]
) => [...SALES_ORDER_SEARCH_CLEAR_KEY, workspaceId, ids];
