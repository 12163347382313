import { useSearchParams } from 'react-router-dom';
import { useDeleteCategory } from 'src/app/_api_adb2c/product/category/hooks/use-delete-category';
import { RemoveDialog } from '../Purchases/Details/delete-purchase';

interface Props {
    open: boolean;
    categoryId: string;
    onClose: () => void;
    onRemove: () => void;
}

export function DeleteCategory({ open, onClose, onRemove, categoryId }: Props) {
    const [searchParams] = useSearchParams();
    const delegateId = searchParams.get('delegateId') || '';

    const { mutateAsync, isLoading } = useDeleteCategory();

    const onSubmit = async () => {
        await mutateAsync({
            id: categoryId,
            delegateId: delegateId || '',
        });

        onClose();
    };

    return (
        <RemoveDialog
            open={open}
            onClose={onClose}
            onRemove={onSubmit}
            title='Delete Category'
            description='Are you sure you want to delete this category?'
            isLoading={isLoading}
        />
    );
}
