import { DateTime } from 'luxon';
import React, { useEffect, useMemo } from 'react';
import { PurchaseOrderNatureLabels } from 'src/app/_api_adb2c/purchase/purchase/enums/purchase-order-nature.enum';
import { usePurchase } from 'src/app/_api_adb2c/purchase/purchase/hooks/use-purchase';
import { usePurchaseTrace } from 'src/app/_api_adb2c/purchase/purchase/hooks/use-purchase-trace';
import { useSalesOrderByPurchase } from 'src/app/_api_adb2c/sales/sales/hooks/use-sales-order-by-purchase';
import { useBatchRulesets } from 'src/app/_api_adb2c/workspace/ruleset/hooks/use-batch-rulesets';
import {
    SupplyChainNodeType,
    SupplyChainNodeTypeLabel,
} from 'src/app/_api_adb2c/workspace/shared/enum/supply-chain-node-type.enum';
import { StatisticsCard } from 'src/app/pages/Purchases/Details/purchase-overview';
import { Progress } from 'src/components/ui/progress';

interface Props {
    id: string;
    workspace: string;
}

interface OverviewProps extends React.HTMLAttributes<HTMLDivElement> {
    title: string;
}

function OverviewLabel({ title, children, ...props }: OverviewProps) {
    return (
        <div className='flex flex-col gap-1' {...props}>
            <span className='font-semibold text-gray-500'>{title}</span>
            <span>{children}</span>
        </div>
    );
}

export function PurchaseCascadeNodeOverview({ id, workspace }: Props) {
    const { data: purchase } = usePurchase(id, workspace, '1');
    const { data: traces } = usePurchaseTrace(id, workspace, '1');
    const { data: salesOrder } = useSalesOrderByPurchase(
        id,
        purchase?.supplier.seller._id,
        !!purchase
    );
    const { data: rulesets } = useBatchRulesets([...(purchase?.rules || [])]);

    console.log('workspace is: ', workspace);

    const documentTypes = useMemo(() => {
        if (!purchase) return [];

        const testTypes: Record<string, string[]> = {};

        rulesets?.forEach((ruleset) => {
            ruleset.rules.forEach((rule) => {
                if (!testTypes[rule.document]) {
                    testTypes[rule.document] = [];
                }

                testTypes[rule.document].push(...rule.processes);
            });
        });

        return testTypes;
    }, [purchase, rulesets]);

    const requiredTypes = useMemo(() => {
        if (!purchase) return [];

        const requiredTypes: string[] = [];
        const processes = purchase.processes;

        Object.entries(documentTypes).forEach(([key, value]) => {
            if (processes.some((process) => value.includes(process))) {
                requiredTypes.push(key);
            }
        });

        return requiredTypes;
    }, [purchase, documentTypes]);

    const documentCompletion = useMemo(() => {
        if (!salesOrder) return undefined;
        if (requiredTypes.length === 0) return undefined;

        const existingDocuments = salesOrder?.documents;
        const totalRequiredCount = requiredTypes.length;
        let currentCount = 0;

        console.log('beginning calculation');
        existingDocuments.forEach((document) => {
            if (requiredTypes.includes(document.type)) {
                currentCount++;
            }
        });

        return (currentCount / totalRequiredCount) * 100;
    }, [requiredTypes, salesOrder]);

    useEffect(() => {
        console.log('this is the purchase', purchase);
        console.log('these are the document types', documentTypes);
        console.log('these are the required types', requiredTypes);
        console.log('these are the documents', documentCompletion);
    }, [documentTypes, requiredTypes, documentCompletion, purchase]);

    const statistics = useMemo(() => {
        let quantity = 0;
        let total = 0;

        const maxTiers = traces?.reduce((acc, curr) => {
            return Math.max(acc, curr.depth);
        }, 0);

        if (purchase) {
            quantity = purchase?.versions[
                purchase.versions.length - 1
            ]?.manifest?.reduce((acc, curr) => acc + curr.quantity, 0);

            total = purchase.versions[
                purchase.versions.length - 1
            ]?.manifest?.reduce(
                (acc, curr) => acc + curr.quantity * curr.ppu,
                0
            );
        }

        return [
            {
                label: 'Order Total',
                subtitle: 'USD',
                value: total,
            },
            {
                label: 'Order Quantities',
                subtitle: 'PCS',
                value: quantity,
            },
            {
                label: 'Cascade Levels',
                subtitle: 'Tiers',
                value: maxTiers,
            },
        ];
    }, [traces, purchase]);

    return (
        <div className='flex w-full flex-col gap-4'>
            <div className='flex justify-around gap-4 text-xs'>
                <div className='grid grid-cols-1 gap-4'>
                    <OverviewLabel title='Supplier'>
                        {purchase?.supplier.seller.name}
                    </OverviewLabel>

                    <OverviewLabel title='Supplier (Secondary)'>
                        --
                    </OverviewLabel>

                    <OverviewLabel title='Nature'>
                        {purchase?.nature
                            ? PurchaseOrderNatureLabels[purchase.nature]
                            : '--'}
                    </OverviewLabel>

                    <OverviewLabel title='Currency'>
                        {purchase?.currency}
                    </OverviewLabel>
                </div>

                <div className='col-span-1 grid grid-cols-1 gap-4'>
                    <OverviewLabel title='Issued On'>
                        {purchase?.createdOn
                            ? DateTime.fromJSDate(
                                  new Date(purchase.createdOn)
                              ).toFormat('yyyy-MM-dd')
                            : '--'}
                    </OverviewLabel>

                    <OverviewLabel title='1st Delivery (Expected)'>
                        {purchase?.shippedOn && purchase.shippedOn.length > 0
                            ? DateTime.fromJSDate(
                                  new Date(purchase.shippedOn[0])
                              ).toFormat('yyyy-MM-dd')
                            : '--'}
                    </OverviewLabel>

                    <OverviewLabel title='Address'>
                        {purchase?.shipToAddress || '--'}
                    </OverviewLabel>

                    <OverviewLabel title='Available Processes'>
                        <div className='flex flex-wrap gap-2'>
                            {purchase?.processes.map((process) => (
                                <span className='inline-flex items-center rounded-md bg-gray-100 px-1.5 py-0.5 text-xs font-medium text-gray-600'>
                                    {
                                        SupplyChainNodeTypeLabel[
                                            process as SupplyChainNodeType
                                        ]
                                    }
                                </span>
                            ))}
                        </div>
                    </OverviewLabel>
                </div>

                <div className='flex flex-col gap-4'>
                    <div className='flex flex-wrap gap-4'>
                        {statistics.map((item) => {
                            return (
                                <StatisticsCard
                                    label={item.label}
                                    subtitle={item.subtitle}
                                    value={item.value}
                                ></StatisticsCard>
                            );
                        })}
                    </div>

                    <OverviewLabel title='Mandatory Doc. Readiness'>
                        <Progress
                            className='mt-2 w-64'
                            value={documentCompletion}
                        />
                    </OverviewLabel>
                </div>
            </div>
        </div>
    );
}
