import { ServiceConfigInterface } from 'src/domain/interfaces/config.context.interface';
import { PurchaseItemApiClient } from './purchase-item.client';

export class PurchaseItemService {
    private server: PurchaseItemApiClient;

    constructor(config: ServiceConfigInterface, token: string) {
        this.server = new PurchaseItemApiClient(config, token);
    }

    list(workspace: string) {
        console.log(`service<purchase>| list(): Enter`);
        console.log(`service<purchase>| list(): $workspace = ${workspace}`);

        return this.server.list(workspace);
    }

    searchPurchaseables(workspace: string, query: string) {
        console.log(`service<purchase>| searchPurchaseables(): Enter`);
        console.log(
            `service<purchase>| searchPurchaseables(): $workspace = ${workspace}`
        );
        console.log(
            `service<purchase>| searchPurchaseables(): $query = ${query}`
        );

        return this.server.searchPurchaseables(workspace, query);
    }

    getPurchaseableCascadeMap(
        workspace: string,
        product: string,
        diversion: string = '0'
    ) {
        console.log(`service<purchase>| getPurchaseableCascadeMap(): Enter`);
        console.log(
            `service<purchase>| getPurchaseableCascadeMap(): $workspace = ${workspace}`
        );
        console.log(
            `service<purchase>| getPurchaseableCascadeMap(): $product = ${product}`
        );
        console.log(
            `service<purchase>| getPurchaseableCascadeMap(): $diversion = ${diversion}`
        );

        return this.server.getPurchaseableCascadeMap(
            workspace,
            product,
            diversion
        );
    }
}
