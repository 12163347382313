export const BASE_PURCHASE_ITEM_QUERY_KEY = ['purchase-item'];

export const PURCHASE_ITEMS_QUERY_KEY = (workspace: string) => [
    ...BASE_PURCHASE_ITEM_QUERY_KEY,
    workspace,
];

export const PURCHASE_ITEMS_SEARCH_QUERY_KEY = (
    workspace: string,
    query: string
) => [...BASE_PURCHASE_ITEM_QUERY_KEY, workspace, query];

export const PURCHASE_ITEMS_CASCADE_QUERY_KEY = (
    workspace: string,
    product: string
) => [...BASE_PURCHASE_ITEM_QUERY_KEY, 'cascade', workspace, product];
