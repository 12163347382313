import { ServiceConfigInterface } from 'src/domain/interfaces/config.context.interface';
import { ApiServer } from 'src/infrastructure/clients/api.server';
import { DelegationCreateObject } from './delegation-create-object';

export class DelegationApiClient extends ApiServer {
    constructor(config: ServiceConfigInterface, token: string) {
        super(token, config, 'workspaces', 'delegations');
    }

    create(
        workspace: string,
        diversion: string = '0',
        body: DelegationCreateObject
    ) {
        console.log(`service<delegation>| create(): Enter`);
        console.log(`service<delegation>| create(): $workspace = ${workspace}`);
        console.log(
            `service<delegation>| create(): $body = ${JSON.stringify(body)}`
        );
        console.log(`service<delegation>| create(): $diversion = ${diversion}`);
        return super.post('create', workspace, diversion, body);
    }

    list(workspace: string, diversion: string = '0') {
        console.log(`service<delegation>| list(): Enter`);
        console.log(`service<delegation>| list(): $workspace = ${workspace}`);
        console.log(`service<delegation>| list(): $diversion = ${diversion}`);

        return super.get('list', workspace, diversion);
    }

    update(
        workspace: string,
        item: string,
        diversion: string = '0',
        body: DelegationCreateObject
    ) {
        console.log(`service<delegation>| update(): Enter`);
        console.log(`service<delegation>| update(): $workspace = ${workspace}`);
        console.log(`service<delegation>| update(): $item = ${item}`);
        console.log(
            `service<delegation>| update(): $body = ${JSON.stringify(body)}`
        );
        console.log(`service<delegation>| update(): $diversion = ${diversion}`);
        return super.patch('update', workspace, item, diversion, body);
    }

    validate(workspace: string, item: string, diversion: string = '0') {
        console.log(`service<delegation>| validate(): Enter`);
        console.log(
            `service<delegation>| validate(): $workspace = ${workspace}`
        );
        console.log(`service<delegation>| validate(): $item = ${item}`);
        console.log(
            `service<delegation>| validate(): $diversion = ${diversion}`
        );

        return super.get('validate', workspace, item, diversion);
    }

    remove(workspace: string, item: string, diversion: string = '0') {
        console.log(`service<delegation>| remove(): Enter`);
        console.log(`service<delegation>| remove(): $workspace = ${workspace}`);
        console.log(`service<delegation>| remove(): $item = ${item}`);
        console.log(`service<delegation>| remove(): $diversion = ${diversion}`);
        return super.delete('remove', workspace, item, diversion);
    }
}
