import { useMutation, useQueryClient } from '@tanstack/react-query';
import { toast } from 'sonner';
import useAppContext from '../../../../../infrastructure/hooks/use-context.hook';
import { SALES_ORDER_SEARCH_CLEAR_KEY } from '../../../sales/sales-order/sales-order-query-keys';
import { PurchaseOrderUpdateDataObject } from '../purchase.model';
import {
    PURCHASE_QUERY_KEY,
    PURCHASE_TRACE_QUERY_KEY,
} from './purchase-query-keys';
import { usePurchaseService } from './use-purchase-service';

export interface UpdatePurchaseParams {
    id: string;
    delegateId?: string;
    data: PurchaseOrderUpdateDataObject;
}

export function useUpdatePurchase() {
    const context = useAppContext();
    const client = useQueryClient();
    const { service } = usePurchaseService();

    return useMutation({
        mutationFn: async ({ id, delegateId, data }: UpdatePurchaseParams) => {
            if (!context.workspace?.id || !id) return;
            await service.update(delegateId || context.workspace.id, id, data);
        },
        onSuccess: (_, { id, delegateId }) => {
            client.invalidateQueries({
                queryKey: PURCHASE_QUERY_KEY(
                    delegateId || context.workspace?.id || '',
                    id
                ),
            });

            client.invalidateQueries({
                queryKey: SALES_ORDER_SEARCH_CLEAR_KEY,
            });

            client.invalidateQueries({
                queryKey: PURCHASE_TRACE_QUERY_KEY(
                    delegateId || context.workspace?.id || '',
                    id
                ),
            });

            toast.success('Purchase order updated');
        },
        onError: (err) => {
            const error = err as Error;

            toast.error(`Failed to update purchase order: ${error.message}`);
        },
    });
}
