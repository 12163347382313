import { Trash } from 'lucide-react';
import { useCallback, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import ReactFlow, {
    Background,
    ControlButton,
    Controls,
    Edge,
    Node,
    useEdgesState,
    useNodesState,
} from 'reactflow';
import { PurchaseOrderNature } from 'src/app/_api_adb2c/purchase/purchase/enums/purchase-order-nature.enum';
import { usePurchaseTrace } from 'src/app/_api_adb2c/purchase/purchase/hooks/use-purchase-trace';
import {
    PurchaseModel,
    PurchaseOrderTraceModel,
} from 'src/app/_api_adb2c/purchase/purchase/models/purchase.model';
import { useVirtualRoot } from 'src/app/_api_adb2c/purchase/virtual-purchase/hooks/use-virtual-root';
import { useDelegations } from 'src/app/_api_adb2c/workspace/delegations/hooks/use-delegations';
import {
    SupplyChainNodeType,
    SupplyChainNodeTypeLabel,
} from 'src/app/_api_adb2c/workspace/shared/enum/supply-chain-node-type.enum';
import { useUserWorkspaces } from 'src/app/_api_adb2c/workspace/users/hooks/use-user-workspaces';
import { TooltipWrapper } from 'src/app/components/TooltipWrapper';
import {
    AddPurchase,
    CascadeNodeProps,
} from 'src/app/pages-v2/Purchases/add-purchase';
import { PurchaseCascadeNode } from 'src/app/pages-v2/Purchases/Details/purchase-cascade-node';
import { useProduct } from 'src/app/_api_adb2c/product/product/hooks/use-product';
import {
    PurchaseCascadeNodeGroup,
    PurchaseCascadeNodeGroupProps,
} from 'src/app/pages/Purchases/Details/Cascade/purchase-cascade-node-group';
import { ImportProductCascade } from './import-product-cascade';
import { PurchaseClearCascade } from '../../../Purchases/DetailsV2/Cascade/purchase-clear-cascade';

interface Props {
    productId: string;
}

export enum CascadeNodeOptions {
    NODE_GROUP = 'node-group',
    NODE = 'node',
}

const nodeTypes = {
    [CascadeNodeOptions.NODE_GROUP]: PurchaseCascadeNodeGroup,
    [CascadeNodeOptions.NODE]: PurchaseCascadeNode,
};

const getOrderPurchaseProcess = (order: PurchaseModel) => {
    if (!order) return '';

    return order.processes.length > 0
        ? SupplyChainNodeTypeLabel[order.processes[0] as SupplyChainNodeType]
        : '';
};

export const getExternalDataId = (
    order: PurchaseModel | PurchaseOrderTraceModel
) => {
    return (
        order?.reference?.find((ref) => ref.source === 'externalDataId')
            ?.value || ''
    );
};

const checkIsPurchaseTrace = (
    data: PurchaseOrderTraceModel | PurchaseModel
): data is PurchaseOrderTraceModel => {
    return (data as PurchaseOrderTraceModel).depth !== undefined;
};

export function ProductTraceMap({ productId }: Props) {
    const [searchParams] = useSearchParams();
    const delegateId = searchParams.get('delegateId') || '';

    const [mode] = useState(false);
    const [cascadeInfo, setCascadeInfo] = useState<CascadeNodeProps>();
    const [action, setAction] = useState<'create' | 'initialise' | 'import'>();
    const [isDelete, setIsDelete] = useState(false);

    const [nodes, setNodes, onNodesChange] = useNodesState([]);
    const [edges, setEdges, onEdgesChange] = useEdgesState([]);

    const { data: product } = useProduct(productId, delegateId);
    const { data: order } = useVirtualRoot(productId, delegateId);
    const { data: traces } = usePurchaseTrace(order?._id || '', delegateId);

    const { data: userWorkspaces } = useUserWorkspaces();
    const { data: delegations } = useDelegations(delegateId);

    const constructSheetTitle = useCallback(
        (order: PurchaseOrderTraceModel | PurchaseModel) => {
            const isTrace = checkIsPurchaseTrace(order);

            if (mode) {
                return isTrace
                    ? order.supplier.seller.name
                    : order.supplier.seller.name;
            }

            const externalDataId = getExternalDataId(order);

            if (externalDataId) return externalDataId;

            return `Internal Order: ${order._id}`;
        },
        [mode]
    );

    const checkIsMemberOrDelegate = useCallback(
        (id: string) => {
            const isMember = userWorkspaces?.find((x) => x._id === id);
            const isDelegate = delegations?.find(
                (x) => x.delegatedTo._id === id
            );

            if (isMember) return true;
            if (isDelegate) return true;

            return false;
        },
        [userWorkspaces, delegations]
    );

    useEffect(() => {
        if (cascadeInfo) {
            setAction('initialise');
        }
    }, [cascadeInfo]);

    const constructDefaultNodes = useCallback(() => {
        if (!order) return;

        const parent = order._id;

        const consolidatedNodes: Node[] = [
            {
                id: `group-${parent}`,
                type: CascadeNodeOptions.NODE_GROUP,
                position: { x: 20, y: 20 },
                data: {
                    tier: 0,
                    height: 160,
                    label: getOrderPurchaseProcess(order),
                } as PurchaseCascadeNodeGroupProps['data'],
            },
            {
                id: `node-${parent}`,
                type: CascadeNodeOptions.NODE,
                position: { x: 8, y: 40 },
                parentNode: `group-${parent}`,
                data: {
                    mode: mode ? 'supplier' : 'order',
                    label: order.supplier.seller.name,
                    sheetLabel: constructSheetTitle(order),
                    purchase: order,
                    isDelegated: true,
                    setCascadeInfo: setCascadeInfo,
                },
            },
        ];

        const item: {
            [key: string]: {
                [key: string]: PurchaseOrderTraceModel[];
            };
        } = {};

        traces
            ?.sort((t1, t2) => {
                const t1CreatedDate = new Date(t1.createdOn).getTime();
                const t2CreatedDate = new Date(t2.createdOn).getTime();

                return t1CreatedDate - t2CreatedDate;
            })
            .forEach((trace) => {
                const tier = trace.depth?.toString();
                const purchaseProcess =
                    trace.processes.length > 0 ? trace.processes[0] : 'Unknown';

                if (!tier) return;

                if (!item[tier]) {
                    item[tier] = {};
                }

                if (!item[tier][purchaseProcess]) {
                    item[tier][purchaseProcess] = [];
                }

                item[tier][purchaseProcess].push(trace);
            });

        Object.entries(item).forEach(([tier, processes], index) => {
            let previousNodeHeight = 0;

            Object.entries(processes).forEach(
                ([process, purchases], processIndex) => {
                    const group = {
                        id: `group-${parent}-${index}-${process}`,
                        type: CascadeNodeOptions.NODE_GROUP,
                        position: {
                            x: 20 + 250 * (index + 1),
                            y: 20 + 60 * processIndex + previousNodeHeight,
                        },
                        data: {
                            tier: parseInt(tier),
                            height: 160 * purchases.length,
                            label:
                                SupplyChainNodeTypeLabel[
                                    process as SupplyChainNodeType
                                ] || 'Not Specified',
                        } as PurchaseCascadeNodeGroupProps['data'],
                    };

                    previousNodeHeight += group.data.height;

                    consolidatedNodes.push(group);

                    purchases.forEach((purchase, index) => {
                        const node = {
                            id: `node-${purchase._id}`,
                            type: CascadeNodeOptions.NODE,
                            position: { x: 8, y: 40 + 160 * index },
                            parentNode: `group-${parent}-${tier}-${process}`,
                            data: {
                                mode: mode ? 'supplier' : 'order',
                                label: purchase.supplier.seller.name,
                                sheetLabel: constructSheetTitle(purchase),
                                purchase: purchase,
                                isDelegated: checkIsMemberOrDelegate(
                                    purchase.supplier._id
                                ),
                                setCascadeInfo: setCascadeInfo,
                            },
                        };

                        consolidatedNodes.push(node);
                    });
                }
            );
        });

        setNodes(consolidatedNodes);
    }, [
        order,
        setNodes,
        constructSheetTitle,
        mode,
        traces,
        checkIsMemberOrDelegate,
    ]);

    useEffect(() => {
        const edges: Edge[] = [];

        const getColour = (type: PurchaseOrderNature) => {
            switch (type) {
                case PurchaseOrderNature.OUTSOURCE:
                    return '#FF9400';
                case PurchaseOrderNature.PROCESS:
                    return '#007C77';
                default:
                    return '#000';
            }
        };

        traces?.forEach((trace) => {
            edges.push({
                id: `${order?._id} - ${trace._id}`,
                source: `node-${trace.parent}`,
                target: `node-${trace._id}`,
                zIndex: 1,
                style: {
                    stroke: getColour(trace.nature),
                },
            });
        });

        setEdges(edges);
    }, [setEdges, traces, order]);

    useEffect(() => {
        constructDefaultNodes();
    }, [constructDefaultNodes]);

    return (
        <>
            <ReactFlow
                className='h-full bg-gray-100'
                proOptions={{
                    hideAttribution: true,
                }}
                nodes={nodes}
                nodeTypes={nodeTypes}
                edges={edges}
                minZoom={0.001}
                onNodesChange={onNodesChange}
                onEdgesChange={onEdgesChange}
                defaultViewport={{
                    zoom: 0.5,
                    x: 0,
                    y: 0,
                }}
            >
                <Background />
                <Controls showInteractive={false}>
                    <ControlButton
                        onClick={() => {
                            setIsDelete(true);
                        }}
                    >
                        <TooltipWrapper label='Clear Cascade'>
                            <Trash />
                        </TooltipWrapper>
                    </ControlButton>
                </Controls>
            </ReactFlow>

            {action === 'initialise' && (
                <AddPurchase
                    product={product}
                    open={action === 'initialise'}
                    onClose={() => {
                        setAction(undefined);
                        setCascadeInfo(undefined);
                    }}
                    cascade={cascadeInfo}
                />
            )}

            {action === 'import' && (
                <ImportProductCascade
                    open={action === 'import'}
                    order={order}
                    onClose={() => {
                        setAction(undefined);
                    }}
                />
            )}

            {isDelete && (
                <PurchaseClearCascade
                    open={isDelete}
                    onClose={() => setIsDelete(false)}
                    purchase={order?._id || ''}
                />
            )}
        </>
    );
}
