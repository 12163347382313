import { useQuery } from '@tanstack/react-query';
import { useContextStore } from 'src/app/stores/context-store';
import { CATEGORIES_QUERY_KEY } from './category-query-keys';
import { useCategoryService } from './use-category-service';
import { toast } from 'sonner';
import { ProductCategoriesModel } from '../category.model';

export function useCategories(delegateId?: string) {
    const { service } = useCategoryService();
    const context = useContextStore();

    return useQuery<ProductCategoriesModel[]>({
        queryKey: CATEGORIES_QUERY_KEY(
            delegateId || context.workspace?._id || ''
        ),
        queryFn: async () => {
            return await service.loadCategories(
                delegateId || context.workspace?._id || ''
            );
        },
        onError: (err) => {
            const error = err as Error;

            toast.error(`Failed to load categories: ${error.message}`);
        },
    });
}
