import { SelectInputV2 } from 'src/app/components/Form/SelectInputV2';
import { MaterialSource, UsageNatureType } from './add-product-material-v3';

interface MaterialSelectionProps {
    usageNature: UsageNatureType;
    materialType: MaterialSource;
    productUsageOptions: { label: string; value: string }[];
    packagingUsageOptions: { label: string; value: string }[];
    customMaterialOptions: { label: string; value: string }[];
}

export function MaterialSelection({
    usageNature,
    materialType,
    productUsageOptions,
    packagingUsageOptions,
    customMaterialOptions,
}: MaterialSelectionProps) {
    if (materialType === 'custom') {
        return (
            <SelectInputV2
                label='Material'
                name='materialId'
                options={customMaterialOptions}
            />
        );
    }

    if (usageNature === 'PRODUCT') {
        return (
            <SelectInputV2
                name='baseMaterial'
                options={productUsageOptions}
                label='Material'
            />
        );
    }

    return (
        <SelectInputV2
            name='baseMaterial'
            label='Material'
            options={packagingUsageOptions}
        />
    );
}
