import { useContextStore } from 'src/app/stores/context-store';
import { useWorkspaceService } from './use-workspace-service';
import { useQuery } from '@tanstack/react-query';
import { WORKSPACE_MEMBERS_QUERY_KEY } from './workspace-query-key';
import { toast } from 'sonner';
import { UserModel } from '../../users/user.model';

export function useWorkspaceMembers(delegateId?: string) {
    const { service } = useWorkspaceService();
    const { workspace } = useContextStore();

    return useQuery<UserModel[]>({
        queryKey: WORKSPACE_MEMBERS_QUERY_KEY(
            delegateId || workspace?._id || ''
        ),
        queryFn: async () => {
            return await service.getMembers(delegateId || workspace?._id || '');
        },
        onError: (err) => {
            const error = err as Error;
            toast.error(`Failed to load workspace members: ${error.message}`);
        },
        enabled: !!workspace?._id,
    });
}
