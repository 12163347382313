export const BASE_PRODUCT_STATISTICS_QUERY_KEY = 'product-statistics';

export const PRODUCT_STATISTICS_REPORT_BY_CATEGORY_QUERY_KEY = (
    workspace: string,
    start: string,
    end: string
) => [
    BASE_PRODUCT_STATISTICS_QUERY_KEY,
    'report-by-category',
    workspace,
    start,
    end,
];
