import { format } from 'date-fns';
import { CalendarIcon } from 'lucide-react';
import { useFormContext } from 'react-hook-form';
import { cn } from 'src/app/utils/cn';
import { Button } from 'src/components/ui/button';
import { Calendar } from 'src/components/ui/calendar';
import {
    FormControl,
    FormField,
    FormItem,
    FormLabel,
    FormMessage,
} from 'src/components/ui/form';
import {
    Popover,
    PopoverContent,
    PopoverTrigger,
} from 'src/components/ui/popover';

export const DateInput = ({
    label,
    name,
    disabled = false,
    multiple = false,
    alignment = 'column',
}: {
    label?: string;
    name: string;
    disabled?: boolean;
    multiple?: boolean;
    alignment?: 'column' | 'row';
}) => {
    const styles: {
        [key: string]: {
            formItem: string;
            formLabel: string;
        };
    } = {
        column: {
            formItem: '',
            formLabel: 'text-xs',
        },
        row: {
            formItem: 'flex items-center gap-4',
            formLabel: 'w-[25%] truncate text-xs',
        },
    };

    const { control } = useFormContext();

    if (disabled) {
        return (
            <FormField
                control={control}
                name={name}
                render={({ field }) => (
                    <FormItem className=''>
                        <FormLabel className='text-xs font-bold'>
                            {label}
                        </FormLabel>
                        <br />
                        <span className='flex h-7 w-full py-1 text-xs'>
                            {field.value
                                ? new Date(field.value).toLocaleDateString()
                                : '-'}
                        </span>
                    </FormItem>
                )}
            />
        );
    }

    return (
        <FormField
            control={control}
            name={name}
            render={({ field }) => (
                <FormItem className={styles[alignment].formItem}>
                    {label && (
                        <FormLabel className={styles[alignment].formLabel}>
                            {label}
                        </FormLabel>
                    )}

                    <Popover>
                        <PopoverTrigger asChild>
                            <FormControl>
                                <Button
                                    variant={'outline'}
                                    className={cn(
                                        'w-full pl-3 text-left text-xs font-normal',
                                        !field.value && 'text-muted-foreground'
                                    )}
                                    data-testid='date-input'
                                >
                                    <span className='max-w-[70%] truncate'>
                                        {field.value
                                            ? format(field.value, 'PPP')
                                            : 'Pick a date'}
                                    </span>
                                    <CalendarIcon className='ml-auto h-4 w-4 opacity-50' />
                                </Button>
                            </FormControl>
                        </PopoverTrigger>
                        <PopoverContent className='w-auto p-0' align='start'>
                            <Calendar
                                mode={multiple ? 'multiple' : 'single'}
                                data-testid='date-input-calendar'
                                selected={field.value}
                                onSelect={field.onChange}
                                initialFocus
                            />
                        </PopoverContent>
                    </Popover>
                    <FormMessage />
                </FormItem>
            )}
        />
    );
};
