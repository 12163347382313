import { Button } from 'src/components/ui/button';
import {
    Dialog,
    DialogContent,
    DialogFooter,
    DialogTitle,
} from 'src/components/ui/dialog';

interface Props {
    open: boolean;
    onClose: () => void;
    onSubmit: () => void;
}

export const DeleteMember = ({ open, onClose, onSubmit }: Props) => {
    return (
        <Dialog open={open} onOpenChange={onClose}>
            <DialogContent>
                <DialogTitle className='text-md'>Remove User</DialogTitle>

                <span className='text-sm'>
                    Are you sure you want to remove this user?
                </span>

                <DialogFooter>
                    <Button
                        size='sm'
                        variant='destructive'
                        onClick={() => onSubmit()}
                    >
                        Remove
                    </Button>
                </DialogFooter>
            </DialogContent>
        </Dialog>
    );
};
