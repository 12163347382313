import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';
import { useProduct } from 'src/app/_api_adb2c/product/product/hooks/use-product';
import { useProductClone } from 'src/app/_api_adb2c/product/product/hooks/use-product-clone';
import { IExternalReference } from 'src/app/_api_adb2c/product/product/models/product.model';
import { DialogV2 } from 'src/app/components-v2/dialog-v2';
import { TextInputV2 } from 'src/app/components/Form/TextInputV2';
import { z } from 'zod';

interface Props {
    product: string;
    open: boolean;
    onClose: () => void;
}

const schema = z.object({
    name: z.string(),
    productNumber: z.string().optional(),
    externalReference: z.string().optional(),
    upc: z.string().optional(),
});

export function CloneProduct({ product, open, onClose }: Props) {
    const [searchParams] = useSearchParams();
    const delegateId = searchParams.get('delegateId') || '';

    const { mutateAsync: clone, isLoading } = useProductClone();

    const { data } = useProduct(product);
    const form = useForm<z.infer<typeof schema>>({
        mode: 'onChange',
        resolver: zodResolver(schema),
        defaultValues: {
            name: data?.name || '',
            externalReference:
                data?.externalReferences?.find(
                    (x) => x.source === 'externalDataId'
                )?.value || '',
            productNumber:
                data?.externalReferences?.find((x) => x.source === 'customer')
                    ?.value || '',
        },
    });

    const onSubmit = async (params: z.infer<typeof schema>) => {
        console.log(params);
        const references: IExternalReference[] = [];

        if (params?.externalReference) {
            references.push({
                source: 'externalDataId',
                value: params.externalReference,
            });
        }

        if (params?.productNumber) {
            references.push({
                source: 'externalReferenceCode',
                value: params.productNumber,
            });
        }

        await clone({
            item: product,
            body: {
                name: params.name,
                externalReferences: references,
                upc: params.upc,
            },
            delegateId: delegateId,
        });

        reset();
    };

    const reset = () => {
        form.reset();
        onClose();
    };

    return (
        <DialogV2
            title='Clone Product'
            open={open}
            onClose={onClose}
            formName='clone-product-form'
            form={form}
            onSubmit={onSubmit}
            isStepDialog
            isLoading={isLoading}
            steps={[
                {
                    title: 'Product Information',
                    description: 'Enter the product information to be cloned',
                    content: (
                        <div>
                            <TextInputV2 label='Name' name='name' />
                            <TextInputV2
                                label='Product Number'
                                name='productNumber'
                            />
                            <TextInputV2
                                label='External Reference'
                                name='externalReference'
                            />
                            <TextInputV2 label='UPC' name='upc' />
                        </div>
                    ),
                },
            ]}
        />
    );
}
