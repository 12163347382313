import { CaretSortIcon } from '@radix-ui/react-icons';
import { CheckIcon } from 'lucide-react';
import { useEffect, useState } from 'react';
import {
    FieldPath,
    FieldValues,
    useFormContext,
    PathValue,
} from 'react-hook-form';
import { cn } from 'src/lib/utils';
import { Button } from 'src/components/ui/button';
import {
    Command,
    CommandEmpty,
    CommandGroup,
    CommandInput,
    CommandItem,
} from 'src/components/ui/command';
import {
    FormControl,
    FormField,
    FormItem,
    FormLabel,
    FormMessage,
} from 'src/components/ui/form';
import {
    Popover,
    PopoverContent,
    PopoverTrigger,
} from 'src/components/ui/popover';

interface SelectInputV2Props<
    TFieldValues extends FieldValues = FieldValues,
    TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> {
    name: TName;
    label?: string;
    options: { label: string; value: string; disabled?: boolean }[];
    placeholder?: string;
    disabled?: boolean;
    className?: string;
    optionClassName?: string;
    setCustomSearch?: (value: string) => void;
    tabIndex?: number;
}

export function SelectInputV2<
    TFieldValues extends FieldValues = FieldValues,
    TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
    name,
    label,
    options,
    placeholder,
    disabled = false,
    className,
    optionClassName,
    setCustomSearch,
    tabIndex,
}: SelectInputV2Props<TFieldValues, TName>) {
    const [open, setOpen] = useState(false);
    const { control, setValue } = useFormContext<TFieldValues>();
    const [allOptions, setAllOptions] = useState(options);

    useEffect(() => {
        setAllOptions(options);
    }, [options]);

    if (disabled) {
        return (
            <FormField
                control={control}
                name={name}
                render={({ field }) => (
                    <FormItem>
                        {label && (
                            <FormLabel className='text-xs font-bold'>
                                {label}
                            </FormLabel>
                        )}
                        <span className='flex h-7 w-full py-1 text-xs'>
                            {field.value || '-'}
                        </span>
                    </FormItem>
                )}
            />
        );
    }

    return (
        <FormField
            control={control}
            name={name}
            render={({ field }) => (
                <FormItem className=''>
                    {label && (
                        <FormLabel className='text-xs'>{label}</FormLabel>
                    )}
                    <Popover open={open} onOpenChange={setOpen}>
                        <PopoverTrigger asChild>
                            <FormControl>
                                <Button
                                    variant='outline'
                                    size='sm'
                                    data-testid='select-input'
                                    tabIndex={tabIndex}
                                    className={cn(
                                        'h-8 w-full pl-3 text-left font-normal capitalize',
                                        className,
                                        !field.value && 'text-muted-foreground'
                                    )}
                                >
                                    <span className='max-w-[75%] truncate text-xs'>
                                        {field.value
                                            ? allOptions.find(
                                                  (option) =>
                                                      option.value ===
                                                      field.value
                                              )?.label
                                            : placeholder || '-'}
                                    </span>
                                    <CaretSortIcon className='ml-auto h-4 w-4 opacity-50' />
                                </Button>
                            </FormControl>
                        </PopoverTrigger>

                        <PopoverContent className='p-0'>
                            <Command
                                className='max-h-[200px]'
                                loop
                                data-testid='select-input-command'
                            >
                                <CommandInput
                                    placeholder='Search...'
                                    className='h-4 text-xs'
                                    data-testid='select-input-command-input'
                                    onValueChange={(value) => {
                                        if (setCustomSearch) {
                                            setCustomSearch(value);
                                        }
                                    }}
                                />
                                <CommandEmpty>No results found.</CommandEmpty>
                                <CommandGroup>
                                    {allOptions.map((option) => (
                                        <CommandItem
                                            value={option.label}
                                            key={option.value}
                                            data-testid='select-input-command-item'
                                            className={cn(
                                                'text-xs',
                                                optionClassName,
                                                option.disabled &&
                                                    'cursor-not-allowed opacity-50'
                                            )}
                                            disabled={option.disabled}
                                            onSelect={() => {
                                                if (
                                                    option.value === field.value
                                                ) {
                                                    setValue(
                                                        name,
                                                        '' as PathValue<
                                                            TFieldValues,
                                                            TName
                                                        >
                                                    );
                                                } else {
                                                    setValue(
                                                        name,
                                                        option.value as PathValue<
                                                            TFieldValues,
                                                            TName
                                                        >
                                                    );
                                                }
                                                setOpen(false);
                                            }}
                                        >
                                            {option.label}
                                            <CheckIcon
                                                className={cn(
                                                    'ml-auto h-4 w-4',
                                                    option.value === field.value
                                                        ? 'opacity-100'
                                                        : 'opacity-0'
                                                )}
                                            />
                                        </CommandItem>
                                    ))}
                                </CommandGroup>
                            </Command>
                        </PopoverContent>
                    </Popover>
                    <FormMessage />
                </FormItem>
            )}
        />
    );
}
