import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import { AppWindow, HelpCircle } from 'lucide-react';
import { useEffect, useState } from 'react';
import {
    FeatureGroup,
    MapContainer,
    Marker,
    Polyline,
    Popup,
    TileLayer,
} from 'react-leaflet';
import Control from 'react-leaflet-custom-control';
import {
    Tooltip,
    TooltipContent,
    TooltipProvider,
    TooltipTrigger,
} from 'src/components/ui/tooltip';
import { cn } from 'src/lib/utils';
import PinIcon from '../../../../assets/location.png';
import { Tags } from '../../Components/Tag';
import {
    PurchaseOrderTraceModel,
    PurchaseOrderVersionModel,
} from '../../../_api/purchase/purchase-order/purchase.model';
import { useWorkspaceById } from '../../../_api/workspace/workspace/hooks/use-workspace-by-id';

type Props = {
    order?: PurchaseOrderVersionModel;
    traces?: PurchaseOrderTraceModel[];
};

interface GeoJson {
    fromLatitude: number;
    fromLongitude: number;
    id: number;
    toLatitude: number;
    toLongitude: number;
    name: string;
    tier: number;
}

const lineColours = [
    'blue',
    'green',
    'purple',
    'orange',
    'darkred',
    'beige',
    'darkblue',
    'darkgreen',
    'cadetblue',
    'darkpurple',
    'pink',
    'lightblue',
    'lightgreen',
    'gray',
    'black',
];

export const customMarkerUserPos = new L.Icon({
    iconUrl: 'https://unpkg.com/leaflet@1.5.1/dist/images/marker-icon.png',
    iconSize: [25, 30],
    iconAnchor: [15, 30],
    popupAnchor: [2, -40],
});

export const PurchaseTracking: React.FC<Props> = ({ order, traces }) => {
    const [controlsOpen, setControlsOpen] = useState(false);
    const [legendOpen, setLegendOpen] = useState(false);
    const [geolocations, setGeolocations] = useState<
        { latitude: number; longitude: number; name: string }[]
    >([]);
    const [polylines, setPolylines] = useState<GeoJson[]>([]);

    const workspaceIds = new Set([
        order?.owner.supplier.owner?.id || '',
        order?.owner.supplier.seller?.id || '',
        ...(traces || []).map((x) => x.supplier.seller?.id || ''),
        ...(traces || []).map((x) => x.supplier.owner?.id || ''),
    ]);

    const { data } = useWorkspaceById({
        workspaceId: Array.from(workspaceIds),
    });

    useEffect(() => {
        if (!data) return;

        const parent = data.find(
            (x) => x.id === order?.owner.supplier.owner?.id
        );

        const polylines: GeoJson[] = [];
        const markerLocations = data
            .filter((x) => {
                return x.company?.gelocation?.lat && x.company?.gelocation?.lon;
            })
            .map((x) => {
                return {
                    longitude: x.company?.gelocation?.lon || 0,
                    latitude: x.company?.gelocation?.lat || 0,
                    name: x.company?.name || '',
                };
            });

        setGeolocations(markerLocations);

        traces?.forEach((x) => {
            const fromCompany = data.find((y) => y.id === x.supplier.owner?.id);
            const company = data.find((y) => y.id === x.supplier?.seller?.id);

            if (x.path.length === 2) {
                if (
                    parent?.company.gelocation?.lon &&
                    parent.company.gelocation.lat &&
                    fromCompany?.company.gelocation?.lat &&
                    fromCompany.company.gelocation.lon
                ) {
                    polylines.push({
                        fromLatitude: parent.company.gelocation.lat,
                        fromLongitude: parent.company.gelocation.lon,
                        id: x.salesOrder,
                        toLongitude: fromCompany.company.gelocation.lon,
                        toLatitude: fromCompany.company.gelocation.lat,
                        name: fromCompany?.company?.name || '',
                        tier: 0,
                    });
                }

                if (
                    fromCompany?.company.gelocation?.lon &&
                    fromCompany.company.gelocation.lat &&
                    company?.company.gelocation?.lat &&
                    company.company.gelocation.lon
                ) {
                    polylines.push({
                        fromLatitude: fromCompany.company.gelocation.lat,
                        fromLongitude: fromCompany.company.gelocation.lon,
                        id: x.salesOrder,
                        toLongitude: company.company.gelocation.lon,
                        toLatitude: company.company.gelocation.lat,
                        name: company.company?.name || '',
                        tier: 0,
                    });
                }
            } else {
                if (
                    fromCompany?.company.gelocation?.lat &&
                    fromCompany.company.gelocation?.lon &&
                    company?.company.gelocation?.lon &&
                    company?.company.gelocation?.lat
                ) {
                    const record: GeoJson = {
                        fromLatitude:
                            fromCompany?.company?.gelocation?.lat || 0,
                        fromLongitude:
                            fromCompany?.company?.gelocation?.lon || 0,
                        id: x.salesOrder,
                        toLatitude: company?.company.gelocation?.lat || 0,
                        toLongitude: company?.company.gelocation?.lon || 0,
                        name: company.company?.name || '',
                        tier: x.path.length - 2,
                    };
                    polylines.push(record);
                } else {
                    console.log('missing geolocation');
                }
            }
        });

        setPolylines(polylines);
    }, [data, traces, order]);

    console.log('polyline', polylines);

    useEffect(() => {
        console.log(controlsOpen);
    }, [controlsOpen]);

    return (
        <div className='relative h-full'>
            <div
                className={cn(
                    'absolute right-14 top-[0.7rem] z-[1000] flex w-80 flex-col gap-2 rounded bg-white p-2 shadow',
                    `${controlsOpen ? 'flex' : 'hidden'}`
                )}
            >
                <div className='flex items-center justify-between border-b pb-1'>
                    <span className='flex items-center gap-2 text-sm font-semibold'>
                        Total Partners:
                        <span className='text-xs font-light'>
                            {data?.length || 0} companies
                        </span>
                    </span>
                </div>

                <div className='flex max-h-[250px] min-h-[250px] flex-col gap-4 overflow-auto px-4 py-2 text-sm'>
                    {data?.map((x, index) => (
                        <div
                            key={index}
                            className='flex items-center justify-between gap-4'
                        >
                            <button className='flex flex-1 gap-4 overflow-hidden'>
                                <div className='relative left-0 flex max-w-full items-center gap-2 bg-white'>
                                    <img
                                        src={PinIcon}
                                        className='max-h-[30px] max-w-[30px] '
                                        alt='Pin Icon'
                                    />

                                    <div className='flex max-w-full flex-col overflow-auto'>
                                        <span className='truncate text-start'>
                                            {x.company?.name}
                                        </span>

                                        <Tags
                                            tags={
                                                x.company.supplyChainNodeType?.map(
                                                    (x) => x.toString()
                                                ) || []
                                            }
                                        />
                                    </div>
                                </div>
                            </button>
                        </div>
                    ))}
                </div>
            </div>

            <div
                className={cn(
                    'absolute right-14 top-[0.7rem] z-[1000] flex w-80 flex-col gap-2 rounded bg-white p-2 shadow',
                    `${legendOpen ? 'flex' : 'hidden'}`
                )}
            >
                <div className='flex items-center justify-between border-b pb-1'>
                    <span className='flex items-center gap-2 text-sm font-semibold'>
                        Legend
                    </span>
                </div>

                <div className='flex max-h-[250px] min-h-[250px] flex-col gap-4 overflow-auto px-4 py-2 text-sm'>
                    {lineColours.map((x, index) => (
                        <div>
                            <div className='flex items-center gap-4'>
                                <div
                                    className={`h-4 w-4 rounded-full`}
                                    style={{
                                        color: x,
                                        backgroundColor: x,
                                    }}
                                ></div>

                                <span className='text-xs capitalize'>
                                    Tier {index + 1}
                                </span>
                            </div>
                        </div>
                    ))}
                </div>
            </div>

            <MapContainer
                className='h-full w-full'
                zoom={3}
                center={[35.86166, 104.195397]}
                attributionControl={false}
                scrollWheelZoom={false}
                boundsOptions={{ padding: [50, 50] }}
                id='map'
                minZoom={2}
            >
                <TileLayer
                    attribution='&amp;copy <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors'
                    url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
                />

                {polylines.map((x, index) => {
                    return (
                        <FeatureGroup>
                            <Polyline
                                key={index}
                                positions={[
                                    [x.toLatitude, x.toLongitude],
                                    [x.fromLatitude, x.fromLongitude],
                                ]}
                                color={lineColours[x.tier]}
                            />
                        </FeatureGroup>
                    );
                })}

                {geolocations.map((x, index) => {
                    return (
                        <Marker
                            key={index}
                            icon={customMarkerUserPos}
                            position={[x.latitude, x.longitude]}
                        >
                            <Popup>
                                <span>
                                    <span>{x.name}</span>
                                </span>
                            </Popup>
                        </Marker>
                    );
                })}

                <Control position='topright'>
                    <div className='flex flex-col'>
                        <TooltipProvider>
                            <Tooltip>
                                <TooltipTrigger>
                                    <div className='flex h-8 w-8 cursor-pointer items-center justify-center border bg-white shadow-lg'>
                                        <HelpCircle
                                            className='h-4 w-4 font-extrabold'
                                            onClick={() => {
                                                setLegendOpen((prev) => !prev);
                                                setControlsOpen(false);
                                            }}
                                        />
                                    </div>
                                </TooltipTrigger>

                                <TooltipContent className='bg-gray-700'>
                                    Legend
                                </TooltipContent>
                            </Tooltip>
                        </TooltipProvider>

                        <TooltipProvider>
                            <Tooltip>
                                <TooltipTrigger>
                                    <div className='flex h-8 w-8 cursor-pointer items-center justify-center border bg-white shadow-lg'>
                                        <AppWindow
                                            className='h-4 w-4 font-extrabold'
                                            onClick={() => {
                                                setControlsOpen(
                                                    (prev) => !prev
                                                );
                                                setLegendOpen(false);
                                            }}
                                        />
                                    </div>
                                </TooltipTrigger>

                                <TooltipContent className='bg-gray-700'>
                                    Controls
                                </TooltipContent>
                            </Tooltip>
                        </TooltipProvider>
                    </div>
                </Control>
            </MapContainer>
        </div>
    );
};
