import { useMutation, useQueryClient } from '@tanstack/react-query';
import { toast } from 'sonner';
import {
    BASE_PURCHASE_QUERY_KEY,
    BASE_PURCHASE_TRACE_QUERY_KEY,
} from 'src/app/_api_adb2c/purchase/purchase/hooks/purchase-query-keys';
import { useContextStore } from 'src/app/stores/context-store';
import { SalesOrderAttachDataObject } from '../request/sales-order-attach-data-object';
import {
    BASE_BATCH_SALES_ORDERS_QUERY_KEY,
    BASE_SALES_ORDERS_QUERY_KEY,
    SALES_ORDERS_QUERY_KEY,
    SALES_ORDER_QUERY_KEY,
} from './sales-order-query-keys';
import { useSalesOrderService } from './use-sales-order-service';

export function useAttachSalesOrderFiles() {
    const client = useQueryClient();
    const { service } = useSalesOrderService();
    const context = useContextStore();

    return useMutation({
        mutationFn: async ({
            delegateId,
            body,
            id,
            diversion = '0',
        }: {
            id: string;
            delegateId?: string;
            body: SalesOrderAttachDataObject;
            diversion?: string;
        }) => {
            return await service.attachFiles(
                delegateId || context.workspace?._id || '',
                id,
                diversion,
                body
            );
        },
        onSuccess: (_, { id, delegateId }) => {
            client.invalidateQueries(
                SALES_ORDER_QUERY_KEY(
                    delegateId || context.workspace?._id || '',
                    id
                )
            );

            client.invalidateQueries(
                SALES_ORDERS_QUERY_KEY(
                    delegateId || context.workspace?._id || ''
                )
            );

            client.invalidateQueries(BASE_PURCHASE_QUERY_KEY);

            client.invalidateQueries(BASE_PURCHASE_TRACE_QUERY_KEY);
            client.invalidateQueries([BASE_SALES_ORDERS_QUERY_KEY]);
            client.invalidateQueries([BASE_BATCH_SALES_ORDERS_QUERY_KEY]);

            toast.success('Updated sales order attachments');
        },
        onError: (err) => {
            const error = err as Error;

            toast.error(`Failed to attach files: ${error.message}`);
        },
    });
}
