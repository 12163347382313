import { useSearchParams } from 'react-router-dom';
import { useSuppliers } from 'src/app/_api_adb2c/purchase/suppliers/hooks/use-suppliers';
import { schema } from './add-substance-v3';
import { z } from 'zod';
import { useFormContext } from 'react-hook-form';
import { DetailLabel } from 'src/app/components-v2/detail-label';
import { useMemo } from 'react';

export function AddSubstanceConfirmation() {
    const [searchParams] = useSearchParams();
    const delegateId = searchParams.get('delegateId') || '';

    const { data: suppliers } = useSuppliers(delegateId);
    const { getValues } = useFormContext<z.infer<typeof schema>>();

    const formData = getValues();
    const substanceType = getValues('substanceType');

    const substance = useMemo(() => {
        if (substanceType && substanceType.length > 0) {
            const parentType = substanceType[0].value;
            if (parentType === 'manual') return;

            const selectedType = substanceType[substanceType.length - 1];

            return selectedType;
        }
    }, [substanceType]);

    const supplier = useMemo(() => {
        return suppliers?.find((x) => x.seller._id === formData.supplier);
    }, [formData.supplier, suppliers]);

    return (
        <div className='grid h-full grid-cols-1 grid-rows-[auto_1fr] justify-between gap-8'>
            <div className='grid grid-cols-1 gap-8'>
                <div className='grid grid-cols-4 gap-2'>
                    <DetailLabel title='Substance'>
                        <span className='capitalize'>
                            {substance?.label || formData.name || '--'}
                        </span>
                    </DetailLabel>
                    <DetailLabel title='CAS Number'>{formData.cas}</DetailLabel>
                    <DetailLabel title='% of Comp. Weight'>
                        {formData.percentage}
                    </DetailLabel>
                    <DetailLabel title='Projected Weight (KG)'>
                        {formData.projectedWeight}
                    </DetailLabel>
                </div>

                <div className='grid grid-cols-4 gap-2'>
                    <DetailLabel title='Supplier'>
                        {supplier?.seller.name}
                    </DetailLabel>
                    <DetailLabel title='Country Of Origin'>
                        {supplier?.seller.country}
                    </DetailLabel>
                    <DetailLabel title='Reach Registartion No.'>
                        {formData.reachRegistrationNumber}
                    </DetailLabel>
                </div>

                <div className='grid grid-cols-4 gap-2'>
                    <DetailLabel title='SDS Documentation'>
                        {formData.files?.name}
                    </DetailLabel>
                </div>
            </div>

            {/* <div>
                {(formData.mixedSubstances || []).length > 0 && (
                    <VirtualTable
                        columns={columns}
                        data={formData.mixedSubstances || []}
                    />
                )}
            </div> */}
        </div>
    );
}
