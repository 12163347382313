import { Edit, Import, Trash } from 'lucide-react';
import { DateTime } from 'luxon';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { PurchaseOrderNatureLabels } from 'src/app/_api_adb2c/purchase/purchase/enums/purchase-order-nature.enum';
import {
    PurchaseOrderStatus,
    PurchaseOrderStatusLabels,
} from 'src/app/_api_adb2c/purchase/purchase/enums/purchase-order-status.enum';
import { usePurchase } from 'src/app/_api_adb2c/purchase/purchase/hooks/use-purchase';
import { usePurchaseTrace } from 'src/app/_api_adb2c/purchase/purchase/hooks/use-purchase-trace';
import {
    PurchaseModel,
    PurchaseOrderTraceModel,
} from 'src/app/_api_adb2c/purchase/purchase/models/purchase.model';
import { useSalesOrderService } from 'src/app/_api_adb2c/sales/sales/hooks/use-sales-order-service';
import { SalesOrderModel } from 'src/app/_api_adb2c/sales/sales/models/sales-order.model';
import { useBatchRulesets } from 'src/app/_api_adb2c/workspace/ruleset/hooks/use-batch-rulesets';
import { useRulesetService } from 'src/app/_api_adb2c/workspace/ruleset/hooks/use-ruleset-service';
import {
    SupplyChainNodeType,
    SupplyChainNodeTypeLabel,
} from 'src/app/_api_adb2c/workspace/shared/enum/supply-chain-node-type.enum';
import { DetailLabel } from 'src/app/components-v2/detail-label';
import { TooltipWrapper } from 'src/app/components/TooltipWrapper';
import { useBreadcrumbStore } from 'src/app/stores/breadcrumb-store';
import { useContextStore } from 'src/app/stores/context-store';
import { generateOrderTitle } from 'src/app/utils/generate-order-title';
import CascadeImage from 'src/assets/cascade.png';
import DocumentImage from 'src/assets/document.png';
import ProductImage from 'src/assets/products.png';
import SubstanceImage from 'src/assets/substance.png';
import TrackingImage from 'src/assets/tracking.png';
import { Progress } from 'src/components/ui/progress';
import {
    Tabs,
    TabsContent,
    TabsList,
    TabsTrigger,
} from 'src/components/ui/tabs';
import { cn } from 'src/lib/utils';
import { PurchaseTracking } from '../Details/Tracking/purchase-tracking';
import { PurchaseCascade } from './Cascade';
import { DeletePurchase } from './delete-purchase';
import { PurchaseDocuments } from './Documents/purchase-documents';
import { EditPurchase } from './edit-purchase';
import { PurchaseManifest } from './Manifest';
import { PurchaseCascadeImport } from './purchase-cascade-import';
import { PurchaseSubstanceV2 } from './Substance/purchase-substance-v2';

enum MenuOptions {
    MANIFEST = 'manifest',
    DOCUMENTS = 'documents',
    CASCADE = 'cascade',
    TRACKING = 'tracking',
    SUBSTANCE = 'substance',
}

export function PurchaseDetailV2() {
    const { id } = useParams<{ id: string }>();
    const [searchParams, setSearchParams] = useSearchParams();
    const [percentage, setPercentage] = useState(0);
    const [maxHeight, setMaxHeight] = useState(0);
    const tabRef = useRef<HTMLDivElement>(null);
    const [action, setAction] = useState<'edit' | 'delete' | 'import'>();

    const delegateId = searchParams.get('delegateId') || '';
    const context = useContextStore();
    const { setBreadcrumbs } = useBreadcrumbStore();

    const { data: purchase } = usePurchase(id || '', delegateId);
    const { data: traces } = usePurchaseTrace(id || '', delegateId || '');

    const rulesetId = useMemo(() => {
        if (!purchase) return '';

        return purchase.rules?.[0];
    }, [purchase]);

    const { data: rulesets } = useBatchRulesets(rulesetId ? [rulesetId] : []);

    const ruleset = useMemo(() => {
        if (!rulesets) return undefined;

        return rulesets.find((x) => x._id === rulesetId);
    }, [rulesetId, rulesets]);

    const { service: rulesetService } = useRulesetService();
    const { service: salesService } = useSalesOrderService();

    const isDeletable = useMemo(() => {
        if (!purchase) return false;

        if (
            purchase.relatedPurchaseOrders &&
            purchase.relatedPurchaseOrders.length > 0
        )
            return false;

        if (
            purchase.status !== PurchaseOrderStatus.DRAFT &&
            purchase.status !== PurchaseOrderStatus.RELEASED
        )
            return false;

        return true;
    }, [purchase]);

    const orderInformation = useMemo(() => {
        const version = purchase?.versions?.[purchase.versions.length - 1];

        if (!version)
            return {
                totalCost: 0,
                totalItems: 0,
            };

        const totalCost = version.manifest.reduce(
            (acc, item) => acc + item.ppu * item.quantity,
            0
        );

        const totalItems = version.manifest.reduce(
            (acc, item) => acc + item.quantity,
            0
        );

        return {
            totalCost: totalCost,
            totalItems: totalItems,
        };
    }, [purchase]);

    const tierInformation = useMemo(() => {
        if (!traces) return 1;

        // Fetch the deepest depth of traces, adding 2 to account for the first tier and how depth is returned
        return Math.max(1, ...traces.map((x) => x.depth + 2));
    }, [traces]);

    const calculateDocReadiness = useCallback(async () => {
        if (!purchase) return;

        // Get the ruleset for the purchase order
        const rulesets = await rulesetService.batch({
            targets: purchase.rules,
        });

        // Find the ruleset for the purchase order
        const ruleset = rulesets?.find((x) => x._id === purchase.rules?.[0]);

        if (!ruleset) return;

        // Get the sales orders for the purchase orders
        const salesOrders: SalesOrderModel[] = await salesService.batch(
            delegateId || context.workspace?._id || '',
            { targets: [purchase._id, ...(traces || []).map((x) => x._id)] },
            '1'
        );

        const consolidatedPurchases: {
            purchase?: PurchaseModel | PurchaseOrderTraceModel;
            order?: SalesOrderModel;
            _id: string;
        }[] = [];

        // Consolidating steps
        const parentSalesOrder = salesOrders?.find(
            (x) => x.purchaseOrderId === purchase._id
        );
        consolidatedPurchases.push({
            purchase: purchase,
            order: parentSalesOrder,
            _id: purchase._id,
        });

        traces?.forEach((trace) => {
            const salesOrder = salesOrders?.find(
                (x) => x.purchaseOrderId === trace._id
            );
            consolidatedPurchases.push({
                purchase: trace,
                order: salesOrder,
                _id: trace._id,
            });
        });

        let totalDocuments = 0;
        let uploadedDocuments = 0;

        consolidatedPurchases.forEach((item) => {
            const operations = item.purchase?.processes || [];

            const requiredTypes = ruleset.rules.filter((rule) => {
                const required = operations.filter((operation) => {
                    return rule.processes.includes(operation);
                });

                return required.length > 0;
            });

            totalDocuments += requiredTypes.length;

            requiredTypes.forEach((rule) => {
                const file = item.order?.documents?.some((doc) => {
                    return doc.type === rule.document;
                });

                if (file) {
                    uploadedDocuments += 1;
                }
            });
        });

        if (!totalDocuments && !uploadedDocuments) {
            setPercentage(0);
            return;
        }

        const percentage = Math.round(
            (uploadedDocuments / totalDocuments) * 100
        );

        setPercentage(percentage);
    }, [purchase, rulesetService, traces, salesService, context, delegateId]);

    useEffect(() => {
        calculateDocReadiness();
    }, [calculateDocReadiness]);

    useEffect(() => {
        const updateTableHeight = () => {
            if (tabRef.current) {
                const parentElement = tabRef.current.parentElement;
                const viewportHeight = window.innerHeight;
                const parentTopOffset =
                    parentElement?.getBoundingClientRect().top || 0;
                const availableHeight = viewportHeight - parentTopOffset - 70;

                if (availableHeight !== maxHeight) {
                    setMaxHeight(availableHeight);
                }
            }
        };

        updateTableHeight();

        const resizeObserver = new ResizeObserver(updateTableHeight);
        if (tabRef.current && tabRef.current.parentElement) {
            resizeObserver.observe(tabRef.current.parentElement);
        }

        return () => {
            resizeObserver.disconnect();
        };
    }, [maxHeight]);

    const menuOptions = [
        {
            title: MenuOptions.MANIFEST,
            icon: ProductImage,
            alt: 'Manifest',
            content: <PurchaseManifest purchaseId={id || ''} />,
        },
        {
            title: MenuOptions.DOCUMENTS,
            icon: DocumentImage,
            alt: 'Documents',
            content: <PurchaseDocuments purchaseId={id || ''} />,
        },
        {
            title: MenuOptions.CASCADE,
            icon: CascadeImage,
            alt: 'Cascade',
            content: <PurchaseCascade purchaseId={id || ''} />,
        },
        {
            title: MenuOptions.TRACKING,
            icon: TrackingImage,
            alt: 'Tracking',
            // content: <PurchaseTracking purchaseId={id || ''} />,
            content: (
                <>
                    {purchase && traces && (
                        <PurchaseTracking data={purchase} traces={traces} />
                    )}
                </>
            ),
        },
        {
            title: MenuOptions.SUBSTANCE,
            icon: SubstanceImage,
            alt: 'Substance',
            content: <PurchaseSubstanceV2 purchaseId={id || ''} />,
        },
    ];

    useEffect(() => {
        if (!purchase) return;

        setBreadcrumbs([
            { label: 'Purchases', navigateTo: '/purchases' },
            { label: generateOrderTitle(purchase) },
        ]);
    }, [purchase, setBreadcrumbs]);

    const switchMenu = (value: string) => {
        setSearchParams((prev) => {
            prev.set('menu', value);
            return prev;
        });
    };

    useEffect(() => {
        if (!searchParams.get('menu')) {
            setSearchParams((prev) => {
                prev.set('menu', MenuOptions.MANIFEST);
                return prev;
            });
        }
    }, [searchParams, setSearchParams]);

    if (!id) return <></>;

    return (
        <>
            <div className='flex h-full flex-col gap-8'>
                <div className='grid grid-cols-12 gap-4'>
                    <div className='col-span-12'>
                        <span className='text-md font-bold'>
                            {purchase
                                ? `${generateOrderTitle(purchase)} (${
                                      PurchaseOrderStatusLabels[
                                          purchase?.status as PurchaseOrderStatus
                                      ]
                                  })`
                                : '--'}
                        </span>
                    </div>

                    <div className='col-span-8 grid grid-cols-1 gap-4 rounded-lg border border-gray-200 bg-white px-4 py-4'>
                        <div className='flex w-full items-center justify-between'>
                            <span className='text-sm font-bold underline decoration-red-800 decoration-2 underline-offset-8'>
                                General Information
                            </span>

                            <div className='flex gap-4'>
                                <TooltipWrapper label='Import Cascade'>
                                    <Import
                                        size={16}
                                        className='cursor-pointer hover:scale-125'
                                        onClick={() => setAction('import')}
                                    />
                                </TooltipWrapper>

                                <TooltipWrapper label='Edit Purchase'>
                                    <Edit
                                        size={16}
                                        className='cursor-pointer hover:scale-125'
                                        onClick={() => setAction('edit')}
                                    />
                                </TooltipWrapper>

                                <TooltipWrapper label='Delete Purchase'>
                                    <Trash
                                        size={16}
                                        className={cn(
                                            'cursor-pointer hover:scale-125',
                                            !isDeletable &&
                                                'cursor-not-allowed hover:scale-125'
                                        )}
                                        onClick={() => {
                                            if (!isDeletable) return;
                                            setAction('delete');
                                        }}
                                    />
                                </TooltipWrapper>
                            </div>
                        </div>

                        <div className='grid grid-cols-3 gap-4'>
                            <DetailLabel title='Supplier'>
                                {purchase?.supplier.seller.name}
                            </DetailLabel>

                            <DetailLabel title='Issued On'>
                                {purchase?.createdOn
                                    ? DateTime.fromJSDate(
                                          new Date(purchase.createdOn)
                                      ).toFormat('yyyy-MM-dd')
                                    : '--'}
                            </DetailLabel>

                            <DetailLabel title='Ruleset Applied'>
                                {ruleset?.name || '--'}
                            </DetailLabel>

                            <DetailLabel title='Type of Order'>
                                {purchase?.nature
                                    ? PurchaseOrderNatureLabels[purchase.nature]
                                    : '--'}
                            </DetailLabel>

                            <DetailLabel title='1st Delivery (Expected)'>
                                {purchase?.shippedOn &&
                                purchase.shippedOn.length > 0
                                    ? DateTime.fromJSDate(
                                          new Date(purchase.shippedOn[0])
                                      ).toFormat('yyyy-MM-dd')
                                    : '--'}
                            </DetailLabel>

                            <DetailLabel title='Address'>
                                {purchase?.shipToAddress || '--'}
                            </DetailLabel>

                            <DetailLabel title='Currency'>
                                {purchase?.currency}
                            </DetailLabel>

                            <DetailLabel title='Related Processes'>
                                <div className='flex max-h-[48px] flex-wrap gap-2 overflow-auto'>
                                    {purchase?.processes.map((process) => (
                                        <span className='inline-flex items-center rounded-md bg-gray-100 px-1.5 py-0.5 text-xs font-medium text-gray-600'>
                                            {
                                                SupplyChainNodeTypeLabel[
                                                    process as SupplyChainNodeType
                                                ]
                                            }
                                        </span>
                                    ))}
                                </div>
                            </DetailLabel>

                            <DetailLabel title='Shipment Dates'>
                                {purchase?.shippedOn &&
                                purchase.shippedOn.length > 0
                                    ? DateTime.fromJSDate(
                                          new Date(purchase.shippedOn[0])
                                      ).toFormat('yyyy-MM-dd')
                                    : '--'}
                            </DetailLabel>
                        </div>
                    </div>

                    <div className='col-span-4 grid grid-cols-2 gap-4'>
                        <div className='flex w-full flex-col justify-between rounded-lg border border-gray-200 bg-white px-4 py-2'>
                            <span className='text-xs'>Order Total</span>

                            <span className='text-end text-xl font-bold'>
                                {orderInformation.totalCost}
                            </span>
                            <span className='text-end text-xs font-extralight'>
                                USD
                            </span>
                        </div>

                        <div className='flex w-full flex-col justify-between rounded-lg border border-gray-200 bg-white px-4 py-2'>
                            <span className='text-xs'>Order Quantities</span>

                            <span className='text-end text-xl font-bold'>
                                {orderInformation.totalItems}
                            </span>
                            <span className='text-end text-xs font-extralight'>
                                PCS
                            </span>
                        </div>

                        <div className='flex w-full flex-col justify-between rounded-lg border border-gray-200 bg-white px-4 py-2'>
                            <span className='text-xs'>Cascade Levels</span>

                            <span className='text-end text-xl font-bold'>
                                {tierInformation}
                            </span>
                            <span className='text-end text-xs font-extralight uppercase'>
                                Tiers
                            </span>
                        </div>

                        <div className='flex w-full flex-col justify-between rounded-lg border border-gray-200 bg-white px-4 py-2'>
                            <span className='text-xs'>Doc Readiness</span>

                            <span className='text-end text-xl font-bold'>
                                {percentage}%
                                <Progress value={percentage} />
                            </span>
                            <span className='text-end text-xs font-extralight uppercase'>
                                Completed
                            </span>
                        </div>
                    </div>
                </div>

                <div className='grid h-full flex-1 grid-cols-1'>
                    <Tabs
                        value={searchParams.get('menu') || MenuOptions.MANIFEST}
                        className='flex w-full flex-col gap-2'
                        onValueChange={switchMenu}
                        ref={tabRef}
                    >
                        <TabsList className='w-full justify-start'>
                            {menuOptions.map((x, index) => {
                                return (
                                    <TabsTrigger
                                        value={x.title}
                                        className='w-24'
                                    >
                                        <figure className='flex cursor-pointer flex-col items-center rounded capitalize'>
                                            <img
                                                src={x.icon}
                                                alt={x.alt}
                                                className='max-w-6 max-h-6 rounded-l p-1'
                                                key={index}
                                                id={`${
                                                    x.alt
                                                }-${index.toString()}`}
                                            />
                                            <figcaption className='text-sm text-gray-500 dark:text-gray-400'>
                                                {x.title}
                                            </figcaption>
                                        </figure>
                                    </TabsTrigger>
                                );
                            })}
                        </TabsList>

                        {menuOptions.map((x) => {
                            return (
                                <TabsContent
                                    key={`menu-${x.title}`}
                                    value={x.title}
                                    className='flex-1 overflow-auto rounded-lg'
                                    style={{
                                        maxHeight:
                                            maxHeight > 280
                                                ? `${maxHeight}px`
                                                : 'auto',
                                    }}
                                >
                                    {x.content}
                                </TabsContent>
                            );
                        })}
                    </Tabs>
                </div>
            </div>

            {action === 'edit' && (
                <EditPurchase
                    purchaseId={id || ''}
                    open={action === 'edit'}
                    onClose={() => setAction(undefined)}
                />
            )}

            {action === 'delete' && (
                <DeletePurchase
                    purchaseId={id || ''}
                    open={action === 'delete'}
                    onClose={() => setAction(undefined)}
                />
            )}

            {action === 'import' && (
                <PurchaseCascadeImport
                    open={action === 'import'}
                    onClose={() => setAction(undefined)}
                />
            )}
        </>
    );
}
