import { CaretSortIcon } from '@radix-ui/react-icons';
import { ColumnDef } from '@tanstack/react-table';
import { Edit, Plus, Trash } from 'lucide-react';
import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useRulesets } from 'src/app/_api_adb2c/workspace/ruleset/hooks/use-rulesets';
import { WorkspaceRulesetModel } from 'src/app/_api_adb2c/workspace/ruleset/ruleset.model';
import { Button } from 'src/components/ui/button';
import { Checkbox } from 'src/components/ui/checkbox';
import { ReusableTable } from '../../Purchases/reusable-table';
import { CreateRuleset } from './create-ruleset';
import { DeleteRuleset } from './delete-ruleset';
import { EditRuleset } from './edit-ruleset';

export const WorkspaceRulesets = () => {
    const [searchParams] = useSearchParams();
    const delegateId = searchParams.get('delegateId') || '';
    const { data } = useRulesets(delegateId);

    const [rowSelection, setRowSelection] = useState({});
    const [rule, setRule] = useState<WorkspaceRulesetModel>();
    const [isCreate, setIsCreate] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [isDelete, setIsDelete] = useState(false);

    const columns: ColumnDef<WorkspaceRulesetModel>[] = [
        {
            id: 'select',
            header: ({ table }) => (
                <Checkbox
                    checked={table.getIsAllPageRowsSelected()}
                    onCheckedChange={(value) =>
                        table.toggleAllPageRowsSelected(!!value)
                    }
                    aria-label='Select all'
                />
            ),
            cell: ({ row }) => (
                <Checkbox
                    checked={row.getIsSelected()}
                    onCheckedChange={(value) => row.toggleSelected(!!value)}
                    aria-label='Select row'
                />
            ),
            enableSorting: false,
            enableHiding: false,
            size: 0,
        },
        {
            id: 'name',
            accessorKey: 'name',
            header: ({ column }) => {
                return (
                    <Button
                        variant='ghost'
                        className='text-xs'
                        onClick={() =>
                            column.toggleSorting(column.getIsSorted() === 'asc')
                        }
                    >
                        Ruleset
                        <CaretSortIcon
                            className={`inline ${
                                column.getIsSorted() === 'asc'
                                    ? 'rotate-180 transform'
                                    : ''
                            }`}
                        />
                    </Button>
                );
            },
            cell: ({ row }) => {
                return row.original.name || row.original._id;
            },
        },
        {
            id: 'description',
            accessorKey: 'description',
            header: 'Description',
            cell: ({ row }) => {
                return row.original.description;
            },
        },
        {
            id: 'documents',
            accessorKey: 'documents',
            header: 'Documents',
            cell: ({ row }) => {
                return row.original.rules?.length || 0;
            },
        },
        {
            id: 'actions',
            header: 'Actions',
            cell: ({ row }) => (
                <div className='flex'>
                    <Button
                        variant={'ghost'}
                        onClick={() => {
                            setIsEdit(true);
                            setRule(row.original);
                        }}
                    >
                        <Edit size={16} />
                    </Button>

                    <Button
                        variant={'ghost'}
                        onClick={() => {
                            setIsDelete(true);
                            setRule(row.original);
                        }}
                    >
                        <Trash size={16} />
                    </Button>
                </div>
            ),
        },
    ];

    return (
        <div className='flex h-full flex-col'>
            <ReusableTable
                columns={columns}
                data={data || []}
                title='Rulesets'
                controls={{
                    enableDelegation: true,
                    enableSearch: true,
                }}
                rowSelection={rowSelection}
                setRowSelection={setRowSelection}
                actions={[
                    {
                        id: 'create',
                        icon: <Plus className='h-4 w-4' />,
                        onClick: () => setIsCreate(true),
                    },
                ]}
            />

            {isCreate && (
                <CreateRuleset
                    onClose={() => setIsCreate(false)}
                    open={isCreate}
                />
            )}

            {isEdit && rule && (
                <EditRuleset
                    onClose={() => setIsEdit(false)}
                    open={isEdit}
                    ruleset={rule}
                />
            )}

            {isDelete && rule && (
                <DeleteRuleset
                    ruleset={rule}
                    open={isDelete}
                    onClose={() => setIsDelete(false)}
                />
            )}
        </div>
    );
};
