import { ServiceConfigInterface } from 'src/domain/interfaces/config.context.interface';
import { ApiServer } from 'src/infrastructure/clients/api.server';
import { SupplierUpdateDataObject } from './requests/supplier-update-data-object';

export class SupplierApiClient extends ApiServer {
    constructor(config: ServiceConfigInterface, token: string) {
        super(token, config, 'purchases', 'supplier-v2');
    }

    get(workspace: string, item: string, diversion: string = '0') {
        console.log(`client<supplier>| get(): Enter`);
        console.log(`client<supplier>| get(): $workspace = ${workspace}`);
        console.log(`client<supplier>| get(): $item = ${item}`);
        console.log(`client<supplier>| get(): $diversion = ${diversion}`);
        return super.get('get', workspace, item, diversion);
    }

    list(workspace: string, diversion: string = '0') {
        console.log(`client<supplier>| list(): Enter`);
        console.log(`client<supplier>| list(): $workspace = ${workspace}`);
        console.log(`client<supplier>| list(): $diversion = ${diversion}`);
        return super.get('list', workspace, diversion);
    }

    remove(workspace: string, item: string, diversion: string = '0') {
        console.log(`client<supplier>| remove(): Enter`);
        console.log(`client<supplier>| remove(): $workspace = ${workspace}`);
        console.log(`client<supplier>| remove(): $item = ${item}`);
        console.log(`client<supplier>| remove(): $diversion = ${diversion}`);

        return super.delete('remove', workspace, diversion, item);
    }

    update(
        workspace: string,
        item: string,
        diversion: string = '0',
        supplier: SupplierUpdateDataObject
    ) {
        console.log(`client<supplier>| update(): Enter`);
        console.log(`client<supplier>| update(): $workspace = ${workspace}`);
        console.log(`client<supplier>| update(): $item = ${item}`);
        console.log(`client<supplier>| update(): $diversion = ${diversion}`);
        console.log(
            `client<supplier>| update(): $supplier = ${JSON.stringify(
                supplier
            )}`
        );

        return super.patch('update', workspace, item, diversion, supplier);
    }
}
