import { Check, ChevronsUpDown } from 'lucide-react';
import * as React from 'react';
import { cn } from 'src/app/utils/cn';
import { Badge } from 'src/components/ui/badge';
import { Button } from 'src/components/ui/button';
import {
    Command,
    CommandEmpty,
    CommandGroup,
    CommandItem,
} from 'src/components/ui/command';
import {
    Popover,
    PopoverContent,
    PopoverTrigger,
} from 'src/components/ui/popover';

export type OptionType = Record<'value' | 'label', string>;

interface MultiSelectProps {
    options: Record<'value' | 'label', string>[];
    selected: Record<'value' | 'label', string>[];
    onChange: React.Dispatch<
        React.SetStateAction<Record<'value' | 'label', string>[]>
    >;
    className?: string;
    placeholder?: string;
}

const MultiSelect = React.forwardRef<HTMLButtonElement, MultiSelectProps>(
    ({ options, selected, onChange, className, ...props }, ref) => {
        const [open, setOpen] = React.useState(false);

        const handleUnselect = (item: Record<'value' | 'label', string>) => {
            onChange(selected.filter((i) => i.value !== item.value));
        };

        return (
            <Popover open={open} onOpenChange={setOpen}>
                <PopoverTrigger asChild className={className}>
                    <Button
                        ref={ref}
                        variant='outline'
                        role='combobox'
                        aria-expanded={open}
                        className={`my-0 h-full w-full justify-between py-0`}
                        onClick={() => setOpen(!open)}
                    >
                        <div className='flex flex-wrap gap-1 overflow-auto'>
                            {selected.map((x) => {
                                return (
                                    <Badge
                                        variant='outline'
                                        className='min-w-[80px] max-w-[80px] justify-center truncate bg-gray-300'
                                        onClick={(e) => {
                                            e.preventDefault();
                                            e.stopPropagation();
                                            handleUnselect(x);
                                        }}
                                        onMouseDown={(e) => {
                                            e.preventDefault();
                                            e.stopPropagation();
                                        }}
                                    >
                                        <span className='truncate'>
                                            {x.label}
                                        </span>
                                    </Badge>
                                );
                            })}
                        </div>
                        <ChevronsUpDown className='h-4 w-4 shrink-0 opacity-50' />
                    </Button>
                </PopoverTrigger>

                <PopoverContent className='max-h-[200px] w-full p-0 shadow'>
                    <Command className={`${className} w-full`}>
                        {/* <CommandInput className='' placeholder='Search ...' /> */}
                        <CommandEmpty>No item found.</CommandEmpty>
                        <CommandGroup className='max-h-64 overflow-auto'>
                            {options.map((option) => (
                                <CommandItem
                                    key={option.value}
                                    onSelect={() => {
                                        onChange(
                                            selected.some(
                                                (item) =>
                                                    item.value === option.value
                                            )
                                                ? selected.filter(
                                                      (item) =>
                                                          item.value !==
                                                          option.value
                                                  )
                                                : [...selected, option]
                                        );
                                        setOpen(true);
                                    }}
                                >
                                    <Check
                                        className={cn(
                                            'mr-2 h-4 w-4',
                                            selected.some(
                                                (item) =>
                                                    item.value === option.value
                                            )
                                                ? 'opacity-100'
                                                : 'opacity-0'
                                        )}
                                    />
                                    {option.label}
                                </CommandItem>
                            ))}
                        </CommandGroup>
                    </Command>
                </PopoverContent>
            </Popover>
        );
    }
);

MultiSelect.displayName = 'MultiSelect';

export { MultiSelect };
