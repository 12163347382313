import { ServiceConfigInterface } from 'src/domain/interfaces/config.context.interface';
import { ProductModel } from './models/product.model';
import { ProductApiClient } from './product.client';
import { ProductCreateDataObject } from './requests/product-create-data-object';
import { ProductUpdateDataObject } from './requests/product-update-data-object';
import { ProductCloneDataObject } from './requests/product-clone-data-object';

export class ProductService {
    private server: ProductApiClient;

    constructor(config: ServiceConfigInterface, token: string) {
        this.server = new ProductApiClient(config, token);
    }

    create(workspace: string, body: ProductCreateDataObject, diversion = '0') {
        console.log(`service<product>| create(): Enter`);
        console.log(`service<product>| create(): $workspace = ${workspace}`);
        console.log(
            `service<product>| create(): $body = ${JSON.stringify(body)}`
        );
        console.log(`service<product>| create(): $diversion = ${diversion}`);

        return this.server.create(workspace, body, diversion);
    }

    list(workspace: string) {
        console.log(`service<product>| list(): Enter`);
        console.log(`service<product>| list(): $workspace = ${workspace}`);

        return this.server.list(workspace);
    }

    get(workspace: string, item: string, diversion = '0') {
        console.log(`service<product>| get(): Enter`);
        console.log(`service<product>| get(): $workspace = ${workspace}`);
        console.log(`service<product>| get(): $item = ${item}`);
        console.log(`service<product>| get(): $diversion = ${diversion}`);
        return this.server.get(workspace, item, diversion);
    }

    getByVersion(workspace: string, item: string, version: string) {
        console.log(`service<product>| getByVersion(): Enter`);
        console.log(
            `service<product>| getByVersion(): $workspace = ${workspace}`
        );
        console.log(`service<product>| getByVersion(): $item = ${item}`);
        console.log(`service<product>| getByVersion(): $version = ${version}`);

        return this.server.getByVersion(workspace, item, version);
    }

    update(workspace: string, item: string, body: ProductUpdateDataObject) {
        console.log(`service<product>| update(): Enter`);
        console.log(`service<product>| update(): $workspace = ${workspace}`);
        console.log(`service<product>| update(): $item = ${item}`);
        console.log(
            `service<product>| update(): $body = ${JSON.stringify(body)}`
        );

        return this.server.update(workspace, item, body);
    }

    remove(workspace: string, item: string) {
        console.log(`service<product>| remove(): Enter`);
        console.log(`service<product>| remove(): $workspace = ${workspace}`);
        console.log(`service<product>| remove(): $item = ${item}`);

        return this.server.remove(workspace, item);
    }

    compose(
        workspace: string,
        item: string,
        material: ProductUpdateDataObject
    ) {
        console.log(`service<product>| compose(): Enter`);
        console.log(`service<product>| compose(): $workspace = ${workspace}`);
        console.log(`service<product>| compose(): $item = ${item}`);
        console.log(
            `service<product>| compose(): $material = ${JSON.stringify(
                material
            )}`
        );

        return this.server.compose(workspace, item, material);
    }

    getLatestVersion(workspace: string, item: string) {
        console.log(`service<product>| getLatestVersion(): Enter`);
        console.log(
            `service<product>| getLatestVersion(): $workspace = ${workspace}`
        );
        console.log(`service<product>| getLatestVersion(): $item = ${item}`);

        return this.server.getLatestVersion(workspace, item);
    }

    async search(
        workspace: string,
        name: string,
        diversion = '0'
    ): Promise<ProductModel> {
        console.log(`service<product>| search(): Enter`);
        console.log(`service<product>| search(): $workspace = ${workspace}`);
        console.log(`service<product>| search(): $name = ${name}`);
        console.log(`service<product>| search(): $diversion = ${diversion}`);
        return await this.server.search(workspace, name, diversion);
    }

    clone(workspace: string, item: string, body: ProductCloneDataObject) {
        console.log(`service<product>| clone(): Enter`);
        console.log(`service<product>| clone(): $workspace = ${workspace}`);
        console.log(`service<product>| clone(): $item = ${item}`);
        console.log(
            `service<product>| clone(): $body = ${JSON.stringify(body)}`
        );

        return this.server.clone(workspace, item, body);
    }

    searchByName(workspace: string, name: string, diversion = '0') {
        console.log(`service<product>| searchByName(): Enter`);
        console.log(
            `service<product>| searchByName(): $workspace = ${workspace}`
        );
        console.log(`service<product>| searchByName(): $name = ${name}`);
        console.log(
            `service<product>| searchByName(): $diversion = ${diversion}`
        );

        return this.server.searchByName(workspace, name, diversion);
    }
}
