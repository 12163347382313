import { useMutation, useQueryClient } from '@tanstack/react-query';
import { toast } from 'sonner';
import { useContextStore } from 'src/app/stores/context-store';
import { SalesOrderShippedRequestObject } from '../request/sales-order-shipped-request-object';
import { SALES_ORDERS_QUERY_KEY } from './sales-order-query-keys';
import { useSalesOrderService } from './use-sales-order-service';

export function useSalesOrderShipped() {
    const { service } = useSalesOrderService();
    const client = useQueryClient();
    const context = useContextStore();

    return useMutation({
        mutationFn: async ({
            id,
            body,
            delegateId,
        }: {
            id: string;
            body: SalesOrderShippedRequestObject;
            delegateId?: string;
        }) => {
            return await service.shipped(
                delegateId || context.workspace?._id || '',
                id,
                body
            );
        },
        onSuccess: (_, { id, delegateId }) => {
            client.invalidateQueries(
                SALES_ORDERS_QUERY_KEY(
                    delegateId || context.workspace?._id || ''
                )
            );

            toast.success(`Sales Order shipped successfully`);
        },
        onError: (err) => {
            const error = err as Error;

            toast.error(`Failed to ship sales order: ${error.message}`);
        },
    });
}
