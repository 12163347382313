import { useContextStore } from 'src/app/stores/context-store';
import { useProductService } from './use-product-service';
import { useQuery } from '@tanstack/react-query';
import { PRODUCTS_QUERY_KEY } from './product-query-keys';
import { toast } from 'sonner';
import { ProductModel } from '../models/product.model';
import { PaginatedResponse } from 'src/app/_api_adb2c/purchase/purchase-item/purchaseable.model';

export function useProducts(delegateId?: string) {
    const context = useContextStore();
    const { service } = useProductService();

    return useQuery<PaginatedResponse<ProductModel>>({
        queryKey: PRODUCTS_QUERY_KEY(
            delegateId || context.workspace?._id || ''
        ),
        queryFn: async () => {
            return await service.list(
                delegateId || context.workspace?._id || ''
            );
        },
        onError: (err) => {
            const error = err as Error;

            toast.error(`Failed to fetch products: ${error.message}`);
        },
    });
}
