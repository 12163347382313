import { ColumnDef } from '@tanstack/react-table';
import { PlusIcon, TrashIcon } from 'lucide-react';
import { DateTime } from 'luxon';
import { useMemo, useState } from 'react';
import { useWorkspaceCertificationTypes } from 'src/app/_api_adb2c/workspace/workspace/hooks/use-workspace-certification-types';
import { DialogV2 } from 'src/app/components-v2/dialog-v2';
import { TableV2 } from 'src/app/components-v2/table-v2';
import { DatePicker } from 'src/app/components/Inputs/DatePicker';
import { FileInput } from 'src/app/components/Inputs/FileInput';
import { Select } from 'src/app/components/Inputs/Select';
import { TextInput } from 'src/app/components/Inputs/TextInput';

interface Props {
    certificates: any[];
    append: (value: any) => void;
    remove: (index: number) => void;
}

interface Certificate {
    certificationType: string;
    certificateNumber: string;
    expiryDate: Date | undefined;
    weight: number | undefined;
    certificateFile: File[] | null;
}

export function AddMaterialCertificates({
    certificates,
    append,
    remove,
}: Props) {
    const { data: types } = useWorkspaceCertificationTypes();
    const [newCertificate, setNewCertificate] = useState<Certificate>({
        certificationType: '',
        certificateNumber: '',
        expiryDate: undefined,
        weight: undefined,
        certificateFile: null,
    });

    const [isModalOpen, setIsModalOpen] = useState(false);

    // Validation function to check if all required fields are filled
    const isFormValid = useMemo(() => {
        return (
            newCertificate.certificationType !== '' &&
            newCertificate.certificateNumber.trim() !== '' &&
            newCertificate.expiryDate !== undefined &&
            newCertificate.weight !== undefined &&
            newCertificate.weight > 0 &&
            newCertificate.certificateFile !== null &&
            newCertificate.certificateFile.length > 0
        );
    }, [newCertificate]);

    const openModal = () => {
        setIsModalOpen(true);
    };

    const handleClose = () => {
        // Reset form when closing
        setNewCertificate({
            certificationType: '',
            certificateNumber: '',
            expiryDate: undefined,
            weight: undefined,
            certificateFile: null,
        });
        setIsModalOpen(false);
    };

    const handleSubmit = async () => {
        if (!isFormValid) return;

        append(newCertificate);
        // Add your submit logic here
        handleClose();
    };

    const columns: ColumnDef<any>[] = [
        {
            id: 'type',
            header: 'Certification Type',
            accessorFn: (row) =>
                types?.find((t) => t.id === row.certificationType)?.name,
        },
        {
            id: 'certificateNumber',
            header: 'Certificate Number',
            accessorFn: (row) => row.certificateNumber,
        },
        {
            id: 'expiryDate',
            header: 'Expiry Date',
            accessorFn: (row) => {
                return DateTime.fromJSDate(row.expiryDate).toFormat(
                    'dd/MM/yyyy'
                );
            },
        },
        {
            id: 'weight',
            header: 'Weight (kg)',
            accessorFn: (row) => row.weight,
        },
        {
            id: 'actions',
            header: 'Actions',
            cell: ({ row }) => {
                return (
                    <div className='flex gap-2'>
                        <TrashIcon
                            className='h-4 w-4 cursor-pointer hover:scale-125'
                            onClick={() => remove(row.index)}
                        />
                    </div>
                );
            },
        },
    ];

    return (
        <div className='flex h-full flex-col gap-4'>
            <TableV2
                columns={columns}
                data={certificates}
                label='Certificates'
                tableContainerClassName='h-full flex-1'
                actions={[
                    {
                        label: 'Add Certificate',
                        onClick: () => {
                            openModal();
                        },
                        id: 'add-certificate',
                        icon: <PlusIcon className='h-4 w-4' />,
                    },
                ]}
            />

            {isModalOpen && (
                <DialogV2
                    open={isModalOpen}
                    onClose={handleClose}
                    title='Upload Document'
                    size='sm'
                    onFinish={handleSubmit}
                    singleStepContent={
                        <div className='flex flex-col gap-4'>
                            <Select
                                label='Certification Type *'
                                options={(types || [])
                                    .sort((a, b) =>
                                        a.name.localeCompare(b.name)
                                    )
                                    .map((type) => ({
                                        label: type.name,
                                        value: type.id,
                                    }))}
                                value={newCertificate.certificationType}
                                onValueChange={(value) =>
                                    setNewCertificate({
                                        ...newCertificate,
                                        certificationType: value,
                                    })
                                }
                            />

                            <TextInput
                                label='Certificate Number *'
                                value={newCertificate.certificateNumber}
                                onValueChange={(value) =>
                                    setNewCertificate({
                                        ...newCertificate,
                                        certificateNumber: value,
                                    })
                                }
                            />

                            <DatePicker
                                label='Expiry Date *'
                                value={newCertificate.expiryDate}
                                onValueChange={(value) =>
                                    setNewCertificate({
                                        ...newCertificate,
                                        expiryDate: value,
                                    })
                                }
                            />

                            <TextInput
                                label='Weight *'
                                value={newCertificate.weight?.toString() || ''}
                                onValueChange={(value) =>
                                    setNewCertificate({
                                        ...newCertificate,
                                        weight: value
                                            ? parseFloat(value)
                                            : undefined,
                                    })
                                }
                                type='number'
                            />

                            <FileInput
                                label='Certificate File *'
                                placeholder='Select certificate file'
                                description='Accepted file types: .pdf, .doc, .docx'
                                onValueChange={(value) =>
                                    setNewCertificate({
                                        ...newCertificate,
                                        certificateFile: value,
                                    })
                                }
                                value={newCertificate.certificateFile}
                                accept='.pdf,.doc,.docx'
                            />
                        </div>
                    }
                />
            )}
        </div>
    );
}
