import { ColumnDef } from '@tanstack/react-table';
import { GanttChartSquare, Globe, Link, Plus, Trash } from 'lucide-react';
import { DateTime } from 'luxon';
import { useEffect, useMemo, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useDeleteSupplier } from 'src/app/_api_adb2c/purchase/suppliers/hooks/use-delete-supplier';
import { useSuppliers } from 'src/app/_api_adb2c/purchase/suppliers/hooks/use-suppliers';
import { SupplierModel } from 'src/app/_api_adb2c/purchase/suppliers/models/supplier.model';
import { useCreateDelegation } from 'src/app/_api_adb2c/workspace/delegations/hooks/use-create-delegation';
import { useDelegations } from 'src/app/_api_adb2c/workspace/delegations/hooks/use-delegations';
import { useDeleteDelegation } from 'src/app/_api_adb2c/workspace/delegations/hooks/use-delete-delegation';
import { TableV2 } from 'src/app/components-v2/table-v2';
import { NavigationLink } from 'src/app/components-v2/typography';
import { TooltipWrapper } from 'src/app/components/TooltipWrapper';
import { Checkbox } from 'src/components/ui/checkbox';
import { cn } from 'src/lib/utils';
import countries from '../../../infrastructure/config/data/countries.json';
import { AddSupplier } from './add-supplier';

export const SupplierList = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const delegateId = searchParams.get('delegateId') || '';
    const navigate = useNavigate();

    const { data, isLoading } = useSuppliers(delegateId);
    const { data: delegations } = useDelegations(delegateId);

    const memoizedData = useMemo(() => {
        if (!data) return [];

        console.log('dataaaa', data);

        return data;
    }, [data]);

    useEffect(() => {
        console.log('this is the data', data);
    }, [data]);
    const [isCreate, setIsCreate] = useState(false);

    const { mutateAsync: removeSupplier } = useDeleteSupplier();
    const { mutateAsync: removeDelegation } = useDeleteDelegation();
    const { mutateAsync: createDelegation } = useCreateDelegation();

    const remove = async (id: string, workspace: string) => {
        const existingDelegation = delegations?.find(
            (x) => x.delegatedTo._id === workspace
        );

        if (existingDelegation) {
            await removeDelegation({
                id: existingDelegation.delegatedTo._id,
            });
        }

        await removeSupplier({
            id: id,
        });
    };

    const assignDelegate = async (id: string) => {
        await createDelegation({
            body: {
                delegatedTo: id,
            },
        });
    };

    const isDelegate = (id: string) => {
        return delegations?.some((x) => x.delegatedTo._id === id);
    };

    const handleRedirectToDetails = (id: string) => {
        navigate(
            delegateId
                ? `/suppliers/${id}?delegateId=${delegateId}`
                : `/suppliers/${id}`
        );
    };

    const columns: ColumnDef<SupplierModel>[] = [
        {
            id: 'select',
            size: 100,
            header: ({ table }) => (
                <Checkbox
                    checked={table.getIsAllPageRowsSelected()}
                    onCheckedChange={(value) =>
                        table.toggleAllPageRowsSelected(!!value)
                    }
                    aria-label='Select all'
                />
            ),
            cell: ({ row }) => (
                <Checkbox
                    checked={row.getIsSelected()}
                    onCheckedChange={(value) => row.toggleSelected(!!value)}
                    aria-label='Select row'
                />
            ),
            enableSorting: false,
            enableHiding: false,
        },
        {
            id: 'name',
            accessorFn: (row) => row.seller.name,
            header: 'Name',
            cell: ({ row }) => {
                return (
                    <NavigationLink
                        path={`/suppliers/${row.original._id}`}
                        search={delegateId && `?delegateId=${delegateId}`}
                    >
                        {row.original.seller.name}
                    </NavigationLink>
                );
            },
        },
        {
            id: 'processes',
            header: 'Processes',
            accessorFn: () => '--',
        },
        {
            id: 'country',
            header: 'Country',
            accessorFn: (row) => {
                const country = countries.find(
                    (x) =>
                        x['alpha-2'] === row.seller.country ||
                        x.name?.toLocaleLowerCase() ===
                            row.seller?.country?.toLocaleLowerCase()
                );

                return country?.name || '--';
            },
        },
        {
            id: 'engagedOn',
            header: 'Engaged On',
            accessorFn: (row) => {
                const date = new Date(row.createdOn);

                return DateTime.fromJSDate(date).toFormat('yyyy-MM-dd');
            },
        },
        {
            id: 'actions',
            header: 'Actions',
            cell: ({ row }) => {
                return (
                    <div className='flex h-full items-center gap-2'>
                        <TooltipWrapper label='View Supplier'>
                            <GanttChartSquare
                                size={16}
                                onClick={() =>
                                    handleRedirectToDetails(row.original._id)
                                }
                                data-testid='view-supplier-button'
                                className='cursor-pointer hover:scale-125'
                            />
                        </TooltipWrapper>

                        <TooltipWrapper label='Apply Delegation'>
                            <Link
                                size={16}
                                onClick={() => {
                                    if (!isDelegate(row.original.seller._id)) {
                                        assignDelegate(row.original.seller._id);
                                    }
                                }}
                                data-testid='assign-delegate-button'
                                className={cn(
                                    'cursor-pointer hover:scale-125',
                                    isDelegate(row.original.seller._id) &&
                                        'cursor-not-allowed text-gray-500'
                                )}
                            />
                        </TooltipWrapper>

                        <TooltipWrapper label='Remove Supplier'>
                            <Trash
                                size={16}
                                onClick={() =>
                                    remove(
                                        row.original._id,
                                        row.original.seller._id
                                    )
                                }
                                className='cursor-pointer text-red-800 hover:scale-125'
                            />
                        </TooltipWrapper>
                    </div>
                );
            },
        },
    ];

    return (
        <div className='flex h-full flex-col'>
            <TableV2
                label='Suppliers'
                columns={columns}
                data={memoizedData || []}
                isLoading={isLoading}
                disabledTooltipColumns={['actions']}
                controls={{
                    enableDelegation: true,
                    enableSearch: true,
                }}
                actions={[
                    {
                        id: 'switch-view',
                        icon: <Globe size={16} />,
                        onClick: () => {
                            setSearchParams((prev) => {
                                prev.set('mode', 'map');

                                return prev;
                            });
                        },
                    },
                    {
                        id: 'add',
                        icon: <Plus size={16} />,
                        onClick: () => setIsCreate(true),
                    },
                ]}
            />

            {isCreate && (
                <AddSupplier
                    onClose={() => setIsCreate(false)}
                    open={isCreate}
                />
            )}
        </div>
    );
};
