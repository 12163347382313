import { useContextStore } from 'src/app/stores/context-store';
import { useMaterialService } from './use-material-service';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { ProductMaterialDataObject } from '../request/product-material-data-object';
import { toast } from 'sonner';
import { MATERIALS_QUERY_KEY } from './material-query-keys';

interface Props {
    delegateId?: string;
    item: string;
    body: ProductMaterialDataObject;
}

export function useCloneMaterial() {
    const { service } = useMaterialService();
    const context = useContextStore();
    const client = useQueryClient();

    return useMutation({
        mutationFn: async ({ delegateId, item, body }: Props) => {
            return await service.clone(
                delegateId || context.workspace?._id || '',
                item,
                body
            );
        },
        onError: (error: any) => {
            const err = error as Error;

            toast.error(`Failed to clone material: ${err.message}`);
        },
        onSuccess: (_, { delegateId }) => {
            toast.success('Material cloned successfully');

            client.invalidateQueries(
                MATERIALS_QUERY_KEY(delegateId || context.workspace?._id || '')
            );
        },
    });
}
