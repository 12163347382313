import { ServiceConfigInterface } from 'src/domain/interfaces/config.context.interface';
import { LinkApiClient } from './link.client';
import { PurchaseOrderDataLinkDataObject } from './requests/purchase-order-link-data-object';

export class LinkService {
    private server: LinkApiClient;

    constructor(config: ServiceConfigInterface, token: string) {
        this.server = new LinkApiClient(config, token);
    }

    async link(
        workspace: string,
        order: string,
        body: PurchaseOrderDataLinkDataObject
    ) {
        console.log(`service<link>| link(): Enter`);
        console.log(`service<link>| link(): $workspace = ${workspace}`);
        console.log(`service<link>| link(): $order = ${order}`);
        console.log(`service<link>| link(): $body = ${JSON.stringify(body)}`);

        return this.server.link(workspace, order, body);
    }

    async getLinked(workspace: string, order: string) {
        console.log(`service<link>| getLinked(): Enter`);
        console.log(`service<link>| getLinked(): $workspace = ${workspace}`);
        console.log(`service<link>| getLinked(): $order = ${order}`);

        return this.server.getLinked(workspace, order);
    }

    async removeLink(
        workspace: string,
        order: string,
        purchase: string,
        diversion = '0'
    ) {
        console.log(`service<link>| removeLink(): Enter`);
        console.log(`service<link>| removeLink(): $workspace = ${workspace}`);
        console.log(`service<link>| removeLink(): $order = ${order}`);
        console.log(`service<link>| removeLink(): $purchase = ${purchase}`);
        console.log(`service<link>| removeLink(): $diversion = ${diversion}`);

        return this.server.removeLink(workspace, order, purchase, diversion);
    }

    async resetCascade(workspace: string, purchase: string) {
        console.log(`service<link>| resetCascade(): Enter`);
        console.log(`service<link>| resetCascade(): $workspace = ${workspace}`);
        console.log(`service<link>| resetCascade(): $purchase = ${purchase}`);

        return this.server.resetCascade(workspace, purchase);
    }
}
