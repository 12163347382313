export const DOCUMENTS_QUERY_KEY = (workspace: string) => [
    'documents',
    workspace,
];

export const DOCUMENT_QUERY_KEY = (workspace: string, id: string) => [
    'document',
    workspace,
    id,
];

export const PARTNER_DOCUMENTS_QUERY_KEY = (
    workspace: string,
    partnerId: string
) => ['partner-documents', workspace, partnerId];
