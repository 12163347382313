import { zodResolver } from '@hookform/resolvers/zod';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import ReactQuill from 'react-quill';
import { TemplateCreateDataObject } from 'src/app/_api_adb2c/workspace/template/template-create-data-object';
import { TextInput } from 'src/app/components/Form/TextInput';
import { Button } from 'src/components/ui/button';
import {
    Dialog,
    DialogContent,
    DialogFooter,
    DialogTitle,
} from 'src/components/ui/dialog';
import { Form } from 'src/components/ui/form';
import { z } from 'zod';

interface Props {
    open: boolean;
    onSubmit: (template: TemplateCreateDataObject) => void;
    onClose: () => void;
}

const formSchema = z.object({
    channel: z.string(),
    code: z.string(),
    content: z.string().optional(),
    description: z.string().optional(),
    locale: z.string(),
    subject: z.string(),
});

export const CreateTemplate = ({ open, onClose, onSubmit }: Props) => {
    const [content, setContent] = useState('');

    const form = useForm<z.infer<typeof formSchema>>({
        mode: 'onChange',
        resolver: zodResolver(formSchema),
        defaultValues: {
            locale: 'en',
        },
    });

    const handleSubmit = async (data: z.infer<typeof formSchema>) => {
        await onSubmit(data);
    };

    return (
        <Dialog open={open} onOpenChange={onClose}>
            <DialogContent>
                <DialogTitle>Create Template</DialogTitle>

                <Form {...form}>
                    <form
                        id='my-form'
                        onSubmit={form.handleSubmit(handleSubmit)}
                    >
                        <TextInput inputName='code' label='Code' />
                        <TextInput inputName='subject' label='Subject' />
                        <TextInput inputName='channel' label='Channel' />

                        <ReactQuill
                            className='mt-4'
                            value={content}
                            onChange={setContent}
                        />
                    </form>
                </Form>

                <DialogFooter>
                    <Button size='sm' variant='outline' onClick={onClose}>
                        Cancel
                    </Button>

                    <Button size='sm' form='my-form' type='submit'>
                        Submit
                    </Button>
                </DialogFooter>
            </DialogContent>
        </Dialog>
    );
};
