import { useMutation, useQueryClient } from '@tanstack/react-query';
import { toast } from 'sonner';
import { PRODUCTS_QUERY_KEY } from 'src/app/_api/products/products/hooks/product-query-keys';
import { useContextStore } from 'src/app/stores/context-store';
import { BASE_PRODUCT_QUERY_KEY } from './product-query-keys';
import { useProductService } from './use-product-service';
import { BASE_PURCHASE_ITEM_QUERY_KEY } from 'src/app/_api_adb2c/purchase/purchase-item/hooks/purchase-item-query-keys';

export function useDeleteProduct() {
    const { service } = useProductService();
    const context = useContextStore();
    const client = useQueryClient();

    return useMutation({
        mutationFn: async ({
            delegateId,
            item,
        }: {
            delegateId?: string;
            item: string;
        }) => {
            return await service.remove(
                delegateId || context.workspace?._id || '',
                item
            );
        },
        onSuccess: (_, { delegateId }) => {
            client.invalidateQueries(
                PRODUCTS_QUERY_KEY(delegateId || context.workspace?._id || '')
            );

            client.invalidateQueries([BASE_PRODUCT_QUERY_KEY]);
            client.invalidateQueries([BASE_PURCHASE_ITEM_QUERY_KEY]);

            toast.success('Product deleted successfully');
        },
        onError: (err) => {
            const error = err as Error;

            toast.error(`Failed to delete product: ${error.message}`);
        },
    });
}
