import { useEffect } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import ReactFlow, {
    Background,
    Edge,
    Node,
    useEdgesState,
    useNodesState,
} from 'reactflow';
import { usePurchaseItemCascade } from 'src/app/_api_adb2c/purchase/purchase-item/hooks/use-purchase-item-cascade';
import { PurchaseCascadeNodeGroup } from 'src/app/pages/Purchases/Details/Cascade/purchase-cascade-node-group';
import { CascadeNodeOptions } from './product-trace-map';

import {
    SupplyChainNodeType,
    SupplyChainNodeTypeLabel,
} from 'src/app/_api_adb2c/workspace/shared/enum/supply-chain-node-type.enum';
import { PurchaseCascadeNode } from 'src/app/pages-v2/Purchases/Details/purchase-cascade-node';

const nodeTypes = {
    [CascadeNodeOptions.NODE_GROUP]: PurchaseCascadeNodeGroup,
    [CascadeNodeOptions.NODE]: PurchaseCascadeNode,
};

export function ProductCascadeTraceMap() {
    const { id } = useParams();
    const [searchParams] = useSearchParams();
    const delegateId = searchParams.get('delegateId') || '';

    const { data: cascades } = usePurchaseItemCascade(id || '', delegateId);
    const [nodes, setNodes, onNodesChange] = useNodesState([]);
    const [edges, setEdges, onEdgesChange] = useEdgesState([]);

    useEffect(() => {
        cascades?.forEach((cascade, index) => {
            if (index !== 0) return;
            const nodes: Node[] = [];
            // Within each tier
            cascade.tiers.forEach((tier, tierIndex) => {
                const processTypes: {
                    label: string;
                    count: number;
                    orders: {
                        _id: string;
                        supplier: {
                            seller: {
                                name: string;
                            };
                            name: string;
                        };
                        workspace: string;
                    }[];
                }[] = [];

                tier.suppliers.forEach((supplier) => {
                    // Get all process types for each supplier
                    supplier.orders.forEach((order) => {
                        const orderProcesses =
                            order.processes.length > 0
                                ? order.processes[0]
                                : 'Unknown';

                        const parsedProcess =
                            SupplyChainNodeTypeLabel[
                                orderProcesses as SupplyChainNodeType
                            ] || 'Unknown';

                        const existingProcess = processTypes.find(
                            (processType) => processType.label === parsedProcess
                        );

                        if (!existingProcess) {
                            processTypes.push({
                                label: parsedProcess,
                                count: 1,
                                orders: [order],
                            });
                        } else {
                            existingProcess.orders.push(order);
                            existingProcess.count++;
                        }
                    });

                    // Create each group for each process type based off tier
                    let previousNodeHeight = 0;
                    processTypes.forEach((process, index) => {
                        const group: Node = {
                            id: `group-${tier.tier}-${process.label}`,
                            type: CascadeNodeOptions.NODE_GROUP,
                            position: {
                                x: 20 + 250 * (tierIndex + 1),
                                y: 20 + 60 * index + previousNodeHeight,
                            },
                            data: {
                                label: process.label,
                                height: 160 * process.count,
                            },
                        };

                        previousNodeHeight += group.data.height;

                        nodes.push(group);

                        process.orders.forEach((order, index) => {
                            console.log('order iss: ', order);

                            const node: Node = {
                                id: `node-${order._id}`,
                                type: CascadeNodeOptions.NODE,
                                position: { x: 8, y: 40 + 160 * index },
                                parentNode: `group-${tier.tier}-${process.label}`,
                                data: {
                                    mode: 'supplier',
                                    sheetLabel:
                                        order?.supplier?.name || 'Unknown',
                                    purchase: order,
                                    workspace: order.workspace,
                                },
                            };

                            nodes.push(node);
                        });
                    });
                });

                setNodes(nodes);
            });
        });
    }, [cascades, setNodes]);

    useEffect(() => {
        // This is to build the edges
        const edges: Edge[] = [];

        cascades?.forEach((cascade, index) => {
            if (index !== 0) return;
            cascade.tiers.forEach((tier) => {
                tier.suppliers.forEach((supplier) => {
                    supplier.orders.forEach((order) => {
                        edges.push({
                            id: `${order._id} - ${order.parentOrderReference}}`,
                            source: `node-${order.parentOrderReference}`,
                            target: `node-${order._id}`,
                            zIndex: 1,
                            style: {
                                stroke: '#000',
                            },
                        });
                    });
                });
            });
        });

        setEdges(edges);
    }, [cascades, setEdges]);

    return (
        <>
            <ReactFlow
                className='h-full w-full bg-gray-100'
                proOptions={{
                    hideAttribution: true,
                }}
                nodes={nodes}
                nodeTypes={nodeTypes}
                edges={edges}
                minZoom={0.001}
                onNodesChange={onNodesChange}
                onEdgesChange={onEdgesChange}
                defaultViewport={{
                    zoom: 0.5,
                    x: 0,
                    y: 0,
                }}
            >
                <Background />
            </ReactFlow>
        </>
    );
}
