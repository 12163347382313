import { useContextStore } from 'src/app/stores/context-store';
import { useDelegationService } from './use-delegation-service';
import { useQuery } from '@tanstack/react-query';
import { toast } from 'sonner';
import { DELEGATIONS_QUERY_KEY } from './delegation-query-keys';
import { WorkspaceDelegationModel } from '../delegation.model';

export function useDelegations(delegateId?: string) {
    const { service } = useDelegationService();
    const context = useContextStore();

    return useQuery<WorkspaceDelegationModel[]>({
        queryKey: DELEGATIONS_QUERY_KEY(
            delegateId || context.workspace?._id || ''
        ),
        queryFn: async () => {
            return await service.list(
                delegateId || context.workspace?._id || ''
            );
        },
        onError: (err) => {
            const error = err as Error;

            toast.error(`Failed to load delegations: ${error.message}`);
        },
    });
}
