import { cn } from 'src/lib/utils';

interface LabelProps extends React.HTMLAttributes<HTMLDivElement> {
    title: string;
}

export function DetailLabel({
    children,
    title,
    className,
    ...args
}: LabelProps) {
    return (
        <div className={cn('flex flex-col gap-1 text-xs', className)}>
            <span className='font-light uppercase text-gray-500'>{title}</span>
            <span style={{ textTransform: 'capitalize' }}>{children ? children : '--'}</span>
        </div>
    );
}
