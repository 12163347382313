import axios from 'axios';
import { msalInstance } from 'src';

const TOKEN_KEY = 'token';
const EXPIRATION_KEY = 'expiration';

const getStoredToken = () => {
    const token = sessionStorage.getItem(TOKEN_KEY);
    const expiration = sessionStorage.getItem(EXPIRATION_KEY);
    return {
        token,
        expirationTime: expiration ? parseInt(expiration) : 0,
    };
};

const setStoredToken = (token: string, expirationTime: number) => {
    sessionStorage.setItem(TOKEN_KEY, token);
    sessionStorage.setItem(EXPIRATION_KEY, expirationTime.toString());
};

const instance = axios.create({});

instance.interceptors.request.use(async (config) => {
    // Check if we have a valid cached token
    const currentTime = Date.now();
    const { token, expirationTime } = getStoredToken();

    if (token && currentTime < expirationTime) {
        if (config.headers && !config.headers.Authorization) {
            config.headers.Authorization = `Bearer ${token}`;
        }

        return Promise.resolve(config);
    }

    const instance = msalInstance;
    const accounts = instance.getAllAccounts();

    if (accounts.length === 0) {
        return Promise.resolve(config);
    }

    const accessTokenRequest = {
        scopes: ['openid'],
        accounts: accounts[0],
    };

    return instance.acquireTokenSilent(accessTokenRequest).then(
        (response) => {
            let token = response.idToken;

            // Store the token in session storage with expiration
            setStoredToken(token, currentTime + 55 * 60 * 1000);

            if (config.headers && !config.headers.Authorization) {
                config.headers.Authorization = `Bearer ${token}`;
            }

            return Promise.resolve(config);
        },
        function (error) {
            return Promise.reject(error);
        }
    );
});

export default instance;
