import { useNavigate } from 'react-router-dom';
import { Button } from 'src/components/ui/button';
import {
    Dialog,
    DialogContent,
    DialogFooter,
    DialogTitle,
} from 'src/components/ui/dialog';

interface Props {
    open: boolean;
    label: string;
    loading?: boolean;
    onClose: () => void;
    onSubmit: () => void;
    navigationOptions?: {
        enabled: boolean;
        path: string;
    };
}

export const DeleteDialog = ({
    open,
    label,
    navigationOptions,
    loading,
    onClose,
    onSubmit,
}: Props) => {
    const navigate = useNavigate();

    const redirect = () => {
        if (navigationOptions?.enabled) {
            navigate(navigationOptions.path);
        }
    };

    const submit = async () => {
        await onSubmit();
        onClose();

        redirect();
    };

    return (
        <Dialog open={open} onOpenChange={onClose}>
            <DialogContent>
                <DialogTitle className='text-md prose'>
                    Remove {label}
                </DialogTitle>

                <span className='text-sm'>
                    Are you sure you want to remove this{' '}
                    <span className='lowercase'>{label}</span>?
                </span>

                <DialogFooter>
                    <Button
                        loading={loading}
                        size='sm'
                        variant='destructive'
                        onClick={submit}
                        data-testid='remove-button'
                    >
                        Remove
                    </Button>
                </DialogFooter>
            </DialogContent>
        </Dialog>
    );
};
