import { ColumnDef } from '@tanstack/react-table';
import { Trash, Plus } from 'lucide-react';
import { useMemo, useEffect, useCallback } from 'react';
import { useFormContext, useFieldArray } from 'react-hook-form';
import { useProducts } from 'src/app/_api_adb2c/product/product/hooks/use-products';
import { usePurchaseItems } from 'src/app/_api_adb2c/purchase/purchase-item/hooks/use-purchase-items';
import {
    ProductUom,
    ProductUomLabels,
} from 'src/app/_api_adb2c/purchase/purchase/enums/product-uom.enum';
import { TableV2 } from 'src/app/components-v2/table-v2';
import { SelectInput } from 'src/app/components/Form/SelectInput';
import { TextInput } from 'src/app/components/Form/TextInput';
import { Button } from 'src/components/ui/button';
import { z } from 'zod';
import { formSchema, purchaseOrderItemDataObjectSchema } from './add-purchase';
import { StepComponentProps } from 'src/app/components-v2/dialog-v2';

interface Props extends Partial<StepComponentProps> {}

export const AddPurchaseItemFields = ({ setFooterControls }: Props) => {
    const name = 'items';

    const form = useFormContext<z.infer<typeof formSchema>>();
    const delegateId = form.watch('delegateId');

    const { data } = usePurchaseItems(delegateId);
    const { data: products } = useProducts(delegateId);

    const { fields, append, remove } = useFieldArray({
        control: form.control,
        name: 'items',
    });

    // Create a stable callback for appending items
    const handleAppendItem = useCallback(() => {
        append({
            unit: ProductUom.PCS,
            ppu: 0,
            quantity: 0,
            product: '',
        });
    }, [append]);

    useEffect(() => {
        if (!setFooterControls) return;

        setFooterControls(
            <Button
                size='sm'
                variant='outline'
                type='button'
                onClick={handleAppendItem}
            >
                <Plus size={16} />
            </Button>
        );
    }, [setFooterControls, handleAppendItem]);

    const columns: ColumnDef<
        z.infer<typeof purchaseOrderItemDataObjectSchema>
    >[] = useMemo(
        () => [
            {
                id: 'material',
                header: 'Material',
                accessor: 'material',
                cell: ({ row }) => {
                    return (
                        <SelectInput
                            name={`${name}.${row.index}.product`}
                            options={(data?.data || []).map((x) => {
                                const product = products?.data?.find(
                                    (p) => p._id === x._id
                                );

                                return {
                                    label: product?.name || x._id,
                                    value: x._id,
                                };
                            })}
                        />
                    );
                },
            },
            {
                id: 'unit',
                header: 'Unit',
                accessor: 'unit',
                cell: ({ row }) => {
                    return (
                        <SelectInput
                            name={`${name}.${row.index}.unit`}
                            options={Object.values(ProductUom).map((x) => ({
                                label: ProductUomLabels[x],
                                value: x,
                            }))}
                        />
                    );
                },
            },
            {
                id: 'ppu',
                header: 'PPU',
                accessor: 'ppu',
                cell: ({ row }) => {
                    return (
                        <TextInput
                            inputName={`${name}.${row.index}.ppu`}
                            type='number'
                        />
                    );
                },
            },
            {
                id: 'quantity',
                header: 'Quantity',
                accessor: 'quantity',
                cell: ({ row }) => {
                    return (
                        <TextInput
                            inputName={`${name}.${row.index}.quantity`}
                            type='number'
                        />
                    );
                },
            },
            {
                id: 'actions',
                header: 'Actions',
                cell: ({ row }) => {
                    return (
                        <Button
                            variant='outline'
                            className='text-red-800'
                            type='button'
                            onClick={() => remove(row.index)}
                        >
                            <Trash className='h-4 w-4' />
                        </Button>
                    );
                },
            },
        ],
        [remove, data?.data, products]
    );

    return (
        <TableV2
            columns={columns}
            data={fields}
            fixedHeight='500px'
            disableTooltips
            tableClassName='table-fixed'
        />
    );
};
