export const BASE_VIRTUAL_PURCHASE_KEY = 'virtual-purchases';
export const VIRTUAL_PURCHASES_QUERY_KEY = (workspace: string) => [
    BASE_VIRTUAL_PURCHASE_KEY,
    workspace,
];

export const VIRTUAL_PURCHASE_ROOT_QUERY_KEY = (
    workspace: string,
    product: string
) => [BASE_VIRTUAL_PURCHASE_KEY, workspace, product];
