import { ServiceConfigInterface } from 'src/domain/interfaces/config.context.interface';
import { ApiServer } from 'src/infrastructure/clients/api.server';
import { PurchaseOrderDataLinkDataObject } from './requests/purchase-order-link-data-object';

export class LinkApiClient extends ApiServer {
    constructor(config: ServiceConfigInterface, token: string) {
        super(token, config, 'purchases', 'links');
    }

    link(
        workspace: string,
        order: string,
        body: PurchaseOrderDataLinkDataObject
    ) {
        console.log(`client<link>| link(): Enter`);
        console.log(`client<link>| link(): $workspace = ${workspace}`);
        console.log(`client<link>| link(): $order = ${order}`);
        console.log(`client<link>| link(): $body = ${JSON.stringify(body)}`);

        return super.put('link', workspace, order, body);
    }

    getLinked(workspace: string, order: string) {
        console.log(`client<link>| getLinked(): Enter`);
        console.log(`client<link>| getLinked(): $workspace = ${workspace}`);
        console.log(`client<link>| getLinked(): $order = ${order}`);
        return super.get('get', workspace, order);
    }

    removeLink(
        workspace: string,
        order: string,
        purchase: string,
        diversion = '0'
    ) {
        console.log(`client<link>| removeLink(): Enter`);
        console.log(`client<link>| removeLink(): $workspace = ${workspace}`);
        console.log(`client<link>| removeLink(): $order = ${order}`);
        console.log(`client<link>| removeLink(): $purchase = ${purchase}`);
        console.log(`client<link>| removeLink(): $diversion = ${diversion}`);

        return super.delete('delete', workspace, order, purchase, diversion);
    }

    resetCascade(workspace: string, purchase: string) {
        console.log(`client<link>| resetCascade(): Enter`);
        console.log(`client<link>| resetCascade(): $workspace = ${workspace}`);
        console.log(`client<link>| resetCascade(): $purchase = ${purchase}`);

        return super.delete('reset', workspace, purchase);
    }
}
