import { useMemo } from 'react';
import { Label, Pie, PieChart } from 'recharts';
import { useSuppliers } from 'src/app/_api_adb2c/purchase/suppliers/hooks/use-suppliers';
import {
    Card,
    CardContent,
    CardDescription,
    CardHeader,
    CardTitle,
} from 'src/components/ui/card';
import {
    ChartConfig,
    ChartContainer,
    ChartTooltip,
    ChartTooltipContent,
} from 'src/components/ui/chart';
import countries from 'src/infrastructure/config/data/countries.json';
import { generateColors, observable12 } from '.';
import { EmptyChart } from '../empty-chart';
import { getDateRangeDescription } from '../utils/get-date-range-description';

const chartConfig = {
    count: {
        label: 'Count',
        color: 'hsl(var(--chart-1))',
    },
} satisfies ChartConfig;

export const renderCustomizedLabel = (props: any) => {
    const RADIAN = Math.PI / 180;
    const { cx, cy, midAngle, outerRadius, fill, payload, percent } = props;
    const sin = Math.sin(-RADIAN * midAngle);
    const cos = Math.cos(-RADIAN * midAngle);
    const delta = Math.abs(1 / cos) + 10;
    const sx = cx + outerRadius * cos;
    const sy = cy + outerRadius * sin;
    const mx = cx + (outerRadius + delta) * cos;
    const my = cy + (outerRadius + delta) * sin;
    const ex = mx + Number(cos.toFixed(1)) * 20;
    const ey = my;
    const textAnchor = cos >= 0 ? 'start' : 'end';

    const maxLength = 10;
    const label =
        payload.label.length > maxLength
            ? `${payload.label.substring(0, maxLength)}...`
            : payload.label;

    return (
        <g>
            <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} fill='none' />
            <rect
                x={ex + (cos >= 0 ? 1 * 5 : -1 * 17)}
                y={ey - 4}
                width={12}
                height={8}
                fill={fill}
            />
            <text
                x={ex + (cos >= 0 ? 1 : -1) * 21}
                y={ey + 4}
                textAnchor={textAnchor}
                className='whitespace-wrap'
            >
                {/* {`${payload.label}: ${(percent * 100).toFixed(2)}%`} */}
                {`${label}: ${(percent * 100).toFixed(2)}%`}
            </text>
        </g>
    );
};

interface Props {
    startDate?: Date;
    endDate?: Date;
}

// Add this function outside of the component
export function SupplierCountry({ startDate, endDate }: Props) {
    const { data: suppliers } = useSuppliers();
    const colors = generateColors(observable12);

    const graphData = useMemo(() => {
        const countryCounts: { label: string; count: number; fill: string }[] =
            [];

        suppliers?.forEach((supplier, index) => {
            if (!supplier.seller?.country) return;

            const createdDate = new Date(supplier.createdOn);

            // Check if the date is within the range
            if (startDate && endDate) {
                if (createdDate < startDate || createdDate > endDate) return;
            }

            const countryName = countries.find(
                (x) =>
                    x['alpha-2'] === supplier.seller.country ||
                    x.name.toLocaleLowerCase() ===
                        supplier.seller.country.toLocaleLowerCase()
            )?.name;

            if (!countryName) return;

            const existing = countryCounts.find((x) => x.label === countryName);

            // Get color based of last digit of index
            const color = colors[index % colors.length];

            if (existing) {
                existing.count++;
            } else {
                countryCounts.push({
                    label: countryName,
                    count: 1,
                    fill: color,
                });
            }
        });

        return countryCounts;
    }, [suppliers, colors, startDate, endDate]);

    const dateRangeDescription = useMemo(
        () => getDateRangeDescription(startDate, endDate),
        [startDate, endDate]
    );

    return (
        <Card className='px-4 py-2 shadow'>
            <CardHeader>
                <CardTitle>Geographical Distribution</CardTitle>
                <CardDescription>{dateRangeDescription}</CardDescription>
            </CardHeader>

            <CardContent>
                {graphData.length > 0 ? (
                    <ChartContainer
                        config={chartConfig}
                        className='mx-auto aspect-square h-56 w-full'
                    >
                        <PieChart>
                            <ChartTooltip
                                cursor={false}
                                content={<ChartTooltipContent hideLabel />}
                            />

                            <Pie
                                data={graphData}
                                dataKey='count'
                                nameKey='label'
                                innerRadius={60}
                                paddingAngle={0}
                            >
                                <Label
                                    content={({ viewBox }) => {
                                        if (
                                            viewBox &&
                                            'cx' in viewBox &&
                                            'cy' in viewBox
                                        ) {
                                            return (
                                                <text
                                                    x={viewBox.cx}
                                                    y={viewBox.cy}
                                                    textAnchor='middle'
                                                    dominantBaseline='middle'
                                                >
                                                    <tspan
                                                        x={viewBox.cx}
                                                        y={viewBox.cy}
                                                        className='fill-foreground text-3xl font-bold'
                                                    >
                                                        {graphData.length}
                                                    </tspan>
                                                    <tspan
                                                        x={viewBox.cx}
                                                        y={
                                                            (viewBox.cy || 0) +
                                                            24
                                                        }
                                                        className='fill-muted-foreground'
                                                    >
                                                        Suppliers
                                                    </tspan>
                                                </text>
                                            );
                                        }
                                    }}
                                />
                            </Pie>
                        </PieChart>
                    </ChartContainer>
                ) : (
                    <EmptyChart />
                )}
            </CardContent>
        </Card>
    );
}
