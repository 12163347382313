export enum SupplyChainNodeType {
    AGENT = 'AGENT',
    ALUMINIUM_EXTRUSION = 'ALUMINIUM_EXTRUSION',
    ASSEMBLY = 'ASSEMBLY',
    BEAM_HOUSE = 'BEAM_HOUSE',
    BRAND = 'BRAND',
    BUTTON_SUPPLIER = 'BUTTON_SUPPLIER',
    CARE_LABEL_SUPPLIER = 'CARE_LABEL_SUPPLIER',
    CASTING = 'CASTING',
    CHEMICAL_SUPPLIER_RECYCLER = 'CHEMICAL_SUPPLIER_RECYCLER',
    CHEMICAL_SUPPLIER_FABRIC_PROCESSING = 'CHEMICAL_SUPPLIER_FABRIC_PROCESSING',
    CHEMICAL_SUPPLIER_RAW_MATERIAL_PROCESSING = 'CHEMICAL_SUPPLIER_RAW_MATERIAL_PROCESSING',
    CHEMICAL_SUPPLIER_YARN_PROCESSING = 'CHEMICAL_SUPPLIER_YARN_PROCESSING',
    COMBING_MILL = 'COMBING_MILL',
    CRUSTING_TANNERY = 'CRUSTING_TANNERY',
    CUSHION_SUPPLIER = 'CUSHION_SUPPLIER',
    CUT_MAKE_TRIM = 'CUT_MAKE_TRIM',
    DISTRIBUTOR = 'DISTRIBUTOR',
    ELASTIC_RIBBON_SUPPLIER = 'ELASTIC_RIBBON_SUPPLIER',
    EMBROIDERER = 'EMBROIDERER',
    FABRIC_COATING = 'FABRIC_COATING',
    FABRIC_DYEING = 'FABRIC_DYEING',
    FABRIC_FINISHING = 'FABRIC_FINISHING',
    FABRIC_MILL = 'FABRIC_MILL',
    FABRIC_PRINTING = 'FABRIC_PRINTING',
    FABRIC_TRADING = 'FABRIC_TRADING',
    FARM = 'FARM',
    FINISHED_GOODS_FACTORY = 'FINISHED_GOODS_FACTORY',
    FINISHED_GOODS_TRADER = 'FINISHED_GOODS_TRADER',
    FOAM_SUPPLIER = 'FOAM_SUPPLIER',
    FULFILLMENT_CENTER = 'FULFILLMENT_CENTER',
    HANGTAG_SUPPLIER = 'HANGTAG_SUPPLIER',
    GARMENT_CHEMCIAL_SUPPLIER = 'GARMENT_CHEMCIAL_SUPPLIER',
    GARMENT_PRINTER = 'GARMENT_PRINTER',
    GARMENT_FINISHING = 'GARMENT_FINISHING',
    GINNIMG_MILL = 'GINNIMG_MILL',
    GINNING_MILL = 'GINNING_MILL',
    INJECTION_MOLD_FACTORY = 'INJECTION_MOLD_FACTORY',
    LABEL_SUPPLIER = 'LABEL_SUPPLIER',
    LACES_SUPPLIER = 'LACES_SUPPLIER',
    LEATHER_CHEMICAL_SUPPLIER = 'LEATHER_CHEMICAL_SUPPLIER',
    LEATHER_FINISHIHG = 'LEATHER_FINISHIHG',
    LEATHER_TANNER = 'LEATHER_TANNER',
    LEATHER_TRADER = 'LEATHER_TRADER',
    LINING_SUPPLIER = 'LINING_SUPPLIER',
    KNITTING_MILL = 'KNITTING_MILL',
    LASER_PROCESSING = 'LASER_PROCESSING',
    LAUNDRY = 'LAUNDRY',
    MINE = 'MINE',
    METAL_GOODS_FACTORY = 'METAL_GOODS_FACTORY',
    PACKAGING_SUPPLIER = 'PACKAGING_SUPPLIER',
    PACKAGING_MATERIAL_SUPPLIER = 'PACKAGING_MATERIAL_SUPPLIER',
    PATTERN_MAKING = 'PATTERN_MAKING',
    PLATING = 'PLATING',
    POCKET_SUPPLIER = 'POCKET_SUPPLIER',
    POLISHING = 'POLISHING',
    PRINTER = 'PRINTER',
    QUALITY_ASSURANCE = 'QUALITY_ASSURANCE',
    RANCH = 'RANCH',
    RAW_MATERIAL_SUPPLIER = 'RAW_MATERIAL_SUPPLIER',
    RAW_MATERIAL_TRADING = 'RAW_MATERIAL_TRADING',
    RECYCLING_FACILITY = 'RECYCLING_FACILITY',
    REFINERY = 'REFINERY',
    RETAILER = 'RETAILER',
    RIVET_SUPPLIER = 'RIVET_SUPPLIER',
    SEWING = 'SEWING',
    SLAUTHER_HOUSE = 'SLAUTHER_HOUSE',
    SOLE_SUPPLIER = 'SOLE_SUPPLIER',
    SPINNING_MILL = 'SPINNING_MILL',
    THREAD_SUPPLIER = 'THREAD_SUPPLIER',
    TOP_MAKING = 'TOP_MAKING',
    WEAVING_MILL = 'WEAVING_MILL',
    WET_BLUE_TANNERY = 'WET_BLUE_TANNERY',
    WET_WHITE_TANNERY = 'WET_WHITE_TANNERY',
    YARN_DYEING = 'YARN_DYEING',
    YARN_SPINNER = 'YARN_SPINNER',
    YARN_TRADING = 'YARN_TRADING',
    ZIPPER_TRADING = 'ZIPPER_TRADING',
    WHOLESALER = 'WHOLESALER',
    OTHERS = 'OTHERS',
    /*********/
    RAW_MATERIAL_PRODUCER = 'RAW_MATERIAL_PRODUCER',
    MATERIAL_CALENDERING = 'MATERIAL_CALENDERING',
    TANNERY = 'TANNERY',
    PATCH_SUPPLIER = 'PATCH_SUPPLIER',
    ZIPPER_SUPPLIER = 'ZIPPER_SUPPLIER',
    ELASTICS_AND_RIBBON_SUPPLIER = 'ELASTICS_AND_RIBBON_SUPPLIER',
    SOURCING_AGENCY = 'SOURCING_AGENCY',
    /*********/
    INJECTION = 'INJECTION',
    CUTTING_AND_ASSEMBLY = 'CUTTING_AND_ASSEMBLY',
    GLUING_AND_CUTTING = 'GLUING_AND_CUTTING',
    DYEING = 'DYEING',
    QUALITY_CHECK = 'QUALITY_CHECK',
    FINAL_PACKING = 'FINAL_PACKING',
    FILL_THE_INK = 'FILL_THE_INK',
    PRINTING = 'PRINTING',
    /*** TP-361 ***/
    POLYMER_SOURCE = 'POLYMER_SOURCE',
    POLYMERIZATION = 'POLYMERIZATION',
    POLYMER_REACTION = 'POLYMER_REACTION',
    FIBER_PRODUCER = 'FIBER_PRODUCER',
    FILAMENT_PRODUCTION = 'FILAMENT_PRODUCTION',
    CELLULOSE_SUPPLIER = 'CELLULOSE_SUPPLIER',
}

export const SupplyChainNodeTypeLabel = {
    [SupplyChainNodeType.AGENT]: 'Agent',
    [SupplyChainNodeType.ALUMINIUM_EXTRUSION]: 'Aluminium Extrusion',
    [SupplyChainNodeType.ASSEMBLY]: 'Assembly',
    [SupplyChainNodeType.BEAM_HOUSE]: 'Beam House',
    [SupplyChainNodeType.BRAND]: 'Brand',
    [SupplyChainNodeType.BUTTON_SUPPLIER]: 'Button Supplier',
    [SupplyChainNodeType.CARE_LABEL_SUPPLIER]: 'Care Label Supplier',
    [SupplyChainNodeType.CASTING]: 'Casting',
    [SupplyChainNodeType.CHEMICAL_SUPPLIER_RECYCLER]:
        'Chemical Supplier Recycler',
    [SupplyChainNodeType.CHEMICAL_SUPPLIER_FABRIC_PROCESSING]:
        'Chemical Supplier Fabric Processing',
    [SupplyChainNodeType.CHEMICAL_SUPPLIER_RAW_MATERIAL_PROCESSING]:
        'Chemical Supplier Raw Material Processing',
    [SupplyChainNodeType.CHEMICAL_SUPPLIER_YARN_PROCESSING]:
        'Chemical Supplier Yarn Processing',
    [SupplyChainNodeType.COMBING_MILL]: 'Combing Mill',
    [SupplyChainNodeType.CRUSTING_TANNERY]: 'Crusting Tannery',
    [SupplyChainNodeType.CUSHION_SUPPLIER]: 'Cushion Supplier',
    [SupplyChainNodeType.CUT_MAKE_TRIM]: 'Cut Make Trim',
    [SupplyChainNodeType.DISTRIBUTOR]: 'Distributor',
    [SupplyChainNodeType.ELASTIC_RIBBON_SUPPLIER]: 'Elastic Ribbon Supplier',
    [SupplyChainNodeType.EMBROIDERER]: 'Embroiderer',
    [SupplyChainNodeType.FABRIC_COATING]: 'Fabric Coating',
    [SupplyChainNodeType.FABRIC_DYEING]: 'Fabric Dyeing',
    [SupplyChainNodeType.FABRIC_FINISHING]: 'Fabric Finishing',
    [SupplyChainNodeType.FABRIC_MILL]: 'Fabric Mill',
    [SupplyChainNodeType.FABRIC_PRINTING]: 'Fabric Printing',
    [SupplyChainNodeType.FABRIC_TRADING]: 'Fabric Trading',
    [SupplyChainNodeType.FARM]: 'Farm',
    [SupplyChainNodeType.FINISHED_GOODS_FACTORY]: 'Finished Goods Factory',
    [SupplyChainNodeType.FINISHED_GOODS_TRADER]: 'Finished Goods Trader',
    [SupplyChainNodeType.FOAM_SUPPLIER]: 'Foam Supplier',
    [SupplyChainNodeType.FULFILLMENT_CENTER]: 'Fulfillment Center',
    [SupplyChainNodeType.HANGTAG_SUPPLIER]: 'Hangtag Supplier',
    [SupplyChainNodeType.GARMENT_CHEMCIAL_SUPPLIER]:
        'Garment Chemical Supplier',
    [SupplyChainNodeType.GARMENT_PRINTER]: 'Garment Printer',
    [SupplyChainNodeType.GARMENT_FINISHING]: 'Garment Finishing',
    [SupplyChainNodeType.GINNIMG_MILL]: 'Ginning Mill',
    [SupplyChainNodeType.INJECTION_MOLD_FACTORY]: 'Injection Mold Factory',
    [SupplyChainNodeType.LABEL_SUPPLIER]: 'Label Supplier',
    [SupplyChainNodeType.LACES_SUPPLIER]: 'Laces Supplier',
    [SupplyChainNodeType.LEATHER_CHEMICAL_SUPPLIER]:
        'Leather Chemical Supplier',
    [SupplyChainNodeType.LEATHER_FINISHIHG]: 'Leather Finishing',
    [SupplyChainNodeType.LEATHER_TANNER]: 'Leather Tanner',
    [SupplyChainNodeType.LEATHER_TRADER]: 'Leather Trader',
    [SupplyChainNodeType.LINING_SUPPLIER]: 'Lining Supplier',
    [SupplyChainNodeType.KNITTING_MILL]: 'Knitting Mill',
    [SupplyChainNodeType.LASER_PROCESSING]: 'Laser Processing',
    [SupplyChainNodeType.LAUNDRY]: 'Laundry',
    [SupplyChainNodeType.MINE]: 'Mine',
    [SupplyChainNodeType.METAL_GOODS_FACTORY]: 'Metal Goods Factory',
    [SupplyChainNodeType.PACKAGING_SUPPLIER]: 'Packaging Supplier',
    [SupplyChainNodeType.PACKAGING_MATERIAL_SUPPLIER]: 'Packaging',
    [SupplyChainNodeType.PATTERN_MAKING]: 'Pattern Making',
    [SupplyChainNodeType.PLATING]: 'Plating',
    [SupplyChainNodeType.POCKET_SUPPLIER]: 'Pocket Supplier',
    [SupplyChainNodeType.POLISHING]: 'Polishing',
    [SupplyChainNodeType.PRINTER]: 'Printer',
    [SupplyChainNodeType.QUALITY_ASSURANCE]: 'Quality Assurance',
    [SupplyChainNodeType.RANCH]: 'Ranch',
    [SupplyChainNodeType.RAW_MATERIAL_SUPPLIER]: 'Raw Material Supplier',
    [SupplyChainNodeType.RAW_MATERIAL_TRADING]: 'Raw Material Trading',
    [SupplyChainNodeType.RECYCLING_FACILITY]: 'Recycling Facility',
    [SupplyChainNodeType.REFINERY]: 'Refinery',
    [SupplyChainNodeType.RETAILER]: 'Retailer',
    [SupplyChainNodeType.RIVET_SUPPLIER]: 'Rivet Supplier',
    [SupplyChainNodeType.SEWING]: 'Sewing',
    [SupplyChainNodeType.SLAUTHER_HOUSE]: 'Slaughter House',
    [SupplyChainNodeType.SOLE_SUPPLIER]: 'Sole Supplier',
    [SupplyChainNodeType.SPINNING_MILL]: 'Spinning Mill',
    [SupplyChainNodeType.THREAD_SUPPLIER]: 'Thread Supplier',
    [SupplyChainNodeType.TOP_MAKING]: 'Top Making',
    [SupplyChainNodeType.WEAVING_MILL]: 'Weaving Mill',
    [SupplyChainNodeType.WET_BLUE_TANNERY]: 'Wet Blue Tannery',
    [SupplyChainNodeType.WET_WHITE_TANNERY]: 'Wet White Tannery',
    [SupplyChainNodeType.YARN_DYEING]: 'Yarn Dyeing',
    [SupplyChainNodeType.YARN_SPINNER]: 'Yarn Spinner',
    [SupplyChainNodeType.YARN_TRADING]: 'Yarn Trading',
    [SupplyChainNodeType.ZIPPER_TRADING]: 'Zipper Trading',
    [SupplyChainNodeType.WHOLESALER]: 'Wholesaler',
    [SupplyChainNodeType.OTHERS]: 'Others',
    /*********/
    [SupplyChainNodeType.RAW_MATERIAL_PRODUCER]: 'Raw Material Producer',
    [SupplyChainNodeType.MATERIAL_CALENDERING]: 'Material Calendering',
    [SupplyChainNodeType.TANNERY]: 'Tannery',
    [SupplyChainNodeType.PATCH_SUPPLIER]: 'Patch Supplier',
    [SupplyChainNodeType.ZIPPER_SUPPLIER]: 'Zipper Supplier',
    [SupplyChainNodeType.ELASTICS_AND_RIBBON_SUPPLIER]:
        'Elastics and Ribbon Supplier',
    [SupplyChainNodeType.SOURCING_AGENCY]: 'Sourcing Agency',
    /*********/
    [SupplyChainNodeType.INJECTION]: 'Injection',
    [SupplyChainNodeType.CUTTING_AND_ASSEMBLY]: 'Cutting and Assembly',
    [SupplyChainNodeType.GLUING_AND_CUTTING]: 'Gluing and Cutting',
    [SupplyChainNodeType.DYEING]: 'Dyeing',
    [SupplyChainNodeType.QUALITY_CHECK]: 'Quality Check',
    [SupplyChainNodeType.FINAL_PACKING]: 'Final Packing',
    [SupplyChainNodeType.FILL_THE_INK]: 'Fill The Ink',
    [SupplyChainNodeType.PRINTING]: 'Printing',
    /*** TP-361 ***/
    [SupplyChainNodeType.POLYMER_SOURCE]: 'Polymer Source',
    [SupplyChainNodeType.POLYMERIZATION]: 'Polymerization',
    [SupplyChainNodeType.POLYMER_REACTION]: 'Polymer Reaction',
    [SupplyChainNodeType.FIBER_PRODUCER]: 'Fiber Producer',
    [SupplyChainNodeType.FILAMENT_PRODUCTION]: 'Filament Production',
    [SupplyChainNodeType.CELLULOSE_SUPPLIER]: 'Cellulose Supplier',
    [SupplyChainNodeType.GINNING_MILL]: 'Ginning Mill',
};

export const getSupplyChainNodeTypeLabel = (type: string) => {
    return SupplyChainNodeTypeLabel[type as SupplyChainNodeType] || type;
};
