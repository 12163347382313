import { ServiceConfigInterface } from 'src/domain/interfaces/config.context.interface';
import { ApiServer } from 'src/infrastructure/clients/api.server';
import { SalesOrderAttachDataObject } from './request/sales-order-attach-data-object';
import { SalesOrderDocCollectDataObject } from './request/sales-order-doc-collection-data-object';
import { SalesOrderShippedRequestObject } from './request/sales-order-shipped-request-object';

export class SalesOrderApiClient extends ApiServer {
    constructor(config: ServiceConfigInterface, token: string) {
        super(token, config, 'orders', 'sales-order');
    }

    attachFiles(
        workspace: string,
        id: string,
        diversion: string,
        body: SalesOrderAttachDataObject
    ) {
        console.log(`client<sales>| attachFiles(): Enter`);
        console.log(`client<sales>| attachFiles(): $workspace = ${workspace}`);
        console.log(`client<sales>| attachFiles(): $id = ${id}`);
        console.log(`client<sales>| attachFiles(): $diversion = ${diversion}`);
        console.log(
            `client<sales>| attachFiles(): $body = ${JSON.stringify(body)}`
        );

        return super.put('attachFiles', workspace, id, diversion, body);
    }

    statistics(workspace: string) {
        console.log(`client<sales>| statistics(): Enter`);
        console.log(`client<sales>| statistics(): $workspace = ${workspace}`);

        return super.get('statistics', workspace);
    }

    search(workspace: string, body: string[]) {
        console.log(`client<sales>| search(): Enter`);
        console.log(`client<sales>| search(): $workspace = ${workspace}`);
        console.log(`client<sales>| search(): $body = ${JSON.stringify(body)}`);

        return super.post('search', workspace, body);
    }

    collectDocuments(body: SalesOrderDocCollectDataObject) {
        console.log(`client<sales>| collectDocuments(): Enter`);
        console.log(
            `client<sales>| collectDocuments(): $body = ${JSON.stringify(body)}`
        );

        return super.post('apiKey', 'collectDocuments', body);
    }

    get(workspace: string, id: string) {
        console.log(`client<sales>| get(): Enter`);
        console.log(`client<sales>| get(): $workspace = ${workspace}`);
        console.log(`client<sales>| get(): $id = ${id}`);

        return super.get('get', workspace, id);
    }

    list(workspace: string) {
        console.log(`client<sales>| list(): Enter`);
        console.log(`client<sales>| list(): $workspace = ${workspace}`);

        return super.get('list', workspace);
    }

    getByPurchaseOrder(workspace: string, purchaseOrder: string) {
        console.log(`client<sales>| getByPurchaseOrder(): Enter`);
        console.log(
            `client<sales>| getByPurchaseOrder(): $workspace = ${workspace}`
        );
        console.log(
            `client<sales>| getByPurchaseOrder(): $purchaseOrder = ${purchaseOrder}`
        );

        return super.get('getByPurchaseOrder', workspace, purchaseOrder);
    }

    shipped(
        workspace: string,
        id: string,
        body: SalesOrderShippedRequestObject
    ) {
        console.log(`client<sales>| ship(): Enter`);
        console.log(`client<sales>| ship(): $workspace = ${workspace}`);
        console.log(`client<sales>| ship(): $id = ${id}`);
        console.log(`client<sales>| ship(): $body = ${JSON.stringify(body)}`);

        return super.put('shipped', workspace, id, body);
    }

    batch(workspace: string, body: { targets: string[] }, diversion: string) {
        console.log(`client<sales>| batch(): Enter`);
        console.log(`client<sales>| batch(): $workspace = ${workspace}`);
        console.log(`client<sales>| batch(): $diversion = ${diversion}`);
        console.log(`client<sales>| batch(): $body = ${JSON.stringify(body)}`);
        return super.post('batch', workspace, diversion, body);
    }
}
