import { CaretSortIcon } from '@radix-ui/react-icons';
import { ColumnDef } from '@tanstack/react-table';
import { Edit, Plus, Trash } from 'lucide-react';
import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useRoles } from 'src/app/_api_adb2c/workspace/roles/hooks/use-roles';
import { WorkspaceRoleModel } from 'src/app/_api_adb2c/workspace/roles/roles.model';
import { Button } from 'src/components/ui/button';
import { Checkbox } from 'src/components/ui/checkbox';
import { ReusableTable } from '../../Purchases/reusable-table';
import { CreateRole } from './create-role';
import { DeleteRole } from './delete-role';
import { EditRole } from './edit-role';

export const WorkspaceRoles = () => {
    const [searchParams] = useSearchParams();
    const delegateId = searchParams.get('delegateId') || '';

    const { data } = useRoles(delegateId);
    const [rowSelection, setRowSelection] = useState({});

    const [role, setRole] = useState<WorkspaceRoleModel>();

    const [isCreate, setIsCreate] = useState(false);
    const [isUpdate, setIsUpdate] = useState(false);
    const [isDelete, setIsDelete] = useState(false);

    const columns: ColumnDef<WorkspaceRoleModel>[] = [
        {
            id: 'select',
            header: ({ table }) => (
                <Checkbox
                    checked={table.getIsAllPageRowsSelected()}
                    onCheckedChange={(value) =>
                        table.toggleAllPageRowsSelected(!!value)
                    }
                    aria-label='Select all'
                />
            ),
            cell: ({ row }) => (
                <Checkbox
                    checked={row.getIsSelected()}
                    onCheckedChange={(value) => row.toggleSelected(!!value)}
                    aria-label='Select row'
                />
            ),
            enableSorting: false,
            enableHiding: false,
            size: 0,
        },
        {
            id: 'name',
            accessorKey: 'name',
            header: ({ column }) => {
                return (
                    <Button
                        variant='ghost'
                        className='text-xs'
                        onClick={() =>
                            column.toggleSorting(column.getIsSorted() === 'asc')
                        }
                    >
                        Name
                        <CaretSortIcon
                            className={`inline ${
                                column.getIsSorted() === 'asc'
                                    ? 'rotate-180 transform'
                                    : ''
                            }`}
                        />
                    </Button>
                );
            },
            accessorFn: (item) => item.name,
        },
        {
            id: 'description',
            accessorKey: 'description',
            header: ({ column }) => {
                return (
                    <Button
                        variant='ghost'
                        className='text-xs'
                        onClick={() =>
                            column.toggleSorting(column.getIsSorted() === 'asc')
                        }
                    >
                        Description
                        <CaretSortIcon
                            className={`inline ${
                                column.getIsSorted() === 'asc'
                                    ? 'rotate-180 transform'
                                    : ''
                            }`}
                        />
                    </Button>
                );
            },
            accessorFn: (item) => item.description,
        },
        {
            id: 'code',
            accessorKey: 'code',
            header: ({ column }) => {
                return (
                    <Button
                        variant='ghost'
                        className='text-xs'
                        onClick={() =>
                            column.toggleSorting(column.getIsSorted() === 'asc')
                        }
                    >
                        Code
                        <CaretSortIcon
                            className={`inline ${
                                column.getIsSorted() === 'asc'
                                    ? 'rotate-180 transform'
                                    : ''
                            }`}
                        />
                    </Button>
                );
            },
            accessorFn: (item) => item.code,
        },
        {
            id: 'actions',
            header: 'Actions',
            cell: ({ row }) => (
                <div>
                    <Button
                        variant='ghost'
                        onClick={() => {
                            setRole(row.original);
                            setIsUpdate(true);
                        }}
                    >
                        <Edit size={16} />
                    </Button>

                    <Button
                        variant='ghost'
                        onClick={() => {
                            setRole(row.original);
                            setIsDelete(true);
                        }}
                    >
                        <Trash size={16} />
                    </Button>
                </div>
            ),
        },
    ];

    return (
        <div className='flex h-full flex-col'>
            <ReusableTable
                data={data || []}
                columns={columns}
                rowSelection={rowSelection}
                setRowSelection={setRowSelection}
                title='Roles'
                controls={{
                    enableDelegation: true,
                    enableSearch: true,
                }}
                actions={[
                    {
                        id: 'create',
                        icon: <Plus className='h-4 w-4' />,
                        onClick: () => setIsCreate(true),
                    },
                ]}
            />

            {isCreate && (
                <CreateRole
                    onClose={() => setIsCreate(false)}
                    open={isCreate}
                />
            )}

            {isDelete && role && (
                <DeleteRole
                    role={role}
                    open={isDelete}
                    onClose={() => setIsDelete(false)}
                />
            )}

            {isUpdate && role && (
                <EditRole
                    role={role}
                    open={isUpdate}
                    onClose={() => setIsUpdate(false)}
                />
            )}
        </div>
    );
};
