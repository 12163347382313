import { useAuth0 } from '@auth0/auth0-react';
import { useCallback } from 'react';
import CustomApiError from '../utils/api-error.utils';

export const useErrorHandler = () => {
    const { logout } = useAuth0();

    return useCallback(
        (
            error: Error | unknown,
            taskDescription: string, // task in format such as 'getting locations', 'updating photo detail', 'deleting album'
            errorReference?: string | number | object, // information that could be useful for tracing error, not yet used at current stage
            hideNotification?: boolean
        ) => {
            // const currentUser = useCurrentUser();
            // const location = useLocation()
            // should add logic for logging error here...

            if ((error as CustomApiError).status === 401) {
                logout({ returnTo: 'https://www.microsoft.com' });
            }

            if (!hideNotification) {
                console.error(error);
            }
        },
        [logout]
    );
};

export default useErrorHandler;
