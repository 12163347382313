import { DownloadCloud } from 'lucide-react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { ManifestItemModel } from 'src/app/_api_adb2c/purchase/purchase/models/manifest-item.model';
import { FileModel } from 'src/app/_api_adb2c/workspace/file/file.model';
import { useFileService } from 'src/app/_api_adb2c/workspace/file/hooks/use-file-service';
import { useDownload } from 'src/app/pages-v2/Materials/Details/Substance';
import { useContextStore } from 'src/app/stores/context-store';
import {
    Popover,
    PopoverContent,
    PopoverTrigger,
} from 'src/components/ui/popover';
import { getFileIdFromAssetName } from 'src/infrastructure/utils/extract-asset-id';
import { PurchaseDocumentItem } from './purchase-documents';

interface Props {
    item: PurchaseDocumentItem;
}

interface Document {
    name: string;
    _id: string;
    type: 'product' | 'material';
}

export function PurchaseProductDocuments({ item }: Props) {
    const [searchParams] = useSearchParams();
    const delegateId = searchParams.get('delegateId') || '';

    const context = useContextStore();
    const [documents, setDocuments] = useState<Document[]>([]);

    const { service: fileService } = useFileService();
    const { download } = useDownload();

    const productDocuments = useMemo(() => {
        const consolidatedDocument: Document[] = [];

        documents.forEach((doc) => {
            if (doc.type === 'product') {
                consolidatedDocument.push(doc);
            }
        });

        return consolidatedDocument;
    }, [documents]);

    const materialDocuments = useMemo(() => {
        const consolidatedDocument: Document[] = [];

        documents.forEach((doc) => {
            if (doc.type === 'material') {
                consolidatedDocument.push(doc);
            }
        });

        return consolidatedDocument;
    }, [documents]);

    const fetchProductDocuments = useCallback((items: ManifestItemModel) => {
        const consolidatedDocument: Document[] = [];

        items.product?.documents.forEach((doc) => {
            const fileId = getFileIdFromAssetName(doc.file.assetName);

            consolidatedDocument.push({
                _id: fileId,
                name: doc.file.originalName,
                type: 'product',
            });
        });

        return consolidatedDocument;
    }, []);

    const fetchMaterialDocuments = useCallback(
        async (item: ManifestItemModel) => {
            const consolidatedSdsFiles: string[] = [];
            const consolidatedDocument: Document[] = [];

            const version = item.version;
            const latestProduct = item?.product?.versions?.find(
                (x) => x.version === version
            );

            latestProduct?.billOfMaterials?.forEach((bill) => {
                bill.material.compositions?.forEach((composition) => {
                    if (composition.sdsFileId) {
                        consolidatedSdsFiles.push(composition.sdsFileId);
                    }
                });
            });

            const sdsFiles: FileModel[] = await fileService.batchGet(
                delegateId || context.workspace?._id || '',
                consolidatedSdsFiles
            );

            latestProduct?.billOfMaterials?.forEach((bill) => {
                bill.material.documents?.forEach((doc) => {
                    const fileId = getFileIdFromAssetName(
                        doc.file.originalName
                    );

                    consolidatedDocument.push({
                        _id: fileId,
                        name: doc.file.originalName,
                        type: 'material',
                    });
                });

                bill.material.compositions?.forEach((composition) => {
                    if (composition.sdsFileId) {
                        const existingFile = sdsFiles.find(
                            (x) => x._id === composition.sdsFileId
                        );

                        consolidatedDocument.push({
                            _id: composition.sdsFileId,
                            name:
                                existingFile?.name ||
                                `SDS File for ${composition.name}`,
                            type: 'material',
                        });
                    }
                });
            });

            return consolidatedDocument;
        },
        [fileService, delegateId, context.workspace?._id]
    );

    const getDocuments = useCallback(async () => {
        const purchase = item.purchase;
        const latestVersion = purchase.versions[purchase.versions.length - 1];
        const consolidatedDocuments: Document[] = [];

        if (!latestVersion) {
            setDocuments(consolidatedDocuments);
            return;
        }

        for (const manifest of latestVersion.manifest) {
            const productDocuments = await fetchProductDocuments(manifest);
            const materialDocuments = await fetchMaterialDocuments(manifest);

            consolidatedDocuments.push(...productDocuments);
            consolidatedDocuments.push(...materialDocuments);
        }

        setDocuments(consolidatedDocuments);
    }, [fetchProductDocuments, fetchMaterialDocuments, item]);

    useEffect(() => {
        getDocuments();
    }, [getDocuments]);

    return (
        <Popover>
            <PopoverTrigger asChild>
                <span className='cursor-pointer text-xs text-blue-400 hover:underline hover:underline-offset-2'>
                    Documents
                </span>
            </PopoverTrigger>

            <PopoverContent side='top' className='flex flex-col gap-2'>
                <div className='flex flex-col gap-2'>
                    <span className='font-bold underline decoration-red-800 underline-offset-2 '>
                        Product Documents
                    </span>

                    {documents.length === 0 && (
                        <div className='flex flex-col gap-2'>
                            <span>No documents uploaded for products.</span>
                        </div>
                    )}

                    {productDocuments.length > 0 && (
                        <div className='flex flex-col gap-2'>
                            <span className='font-bold'>Product Documents</span>

                            <div className='flex flex-col gap-2'>
                                {productDocuments.map((doc, index) => (
                                    <div
                                        className='group flex items-center justify-between gap-2'
                                        key={index}
                                    >
                                        <span className='max-w-[80%] cursor-pointer truncate group-hover:underline'>
                                            {doc.name}
                                        </span>

                                        <DownloadCloud
                                            size={16}
                                            className='hover:scale-125'
                                            onClick={() => {
                                                download(doc._id, doc.name);
                                            }}
                                        />
                                    </div>
                                ))}
                            </div>
                        </div>
                    )}

                    {materialDocuments.length > 0 && (
                        <div className='flex flex-col gap-2'>
                            <span className='font-bold'>
                                Material Documents
                            </span>

                            <div className='flex flex-col gap-2'>
                                {materialDocuments.map((doc, index) => (
                                    <div
                                        className='group flex items-center justify-between gap-2'
                                        key={index}
                                    >
                                        <span className='group-hover:underline'>
                                            {doc.name}
                                        </span>

                                        <DownloadCloud
                                            size={16}
                                            className='hover:scale-125'
                                            onClick={() => {
                                                download(doc._id, doc.name);
                                            }}
                                        />
                                    </div>
                                ))}
                            </div>
                        </div>
                    )}
                </div>
            </PopoverContent>
        </Popover>
    );
}
