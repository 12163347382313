import { useMemo } from 'react';
import { Pie, PieChart } from 'recharts';
import { usePurchases } from 'src/app/_api_adb2c/purchase/purchase/hooks/use-purchases';
import { PurchaseModel } from 'src/app/_api_adb2c/purchase/purchase/models/purchase.model';
import {
    Card,
    CardContent,
    CardDescription,
    CardHeader,
    CardTitle,
} from 'src/components/ui/card';
import {
    ChartConfig,
    ChartContainer,
    ChartTooltip,
    ChartTooltipContent,
} from 'src/components/ui/chart';
import { renderCustomizedLabel } from '../Supplier/supplier-country';
import { EmptyChart } from '../empty-chart';
import { getDateRangeDescription } from '../utils/get-date-range-description';
import { generateColors, observable12 } from '../Supplier';
import {
    SupplyChainNodeType,
    SupplyChainNodeTypeLabel,
} from 'src/app/_api_adb2c/workspace/shared/enum/supply-chain-node-type.enum';

interface Props {
    startDate?: Date;
    endDate?: Date;
}

const chartConfig = {
    count: {
        label: 'Count',
        color: 'hsl(var(--chart-1))',
    },
} satisfies ChartConfig;

export function OrderProcessDistributions({ startDate, endDate }: Props) {
    const color = generateColors(observable12);
    const { data: purchases } = usePurchases({
        draft: false,
        page: 0,
        limit: 0,
    });

    const memoizedPurchases = useMemo(() => {
        if (!purchases?.data) return [];

        const responses: PurchaseModel[] = [];

        purchases.data.forEach((purchase) => {
            const createdDate = new Date(purchase.createdOn);

            if (startDate && endDate) {
                if (createdDate < startDate || createdDate > endDate) return;
            }

            responses.push(purchase);
        });

        return responses;
    }, [purchases, startDate, endDate]);

    const graphData = useMemo(() => {
        const processCounts: { label: string; count: number; fill: string }[] =
            [];

        memoizedPurchases.forEach((purchase) => {
            const processes = purchase.processes || [];

            processes.forEach((process, index) => {
                const formattedProcess =
                    SupplyChainNodeTypeLabel[process as SupplyChainNodeType];
                const existing = processCounts.find(
                    (x) => x.label === formattedProcess
                );

                if (existing) {
                    existing.count += 1;
                } else {
                    processCounts.push({
                        // label: process,
                        label: formattedProcess,
                        count: 1,
                        fill: color[index % color.length],
                    });
                }
            });
        });

        return processCounts;
    }, [memoizedPurchases, color]);

    return (
        <Card className='px-4 py-2 shadow'>
            <CardHeader>
                <CardTitle>Process Distribution</CardTitle>
                <CardDescription>
                    {getDateRangeDescription(startDate, endDate)}
                </CardDescription>
            </CardHeader>

            <CardContent>
                {graphData.length > 0 ? (
                    <ChartContainer
                        config={chartConfig}
                        className='mx-auto aspect-square h-56 w-full'
                    >
                        <PieChart>
                            <ChartTooltip
                                cursor={false}
                                content={<ChartTooltipContent hideLabel />}
                            />

                            <Pie
                                data={graphData}
                                dataKey='count'
                                nameKey='label'
                                innerRadius={60}
                                paddingAngle={2}
                                labelLine
                                label={renderCustomizedLabel}
                            />
                        </PieChart>
                    </ChartContainer>
                ) : (
                    <EmptyChart />
                )}
            </CardContent>
        </Card>
    );
}
