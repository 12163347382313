import { useNavigate } from 'react-router-dom';

export enum EventType {
    NEW = 'NEW',
    UPDATED = 'UPDATED',
    DELETED = 'DELETED',
}

type Event = {
    name: string;
    value: string;
    type: EventType;
    icon: React.ReactNode;
    navigateTo?: string;
};

type EventProps = {
    events: Event[];
    showTag?: boolean;
};

type EventTagProps = {
    type: EventType;
};

const EventTag: React.FC<EventTagProps> = ({ type }) => {
    const colour = {
        [EventType.NEW]: 'text-red-600',
        [EventType.UPDATED]: 'text-orange-600',
        [EventType.DELETED]: 'text-yellow-600',
    };

    return <span className={`font-bold ${colour[type]}`}>{type}</span>;
};

export const EventCard: React.FC<EventProps> = ({ events, showTag }) => {
    const navigate = useNavigate();

    return (
        <div className='flex flex-wrap gap-4'>
            {events.map((event, index) => {
                return (
                    <div
                        key={index}
                        onClick={() => {
                            if (event.navigateTo) {
                                navigate(event.navigateTo);
                            }
                        }}
                        className='max-h-[66px] min-h-[66px] min-w-[200px] max-w-[200px] cursor-pointer bg-white p-4 text-xs shadow hover:scale-105 hover:bg-gray-200'
                    >
                        <div className='flex h-full items-center gap-4 truncate'>
                            <span className='text-2xl'>{event.icon}</span>
                            <div className='flex h-full flex-col justify-between truncate'>
                                <span className='flex gap-2 text-xs text-gray-400'>
                                    {event.name}

                                    {showTag && <EventTag type={event.type} />}
                                </span>

                                <span className='truncate text-sm font-bold'>
                                    {event.value}
                                </span>
                            </div>
                        </div>
                    </div>
                );
            })}
        </div>
    );
};
