import { useDeletePurchase } from 'src/app/_api_adb2c/purchase/purchase/hooks/use-delete-purchase';
import { PurchaseModel } from 'src/app/_api_adb2c/purchase/purchase/models/purchase.model';
import { Button } from 'src/components/ui/button';
import {
    Dialog,
    DialogContent,
    DialogFooter,
    DialogTitle,
} from 'src/components/ui/dialog';

interface Props {
    open: boolean;
    data: PurchaseModel;
    onClose: () => void;
}

export const DeletePurchase = ({ open, data, onClose }: Props) => {
    const { mutateAsync } = useDeletePurchase();

    const remove = async () => {
        await mutateAsync({
            id: data._id,
        });

        onClose();
    };

    return (
        <RemoveDialog
            title='Remove Purchase'
            description='Are you sure you want to remove this purchase?'
            open={open}
            onClose={onClose}
            onRemove={remove}
        />
    );
};

interface RemoveDialogProps {
    open: boolean;
    onClose: () => void;
    onRemove: () => void;
    title: string;
    description?: string;
    isLoading?: boolean;
}

export function RemoveDialog({
    open,
    onClose,
    onRemove,
    title,
    description,
    isLoading,
}: RemoveDialogProps) {
    return (
        <Dialog open={open} onOpenChange={onClose}>
            <DialogContent>
                <DialogTitle className='text-md'>{title}</DialogTitle>

                <span className='text-sm'>{description}</span>

                <DialogFooter>
                    <Button
                        size='sm'
                        onClick={onRemove}
                        disabled={isLoading}
                        className='flex min-w-[100px] items-center gap-2'
                    >
                        {isLoading && (
                            <svg
                                className='h-4 w-4 animate-spin'
                                xmlns='http://www.w3.org/2000/svg'
                                fill='none'
                                viewBox='0 0 24 24'
                            >
                                <circle
                                    className='opacity-25'
                                    cx='12'
                                    cy='12'
                                    r='10'
                                    stroke='currentColor'
                                    strokeWidth='4'
                                />
                                <path
                                    className='opacity-75'
                                    fill='currentColor'
                                    d='M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z'
                                />
                            </svg>
                        )}
                        {isLoading ? 'Removing...' : 'Remove'}
                    </Button>
                </DialogFooter>
            </DialogContent>
        </Dialog>
    );
}
