export enum SupportDocType {
    AUDIT_REPORT = 'AUDIT_REPORT',
    BUSINESS_REGISTRATION = 'BUSINESS_REGISTRATION',
    BILL_OF_LADING = 'BILL_OF_LADING',
    CERTIFICATE_OF_ORIGIN = 'CERTIFICATE_OF_ORIGIN',
    CERTIFICATION_RECORD = 'CERTIFICATION_RECORD',
    PRODUCT_CERTIFICATE = 'PRODUCT_CERTIFICATE',
    COMMERCIAL_INVOICE = 'COMMERCIAL_INVOICE',
    CONTRACT = 'CONTRACT',
    CUSTOM_CLEARANCE = 'CUSTOM_CLEARANCE',
    DELIVERY_NOTE = 'DELIVERY_NOTE',
    INVOICE = 'INVOICE',
    MAP_SCREENSHOT = 'MAP_SCREENSHOT',
    PACKING_LIST = 'PACKING_LIST',
    PAYMENT_VOUCHER = 'PAYMENT_VOUCHER',
    PURCHASE_CONTRACT = 'PURCHASE_CONTRACT',
    PRODUCTION_RECORD = 'PRODUCTION_RECORD',
    PRODUCT_SPECIFICATIONS = 'PRODUCT_SPECIFICATIONS',
    TRANPORTATION_INVOICE = 'TRANPORTATION_INVOICE',
    WAREHOUSE_RECORD = 'WAREHOUSE_RECORD',
    OTHER = 'OTHER',
    SEGREGATION_SUPPORT = 'SEGREGATION_SUPPORT',
    SAFETY_DATA_SHEET = 'SAFETY_DATA_SHEET',
    BANK_CERTIFICATE = 'BANK_CERTIFICATE',
}

export const SupportDocTypeLabel: Record<SupportDocType, string> = {
    [SupportDocType.AUDIT_REPORT]: 'Audit Report',
    [SupportDocType.BILL_OF_LADING]: 'Bill of Lading',
    [SupportDocType.CERTIFICATE_OF_ORIGIN]: 'Certificate of Origin',
    [SupportDocType.CERTIFICATION_RECORD]: 'Certification Record',
    [SupportDocType.PRODUCT_CERTIFICATE]: 'Product Certificate',
    [SupportDocType.COMMERCIAL_INVOICE]: 'Commercial Invoice',
    [SupportDocType.CONTRACT]: 'Contract',
    [SupportDocType.CUSTOM_CLEARANCE]: 'Custom Clearance',
    [SupportDocType.DELIVERY_NOTE]: 'Delivery Note',
    [SupportDocType.INVOICE]: 'Invoice',
    [SupportDocType.MAP_SCREENSHOT]: 'Map Screenshot',
    [SupportDocType.PACKING_LIST]: 'Packing List',
    [SupportDocType.PAYMENT_VOUCHER]: 'Payment Voucher',
    [SupportDocType.PURCHASE_CONTRACT]: 'Purchase Contract',
    [SupportDocType.PRODUCTION_RECORD]: 'Production Record',
    [SupportDocType.PRODUCT_SPECIFICATIONS]: 'Product Specifications',
    [SupportDocType.TRANPORTATION_INVOICE]: 'Transportation Invoice',
    [SupportDocType.WAREHOUSE_RECORD]: 'Warehouse Record',
    [SupportDocType.OTHER]: 'Other',
    [SupportDocType.SEGREGATION_SUPPORT]: 'Segregation Support',
    [SupportDocType.BUSINESS_REGISTRATION]: 'Business Registration',
    [SupportDocType.SAFETY_DATA_SHEET]: 'Safety Data Sheet',
    [SupportDocType.BANK_CERTIFICATE]: 'Bank Certificate',
};
