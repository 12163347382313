import { useMutation, useQueryClient } from '@tanstack/react-query';
import { toast } from 'sonner';
import { useContextStore } from 'src/app/stores/context-store';
import { ProductCategoryUpdateDataObject } from '../product-category-update-data-object';
import {
    CATEGORIES_QUERY_KEY,
    CATEGORY_QUERY_KEY,
} from './category-query-keys';
import { useCategoryService } from './use-category-service';

export function useUpdateCategory() {
    const { service } = useCategoryService();
    const client = useQueryClient();
    const context = useContextStore();

    return useMutation({
        mutationFn: async ({
            delegateId,
            id,
            body,
        }: {
            delegateId?: string;
            id: string;
            body: ProductCategoryUpdateDataObject;
        }) => {
            return await service.update(
                delegateId || context.workspace?._id || '',
                id,
                body
            );
        },
        onSuccess: (_, { delegateId, id }) => {
            client.invalidateQueries(
                CATEGORIES_QUERY_KEY(delegateId || context.workspace?._id || '')
            );

            client.invalidateQueries(
                CATEGORY_QUERY_KEY(
                    delegateId || context.workspace?._id || '',
                    id
                )
            );

            toast.success('Category updated successfully');
        },
        onError: (err) => {
            const error = err as Error;
            toast.error(`Failed to update category: ${error.message}`);
        },
    });
}
