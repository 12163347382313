import L from 'leaflet';
import icon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';
import {
    GanttChartSquare,
    Link,
    MapPin,
    Menu,
    Sheet,
    Trash,
    UserPlus,
    Users,
} from 'lucide-react';
import { useEffect, useState } from 'react';
import { MapContainer, Marker, Popup, TileLayer } from 'react-leaflet';
import Control from 'react-leaflet-custom-control';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useDeleteSupplier } from 'src/app/_api_adb2c/purchase/suppliers/hooks/use-delete-supplier';
import { useSuppliers } from 'src/app/_api_adb2c/purchase/suppliers/hooks/use-suppliers';
import { useCreateDelegation } from 'src/app/_api_adb2c/workspace/delegations/hooks/use-create-delegation';
import { useDelegations } from 'src/app/_api_adb2c/workspace/delegations/hooks/use-delegations';
import { useDeleteDelegation } from 'src/app/_api_adb2c/workspace/delegations/hooks/use-delete-delegation';
import { TooltipWrapper } from 'src/app/components/TooltipWrapper';
import { Button } from 'src/components/ui/button';
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuGroup,
    DropdownMenuItem,
    DropdownMenuLabel,
    DropdownMenuSeparator,
    DropdownMenuTrigger,
} from 'src/components/ui/dropdown-menu';
import { cn } from 'src/lib/utils';
import { AddSupplier } from './add-supplier';
import { SupplierList } from './supplier-list';

let DefaultIcon = L.icon({
    iconUrl: icon,
    shadowUrl: iconShadow,
});

export const customMarkerUserPos = new L.Icon({
    iconUrl: 'https://unpkg.com/leaflet@1.5.1/dist/images/marker-icon.png',
    iconSize: [25, 30],
    iconAnchor: [15, 30],
    popupAnchor: [2, -40],
});

L.Marker.prototype.options.icon = DefaultIcon;

export const Suppliers = () => {
    const { data } = useSuppliers();
    const navigate = useNavigate();
    const { data: delegations } = useDelegations();
    const [searchParams, setSearchParams] = useSearchParams({});

    const mode = searchParams.get('mode');

    const [isCreate, setIsCreate] = useState(false);
    const [isListOpen, setIsListOpen] = useState(true);

    const { mutateAsync: removeSupplier } = useDeleteSupplier();
    const { mutateAsync: removeDelegation } = useDeleteDelegation();
    const { mutateAsync: createDelegation } = useCreateDelegation();

    const remove = async (id: string, workspace: string) => {
        const existingDelegation = delegations?.find(
            (x) => x.delegatedTo._id === workspace
        );

        if (existingDelegation) {
            await removeDelegation({
                id: existingDelegation.delegatedTo._id,
            });
        }

        await removeSupplier({
            id: id,
        });
    };

    const assignDelegate = async (id: string) => {
        await createDelegation({
            body: {
                delegatedTo: id,
            },
        });
    };

    const handleRedirectToDetails = (id: string) => {
        navigate(`/suppliers/${id}`);
    };

    useEffect(() => {
        if (!mode) {
            setSearchParams((prev) => {
                prev.set('mode', 'list');

                return prev;
            });
        }
    }, [mode, setSearchParams]);

    return (
        <div className='flex h-full flex-col gap-4'>
            {mode === 'map' && (
                <>
                    <MapContainer
                        className='h-full w-full'
                        zoom={3}
                        center={[35.86166, 104.195397]}
                        attributionControl={false}
                        scrollWheelZoom={false}
                        boundsOptions={{ padding: [50, 50] }}
                        minZoom={2}
                    >
                        <TileLayer
                            attribution='&amp;copy <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors'
                            url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
                        />

                        <Control position='topleft'>
                            <TooltipWrapper label='List Suppliers'>
                                <div
                                    className='flex h-8 w-8 cursor-pointer items-center justify-center border bg-white shadow-lg'
                                    data-testid='list-suppliers-button'
                                >
                                    <Users
                                        onClick={() =>
                                            setIsListOpen((prev) => !prev)
                                        }
                                        className='h-4 w-4 font-extrabold'
                                    />
                                </div>
                            </TooltipWrapper>

                            <TooltipWrapper label='Add Supplier'>
                                <div
                                    className='flex h-8 w-8 cursor-pointer items-center justify-center border bg-white shadow-lg'
                                    data-testid='add-supplier-button'
                                >
                                    <UserPlus
                                        onClick={() =>
                                            setIsCreate((prev) => !prev)
                                        }
                                        className='h-4 w-4 font-extrabold'
                                    />
                                </div>
                            </TooltipWrapper>

                            <TooltipWrapper label='Switch View'>
                                <div
                                    className='flex h-8 w-8 cursor-pointer items-center justify-center border bg-white shadow-lg'
                                    data-testid='switch-view-button'
                                >
                                    <Sheet
                                        size={16}
                                        onClick={() =>
                                            setSearchParams((prev) => {
                                                prev.set('mode', 'list');

                                                return prev;
                                            })
                                        }
                                    />
                                </div>
                            </TooltipWrapper>
                        </Control>

                        {data?.map((supplier: any) => {
                            if (!supplier.seller.location) return <></>;
                            if (!supplier.seller.location.coordinates)
                                return <></>;

                            console.log(supplier.seller.location.coordinates);
                            if (
                                !supplier.seller.location.coordinates[0] ||
                                !supplier.seller.location.coordinates[1]
                            )
                                return <></>;

                            return (
                                <Marker
                                    key={supplier.seller._id}
                                    position={[
                                        supplier.seller?.location
                                            ?.coordinates?.[0],
                                        supplier.seller?.location
                                            ?.coordinates?.[1],
                                    ]}
                                    icon={customMarkerUserPos}
                                >
                                    <Popup>{supplier.seller.name}</Popup>
                                </Marker>
                            );
                        })}

                        {isListOpen && (
                            <div
                                className={cn(
                                    'absolute right-4 top-[0.7rem] z-[1000] flex min-h-[300px] w-80 flex-col gap-2 rounded bg-white p-2 shadow',
                                    `${isListOpen ? 'flex' : 'hidden'}`
                                )}
                            >
                                <div className='flex items-center justify-between border-b pb-1'>
                                    <span className='flex items-center gap-2 text-sm font-semibold'>
                                        Suppliers:{' '}
                                        <span className='text-xs font-light'>
                                            {data?.length || 0} companies
                                        </span>
                                    </span>
                                </div>

                                <div className='flex max-h-[250px] min-h-[250px] flex-col gap-4 overflow-auto px-4 py-2 text-xs'>
                                    {data?.map((supplier: any) => {
                                        return (
                                            <div
                                                key={supplier._id}
                                                className='flex items-center justify-between'
                                            >
                                                <Button
                                                    size='sm'
                                                    variant='ghost'
                                                    className='flex max-w-[80%] flex-1 justify-start gap-2 truncate px-2'
                                                >
                                                    <MapPin className='h-4 w-4' />
                                                    <span className='flex flex-1 justify-start truncate'>
                                                        {supplier.seller.name}
                                                    </span>
                                                </Button>

                                                <div className='flex items-center gap-2'>
                                                    <TooltipWrapper label='View Details'>
                                                        <Button
                                                            variant='ghost'
                                                            size='sm'
                                                            data-testid='view-supplier-button'
                                                            onClick={() =>
                                                                handleRedirectToDetails(
                                                                    supplier._id
                                                                )
                                                            }
                                                        >
                                                            <GanttChartSquare
                                                                size={16}
                                                            />
                                                        </Button>
                                                    </TooltipWrapper>

                                                    <DropdownMenu>
                                                        <DropdownMenuTrigger data-testid='supplier-actions-button'>
                                                            <TooltipWrapper label='Actions'>
                                                                <Menu
                                                                    size={16}
                                                                />
                                                            </TooltipWrapper>
                                                        </DropdownMenuTrigger>

                                                        <DropdownMenuContent
                                                            side='left'
                                                            className='z-[1000000] w-56'
                                                        >
                                                            <DropdownMenuLabel className='text-xs font-semibold'>
                                                                Actions
                                                            </DropdownMenuLabel>
                                                            <DropdownMenuSeparator />
                                                            <DropdownMenuGroup>
                                                                <DropdownMenuItem
                                                                    className='flex items-center gap-2'
                                                                    data-testid='assign-delegate-button'
                                                                    onClick={() => {
                                                                        assignDelegate(
                                                                            supplier
                                                                                .seller
                                                                                ._id
                                                                        );
                                                                    }}
                                                                    disabled={delegations?.some(
                                                                        (x) =>
                                                                            x.delegatedTo ===
                                                                            supplier
                                                                                .seller
                                                                                ._id
                                                                    )}
                                                                >
                                                                    <Link
                                                                        size={
                                                                            16
                                                                        }
                                                                    />
                                                                    <span className='truncate text-xs'>
                                                                        Assign
                                                                        As
                                                                        Delegate
                                                                    </span>
                                                                </DropdownMenuItem>

                                                                <DropdownMenuItem
                                                                    data-testid='remove-supplier-button'
                                                                    onClick={() => {
                                                                        remove(
                                                                            supplier._id,
                                                                            supplier
                                                                                .seller
                                                                                ._id
                                                                        );
                                                                    }}
                                                                    className='flex items-center gap-2'
                                                                >
                                                                    <Trash
                                                                        size={
                                                                            16
                                                                        }
                                                                    />
                                                                    <span className='truncate text-xs'>
                                                                        Remove
                                                                        Supplier
                                                                    </span>
                                                                </DropdownMenuItem>
                                                            </DropdownMenuGroup>
                                                        </DropdownMenuContent>
                                                    </DropdownMenu>
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        )}
                    </MapContainer>
                </>
            )}

            {mode === 'list' && <SupplierList />}

            {isCreate && (
                <AddSupplier
                    onClose={() => setIsCreate(false)}
                    open={isCreate}
                />
            )}
        </div>
    );
};
