import { ServiceConfigInterface } from 'src/domain/interfaces/config.context.interface';
import { ApiServer } from 'src/infrastructure/clients/api.server';
import { FileGetDataObject } from './file-get-data-object';
import { FileCreateDataObject } from './file-create-data-object';

export class AttachmentApiClient extends ApiServer {
    constructor(config: ServiceConfigInterface, token: string) {
        super(token, config, 'workspaces', 'file');
    }

    upload(
        workspace: string,
        asset: string,
        diversion: string,
        body: FileCreateDataObject
    ) {
        console.log(`client<file>| upload(): Enter`);
        console.log(`client<file>| upload(): $workspace = ${workspace}`);
        console.log(`client<file>| upload(): $asset = ${asset}`);
        console.log(`client<file>| upload(): $diversion = ${diversion}`);
        console.log(`client<file>| upload(): $body = ${body}`);

        return super.post('upload', workspace, asset, diversion, body);
    }

    download(
        workspace: string,
        body: FileGetDataObject,
        diversion: string = '0'
    ) {
        console.log(`client<file>| download(): Enter`);
        console.log(`client<file>| download(): $workspace = ${workspace}`);
        console.log(`client<file>| download(): $body = ${body}`);
        console.log(`client<file>| download(): $diversion = ${diversion}`);

        return super.post('download', workspace, diversion, body);
    }
}
