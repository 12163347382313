import { MultiSelectInputV2 } from 'src/app/components/Form/MultiSelectInputV2';

import {
    PurchaseOrderNature,
    PurchaseOrderNatureLabels,
} from 'src/app/_api_adb2c/purchase/purchase/enums/purchase-order-nature.enum';

import { SelectInput } from 'src/app/components/Form/SelectInput';
import { SupplyChainNodeTypeLabel } from 'src/app/_api_adb2c/workspace/shared/enum/supply-chain-node-type.enum';
import { SupplyChainNodeType } from 'src/app/_api_adb2c/workspace/shared/enum/supply-chain-node-type.enum';
import { TextInputV2 } from 'src/app/components/Form/TextInputV2';

export function EditPurchaseRequiredFields() {
    const currencies = ['USD', 'EUR', 'HKD', 'RMB', 'SGB'];

    return (
        <>
            <TextInputV2
                label='Delivery Address'
                name='shipToAddress'
                placeholder='Enter Shipping Address'
            />

            <TextInputV2
                label='Ref No.'
                name='externalDataId'
                placeholder='Enter reference number'
            />

            <SelectInput
                label='Currency'
                name='currency'
                options={currencies.map((x) => ({ label: x, value: x }))}
            />

            <SelectInput
                label='Order Nature (Optional)'
                name='orderNature'
                options={Object.values(PurchaseOrderNature).map((nature) => ({
                    label: PurchaseOrderNatureLabels[nature],
                    value: nature,
                }))}
            />

            <MultiSelectInputV2
                label='Purchase Processes'
                name='purchaseProcesses'
                options={Object.values(SupplyChainNodeType).map((x) => ({
                    label: SupplyChainNodeTypeLabel[x],
                    value: x,
                }))}
            />
        </>
    );
}
