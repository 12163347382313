import { ServiceConfigInterface } from 'src/domain/interfaces/config.context.interface';
import { RolesApiClient } from './roles.client';

export class RolesService {
    private server: RolesApiClient;

    constructor(
        config: ServiceConfigInterface,
        private readonly token: string
    ) {
        this.server = new RolesApiClient(config, token);
    }

    async list(workspace: string) {
        console.log(`service<role>| list(): Enter`);
        console.log(`service<role>| list(): $workspace = ${workspace}`);

        return await this.server.list(workspace);
    }

    async create(workspace: string, body: any) {
        console.log(`service<role>| create(): Enter`);
        console.log(`service<role>| create(): $workspace = ${workspace}`);
        console.log(`service<role>| create(): $body = ${JSON.stringify(body)}`);

        return await this.server.create(workspace, body);
    }

    async delete(workspace: string, id: string) {
        console.log(`service<role>| delete(): Enter`);
        console.log(`service<role>| delete(): $workspace = ${workspace}`);
        console.log(`service<role>| delete(): $id = ${id}`);

        await this.server.remove(workspace, id);
    }

    async getRoleMembers(workspace: string, role: string) {
        console.log(`service<role>| getRoleMembers(): Enter`);
        console.log(
            `service<role>| getRoleMembers(): $workspace = ${workspace}`
        );
        console.log(`service<role>| getRoleMembers(): $role = ${role}`);

        return await this.server.getRoleMembers(workspace, role);
    }

    async assignMember(workspace: string, role: string, body: any) {
        console.log(`service<role>| assignMember(): Enter`);
        console.log(`service<role>| assignMember(): $workspace = ${workspace}`);
        console.log(`service<role>| assignMember(): $role = ${role}`);
        console.log(
            `service<role>| assignMember(): $body = ${JSON.stringify(body)}`
        );

        return await this.server.assignMember(workspace, role, body);
    }

    async unassignMember(workspace: string, role: string, body: any) {
        console.log(`service<role>| unassignMember(): Enter`);
        console.log(
            `service<role>| unassignMember(): $workspace = ${workspace}`
        );
        console.log(`service<role>| unassignMember(): $role = ${role}`);
        console.log(
            `service<role>| unassignMember(): $body = ${JSON.stringify(body)}`
        );

        return await this.server.unassignMember(workspace, role, body);
    }

    async updateRolePermissions(workspace: string, role: string, body: any) {
        console.log(`service<role>| updateRolePermissions(): Enter`);
        console.log(
            `service<role>| updateRolePermissions(): $workspace = ${workspace}`
        );
        console.log(`service<role>| updateRolePermissions(): $role = ${role}`);
        console.log(
            `service<role>| updateRolePermissions(): $body = ${JSON.stringify(
                body
            )}`
        );

        return await this.server.updateRolePermissions(workspace, role, body);
    }
}
