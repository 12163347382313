import { Trash } from 'lucide-react';
import { useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { toast } from 'sonner';
import { Button } from 'src/components/ui/button';
import {
    Dialog,
    DialogContent,
    DialogFooter,
    DialogHeader,
    DialogPortal,
    DialogTrigger,
} from 'src/components/ui/dialog';
import { useDeletePurchase } from '../../_api/purchase/purchase-order/hooks/use-delete-purchase';
import {
    PurchaseOrderStatus,
    PurchaseOrderVersionModel,
} from '../../_api/purchase/purchase-order/purchase.model';

type Props = {
    order?: PurchaseOrderVersionModel;
    withNavigate?: boolean;
};

export const DeletePurchase: React.FC<Props> = ({ order, withNavigate }) => {
    const navigate = useNavigate();
    const [open, setOpen] = useState(false);
    const [searchParams] = useSearchParams();

    const delegateId = searchParams.get('delegateId') || '';

    const { mutateAsync } = useDeletePurchase();

    const deletePurchase = async () => {
        if (order?.owner.status !== PurchaseOrderStatus.DRAFT) {
            toast.error(
                'You cannot delete a purchase that is not in draft status.'
            );

            return;
        }

        await mutateAsync({
            id: order.owner.id,
            delegateId,
        });

        setOpen(false);

        if (withNavigate) {
            navigate('/purchases');
        }
    };

    return (
        <>
            <Dialog open={open} onOpenChange={setOpen}>
                <DialogTrigger onClick={() => setOpen(true)}>
                    <Button variant={'ghost'} className='p-2'>
                        <Trash className='h-4 w-4' />
                    </Button>
                </DialogTrigger>

                <DialogPortal>
                    <DialogContent>
                        <DialogHeader className='font-bold'>
                            Delete Purchase
                        </DialogHeader>

                        <div className='flex flex-col gap-2'>
                            <span>
                                Are you sure you want to delete this,{' '}
                                <span className='font-bold text-red-800'>
                                    {order?.owner?.externalDataId
                                        ? order?.owner.externalDataId
                                        : order?.owner.id}
                                </span>
                                ?
                            </span>

                            <span>
                                This action cannot be undone. This will
                                permanently delete the selected purchase.
                            </span>
                        </div>

                        <DialogFooter>
                            <Button
                                variant='outline'
                                type='submit'
                                className='bg-red-800 text-white'
                                onClick={async () => {
                                    await deletePurchase();
                                }}
                            >
                                Confirm
                            </Button>
                        </DialogFooter>
                    </DialogContent>
                </DialogPortal>
            </Dialog>
        </>
    );
};
