import {
    ColumnDef,
    ColumnFiltersState,
    OnChangeFn,
    RowSelectionState,
    SortingState,
    VisibilityState,
    flexRender,
    getCoreRowModel,
    getFilteredRowModel,
    getPaginationRowModel,
    getSortedRowModel,
    useReactTable,
} from '@tanstack/react-table';
import { useState } from 'react';
import { Button } from 'src/components/ui/button';
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableHeader,
    TableRow,
} from 'src/components/ui/table';
import { cn } from 'src/lib/utils';
import { FilterControls } from './Filters';
import { Loader2 } from 'lucide-react';

interface DataTableProps<TData, TValue> {
    columns: ColumnDef<TData, TValue>[];
    data: TData[];
    rowSelection?: RowSelectionState;
    setRowSelection?: OnChangeFn<RowSelectionState>;
    headerClassName?: string;
    bodyClassName?: string;
    label?: string;
    enableFilters?: boolean;
    actions?: ButtonActions[];
    isLoading?: boolean;
    enablePagination?: boolean;
}

interface ButtonActions extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    label: React.ReactElement;
}

export function DataTable<TData, TValue>({
    columns,
    data,
    rowSelection,
    setRowSelection,
    headerClassName,
    bodyClassName,
    label,
    enableFilters = false,
    actions = [],
    isLoading = false,
    enablePagination = true,
}: DataTableProps<TData, TValue>) {
    const [sorting, setSorting] = useState<SortingState>([]);
    const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
    const [columnVisibility, setColumnVisibility] = useState<VisibilityState>(
        {}
    );

    const table = useReactTable({
        data,
        columns,
        state: {
            sorting,
            columnFilters,
            columnVisibility,
            rowSelection: rowSelection ? rowSelection : {},
        },
        onSortingChange: setSorting,
        onColumnFiltersChange: setColumnFilters,
        onColumnVisibilityChange: setColumnVisibility,
        onRowSelectionChange: setRowSelection || undefined,
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getPaginationRowModel: enablePagination
            ? getPaginationRowModel()
            : undefined,
    });

    return (
        <>
            {/* Table */}
            {enableFilters && (
                <FilterControls
                    table={table}
                    columnFilters={columnFilters}
                ></FilterControls>
            )}
            <div className='ml-1 flex-1 rounded-md border'>
                <div className='flex h-10 items-center justify-between bg-red-800 px-2 py-1 text-white'>
                    <span className='font-bold'>{label}</span>

                    <div>
                        {actions.map((action) => {
                            return (
                                <Button
                                    variant='ghost'
                                    size='sm'
                                    onClick={action.onClick}
                                >
                                    {action.label}
                                </Button>
                            );
                        })}
                    </div>
                </div>

                <Table>
                    <TableHeader className='text-xs'>
                        {table.getHeaderGroups().map((headerGroup) => (
                            <TableRow key={headerGroup.id} className='text-xs'>
                                {headerGroup.headers.map((header) => {
                                    return (
                                        <TableHead
                                            key={header.id}
                                            className={cn(
                                                'text-xs',
                                                headerClassName
                                            )}
                                        >
                                            {header.isPlaceholder
                                                ? null
                                                : flexRender(
                                                      header.column.columnDef
                                                          .header,
                                                      header.getContext()
                                                  )}
                                        </TableHead>
                                    );
                                })}
                            </TableRow>
                        ))}
                    </TableHeader>

                    <TableBody className={cn('', bodyClassName)}>
                        {isLoading ? (
                            <TableRow className='text-xs'>
                                <TableCell
                                    colSpan={columns.length}
                                    className='h-24 text-center'
                                >
                                    <span className='flex items-center justify-center gap-2'>
                                        <Loader2 className='inline animate-spin' />
                                        Loading...
                                    </span>
                                </TableCell>
                            </TableRow>
                        ) : (
                            <>
                                {table.getRowModel().rows?.length ? (
                                    table.getRowModel().rows.map((row) => (
                                        <TableRow
                                            key={row.id}
                                            className='text-xs'
                                            data-state={
                                                row.getIsSelected() &&
                                                'selected'
                                            }
                                        >
                                            {row
                                                .getVisibleCells()
                                                .map((cell) => (
                                                    <TableCell
                                                        key={cell.id}
                                                        className='truncate'
                                                    >
                                                        {flexRender(
                                                            cell.column
                                                                .columnDef.cell,
                                                            cell.getContext()
                                                        )}
                                                    </TableCell>
                                                ))}
                                        </TableRow>
                                    ))
                                ) : (
                                    <TableRow className='text-xs'>
                                        <TableCell
                                            colSpan={columns.length}
                                            className='h-24 text-center'
                                        >
                                            No results.
                                        </TableCell>
                                    </TableRow>
                                )}
                            </>
                        )}
                    </TableBody>
                </Table>
            </div>

            {/* Pagination */}
            {enablePagination && (
                <div className='flex items-center justify-end space-x-2 py-4'>
                    <Button
                        variant='outline'
                        size='sm'
                        onClick={() => table.previousPage()}
                        disabled={!table.getCanPreviousPage()}
                    >
                        Previous
                    </Button>
                    <Button
                        variant='outline'
                        size='sm'
                        onClick={() => table.nextPage()}
                        disabled={!table.getCanNextPage()}
                    >
                        Next
                    </Button>
                </div>
            )}
        </>
    );
}
