import { useQuery } from '@tanstack/react-query';
import { useContextStore } from 'src/app/stores/context-store';
import { ProductModel } from '../models/product.model';
import { PRODUCT_QUERY_KEY } from './product-query-keys';
import { useProductService } from './use-product-service';

export function useProduct(id: string, delegatedId?: string) {
    const { service } = useProductService();
    const context = useContextStore();

    return useQuery<ProductModel>({
        queryKey: PRODUCT_QUERY_KEY(
            delegatedId || context.workspace?._id || '',
            id
        ),
        queryFn: async () => {
            return await service.get(
                delegatedId || context.workspace?._id || '',
                id
            );
        },
        onError: (err) => {
            const error = err as Error;

            console.error(`Failed to fetch product: ${error.message}`);
        },
        enabled: !!id,
    });
}
