export const PRODUCTS_QUERY_KEY = (workspace: string) => [
    'products',
    workspace,
];

export const PRODUCT_QUERY_KEY = (workspace: string, id: string) => [
    'product',
    workspace,
    id,
];
