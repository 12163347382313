import { useNavigate, useSearchParams } from 'react-router-dom';
import { toast } from 'sonner';
import { useDeleteProduct } from 'src/app/_api_adb2c/product/product/hooks/use-delete-product';
import { usePurchases } from 'src/app/_api_adb2c/purchase/purchase/hooks/use-purchases';
import { RemoveDialog } from '../../Purchases/Details/delete-purchase';

interface Props {
    product: string;
    open: boolean;
    onClose: () => void;
}

export function DeleteProduct({ product, open, onClose }: Props) {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const delegateId = searchParams.get('delegateId') || '';
    const { data: purchases } = usePurchases({
        draft: false,
        page: 0,
        limit: 0,
    });

    const { mutateAsync: remove, isLoading } = useDeleteProduct();

    const checkIfProductIsUsed = () => {
        return purchases?.data.some((purchase) => {
            return purchase.versions.some((version) => {
                return version.manifest.some((manifest) => {
                    return manifest.product._id === product;
                });
            });
        });
    };

    const removeProduct = async () => {
        if (checkIfProductIsUsed()) {
            toast.error(
                'Unable to remove product. Product is used in a purchase.'
            );
            return;
        }

        await remove({
            item: product,
            delegateId: delegateId,
        });

        onClose();

        navigate('/products');
    };

    return (
        <RemoveDialog
            open={open}
            isLoading={isLoading}
            onClose={onClose}
            onRemove={removeProduct}
            title='Delete Product'
            description='Are you sure you want to delete this product?'
        />
    );
}
