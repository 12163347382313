import { ServiceConfigInterface } from 'src/domain/interfaces/config.context.interface';
import { SolicitApiClient } from './solicit.client';

export class SolicitService {
    private server: SolicitApiClient;

    constructor(config: ServiceConfigInterface, token: string) {
        this.server = new SolicitApiClient(config, token);
    }

    list(workspace: string, partner: string) {
        console.log(`service<solicit> list(): Enter`);
        console.log(`service<solicit> list(): workspace: ${workspace}`);
        console.log(`service<solicit> list(): partner: ${partner}`);

        return this.server.solicitDocuments(workspace, partner);
    }
}
