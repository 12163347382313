import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { useCloneMaterial } from 'src/app/_api_adb2c/product/material/hooks/use-clone-material';
import { MaterialModel } from 'src/app/_api_adb2c/product/material/models/material.model';
import { ProductMaterialDataObject } from 'src/app/_api_adb2c/product/material/request/product-material-data-object';
import {
    ProductUom,
    ProductUomLabels,
} from 'src/app/_api_adb2c/purchase/purchase/enums/product-uom.enum';
import { DialogV2, StepItem } from 'src/app/components-v2/dialog-v2';
import { SelectInputV2 } from 'src/app/components/Form/SelectInputV2';
import { TextInputV2 } from 'src/app/components/Form/TextInputV2';
import { z } from 'zod';

interface Props {
    open: boolean;
    material?: MaterialModel;
    onClose: () => void;
}

const schema = z.object({
    name: z.string().min(1, { message: 'Material name is required' }),
    description: z.string().optional(),
    uom: z.string(),
    weight: z.coerce.number().optional(),
    cost: z.coerce.number(),
    currency: z.string(),
    width: z.coerce.number().optional(),
    height: z.coerce.number().optional(),
    length: z.coerce.number().optional(),
});

export function CloneMaterial({ material, open, onClose }: Props) {
    const { mutateAsync: cloneMaterial, isLoading } = useCloneMaterial();

    const currencies = ['USD', 'EUR', 'HKD', 'RMB', 'SGB'];
    const form = useForm<z.infer<typeof schema>>({
        resolver: zodResolver(schema),
        defaultValues: {
            name: material?.name,
            description: material?.description,
            cost: material?.unitCost,
            currency: material?.unitCostCurrency,
            uom: material?.unitOfMeasurement,
            weight: material?.specifications?.weight,
            width: material?.specifications?.width,
            height: material?.specifications?.height,
            length: material?.specifications?.length,
        },
    });

    const onSubmit = async (data: z.infer<typeof schema>) => {
        const body: ProductMaterialDataObject = {
            name: data.name,
            description: data.description,
            unitOfMeasurement: data.uom,
            unitCost: data.cost,
            unitCostCurrency: data.currency,
            specifications: {
                weight: data.weight,
                width: data.width,
                height: data.height,
                length: data.length,
            },
            weight: data.weight,
            workspace: material?._id,
        };

        await cloneMaterial({ item: material?._id || '', body });
    };

    const steps: StepItem[] = [
        {
            title: 'Component Information',
            description: 'Information to be cloned',
            content: (
                <div className='space-y-4'>
                    <TextInputV2 label='Component Name' name='name' />
                    <TextInputV2 label='Description' name='description' />

                    <div className='grid grid-cols-2 gap-4'>
                        <TextInputV2 label='Cost' name='cost' type='number' />
                        <SelectInputV2
                            label='Currency'
                            name='currency'
                            options={currencies.map((x) => ({
                                label: x,
                                value: x,
                            }))}
                        />
                    </div>

                    <SelectInputV2
                        label='Unit of Measurement'
                        name='uom'
                        options={Object.values(ProductUom).map((x) => ({
                            label: ProductUomLabels[x],
                            value: x,
                        }))}
                    />

                    <TextInputV2 label='Weight' name='weight' type='number' />

                    <div className='grid grid-cols-3 gap-4'>
                        <TextInputV2 label='Width' name='width' type='number' />
                        <TextInputV2
                            label='Height'
                            name='height'
                            type='number'
                        />
                        <TextInputV2
                            label='Length'
                            name='length'
                            type='number'
                        />
                    </div>
                </div>
            ),
        },
    ];

    return (
        <DialogV2
            title='Clone Component'
            open={open}
            onClose={onClose}
            form={form}
            onSubmit={onSubmit}
            formName='clone-material-form'
            isStepDialog
            size='lg'
            steps={steps}
            isLoading={isLoading}
        />
    );
}
