import React from 'react';
import { AlertCircle, Info, AlertTriangle } from 'lucide-react';
import {
    Tooltip,
    TooltipContent,
    TooltipProvider,
    TooltipTrigger,
} from 'src/components/ui/tooltip';
import { cn } from 'src/lib/utils';

interface TooltipWrapperProps {
    content: React.ReactNode;
    tooltipContent?: React.ReactNode;
    className?: string;
    disabled?: boolean;
    mode?: 'default' | 'error' | 'info' | 'warning';
    triggerClassName?: string;
}

export const TooltipWrapper = ({
    content,
    tooltipContent,
    className = 'shadow-lg border bg-white text-black',
    disabled = false,
    mode = 'default',
    triggerClassName,
}: TooltipWrapperProps) => {
    const getModeStyles = () => {
        switch (mode) {
            case 'error':
                return 'bg-red-50 border-red-200 text-red-900';
            case 'info':
                return 'bg-blue-50 border-blue-200 text-blue-900';
            case 'warning':
                return 'bg-yellow-50 border-yellow-200 text-yellow-900';
            default:
                return className;
        }
    };

    const getIcon = () => {
        switch (mode) {
            case 'error':
                return <AlertCircle className='mr-2 h-4 w-4 text-red-500' />;
            case 'info':
                return <Info className='mr-2 h-4 w-4 text-blue-500' />;
            case 'warning':
                return (
                    <AlertTriangle className='mr-2 h-4 w-4 text-yellow-500' />
                );
            default:
                return null;
        }
    };

    if (disabled) {
        return <>{content}</>;
    }

    return (
        <TooltipProvider>
            <Tooltip>
                <TooltipTrigger asChild>
                    <div
                        className={cn(
                            'w-full cursor-pointer truncate',
                            triggerClassName
                        )}
                    >
                        {content}
                    </div>
                </TooltipTrigger>
                <TooltipContent
                    className={cn(
                        getModeStyles(),
                        'border bg-white text-black shadow-lg'
                    )}
                >
                    <div className='flex items-center'>
                        {getIcon()}
                        {tooltipContent || content}
                    </div>
                </TooltipContent>
            </Tooltip>
        </TooltipProvider>
    );
};
