import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { cn } from 'src/app/utils/cn';
import {
    FormControl,
    FormField,
    FormItem,
    FormLabel,
    FormMessage,
} from 'src/components/ui/form';
import { Input } from 'src/components/ui/input';

const sizes = {
    xs: 'text-xs',
    sm: 'text-sm',
    md: 'text-base',
};

export interface TextInputProps
    extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'size'> {
    label?: string;
    inputName: string;
    type?: string;
    placeholder?: string;
    startAdornment?: React.ReactNode;
    endAdornment?: React.ReactNode;
    disabled?: boolean;
    readOnly?: boolean;
    className?: string;
    displayValue?: string;
    size?: keyof typeof sizes;
    onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
}

export const TextInput = ({
    label,
    inputName: name,
    type = 'string',
    placeholder,
    startAdornment,
    endAdornment,
    disabled = false,
    readOnly = false,
    className,
    displayValue,
    size = 'xs',
    onKeyDown,
    ...args
}: TextInputProps) => {
    const { control, watch } = useFormContext();

    const currentValue = watch(name);
    const [value, setValue] = useState(currentValue);

    useEffect(() => {
        setValue(currentValue);
    }, [currentValue]);

    if (disabled && !readOnly) {
        return (
            <FormField
                control={control}
                name={name}
                render={({ field }) => (
                    <FormItem className=''>
                        <FormLabel className='text-xs font-bold'>
                            {label}
                        </FormLabel>
                        <br />
                        <span className='flex h-7 w-full py-1 text-xs'>
                            {displayValue || field.value || '-'}
                        </span>
                    </FormItem>
                )}
            />
        );
    }

    return (
        <FormField
            control={control}
            name={name}
            rules={{
                required: `${label} is required`,
            }}
            key={name}
            render={({ field }) => (
                <FormItem className='flex flex-1 flex-col'>
                    {label && (
                        <FormLabel className={cn('', sizes[size])}>
                            {label}
                        </FormLabel>
                    )}

                    <FormControl>
                        <div className={cn('relative', sizes[size])}>
                            {startAdornment && (
                                <div className='absolute left-2 top-1/2 -translate-y-1/2 transform text-gray-400'>
                                    {startAdornment}
                                </div>
                            )}

                            <Input
                                {...args}
                                {...field}
                                type={type}
                                key={name}
                                placeholder={placeholder || '-'}
                                data-testid='input'
                                disabled={disabled}
                                readOnly={readOnly}
                                className={cn(sizes[size], className)}
                                value={displayValue || value || ''}
                                onChange={(e) => setValue(e.target.value)}
                                onBlur={() => field.onChange(value)}
                                onKeyDown={(e) => {
                                    if (onKeyDown) {
                                        onKeyDown(e);
                                    }
                                }}
                            />

                            {endAdornment && (
                                <div className='absolute right-2 top-1/2 -translate-y-1/2 transform text-gray-400'>
                                    {endAdornment}
                                </div>
                            )}
                        </div>
                    </FormControl>
                    <FormMessage />
                </FormItem>
            )}
        />
    );
};
